import React from 'react'

import ShareItem from '../ShareItem'
import '../../assets/styles/modal.scss'
import '../../assets/styles/eventModal.scss'

const ModalShare = ({
  title, // {title}을 SNS에 공유해보세요.
  btnClose,
  data,
  banner, // SNS 공유시 보이는 이미지
  handleCloseModal,
  postShare, // 공유 성공시 호출될 함수
  snsShareTitle, // SNS 공유시 title
  pathname // 목록페이지에서 공유 버튼 클릭 시 현재 url말고 다른 url로 공유되어야 할 때
}) => {
  return (
    <>
      <div className="modal-wrap share-modal">
        <div className="dimmed" onClick={handleCloseModal}></div>
        <div className="modal-inner">
          {btnClose && (
            <button type="button" className="btn-modal-close" onClick={handleCloseModal}>
              <span className="blind">닫기</span>
            </button>
          )}
          <div className="modal-content">
            <p>
              {title}
              <br />
              SNS에 공유해보세요.
            </p>
            <ShareItem data={data} banner={banner} postShare={postShare} snsShareTitle={snsShareTitle} pathname={pathname} />
          </div>
        </div>
      </div>
    </>
  )
}

export default ModalShare
