import React, { useState, useCallback } from 'react';
import ModalPortal from '../../ModalPortal';
import ModalOrderCancel from '../../components/modal/ModalOrderCancel';

import api from 'common/api';

export default function ButtonOrderCancel({ orderNo, orderDate }) {
  const [modalopen, setModalopen] = useState(false);

  // 주문취소
  const handleOpenModal = useCallback(() => {
    alert('주문 내역 전체가 취소됩니다');
    document.body.classList.add('modal-open');
    setModalopen(true);
  }, []);

  const handleCloseModal = useCallback((e) => {
    document.body.classList.remove('modal-open');
    setModalopen(false);
  }, []);

  // 주문취소 API
  const putOrderCancel = (cancelReason, orderNo) => {
    const data = {
      cancelReason: cancelReason,
      cancelType: 'R',
      orderNo: orderNo,
    };

    api
      .put('/v2/order/cancel', data)
      .then((response) => {
        if (response.data.meta.code !== 200) {
          alert(response.data.meta.message);
          return false;
        }

        alert('취소되었습니다.');
        window.location.reload();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <>
      <button
        className="button stroke"
        onClick={() => {
          handleOpenModal(orderNo, orderDate);
        }}
      >
        <span>주문취소</span>
      </button>

      {modalopen ? (
        <ModalPortal>
          <ModalOrderCancel
            orderNo={orderNo}
            orderDate={orderDate}
            putOrderCancel={putOrderCancel}
            btnClose
            handleCloseModal={handleCloseModal}
          />
        </ModalPortal>
      ) : null}
    </>
  );
}
