import useOnClickOutside from 'hooks/useOnClickOutside'
import ModalPortal from 'ModalPortal'

import s from '../aiTalk.module.css'

export default function Modal({ btn = false, children, onClose, dateClose, width }) {
  const modalRef = useOnClickOutside(onClose)

  return (
    <ModalPortal>
      <article className={s.modalWrap}>
        <div className={s.modalContent} style={{ maxWidth: width ? width : '100%' }} ref={modalRef}>
          {btn && <button className={s.btnClose} onClick={onClose}></button>}
          {children}
          {dateClose && (
            <button className={s.btnTodayClose} onClick={dateClose.onClick}>
              {dateClose.title}
            </button>
          )}
        </div>
      </article>
    </ModalPortal>
  )
}
