import AuthService from 'services/authService'

import useModalLogin from 'store/useModalLogin'

export default function useGoOrderCode() {
  const isLoggedIn = AuthService.isLoggedIn()
  const { setIsModalLogin } = useModalLogin()

  const handleOrderCode = (packageCode, isNewWindow = false) => {
    if (isLoggedIn) {
      goOrderCode(packageCode, isNewWindow)
    } else {
      setIsModalLogin(true)
    }
  }

  return { handleOrderCode, isLoggedIn }
}

function goOrderCode(packageCode, isNewWindow) {
  if (isNewWindow) {
    window.open(
      `/mypage/order/cart/promotion/${packageCode}?errorReturnUrl=${encodeURIComponent(
        document.location.pathname
      )}`
    )
  } else {
    document.location.href = `/mypage/order/cart/promotion/${packageCode}?errorReturnUrl=${encodeURIComponent(
      document.location.pathname
    )}`
  }
}
