import { useCallback, useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from 'react-router'

import useConsultation from 'hooks/useConsultation'
import {
  getUserLevel,
  getUserRecommendedProduct
} from 'pages/levelTestAdvanced/useLevelTestAdvanced'
import { Swiper, SwiperSlide } from 'swiper/react'

import ALERT_MESSAGE from 'common/alertMessage'
import api from 'common/api'
import { API_HOST } from 'common/config'
import { getUtm } from 'components/promotion/utile/utile'
import useLevelTestAdvancedExamStore from 'store/levelTestAdvanced/useLevelTestAdvancedExamStore'
import useLevelTestAdvancedStore from 'store/levelTestAdvanced/useLevelTestAdvancedStore'

import { LTA_HOST_CDN } from 'pages/levelTestAdvanced/config'

import AnalyzingCompleteModal from './AnalyzingCompleteModal'
import styles from './AnalyzingSection.module.css'
import { reviewListValues } from '../../config'

export default function AnalyzingSection() {
  const [isShow, setIsShow] = useState(false)
  const [seq, setSeq] = useState(null)
  const isTablet = useMediaQuery({ maxWidth: 750 })
  const isMobile = useMediaQuery({ maxWidth: 425 })

  const navigate = useNavigate()
  const { onPostUserConsultation } = useConsultation()

  const { selfCheck, test } = useLevelTestAdvancedStore((state) => ({
    selfCheck: state.selfCheck,
    test: state.test
  }))
  const leadTime = useLevelTestAdvancedExamStore((state) => state.leadTime)

  const correctAnswers = test?.filter(({ isCorrect }) => isCorrect)
  const totalScore = correctAnswers?.reduce((acc, cur) => (acc += cur.score), 0)

  const handleClickResult = () => {
    if (seq) {
      navigate(`/levelTestAdvanced/result?seq=${seq}`)
    }
  }

  // 내부상담요청
  const handlePostConsultation = () => {
    const contents = {
      '망설이는 이유': selfCheck['reason'],
      '원하는 공부법': selfCheck['method'],
      목표: selfCheck['goal']?.slice(0, selfCheck['goal'].length - 1),
      score: totalScore,
      level: getUserLevel(totalScore),
      추천상품: getUserRecommendedProduct(totalScore)
    }
    const formData = {
      category: 'levelTest3',
      utm: getUtm() || '',
      smsAgreeYn: '',
      privacyAgreeYn: 'Y',
      content: JSON.stringify(contents)
    }
    onPostUserConsultation(formData, (response) => {
      if (response.data.data) {
        alert(ALERT_MESSAGE.CONSULTATION_COMPLETE)
      } else {
        alert(ALERT_MESSAGE.CONSULTATION_DUPLICATED)
      }
      handleClickResult()
    })
  }

  // 테스트 완료 api post
  const onSubmit = useCallback(() => {
    try {
      const url = `${API_HOST}/v2/level-test/deepen/complete`
      const data = {
        leadTime,
        correctAnswerCount: correctAnswers?.length,
        score: totalScore,
        level: getUserLevel(totalScore)
      }
      api.post(url, data).then((res) => {
        setSeq(res.data.data)
        setTimeout(() => {
          setIsShow(true)
        }, 4800)
      })
    } catch (error) {
      alert('통신장애가 발생되었습니다.\n 잠시후 다시 시도해주세요')
    }
  }, [correctAnswers?.length, leadTime, totalScore])
  useEffect(() => {
    onSubmit()
  }, [onSubmit])

  const radius = isMobile ? 83 : isTablet ? 160 : 109
  const circumference = isMobile ? 77 : isTablet ? 155 : 104

  return (
    <div className={styles.container}>
      <p className={styles.subTitle}>고생하셨어요!</p>
      <p className={styles.title}>레벨테스트 분석중...</p>
      <div className={styles.loadinerContainer}>
        <svg className={styles.loadingCircle}>
          <circle className={styles.frame} cx={radius} cy={radius} r={circumference} />
          <circle className={styles.bar} cx={radius} cy={radius} r={circumference} />
        </svg>
        <div className={styles.imgContainer}>
          <img src={`${LTA_HOST_CDN}img-test-analyzing.png`} alt="analyzing" />
        </div>
      </div>
      <p className={styles.description}>
        심화 레벨테스트를 받아본
        <br />
        수강생의 후기들을 확인해 보세요
      </p>

      <div className={styles.reviewContainer}>
        <Swiper
          className={styles.reviewSwiper}
          autoplay
          loop
          speed={3000}
          direction="vertical"
          spaceBetween={16}
          slidesPerView={2}
        >
          {reviewListValues.map((review) => (
            <SwiperSlide className={styles.reviewSlide} key={review.id}>
              <img className={styles.star} src={`${LTA_HOST_CDN}icon-review-star.png`} alt="star" />
              <p className={styles.reviewTitle}>{review.title}</p>
              <p className={styles.reviewDescription}>{review.description}</p>
              <p className={styles.reviewWriter}>By. {review.writer}</p>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <AnalyzingCompleteModal
        onClickResult={handleClickResult}
        onPostConsultation={handlePostConsultation}
        open={isShow}
      />
    </div>
  )
}
