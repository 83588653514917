import { useEffect, useState, useRef } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useParams, useLocation } from 'react-router-dom'

import useHideHeader from 'hooks/useHideHeader'

import api from 'common/api'

import CurriculumList from './CurriculumList'
import DetailCard from './DetailCard'
import PriceInfo from './PriceInfo'
import QuestionList from './QuestionList'
import ReviewList from './ReviewList'
import TeacherInfo from './TeacherInfo'
import Banner from '../../components/Banner'

export default function DetailEntrance() {
  const { packageSeq } = useParams()
  const { pathname } = useLocation()

  const { top } = useHideHeader()

  const [classData, setClassData] = useState([])
  const [entrance, setEntrance] = useState('entrance')

  const [productPnt, setProductPnt] = useState(false)
  const [curriculumPnt, setCurriculumPnt] = useState(false)
  const [reviewPnt, setReviewPnt] = useState(false)
  const [questionPnt, setQuestionPnt] = useState(false)

  const productDetailRef = useRef()
  const curriculumPointRef = useRef()
  const reviewPointRef = useRef()
  const questionPointRef = useRef()
  const menuBarRef = useRef()

  const [prodDetail, setProdDetail] = useState(false)

  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1024 })

    return isDesktop ? children : null
  }

  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 1024 })

    return isMobile ? children : null
  }

  const onScroll = (moveTarget) => {
    window.scrollTo(0, document.querySelector(moveTarget).offsetTop - 10)
  }

  const scrollCheck = () => {
    if (questionPointRef.current.getBoundingClientRect().top < 150) {
      setQuestionPnt(true)
      setProductPnt(false)
      setCurriculumPnt(false)
      setReviewPnt(false)
      menuBarRef.current.classList.add('fixed')
    } else if (reviewPointRef.current.getBoundingClientRect().top < 150) {
      setReviewPnt(true)
      setQuestionPnt(false)
      setProductPnt(false)
      setCurriculumPnt(false)
      menuBarRef.current.classList.add('fixed')
    } else if (curriculumPointRef.current.getBoundingClientRect().top < 150) {
      setReviewPnt(false)
      setQuestionPnt(false)
      setProductPnt(false)
      setCurriculumPnt(true)
      menuBarRef.current.classList.add('fixed')
    } else if (productDetailRef.current.getBoundingClientRect().top < 150) {
      setReviewPnt(false)
      setQuestionPnt(false)
      setProductPnt(true)
      setCurriculumPnt(false)
      menuBarRef.current.classList.add('fixed')
    } else {
      menuBarRef.current.classList.remove('fixed')
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', scrollCheck)

    return () => {
      window.removeEventListener('scroll', scrollCheck)
      document.body.classList.remove('modal-open')
    }
  }, [])

  const getClassData = () => {
    api
      .get(`/v2/store/sale/entrance-package/detail`)
      .then((response) => {
        setClassData(response.data.data)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  useEffect(() => {
    if (packageSeq) {
      getClassData(packageSeq)
    }
  }, [packageSeq, pathname])

  return (
    <>
      <section className="product-col">
        <div className="inner-col">
          <div className="product-content-col">
            <DetailCard classData={classData} />
            <Mobile>
              <PriceInfo classData={classData} entrance={entrance} />
            </Mobile>
            {/* 해당 상품과 연관된 이벤트가 있는 경우 노출 */}
            {classData.packageEvents && classData.packageEvents.length > 0 && (
              <Banner type={'normal'} bannerSlideLists={classData.packageEvents} />
            )}

            <section className="product-list-col" style={{ top }} ref={menuBarRef}>
              <ul>
                <li className={productPnt === true ? 'active' : ''}>
                  <button type="button" onClick={() => onScroll('.product-detail')}>
                    상품소개
                  </button>
                </li>
                {classData.packageTypeCode === 'REAL' ? null : (
                  <li className={curriculumPnt === true ? 'active' : ''}>
                    <button type="button" onClick={() => onScroll('.curriculum-col')}>
                      커리큘럼
                    </button>
                  </li>
                )}
                <li className={reviewPnt === true ? 'active' : ''}>
                  <button type="button" onClick={() => onScroll('.review-list-col')}>
                    후기
                  </button>
                </li>
                <li className={questionPnt === true ? 'active' : ''}>
                  <button type="button" onClick={() => onScroll('.question-col')}>
                    상품문의
                  </button>
                </li>
              </ul>
            </section>
            {/* 구성정보 : 묶음 상품인 경우에만 노출 */}

            {/* <Composition packageSeq={packageSeq} entrance={entrance} /> */}
            <div
              ref={productDetailRef}
              className={prodDetail === true ? 'product-detail active' : 'product-detail'}
              dangerouslySetInnerHTML={{
                __html: classData.detail
              }}
            ></div>
            <button className="btn-detail-more" type="button" onClick={() => setProdDetail(true)}>
              <span>상품정보 더보기</span>
            </button>
            {/* {classData.packageTypeCode === 'COMBINATION' && (
            )} */}
            <TeacherInfo classData={classData} />
            {/* 실물상품, 앱 이용권인 경우 비노출 */}
            {classData.packageTypeCode === 'REAL' || classData.packageTypeCode === 'APP' ? null : (
              <>
                <div className="point-curriculum" ref={curriculumPointRef}></div>
                <CurriculumList packageSeq={packageSeq} entrance={entrance} />
              </>
            )}
            <div className="point-review" ref={reviewPointRef}></div>
            <ReviewList packageSeq={packageSeq} classData={classData} />
            <div className="point-question" ref={questionPointRef}></div>
            <QuestionList packageSeq={packageSeq} classData={classData} />
          </div>
          <Desktop>
            <PriceInfo classData={classData} entrance={entrance} />
          </Desktop>
        </div>
      </section>
    </>
  )
}
