import { useEffect, useState } from 'react'

import api from 'common/api'

export default function useGetFaqContent(seq) {
  const [answer, setAnswer] = useState('')
  const [list, setList] = useState({})

  const fetchFaqContent = async (seq) => {
    try {
      const response = await api.get(`/v2/faq/${seq}`)
      const data = response.data.data
      setList((prevList) => ({ ...prevList, [`${seq}`]: data }))
      setAnswer(data)
    } catch (e) {
      console.error(`fetch faq answer error (${seq}): `, e)
    }
  }

  useEffect(() => {
    if (seq) {
      if (list[seq]) {
        setAnswer(list[seq])
      } else {
        fetchFaqContent(seq)
      }
    }
    return () => setAnswer('')
  }, [seq, list])

  return { answer }
}
