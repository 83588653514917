import { useEffect, useState } from 'react'
import { useLocation } from 'react-router'

import classNames from 'classnames'

import NavLeft from './NavLeft'
import styles from '../../styles/SideMenu.module.scss'

export default function HeaderHamburger() {
  const currentPath = useLocation().pathname
  const [isHamburger, setHamburger] = useState(false)

  useEffect(() => {
    setHamburger(false)
  }, [currentPath])

  return (
    <>
      <button className={styles.hamburgerButton} onClick={() => setHamburger(!isHamburger)}>
        <span className={styles.icon}>
          <i className="blind">hamburger menu</i>
        </span>
      </button>
      <div
        onClick={() => setHamburger(!isHamburger)}
        className={classNames(styles.navDim, isHamburger && styles.active)}
      >
        <span className={styles.closeIcon}>
          <i className="blind">레이어 닫기</i>
        </span>
      </div>
      <NavLeft isOver={false} isHamburger={isHamburger} />
    </>
  )
}
