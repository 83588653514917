import React from 'react'

import cx from 'classnames'

import s from './ChatTopFaq.module.scss'
import { AI_FAQ_CONFIG } from '../config'

export default function ChatTopFaq({ isPlatform }) {
  return (
    <div className={cx(s.chatTopFaq, { [s.webview]: isPlatform })}>
      <hgroup className={s.faqTitle}>
        <div className={s.badge}>
          <span>AI 상담원</span>
        </div>
        <span className={s.faqSmallTitle}>365일, 24시간 언제든지!</span>
        <h5 className={s.faqBigTitle}>무엇을 도와드릴까요?</h5>
      </hgroup>
      <figure className={s.faqThumb}>
        <img src={AI_FAQ_CONFIG.AI_PROFILE_MAIN} alt="ai 상담원" />
      </figure>
    </div>
  )
}
