import React from 'react'
import { useNavigate } from 'react-router'

import useGetFaqList from 'pages/service/hooks/useGetFaqList'

import s from './WebviewCustomer.module.scss'

export default function FaqBestList() {
  const navigate = useNavigate()
  const { faqList } = useGetFaqList({ cate: 'BEST', group: 'YANADOO_FAQ_GROUP', page: 1, size: 3 })
  const handleFaqItem = (faqSeq) => {
    navigate(`/webview/service/faq?faqSeq=${faqSeq}`)
  }
  return (
    <ul className={s.faqBestList}>
      {faqList.map((item, index) => (
        <li key={index} onClick={() => handleFaqItem(item.faqSeq)}>
          <span dangerouslySetInnerHTML={{ __html: item.title }}></span>
        </li>
      ))}
    </ul>
  )
}
