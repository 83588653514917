import React from 'react'

import s from './ChatTopInfo.module.scss'
import { AI_TRAVEL_CONFIG } from '../config'

export default function ChatTopInfo({ metaInfo }) {
  const { TRAVEL_THUMBNAIL_HOST } = AI_TRAVEL_CONFIG
  const { title, titleEng, titleImg, roleAi, roleUser, scenario } = metaInfo
  if (!metaInfo) return null
  return (
    <div className={s.chatTopInfo}>
      <img className={s.aiImg} src={`${TRAVEL_THUMBNAIL_HOST}${titleImg}`} alt={title} />
      <dl>
        <dt className={s.aiName}>{title}</dt>
        <dd className={s.aiDesc}>{titleEng}</dd>
        <dd className={s.aiHash}>
          <span>
            <em>🤖 AI : </em>
            {roleAi}
          </span>
          <span style={{ backgroundColor: '#FFE6A5' }}>
            <em>👍️ 나 : </em>
            {roleUser}
          </span>
        </dd>
      </dl>
      <div className={s.aiNoti}>
        <p>
          {scenario.split('\n').map((item, index) => (
            <span key={index}>{item}</span>
          ))}
        </p>
      </div>
    </div>
  )
}
