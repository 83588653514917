import { useEffect, useState } from 'react'

export default function useGetUser() {
  const [user, setUser] = useState(null)

  useEffect(() => {
    const localUser = localStorage.getItem('user')
    if (localUser) {
      try {
        const parsedUser = JSON.parse(localUser)
        setUser(parsedUser)
      } catch (error) {
        console.error('Error parsing user data from localStorage', error)
        setUser({})
      }
    }
  }, [])

  return { user }
}
