import React from 'react'

export default function NoticeItem({ notice }) {
  return (
    <dl>
      <dt dangerouslySetInnerHTML={{ __html: notice.title }} />
      <dd>{notice.registDate}</dd>
    </dl>
  )
}
