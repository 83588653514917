import { useLayoutEffect, useState } from 'react'

export default function useRemoveCommonLayout() {
  const [isRemoved, setIsRemoved] = useState(false)

  useLayoutEffect(() => {
    const header = document.querySelector('header')
    const footer = document.querySelector('footer')
    const nav = header.previousElementSibling
    const channelTalk = document.getElementById('ch-plugin')
    const adnWingBanner = document.getElementById('adn_panel_container')

    header && header.remove()
    footer && footer.remove()
    nav && nav.remove()
    channelTalk && channelTalk.remove()
    adnWingBanner && adnWingBanner.remove()

    setIsRemoved(true)
  }, [])

  return { isRemoved }
}
