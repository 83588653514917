import classNames from 'classnames'
import useHideHeader from 'hooks/useHideHeader'

import { tabValues } from '../config'
import styles from '../StoreDetail.module.scss'

export default function Tab({ isSticky, tabRef, headerHeight, selectedTab, packageTypeCode }) {
  const { top } = useHideHeader()
  const handleScroll = (moveTarget) => {
    window.scrollTo({
      left: 0,
      top: document.querySelector(moveTarget).offsetTop - headerHeight - 50,
      behavior: 'smooth'
    })
  }

  return (
    <section
      ref={tabRef}
      className={classNames(styles.tabSection, isSticky && styles.fixed)}
      style={{ top }}
    >
      <ul>
        {tabValues.map(({ name, value }) => {
          if (value === 'curriculum' && packageTypeCode === 'REAL') return null
          return (
            <li key={value} className={classNames(selectedTab === value && styles.active)}>
              <button type="button" onClick={() => handleScroll(`.${value}-col`)}>
                {name}
              </button>
            </li>
          )
        })}
      </ul>
    </section>
  )
}
