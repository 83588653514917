import React from 'react'

import s from './Chat.module.scss'
import ChatForm from './ChatForm'

export default function ChatFooter({ addToFeed, postPromptMessage, isAiResponse }) {
  return (
    <section className={s.chatFooter}>
      <div className={s.footerInner}>
        <ChatForm
          addToFeed={addToFeed}
          postPromptMessage={postPromptMessage}
          isAiResponse={isAiResponse}
        />
      </div>
    </section>
  )
}
