import { ThemeProvider } from 'styled-components'

import theme from 'common/theme'
import Loading from 'components/common/Loading'

import ContentsSection from './components/ContentsSection'
import TopImageSection from './components/TopImageSection'
import TopInfoSection from './components/TopInfoSection'
import useStoreDetail from './hooks/useStoreDetail'
import styles from './StoreDetail.module.scss'

export default function StoreProduct() {
  const {
    isEntrancedUser,
    isEntrancedCheck,
    userInfo,
    classData,
    isLoading,
    isSticky,
    onScrollToTop,
    onChangeIsSticky
  } = useStoreDetail()

  return (
    <>
      {isLoading ? (
        <div className={styles.loadingBox}>
          <Loading />
        </div>
      ) : (
        <ThemeProvider theme={theme}>
          {!classData ? (
            <div className={styles.dataNone}>클래스가 존재하지 않습니다.</div>
          ) : (
            <div className={styles.container}>
              <TopImageSection
                packageImages={classData.packageDetailImages}
                hashTags={classData.hashTags}
              />
              <TopInfoSection
                isSticky={isSticky}
                classData={classData}
                userInfo={userInfo}
                isEntrancedUser={isEntrancedUser}
                isEntrancedCheck={isEntrancedCheck}
              />
              <ContentsSection
                isSticky={isSticky}
                classData={classData}
                userInfo={userInfo}
                onChangeIsSticky={onChangeIsSticky}
                isLoading={false}
              />
            </div>
          )}

          {isSticky && (
            <div className={styles.scrollToTop}>
              <button onClick={onScrollToTop}>top</button>
            </div>
          )}
        </ThemeProvider>
      )}
    </>
  )
}
