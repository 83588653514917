function getParameterByName(name) {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]')
  let regex = new RegExp('[\\?&]' + name + '=([^&#]*)'),
    results = regex.exec(window.location.search)
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '))
}

export function getUtm() {
  let utm = ''
  try {
    utm +=
      getParameterByName('utm_source') +
      '&' +
      getParameterByName('utm_medium') +
      '&' +
      getParameterByName('utm_campaign') +
      '&' +
      getParameterByName('utm_content')
    utm = utm.replace(/&&/g, '&').replace(/&+$/, '')
  } catch (e) {}
  return utm
}
export function formatDate(date) {
  if (date) {
    let getDate = date.split(' ')
    return getDate[0]
  } else {
    return null
  }
}
// Stagger 애니메이션
export class StaggerMotion {
  constructor(ref, interval = 200, cx, isOnce) {
    this.ref = ref
    this.interval = interval
    this.intervalId = null
    this.cx = cx
    this.isOnce = isOnce
  }

  staggerOn() {
    clearInterval(this.intervalId)
    const targets = this.ref.current ? this.ref.current.children : []
    let index = 1

    targets[0].classList.add(this.cx)

    this.intervalId = setInterval(() => {
      if (index === targets.length) {
        clearInterval(this.intervalId)
      } else {
        targets[index].classList.add(this.cx)
        index++
      }
    }, this.interval)
  }

  staggerOff() {
    const targets = this.ref.current ? [...this.ref.current.children] : []
    clearInterval(this.intervalId)
    if (!this.isOnce) {
      targets.forEach((ele) => {
        ele.classList.remove(this.cx)
      })
    }
  }
}

export function countDigit(obj, number) {
  obj.innerHTML = ''
  const numChars = number.split('')
  const numArray = []
  const setOfNumbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]

  for (let i = 0; i < numChars.length; i++) {
    if (setOfNumbers.includes(parseInt(numChars[i], 10))) {
      const digitContainer = document.createElement('span')
      digitContainer.className = 'digit-box'

      const digitSpan = document.createElement('span')
      digitSpan.className = 'digit' + numArray.length

      for (let j = 0; j < 10; j++) {
        const digit = document.createElement('span')
        digit.textContent = j
        digitSpan.appendChild(digit)
      }

      digitContainer.appendChild(digitSpan)
      obj.appendChild(digitContainer)

      numArray[numArray.length] = parseInt(numChars[i], 10)
    } else {
      const span = document.createElement('span')
      span.textContent = numChars[i]
      obj.appendChild(span)
    }
  }

  const increment = obj.querySelector('.digit-box').offsetHeight
  const speed = 2000

  for (let i = 0; i < numArray.length; i++) {
    const digitElement = obj.querySelector('.digit' + i)
    const animationDuration = Math.round(speed / (1 + i * 0.333))

    digitElement.style.top = -(increment * numArray[i]) + 'px'
    digitElement.style.transition = 'top ' + animationDuration + 'ms ease'
  }
}
