import React, { useState } from 'react';
import EditProfile from 'components/EditProfile';
import JoinComplete from 'components/JoinComplete';
import OverseasJoin from 'components/OverseasJoin';
import '../../../src/assets/styles/modal.scss';
import '../../../src/assets/styles/modalJoin.scss';

export default function ModalJoinOverseas({
  title,
  btnClose,
  handleCloseModal,
  overseasToken,
  tokenData,
}) {
  // step: JOIN, PROFILE, COMPLETE
  const [step, setStep] = useState('JOIN');
  const [profileAccessToken, setProfileAccessToken] = useState(null);
  const [signUpResultApiToken, setSignUpResultApiToken] = useState(null);
  const [userId, setUserId] = useState(null);

  const handleUserInfo = (token, id) => {
    setProfileAccessToken(token);
    setUserId(id);

    if (token) {
      setStep('PROFILE');
    }
  };

  const handleSignUpToken = (token) => {
    setSignUpResultApiToken(token);

    if (token) {
      setStep('COMPLETE');
    }
  };

  return (
    <div
      className={
        'modal-wrap' +
        (step === 'PROFILE'
          ? ' profile'
          : ' modal-form' + (step === 'JOIN' ? ' join' : ' complete'))
      }
    >
      <div className="dimmed" onClick={handleCloseModal}></div>
      <div className="modal-inner">
        {btnClose && (
          <button
            type="button"
            className="btn-modal-close"
            onClick={handleCloseModal}
          >
            <i className="blind">닫기</i>
          </button>
        )}
        <div className="modal-header">
          {step === 'JOIN' ? (
            <h3>
              <i className="blind">{title}</i>
            </h3>
          ) : step === 'PROFILE' ? (
            <i className="blind">프로필 수정</i>
          ) : step === 'COMPLETE' ? (
            <i className="blind">완료</i>
          ) : null}
        </div>
        {step === 'JOIN' ? (
          <OverseasJoin
            handleUserInfo={handleUserInfo}
            overseasToken={overseasToken}
            tokenData={tokenData}
          />
        ) : step === 'PROFILE' ? (
          <EditProfile
            token={profileAccessToken}
            userId={userId}
            handleSignUpToken={handleSignUpToken}
          />
        ) : step === 'COMPLETE' ? (
          <JoinComplete
            token={signUpResultApiToken}
            userId={userId}
            handleCloseModal={handleCloseModal}
          />
        ) : null}
      </div>
    </div>
  );
}
