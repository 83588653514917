import React from 'react';
import theme from 'common/theme';
import styled from 'styled-components';
import ico_info from '../../assets/images/ico_info_30x30.png';

export default function BoardListItem({ text }) {
  return (
    <>
      <InfoFullBox>
        <theme.SmInnerBox>
          <p>{text}</p>
        </theme.SmInnerBox>
      </InfoFullBox>
    </>
  );
}

const InfoFullBox = styled.section`
  background-color: #ececf6;
  p {
    padding: 1.6rem 2rem 1.4rem 3.6rem;
    font-size: 1.4rem;
    color: #717188;
    background: url(${ico_info}) no-repeat 1rem 1.45rem / 1.8rem auto;
  }
  @media ${({ theme }) => theme.device.mobile} {
    p {
      padding: 3.2rem 4rem 2.8rem 8.4rem;
      font-size: 2.5rem;
      background-position: 4.2rem 3rem !important;
      background-size: 3rem auto !important;
    }
  }
`;
