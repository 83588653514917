import styled, { ThemeProvider } from 'styled-components'

import theme from 'common/theme'

import bg_plag_premium from 'assets/images/bg_plag_premium.png'

export default function ItemStore({ storeData, title, subTitle }) {
  return (
    <ThemeProvider theme={theme}>
      {/* {console.log('storeData????', storeData)} */}
      <Title>
        {title && title}
        <span>{subTitle && subTitle}</span>
      </Title>
      <Product className="class-items">
        {storeData &&
          storeData.map((item, itemIndex2) => {
            return (
              <li key={itemIndex2}>
                <a href={item.productUrl} target={`_${item.linkTarget.toLowerCase()}`}>
                  <span className={item.iconType.toLowerCase()} />
                  <img src={item.thumbnailPath} alt={item.categoryName} />
                  <ProductTxt>
                    <strong>{item.productName}</strong>
                    <p>
                      {item.text1} <br />
                      {item.text2}
                    </p>
                  </ProductTxt>
                </a>
              </li>
            )
          })}
      </Product>
    </ThemeProvider>
  )
}
const Product = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(auto-fit, minmax(3rem, auto));
  grid-gap: 6rem 3rem;

  li {
    position: relative;

    a {
      display: block;
    }

    img {
      width: 100%;
      background: #ddd;
      border-radius: 1rem;
    }

    span {
      &:after {
        position: absolute;
        top: 1rem;
        left: 1rem;
        height: 3.2rem;
        padding: 0 0.7rem;
        font-size: 1.6rem;
        font-weight: 700;
        line-height: 2.1;
        border-radius: 0.5rem;
        color: #fff;
        z-index: 1;
      }

      &.new:after {
        content: 'NEW';
        background: #17c624;
      }
      &.premium:after {
        content: 'PREMIUM';
        padding-right: 1.2rem;
        background: url(${bg_plag_premium}) no-repeat center / 100%;
        color: #f09000;
      }
      &.hot:after {
        content: 'HOT';
        background: #ff5647;
      }
      &.best:after {
        content: 'BEST';
        background: #269cff;
      }
      &.event:after {
        content: 'EVENT';
        background: #925cf6;
      }
      &.none {
        border: 0;
        background: none;
      }
    }

    &:first-child,
    &:nth-child(odd) {
      margin-left: 0;
    }
  }
  @media ${({ theme }) => theme.device.mobile} {
    grid-template-columns: 1fr;
    grid-gap: 4rem;
    width: unset;
    li {
      width: unset;
      margin-left: 0;

      a {
        display: flex;
      }

      img {
        width: 49%;
        height: 100%;
        overflow: hidden;
      }

      span {
        &.premium:after {
          padding-right: 1.4rem;
        }
      }
    }
  }
`
const ProductTxt = styled.div`
  margin: 3rem 0 0;

  strong {
    font-size: 2rem;
    font-weight: 700;
    color: #000;
  }

  p {
    margin: 1rem 0 0;
    font-size: 1.6rem;
    font-weight: 400;
    color: #717188;
  }

  @media ${({ theme }) => theme.device.mobile} {
    display: inline-block;
    margin: 0 0 0 3rem;
    flex: 47% 1 0;

    strong {
      font-size: 2.8rem;
    }

    p {
      font-size: 2rem;
    }
  }
`

const Title = styled.h3`
  margin-left: 1rem;
  font-size: 4rem;
  font-weight: 900;
  letter-spacing: -1px;
  min-width: 0;

  span {
    display: block;
    margin: 1.4rem 0 0;
    font-size: 2rem;
    font-weight: 500;
  }
  @media ${({ theme }) => theme.device.mobile} {
    margin: 0 0 3rem 0;
    font-size: 3.2rem;

    span {
      font-size: 2rem;
    }
  }
`
