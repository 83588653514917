import { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'

import styled, { css, keyframes } from 'styled-components'

import { YAFIT_ALLINONE_LINK } from 'common/config'

import IcoArrow from 'assets/images/ico_arrow_60x60w.png'

export default function BrandValueSection() {
  const brandValueRef = useRef(null)
  const [activeSection, setActiveSection] = useState(null)

  const scrollCheck = () => {
    if (!brandValueRef.current) return
    if (
      0 < brandValueRef.current.getBoundingClientRect().bottom &&
      brandValueRef.current.getBoundingClientRect().top < 800
    ) {
      setActiveSection('value')
    } else {
      setActiveSection(null)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', scrollCheck)

    return () => {
      window.removeEventListener('scroll', scrollCheck)
    }
  }, [])

  return (
    <>
      <section ref={brandValueRef}>
        <InnerCol>
          <DescBox>
            <InnerBox>
              <AnimationText delay={activeSection === 'value' && '0.1'}>
                변화의 시작은 매우 쉽습니다.
              </AnimationText>
              <AnimationText delay={activeSection === 'value' && '0.4'}>
                올바른 선택 하나로 당신도 지금 시작할 수 있습니다.
              </AnimationText>
              <AnimationText delay={activeSection === 'value' && '0.7'}>
                야나두와 함께 성장하는 나로 시작하세요.
              </AnimationText>
            </InnerBox>
          </DescBox>
          <ValueBox>
            <InnerBox>
              <p>
                야나두와 함께 하는
                <span>그 이상의 가치 +</span>
              </p>
              <ul>
                <li>
                  <Link to="/promotion-landing/bestAwards">
                    하루 10분 영어
                    <strong>야나두 영어</strong>
                  </Link>
                </li>
                <li>
                  <a href={YAFIT_ALLINONE_LINK}>
                    수만명이 함께 달리는<strong>야나두 피트니스</strong>
                  </a>
                </li>
                <li>
                  <Link to="/store/list">
                    새로운 성공 로드맵<strong>야나두 클래스</strong>
                  </Link>
                </li>
              </ul>
            </InnerBox>
          </ValueBox>
        </InnerCol>
      </section>
    </>
  )
}

const TextFade = keyframes`
  0% {
    transform: translateY(20%);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
`

const InnerCol = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;
`

const InnerBox = styled.div`
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 3rem;
  box-sizing: border-box;

  @media ${({ theme }) => theme.device.mobile} {
    padding: 0 7.2rem;
  }
`

const DescBox = styled.div`
  padding: 29.5rem 0 18rem;
  background-color: #f7f7fc;
  text-align: center;

  @media ${({ theme }) => theme.device.mobile} {
    padding: 29.6rem 0 22rem;
  }
`

const AnimationText = styled.p`
  font-weight: 700;
  font-size: 4rem;
  line-height: 1.4;
  word-break: keep-all;
  opacity: 0;

  ${(props) =>
    props.delay &&
    css`
      animation: ${TextFade} 1s ${(props) => `${props.delay}s` || '0s'} linear forwards;
    `}

  @media ${({ theme }) => theme.device.mobile} {
    font-size: 4.8rem;
  }
`

const ValueBox = styled.div`
  padding: 7.2rem 0;
  color: #fff;
  background-color: #000;

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
  }

  p {
    flex: 1 1 auto;
    font-weight: 700;
    font-size: 2.8rem;
    line-height: 1.4;

    > span {
      display: block;
      color: #ffb900;
    }
  }

  ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 0 1 auto;

    li {
      flex: 1 0 auto;
      min-width: 21.2rem;
      border-left: 1px solid #717188;

      & > a {
        display: block;
        padding-left: 1.6rem;
        font-size: 1.8rem;
        line-height: 1.7;
        color: #b7b7c8;
        box-sizing: border-box;

        & > strong {
          display: block;
          font-size: 2.4rem;
          color: #fff;

          &::after {
            content: '';
            display: inline-block;
            width: 2rem;
            height: 2rem;
            margin-left: 0.4rem;
            background: url(${IcoArrow}) no-repeat 50% 0/ 100% auto;
            image-rendering: -webkit-optimize-contrast;
            image-rendering: -ms-optimize-contrast;
          }
        }
      }
    }
  }

  @media ${({ theme }) => theme.device.mobile} {
    padding: 9.6rem 0;

    > div {
      flex-wrap: wrap;
    }

    p {
      flex: 1 1 100%;
      display: block;
    }

    ul {
      width: 100%;
      margin-top: 6.4rem;

      li {
        min-width: 21.2rem;

        & > a {
          padding-left: 1.6rem;
          font-size: 2rem;

          & > strong {
            font-size: 2.4rem;
          }
        }
      }
    }
  }
`
