import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';

SwiperCore.use([Navigation, Pagination]);

const ReviewItem = React.memo(({ list, type }) => {
  return (
    <li>
      <dl className="user-thumb-box">
        <dt style={{ backgroundImage: `url(` + list.userProfileImageUrl + `)` }}>
          <i className="blind">프로필 이미지</i>
        </dt>
        <dd>
          <p>
            <strong>{list.userName}</strong>
          </p>
          <p>
            <span>{list.registDate}</span>
            <span></span>
          </p>
        </dd>
      </dl>
      {type === 'BOARD' ? (
        <>
          {list.eventApplyBoard.eventApplyBoardAttachFiles &&
          list.eventApplyBoard.eventApplyBoardAttachFiles.length > 1 ? (
            <div className="review-list-area">
              <Swiper
                className="swiper-container swiper-review"
                spaceBetween={5}
                pagination={{ clickable: true }}
              >
                {list.eventApplyBoard.eventApplyBoardAttachFiles.map((item, idx) => (
                  <SwiperSlide key={idx}>
                    <span
                      className="review-img-box"
                      style={{ backgroundImage: `url(${item.attachFilePath})` }}
                    ></span>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          ) : (
            list.eventApplyBoard.eventApplyBoardAttachFiles.map((item, idx) => (
              <div key={idx} className="review-list-area">
                <span
                  className="review-img-box"
                  style={{ backgroundImage: `url(${item.attachFilePath})` }}
                ></span>
              </div>
            ))
          )}
          <p>
            <em>{list.eventApplyBoard.content}</em>
          </p>
        </>
      ) : (
        <p>
          <em>{list.eventApplyComment.comment}</em>
        </p>
      )}
    </li>
  );
});

export default ReviewItem;
