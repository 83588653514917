const sortOrderValues = [
  {
    title: '인기순',
    value: 'POPULAR'
  },
  {
    title: '최신순',
    value: 'RECENT'
  }
]

export { sortOrderValues }
