import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router'

import { getBannerList } from 'api/storeApi'
import useGetClassCategory from 'hooks/useGetClassCategory'
import useGetCurationList from 'hooks/useGetCurationList'

import { scrollMove } from 'common/common'
import * as config from 'common/config'
import useGetStoreList from 'components/Store/hooks/useGetStoreList'

import { bannerCategoryValues } from '../config'

export default function useStoreCategory() {
  const { categorySeq } = useParams()

  const [categoryTitle, setCategoryTitle] = useState('전체보기')
  const [sort, setSort] = useState('POPULAR')
  const [category, setCategory] = useState('')
  const [sortOrder, setSortOrder] = useState(0)
  const [bannerList, setBannerList] = useState([])

  const { data: storeData, isLoading, isEmpty } = useGetStoreList(sort, category)
  const cateList = useGetClassCategory(categorySeq.toUpperCase())
  const curationList = useGetCurationList(categorySeq.toUpperCase(), sortOrder)

  const handleCategory = useCallback(
    (cate, name, idx) => {
      let categoryCodes = ''

      if (cate === 'all') {
        let categoryAll = []
        cateList.forEach((item) => {
          categoryAll.push(...item.categoryCodeList)
        })
        categoryAll.forEach((item) => {
          categoryCodes += '&categoryCodes=' + item
        })
      } else {
        // category
        cate.forEach((item) => {
          categoryCodes += '&categoryCodes=' + item
        })
      }
      setSortOrder(idx)
      setCategory(categoryCodes)
      setCategoryTitle(name)
    },
    [cateList]
  )

  const handleGetBannerList = useCallback(async () => {
    try {
      const list = await getBannerList('CLASS', bannerCategoryValues[categorySeq])
      setBannerList(list)
    } catch (error) {
      alert(config.MESSAGE['common-error'])
    }
  }, [categorySeq])

  useEffect(() => {
    handleGetBannerList()
  }, [categorySeq, handleGetBannerList])

  useEffect(() => {
    scrollMove(0, 0)
  }, [category])

  useEffect(() => {
    if (cateList && cateList.length > 0) handleCategory('all', '전체')
    if (cateList.length < 1) setCategory([])
  }, [cateList, handleCategory])

  return {
    categorySeq,
    cateList,
    handleCategory,
    categoryTitle,
    curationList,
    isLoading,
    storeData: storeData || [],
    setSort,
    sort,
    isEmpty,
    bannerList
  }
}
