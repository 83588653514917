import React, { useState } from 'react'
import Lottie from 'react-lottie'

import AiTravelTranslateBtn from 'pages/aiTalk/components/AiTravelTranslateBtn'
import ButtonAudio from 'pages/aiTalk/components/ButtonAudio'
import LottieData from 'pages/aiTalk/lottieData.json'

import s from './Chat.module.scss'
import { AI_TRAVEL_CONFIG } from '../config'

export default function MessageAi({ message, metaInfo, userSeq }) {
  const { title, roleAiEng } = metaInfo
  const { TRAVEL_PROFILE_IMAGE } = AI_TRAVEL_CONFIG

  // translate
  const [translateText, setTranslateText] = useState(null)
  const [transLoading, setTransLoading] = useState(false)
  const [translated, setTranslated] = useState(false)

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: LottieData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  return (
    <div className={s.aiMessage}>
      <div className={s.msgThumb}>
        <img src={TRAVEL_PROFILE_IMAGE} alt={title} />
      </div>
      <dl>
        <dt className={s.msgName}>{roleAiEng}</dt>
        <dd className={s.message}>
          {message}
          <div className={s.btnGroup}>
            <ButtonAudio text={message} userSeq={userSeq} />
            <AiTravelTranslateBtn
              type={'AI'}
              message={message}
              translated={translated}
              translateText={translateText}
              setTransLoading={setTransLoading}
              setTranslated={setTranslated}
              setTranslateText={setTranslateText}
              userSeq={userSeq}
            />
          </div>
        </dd>
        {transLoading && !translateText && (
          <dd className={s.translateText}>
            <div className={s.lottieBox}>
              <Lottie options={defaultOptions} />
            </div>
          </dd>
        )}
        {translateText && translated && <dd className={s.translateText}>{translateText}</dd>}
      </dl>
    </div>
  )
}
