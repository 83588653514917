import React, { useState, useEffect, useCallback } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from 'react-router-dom'

import ModalPortal from 'ModalPortal'
import AuthService from 'services/authService'
import useModalLogin from 'store/useModalLogin'
import SwiperCore, { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import api from 'common/api'
import * as config from 'common/config'
import AlertModal from 'components/modal/AlertModal'

import 'swiper/swiper.scss'
import 'swiper/components/pagination/pagination.scss'

SwiperCore.use([Pagination])

export default function CourseItem({ title, subTitle, type, subType }) {
  const navigate = useNavigate()
  const [courseData, setCourseData] = useState(null)
  const [modalopenAlert, setModalopenAlert] = useState(false)
  const { setIsModalLogin } = useModalLogin()

  const getCourseData = (type, subType) => {
    api
      .get(`/v2/store/sale/package-class/${type}/${subType}/list`)
      .then((response) => {
        setCourseData(response.data.data.content)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const directOrder = (packageSeq) => {
    // 주문번호 재셋팅을 위한 productParam 값 셋팅.
    window.sessionStorage.setItem('productParam', JSON.stringify({ param: packageSeq, orderReset: false }))

    api
      .post(`/payment/v2/order/${packageSeq}`)
      .then((response) => {
        if (response.data.meta?.code === 200) {
          navigate(`/mypage/order/cart/detail/${response.data.data}`)
        } else {
          showErrorMessage(response)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const addComma = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  const showErrorMessage = (response) => {
    if (response.data.meta.message) {
      alert(response.data.meta.message)
    } else {
      alert(config.MESSAGE['common-error'])
    }
  }

  const handleOpenModalLogin = useCallback((e) => {
    document.body.classList.add('modal-open')
    setIsModalLogin(true)
  }, [])

  const handleOpenModalAlert = useCallback((e) => {
    document.body.classList.add('modal-open')
    setModalopenAlert(true)
    setDataLayer()
  }, [])

  const handleCloseModalAlert = useCallback((e) => {
    document.body.classList.remove('modal-open')
    setModalopenAlert(false)
  }, [])

  const moveCart = () => {
    window.location.href = '/mypage/order/cart'
  }

  const setDataLayer = () => {
    window.dataLayer.push({
      event: 'detailpage_add_to_cart'
    })
  }

  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1025 })

    return isDesktop ? children : null
  }

  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 1024 })

    return isMobile ? children : null
  }

  useEffect(() => {
    if (type && subType) {
      getCourseData(type, subType)
    }
  }, [])
  return (
    <>
      <Desktop>
        <div className="inner-col">
          {title && <h3>{title}</h3>}
          <p>{subTitle}</p>
          <div>
            <ul className="course-lists">
              {courseData &&
                courseData.map((course, idx) => {
                  return (
                    <li key={idx}>
                      <div
                        className="contents"
                        style={{
                          backgroundImage: `url(${course.thumbnailPath})`
                        }}
                      >
                        <div className="txt-area">
                          <div className="level-txt">
                            <span className="level">{course.subTitle}</span>
                            <span>
                              수강기간 {course.classPeriod}일/총 {course.lectureCount}강
                            </span>
                          </div>
                          <strong>{course.packageName}</strong>
                          <div
                            className="course-sub-tit"
                            dangerouslySetInnerHTML={{
                              __html: course.detail
                            }}
                          ></div>
                        </div>
                      </div>
                      <button
                        onClick={
                          !AuthService.getUserInfo()
                            ? handleOpenModalLogin
                            : () => {
                                directOrder(course.packageSeq)
                              }
                        }
                      >
                        {addComma(course.salePrice)}원 수강 신청
                      </button>
                    </li>
                  )
                })}
            </ul>
          </div>
        </div>
      </Desktop>
      <Mobile>
        <>
          {title && <h3>{title}</h3>}
          <p>{subTitle}</p>
          <Swiper pagination={true} slidesPerView={1.2} spaceBetween={20} className="mySwiper">
            {courseData &&
              courseData.map((course, idx) => {
                return (
                  <div key={String(idx)}>
                    <SwiperSlide>
                      <div className="contents">
                        <div className="txt-area">
                          <div className="level-txt">
                            <span className="level">{course.subTitle}</span>
                            <span>
                              수강기간 {course.classPeriod}일/총 {course.lectureCount}강
                            </span>
                          </div>
                          <strong>{course.packageName}</strong>
                          <div
                            className="course-sub-tit"
                            dangerouslySetInnerHTML={{
                              __html: course.detail
                            }}
                          ></div>
                        </div>
                        <span
                          className="course-img"
                          style={{
                            backgroundImage: `url(${course.thumbnailPath})`
                          }}
                        ></span>
                      </div>
                      <button
                        onClick={
                          !AuthService.getUserInfo()
                            ? handleOpenModalLogin
                            : () => {
                                directOrder(course.packageSeq)
                              }
                        }
                      >
                        {addComma(course.salePrice)}원 수강 신청
                      </button>
                    </SwiperSlide>
                  </div>
                )
              })}
          </Swiper>
        </>
      </Mobile>

      {modalopenAlert ? (
        <ModalPortal>
          <AlertModal btnGroup msg={'상품이 장바구니에 추가되었습니다. 장바구니로 이동하시겠습니까?'} handleCloseModal={handleCloseModalAlert} handleConfirmClose={moveCart} />
        </ModalPortal>
      ) : null}
    </>
  )
}
