export const STORE_DETAIL_CDN_HOST =
  'https://english.yanadoocdn.com/upload/yanadoo/new/store/detail'

export const tabValues = [
  {
    name: '상품소개',
    value: 'product'
  },
  {
    name: '수강생 후기',
    value: 'review-list'
  },
  {
    name: '커리큘럼',
    value: 'curriculum'
  },
  {
    name: '상품문의',
    value: 'question'
  }
]
