import React from 'react'

import s from './RelatedFaq.module.scss'
import SubContentsItem from './SubContentsItem'

export default function RelatedFaq({ subContents, handleSubContent }) {
  return (
    <dl className={s.relatedFaq}>
      <dt>연관 질문</dt>
      {subContents.map((sub, index) => (
        <SubContentsItem
          key={index}
          title={sub.title}
          contents={sub.contents}
          handleSubContent={handleSubContent}
        />
      ))}
    </dl>
  )
}
