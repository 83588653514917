import React, { useState, useCallback, useEffect } from 'react';
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import ModalPortal from '../../ModalPortal';
import ModalLearningInquiry from '../../components/modal/ModalLearningInquiry';
import Lnb from '../../components/Lnb';
import NoContent from '../../components/NoContent';
import api from 'common/api';
import * as config from 'common/config';
import icoImg from '../../assets/images/ico_no_mobile_420x420.png';

export default function QnaDetail() {
  const { currentSeq } = useParams();
  const { state } = useLocation();
  const studySeq = state && state.studySeq;

  const [qnaDetailData, setQnaDetailData] = useState([]);
  const [modalopen, setModalopen] = useState(false);

  const handleOpenModal = useCallback((e) => {
    document.body.classList.add('modal-open');
    setModalopen(true);
  }, []);

  const handleCloseModal = useCallback((e) => {
    document.body.classList.remove('modal-open');
    setModalopen(false);
  }, []);

  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1024 });

    return isDesktop ? children : null;
  };

  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 1024 });

    return isMobile ? children : null;
  };

  function formatDate(date) {
    if (date) {
      let getDate = date.split(' ');
      return getDate[0];
    } else {
      return null;
    }
  }

  const getQnaDetailData = () => {
    api
      .get('/v2/lecture-question/' + currentSeq)
      .then((response) => {
        setQnaDetailData(response.data.data);
      })
      .catch((e) => {
        alert(config.MESSAGE['common-error']);
      });
  };

  useEffect(() => {
    getQnaDetailData();
  }, [currentSeq]);

  return (
    <>
      <div className="qna-col">
        <div className="inner-col">
          <div className="container mypage-sub">
            <Lnb />
            <Desktop>
              {qnaDetailData && (
                <section className="list-detail-col">
                  <div className="title-col">
                    <strong>학습문의</strong>
                  </div>
                  <div className="detail-title-area">
                    <div className="tit-flex-form">
                      <strong className="sub-tit">
                        {qnaDetailData.classTitle}
                      </strong>
                      <div className="info">
                        <strong
                          className={
                            'check' +
                            (qnaDetailData.replyStatus === 'Y'
                              ? ' completed'
                              : '')
                          }
                        >
                          {qnaDetailData.replyStatus === 'Y'
                            ? '답변완료'
                            : '답변대기'}
                        </strong>
                        <span className="date">
                          {formatDate(qnaDetailData.registDate)}
                        </span>
                      </div>
                    </div>
                    <p className="main-tit">{qnaDetailData.lectureTitle}</p>
                  </div>
                  <div className="detail-content">
                    <div className="detail-question">
                      <pre
                        dangerouslySetInnerHTML={{
                          __html: qnaDetailData.contents,
                        }}
                      ></pre>
                      {qnaDetailData.questionFilePath && (
                        <img src={qnaDetailData.questionFilePath} alt="" />
                      )}
                    </div>
                    {qnaDetailData.replyStatus === 'Y' ? (
                      <div className="detail-answer">
                        <div className="thumb-area">
                          <span
                            className="thumb"
                            style={{
                              backgroundImage:
                                'url(' +
                                qnaDetailData.answer.answerThumbnailPath +
                                ')',
                            }}
                          >
                            <i className="blind">썸네일</i>
                          </span>
                          <div className="desc">
                            <strong>학습관리자</strong>
                            <p>{qnaDetailData.answer.answerDate}</p>
                          </div>
                        </div>
                        <pre
                          className="answer-txt"
                          dangerouslySetInnerHTML={{
                            __html: qnaDetailData.answer.answerContent,
                          }}
                        ></pre>
                        {qnaDetailData.answer.answerFilePath && (
                          <img
                            src={qnaDetailData.answer.answerFilePath}
                            alt=""
                          />
                        )}
                      </div>
                    ) : null}
                  </div>

                  <div className="list-btn-col">
                    <div className="inner-col">
                      <Link
                        to={'/myclass/detail/' + studySeq}
                        state={{ tabMenu: 2 }}
                        className="btn btn-go-list"
                      >
                        목록보기
                      </Link>
                    </div>
                  </div>
                </section>
              )}
            </Desktop>
            <Mobile>
              <div className="class-empty myclass">
                <NoContent
                  icoImg={icoImg}
                  titleMsg={
                    'PC버전과 야나두앱에서만 이용할 수 있는 서비스입니다.'
                  }
                />
              </div>
            </Mobile>
          </div>
        </div>
      </div>
      {modalopen ? (
        <ModalPortal>
          <ModalLearningInquiry
            qnaData={qnaDetailData}
            btnClose
            handleCloseModal={handleCloseModal}
          />
        </ModalPortal>
      ) : null}
    </>
  );
}
