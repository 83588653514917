import React, { useRef, useState } from 'react'

import classNames from 'classnames'
import useConsultation from 'hooks/useConsultation'
import useValidation from 'hooks/useValidation'
import { v4 as uuid } from 'uuid'

import alertMessage from 'common/alertMessage'
import DefaultInput from 'components/form/DefaultInput'
import FormInterval from 'components/form/FormInterval'
import PhoneCounselTime from 'components/form/PhoneCounselTime'
import PhoneInput from 'components/form/PhoneInput'
import { COUNSEL_TIME, DEVICE_OPTION } from 'components/promotion/dbCollectionForm/config'
import ModalPrivacyAgree from 'components/promotion/modal/ModalPrivacyAgree'
import ModalWrap from 'components/promotion/modal/ModalWrap'
import { getUtm } from 'components/promotion/utile/utile'
import ResponsiveImage from 'components/ResponsiveImage'

import s from './ModalCounsel.module.css'

export default function ModalCounsel({
  title,
  formName,
  banner,
  content,
  pCategory,
  btnColor = '#000',
  btnText = '상담 예약하기',
  btnBgColor,
  btnBg,
  onClose,
  eventName,
  addContent = false,
  response,
  counselTime = COUNSEL_TIME,
  onSuccess
}) {
  const nameRef = useRef(null)
  const hp2Ref = useRef(null)
  const hp3Ref = useRef(null)
  const counselTimeRef = useRef(null)
  const [device, setDevice] = useState('Apple 풀패키지')
  const [name, setName] = useState('')
  const [phone, setPhone] = useState({ hp1: '010', hp2: '', hp3: '' })
  const [time, setTime] = useState(counselTime[0])
  const [agree, setAgree] = useState({ all: false, privacy: false, sms: false })
  const [isShowPrivacyModal, setIsShowPrivacyModal] = useState(false)
  const [disabled, setDisabled] = useState(false)

  const { checkIsEmpty, checkMinLength, onKeyUpKoreanInput } = useValidation()
  const { onPostConsultation } = useConsultation()

  const ratio = Math.floor(
    banner && banner.width
      ? banner.width > banner.height
        ? (banner.height / banner.width) * 100
        : (banner.width / banner.height) * 100
      : 100
  )

  const validationName = (e) => {
    setName(onKeyUpKoreanInput(e.target.value, 'FORM_USERNAME_VALIDATION'))
  }

  const handleChangePhone = (e) => {
    const { name, value } = e.target
    setPhone({
      ...phone,
      [name]: value
    })
  }

  const handleChangeTime = (e) => {
    setTime(e.target.value)
  }

  const handleAgree = (e) => {
    const agreeKey = e.target.name
    const checked = e.target.checked

    if (agreeKey === 'all') {
      return setAgree({ all: checked, privacy: checked, sms: checked })
    }

    if (agreeKey !== 'all') {
      if (!checked) {
        return setAgree({ ...agree, all: checked, [agreeKey]: checked })
      }

      if (!agree.all && checked) {
        if (agree.sms || agree.privacy) {
          return setAgree({ ...agree, all: checked, [agreeKey]: checked })
        }
      }
    }

    setAgree({ ...agree, [agreeKey]: checked })
  }

  const handleCheckValidation = () => {
    if (
      !checkIsEmpty(nameRef, 'FORM_USERNAME_REQUIRED') ||
      !checkIsEmpty(hp2Ref, 'FORM_PHONE_REQUIRED') ||
      !checkIsEmpty(hp3Ref, 'FORM_PHONE_REQUIRED') ||
      !checkIsEmpty(counselTimeRef, 'FORM_COUNSEL_REQUIRED') ||
      !checkMinLength(hp2Ref, 3, 'FORM_PHONE_VALIDATION') ||
      !checkMinLength(hp3Ref, 4, 'FORM_PHONE_VALIDATION')
    ) {
      return false
    }
    if (!agree.privacy) {
      alert(alertMessage.FORM_PRIVACY_REQUIRED)
      return false
    }

    return true
  }

  const onSubmit = () => {
    if (handleCheckValidation()) {
      let formData = {
        category: pCategory,
        content: content ? content : addContent ? `기기옵션 선택 : ${device}` : '',
        counselTime: time,
        email: '',
        etc: '',
        phone: `${phone.hp1}-${phone.hp2}-${phone.hp3}`,
        privacyAgreeYn: agree.privacy ? 'Y' : 'N',
        smsAgreeYn: agree.sms ? 'Y' : 'N',
        userAge: '',
        userName: name,
        utm: getUtm() || ''
      }

      setDisabled(true)
      onPostConsultation(formData, (res) => {
        const message = res.data.meta.message
        const duplication = res.data.data
        if (message === 'SUCCESS') {
          if (onSuccess) {
            onSuccess(duplication)
          }
          onClose(true)
        } else {
          alert('통신장애가 발생되었습니다.')
        }
      })
    }
  }

  const handleIsAgreePrivacyToggle = () => {
    setIsShowPrivacyModal((prev) => !prev)
  }

  return (
    <>
      <div
        className={classNames(
          s['pop-temp'],
          s['pop-counseling'],
          s['counsel-pop'],
          'modal-counsel'
        )}
      >
        <div className={s['pop-ynd-bg']} onClick={onClose}></div>
        <form name={formName} autoComplete="off">
          <div className={s['pop-ynd-content']}>
            {response ? (
              <ResponsiveImage pc={banner.pc} mo={banner.mo} alt={eventName} maxWidth={1024} />
            ) : (
              banner && (
                <div
                  className={s.bannerArea}
                  style={{ backgroundImage: `url(${banner.src})`, paddingBottom: `${ratio}%` }}
                >
                  <span>{title ? title : '배너 이미지'}</span>
                </div>
              )
            )}
            <div className={s.formContainer}>
              {title && (
                <h3>
                  {title.map((item, index) => (
                    <React.Fragment key={uuid()}>
                      {item}
                      {index + 1 < title.length && <br />}
                    </React.Fragment>
                  ))}
                </h3>
              )}
              <div style={title ? {} : { marginTop: 0 }}>
                {addContent && (
                  <dl>
                    <dt>
                      기기 옵션 <p>(택 1)</p>
                    </dt>

                    <dd>
                      <select
                        name="counseltime"
                        className={s['consulting-time']}
                        onChange={(e) => setDevice(e.target.value)}
                      >
                        {DEVICE_OPTION.map((item, index) => {
                          return (
                            <option key={`${item + index}`} value={item}>
                              {item}
                            </option>
                          )
                        })}
                      </select>
                      <p>*선택한 기기 옵션에 대한 상담과 혜택이 제공됩니다</p>
                    </dd>
                  </dl>
                )}
                <DefaultInput
                  label="이름"
                  inputProps={{
                    ref: nameRef,
                    value: name,
                    onChange: validationName
                  }}
                />
                <FormInterval marginTop="1rem" />
                <PhoneInput
                  phone={phone}
                  hp2Ref={hp2Ref}
                  hp3Ref={hp3Ref}
                  onChange={handleChangePhone}
                />
                <FormInterval marginTop="1rem" />
                <PhoneCounselTime
                  counselTime={counselTime}
                  counselTimeRef={counselTimeRef}
                  value={time}
                  onChange={handleChangeTime}
                />
              </div>
              <ul className={`${s['agree-box']} ${agree.all ? s.active : null}`}>
                <li>
                  <label>
                    <input
                      type="checkbox"
                      id="all"
                      name="all"
                      className={s['all-checkbox']}
                      onChange={handleAgree}
                      checked={agree.all}
                    />
                    <span>전체동의</span>
                  </label>
                </li>
                <li>
                  <label>
                    <input
                      type="checkbox"
                      id="care_agree"
                      name="privacy"
                      className={s['checkes']}
                      onChange={handleAgree}
                      checked={agree.privacy}
                    />
                    <span>개인정보 수집·이용 동의[필수]</span>
                  </label>
                  <button
                    type="button"
                    className={s['btn-agree-detail']}
                    onClick={handleIsAgreePrivacyToggle}
                  >
                    [약관보기]
                  </button>
                </li>
                <li>
                  <label>
                    <input
                      type="checkbox"
                      id="sms_care_agree"
                      name="sms"
                      className={s['checkes']}
                      onChange={handleAgree}
                      checked={agree.sms}
                    />
                    <span>SMS 광고 및 마케팅 이용 동의[선택]</span>
                  </label>
                </li>
              </ul>
            </div>
            <button
              type="button"
              className={classNames(s['btn-counsel-pop-submit'], 'btn-counsel-pop-submit')}
              onClick={onSubmit}
              style={{ background: btnBgColor || btnBg, color: btnColor }}
              disabled={disabled}
            >
              {btnText}
            </button>
          </div>
        </form>
      </div>
      {isShowPrivacyModal && (
        <ModalWrap>
          <ModalPrivacyAgree toggle={handleIsAgreePrivacyToggle} />
        </ModalWrap>
      )}
    </>
  )
}
