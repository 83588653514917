import React from "react";
import "../../assets/styles/notice.scss";

export default function DetailPolicyFacebook() {
  return (
    <>
      <div className="refund-col">
        <div className="inner-col">
          <div className="container mypage-sub">
            <section className="list-detail-col">
              <div className="title-col">
                <strong>개인정보 수집 및 이용동의</strong>
              </div>
              <div className="detail-title-area">
                <div className="tit-flex-form">
                  <strong className="sub-tit black" style={{ marginTop: "0" }}>
                    개인정보 수집 및 이용동의
                  </strong>
                  <div className="info">
                    <span className="date" />
                  </div>
                </div>
              </div>
              <div className="policy-content">
                <p>
                  야나두는 회원가입, 서비스 제공, 장학금제도에 따른 개인 실명과 계좌정보, 기타상담 등을 위해 개인정보를 수집하고 있으며, 그리고 보다 다양한 서비스 제공을 위하여
                  아래와 같이 회원의 개인정보를 수집, 이용합니다.
                </p>
                <table>
                  <colgroup>
                    <col width="30%" />
                    <col width="30%" />
                    <col width="40%" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>이용목적</th>
                      <th>수집항목</th>
                      <th>보유기간</th>
                    </tr>
                    <tr>
                      <td>SNS 계정을 이용한 회원가입</td>
                      <td>(필수)이메일, 프로필정보</td>
                      <td>
                        <em>
                          SNS로부터 제공받은 정보는 회원가입 단계에서만 이용하며, 회원가입이 진행되지 않는 경우 즉시 파기합니다. 또한, 당사의 회원가입 절차를 통해 고객이 직접
                          동의한 경우에 한하여 개인정보를 보관합니다
                        </em>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p>
                  개인정보 수집 및 이용동의에 대하여 동의를 거부할 권리가 있으며, 거부할 경우 서비스 이용이 제한됩니다. <br />
                  단, 선택항목의 경우에는 거부 시 회원가입 및 서비스 이용에 문제가 없습니다.
                </p>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
}
