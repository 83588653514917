import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import AuthService from 'services/authService';
import styled from 'styled-components';
import Loading from 'components/common/Loading';

const Logout = () => {
  const location = useLocation();
  const currentPath = location.search.split('returnUrl=')[1];
  const LogoutAction = () => {
    AuthService.signOut(window.location.replace(decodeURIComponent(currentPath)));
  };
  useEffect(() => {
    LogoutAction();
  }, []);

  return (
    <LoadingBox>
      <Loading />
    </LoadingBox>
  );
};

export default Logout;

const LoadingBox = styled.section`
  position: relative;
  height: 60rem;
`;
