import React from 'react'

import MessageAi from './MessageAi'
import MessageUser from './MessageUser'

export default function ChatFeedItem({ type, message, userSeq, pageType }) {
  if (type === 'AI') {
    return <MessageAi message={message} userSeq={userSeq} pageType={pageType} />
  } else {
    return <MessageUser message={message} userSeq={userSeq} />
  }
}
