import { YANADOO_CDN_HOST } from 'common/config'

export const STANDBYME_HOST_CDN = `${YANADOO_CDN_HOST}/promotion/standByMe/`

export const standByMeValues = {
  metaInfo: {
    title: '평생수강+학습지 시작하면 스탠바이미 Go 0원',
    description: '야나두 전체 강의 평생수강! 스르르 학습지까지 한 번에',
    imgsrc: `${YANADOO_CDN_HOST}/banner/img_standByMe_og.png`,
    site_name: '하루 10분 야나두'
  },
  bannerSection: {
    '01': {
      alt: '선착순 수량이 얼마 안 남았어요, 평생수강 + 학습지 시작하면 스탠바이미 GO 무료',
      background: '#6bb3ff'
    },
    '02': {
      alt: '전체강의 평생수강 + 스탠바이미 GO, 지금 최저가로 모두 가져가세요',
      background: '#4073e9'
    },
    '03': {
      alt: '벌써 올해 반이나 지났는데... 새해에 상상한 내 모습 실제로 이루셨나요?',
      background: '#fff'
    },
    '04': {
      alt: '평생 해도 해결되지 않는 영어, 당신의 영어 실력이 여전히 제자리인 이유',
      background: '#fff'
    },
    '05': {
      alt: '올해의 브랜드 대상, 4년 연속 1위',
      background: '#cae0ff'
    },
    '06': {
      alt: '야나두를 시작하고 이렇게 많은 분들이 달라졌어요',
      background: '#fff'
    },
    '07': {
      alt: '학습 효율에 일상 속 즐거움까지, 스탠바이미 GO 하나면 완성됩니다.',
      background: '#131b22'
    },
    '08': {
      alt: '스탠바이미 GO로 달라진 일상, 직접 확인해보세요',
      background: '#fff'
    },
    '09': {
      alt: '이미 170만명이 변화하고 있어요',
      background: '#131b22'
    },
    10: {
      alt: '바쁜 직장인에 딱 맞는, 야나두만의 4가지 맞춤 솔루션',
      background: '#e0eafb'
    },
    11: {
      alt: '하루 10분 영어 평생 수강 혜택 적용',
      background: '#cce1ff'
    },
    12: {
      alt: '170만명의 회원이 인정한 야나두니까 걱정말고 들어보세요',
      background: '#27303c'
    },
    13: {
      alt: '제품상세스펙',
      background: '#cee2ff'
    }
  },
  dbModal: {
    pCategory: 'standByMe',
    eventName: 'standByMe',
    formName: 'standByMeForm',
    btnBgColor: '#19b644',
    btnColor: '#fff',
    btnText: '스탠바이미Go 무료 신청하기',
    banner: { src: `${STANDBYME_HOST_CDN}img-db-modal.png`, width: 660, height: 345 }
  },
  modalButton: {
    buttonColor: '#fff',
    buttonBackground: '#19b644'
  },
  precaution: {
    precautionsTitle: '스탠바이미 Go 유의사항',
    list: [
      {
        title: ['[상품구성 안내]'],
        text: [
          '① 전자기기 정보: LG 스탠바이미 Go(모델명: 27LX5QKNA)',
          '② 본 상품은 ‘스르르학습지 기초팩’, ‘스르르학습지 원어민팩’,‘스르르학습지 스피치팩’, ‘10분 영어 평생수강 패키지’의 모든 학습 콘텐츠가 포함된 패키지입니다.',
          '③ 해당 이벤트는 별도 안내 없이 종료되거나 연장될 수 있습니다.',
          '④ 이벤트, 프로모션 등으로 판매가가 변동될 수 있으며, 이로 인한 환불이나 가격 보상은 불가합니다.]'
        ]
      },
      {
        title: ['[배송 안내]'],
        text: [
          '① LG 스탠바이미 Go는 기본 무료 배송이나 도서 산간 지역의 경우 추가 운임이 발생할 수 있으며 추가 운임이 발생할 경우 배송 전 안내드립니다.',
          '② LG 스탠바이미 Go 취소, 반품 시 상품 수령 후 개봉전 7일 이내 신청해주셔야 하며, 수령일로부터 7일 경과 및 개봉된 제품은 반품이 불가합니다.',
          '③ 제품 수령지 미변경으로 인한 분실/배송비 부담에 대해서는 책임지지 않습니다.',
          '④ 제품 보증기간은 제조사의 품질 보증기간 약관 및 법률에 따릅니다.',
          '⑤ LG스탠바이미 Go는 설치를 진행하지 않는 배송 상품입니다.'
        ]
      },
      {
        title: ['[평생수강 안내]'],
        text: [
          '① 본 상품의 영어 학습 유효기간은 평생수강 연장 안내에 따른 조건 달성시 1년씩 연장 가능하며, 평생수강 연장 조건 미달성시 1회에 한하여 1년의 연장기회를 제공합니다.',
          '② 평생수강 연장 방법 : 누적 출석일 77일 이상 달성 시(365일 기준), 종료 한 달 전 연장 안내 팝업에서 ‘연장하기’ 클릭 시 연장 적용',
          '③ 출석일 초기화 안내 : 수강기간이 연장된 경우, 연장 된 수강기간 종료일 기준 365일 전 출석일 수 초기화 (매년 동일한 방법으로 연장)',
          '④ 매년 연장된 수강기간 종료일 전까지 77일 출석 조건을 만족하는 경우 평생 동안 연장 가능합니다.'
        ]
      },
      {
        title: ['[패키지 환불 안내]'],
        text: [
          "① 본 상품의 환불 정책은 '야나두 패키지 환불 정책'을 따릅니다. ",
          '- 기본 학습 기간은 수강기간 3개월(90일)+복습기간 9개월(275일) 총 12개월(1년)입니다.',
          '- 환불금액은 수강기간 3개월(90일)을 기준으로 책정합니다.',
          '- 무료로 제공되는 복습기간의 환불금액은 책정 할 수 있는 환불금이 없습니다.',
          "- 야나두 장학금을 받은 경우, 최종 환불 금액에서 장학금 지급 금액이 추가 차감됩니다.',",
          '② 수강시작 후 7일 이내, 5강 미만 수강 시에는 100% 환불 가능합니다.',
          '③ 수강시작 후 7일 초과, 5강 이상 수강 시에는 잔여일에 대한 환불이 가능합니다.',
          '④ 반품 배송비(상품 및 사은품 별 별도 책정)는 소비자 부담입니다.',
          '- LG 스탠바이미 Go 반품배송비 : 7,000원, ',
          "- 스르르학습지 반품배송비 : 6,000원'",
          '⑤ 환불 시, “야나두 환불규정”에 따라 환불금액에서 사은품 비용이 별도 청구됩니다.',
          '- 전자기기(LG 스탠바이미 Go) 차감비용 : 1,190,000원(차감금액 기재)',
          '- 스르르 학습지 기초팩&굿즈 환불 차감액 230,000원',
          '- 스르르 학습지 원어민팩&굿즈 환불 차감액 230,000원',
          '- 스르르학습지 스피치팩&굿즈 환불 차감액 230,000원',
          '⑥ 사은품만 별도로 환불할 수 없습니다.',
          '⑦ 더 자세한 환불 정책 및 정산 방법 등은 [환불 규정]을 참고해 주시기 바랍니다.'
        ]
      },
      {
        title: ['[전자 기기 A/S 안내]'],
        text: [
          '① 전자 기기 제품의 A/S는 각 제조사의 정책을 따릅니다.',
          '- LG전자 서비스 센터: 1544-7777',
          '② 이외에 기기를 통한 수강 불편사항은 야나두 고객센터로 문의 바랍니다.',
          '- 운영시간 : 평일 09:00 ~ 18:00 대표번호 : 1600–0563'
        ]
      }
    ]
  }
}
