import classNames from 'classnames'

import ModalCounselComplete from 'components/promotion/modal/ModalCounselComplete'
import ModalCounselDuplicated from 'components/promotion/modal/ModalCounselDuplicated'
import ModalPrivacyAgree from 'components/promotion/modal/ModalPrivacyAgree'
import ModalWrap from 'components/promotion/modal/ModalWrap'
import ResponsiveImage from 'components/ResponsiveImage'

import styles from './ModalForm.module.scss'
import { COUNSEL_TIME, dbCollectionFormValues } from '../config'
import useDbCollectionForm from '../hooks/useDbCollectionForm'

export default function EventPopup({ promotion }) {
  const { category, buttonBackground, buttonColor, modalBanner, modalTitle, modalButtonText } =
    dbCollectionFormValues[promotion]

  const {
    formRef,
    formData,
    modalControl,
    allChecked,
    handleInputChange,
    handleCheckboxChange,
    handleModalToggle,
    koreanNameCheck,
    handleAllCheckboxChange,
    handleSubmit
  } = useDbCollectionForm(promotion)

  return (
    <>
      <section className={classNames(styles.wrap, styles.event)}>
        <article className={styles.modal}>
          {modalBanner && (
            <div>
              <ResponsiveImage
                pc={modalBanner.pc}
                mo={modalBanner?.pc ? modalBanner.pc : modalBanner.mo}
                alt={modalBanner.alt}
              />
            </div>
          )}

          <form
            ref={formRef}
            onSubmit={(e) => {
              e.preventDefault()
              handleSubmit(category)
            }}
          >
            <div className={styles.inner}>
              {modalTitle && <h3 className={styles.title}>{modalTitle}</h3>}
              <ul className={styles.fields}>
                <li>
                  <label htmlFor="userName">이름</label>
                  <input
                    type="text"
                    id="userName"
                    value={formData.userName}
                    onChange={handleInputChange}
                    onKeyUp={koreanNameCheck}
                    placeholder="이름을 입력해주세요"
                  />
                </li>
                <li>
                  <label htmlFor="phone">전화번호</label>
                  <input
                    type="tel"
                    id="phone"
                    value={formData.phone}
                    placeholder="-없이 작성해주세요"
                    onChange={handleInputChange}
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, '')
                    }}
                  />
                </li>
                <li>
                  <label htmlFor="counsel">
                    상담 가능 시간 <br />
                  </label>
                  <div>
                    <select id="counselTime" onChange={handleInputChange}>
                      {COUNSEL_TIME.map((item, index) => (
                        <option key={`${item + index}`} value={index === 0 ? '' : item}>
                          {item}
                        </option>
                      ))}
                      {promotion === 'bestAwards' && (
                        <option value="재입고 알림만 받을래요">재입고 알림만 받을래요</option>
                      )}
                    </select>
                  </div>
                </li>
              </ul>

              <ul className={styles.agree}>
                <li>
                  <label>
                    <input
                      type="checkbox"
                      id="allAgree"
                      checked={allChecked}
                      onChange={handleAllCheckboxChange}
                    />
                    <em></em>
                    전체 동의
                  </label>
                </li>
                <>
                  <li>
                    <label>
                      <input
                        type="checkbox"
                        id="privacyAgreeYn"
                        checked={formData.privacyAgreeYn}
                        onChange={handleCheckboxChange}
                      />
                      <em></em>
                      개인정보 수집·이용 동의 [필수]
                      <button type="button" onClick={() => handleModalToggle('privacy')}>
                        [약관보기]
                      </button>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input
                        type="checkbox"
                        id="smsAgreeYn"
                        checked={formData.smsAgreeYn}
                        onChange={handleCheckboxChange}
                      />
                      <em></em>
                      SMS 광고 및 마케팅 이용 동의 [선택]
                    </label>
                  </li>
                </>
              </ul>
            </div>
            <button
              type="submit"
              className={styles.submitButton}
              style={{ background: buttonBackground, color: buttonColor }}
            >
              {modalButtonText}
            </button>
          </form>
        </article>
      </section>

      {modalControl.privacy && (
        <ModalWrap>
          <ModalPrivacyAgree
            toggle={() => {
              handleModalToggle('privacy')
            }}
          />
        </ModalWrap>
      )}

      {modalControl.success && (
        <ModalCounselComplete
          onClose={() => {
            handleModalToggle('success')
            modalControl.question && handleModalToggle('question')
          }}
          buttonColor={buttonColor}
          buttonBackground={buttonBackground}
        />
      )}

      {modalControl.duplicate && (
        <ModalCounselDuplicated
          onClose={() => {
            handleModalToggle('duplicate')
            modalControl.question && handleModalToggle('question')
          }}
          buttonColor={buttonColor}
          buttonBackground={buttonBackground}
        />
      )}
    </>
  )
}
