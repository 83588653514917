import { useCallback } from 'react'
import { useBeforeUnload } from 'react-router-dom'

export default function useAlertBeforeUnload(message) {
  useBeforeUnload(
    useCallback(
      (event) => {
        event.preventDefault()
        event.returnValue = message
      },
      [message]
    ),
    { capture: true }
  )
}
