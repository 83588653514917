import { useEffect, useState } from 'react';
import styled from 'styled-components';
import theme from 'common/theme';
import { ThemeProvider } from 'styled-components';

export default function ModalModify({
  handleCloseModal,
  handleCommentModify,
  handleCommentDelete,
  modalCommentSeq,
}) {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    if (loading) {
      handleCloseModal();
    }
    return () => setLoading(false);
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <ModalWrap>
        <div onClick={handleCloseModal}></div>
        <ModalInner>
          <BtnBox>
            <button onClick={handleCommentModify}>
              <span>댓글 수정</span>
            </button>
            <button
              onClick={(e) => {
                handleCommentDelete(e, modalCommentSeq);
              }}
            >
              <span>댓글 삭제</span>
            </button>
          </BtnBox>
          <button onClick={handleCloseModal}>
            <span>취소</span>
          </button>
        </ModalInner>
      </ModalWrap>
    </ThemeProvider>
  );
}

const ModalWrap = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 5rem;
  z-index: 999;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.4);
  box-sizing: border-box;

  & > div:first-child {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  @media ${({ theme }) => theme.device.mobile} {
    align-items: flex-end;
    justify-content: flex-end;
  }
`;

const ModalInner = styled.div`
  width: 48rem;
  background-color: #fff;
  border-radius: 1rem;
  overflow: hidden;

  button {
    position: relative;
    width: 100%;
    height: 8rem;
    color: #000;
    font-weight: 400;
    font-size: 2rem;
    border: 1px solid #e7e7f0;
    box-sizing: border-box;
    cursor: pointer;

    &:hover {
      background-color: #f7f7fc;
    }

    & + button {
      margin-top: 2rem;
      color: #ff5647;
    }
  }

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    background-color: transparent;

    button {
      height: 11.5rem;
      margin: 0;
      font-size: 2.9rem;
      border: 0;
      border-radius: 0;
      background-color: #fff;

      & + button {
        margin: 0;
        border-top: 1px solid #e7e7f0;
      }
    }
  }
`;

const BtnBox = styled.div`
  padding: 4.2rem 3.2rem;
  box-sizing: border-box;

  @media ${({ theme }) => theme.device.mobile} {
    position: relative;
    padding: 0;
    border-radius: 1.5rem;
    background-color: #fff;
    overflow: hidden;

    button {
      margin: 0;
    }

    & + button {
      margin-top: 2.5rem;
      border-radius: 1.5rem;
    }
  }
`;
