import React, { useEffect, useRef, useState } from 'react'

import cx from 'classnames'

import useChatFeedListStore from 'store/useChatFeedListStore'
import useFeedbackStore from 'store/useFeedbackStore'

import s from './Chat.module.scss'
import ChatFeedContainer from './ChatFeedContainer'
import ModalFeedback from './ModalFeedback'
import ModalFeedbackLoading from './ModalFeedbackLoading'
import ModalFeedNot from './ModalFeedNot'
import useGetTravelFeedback from '../hooks/useGetFeedback'

export default function ChatContainer({ metaInfo, isAiResponse, lectureSeq, userSeq }) {
  const { feedList } = useChatFeedListStore()
  const { isFeedback } = useFeedbackStore()
  const { fetchFeedback, feedback } = useGetTravelFeedback()
  const containerRef = useRef(null)
  const [modal, setModal] = useState(false)
  const [alert, setAlert] = useState(false)
  const [loading, setLoading] = useState(false)

  const scrollToBottom = () => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight
    }
  }

  const handlFeedback = () => {
    setModal(false)
    setLoading(true)
    fetchFeedback({ chatWindow: metaInfo.chatWindow, lectureSeq, userSeq })
  }

  const handleFeedModal = () => {
    if (feedList.length < 2) {
      setAlert(!alert)
    } else {
      setModal(!modal)
    }
  }

  useEffect(() => {
    scrollToBottom()
  }, [feedList, feedback])

  return (
    <section className={s.chatContainer} ref={containerRef}>
      <div className={s.contentInner}>
        <ChatFeedContainer
          metaInfo={metaInfo}
          isAiResponse={isAiResponse}
          userSeq={userSeq}
          feedback={feedback}
        />
      </div>
      {!feedback && (
        <button
          className={cx(s.btnFeedback, { [s.active]: feedList.length >= 2 })}
          onClick={handleFeedModal}
          disabled={modal || alert}
        >
          AI피드백 받기 →
        </button>
      )}
      {modal && <ModalFeedback onClick={() => setModal(!modal)} onConfirm={handlFeedback} />}
      {alert && <ModalFeedNot onClick={() => setAlert(!alert)} />}
      {loading && !isFeedback && <ModalFeedbackLoading />}
    </section>
  )
}
