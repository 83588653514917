import { useState } from 'react'

import ResponsiveImage from 'components/ResponsiveImage'

import TrainingStep from './step'
import StepSwiper from './StepSwiper'
import { HOST_CDN } from '../config'
import s from '../EnglishHome.module.css'

export default function BigDataSection() {
  const [activeIdx, setActiveIdx] = useState(0)
  return (
    <section className={s.bigData}>
      <div className={s.inner}>
        <h3 className={s.title}>
          <ResponsiveImage pc={`${HOST_CDN}title_bigdata.png`} mo={`${HOST_CDN}title_m_bigdata.png`} alt={'AI 트레이닝 시스템'} maxWidth={1024} />
        </h3>
        <div className={s.stepBox}>
          <StepSwiper setActiveIdx={setActiveIdx} />
          <TrainingStep activeIdx={activeIdx} />
        </div>
      </div>
    </section>
  )
}
