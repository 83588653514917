import styles from './form.module.scss'

export default function PhoneCounselTime({ value, counselTime, onChange, counselTimeRef }) {
  return (
    <div className={`${styles.row} row`}>
      <label className={styles.label}>상담 가능 시간</label>
      <select
        value={value}
        className={styles.counselSelect}
        onChange={onChange}
        ref={counselTimeRef}
      >
        {counselTime.map((item, index) => {
          return (
            <option key={`${item + index}`} value={index === 0 ? '' : item}>
              {item}
            </option>
          )
        })}
      </select>
    </div>
  )
}
