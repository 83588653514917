import styles from "./modalContainer.module.css";
import classnames from "classnames/bind";

const cx = classnames.bind(styles);

export default function ModalContainer({ children, open, onClose }) {
  return (
    <div className={cx("modal", open && "active")}>
      <div className={styles.dimmed} onClick={onClose}></div>
      {children}
    </div>
  );
}
