import { useCallback, useEffect, useRef, useState } from 'react'
import { Link, useParams, useLocation } from 'react-router-dom'

import styled, { ThemeProvider } from 'styled-components'
import { v4 as uuid } from 'uuid'

import api from 'common/api'
import theme from 'common/theme'

import IcoArrow from 'assets/images/ico_check_36x21.png'

const serviceItems = [
  {
    id: 1,
    brandCode: 'yanadoo',
    name: '야나두',
    childTabs: [
      {
        id: 1,
        termsType: 'service',
        name: '이용약관'
      },
      {
        id: 2,
        termsType: 'privacy_policy',
        name: '개인정보처리방침'
      }
    ]
  },

  {
    id: 2,
    brandCode: 'YAFIT_CYCLE',
    name: '야핏사이클',
    childTabs: [
      {
        id: 1,
        termsType: 'service',
        brandCode: 'YAFIT_CYCLE',
        name: '이용약관'
      },
      {
        id: 2,
        termsType: 'privacy_policy',
        name: '개인정보처리방침'
      },
      {
        id: 3,
        termsType: 'OPERATION_POLICY',
        name: '운영 정책'
      }
    ]
  },
  {
    id: 3,
    brandCode: 'yafit_rider',
    name: '야핏 무브',
    childTabs: [
      {
        id: 1,
        termsType: 'service',
        brandCode: 'yafit_rider',
        name: '이용약관'
      },
      {
        id: 2,
        termsType: 'privacy_policy',
        name: '개인정보처리방침'
      },
      {
        id: 3,
        termsType: 'location_service',
        name: '위치기반 서비스 이용약관'
      }
    ]
  },
  {
    id: 4,
    name: 'B2B 단체수강',
    brandCode: 'B2B',
    childTabs: [
      {
        id: 1,
        termsType: 'service',
        name: '이용약관'
      },
      {
        id: 2,
        termsType: 'privacy_policy',
        name: '개인정보처리방침'
      }
    ]
  }
]

export default function TempTerms() {
  const contentRef = useRef(null)

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const isPrivacyOnly = searchParams.get('privacyOnly')

  const { brandSeq, itemSeq } = useParams()

  const [hash, setHash] = useState(location.hash)
  const [termsData, setTermsData] = useState([])
  const [termsDataList, setTermsDataList] = useState([])
  const [currentSeq, setCurrentSeq] = useState([])

  // Hash 리셋
  const resetHash = useCallback(() => {
    setHash(location.hash)
  }, [location])

  const getTermsContent = useCallback(async () => {
    try {
      let result

      if (brandSeq === 'B2B') {
        // B2B API
        result = await api
          .get(`/b2b/terms/?termsSeq=&termsType=${itemSeq.toUpperCase()}`) //
          .then((response) => response.data.data.terms.content1)
      } else {
        // 야나두/야핏사이클/야핏무브 API
        result = await api
          .get(
            `/v2/terms?brandCode=${brandSeq.toUpperCase()}&seq=${currentSeq}&termsType=${itemSeq.toUpperCase()}`
          ) //
          .then((response) => response.data.data.contents)
      }
      setTermsData(result)
    } catch (error) {
      console.error(error)
    }
  }, [brandSeq, itemSeq, currentSeq])

  const getTermsHistory = useCallback(async () => {
    try {
      let result

      if (brandSeq === 'B2B') {
        // B2B
        result = await api
          .get(`/b2b/terms/?termsSeq=&termsType=${itemSeq.toUpperCase()}`) //
          .then((response) => response.data.data.termsList)
      } else {
        // 야나두/야핏사이클/야핏무브 API
        result = await api
          .get(
            `/v2/terms/history-titles?brandCode=${brandSeq.toUpperCase()}&termsType=${itemSeq.toUpperCase()}`
          ) //
          .then((response) => response.data.data)
      }
      setTermsDataList(result)
    } catch (error) {
      console.error(error)
    }
  }, [brandSeq, itemSeq])

  const handleTermsChange = (e) => {
    const seq = parseInt(e.target.value)

    if (brandSeq === 'B2B') {
      const selectedTerms = termsDataList.filter((item) => item.termsSeq === seq)
      setTermsData(selectedTerms[0].content1)
    } else {
      setCurrentSeq(seq)
    }
  }

  // Data Fetch
  useEffect(() => {
    getTermsContent()
    getTermsHistory()
    resetHash()
  }, [getTermsContent, getTermsHistory, resetHash])

  // Hash로 문서 위치로 이동 로직
  useEffect(() => {
    if (!hash || !contentRef.current) return

    const targetEl = contentRef.current.querySelector(hash)
    window.scrollTo(0, targetEl ? targetEl.offsetTop : 0)
  }, [termsData, hash])

  const FormBox = ({ title, contents }) => {
    return (
      <FormBoxTemp>
        <h3>{title}</h3>
        <div ref={contentRef}>
          {contents && <div dangerouslySetInnerHTML={{ __html: contents }}></div>}
        </div>
      </FormBoxTemp>
    )
  }

  return (
    <ThemeProvider theme={theme}>
      <InnerCol>
        <BrandTabsTemp>
          <ul>
            {serviceItems.map(({ name, brandCode, childTabs }) => {
              const privacyPolicyIndex = childTabs.findIndex(
                (item) => item.termsType === 'privacy_policy'
              )
              const setIndex = isPrivacyOnly === 'y' ? privacyPolicyIndex : 0

              return (
                <li key={uuid()}>
                  <Link
                    to={`/service/${brandCode}/${childTabs[setIndex].termsType}${location.search}`} //
                    className={brandSeq === brandCode ? 'active' : ''}
                  >
                    {name}
                  </Link>
                  <ServiceTabsTemp>
                    <ul className={brandCode}>
                      {childTabs.map(({ termsType, name }) => {
                        const isActive = itemSeq === termsType ? 'active' : ''

                        if (isPrivacyOnly === 'y' && termsType !== 'privacy_policy') return null
                        return (
                          <li key={uuid()}>
                            <Link
                              to={`/service/${brandCode}/${termsType}${location.search}`} //
                              className={`${brandCode}_${termsType} ${isActive}`}
                            >
                              {name}
                            </Link>
                          </li>
                        )
                      })}
                    </ul>
                  </ServiceTabsTemp>
                </li>
              )
            })}
          </ul>
        </BrandTabsTemp>
        <TermsFormTemp>
          {serviceItems
            .filter((brand) => brand.brandCode === brandSeq) //
            .map((brandItem) =>
              brandItem.childTabs
                .filter((service) => service.termsType === itemSeq) //
                .map((serviceItem) => (
                  <FormBox
                    key={uuid()} //
                    title={`${brandItem.name} ${serviceItem.name}`}
                    contents={termsData}
                  />
                ))
            )}
        </TermsFormTemp>
        <TermsHistoryTemp>
          <select onChange={handleTermsChange}>
            {termsDataList &&
              termsDataList.map((termsList) => (
                <option
                  value={brandSeq === 'B2B' ? termsList.termsSeq : termsList.seq}
                  key={uuid()}
                >
                  {termsList.title}
                </option>
              ))}
          </select>
        </TermsHistoryTemp>
      </InnerCol>
    </ThemeProvider>
  )
}

const InnerCol = styled.div`
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 3rem;
  background-color: #fff;
  text-align: center;
  overflow: hidden;

  @media ${({ theme }) => theme.device.mobile} {
    padding: 0;
  }
`

const BrandTabsTemp = styled.section`
  display: inline-block;
  margin: 8rem 0 5.4rem;

  & > ul {
    display: flex;
    justify-content: center;
    align-items: baseline;
    border: 1px solid #e7e7f0;

    & > li {
      width: inherit;
      height: inherit;
      box-sizing: border-box;

      & + li {
        border-left: 1px solid #e7e7f0;
      }

      & > a {
        display: block;
        position: relative;
        width: 23.7rem;
        padding: 1.5rem 0 1.3rem;
        font-size: 1.4rem;

        &.active {
          background-color: #ffb900;
          font-weight: 700;

          & + section {
            display: block;
          }
        }
      }
    }
  }

  @media ${({ theme }) => theme.device.mobile} {
    display: block;
    width: 100%;
    margin: 0;

    & > ul {
      display: table;
      table-layout: fixed;
      width: 100%;
      border: 0;
      border-bottom: 1px solid #e7e7f0;

      & > li {
        display: table-cell;

        a {
          width: inherit;
          padding: 3rem 0;
          font-size: 2.7rem;
        }
      }
    }
  }
`

const ServiceTabsTemp = styled.section`
  display: none;
  position: absolute;
  left: 3rem;
  width: 100%;
  margin-bottom: 3.1rem;
  padding-top: 5.4rem;
  font-size: 2rem;
  text-align: left;

  div + div {
    // display: none;
  }

  ul {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;

    li {
      flex: 0 0 auto;
      & + li {
        margin-left: 4.8rem;
      }

      a {
        position: relative;
        color: #a0a0b6;
        font-weight: 700;
        padding: 0;
        font-size: 2rem;

        &.active {
          color: #000;

          &:before {
            content: '';
            display: inline-block;
            position: absolute;
            bottom: 0;
            width: 100%;
            border-bottom: 0.3rem solid #ffb900;
          }
        }
      }
    }
  }

  @media ${({ theme }) => theme.device.mobile} {
    position: absolute;
    left: 0;
    width: 100%;
    padding: 0;
    margin-bottom: 0;
    font-size: 2.9rem;

    ul {
      li {
        margin: 0 0 0 4rem;

        &:first-child {
          margin: 0 0 0 4rem;
        }

        .yafit_rider_club_service {
          margin-right: 6rem;
        }

        a {
          display: inline-block;
          padding: 3.7rem 0 3.5rem;
          font-size: 2.9rem;

          &.active:before {
            border-width: 0.4rem;
          }
        }
      }
    }
  }
`

const TermsFormTemp = styled.section`
  padding-top: 5.5rem;
  text-align: left;

  @media ${({ theme }) => theme.device.mobile} {
    padding-top: 10.4rem;
  }
`

const FormBoxTemp = styled.div`
  margin-bottom: 2.7rem;
  border-top: 0.3rem solid #000;
  border-bottom: 1px solid #b7b7c8;

  h3 {
    padding: 2.1rem 3rem 2rem;
    font-size: 1.8rem;
    border-bottom: 1px solid #e7e7f0;
  }

  & > div {
    width: 100%;
    margin: 0 auto;
    padding: 3rem 7rem 3rem 3rem;
    overflow-y: auto;
    box-sizing: border-box;
    word-break: keep-all;

    & > div {
      max-width: 109.4rem;
      width: 100%;
      height: 100%;
      font-size: 1.6rem;
      line-height: 1.6;
    }
  }

  @media ${({ theme }) => theme.device.mobile} {
    border-top: 1px solid #dfdfea;
    border-bottom: 0;

    h3 {
      display: none;
    }

    & > div {
      padding: 6rem 4.2rem 0;
      div {
        font-size: 3rem;
      }
    }
  }
`

const TermsHistoryTemp = styled.div`
  margin-bottom: 6.7rem;
  text-align: left;

  select {
    display: inline-block;
    width: 48rem;
    padding: 0.4rem 1.6rem 0.5rem;
    color: #000;
    background: #fff url(${IcoArrow}) 96% 50%/1.2rem auto no-repeat;
    font-weight: 400;
    font-size: 1.4rem;
    border: 1px solid #dfdfea;
  }

  @media ${({ theme }) => theme.device.mobile} {
    margin: 7.8rem 0 6rem;
    padding: 0 4.2rem 0 4.3rem;
    box-sizing: border-box;

    select {
      width: 100%;
      padding: 2.6rem 2.7rem 2.9rem;
      background-size: 2rem;
      font-size: 2.9rem;
    }
  }
`
