import React, { useEffect, useState } from 'react';

const Toast = ({ type, msg, subMsg, openToast, handleToastStatus }) => {
  // 1. msg: 토스트 메시지
  // 2. openToast: 토스트 팝업 오픈 여부
  // 3. handleToastStatus: 부모 컴포넌트의 토스트 팝업 오픈 여부 초기화
  // 4. subMsg: 토스트 메시지에 추가 문구
  // 5. type: coupon -> ModalCouponDuplicate(쿠폰 적용하기 팝업)에 쓰이는 스타일
  // 참고: ModalSocialLogin.jsx

  const [isTimeout, setIsTimeout] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(
      () => {
        handleToastStatus(false);
        setIsTimeout(true);
      },

      1500
    );

    return () => {
      setIsTimeout(false);
      clearTimeout(timeout);
    };
  }, [openToast]);

  return (
    <>
      <div
        className={
          'toast-box' +
          (type === 'coupon' ? ' coupon' : '') +
          (openToast && !isTimeout ? ' active' : '')
        }
      >
        {msg && openToast && !isTimeout && (
          <p>
            {subMsg && <strong>{subMsg}</strong>}
            {msg}
          </p>
        )}
      </div>
    </>
  );
};

export default Toast;
