import React from 'react'

import styled from 'styled-components'

import BgStarOrange from '../../assets/images/bg_star_120x24_orange.png'
import BgStarGray from '../../assets/images/bg_star_120x24g.png'

const StarPoint = styled.div`
  display: ${(props) => props.type || 'block'};
  position: relative;
  width: ${(props) => props.width + 'rem' || '10rem'};
  height: ${(props) => props.height + 'rem' || '2rem'};
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(${BgStarGray}) 0 0 no-repeat;
    background-size: auto 100%;
    z-index: 2;
  }

  span {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: ${(props) => props.per + '%' || '0%'};
    height: 100%;
    background: url(${BgStarOrange}) 0 0 no-repeat;
    background-size: auto 100%;
    max-width: 100%;
    text-indent: -9999rem;
    overflow: hidden;
  }
`

export default function Satisfaction({ type, width, height, per }) {
  return (
    <StarPoint type={type} width={width} height={height} per={per && per * 20}>
      <span></span>
    </StarPoint>
  )
}
