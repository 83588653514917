import { useEffect, useMemo, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from 'react-router'

import useInterval from 'hooks/useInterval'
import useLevelTestAdvancedExamStore from 'store/levelTestAdvanced/useLevelTestAdvancedExamStore'

import { LIMITED_TIME } from '../config'
import styles from '../LevelTestAdvancedExam.module.scss'

export default function Timer() {
  const navigate = useNavigate()
  const [time, setTime] = useState(0)
  const isMobile = useMediaQuery({ maxWidth: 500 })
  const isTablet = useMediaQuery({ maxWidth: 750 })
  const startTime = useLevelTestAdvancedExamStore((state) => state.startTime)

  const cw = isMobile ? 32 : isTablet ? 64 : 43
  const strokeWidth = isMobile ? 5 : isTablet ? 10 : 6

  const parsePadStart = (number) => {
    return String(number).padStart(2, '0')
  }

  const strokeDashoffset = useMemo(() => {
    const progress = 1 - time / 3600
    return progress * ((cw - strokeWidth / 2) * 6.28)
  }, [cw, strokeWidth, time])

  useEffect(() => {
    if (time > LIMITED_TIME) {
      setTime(null)
      alert(`레벨테스트를 제한 시간(60분)이 경과되어 초기 화면으로 이동합니다.\n레벨테스트는 중간 저장되지 않으며, 처음부터 다시 진행해야 합니다.`)
      navigate('/levelTestAdvanced/landing')
    }
  }, [navigate, time])

  useInterval(() => {
    setTime((prev) => prev + 1)
  }, 1000)

  useEffect(() => {
    if (startTime) {
      const diff = new Date().getTime() - startTime.getTime()
      setTime(Math.trunc(diff / 1000))
    }
  }, [startTime])

  return (
    <div className={styles.timer}>
      <svg className={styles.timerCircle}>
        <circle className={styles.frame} cx={cw} cy={cw} r={cw - strokeWidth / 2} strokeWidth={strokeWidth} />
        <circle className={styles.bar} cx={cw} cy={cw} r={cw - strokeWidth / 2} strokeWidth={strokeWidth} style={{ strokeDashoffset, strokeDasharray: (cw - strokeWidth / 2) * 6.28 }} />
      </svg>
      <em className={`${styles.timerText} ${time > 3300 ? styles.timeLimit : ''}`}>
        {parsePadStart(parseInt(time / 60))}:{parsePadStart(time % 60)}
      </em>
    </div>
  )
}
