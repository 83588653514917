import SpeechToText from './SpeechToText'
import SpeechToTextAos from './SpeechToTextAos'
const getOS = () => {
  const userAgent = typeof window !== 'undefined' ? window.navigator.userAgent : null
  if (!userAgent) return undefined

  let osVersion = undefined
  // Windows OS
  if (/Windows NT \d+\.\d+/i.test(userAgent)) {
    osVersion = 'window'
  }
  // macOS
  else if (/Mac OS X \d+_\d+/i.test(userAgent)) {
    osVersion = 'mac'
  }
  // iOS
  else if (/iPhone|iPad|iPod/i.test(userAgent)) {
    osVersion = 'ios'
  }
  // Android OS
  else if (/Android/i.test(userAgent)) {
    osVersion = 'aos'
  }
  return osVersion
}

export default function ChatFooterAudio({ addToFeed, isLoading, character = {} }) {
  const props = { addToFeed, isLoading, character }
  const isOS = getOS()

  return <>{isOS === 'aos' ? <SpeechToTextAos {...props} /> : <SpeechToText {...props} />}</>
}
