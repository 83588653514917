import { YANADOO_CDN_HOST } from 'common/config'

export const IPADTEN_HOST_CDN = `${YANADOO_CDN_HOST}/promotion/tabpackageten/`

const tabPackage10thValues = {
  metaInfo: {
    title: '평생수강 + iPad 10세대',
    description:
      '걱정 없이 평생 수강하고 iPad 10세대 받아요! 영어회화 기초부터 원어민과의 대화까지 올 케어 최신형 iPad 10세대 받고 시작하세요. ',
    site_name: '하루 10분 야나두',
    imgsrc: `${YANADOO_CDN_HOST}/banner/img_tabpackageten_og.png`
  },
  experienceList: [
    {
      pc: `${IPADTEN_HOST_CDN}2023/jan/img_pc_tabpackageten_exp_1.png`,
      mo: `${IPADTEN_HOST_CDN}2023/jan/img_m_tabpackageten_exp_1.png`,
      alt: 'iPad 패키지로 공부하는 사진',
      text: (
        <>
          <strong>
            <span>언제 어디서나 iPad로 공부</span>하니
            <br />
            실력이 점점 늘어요!
          </strong>
        </>
      )
    },
    {
      pc: `${IPADTEN_HOST_CDN}2023/jan/img_pc_tabpackageten_exp_2.png`,
      mo: `${IPADTEN_HOST_CDN}2023/jan/img_m_tabpackageten_exp_2.png`,
      alt: 'iPad 패키지로 공부하는 사진',
      text: (
        <>
          <strong>
            <span>
              iPad로 강의를 들으며 멀티로
              <br />
              필기
            </span>
            할 수 있어 더 체계적이에요!
          </strong>
        </>
      )
    },
    {
      pc: `${IPADTEN_HOST_CDN}2023/jan/img_pc_tabpackageten_exp_3.png`,
      mo: `${IPADTEN_HOST_CDN}2023/jan/img_m_tabpackageten_exp_3.png`,
      alt: 'iPad 패키지로 공부하는 사진',
      text: (
        <>
          <strong>
            <span>위클리, 먼슬리 플랜</span>을 세우며
            <br />
            공부할 수 있어서 더욱 알차요!
          </strong>
        </>
      )
    }
  ],
  ipadSlideList: [
    {
      title: `24.8cm x 17.9cm의\n넓은 화면 공간`,
      description: (
        <>
          영상의 몰입도를 높이고
          <br />
          화면 활용 효율성을 높여줍니다.
        </>
      )
    },
    {
      title: `1,200만 화소로\n업그레이드 된 카메라`,
      description: (
        <>
          1,200만 화소, f/1.8 조리개,
          <br />
          4K 동영상 녹화 기능 탑재,
          <br />
          스마트 HDR3이 지원되는 카메라로
          <br />
          더욱 똑똑해졌어요.
        </>
      )
    },
    {
      title: `바이오닉스 칩\n업그레이드`,
      description: (
        <>
          고성능 A14 Bionic칩으로
          <br />
          업그레이드 되어 더 빠르고 쾌적한
          <br />
          멀티태스킹 작업이 가능합니다.
        </>
      )
    },
    {
      title: `고사양\n프로그램 가능`,
      description: (
        <>
          CPU 성능 20%, GPU 성능 10%
          <br />
          향상되어 고성능 프로그램도
          <br />
          발열 없이 빠르게 사용할 수 있어요.
        </>
      )
    },
    {
      title: `USB-C Type으로\n편리하게`,
      description: (
        <>
          C type 충전단자로
          <br />
          별도 충전 케이블 없이 편리하게
          <br />
          사용 가능합니다.
        </>
      )
    }
  ],
  specialBonusList: [
    {
      pc: `${IPADTEN_HOST_CDN}2023/jan/img_pc_tabpackageten_special_1.png`,
      mo: `${IPADTEN_HOST_CDN}2023/jan/img_m_tabpackageten_special_1.png`,
      alt: '추가혜택 이미지',
      text: (
        <>
          영어 공부만 해도
          <br />
          84,000원 장학금 증정
        </>
      )
    },
    {
      pc: `${IPADTEN_HOST_CDN}2024/img_aitalk_pc.png`,
      mo: `${IPADTEN_HOST_CDN}2024/img_aitalk_mo.png`,
      alt: '추가혜택 이미지',
      text: (
        <>
          나만의 AI 원어민 친구
          <br />
          <strong>AI 원어민톡</strong>
        </>
      )
    },
    {
      pc: `${IPADTEN_HOST_CDN}2023/jan/img_pc_tabpackageten_special_3.png`,
      mo: `${IPADTEN_HOST_CDN}2023/jan/img_m_tabpackageten_special_3.png`,
      alt: '추가혜택 이미지',
      text: `10만원 상당\n틈새단어 앱 이용권`
    },
    {
      pc: `${IPADTEN_HOST_CDN}2023/jan/img_pc_tabpackageten_special_4.png`,
      mo: `${IPADTEN_HOST_CDN}2023/jan/img_m_tabpackageten_special_4.png`,
      alt: '추가혜택 이미지',
      text: `알차게 복습!\n강의별 PDF 학습자료`
    },
    {
      text: (
        <>
          영어회화에 자주 쓰이는 표현들만! <br />
          입으로 훈련하는
          <strong>NEW 트레이닝 북</strong>
        </>
      )
    }
  ],
  threeStepList: [
    {
      pc: `${IPADTEN_HOST_CDN}2023/jan/img_pc_tabpackageten_key_1.png`,
      mo: `${IPADTEN_HOST_CDN}2023/jan/img_m_tabpackageten_key_1.png`,
      alt: 'key1 마법패턴, key2 9단계 입체 커리큘럼, key3 증명된 10분 학습효과',
      title: (
        <>
          원어민이 영어를 배우는 원리
          <strong>마법 패턴</strong>
        </>
      ),
      description: '익숙한 문장 하나로 다양한 표현을 쉽게 배워요'
    },
    {
      pc: `${IPADTEN_HOST_CDN}2023/jan/img_pc_tabpackageten_key_2.png`,
      mo: `${IPADTEN_HOST_CDN}2023/jan/img_m_tabpackageten_key_2.png`,
      alt: 'step1 문장패턴 익히기, step2 단어바꿔 말해보기, step3 확장해서 말해보기',
      title: (
        <>
          말하기에 필요한 모든 것<strong>9단계 입체 커리큘럼</strong>
        </>
      ),
      description: '전문 강사들의 11년 연구 결실을 압축했어요'
    },
    {
      pc: `${IPADTEN_HOST_CDN}2023/jan/img_pc_tabpackageten_key_3.png`,
      mo: `${IPADTEN_HOST_CDN}2023/jan/img_m_tabpackageten_key_3.png`,
      alt: '문화, 문맥, 듣기, 구조, 어순, 단어, 억양, 발음, 뉘앙스',
      title: (
        <>
          짧게 집중해서 10분
          <strong>증명된 학습효과</strong>
        </>
      ),
      description: '원리/ 표현 연습/ 복습까지 10분 강의 하나면 충분해요'
    }
  ],
  tenMinutesValue: [
    {
      img: `${YANADOO_CDN_HOST}/promotion/curriculum/img_ten01.jpg`,
      alt: '뇌파 메핑 분석결과',
      guideTxt: '출처 : KBS 수요기획 <하루 10분의 기적>중, 프로그램 내 자체 검사 결과',
      title: '뇌를 제대로 활용하는 10분',
      description: (
        <>
          뇌는 한 번에 할당할 수 있는 용량이 제한적이기 때문에 뇌의 활성도를 높이고, 집중력이 높은
          시간 동안 빠르게 주입 시키는 것이 필요합니다.
          <br />
          즉, 긴 시간 많은 양을 학습하는 것보다 10분 동안 꾸준히 하는 것이 더욱효과적입니다.
        </>
      )
    },
    {
      img: `${YANADOO_CDN_HOST}/promotion/curriculum/img_ten02.jpg`,
      alt: '반복의 중요성 분석자료',
      guideTxt: '출처 : KBS 수요기획 <하루 10분의 기적>중, 프로그램 내 자체 검사 결과',
      title: '반복의 중요성 분석자료',
      description: (
        <>
          뇌에는 정보 저장 창고 역할을 하는 '해마'가 있습니다.
          <br />
          하지만, 정보를 보관하는 기간이 한 달을 넘지 않기 때문에 지속적인 자극을 주어야 합니다.
          <br />
          실험 연구 결과반복 학습 시 기억 효과가 약 2배 이상 높게 나타났습니다.
        </>
      )
    },
    {
      img: `${YANADOO_CDN_HOST}/promotion/curriculum/img_ten03.jpg`,
      alt: '반복을 가능케 하는 10분',
      guideTxt: '출처 : 습관과 뇌 활용의 상관관계 by.케이온',
      title: '강력한 습관을 만드는 10분',
      description: (
        <>
          영어학습이 습관이 아닌 그룹은 뇌가 일을 늘 새롭게 해야 하기에 스트레스를 많이 받습니다.
          반면 영어학습이 습관인 그룹은 뇌가 조금만 일해도 그 일을 잘 해낼 수 있습니다. <br />
          즉, 영어학습이 습관이 되면 아주 적은 노력만으로 영어를 잘하게 됩니다.
        </>
      )
    },
    {
      img: `${YANADOO_CDN_HOST}/promotion/curriculum/img_ten04.png`,
      alt: '뇌구조연구 > 적정학습량 > 장기기억저장 > 효과극대화',
      title: '결과의 차이를 만드는 10분',
      description: (
        <>
          우리의 뇌 구조를 파악하여 적정 학습 시간을 설계, 소화할 수 있는 적정 양의 학습 내용을
          주입, 반복을 통해 배운 내용을 장기 기억으로 저장, 습관을 형성하여 큰 노력 없이도 충분히
          영어 실력을 향상시킬 수 있게 되는 10분 영어입니다.
        </>
      )
    }
  ],
  reviewSlide: {
    title: '야 나두 했어!',
    description: '10분 영어 이런게 좋아요',
    background: `#ff0392`
  }
}

export default tabPackage10thValues
