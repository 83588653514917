import React from 'react'
import Lottie from 'react-lottie'

import cx from 'classnames'
import LottieData from 'pages/aiTalk/lottieData.json'

import useChatFeedListStore from 'store/useChatFeedListStore'

import s from './Chat.module.scss'
import ChatFeedItem from './ChatFeedItem'
import InitMessage from './InitMessage'
import SRRLoading from '../CircleLoading.json'
import { AINADOO_CONFIG } from '../config'

export default function ChatFeedList({ isAiResponse, userSeq, pageType }) {
  const { feedList } = useChatFeedListStore()

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: SRRLoading,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }
  return (
    <ul className={s.feedList}>
      <InitMessage pageType={pageType} />
      {feedList.map(({ type, message }, index) => (
        <li className={cx(s.feedItem, s[type.toLowerCase()])} key={index}>
          <ChatFeedItem
            type={type}
            message={message}
            isAiResponse={isAiResponse}
            userSeq={userSeq}
            pageType={pageType}
          />
        </li>
      ))}
      {isAiResponse && (
        <li className={cx(s.feedItem, s.srrLoading)}>
          <div className={s.aiMessage}>
            <div className={s.msgThumb}>
              <img
                src={`${AINADOO_CONFIG.IMG_HOST}${
                  pageType === 'srr' ? 'img_ainadoo_profile_srr' : 'img_ainadoo_profile'
                }.png`}
                alt="AI 스르르"
              />
            </div>
            <dl>
              <dt className={s.msgName}>{pageType === 'srr' ? 'AI 스르르' : 'AI 나두'}</dt>
              <dd className={s.message}>
                <div className={s.lottieBox}>
                  <Lottie options={defaultOptions} />
                </div>
                답변을 생성 중입니다.
              </dd>
            </dl>
          </div>
        </li>
      )}
    </ul>
  )
}
