import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import theme from 'common/theme';
import { ThemeProvider } from 'styled-components';
import img_mall_college from '../../assets/images/img_mall_college.png';

export default function ModalMallAuth({
  mallType,
  handleCloseModal,
  mallInfoComplete,
  packageSeq,
}) {
  const [isBtnActive, setBtnActive] = useState(false);
  const collegeNameRef = useRef();
  const campusNameRef = useRef();
  const validationCheck = () => {
    const collegeName = collegeNameRef.current.value;
    const campusName = campusNameRef.current.value;
    collegeName.trim().length > 0 && campusName.trim().length > 0
      ? setBtnActive(true)
      : setBtnActive(false);
  };
  const mallAuthComplete = () => {
    const collegeName = collegeNameRef.current.value;
    const campusName = campusNameRef.current.value;
    if (collegeName.trim().length > 0 && campusName.trim().length > 0) {
      window.localStorage.setItem('mallCategory', collegeName);
      window.localStorage.setItem('mallDetail', campusName);
      window.localStorage.setItem('packageSeq', packageSeq);
      mallInfoComplete();
    } else {
      alert('대학교 명을 입력해주세요.');
    }
  };

  useEffect(() => {
    if (mallType === '') mallInfoComplete();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <ModalWrap>
        <div onClick={handleCloseModal}></div>
        <ModalInner>
          <button onClick={handleCloseModal} className={'ico-close'}>
            <span>취소</span>
          </button>
          {mallType && mallType === 'college' ? (
            <CollegeBox>
              <dl>
                <dt>
                  <strong>
                    수강 신청 및 결제를 위해
                    <br />
                    아래 정보를 입력해주세요
                  </strong>
                  입력하신 정보는 야나두 이용자 식별 및<br />
                  회원관리 서비스 제공에 사용됩니다
                  <img src={img_mall_college} alt="" />
                </dt>
                <dd>
                  <input
                    type="text"
                    placeholder="대학교 명을 입력해주세요."
                    name="collegeName"
                    ref={collegeNameRef}
                    onChange={validationCheck}
                    maxLength={20}
                  />
                </dd>
                <dd>
                  <input
                    type="text"
                    placeholder="캠퍼스 명을 입력해주세요.(없을경우 '없음'입력)"
                    name="campusName"
                    ref={campusNameRef}
                    onChange={validationCheck}
                    maxLength={20}
                  />
                </dd>
              </dl>
              <button
                onClick={() => mallAuthComplete()}
                className={isBtnActive ? `btn-complete active` : `btn-complete`}
              >
                <span>결제하기</span>
              </button>
            </CollegeBox>
          ) : null}
        </ModalInner>
      </ModalWrap>
    </ThemeProvider>
  );
}

const ModalWrap = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.4);
  box-sizing: border-box;

  & > div:first-child {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  @media ${({ theme }) => theme.device.mobile} {
    align-items: flex-end;
    justify-content: flex-end;
  }
`;

const ModalInner = styled.div`
  position: relative;
  width: 48rem;
  background-color: #fff;
  border-radius: 1rem;
  overflow: hidden;

  .ico-close {
    position: absolute;
    top: 0.6rem;
    right: 1.2rem;
    width: 5rem;
    height: 5rem;
    text-indent: -9999rem;
    border: 0;
    outline: 0;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 2.5rem;
      height: 0.1rem;
      margin: auto;
      border-top: 0.1rem solid #000;
      transform: rotate(45deg);
    }
    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 2.5rem;
      height: 0.1rem;
      margin: auto;
      border-top: 0.1rem solid #000;
      transform: rotate(-45deg);
    }
    &:hover {
      background-color: #fff;
    }
  }

  button {
    position: relative;
    width: 100%;
    height: 8rem;
    color: #000;
    font-weight: 400;
    font-size: 2rem;
    border: 1px solid #e7e7f0;
    box-sizing: border-box;
    cursor: pointer;

    &:hover {
      background-color: #f7f7fc;
    }

    & + button {
      margin-top: 2rem;
      color: #ff5647;
    }
  }

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    min-height: calc(100% - 8.7rem);
    max-height: calc(100% - 8.7rem);
    border-radius: 2rem 2rem 0 0;
    overflow: auto;
    .ico-close {
      top: 1.2rem;
      right: 2.4rem;
      width: 10rem;
      height: 10rem;
      &:before {
        width: 5rem;
        border-top: 0.1rem solid #000;
      }
      &:after {
        width: 5rem;
        border-top: 0.1rem solid #000;
      }
    }
  }
`;

const CollegeBox = styled.div`
  padding: 10.3rem 0 3.2rem;
  dl {
    dt {
      font-weighgt: 400;
      font-size: 2rem;
      line-height: 1.3;
      color: #000;
      text-align: center;
      img {
        display: block;
        margin: 2.4rem auto;
        width: 75%;
      }
      strong {
        display: block;
        padding-bottom: 2rem;
        font-weight: 700;
        font-size: 3rem;
        color: #000;
        text-align: center;
      }
    }
    dd {
      padding: 1.5rem 3.2rem;
      box-sizing: border-box;
      input {
        width: 100%;
        padding: 1rem 1.8rem;
        font-size: 1.9rem;
        border-bottom: 0.1rem solid #e3e3ed;
        box-sizing: border-box;
        &::placeholder {
          color: #b7b7c8;
        }
      }
    }
  }
  .btn-complete {
    display: block;
    width: calc(100% - 6.4rem);
    height: 6.4rem;
    margin: 3rem auto 0;
    font-size: 1.7rem;
    color: #fff;
    background-color: #cfcfda;
    border: 0;
    outline: 0;
    border-radius: 0.3rem;
    &:hover {
      background-color: #cfcfda;
    }
    &.active {
      color: #000;
      background-color: #ffb900;
    }
  }
  @media ${({ theme }) => theme.device.mobile} {
    padding: 13.5rem 0 3.2rem;
    dl {
      dt {
        font-size: 3.1rem;
        strong {
          padding-bottom: 3.2rem;
          font-size: 4.7rem;
        }
        img {
          margin: 3rem auto;
          width: 75%;
          max-width: 60rem;
        }
      }
      dd {
        padding: 3rem 4.2rem;
        input {
          width: 100%;
          padding: 2rem 2.2rem;
          font-size: 2.9rem;
          border-bottom: 0.1rem solid #e3e3ed;
        }
      }
    }
    .btn-complete {
      height: 10rem;
      font-size: 2.7rem;
      border-radius: 0.4rem;
    }
  }
`;
