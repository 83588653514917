import React, { useState } from 'react'

import FaqSearchForm from 'pages/service/components/FaqSearchForm'
import useGetFaqList from 'pages/service/hooks/useGetFaqList'

import CustomerFaqCategory from './CustomerFaqCategory'
import CustomerFaqList from './CustomerFaqList'
import CustomerFooter from './CustomerFooter'
import CustomerTitleBar from './CustomerTitleBar'
import s from './WebviewCustomer.module.scss'

export default function CustomerFaq() {
  const [terminate, setTerminate] = useState(false)
  const [cate, setCate] = useState('BEST')
  const { faqList } = useGetFaqList({ cate, group: 'YANADOO_FAQ_GROUP', page: 1, size: 99 })

  const handleCategory = (code) => {
    setCate(code)
  }

  return (
    <article className={s.customerWrap}>
      {!terminate && <CustomerTitleBar title="자주 묻는 질문" />}
      <div className={s.customerInner} style={{ gap: 0 }}>
        <section className={s.sectionIntro}>
          <h3 className={s.sectionTitle}>자주 묻는 질문 FAQ</h3>
          <FaqSearchForm styleName={s.faqSearchForm} isPlatform />
          <CustomerFaqCategory handleCategory={handleCategory} cate={cate} />
        </section>
        <CustomerFaqList faqList={faqList} />
      </div>
      <CustomerFooter />
    </article>
  )
}
