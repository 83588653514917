import { useState } from 'react'

import ALERT_MESSAGE from 'common/alertMessage'
import CheckBox from 'components/form/checkbox'
import ModalContainer from 'components/modal/modalContainer'
import ModalPrivacyAgree from 'components/promotion/modal/ModalPrivacyAgree'
import ModalWrap from 'components/promotion/modal/ModalWrap'
import ResponsiveImage from 'components/ResponsiveImage'

import styles from './AnalyzingSection.module.css'
import { LTA_HOST_CDN } from 'pages/levelTestAdvanced/config'

export default function AnalyzingCompleteModal({ open, onPostConsultation, onClickResult }) {
  const [isChecked, setIsChecked] = useState(true)
  const [isShowAgreeModal, setIsShowAgreeModal] = useState(false)

  const handleChangeCheckbox = (e) => {
    setIsChecked(e.target.checked)
  }

  const handleClickPrivacyTerms = () => {
    setIsShowAgreeModal(true)
  }

  const handleAgreeModalToggle = () => {
    setIsShowAgreeModal((prev) => !prev)
  }

  const handleClickConsultation = () => {
    if (!isChecked) {
      alert(ALERT_MESSAGE.FORM_PRIVACY_REQUIRED)
      return
    }
    onPostConsultation()
  }

  return (
    <>
      <ModalContainer open={open}>
        <div className={styles.modal}>
          <div className={styles.modalHeader}>
            <ResponsiveImage pc={`${LTA_HOST_CDN}img-analyzing-complete.png`} mo={`${LTA_HOST_CDN}img-analyzing-complete-m.png`} alt="gift" />
          </div>
          <div className={styles.modalBody}>
            <p className={styles.modalTitle}>
              레벨테스트 결과가
              <br />
              도착했어요!
            </p>
            <p className={styles.modalDescription}>
              아래 개인정보 수집 · 이용에 동의하시면
              <br />
              전문 학습 가이드가 추가로 할인받을 수 있는
              <br />
              혜택과 경품에 대해 설명드려요😊
            </p>
            <button className={`${styles.modalButton} ${styles.modalButtonHighlight}`} onClick={handleClickConsultation}>
              레벨테스트 결과 확인
              <p className={styles.modalButtonDescription}>+ 전문가와 전화로 상담하기</p>
            </button>
            <button className={styles.modalButton} onClick={onClickResult}>
              레벨테스트 결과만 보기
            </button>
            <div className={styles.checkboxRow}>
              <CheckBox id="privacyAgree" isChecked={isChecked} label="개인정보 수집·이용 동의 (필수)" onChange={handleChangeCheckbox} />
              <button className={styles.termsButton} onClick={handleClickPrivacyTerms}>
                약관보기
              </button>
            </div>
          </div>
        </div>
      </ModalContainer>

      {/* 개인정보 동의 팝업 */}
      {isShowAgreeModal && (
        <ModalWrap onClose={handleAgreeModalToggle}>
          <ModalPrivacyAgree style={{ backgroundColor: 'rgba(0,0,0,0.6)' }} toggle={handleAgreeModalToggle} />
        </ModalWrap>
      )}
    </>
  )
}
