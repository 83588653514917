import React from 'react'

import cx from 'classnames'
import ModalPortal from 'ModalPortal'

import s from './ModalAiTravel.module.scss'
import { AI_TRAVEL_CONFIG } from '../config'

export default function ModalComplete() {
  const { TRAVEL_IMAGE_HOST } = AI_TRAVEL_CONFIG
  return (
    <ModalPortal>
      <div className={cx(s.modalAiTravel, s.complete)}>
        <img src={`${TRAVEL_IMAGE_HOST}img_mission_complete.png`} alt="미션 완료" />
      </div>
    </ModalPortal>
  )
}
