import React, { useState, useEffect } from 'react'

import html2canvas from 'html2canvas'
import moment from 'moment'

import api from 'common/api'

import 'assets/styles/modal.scss'
import 'assets/styles/coupon.scss'
import 'assets/styles/_modalCertificate.scss'

import iconRibbon from 'assets/images/bg_certificate_ribbon.png'
import imageStamp from 'assets/images/image_stamp.png'

let nowDate = new Date()
let year = nowDate.getFullYear()
let month = nowDate.getMonth() + 1
let date = nowDate.getDate()

export default function ModalCertificate({ btnClose, studySeq, handleCloseModalCertificate, subscriptionType, packageType }) {
  const [userData, setUserData] = useState(null)

  const dateType = (type, date) => {
    if (type === 'kor') {
      return moment(date).format('YYYY년 MM월 DD일')
    } else if (type === 'dot') {
      return moment(date).format('YYYY.MM.DD')
    }
  }

  const getIdentificationCard = () => {
    api
      .get('/v2/learning-history/identification-card/' + studySeq)
      .then((response) => {
        setUserData(response.data.data)
      })
      .catch((e) => {
        console.log(e)
      })
  }
  const getPackageCard = () => {
    api
      .get('/v2/learning-history/identification-card/package/' + studySeq)
      .then((response) => {
        setUserData(response.data.data)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const imageDownload = () => {
    html2canvas(document.getElementById('certificatePreview')).then(function (canvas) {
      let fileName = '수강증_' + Date.now()
      saveAsImage(canvas.toDataURL('image/jpg'), fileName + '.jpg')
    })
  }

  const saveAsImage = (uri, filename) => {
    let link = document.createElement('a')
    if (typeof link.download === 'string') {
      link.href = uri
      link.download = filename
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } else {
      window.open(uri)
    }
  }

  useEffect(() => {
    if (packageType === 'CLASS') {
      getIdentificationCard()
    } else {
      getPackageCard()
    }
  }, [packageType])

  return (
    userData && (
      <>
        <div className="modal-wrap">
          <div className="dimmed" onClick={handleCloseModalCertificate}></div>
          <div className="modal-inner">
            {btnClose && (
              <button type="button" className="btn-modal-close" onClick={handleCloseModalCertificate}>
                <i className="blind">닫기</i>
              </button>
            )}
            <div className="modal-header">
              <strong>수강증</strong>
            </div>
            <div className="modal-content certificate">
              <div className="certificate-box">
                <div className="certification-container">
                  <div className="certification-inner" id="certificatePreview">
                    <dl>
                      <img src={iconRibbon} className="certificate-ribbon" alt="ribbon" />
                      <dt>
                        <strong>수강증</strong>
                      </dt>
                      <dd>
                        <ul>
                          <li>
                            <span className="word-2">성 명</span>
                            <strong>{userData.userName}</strong>
                          </li>
                          <li>
                            <span className="word-2">I D</span>
                            <strong>{userData.userId}</strong>
                          </li>
                          <li>
                            <span className="word-5">교육과정명</span>
                            {packageType !== 'CLASS' ? <strong className="ellipsis line-clamp-2">{userData.packageName}</strong> : <strong className="ellipsis line-clamp-2">{userData.className}</strong>}
                          </li>

                          {subscriptionType !== 'U_PLUS' ? (
                            <li>
                              <span className="word-4">수 강 기 간</span>
                              <strong>
                                {dateType('dot', userData.classBeginDate)} ~ {dateType('dot', userData.classCloseDate)}
                              </strong>
                            </li>
                          ) : null}
                          <li>
                            <span className="word-3">출 석 률</span>
                            <strong>
                              {userData.totalAttendanceRate}% ({userData.attendanceDays}일 출석)
                            </strong>
                          </li>
                          <li>
                            <span className="word-3">달 성 율</span>
                            <strong>{userData.myAchievementRate}%</strong>
                          </li>
                          <li>
                            <span className="word-3">진 도 율</span>
                            <strong>{userData.progressRate}%</strong>
                          </li>
                          <li>
                            <span className="word-7">완강수/총강의</span>
                            <strong>
                              {userData.mediaCompleteLectureCount}/{userData.totalLectureCount}
                            </strong>
                          </li>
                        </ul>
                        <p>
                          위 사람은 주식회사 야나두에서
                          <br />
                          상기 교육과정을 위와 같이 수강함을 증명합니다.
                          <strong>
                            {year}년 {month}월 {date}일
                          </strong>
                        </p>
                        <img src={imageStamp} className="image-stamp" alt="ribbon" />
                      </dd>
                    </dl>
                  </div>
                </div>
                <div className="btn-flex-form">
                  <button className="button warning" onClick={imageDownload}>
                    다운로드
                  </button>
                </div>
              </div>
              <div className="notice-box">
                <strong>유의사항</strong>
                <ul>
                  <li> 현재 수강중이거나 수강완료된 상품에 관련된 정보를 확인할 수 있으며, 신청일 기준으로 조회가 가능합니다.</li>
                  <li>
                    문의사항은 고객센터 <a href="tel:1600-0563">1600-0563</a>
                    으로 문의주시길 바랍니다.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  )
}
