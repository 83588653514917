import React, { useState, useRef, useCallback } from 'react';
import ModalPortal from '../../ModalPortal';
import AlertModal from './AlertModal';

const ModalOrderCancel = ({
  btnClose,
  handleCloseModal,
  putOrderCancel,
  orderNo,
  orderDate,
}) => {
  const selectRef = useRef();
  const [flag, setFlag] = useState(false);
  const [modalopen, setModalopen] = useState(false);
  const [textareaValue, setTextareaValue] = useState('');
  const [textareaValueLength, setTextareaValueLength] = useState(null);
  const [cancelValue, setCancelValue] = useState('');

  const handleOpenModal = (e) => {
    if (cancelValue.length !== 0 || textareaValue.trim().length !== 0) {
      document.body.classList.add('modal-open');
      setModalopen(true);
    } else {
      alert('취소 사유를 입력해주세요.');
      return false;
    }
  };

  const handleCloseModal2 = useCallback((e) => {
    document.body.classList.remove('modal-open');
    setModalopen(false);
  }, []);

  // 직접입력일 때 취소이유
  const handleTextareaValue = (e) => {
    setTextareaValue(e.target.value);
    setTextareaValueLength(e.target.value.length);
  };

  const onChangeSelect = (e) => {
    const value = e.target.value;
    e.target.style.color = '#000';

    setCancelValue(e.target.value);

    if (value === '직접입력') {
      setFlag(true);
      setCancelValue('');
    } else {
      setFlag(false);
      setTextareaValue('');
    }
  };

  const handleConfirmClose = (orderNo) => {
    if (orderNo && textareaValue) {
      handleCloseModal2();
      putOrderCancel(textareaValue, orderNo);
      // console.log('---textareaValue---');
      // console.log(textareaValue);
    }
    if (orderNo && cancelValue && cancelValue !== '직접입력') {
      handleCloseModal2();
      putOrderCancel(cancelValue, orderNo);
      // console.log('---cancelValue---');
      // console.log(cancelValue);
    }
  };

  return (
    <>
      <div className="modal-wrap">
        <div className="dimmed" onClick={handleCloseModal}></div>
        <div className="modal-inner">
          {btnClose && (
            <button
              type="button"
              className="btn-modal-close"
              onClick={handleCloseModal}
            >
              <i className="blind">닫기</i>
            </button>
          )}
          <div className="modal-header">
            <strong>주문취소</strong>
          </div>
          <div className="modal-content">
            <div className="modal-order-info">
              <dl>
                <dt>주문일자</dt>
                <dd>{orderDate && orderDate.substring(0, 10)}</dd>
              </dl>
              <dl>
                <dt>주문번호</dt>
                <dd>{orderNo && orderNo}</dd>
              </dl>
            </div>
            <div className="modal-order-cancle">
              <strong>취소 사유</strong>
              <select
                name=""
                className="modal-select-box"
                ref={selectRef}
                onChange={onChangeSelect}
                defaultValue="default"
              >
                <option value="default" disabled>
                  취소사유를 선택해주세요
                </option>
                <option value="품절">품절</option>
                <option value="다른 상품 주문 예정">다른 상품 주문 예정</option>
                <option value="구매의사 없어짐">구매의사 없어짐</option>
                <option value="배송 지연">배송 지연</option>
                <option value="직접입력">직접입력</option>
              </select>
              {flag && (
                <textarea
                  maxLength="200"
                  placeholder="취소사유를 입력해주세요"
                  className="basic-textarea"
                  value={textareaValue}
                  onChange={handleTextareaValue}
                ></textarea>
              )}
              <button className="btn active" onClick={handleOpenModal}>
                <span>저장</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      {modalopen ? (
        <ModalPortal>
          <AlertModal
            btnGroup
            msg={'주문취소를 진행하시겠습니까?'}
            handleCloseModal={handleCloseModal2}
            handleConfirmClose={() => {
              handleConfirmClose(orderNo);
            }}
          />
        </ModalPortal>
      ) : null}
    </>
  );
};

export default ModalOrderCancel;
