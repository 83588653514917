import { useEffect } from 'react'
import { useLocation } from 'react-router'

export default function useChScrollUnlock() {
  const location = useLocation()

  useEffect(() => {
    const handleBodyClassChange = () => {
      if (
        location.pathname.includes('/mall/') ||
        location.pathname.includes('/AInativespeakertalk/')
      ) {
        const bodyClass = document.body.classList
        if (bodyClass.contains('ch-scroll-lock')) {
          bodyClass.remove('ch-scroll-lock')
        }
      }
    }

    handleBodyClassChange()

    const observer = new MutationObserver(handleBodyClassChange)
    observer.observe(document.body, { attributes: true, attributeFilter: ['class'] })

    return () => {
      observer.disconnect()
    }
  }, [location.pathname])
}
