function eventWinnerFrmChk() {
  const userName = document.getElementById('userName')
  const zipCode = document.getElementById('zipCode')
  const baseAddress = document.getElementById('baseAddress')
  const detailAddress = document.getElementById('detailAddress')
  const agreePrivacy = document.getElementById('agreePrivacy')

  if (!userName.value) {
    alert('이름을 입력해 주세요.')
    userName.focus()
    return false
  }

  if (!zipCode.value) {
    alert('우편번호를 입력해 주세요.')
    zipCode.focus()
    return false
  }

  if (!baseAddress.value) {
    alert('기본 주소를 입력해 주세요.')
    baseAddress.focus()
    return false
  }

  if (!detailAddress.value) {
    alert('상세 주소를 입력해 주세요.')
    detailAddress.focus()
    return false
  }

  if (!agreePrivacy.checked) {
    alert('개인정보 수집 동의를 체크해 주세요.')
    return false
  }

  // document.eventWinnerListFrm.submit();
  alert('저장 되었습니다.')
}

const EventWinnerAddress = () => {
  return (
    <>
      <section className="special-title-col">
        <div className="inner-col">
          <h1>
            <strong>당첨을 축하합니다!</strong>경품 배송을 위해 수령지 주소를 입력해주세요
          </h1>
        </div>
      </section>
      <section className="event-winner-col">
        <div className="inner-col">
          <h1 className="txt-hidden">당첨 결과 확인</h1>
          <form name="eventWinnerListFrm" action="#">
            <div className="winner-list-box">
              <dl>
                <dt>수령자명</dt>
                <dd>
                  <input type="text" id="userName" placeholder="이름을 입력해 주세요." />
                </dd>
              </dl>
              <dl>
                <dt>주소</dt>
                <dd className="flex-box">
                  <input type="text" id="zipCode" placeholder="우편번호를 입력해 주세요." />
                  <button type="button" className="btn-address">
                    주소찾기
                  </button>
                </dd>
                <dd>
                  <input type="text" id="baseAddress" placeholder="주소를 입력해 주세요." />
                </dd>
              </dl>
              <dl>
                <dt>상세주소</dt>
                <dd>
                  <input type="text" id="detailAddress" placeholder="상세 주소를 입력해 주세요." />
                </dd>
              </dl>
              <div className="agree-box">
                <label>
                  <input type="checkbox" id="agreePrivacy" />
                  <span>개인정보 수집 동의</span>
                </label>
                <button type="button" className="btn-underline">
                  내용보기
                </button>
              </div>
              <button type="button" className="btn-complete" onClick={eventWinnerFrmChk}>
                확인
              </button>
            </div>
          </form>
        </div>
      </section>
    </>
  )
}

export default EventWinnerAddress
