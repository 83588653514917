import { useEffect } from 'react'

export default function useRemoveInlineStyles() {
  useEffect(() => {
    // MutationObserver 콜백 함수
    const observerCallback = (mutationsList) => {
      mutationsList.forEach((mutation) => {
        if (mutation.type === 'attributes' && mutation.attributeName === 'style') {
          // html 또는 body 태그의 인라인 스타일이 변경되면 제거
          if (mutation.target.tagName === 'HTML' || mutation.target.tagName === 'BODY') {
            mutation.target.removeAttribute('style')
            console.log(`${mutation.target.tagName} 태그의 인라인 스타일이 제거되었습니다.`)
          }
        }
      })
    }

    // MutationObserver 설정
    const observer = new MutationObserver(observerCallback)
    const config = { attributes: true, attributeFilter: ['style'] }

    // html 및 body 태그 감시 시작
    observer.observe(document.documentElement, config) // HTML 태그
    observer.observe(document.body, config) // body 태그

    // 컴포넌트가 언마운트될 때 MutationObserver 해제
    return () => {
      observer.disconnect()
    }
  }, []) // 빈 배열을 사용하여 컴포넌트가 마운트될 때 한 번만 실행
}
