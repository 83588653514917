import React from 'react'

import s from './ToastMessage.module.scss'

const toastMessage = {
  chat: {
    intro: '채팅 모드로 전환되었어요.'
  },
  audio: {
    intro: '음성 모드로 전환되었어요.',
    suggestion: '이제 AI가 자동으로 말을 걸어요. 볼륨을 조절해 주세요.'
  },
  caution: {
    intro: '대기 상태일 때만 채팅 모드로 변경할 수 있어요.',
    suggestion: 'AI의 차례가 끝난 후 다시 시도해 주세요.'
  },
  sound_on: {
    intro: 'AI 음성 자동 듣기 ON',
    suggestion: '이제 AI의 음성을 계속해서 들을 수 있어요.'
  }
}

export default function ToastMessage({ type }) {
  if (!type || type === null) return
  return (
    <div className={s.toast}>
      <p>
        {toastMessage[type].intro}
        {toastMessage[type].suggestion && (
          <>
            <br />
            {toastMessage[type].suggestion}
          </>
        )}
      </p>
    </div>
  )
}
