import React, { useState, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import useSubscription from 'hooks/useSubscription'
import ModalPortal from 'ModalPortal'
import AuthService from 'services/authService'

import api from 'common/api'
import * as config from 'common/config'
import { getCookie } from 'common/Cookie'

import ModalMemberType from '../modal/ModalMemberType'
import NoContent from '../NoContent'

export default function KakaoAuth() {
  const navigate = useNavigate()
  const accessUserId = new URL(window.location.href).searchParams.get('id')
  const accessUserToken = new URL(window.location.href).searchParams.get('token')
  const isFrame = new URL(window.location.href).searchParams.get('isFrame')
  const [encryptUserToken, setEncryptUserToken] = useState(null)
  const [modalopenSocialType, setModalopenSocialType] = useState(false)

  const signType = sessionStorage.getItem('signType')
  const { subScriptionModal, postSubscription } = useSubscription()

  const handleOpenModalMemberType = useCallback((e) => {
    document.body.classList.add('modal-open')
    setModalopenSocialType(true)
  }, [])

  const handleCloseModalMemberType = useCallback((e) => {
    document.body.classList.remove('modal-open')
    setModalopenSocialType(false)
    // 소셜 로그인 후 historyUrl 쿠키값이 저장되어 있다면 해당 URL로 페이지 이동.
    if (getCookie('historyUrl') !== '') {
      if (window.location.href !== getCookie('historyUrl')) window.location.href = getCookie('historyUrl')
    } else {
      navigate('/')
    }
  }, [])

  const getEncrypToken = () => {
    api
      .get(config.AUTH_API_HOST + '/auth/v2/sign-in/encrypt-sns-token?snsToken=' + accessUserToken)
      .then((response) => {
        setEncryptUserToken(response.data.data.encryptSnsToken)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  // 소셜 토큰 암호화 o
  const handleLoginKakao = async (isFrame) => {
    try {
      const response = await api.post(`${config.AUTH_API_HOST}/auth/v2/sign-in/social/get-token?deviceType=0&snsToken=${encryptUserToken}&snsType=3`)

      if (response.data.meta.code !== 200) {
        handleOpenModalMemberType()
        return false
      }

      if (signType === 'uPlus') {
        const data = { userSeq: response.data.data.user.userSeq }

        // subscription post
        await postSubscription(data)

        AuthService.setUserInfo(response.data.data)
        return
      }

      AuthService.setUserInfo(response.data.data)
      AuthService.biginUserInfo(isFrame)
    } catch (e) {
      console.log(e)
    }
  }
  useEffect(() => {
    if (accessUserToken) {
      getEncrypToken()
    }
  }, [accessUserToken])

  useEffect(() => {
    if (encryptUserToken) {
      handleLoginKakao(isFrame)
    }
  }, [encryptUserToken])

  return (
    <>
      <div className="social-login kakao">
        <NoContent titleMsg={''} />
      </div>
      {modalopenSocialType ? (
        <ModalPortal>
          <ModalMemberType title={'회원가입'} btnClose handleCloseModal={handleCloseModalMemberType} snsType={3} accessToken={accessUserId} encryptToken={encryptUserToken} />
        </ModalPortal>
      ) : null}
      {subScriptionModal}
    </>
  )
}
