import React, { useState } from 'react'
import Lottie from 'react-lottie'

import AiTravelTranslateBtn from 'pages/aiTalk/components/AiTravelTranslateBtn'
import ButtonAudio from 'pages/aiTalk/components/ButtonAudio'
import LottieData from 'pages/aiTalk/lottieData.json'

import s from './Chat.module.scss'

export default function MessageUser({ message, userSeq }) {
  const [translateText, setTranslateText] = useState(null)
  const [transLoading, setTransLoading] = useState(false)
  const [translated, setTranslated] = useState(false)

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: LottieData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  return (
    <>
      <div className={`${s.message} ${s.userMessage}`}>
        <p>{message}</p>
        <div className={s.btnGroup}>
          <ButtonAudio text={message} yellow userSeq={userSeq} />
          <AiTravelTranslateBtn
            type={'USER'}
            message={message}
            translated={translated}
            translateText={translateText}
            setTransLoading={setTransLoading}
            setTranslated={setTranslated}
            setTranslateText={setTranslateText}
            userSeq={userSeq}
          />
        </div>
      </div>
      {transLoading && !translateText && (
        <p className={s.translateText}>
          <div className={s.lottieBox}>
            <Lottie options={defaultOptions} />
          </div>
        </p>
      )}
      {translateText && translated && <p className={s.translateText}>{translateText}</p>}
    </>
  )
}
