import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import useSubscription from 'hooks/useSubscription'
import AuthService from 'services/authService'

import api from 'common/api'
import * as config from 'common/config'
import EditProfile from 'components/EditProfile'
import Join from 'components/Join'
import JoinComplete from 'components/JoinComplete'

import 'assets/styles/modal.scss'
import 'assets/styles/modalJoin.scss'

export default function ModalJoin({
  title,
  btnClose,
  type,
  snsType,
  accessToken,
  encryptToken,
  userToken,
  handleCloseModal,
  whereJoinType,
  handleOpenModalFindId,
  handleCloseModalJoin
}) {
  const joinType = type
  const navigate = useNavigate()

  // whereJoinType 회원 가입 경로
  // CLASS_REGISTRATION(12, "수강신청"),
  // YAFIT_FIND_EFFECT(74, "야핏 효과찾기"),
  // YANADOO2_APP(80, "야나두3.0 앱"),
  // YAFIT(81, "야핏 사이클"),
  // YANADOO_WEB(90, "야나두3.0 웹"),
  // ENGLISH_PROMOTION(91, "영어 프로모션")

  // step: JOIN, PROFILE, COMPLETE
  const [step, setStep] = useState('JOIN')
  const [profileAccessToken, setProfileAccessToken] = useState(null)
  const [signUpResultApiToken, setSignUpResultApiToken] = useState(null)
  const [userId, setUserId] = useState(null)

  const signType = sessionStorage.getItem('signType')
  const { subScriptionModal, postSubscription } = useSubscription()

  const handleUserInfo = (token, id) => {
    setProfileAccessToken(token)
    setUserId(id)

    if (token) {
      setStep('PROFILE')
    }
  }

  const handleSignUpToken = (token) => {
    setSignUpResultApiToken(token)

    if (token) {
      setStep('COMPLETE')
    }
  }

  // 소셜 로그인 -  소셜 토큰 암호화 o
  const handleLoginSocial = async () => {
    try {
      const response = await api.post(
        `${config.AUTH_API_HOST}/auth/v2/sign-in/social/get-token?deviceType=0&snsToken=${encryptToken}&snsType=${snsType}`
      )

      if (response.data.meta.code !== 200) {
        alert('실패')
        return false
      }

      if (signType === 'uPlus') {
        const data = { userSeq: response.data.data.user.userSeq }

        // subscription post
        await postSubscription(data)

        AuthService.setUserInfo(response.data.data)
        return
      }

      AuthService.setUserInfo(response.data.data)

      window.location.href = '/'
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  return (
    <>
      <div
        className={
          'modal-wrap' +
          (step === 'PROFILE'
            ? ' profile'
            : ' modal-form' + (step === 'JOIN' ? ' join' : ' complete'))
        }
      >
        <div
          className="dimmed"
          onClick={
            joinType === 'SOCIAL' && step !== 'JOIN'
              ? handleLoginSocial
              : joinType === 'PAYMENT' && step === 'JOIN'
              ? () => navigate('/')
              : handleCloseModal
          }
        ></div>
        <div className="modal-inner">
          {btnClose && (
            <button
              type="button"
              className="btn-modal-close"
              onClick={
                joinType === 'SOCIAL' && step !== 'JOIN'
                  ? handleLoginSocial
                  : joinType === 'PAYMENT' && step === 'JOIN'
                  ? () => navigate('/')
                  : handleCloseModal
              }
            >
              <i className="blind">닫기</i>
            </button>
          )}
          <div className="modal-header">
            {step === 'JOIN' ? (
              <h3>
                <i className="blind">{title}</i>
              </h3>
            ) : step === 'PROFILE' ? (
              <i className="blind">프로필 수정</i>
            ) : step === 'COMPLETE' ? (
              <i className="blind">완료</i>
            ) : null}
          </div>
          {step === 'JOIN' ? (
            <Join
              handleUserInfo={handleUserInfo}
              joinType={joinType}
              snsType={snsType}
              accessToken={accessToken}
              userToken={userToken}
              whereJoinType={whereJoinType}
              handleOpenModalFindId={handleOpenModalFindId}
              handleCloseModalJoin={handleCloseModalJoin}
            />
          ) : step === 'PROFILE' ? (
            <EditProfile
              token={profileAccessToken}
              userId={userId}
              handleSignUpToken={handleSignUpToken}
            />
          ) : step === 'COMPLETE' ? (
            <JoinComplete
              token={signUpResultApiToken}
              userId={userId}
              joinType={joinType}
              snsType={snsType}
              encryptToken={encryptToken}
              handleLoginSocial={handleLoginSocial}
              handleCloseModal={handleCloseModal}
            />
          ) : null}
        </div>
      </div>
      {subScriptionModal}
    </>
  )
}
