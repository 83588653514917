import React from 'react';

import IcoDownLoad from '../../assets/images/ico_download_54x54b.png';

import styled, { ThemeProvider } from 'styled-components';
import theme from 'common/theme';

export default function FileDownLoad({ data }) {
  const handleAlert = () => {
    alert('파일은 앱에서 다운받아주세요.');
  };

  return (
    <ThemeProvider theme={theme}>
      {data && (
        <FileDownLoadLink onClick={handleAlert}>
          <strong>{data.name}</strong>
        </FileDownLoadLink>
      )}
    </ThemeProvider>
  );
}

const FileDownLoadLink = styled.a`
  display: inline-flex;
  align-items: center;
  width: 38.2rem;
  height: 6rem;
  margin-top: 1.5rem;
  padding: 1.7rem 2.4rem;
  border: 1px solid #dfdfea;
  border-radius: 79rem;
  background: #fff url(${IcoDownLoad}) 94% 50% no-repeat;
  background-size: 1.7rem auto;
  box-sizing: border-box;
  cursor: pointer;

  strong {
    ${theme.StyleEllipsis}
    display: block;
    padding-right: 3rem;
    font-weight: 700;
    font-size: 1.6rem;
    white-space: nowrap;
  }

  span {
    margin-left: 0.9rem;
    padding-top: 0.2rem;
    font-weight: 700;
    font-size: 1.2rem;
    color: #b7b7c8;
  }

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    height: 8.4rem;
    margin-top: 3rem;
    padding: 2.1rem 3.8rem;
    border-radius: 120rem;
    background-size: 3rem auto;

    strong {
      font-size: 2.4rem;
    }

    span {
      margin-left: 1.9rem;
      font-size: 2rem;
    }
  }
`;
