import React, { useEffect, useState } from 'react'

import cx from 'classnames'

import s from './MissionList.module.scss'
import ModalComplete from './ModalComplete'

export default function MissionList({ missions, isChecked }) {
  const [open, setOpen] = useState(true)
  const [checkCount, setCheckCount] = useState(0)
  const [allChecked, setAllChecked] = useState(false)
  const [complete, setComplete] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setOpen(false)
    }, 3000)

    return () => clearTimeout(timer)
  }, [])

  useEffect(() => {
    const count = Object.values(isChecked).filter((value) => value).length
    setCheckCount(count)

    if (count > 0 && count === Object.keys(isChecked).length) {
      setAllChecked(true)
      setComplete(true)
    } else {
      setAllChecked(false)
    }
  }, [isChecked])

  useEffect(() => {
    let cTimer = null
    if (complete) {
      cTimer = setTimeout(() => {
        setComplete(false)
      }, 3000)
    }

    return () => clearTimeout(cTimer)
  }, [complete])

  return (
    <>
      <div className={s.fixedMissions}>
        <hgroup className={s.titleGroup}>
          <h4>
            대화 미션
            {allChecked ? (
              <span className={s.all}> 모두 완료!</span>
            ) : (
              <span className={s.count}>
                {' '}
                진행중 ({checkCount}/{missions.length})
              </span>
            )}
          </h4>
          <button className={cx(s.btnArrow, { [s.down]: !open })} onClick={() => setOpen(!open)}>
            toggle button
          </button>
        </hgroup>
        <ul className={cx(s.missionList, { [s.open]: open })}>
          {missions.map(({ subject, eng, kor }, index) => (
            <li key={index} className={cx({ [s.active]: isChecked[index + 1] })}>
              <dl className={s.mission}>
                <dt>{subject}</dt>
                <dd>{eng}</dd>
                <dd>{kor}</dd>
              </dl>
            </li>
          ))}
        </ul>
      </div>
      {complete && <ModalComplete />}
    </>
  )
}
