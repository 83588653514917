import React, { useState, useCallback, useLayoutEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import ModalPortal from '../ModalPortal';
import ModalEditProfile from './modal/ModalEditProfile';
import ModalMindsetIntro from './modal/ModalMindsetIntro';
import ModalMindsetResult from './modal/ModalMindsetResult';
import AlertModal from './modal/AlertModal';
import api from 'common/api';
import * as config from 'common/config';
import AuthService from 'services/authService';
import '../assets/styles/lnb.scss';

const Lnb = () => {
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const [currentIdx, setCurrentIdx] = useState(0);
  const [modalopen, setModalopen] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [modalopenMindset, setModalopenMindset] = useState(false);
  const [modalopenMindsetResult, setModalopenMindsetResult] = useState(false);
  const [modalopenAlert, setModalopenAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleOpenModalMindsetIntro = useCallback((e) => {
    document.body.classList.add('modal-open');
    setModalopenMindset(true);
  }, []);

  const handleCloseModalMindsetIntro = useCallback((e) => {
    document.body.classList.remove('modal-open');
    setModalopenMindset(false);
  }, []);

  const handleOpenModalMindsetResult = useCallback((e) => {
    document.body.classList.add('modal-open');
    setModalopenMindsetResult(true);
  }, []);

  const handleCloseModalMindsetResult = useCallback((e) => {
    document.body.classList.remove('modal-open');
    setModalopenMindsetResult(false);
  }, []);

  const lnbMenuLists = [
    {
      menu: 'MY 클래스',
      url: 'myclass/1',
    },
    {
      menu: '주문배송',
      url: 'mypage/order/list',
    },
    {
      menu: '쿠폰/수강권',
      url: 'mypage/coupon',
    },
    {
      menu: '나의 문의/후기',
      url: 'mypage/qna/inquiry',
    },
    {
      menu: '학습 기기 관리',
      url: 'service/device',
    },
    {
      menu: '회원정보수정',
      url: 'member/auth',
    },
  ];

  const handleOpenModal = useCallback((e) => {
    document.body.classList.add('modal-open');
    setModalopen(true);
  }, []);

  const handleCloseModal = useCallback((e) => {
    document.body.classList.remove('modal-open');
    setModalopen(false);
  }, []);

  const handleOpenModalAlert = useCallback((e) => {
    document.body.classList.add('modal-open');
    setModalopenAlert(true);
  }, []);

  const handleCloseAlertModal = useCallback((e) => {
    document.body.classList.remove('modal-open');
    setModalopenAlert(false);
  }, []);

  const onClickLnbMenu = (idx) => {
    // window.scrollTo(0,0);
    setCurrentIdx(idx);
  };

  const handleSignOut = () => {
    handleOpenModalAlert();
  };

  const handleConfirmClose = (status) => {
    setModalopenAlert(!status);
    // 모달 확인 버튼 누를 시 로그아웃
    AuthService.signOut(() => (window.location.href = '/'));
  };

  // 유저정보 가져오기
  const getUserInfo = () => {
    api
      .get(config.AUTH_API_HOST + '/auth/v2/user')
      .then((response) => {
        setUserInfo(response.data.data);
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useLayoutEffect(() => {
    getUserInfo();
  }, []);

  return (
    <>
      <aside id="lnb">
        <div className="lnb-col">
          {!isLoading && (
            <>
              <div className="lnb-user-col">
                <button
                  className="user-thumb"
                  onClick={handleOpenModal}
                  style={{
                    backgroundImage: `url(${userInfo && userInfo.profileImageUrl})`,
                  }}
                >
                  <i className="blind">썸네일</i>
                </button>
                <strong className="nickname">{userInfo && userInfo.nickName}</strong>
                {/* <p className="ellipsis">멤버십가입(22.12.01)</p> */}
                {/* <div className="user-point">
              <span>999,999,999</span>
            </div> */}
                {userInfo && userInfo.entrance.entranceStatus !== 'ENTRANCE' ? (
                  // 입학 상태 아님
                  <div className="user-yta normal">
                    <strong>일반회원</strong>
                  </div>
                ) : userInfo &&
                  userInfo.entrance.entranceStatus === 'ENTRANCE' &&
                  !userInfo.entrance.isWritePledgeTarget &&
                  !userInfo.entrance.isWriteEntrancePledge ? (
                  // 입학 상태이면서, 마인드셋 강의 수강 안함, 서약서 미작성
                  <div className="user-yta">
                    <strong>입학회원</strong>
                  </div>
                ) : userInfo &&
                  userInfo.entrance.entranceStatus === 'ENTRANCE' &&
                  userInfo.entrance.isWritePledgeTarget &&
                  !userInfo.entrance.isWriteEntrancePledge ? (
                  // 입학 상태이면서, 마인드셋 강의 수강함, 서약서 작성을 안한경우
                  <div className="user-yta">
                    <strong>입학회원</strong>
                    <button type="button" onClick={handleOpenModalMindsetIntro}>
                      서약서 작성하기
                    </button>
                  </div>
                ) : userInfo &&
                  userInfo.entrance.entranceStatus === 'ENTRANCE' &&
                  userInfo.entrance.isWritePledgeTarget &&
                  userInfo.entrance.isWriteEntrancePledge ? (
                  // 입학 상태이면서, 마인드셋 강의 수강함, 서약서 작성을 한 경우
                  <div className="user-yta">
                    <strong>입학회원</strong>
                    <button type="button" onClick={handleOpenModalMindsetResult}>
                      나의 서약서 보기
                    </button>
                  </div>
                ) : (
                  ''
                )}
              </div>
              <ul className="lnb-menu-col">
                {lnbMenuLists.map((lnbMenuList, idx) => {
                  return (
                    <li
                      key={idx}
                      className={currentIdx === idx ? 'active' : null}
                      onClick={() => onClickLnbMenu(idx)}
                    >
                      <NavLink
                        to={{
                          pathname: `/${lnbMenuList.url}`,
                        }}
                        // activeClassName="active"
                      >
                        {lnbMenuList.menu}
                      </NavLink>
                    </li>
                  );
                })}
                {isMobile && (
                  <li>
                    <button type="button" className="btn-logout" onClick={handleSignOut}>
                      <span>로그아웃</span>
                    </button>
                  </li>
                )}
              </ul>
              <div className="lnb-btn-group">
                <a href="tel:1600-0563" className="btn positive phone">
                  <span>전화상담 1600-0563</span>
                </a>
                <a href="https://pf.kakao.com/_xeWxjMu" target="_blank" className="btn kakao">
                  <span>카카오톡 상담</span>
                </a>
              </div>
              {/* <div className="lnb-notice-col">
            <span>문의전화</span>
            <a href="tel:1600-0563">1600.0563</a>
            <p>평일 오전 9시 &#126; 오후 6시</p>
          </div> */}
            </>
          )}
        </div>
      </aside>
      {modalopen ? (
        <ModalPortal>
          <ModalEditProfile btnClose userInfo={userInfo} handleCloseModal={handleCloseModal} />
        </ModalPortal>
      ) : null}
      {modalopenMindset ? (
        <ModalPortal>
          <ModalMindsetIntro
            title={'서약서 작성'}
            btnClose
            handleCloseModal={handleCloseModalMindsetIntro}
          />
        </ModalPortal>
      ) : null}
      {modalopenMindsetResult ? (
        <ModalPortal>
          <ModalMindsetResult
            title={'서약서 보기'}
            btnClose
            handleCloseModal={handleCloseModalMindsetResult}
          />
        </ModalPortal>
      ) : null}
      {modalopenAlert ? (
        <ModalPortal>
          <AlertModal
            // btnClose
            btnGroup
            msg={'로그아웃을 하시겠습니까?'}
            handleCloseModal={handleCloseAlertModal}
            handleConfirmClose={handleConfirmClose}
          />
        </ModalPortal>
      ) : null}
    </>
  );
};

export default Lnb;
