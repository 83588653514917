import React, { useState, useCallback } from 'react';

import api from 'common/api';

import ModalPortal from '../../ModalPortal';
import ModalDelivery from '../../components/modal/ModalDelivery';

export default function ButtonDelivery({ orderNo }) {
  const [modalopen, setModalopen] = useState(false);

  const [orderDelivery, setOrderDelivery] = useState([]);

  const handleOpenModal = (orderNo) => {
    document.body.classList.add('modal-open');
    setModalopen(true);
    getOrderDelivery(orderNo);
  };

  const handleCloseModal = useCallback((e) => {
    document.body.classList.remove('modal-open');
    setModalopen(false);
  }, []);

  // 배송지 조회 API
  const getOrderDelivery = (orderNo) => {
    api
      .get(`v2/order/delivery?orderNo=${orderNo}`)
      .then((response) => {
        if (response.data.meta.code !== 200) {
          alert(response.data.meta.message);
        } else {
          setOrderDelivery(response.data.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // 배송지 변경 API
  const putOrderDelivery = (
    deliveryAddr1,
    deliveryAddr2,
    deliveryUsername,
    deliveryPhone,
    deliveryRequest,
    zipCode,
    deliverySeq
  ) => {
    const data = {
      deliveryAddr1: deliveryAddr1,
      deliveryAddr2: deliveryAddr2,
      deliveryUsername: deliveryUsername,
      deliveryPhone: deliveryPhone,
      deliveryRequest: deliveryRequest,
      zipCode: zipCode,
      deliverySeq: deliverySeq,
    };

    api
      .put('/v2/order/delivery', data)
      .then((response) => {
        if (response.data.meta.code !== 200) {
          alert(response.data.meta.message);

          return false;
        }

        alert('배송지 정보가 저장되었습니다.');
        window.location.reload();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <>
      <button
        className="button stroke"
        onClick={() => {
          handleOpenModal(orderNo);
        }}
      >
        <span>배송지 확인/변경</span>
      </button>

      {modalopen ? (
        <ModalPortal>
          <ModalDelivery
            orderDelivery={orderDelivery}
            putOrderDelivery={putOrderDelivery}
            btnClose
            handleCloseModal={handleCloseModal}
          />
        </ModalPortal>
      ) : null}
    </>
  );
}
