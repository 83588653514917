import { Link } from 'react-router-dom'

export default function DynamicLink({ className = '', pathname, children, ...props }) {
  if (pathname.includes('http') || pathname.includes('.html'))
    return (
      <a href={pathname} className={className} {...props}>
        {children}
      </a>
    )
  return (
    <Link to={pathname} className={className} {...props}>
      {children}
    </Link>
  )
}
