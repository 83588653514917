import { useEffect, useState } from 'react'

import api from 'common/api'

export default function useGetFaqCategory(groupCode) {
  const [category, setCategory] = useState([])

  const fetchFaqCategory = async () => {
    try {
      const response = await api.get(`/v2/faq/group?groupCode=${groupCode}`)
      const data = response.data.data
      setCategory(data)
    } catch (e) {
      console.error(`fetch faq category error (${groupCode}): `, e)
    }
  }

  useEffect(() => {
    fetchFaqCategory()
    return () => setCategory([])
  }, [groupCode])

  return { category }
}
