import React, { useState, useCallback, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';

import ModalPortal from 'ModalPortal';
import styled, { ThemeProvider } from 'styled-components';


import api from 'common/api';
import * as config from 'common/config';
import MobileHeader from 'components/common/MobileHeader';
import DetailReviewForm from 'components/DetailReviewForm';
import AlertModal from 'components/modal/AlertModal';
import ModalReviewWrite from 'components/modal/ModalReviewWrite';

// import '../../../assets/styles/main.scss';

import icoDownload_54x54b from '../../../assets/images/ico_download_54x54b.png';
import theme from '../../../common/theme';
import Lnb from '../../../components/Lnb';

const ListDetailView = styled.section`
  padding: 8.2rem 0 0;

  .title-col {
    padding: 0 0 2rem;
    border-bottom: 0.3rem solid #000;

    strong {
      font-weight: 700;
      font-size: 2rem;
    }
  }

  pre {
    font-size: 1.6rem;
    white-space: pre-wrap;
  }

  .detail-title-area {
    position: relative;
    padding: 2.5rem 2.8rem;
    border-bottom: 0.1rem solid #e7e7f0;

    .thumb-nail {
      width: 7rem;
      height: 7rem;
    }

    .tit-flex-form {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .sub-tit {
        flex: 0 1 70%;
        font-weight: 700;
        font-size: 1.6rem;
        color: #28284b;

        &.black {
          font-size: 1.8rem;
          color: #000;
        }
      }

      .info {
        .check {
          font-weight: 700;
          font-size: 1.6rem;
          color: #a0a0b6;

          & + .date {
            margin-left: 1rem;
          }

          &.completed {
            color: #17c624;
          }
        }

        .date {
          font-weight: 400;
          font-size: 1.2rem;
          color: #a0a0b6;
        }
      }
    }

    .main-tit {
      margin-top: 0.5rem;
      font-weight: 700;
      font-size: 2rem;
    }

    .btn-group {
      //position: absolute;
      //bottom: 2.5rem;
      //right: 2.8rem;
      text-align: right;

      button {
        width: auto;
        height: auto;
        font-weight: 700;
        font-size: 1.6rem;
        background-color: transparent;
        border: none;

        &.btn-delete {
          color: #ff5647;
        }

        & + button {
          &::before {
            content: '';
            display: inline-block;
            width: 0.1rem;
            height: 1.1rem;
            margin: 0 1.2rem;
            background-color: #cfcfda;
          }
        }
      }
    }

    &.board {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .thumb-area {
        flex: 0 1 auto;

        // width: 7rem;
        // height: 7rem;

        span {
          display: block;
          width: 7rem;
          height: 7rem;
          border-radius: 3px;
          background-color: #e7e7f0;
          background-repeat: no-repeat;
          background-position: 50% 50%;
          background-size: cover;
          overflow: hidden;
        }

        + .info-area {
          flex: 1 1 auto;
          width: 80%;
          margin-left: 1.9rem;
        }
      }

      .info-area {
        display: flex;
        align-items: center;
        flex: 0 1 auto;
        width: 100%;

        .main-tit {
          display: inline-block;
          max-width: 80%;
        }

        .star-point {
          height: 1.8rem;
          margin-top: 1rem;
        }

        .class {
          flex: 0 1 auto;
          width: 72%;

          .main-tit {
            display: block;
            max-width: 90%;
          }

          .sub-tit {
            font-size: 1.9rem;
            color: #a0a0b6;
          }
        }

        .reply-modify {
          flex: 1 1 auto;
          text-align: right;

          .modify {
            margin-top: 1rem;
          }
        }

        .reply {
          strong {
            font-weight: 700;
            font-size: 1.6rem;
            color: #a0a0b6;

            & + .date {
              margin-left: 1rem;
            }

            &.completed {
              color: #17c624;
            }
          }
        }

        .date {
          font-weight: 400;
          font-size: 1.2rem;
          color: #a0a0b6;
        }

        button {
          font-weight: 700;
          font-size: 1.6rem;

          &.btn-delete {
            color: #ff5647;
          }

          & + button {
            &::before {
              content: '';
              display: inline-block;
              width: 0.1rem;
              height: 1.2rem;
              margin: 0 1.2rem;
              background-color: #cfcfda;
            }
          }
        }
      }
    }

    &.inquiry {
      .info-area {
        align-items: flex-start;

        .reply-modify {
          .modify {
            margin-top: 3.5rem;
          }
        }
      }
    }
  }

  .detail-content {
    border-bottom: 0.1rem solid #b7b7c8;
  }

  .detail-question {
    padding: 4.5rem 4rem;
    font-size: 1rem;
    white-space: pre-line;
    line-height: 1.2;
    background-color: #f7f7fc;

    > div {
      width: 100%;
      margin-bottom: 0;
    }

    img {
      display: block;
      margin: 1rem 0;
    }
  }

  .detail-download {
    padding: 1.4rem 2rem;
    font-weight: 700;
    font-size: 1.2rem;
    color: #28284b;
    background-color: #f7f7fc;
    text-align: right;

    &::before {
      content: '첨부파일';
      display: inline-block;
    }

    a {
      margin-left: 1rem;
      font-weight: 400;

      &::after {
        content: '';
        display: inline-block;
        width: 2rem;
        height: 2rem;
        margin-left: 0.5rem;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 50%;
        background-image: url(${icoDownload_54x54b});
        vertical-align: middle;
      }
    }
  }

  @media ${({ theme }) => theme.device.mobile} {
    padding: 0;

    .title-col {
      display: none;
    }

    .detail-title-area {
      padding: 5rem 4.2rem;

      .tit-flex-form {
        flex-direction: column;
        align-items: flex-start;

        .sub-tit {
          font-size: 2.5rem;

          &.black {
            order: 2;
            margin-top: 2.2rem;
            font-size: 4rem;
          }
        }

        .info {
          .check {
            font-size: 2.5rem;
          }
          .date {
            font-size: 2.3rem;
          }
        }
      }

      .btn-group {
        button {
          font-size: 2.5rem;
        }
      }

      &.board {
        min-height: 14rem;

        .thumb-area {
          span {
            width: 13.2rem;
            height: 13.2rem;
          }
        }

        .main-tit {
          font-size: 2.7rem;
        }

        .info {
          position: absolute;
          bottom: 5rem;
          font-size: 2.5rem;
        }

        .info-area {
          flex-direction: column;
          align-items: initial;

          .class {
            position: relative;
            width: 100%;

            .sub-tit {
              font-size: 2.5rem;
            }
          }

          .reply-modify {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 3.5rem;

            .reply {
              strong {
                font-size: 2.2rem;
              }
            }

            .modify {
              flex: 1 1 auto;
              margin-top: 0;
            }
          }

          .date {
            font-size: 2.4rem;
          }

          .star-point {
            top: 1rem;
            right: auto;
            left: 0;
            width: 17rem;
            height: 3rem;
            margin: auto;
          }

          .btn-group {
            position: static;
          }

          button {
            font-size: 2.5rem;

            & + button {
              &::before {
                width: 0.2rem;
                height: 1.8rem;
              }
            }
          }
        }
      }

      &.inquiry {
        align-items: flex-start;
        min-height: 12rem;
      }
    }

    .detail-content {
      &.line-unset {
        border-bottom: none;
      }
    }

    .detail-question {
      padding: 5rem 4.2rem;
      font-size: 2.6rem;
    }

    .detail-download {
      padding: 3.7rem 5.2rem;
      font-weight: 400;
      font-size: 2.7rem;

      &::before {
        content: '';
      }

      a {
        display: inline-block;
        position: relative;
        width: 100%;
        margin-left: 0;
        font-weight: 400;
        text-align: left;

        &::after {
          content: '';
          display: inline-block;
          position: absolute;
          top: -1rem;
          right: 0;
          width: 5rem;
          height: 5rem;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: 50% 50%;
          background-image: url(${icoDownload_54x54b});
        }
      }
    }

    pre {
      font-size: 2.6rem;

      table {
        width: auto !important;
      }
    }

    .detail-content {
      border-bottom: none;
    }

    .qna-lists-col {
      padding: 0;

      .board-btn-group {
        width: 90%;
      }
    }
  }
`;

export default function DetailReview() {
  const navigate = useNavigate();
  const { learningReviewSeq } = useParams();
  const [reviewData, setReviewData] = useState([]);
  const [isLoading, setIsLoading] = useState([]);
  const [modalopenAlert, setModalopenAlert] = useState(false);
  const [modalopenReview, setModalopenReview] = useState(false);

  const handleOpenModalAlert = useCallback((e) => {
    document.body.classList.add('modal-open');
    setModalopenAlert(true);
  }, []);

  const handleCloseModalAlert = useCallback((e) => {
    document.body.classList.remove('modal-open');
    setModalopenAlert(false);
  }, []);

  const handleOpenModalReview = useCallback((e) => {
    document.body.classList.add('modal-open');
    setModalopenReview(true);
  }, []);

  const handleCloseModalReview = useCallback((e) => {
    document.body.classList.remove('modal-open');
    setModalopenReview(false);
  }, []);

  function formatDate(date) {
    if (date) {
      let getDate = date.split(' ');
      return getDate[0];
    } else {
      return null;
    }
  }

  // 내가 작성한 수강 후기가 있다면 데이터 업데이트
  const getMyreview = (
    setTextareaValue,
    setTextareaLength,
    setLearningReviewSeq,
    handleGetPoint,
    handleUpdateImage
  ) => {
    api
      .get('/v2/review/learning-review/' + reviewData.studyGroupUserSeq)
      .then((response) => {
        if (response.data.meta.code === 200) {
          setTextareaValue(response.data.data.contents);
          setTextareaLength(response.data.data.contents.length);
          setLearningReviewSeq(response.data.data.learningReviewSeq);
          handleGetPoint(response.data.data.satisfaction);
          handleUpdateImage(response.data.data.attachments);
        }
      })
      .catch((e) => {
        alert(config.MESSAGE['common-error']);
      });
  };

  // 수강 후기 수정
  const editMyReview = (learningReviewSeq, textareaValue, satisfaction, registerImage) => {
    const data = {
      learningReviewSeq: learningReviewSeq,
      contents: textareaValue,
      satisfaction: satisfaction,
      attachments: registerImage,
    };

    api
      .put('/v2/review', data)
      .then((response) => {
        if (response.data.meta.code !== 200) {
          alert(response.data.meta.message);

          return false;
        }

        alert('수강 후기 수정이 완료되었습니다.');
        window.location.reload();
      })
      .catch((e) => {
        alert(config.MESSAGE['common-error']);
      });
  };

  // 상품 후기 수정
  const editProductReview = (
    learningReviewSeq,
    productTextareaValue,
    satisfaction,
    uploadImgData
  ) => {
    const data = {
      learningReviewSeq: learningReviewSeq,
      contents: productTextareaValue,
      satisfaction: satisfaction,
      attachments: uploadImgData,
    };

    api
      .put('/v2/review', data)
      .then((response) => {
        if (response.data.meta.code !== 200) {
          alert(response.data.meta.message);

          return false;
        }

        alert('상품 후기 수정이 완료되었습니다.');
        window.location.reload();
      })
      .catch((e) => {
        alert(config.MESSAGE['common-error']);
      });
  };

  // 상품 후기 조회
  const getProductReview = (
    setTextareaValue,
    setTextareaLength,
    setProductLearningReviewSeq,
    handleGetPoint
  ) => {
    api
      .get(`/v2/review/${learningReviewSeq}`)
      .then((response) => {
        if (response.data.meta.code === 200) {
          setTextareaValue(response.data.data.contents);
          setTextareaLength(response.data.data.contents.length);
          setProductLearningReviewSeq(response.data.data.learningReviewSeq);
          handleGetPoint(response.data.data.satisfaction);
        }
      })
      .catch((e) => {
        alert(config.MESSAGE['common-error']);
      });
  };

  const getReviewData = () => {
    api
      .get(`/v2/review/${learningReviewSeq}`)
      .then((response) => {
        setReviewData(response.data.data);
        setIsLoading(true);
      })
      .catch((e) => {
        alert(config.MESSAGE['common-error']);
      });
  };

  const deleteReviewData = () => {
    api
      .delete(`/v2/review/${learningReviewSeq}`)
      .then((response) => {
        alert('삭제가 완료되었습니다.');
        navigate('/mypage/qna/review');
      })
      .catch((e) => {
        alert(config.MESSAGE['common-error']);
      });
  };

  useEffect(() => {
    getReviewData();

    // 페이지 벗어날때 모달 클래스 삭제
    return () => {
      document.body.classList.remove('modal-open');
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <div className="qna-col">
        <div className="inner-col">
          <div className="container mypage-sub">
            <MobileHeader title="후기" onClick={() =>
                  document.referrer == '' ? navigate('/mypage/qna/review') : navigate(-1)
                }/>
            <Lnb />
            <ListDetailView>
              <div className="title-col">
                <strong>{reviewData.reviewTypeName}</strong>
              </div>
              <div className="detail-title-area board">
                <div className="thumb-area">
                  <span
                    style={
                      reviewData.thumbnailPath && {
                        backgroundImage: `url(${reviewData.thumbnailPath})`,
                      }
                    }
                  />
                </div>
                <div className="info-area">
                  <div className="class">
                    <p className="main-tit ellipsis">{reviewData.title}</p>

                    <strong className="star-point">
                      <span
                        style={{
                          width: reviewData.satisfaction * 20 + '%',
                        }}
                      />
                    </strong>
                  </div>
                  <div className="reply-modify">
                    <div className="reply">
                      <strong
                        className={
                          'check' +
                          (reviewData.answerYn === 'Y' && reviewData.answer ? ' completed' : '')
                        }
                      >
                        {reviewData.answerYn === 'Y' && reviewData.answer ? '답변완료' : '답변대기'}
                      </strong>
                      <span className="date">{formatDate(reviewData.registDate)}</span>
                    </div>
                    <div className="modify">
                      {reviewData.answerYn === 'N' && (
                        <div className="btn-group">
                          <button className="btn-modify" onClick={handleOpenModalReview}>
                            <span>수정</span>
                          </button>
                          <button className="btn-delete" onClick={handleOpenModalAlert}>
                            <span>삭제</span>
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="detail-content">
                <div className="detail-question">
                  <DetailReviewForm
                    getReviewData={getReviewData}
                    reviewData={reviewData}
                    isLoading={isLoading}
                  ></DetailReviewForm>
                </div>
              </div>
              <div className="list-btn-col">
                <div className="inner-col">
                  <Link
                    to={{
                      pathname: '/mypage/qna/review',
                    }}
                    className="btn btn-go-list"
                  >
                    목록보기
                  </Link>
                </div>
              </div>
            </ListDetailView>
          </div>
        </div>
      </div>
      {modalopenAlert ? (
        <ModalPortal>
          <AlertModal
            // btnClose
            btnGroup
            msg={'작성하신 후기를 삭제하시겠습니까? 삭제시 복구할 수 없습니다.'}
            handleCloseModal={handleCloseModalAlert}
            handleConfirmClose={deleteReviewData}
          />
        </ModalPortal>
      ) : null}
      {modalopenReview ? (
        <ModalPortal>
          <ModalReviewWrite
            title={reviewData.reviewType === 'PRODUCT' ? '상품 후기 작성' : '수강 후기 작성'}
            btnClose
            studySeq={reviewData.studyGroupUserSeq}
            handleCloseModalReviewWrite={handleCloseModalReview}
            getMyreview={getMyreview}
            editMyReview={editMyReview}
            getProductReview={getProductReview}
            editProductReview={editProductReview}
            myClassLearningReviewSeq={learningReviewSeq}
          />
        </ModalPortal>
      ) : null}
    </ThemeProvider>
  );
}
