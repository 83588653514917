import React from 'react'

import s from './Chat.module.scss'
import { AI_FAQ_CONFIG } from '../config'

export default function InitMessage() {
  return (
    <li className={s.feedItem}>
      <div className={s.aiMessage}>
        <div className={s.msgThumb}>
          <img src={AI_FAQ_CONFIG.AI_PROFILE_THUMB} alt="AI 상담원" />
        </div>
        <dl>
          <dt className={s.msgName}>AI 상담원</dt>
          <dd className={s.message}>
            안녕하세요. AI 상담원입니다. 궁금한 점을 자유롭게 물어보세요.
          </dd>
        </dl>
      </div>
    </li>
  )
}
