import React, { useState } from 'react'
import { useMediaQuery } from 'react-responsive'

import cx from 'classnames'
import ModalPortal from 'ModalPortal'

import { setCookie } from 'common/Cookie'
import ResponsiveImage from 'components/ResponsiveImage'

import s from './CoachMask.module.scss'
import { CHAT_COMPONENTS_CONFIG } from './config'

export default function CoachMask({ top, handleCoachMask }) {
  const isMobile = useMediaQuery({ maxWidth: 1024 })
  const [step, setStep] = useState(1)
  const [checked, setChecked] = useState(false)

  const handleMaskClose = () => {
    if (checked) {
      setCookie('aiTalkCoachMask', 'N', 100)
    }
    handleCoachMask()
  }
  return (
    <ModalPortal>
      <div className={s.coachMask}>
        <div
          className={cx(s.coachContent, s[`step${step}`])}
          style={{ paddingTop: !isMobile && top ? top : 0 }}
        >
          {step === 1 ? (
            <>
              <div className={s.imgMask}>
                <ResponsiveImage
                  pc={
                    'https://english.yanadoocdn.com/upload/yanadoo/new/aitalk/pc_coach_mask_1_01.png'
                  }
                  mo={
                    'https://english.yanadoocdn.com/upload/yanadoo/new/aitalk/m_coach_mask_1_01.png'
                  }
                  maxWidth={1024}
                  alt={'채팅모드'}
                />
                <ResponsiveImage
                  pc={
                    'https://english.yanadoocdn.com/upload/yanadoo/new/aitalk/pc_coach_mask_1_02.png'
                  }
                  mo={
                    'https://english.yanadoocdn.com/upload/yanadoo/new/aitalk/m_coach_mask_1_02.png'
                  }
                  maxWidth={1024}
                  alt={'채팅모드'}
                />
              </div>
              <button className={s.btnNextStep} onClick={() => setStep(2)}>
                다음
              </button>
            </>
          ) : (
            <>
              <div className={s.imgMask}>
                <ResponsiveImage
                  pc={
                    'https://english.yanadoocdn.com/upload/yanadoo/new/aitalk/pc_coach_mask_2_01.png'
                  }
                  mo={
                    'https://english.yanadoocdn.com/upload/yanadoo/new/aitalk/m_coach_mask_2_01.png'
                  }
                  maxWidth={1024}
                  alt={'음성모드'}
                />
                <ResponsiveImage
                  pc={
                    'https://english.yanadoocdn.com/upload/yanadoo/new/aitalk/pc_coach_mask_2_02.png'
                  }
                  mo={
                    'https://english.yanadoocdn.com/upload/yanadoo/new/aitalk/m_coach_mask_2_02.png'
                  }
                  maxWidth={1024}
                  alt={'음성모드'}
                />
              </div>
              <div className={s.closeButtons}>
                <button
                  className={cx(s.btnCheck, { [s.checked]: checked })}
                  onClick={() => setChecked(!checked)}
                >
                  다시 표시 안함
                </button>
                <button className={s.btnClose} onClick={handleMaskClose}>
                  {CHAT_COMPONENTS_CONFIG.svg.close}
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </ModalPortal>
  )
}
