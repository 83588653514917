import React, { useState, useCallback, useEffect, useRef } from 'react'

import ModalPortal from 'ModalPortal'
import styled, { ThemeProvider } from 'styled-components'

import theme from 'common/theme'

import ModalImageCollectAll from './modal/ModalImageCollectAll'
import ModalImageDetail from './modal/ModalImageDetail'
import icoNoImage from '../assets/images/ico_no_image.png'

export default function CardImage({
  type,
  isLoading,
  deviceCheck,
  cardImages,
  cardImagesLength,
  categoryData,
  handleCategoryCodeList
}) {
  const [count, setCount] = useState(9) // 카드 기본 노출 개수
  const defaultCountRef = useRef(cardImagesLength)
  const [seq, setSeq] = useState({
    learningReviewSeq: null,
    scholarshipSeq: null,
    scholarshipRefundHistorySeq: null
  })

  const apiInfo = {
    SRR: {
      apiAttachmentsUrl: `/v2/community-tab/srr-learning-review/attachments?pageIndex=`,
      apiDetailUrl: `/v2/community-tab/srr-learning-review/detail?learningReviewSeq=`,
      apiRollingUrl: '/v2/community-tab/srr-learning-review/rolling/attachment?learningReviewSeq=',
      apiPrevNextUrl: '/v2/community-tab/srr-learning-review/move-and-detail?moveType='
    },
    REAL: {
      apiAttachmentsUrl: `/v2/review/real-time/learning-and-scholarship/attachments?pageIndex=`,
      apiDetailUrl: `/v2/review/detail/`,
      apiDetailScholarshipUrl: `/v1/scholarship/scholarshipReview/withoutUser/`,
      apiRollingUrl: '/v2/review/real-time/learning-and-scholarship/rolling/attachment?reviewType=',
      apiPrevNextUrl: '/v2/review/real-time/learning-and-scholarship/move?moveType='
    }
  }[type]

  const handleSettingSeq = (data) => {
    data.scholarshipSeq
      ? setSeq({
          learningReviewSeq: null,
          scholarshipSeq: data.scholarshipSeq,
          scholarshipRefundHistorySeq: data.scholarshipRefundHistorySeq
        })
      : setSeq({
          learningReviewSeq: data.learningReviewSeq,
          scholarshipSeq: null,
          scholarshipRefundHistorySeq: null
        })
  }

  const [modalOpenCollectAll, setModalOpenCollectAll] = useState(false)
  const [modalOpenImageDetail, setModalOpenImageDetail] = useState(false)

  const handleOpenImageDetailModal = useCallback((data) => {
    document.body.classList.add('modal-open')
    handleSettingSeq(data)
    setModalOpenImageDetail(true)
  }, [])
  const handleCloseImageDetailModal = useCallback(() => {
    document.body.classList.remove('modal-open')
    setModalOpenImageDetail(false)
  }, [])

  const handleOpenImageCollectAllModal = useCallback(() => {
    document.body.classList.add('modal-open')
    setModalOpenCollectAll(true)
  }, [])
  const handleCloseImageCollectAllModal = useCallback(() => {
    document.body.classList.add('modal-open')
    setModalOpenCollectAll(false)
  }, [])

  useEffect(() => {
    setCount(deviceCheck ? 6 : 9)
  }, [])

  useEffect(() => {
    if (!isLoading && cardImagesLength < count) {
      // 카드 이미지의 배열의 길이를 countRef 세팅
      cardImages.length = count
      // cardImages 배열의 empty를 { url: '' }로 세팅
      cardImages.fill({ url: '' }, cardImagesLength)
    }
    setCount(deviceCheck ? 6 : 9)
  }, [isLoading, deviceCheck])

  return (
    <ThemeProvider theme={theme}>
      {!isLoading && (
        <CardImageBox>
          <ul>
            {cardImages
              .filter((item, idx) => idx < count)
              .map((cardImage, idx) => {
                return (
                  <li
                    key={idx}
                    className={cardImage.url === '' ? 'no-image' : ''}
                    style={
                      cardImage.url !== ''
                        ? { backgroundImage: `url(${cardImage.url})` }
                        : undefined
                    }
                  >
                    {cardImage?.count > 1 && <span>{cardImage?.count}</span>}
                    {/* cardImages의 length가 (PC:10개 / MO:7개) 이상이고 마지막 카드 이미지라면 */}
                    {deviceCheck ? (
                      defaultCountRef.current > count && idx + 1 === count ? (
                        <button className="btn-more" onClick={handleOpenImageCollectAllModal}>
                          <theme.Blind>전체 보기 팝업</theme.Blind>
                        </button>
                      ) : (
                        <button
                          onClick={
                            cardImage?.learningReviewSeq || cardImage?.scholarshipSeq
                              ? () => {
                                  handleOpenImageDetailModal(cardImage)
                                }
                              : undefined
                          }
                        >
                          <theme.Blind>상세 보기 팝업</theme.Blind>
                        </button>
                      )
                    ) : defaultCountRef.current > count && idx + 1 === count ? (
                      <button className="btn-more" onClick={handleOpenImageCollectAllModal}>
                        <theme.Blind>전체 보기 팝업</theme.Blind>
                      </button>
                    ) : (
                      <button
                        onClick={
                          cardImage?.learningReviewSeq || cardImage?.scholarshipSeq
                            ? () => {
                                handleOpenImageDetailModal(cardImage)
                              }
                            : undefined
                        }
                      >
                        <theme.Blind>상세 보기 팝업</theme.Blind>
                      </button>
                    )}
                  </li>
                )
              })}
          </ul>
        </CardImageBox>
      )}
      {/* 전체보기 모달 */}
      {modalOpenCollectAll ? (
        <ModalPortal>
          <ModalImageCollectAll
            type={type}
            btnClose
            title={'전체보기'}
            cardImages={cardImages}
            handleOpenImageDetailModal={handleOpenImageDetailModal}
            handleCloseModal={handleCloseImageCollectAllModal}
            apiInfo={apiInfo}
            categoryData={categoryData}
            handleCategoryCodeList={handleCategoryCodeList}
          />
        </ModalPortal>
      ) : null}
      {/* 상세보기 모달 */}
      {modalOpenImageDetail ? (
        <ModalPortal>
          <ModalImageDetail
            type={type}
            seq={seq}
            btnClose
            deviceCheck={deviceCheck}
            title={'이미지 후기'}
            cardImages={cardImages}
            handleOpenImageCollectAllModal={handleOpenImageCollectAllModal}
            handleCloseModal={handleCloseImageDetailModal}
            apiInfo={apiInfo}
            handleSettingSeq={handleSettingSeq}
            categoryData={categoryData}
            handleCategoryCodeList={handleCategoryCodeList}
          />
        </ModalPortal>
      ) : null}
    </ThemeProvider>
  )
}

const CardImageBox = styled.div`
  position: relative;
  margin-bottom: 5rem;

  ul {
    display: flex;
    position: relative;
    margin: 0 -0.4rem;
    padding-left: 27.2rem;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    li {
      flex: 1 1 20%;
      position: relative;
      margin: 0.4rem;
      padding-bottom: 23.3%;
      background-color: #e7e7f0;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover;

      &:first-child {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 26.4rem;
      }

      &.no-image {
        background-image: url(${icoNoImage});
        background-size: 26% auto;
      }

      span {
        position: absolute;
        bottom: 0;
        right: 0;
        min-width: 2.8rem;
        padding: 0.64rem 0 0.5rem;
        font-family: 'Inter';
        font-weight: 700;
        font-size: 1.4rem;
        color: #fff;
        background-color: rgba(40, 40, 75, 0.6);
        text-align: center;
        box-sizing: border-box;
      }
    }
  }

  button {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    right: 0;
    cursor: pointer;

    &.btn-more {
      &::before {
        content: '+ 더보기';
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        font-weight: 700;
        font-size: 1.8rem;
        color: #fff;
        background-color: rgba(40, 40, 75, 0.6);
      }
    }
  }

  @media ${({ theme }) => theme.device.mobile} {
    margin-bottom: 6rem;

    ul {
      margin: 0 -0.4rem;
      padding-left: 0;

      li {
        flex: 1 1 30%;
        margin: 0.4rem;
        padding-bottom: 32.05%;

        &:first-child {
          position: relative;
          top: auto;
          left: auto;
          bottom: auto;
          width: auto;
        }

        &.no-image {
          background-size: 26% auto;
        }

        span {
          min-width: 4.4rem;
          padding: 1rem 1rem;
          font-size: 2rem;
        }
      }
    }

    button {
      &.btn-more {
        &::before {
          font-size: 2.4rem;
        }
      }
    }
  }
`
