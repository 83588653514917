import React from 'react'
import { Link } from 'react-router-dom'

const ListItem = React.memo(({ item, comparison }) => {
  function formatDate(date) {
    if (date) {
      let getDate = date.split(' ')
      return getDate[0]
    } else {
      return null
    }
  }

  return (
    <>
      <div className="thumb-box">
        <i className="thumb" style={{ backgroundImage: `url(${item.bannerImageFilePath})` }}></i>
        <em>{item.remainingDays}</em>
        {item.relatedPackages && (
          <div className="event-related-package">
            {comparison && item.remainingPackages > 0 ? (
              <>
                <span>{item.remainingPackages}개</span> 밖에 남지 않았어요!
              </>
            ) : (
              '일시 품절'
            )}
          </div>
        )}
        {/* 종료 이벤트일 경우에만 */}
        {item.eventStatus === 'END' && (
          <div className="button-box">
            {item.eventType === 'LINK' ? (
              <a href={item.bannerLink} className="btn btn-detail" target="_blank" rel="noopener noreferrer">
                <span>내용보기</span>
              </a>
            ) : (
              <Link
                to={{
                  pathname: `/event/detail/${item.eventSeq}`
                }}
                state={{ bannerImage: item.bannerImageFilePath }}
                className="btn btn-detail"
              >
                <span>내용보기</span>
              </Link>
            )}
            {/* 당첨 발표가 있는 이벤트일 경우에만 */}
            {item.winnerAnnouncementDisplayYn === 'Y' && (
              <Link
                to={{
                  pathname: `/event/winner/${item.eventSeq}`
                }}
                className="btn btn-winner"
              >
                <span>당첨자 확인</span>
              </Link>
            )}
          </div>
        )}
      </div>
      <div className="list-desc">
        <p>
          <strong>{item.eventName}</strong>
        </p>
        <p className="list-period">
          <span>{formatDate(item.startDate)}</span>~<span>{formatDate(item.endDate)}</span>
        </p>

        {item.winnerAnnouncementDisplayYn === 'Y' && <p className="list-announcement">{formatDate(item.winnerAnnouncementDate)}</p>}
      </div>
    </>
  )
})

export default ListItem
