import React, { useEffect, useRef } from 'react'

import useChatFeedListStore from 'store/useChatFeedListStore'

import s from './Chat.module.scss'
import ChatFeedContainer from './ChatFeedContainer'

export default function ChatContainer({ isAiResponse, addToFeed, isPlatform }) {
  const { feedList } = useChatFeedListStore()
  const containerRef = useRef(null)

  const scrollToBottom = () => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight
    }
  }

  useEffect(() => {
    scrollToBottom()
  }, [feedList])

  return (
    <section className={s.chatContainer} ref={containerRef}>
      <div className={s.contentInner}>
        <ChatFeedContainer
          isAiResponse={isAiResponse}
          addToFeed={addToFeed}
          isPlatform={isPlatform}
        />
      </div>
    </section>
  )
}
