import React, { useEffect } from 'react'

import OAuth from 'common/OAuth'

import ResponsiveImage from './ResponsiveImage'

const IMG_HOST = 'https://english.yanadoocdn.com/upload/yanadoo/images/common'

export default function SocialLogin({ joinPath, iframe }) {
  //snsType: 0:일반, 1:네이버, 2:페이스북, 3:카카오, 4:애플

  useEffect(() => {
    OAuth.injectScript('https://developers.kakao.com/sdk/js/kakao.js', () => {
      if (!window.Kakao.isInitialized()) {
        window.Kakao.init(OAuth.KAKAO_JS_KEY)
      }
    })

    OAuth.injectScript('https://connect.facebook.net/en_US/sdk.js', () => {
      OAuth.setFBAsyncInit()
    })

    OAuth.injectScript(
      'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js',
      () => {
        OAuth.setAppleInit()
      }
    )

    OAuth.injectScript(
      'https://static.nid.naver.com/js/naveridlogin_js_sdk_2.0.2-nopolyfill.js',
      () => {
        if (iframe) {
          OAuth.handleNaverLoginIframe()
        } else {
          OAuth.handleNaverLogin()
        }
      }
    )
  }, [])

  return (
    <>
      <ul className="social-list">
        <li>
          <button onClick={() => OAuth.handleKakaoLogin(joinPath, iframe)}>
            <ResponsiveImage
              pc={`${IMG_HOST}/ico_signin_kakao.png`}
              mo={`${IMG_HOST}/ico_m_signin_kakao.png`}
              alt="카카오 로그인"
              maxWidth={1024}
            />
          </button>
        </li>
        <li>
          <button
            className="btn-naver"
            id="naverIdLogin"
            onClick={() => {
              if (iframe) {
                OAuth.handleNaverLoginIframe()
              } else {
                OAuth.handleNaverLogin()
              }
            }}
          ></button>
          <ResponsiveImage
            pc={`${IMG_HOST}/ico_signin_naver.png`}
            mo={`${IMG_HOST}/ico_m_signin_naver.png`}
            alt="네이버 로그인"
            maxWidth={1024}
          />
        </li>
        <li>
          <button onClick={() => OAuth.handleAppleLogin(joinPath)}>
            <ResponsiveImage
              pc={`${IMG_HOST}/ico_signin_apple.png`}
              mo={`${IMG_HOST}/ico_m_signin_apple.png`}
              alt="애플 로그인"
              maxWidth={1024}
            />
          </button>
        </li>
        <li>
          <button onClick={() => OAuth.handleFacebookLogin(joinPath)}>
            <ResponsiveImage
              pc={`${IMG_HOST}/ico_signin_facebook.png`}
              mo={`${IMG_HOST}/ico_m_signin_facebook.png`}
              alt="페이스북 로그인"
              maxWidth={1024}
            />
          </button>
        </li>
      </ul>
    </>
  )
}
