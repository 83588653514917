import { useEffect, useState } from 'react'

import api from 'common/api'

export default function useGetFaqList({ cate, group, page, size }) {
  const [faqList, setFaqList] = useState([])
  const [totalPage, setTotalPage] = useState(1)

  // /v2/faq/list?faqGroup=ATTEND&index=1&parentFaqGroup=YANADOO_FAQ_GROUP&size=5
  const fetchFaqList = async () => {
    try {
      const response = await api.get(
        `/v2/faq/list?faqGroup=${cate}&index=${page}&parentFaqGroup=${group}&size=${size}`
      )
      const data = response.data.data
      setFaqList(data.content)
      setTotalPage(data.totalPages)
    } catch (e) {
      console.error(`fetch faq list error : `, e)
    }
  }

  useEffect(() => {
    fetchFaqList()
    return () => setFaqList([])
  }, [cate, group, page, size])

  return { faqList, totalPage }
}
