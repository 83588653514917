import { useCallback, useState, useRef, useEffect } from 'react';
import ModalPortal from '../../ModalPortal';
import AlertModal from './AlertModal';
import '../../assets/styles/modal.scss';
import '../../assets/styles/coupon.scss';
import api from 'common/api';

const ModalCouponDownload = ({ btnClose, handleCloseModal, couponAllListData }) => {
  const [couponCodeList, setCouponCodeList] = useState([]);
  const [warning, setWarning] = useState(false);
  const [warningText, setWarningText] = useState('');
  const [flag, setFlag] = useState(false);
  const [modalopen, setModalopen] = useState(false);
  const [couponCode, setCouponCode] = useState('');
  const [couponCodeLength, setCouponCodeLength] = useState(null);
  const couponRef = useRef();

  useEffect(() => {
    // 등록 된 쿠폰 리스트
    setCouponCodeList(
      couponAllListData.map((item, idx) => {
        return item.couponCode;
      })
    );
  }, [couponAllListData]);

  const handleOpenModal2 = useCallback((e) => {
    document.body.classList.add('modal-open');
    setModalopen(true);
  }, []);

  const handleCloseModal2 = useCallback((e) => {
    document.body.classList.remove('modal-open');
    setModalopen(false);
  }, []);

  const changeRegisterNumber = (e) => {
    let value = e.target.value;
    let regCheck = /^[A-Za-z0-9\-\_]{1,20}$/;

    setCouponCodeLength(value.length);
    setCouponCode(value);

    if (regCheck.test(value) && value.length > 0) {
      setFlag(true);
      setWarning(false);
    } else {
      setFlag(false);
      setWarningText('유효하지 않은 코드입니다.');
      setWarning(true);
    }
  };

  const postCouponCode = (e) => {
    e.preventDefault();

    if (couponCode === '' || !flag) {
      alert('쿠폰 번호를 확인해주세요.');
      couponRef.current.focus();
      return false;
    }

    if (couponCodeList.includes(couponCode)) {
      setWarningText('이미 등록된 쿠폰입니다.');
      setWarning(true);
      couponRef.current.focus();
      return false;
    } else {
      setWarning(false);
    }

    const data = {
      couponCode: couponCode,
    };

    api
      .post(`/v2/coupon-publish?couponCode=${data.couponCode}`)
      .then((response) => {
        if (response.data.meta.code !== 200) {
          alert(response.data.meta.message);
        } else {
          alert('쿠폰 등록이 완료되었습니다!');
          setCouponCode('');
          // handleOpenModal2();
          window.location.reload();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <>
      <div className="modal-wrap">
        <div className="dimmed" onClick={handleCloseModal}></div>
        <div className="modal-inner">
          {btnClose && (
            <button type="button" className="btn-modal-close" onClick={handleCloseModal}>
              <i className="blind">닫기</i>
            </button>
          )}
          <div className="modal-header">
            <strong>쿠폰 등록</strong>
          </div>
          <div className="modal-content type-fixed-button register">
            <form name="registerForm" action="#">
              <div className="modal-center-box">
                <div className="modal-center-inner">
                  <div className="register-box">
                    <input
                      type="text"
                      className={'coupon-code' + (flag === true ? ' active' : '')}
                      maxLength="20"
                      placeholder="쿠폰 코드를 입력해주세요."
                      onChange={changeRegisterNumber}
                      ref={couponRef}
                      value={couponCode}
                    />
                    <span className={'warning' + (warning === true ? ' active' : '')}>
                      {warningText}
                    </span>
                  </div>
                  <div className="coupon-notice">
                    <strong>쿠폰 등록 안내</strong>
                    <ul>
                      <li>할인쿠폰은 중복 적용이 불가합니다.</li>
                      <li>결제취소/환불 시 사용한 쿠폰은 소멸됩니다.</li>
                      <li>보유하고 있는 쿠폰코드를 등록하시면 바로 이용이 가능합니다.</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="btn-flex-form">
                <button
                  type="submit"
                  className={'btn' + (flag === true ? ' active' : ' disabled')}
                  onClick={postCouponCode}
                >
                  <span>쿠폰 등록하기</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {modalopen ? (
        <ModalPortal>
          <AlertModal
            // btnClose
            btnGroup
            msg={'쿠폰이 등록이 완료되었습니다.'}
            handleCloseModal={handleCloseModal2}
          />
        </ModalPortal>
      ) : null}
    </>
  );
};

export default ModalCouponDownload;
