import { useEffect, useState } from 'react'

import api from 'common/api'

import { AINADOO_CONFIG } from '../config'

export default function useGetQnaId({ classSeq, userSeq }) {
  const { API_HOST } = AINADOO_CONFIG
  const [assistantId, setAssistantId] = useState('')
  const [threadId, setThreadId] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    const getQnaId = async () => {
      const payload = {
        classSeq,
        userSeq
      }

      try {
        setIsLoading(true)
        const response = await api.post(API_HOST.enter, payload)

        if (response.data) {
          setAssistantId(response.data.assistantId)
          setThreadId(response.data.threadId)
        }
      } catch (e) {
        console.error('🚀 error:', e)
        setError(e)
      } finally {
        setIsLoading(false)
      }
    }

    if (classSeq && userSeq) {
      getQnaId()
    }
  }, [API_HOST, classSeq, userSeq])

  return { assistantId, threadId, isLoading, error }
}
