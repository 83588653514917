import { useCallback, useEffect, useRef } from 'react'
import { useLocation } from 'react-router'

import useGetGnbList from 'hooks/useGetGnbList'

import useHeaderHeightStore from 'store/useHeaderHeightStore'
import useHeaderStore from 'store/useHeaderStore'

import { headerValues } from '../config'

export default function useHeader() {
  const { gnbItems } = useGetGnbList()
  const currentPath = useLocation().pathname
  const headerRef = useRef(null)
  let preScrollTop = useRef(0)

  const setHeaderHeight = useHeaderHeightStore((state) => state.setHeaderHeight)
  const setIsHideHeader = useHeaderHeightStore((state) => state.setIsHideHeader)
  const headerHeight = useHeaderHeightStore((state) => state.headerHeight)
  const isMain = useHeaderStore((state) => state.isMain)
  const onChangeCurrentSubIndex = useHeaderStore((state) => state.onChangeCurrentSubIndex)
  const currentSubIndex = useHeaderStore((state) => state.currentSubIndex)
  const onChangeGnbActiveDepthIndex = useHeaderStore((state) => state.onChangeGnbActiveDepthIndex)
  const onChangeIsMain = useHeaderStore((state) => state.onChangeIsMain)

  // 메인이 아닐 경우 active할 인덱스 처리
  const onChangeSubIndex = useCallback(
    (pagePath) => {
      let activeDepthBoolen = true
      let customURL = ''
      gnbItems?.forEach((item, idx) => {
        // 1 depth
        item.childGnbMenus.forEach((subItem, subIdx) => {
          // 2 depth
          if (pagePath === subItem.link || pagePath === item.link) {
            onChangeCurrentSubIndex(idx)
            onChangeIsMain(false)
            onChangeGnbActiveDepthIndex({ depth1: idx, depth2: subIdx, depth3: 0 })
            activeDepthBoolen = false
            return false
          }
          subItem.childGnbMenus.forEach((detailItem, detailIdx) => {
            // 3 depth
            function setDepth() {
              onChangeCurrentSubIndex(idx)
              onChangeIsMain(false)
              onChangeGnbActiveDepthIndex({ depth1: idx, depth2: subIdx, depth3: detailIdx })
              activeDepthBoolen = false
              return false
            }
            customURL = ''
            if (detailItem.link.indexOf('community') !== -1) {
              const link = detailItem.link.split('?')[0]
              link
                .split('?')[0]
                .split('/')
                .forEach((item, idx) => {
                  if (item.length > 0) {
                    customURL += '/' + item
                  }
                })
              if (customURL === pagePath?.split('?')[0]) {
                setDepth()
              }
            } else {
              customURL = detailItem.link
            }
            if (pagePath === customURL) {
              setDepth()
            }
          })
        })
      })
      if (activeDepthBoolen) onChangeGnbActiveDepthIndex({ depth1: 0, depth2: 0, depth3: 0 })
    },
    [gnbItems, onChangeCurrentSubIndex, onChangeGnbActiveDepthIndex, onChangeIsMain]
  )

  const gnbActiveCheck = useCallback(() => {
    if (currentPath === '/') {
      onChangeIsMain(true) // 야나두 메인 페이지라면, 메인 GNB를 노출합니다.
    } else {
      onChangeSubIndex(currentPath)
    }
  }, [currentPath, onChangeIsMain, onChangeSubIndex])

  // 특정URL 진입시 active하고 싶은 GNB메뉴와 매치시키는 함수
  const checkUrlMatch = useCallback(() => {
    headerValues.urlCustomActiveList.forEach((item) => {
      if (currentPath.indexOf(item.pagePath) !== -1) onChangeSubIndex(item.matchUrl)
    })
  }, [currentPath, onChangeSubIndex])

  useEffect(() => {
    gnbActiveCheck()
    checkUrlMatch()
  }, [gnbItems, currentPath, gnbActiveCheck, checkUrlMatch])

  // 헤더가 변경될 때 마다 높이값 가져와서 store 저장하는 함수
  useEffect(() => {
    const headerElement = headerRef.current
    if (!headerElement) return
    const resizeObserver = new ResizeObserver((entries) => {
      const entry = entries[0]
      const newHeight = entry.contentRect.height

      if (headerHeight !== newHeight) {
        window.requestAnimationFrame(() => {
          setHeaderHeight(newHeight)
        })
      }
    })
    resizeObserver.observe(headerElement)
    return () => {
      resizeObserver.unobserve(headerElement)
    }
  }, [headerHeight, setHeaderHeight])

  /**
   * 스크롤에 따라 헤더 숨김 처리하는 함수
   * scroll down -> 헤더 숨김
   * scroll up -> 헤더 보임
   */
  const handleHideGnb = useCallback(() => {
    let currentScrollTop = window.scrollY
    if (currentScrollTop > 50) {
      if (preScrollTop.current < currentScrollTop) {
        setIsHideHeader(true)
      } else {
        setIsHideHeader(false)
      }
      preScrollTop.current = currentScrollTop
    }
  }, [setIsHideHeader])

  useEffect(() => {
    let timer = null
    setInterval(() => {
      document.addEventListener('scroll', handleHideGnb)
    })
    return () => {
      clearInterval(timer)
      document.removeEventListener('scroll', handleHideGnb)
    }
  }, [handleHideGnb])

  return { headerRef, currentSubIndex, isMain }
}
