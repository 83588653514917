export const HOST_CDN = 'https://english.yanadoocdn.com/upload/yanadoo/new/english/'

export const brandCardValues = [
  {
    pc: `${HOST_CDN}img_eh_section01_card01.png`,
    mo: `${HOST_CDN}img_m_eh_section01_card01_v2.png`,
    alt: '유투브 조회수 1.4억뷰'
  },
  {
    pc: `${HOST_CDN}img_eh_section01_card02_v2.png`,
    mo: `${HOST_CDN}img_m_eh_section01_card02_v2.png`,
    alt: '누적 회원수 170만명'
  },
  {
    pc: `${HOST_CDN}img_eh_section01_card03.png`,
    mo: `${HOST_CDN}img_m_eh_section01_card03.png`,
    alt: '장학금 지급액 88억'
  }
]

export const shortsListValues = [
  {
    link: 'https://www.youtube.com/shorts/YKTVyBFisCs',
    pc: `${HOST_CDN}shorts_pc_01.png`,
    mo: `${HOST_CDN}shorts_mo_01.png`,
    alt: '길을 가다 우연히 오드리를 만났다'
  },
  {
    link: 'https://www.youtube.com/shorts/-elUFQGWFi8',
    pc: `${HOST_CDN}shorts_pc_02.png`,
    mo: `${HOST_CDN}shorts_mo_02.png`,
    alt: '우버 사기 대응 표현 3가지'
  },
  {
    link: 'https://www.youtube.com/shorts/R46OvLIMkrs',
    pc: `${HOST_CDN}shorts_pc_03.png`,
    mo: `${HOST_CDN}shorts_mo_03.png`,
    alt: 'bye 대신 쓰는 인사말 3가지'
  }
]

export const lectureValues = [
  {
    link: '/promotion-landing/bestAwards',
    pc: `${HOST_CDN}img_lecture_01_v2.png`,
    mo: `${HOST_CDN}img_mo_lecture_01_v3.png`,
    alt: 'NEW 10분 영어'
  },
  {
    link: '/promotion-landing/SRRfullpackage',
    pc: `${HOST_CDN}img_lecture_02_v2.png`,
    mo: `${HOST_CDN}img_mo_lecture_02_v2.png`,
    alt: '스르르 학습지'
  },
  {
    link: '/promotion-landing/bestAwardsDBOnly',
    pc: `${HOST_CDN}img_lecture_03_v2.png`,
    mo: `${HOST_CDN}img_mo_lecture_03_v2.png`,
    alt: '평생수강 패키지'
  },
  {
    link: '/promotion-landing/frontEnglish',
    pc: `${HOST_CDN}img_lecture_04_v2.png`,
    mo: `${HOST_CDN}img_mo_lecture_04_v2.png`,
    alt: '앞자리 영어'
  }
]

export const resultCountValues = [
  {
    pc: `${HOST_CDN}text_result_01.png`,
    mo: `${HOST_CDN}text_m_result_01.png`,
    alt: '실력향상 97%'
  },
  {
    pc: `${HOST_CDN}text_result_02.png`,
    mo: `${HOST_CDN}text_m_result_02.png`,
    alt: '친구추천 98%'
  },
  { pc: `${HOST_CDN}text_result_03.png`, mo: `${HOST_CDN}text_m_result_03.png`, alt: '만족도 99%' }
]

export const mainVideoValue = {
  link: 'https://www.youtube.com/watch?v=0PzNhLpn1Ac',
  pc: `${HOST_CDN}video_thumb_01.png`,
  mo: `${HOST_CDN}video_m_thumb_01.png`,
  alt: '김준호 B&A'
}
export const videoValues = [
  {
    link: 'https://www.youtube.com/watch?v=id406ux2IaM',
    pc: `${HOST_CDN}video_thumb_02.png`,
    mo: `${HOST_CDN}video_m_thumb_02.png`,
    alt: '60일간의 영어회화'
  },
  {
    link: 'https://www.youtube.com/watch?v=fFpMbVR5JYI',
    pc: `${HOST_CDN}video_thumb_03.png`,
    mo: `${HOST_CDN}video_m_thumb_03.png`,
    alt: '경상도 촌놈의 영어고자 탈출기'
  },
  {
    link: 'https://www.youtube.com/watch?v=53dWMELidMQ',
    pc: `${HOST_CDN}video_thumb_04.png`,
    mo: `${HOST_CDN}video_m_thumb_04.png`,
    alt: '야나두 2달 공부 후기'
  }
]

export const trainingStepValues = [
  { pc: `${HOST_CDN}training_01.png`, mo: `${HOST_CDN}training_m_01.png`, alt: 'step1' },
  { pc: `${HOST_CDN}training_02.png`, mo: `${HOST_CDN}training_m_02.png`, alt: 'step2' },
  { pc: `${HOST_CDN}training_03.png`, mo: `${HOST_CDN}training_m_03.png`, alt: 'step3' },
  { pc: `${HOST_CDN}training_04.png`, mo: `${HOST_CDN}training_m_04.png`, alt: 'step4' },
  { pc: `${HOST_CDN}training_05_v2.png`, mo: `${HOST_CDN}training_m_05_v2.png`, alt: 'step5' },
  { pc: `${HOST_CDN}training_06.png`, mo: `${HOST_CDN}training_m_06.png`, alt: 'step6' }
]
