import { useEffect, useRef } from 'react'

import s from 'components/DigitCounter.module.css'

import { countDigit } from './promotion/utile/utile'

export default function DigitCounter({ target }) {
  const counterRef = useRef(null)

  useEffect(() => {
    const observer = new IntersectionObserver(
      (e) => {
        e.forEach((ele) => {
          // Enter
          if (ele.isIntersecting) {
            countDigit(counterRef.current, `${target}`)
          }
        })
      },
      {
        threshold: 1
      }
    )

    observer.observe(counterRef.current)

    return () => {
      observer.disconnect()
    }
  }, [target])

  return <div className={s.digit} ref={counterRef}></div>
}
