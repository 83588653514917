import { lazy } from 'react'

const CourseReview = lazy(() => import('pages/courseReview'))
const DetailReview = lazy(() => import('pages/detailReview'))
const SuccessReview = lazy(() => import('pages/successReview'))

const ReviewRoutes = [
  {
    path: 'courseReview/*',
    element: <CourseReview />
  },
  {
    path: 'courseReview/detail/:learningReviewSeq/:scholarshipRefundHistorySeq',
    element: <DetailReview />
  },
  { path: 'successReview', element: <SuccessReview /> }
]

export default ReviewRoutes
