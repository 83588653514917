import styles from './form.module.scss'

export default function DefaultInput({ label, inputProps }) {
  return (
    <div className={`${styles.row} row`}>
      <label className={styles.label}>{label}</label>
      <input type="text" className={styles.inputBox} {...inputProps} />
    </div>
  )
}
