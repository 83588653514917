import React, { useEffect, useState, useRef } from 'react'

import cx from 'classnames'

import s from './ChatMode.module.scss'
import ToastMessage from './ToastMessage'

export default function ChatMode({ handleModeChange, toastType, mode }) {
  const [currentType, setCurrentType] = useState(null)
  const isFirstRender = useRef(true)

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }
    setCurrentType(toastType)
  }, [toastType])

  return (
    <div className={s.chatModeTap}>
      <div className={s.tapContainer}>
        <button
          className={cx({ [s.active]: mode === 'chat' })}
          onClick={() => handleModeChange('chat')}
        >
          채팅 모드
        </button>
        <button
          className={cx({ [s.active]: mode === 'audio' })}
          onClick={() => handleModeChange('audio')}
        >
          음성 모드 <span>(Beta)</span>
        </button>
      </div>
      {currentType && <ToastMessage type={currentType} key={currentType} />}
    </div>
  )
}
