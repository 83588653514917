import React from 'react'

import FaqSearchForm from './FaqSearchForm'
import SearchNavigate from './SearchNavigate'
import SearchPopular from './SearchPopular'
import useGetKeywords from '../hooks/useGetKeywords'
import s from '../Service.module.scss'

export default function FaqSearch({ isMobile }) {
  const { keywords } = useGetKeywords()
  return (
    <section className={s.faqSearchSection}>
      <div className={s.csInner}>
        {!isMobile && <SearchNavigate />}
        <div className={s.faqSearchWrap}>
          <FaqSearchForm styleName={s.faqSearchBox} />
          <SearchPopular keys={keywords} />
        </div>
      </div>
    </section>
  )
}
