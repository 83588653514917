import { LTA_HOST_CDN } from 'pages/levelTestAdvanced/config'

import styles from '../LevelTestAdvancedExam.module.scss'

/**
 * 네비게이션
 */
export default function Navigation({ prevDisabled, onClickPrev, onClickNext, children }) {
  return (
    <div className={styles.navigation}>
      <button disabled={prevDisabled} className={styles.button} onClick={onClickPrev}>
        <img src={`${LTA_HOST_CDN}icon-test-arrow-prev.png`} alt="prev arrow icon" />
        이전
      </button>

      {children}
      <button className={styles.button} onClick={onClickNext}>
        다음
        <img src={`${LTA_HOST_CDN}icon-test-arrow-next.png`} alt="next arrow icon" />
      </button>
    </div>
  )
}
