import React from 'react'
import { useMediaQuery } from 'react-responsive'

import { ThemeProvider } from 'styled-components'

import theme from 'common/theme'

import BottomMenuSection from './components/BottomMenuSection'
import BrandValueSection from './components/BrandValueSection'
import HomeReviewSection from './components/HomeReviewSection'
import MainSlideSection from './components/MainSlideSection'
import MedalSection from './components/MedalSection'
import ServiceSection from './components/ServiceSection'
import TextRollingSection from './components/TextRollingSection'
import VodReviewSection from './components/VodReviewSection'
import useHome from './hooks/useHome'

const Main = () => {
  const deviceCheck = useMediaQuery({ maxWidth: 1024 }) ? true : false
  const { bannerList } = useHome()

  return (
    <>
      <ThemeProvider theme={theme}>
        <MainSlideSection bannerList={bannerList} />
        <BrandValueSection />
        <ServiceSection deviceCheck={deviceCheck} />
        <TextRollingSection />
        <MedalSection />
        <HomeReviewSection />
        <VodReviewSection />
        <BottomMenuSection />
      </ThemeProvider>
    </>
  )
}

export default Main
