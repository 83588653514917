import React, { useState, useRef, useEffect } from 'react';
import DaumPostCode from 'react-daum-postcode';
import api from 'common/api';
import * as config from 'common/config';
import '../../assets/styles/modal.scss';
import '../../assets/styles/eventModal.scss';

const ModalWinnerAddress = ({ btnClose, handleCloseModal, myResultData }) => {
  const nameRef = useRef();
  const addressRef = useRef();

  const eventSeq = myResultData.eventSeq;

  const [addressResultData, setAddressResultData] = useState(null);
  const [isComplete, setIsComplete] = useState(null);
  const [isChecked, setIsChecked] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [inputValue, setInputValue] = useState({
    recipient: '',
    defaultAddress: '',
    zipCode: '',
    detailAddress: '',
    agree: isChecked ? 'Y' : 'N',
  });

  const handleInputValue = (e) => {
    let { name, value } = e.target;

    setInputValue({ ...inputValue, [name]: value });
  };

  const handleCheckItem = () => {
    setIsChecked(!isChecked);
  };

  const getAddressData = () => {
    api
      .get('/v2/event/winner-manage/' + eventSeq + '/winner/address')
      .then((response) => {
        setAddressResultData(response.data.data);
      })
      .catch((e) => {
        alert(config.MESSAGE['common-error']);
      });
  };

  const postWinnerAddress = () => {
    const data = {
      eventSeq: eventSeq,
      recipient: inputValue.recipient,
      zipCode: inputValue.zipCode,
      address: inputValue.defaultAddress,
      detailAddress: inputValue.detailAddress,
      collectionAgreeYn: inputValue.agree,
    };

    api
      .put('/v2/event/winner-manage/winner/address', data)
      .then((response) => {
        if (response.data.meta.code !== 200) {
          alert(response.data.meta.message);

          return false;
        }

        alert('저장되었습니다.');
        window.location.reload();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // 주소 팝업
  const handlePostCode = (e) => {
    e.preventDefault();
    new window.daum.Postcode({
      onComplete: (data) => {
        let fullAddress = data.address;
        let extraAddress = '';

        if (data.addressType === 'R') {
          if (data.bname !== '') {
            extraAddress += data.bname;
          }
          if (data.buildingName !== '') {
            extraAddress +=
              extraAddress !== ''
                ? `, ${data.buildingName}`
                : data.buildingName;
          }
          fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
        }
        setInputValue({
          ...inputValue,
          defaultAddress: fullAddress,
          zipCode: data.zonecode,
        });
      },
    }).open();
  };

  useEffect(() => {
    if (
      inputValue.recipient &&
      inputValue.defaultAddress &&
      inputValue.zipCode &&
      inputValue.detailAddress &&
      isChecked
    ) {
      setIsComplete(true);
    } else {
      setIsComplete(false);
    }
  }, [inputValue, isChecked]);

  useEffect(() => {
    if (isChecked) {
      setInputValue({ ...inputValue, agree: 'Y' });
    } else {
      setInputValue({ ...inputValue, agree: 'N' });
    }
  }, [isChecked]);

  useEffect(() => {
    if (addressResultData) {
      if (addressResultData.resultCode === 'EXIST') {
        setInputValue({
          ...inputValue,
          recipient: addressResultData.recipient,
          zipCode: addressResultData.zipCode,
          defaultAddress: addressResultData.address,
          detailAddress: addressResultData.detailAddress,
        });
        setIsChecked(
          addressResultData.collectionAgreeYn === 'Y' ? true : false
        );
      }
    }
  }, [addressResultData]);

  useEffect(() => {
    if (
      myResultData === '' ||
      myResultData.winnerYn === 'N' ||
      myResultData.addressCollectionYn === 'N'
    ) {
      setAlertType(true);
    } else {
      setAlertType(false);
    }
    getAddressData();
    setIsLoading(false);
  }, []);

  return (
    <>
      {!isLoading && (
        <div
          className={
            'modal-wrap event-winner-modal ' + (alertType ? 'alert-type' : '')
          }
        >
          <div className="dimmed" onClick={handleCloseModal}></div>
          <div className="modal-inner">
            {btnClose && (
              <button
                type="button"
                className="btn-modal-close"
                onClick={handleCloseModal}
              >
                <span className="blind">닫기</span>
              </button>
            )}
            <div className="modal-header">
              {myResultData === '' ||
              myResultData === undefined ||
              myResultData.winnerYn === 'N' ||
              (myResultData.winnerYn === 'Y' &&
                myResultData.addressCollectionYn === 'N') ? null : (
                <strong>당첨 결과 확인</strong>
              )}
            </div>
            <div className="modal-content">
              {/* 당첨 되지 않은 경우 */}
              {myResultData && myResultData.winnerYn === 'N' ? (
                <div className="title no-win">
                  <h1>
                    <strong>회원님은 당첨자 명단에 없습니다.</strong>
                    다른 진행중인 이벤트에 도전해 주세요.
                  </h1>
                </div>
              ) : // 당첨 & 주소 정보 추가 수집 여부가 Y인 경우
              // addressResultData 가 EXPIRED 일경우 모두 비활성화
              myResultData.winnerYn === 'Y' &&
                myResultData.addressCollectionYn === 'Y' &&
                addressResultData ? (
                <form name="winnerAddressFrm" action="#">
                  <div className="title">
                    <h1>
                      <strong>당첨을 축하합니다!</strong>경품 배송을 위해 수령지
                      주소를 입력해주세요
                    </h1>
                  </div>
                  <div className="winner-list-box">
                    <dl>
                      <dt>수령자명</dt>
                      <dd>
                        <input
                          type="text"
                          placeholder="이름을 입력해 주세요."
                          name="recipient"
                          ref={nameRef}
                          value={inputValue.recipient}
                          onChange={handleInputValue}
                          disabled={
                            addressResultData.resultCode === 'EXPIRED'
                              ? true
                              : false
                          }
                        />
                      </dd>
                    </dl>
                    <dl>
                      <dt>주소</dt>
                      <dd className="address-box">
                        <div className="flex-box">
                          <input
                            type="text"
                            className={
                              'input-value zone-code ellipsis' +
                              (inputValue.zipCode ? ' active' : '')
                            }
                            name="zipCode"
                            value={
                              inputValue.zipCode
                                ? inputValue.zipCode
                                : '우편번호'
                            }
                            readOnly
                            disabled={
                              addressResultData.resultCode === 'EXPIRED'
                                ? true
                                : false
                            }
                          ></input>
                          <button
                            type="button"
                            className="btn-address"
                            onClick={handlePostCode}
                            disabled={
                              addressResultData.resultCode === 'EXPIRED'
                                ? true
                                : false
                            }
                          >
                            <span>주소찾기</span>
                          </button>
                        </div>
                        <input
                          type="text"
                          className={
                            'input-value' +
                            (inputValue.defaultAddress ? ' active' : '')
                          }
                          name="defaultAddress"
                          value={
                            inputValue.defaultAddress
                              ? inputValue.defaultAddress
                              : '주소를 입력해주세요.'
                          }
                          readOnly
                          disabled={
                            addressResultData.resultCode === 'EXPIRED'
                              ? true
                              : false
                          }
                        />
                        <input
                          type="text"
                          placeholder="상세 주소를 입력해주세요."
                          name="detailAddress"
                          ref={addressRef}
                          value={inputValue.detailAddress}
                          onChange={handleInputValue}
                          disabled={
                            addressResultData.resultCode === 'EXPIRED'
                              ? true
                              : false
                          }
                        />
                      </dd>
                    </dl>
                    <div className="agree-box">
                      <label className="agree">
                        <input
                          type="checkbox"
                          checked={isChecked ? true : false}
                          onChange={handleCheckItem}
                          disabled={
                            addressResultData.resultCode === 'EXPIRED'
                              ? true
                              : false
                          }
                        />
                        <span className="chk"></span>
                        <p>개인 정보 수집 동의</p>
                      </label>
                      <button
                        type="button"
                        className="btn-underline"
                        onClick={() => window.open('/service/policydetailjoin')}
                      >
                        내용보기
                      </button>
                    </div>
                    <button
                      type="button"
                      className={
                        'btn btn-complete ' +
                        (isComplete ? 'active' : 'disabled')
                      }
                      disabled={isComplete ? false : true}
                      onClick={postWinnerAddress}
                    >
                      <span>확인</span>
                    </button>
                  </div>
                </form>
              ) : // 당첨 & 주소 정보 추가 수집 여부가 N인 경우
              myResultData.winnerYn === 'Y' &&
                myResultData.addressCollectionYn === 'N' ? (
                <div className="title">
                  <h1>
                    <strong>당첨을 축하합니다!</strong>
                    자세한 내용은 당첨자 발표
                    <br />
                    상세 내용을 확인해주세요.
                  </h1>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      )}
      <DaumPostCode
        className="post-code"
        onComplete={handlePostCode}
        autoClose
        animation
      />
    </>
  );
};

export default ModalWinnerAddress;
