import { useMediaQuery } from 'react-responsive'
import { Link } from 'react-router-dom'

import useHeaderStore from 'store/useHeaderStore'

import ClassSearch from './ClassSearch'
import HeaderEventButton from './HeaderEventButton'
import HeaderHamburger from './HeaderHamburger'
import HeaderLoginButton from './HeaderLoginButton'
import styles from '../../Header.module.scss'

export default function SideMenu() {
  const isMobile = useMediaQuery({ maxWidth: 1024 })
  const gnbActiveDepthIndex = useHeaderStore((state) => state.gnbActiveDepthIndex)

  return (
    <div className={styles.sideMenu}>
      {gnbActiveDepthIndex !== null && gnbActiveDepthIndex.depth1 === 1 && <ClassSearch />}
      <HeaderEventButton />
      <Link className={styles.B2bLink} to="/promotion-landing/b2bLanding">
        기업교육
      </Link>
      <HeaderLoginButton />
      {isMobile && <HeaderHamburger />}
    </div>
  )
}
