import { useNavigate } from 'react-router-dom'

import TabMain from './TabMain'

import '../../assets/styles/notice.scss'
import React, { useEffect, useState } from 'react'

import api from '../../common/api'

import MobileHeader from 'components/common/MobileHeader'

export default function DetailPolicy() {
  const navigate = useNavigate()
  const [policyData, setPolicyData] = useState([])
  const [policyDataList, setPolicyDataList] = useState([])
  const [currentSeq, setCurrentSeq] = useState([])

  const getPolicyData = () => {
    api
      .get('/v2/privacy-policy/?seq=' + currentSeq)
      .then((response) => {
        setPolicyData(response.data.data.contents)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const getPolicyDataList = () => {
    api
      .get('/v2/privacy-policy/history-titles/')
      .then((response) => {
        setPolicyDataList(response.data.data)
      })
      .catch((e) => {
        console.log(e)
      })
  }
  const handlePolicyChange = (e) => {
    const targetValue = e.target.value
    setCurrentSeq(targetValue)
  }

  useEffect(() => {
    getPolicyData()
  }, [currentSeq])

  useEffect(() => {
    getPolicyDataList()
  }, [])
  return (
    <>
      <div className="policy-col">
        <div className="inner-col">
          <div className="container mypage-sub">
            <MobileHeader title="개인정보처리방침" onClick={() => (document.referrer == '' ? navigate('/service/center') : navigate(-1))}/>
            <section className="list-detail-col">
              <div className="title-col">
                <TabMain activeTab={'POLICY'} />
              </div>
              <div className="detail-title-area">
                <div className="tit-flex-form">
                  <strong className="sub-tit black" style={{ marginTop: '0' }}>
                    개인정보처리방침
                  </strong>
                  <div className="info">
                    <span className="date" />
                  </div>
                </div>
              </div>
              <div className="detail-content line-unset">
                <div className="detail-question">{policyData && <pre dangerouslySetInnerHTML={{ __html: policyData }}></pre>}</div>
              </div>
              <div className="select-box-col">
                <select name="policyPast" id="policyPast" onChange={handlePolicyChange}>
                  {policyDataList &&
                    policyDataList.map((policyList) => (
                      <option value={policyList.seq} key={policyList.seq}>
                        {policyList.title}
                      </option>
                    ))}
                </select>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  )
}
