import { useEffect, useRef } from 'react'

import { StaggerMotion } from 'components/promotion/utile/utile'
import ResponsiveImage from 'components/ResponsiveImage'

import { resultCountValues } from '../config'
import s from '../EnglishHome.module.css'

export default function ResultSection() {
  const motionRef = useRef(null)
  const staggerInstance = new StaggerMotion(motionRef, 500, s.active)

  useEffect(() => {
    const observer = new IntersectionObserver(
      (els) => {
        els.forEach((el) => {
          if (el.isIntersecting) {
            setTimeout(() => {
              staggerInstance.staggerOn(motionRef)
            }, 300)
          } else {
            staggerInstance.staggerOff(motionRef)
          }
        })
      },
      {
        threshold: 1
      }
    )

    observer.observe(motionRef.current)

    return () => {
      observer.disconnect()
      staggerInstance.staggerOff(motionRef)
    }
  }, [staggerInstance])

  return (
    <section className={s.result}>
      <div className={s.inner}>
        <ul className={s.countList} ref={motionRef}>
          {resultCountValues.map(({ pc, mo, alt }, index) => {
            return (
              <li key={index}>
                <ResponsiveImage pc={pc} mo={mo} alt={alt} maxWidth={1024} />
              </li>
            )
          })}
        </ul>
        <p className={s.summary}>*유료 수강생 4,854명 조사 결과 (2019.12)</p>
      </div>
    </section>
  )
}
