import { YANADOO_CDN_HOST } from 'common/config'

export const BEST_PERFORM_CDN = YANADOO_CDN_HOST + '/promotion/bestAwardsDBOnly_performance/'

export const bestAwardsPerformValues = {
  realReview: [
    {
      reviewImgSrc: `${BEST_PERFORM_CDN}img_review01.png`,
      reviewImgAlt:
        '외국인 앞에서도 촌스럽지 않게 표현하는 내 자신이 뿌듯했어요! 나이 50에도 도전할 수 있는 힘이 생겼어요. Reviewer 50대 개그맨 김준호님',
      iframeSrc: '0PzNhLpn1Ac?si=MdFu5jzlDsqm0Ud6'
    },
    {
      reviewImgSrc: `${BEST_PERFORM_CDN}img_review02.png`,
      reviewImgAlt:
        '미국에 살면서도 영어공부가 꼭 필요했어요. 현생을 살면서 영어를 잘 할 수 있는 방법을 찾았어요. Reviewer 미국 6년차 유튜버 칙블린님',
      iframeSrc: 'n8UO5xK1Xzo?si=VD714s3trAshnz6R'
    },
    {
      reviewImgSrc: `${BEST_PERFORM_CDN}img_review03.png`,
      reviewImgAlt:
        '하루 10분씩 쇼츠 보듯 쉽게 배워서 편하더라고요. 부모님과 해외여행도 두렵지 않아요! Reviewer 모델 유지형님',
      iframeSrc: 'iSVkC786OAk?si=bpPeqUbCQXjNAvWa'
    },
    {
      reviewImgSrc: `${BEST_PERFORM_CDN}img_review04.png`,
      reviewImgAlt:
        '지속적으로, 밀도있게 많은 문장을 접할 수 있어 정말 효율적인 공부법이에요! Reviewer 연대생 유튜버 예아님',
      iframeSrc: 'Xvvv97ozr1A?si=FIIXvdIY6SEDutXV'
    }
  ],
  yourLifeCarousel: [
    {
      title: '내 커리어에 특별함을 더해보세요 ✨',
      list: [
        {
          iframeSrc: 'DS70m9nseAE?si=_jmCHZC7c5uEKap3',
          thumbSrc: `${BEST_PERFORM_CDN}img_carousel-thumb01.png`,
          title: '17년차 외항사승무원의 영어 공부 팁'
        },
        {
          iframeSrc: 'PUeTNyfisCA?si=aj_cpw2q8V3g3C3U',
          thumbSrc: `${BEST_PERFORM_CDN}img_carousel-thumb02.png`,
          title: '“영어 회화? 제발 ‘공부’하지마세요”'
        },
        {
          iframeSrc: 'e98e3m2X4tA?si=f8WBphbBX-dfcQYF',
          thumbSrc: `${BEST_PERFORM_CDN}img_carousel-thumb03.png`,
          title: '서류 합격 2배, 나만의 무기는 영어!'
        },
        {
          iframeSrc: 'FZaSVbSm1Hc?si=5dy-CMCkTjiZYZd_',
          thumbSrc: `${BEST_PERFORM_CDN}img_carousel-thumb04.png`,
          title: '여행 유튜버가 말하는 영어가 절실한 순간'
        }
      ]
    },
    {
      title: '일상 속 소소한 자신감을 찾아보세요 🍀',
      list: [
        {
          iframeSrc: 'VQycJLfxpA4?si=ONZ4Iz0DLRyaIFU8',
          thumbSrc: `${BEST_PERFORM_CDN}img_carousel-thumb05.png`,
          title: '둥이들을 위한 엄마의 영어 공부법'
        },
        {
          iframeSrc: 'EX0dF1Kvxh0?si=t12RglmuqPoEL5uj',
          thumbSrc: `${BEST_PERFORM_CDN}img_carousel-thumb06.png`,
          title: '성공적 하루를 위한 자취생 모닝루틴'
        },
        {
          iframeSrc: 'nivc7eqvGRw?si=6eJl0RQBTQOQ9Gom',
          thumbSrc: `${BEST_PERFORM_CDN}img_carousel-thumb07_v2.png`,
          title: '토익 점수와 영어 회화는 별개더라고요'
        },
        {
          iframeSrc: 'BalIXoG4mD8?si=_7P_ZJnUPctJ8KL1',
          thumbSrc: `${BEST_PERFORM_CDN}img_carousel-thumb08.png`,
          title: '40대 영잘러 꿈 위해 노력할거예요'
        }
      ]
    }
  ],
  yourLifeCarousel03: [
    {
      imgSrc: `${BEST_PERFORM_CDN}img_carousel0301.png`,
      alt: '매일매일 공부해요. 이제는 영어공부가 부담스럽지 않고 매일매일 공부를 하고 있습니다.'
    },
    {
      imgSrc: `${BEST_PERFORM_CDN}img_carousel0302.png`,
      alt: '꾸준히 하게 돼 좋아요. 초보에게 맞게 짧은 시간 재미있을 정도로 공부하니 꾸준히 하게 돼 좋아요'
    },
    {
      imgSrc: `${BEST_PERFORM_CDN}img_carousel0303.png`,
      alt: '믿음이 생겼어요. 영어를 머리를 거치지 않고 입에서 바로 나가게 할 수 있다는 믿음이 생겼어요'
    },
    {
      imgSrc: `${BEST_PERFORM_CDN}img_carousel0304.png`,
      alt: '나이 70에 영어 회화를 시작했어요. 나이 70에 영어 회화를 시작했어요. 하루도 빼 놓지 않고 공부하니 이렇게 장학금을 받게 되었네요.'
    },
    {
      imgSrc: `${BEST_PERFORM_CDN}img_carousel0305.png`,
      alt: '교과서보다 도움돼요. 20년 넘게 본 교과서보다 예나쌤 수업 듣는 게 영어 공부에 훨씬 도움돼요'
    },
    {
      imgSrc: `${BEST_PERFORM_CDN}img_carousel0306.png`,
      alt: '앞으로도 열심히 달려볼게요. 열심히 듣다보면 자연스레 말문이 트이리라 믿습니다~앞으로도 열심히 달려보겠습니다!!'
    },
    {
      imgSrc: `${BEST_PERFORM_CDN}img_carousel0307.png`,
      alt: '자신감이 생겼어요. 늘 어렵게만 생각했었는데 강의를 계속 듣고 한마디씩 따라하다보니 자신감이 생기고 있습니다'
    },
    {
      imgSrc: `${BEST_PERFORM_CDN}img_carousel0308.png`,
      alt: '영어 공부를 습관으로 만들었어요. 뭐든 작심삼일이던 제가 영어 공부를 습관으로 만들어 뿌듯하네요.'
    },
    {
      imgSrc: `${BEST_PERFORM_CDN}img_carousel0309.png`,
      alt: '실력이 쑥쑥 늘어요. 막상 시작하니까 너무 재밌고 하다보니 실력도 쑥쑥 늘어나 너무 좋아요.'
    },
    {
      imgSrc: `${BEST_PERFORM_CDN}img_carousel0310.png`,
      alt: '야나두 너무 좋아요~ 아직 유창하게는 아니지만 자신감을 가지고 영어를 배우고 말하고 있습니다 야나두 너무 좋아요~^^'
    },
    {
      imgSrc: `${BEST_PERFORM_CDN}img_carousel0311.png`,
      alt: '영어가 늘고 있어요!! 영어 왕초보였는데 이제는 영어가 늘고 있는게 느껴져요!!'
    },
    {
      imgSrc: `${BEST_PERFORM_CDN}img_carousel0312.png`,
      alt: '영어를 꾸준히 할 수 있어요. 강의가 짧아 부족한 부분을 반복하는데도 힘들지 않고 시간의 제약을 받지 않아 영어를 꾸준히 할 수 있어요.'
    },
    {
      imgSrc: `${BEST_PERFORM_CDN}img_carousel0313.png`,
      alt: '생활의 일부분이 되었어요. 야나두가 생활의 일부분이 되어버렀습니다. 아직은 유창하게 말할수 있는 단계는 아니지만 조금씩 나아지고 있습니다.'
    }
  ],
  curriculum: {
    basic: {
      step: 'Step.1',
      description: (
        <>
          어순에 맞는 영어 표현을 쓸 수 있고 영어의 기본 <br />
          개념을 바르게 이해할 수 있어요
        </>
      ),
      list: [
        {
          title: '입문회화',
          teacher: '원예나',
          thumbnail: `${YANADOO_CDN_HOST}/promotion/bestAwardsDBOnly/img-teacher-thumbnail-01.png`,
          count: 20,
          keyword: '기초 회화'
        },
        {
          title: '앞자리 영어',
          teacher: '원예나',
          thumbnail: `${YANADOO_CDN_HOST}/promotion/bestAwardsDBOnly/img-teacher-thumbnail-02.png`,
          count: 71,
          keyword: '기초 회화'
        },
        {
          title: '1분 원샷 영어',
          teacher: '오드리',
          thumbnail: `${YANADOO_CDN_HOST}/promotion/bestAwardsDBOnly/img-teacher-thumbnail-03.png`,
          count: 200,
          keyword: '입문 회화'
        },
        {
          title: (
            <>
              <b>NEW</b> 버디톡
            </>
          ),
          teacher: '오드리',
          thumbnail: `${YANADOO_CDN_HOST}/promotion/bestAwardsDBOnly/img-teacher-thumbnail-04.png`,
          count: 41,
          keyword: '기초 영어 회화'
        },
        {
          title: '1mm 스피킹',
          teacher: '에일린',
          thumbnail: `${YANADOO_CDN_HOST}/promotion/bestAwardsDBOnly/img-teacher-thumbnail-05.png`,
          count: 23,
          keyword: '입문 회화'
        },
        {
          title: '마법패턴/명대사패턴',
          teacher: '원예나',
          thumbnail: `${YANADOO_CDN_HOST}/promotion/bestAwardsDBOnly/img-teacher-thumbnail-06.png`,
          count: 21,
          keyword: '기초 영어 패턴'
        }
      ]
    },
    advanced: {
      step: 'Step.2',
      description: (
        <>
          일상적인 주제의 대화를 나눌 수 있고 생각한 <br />
          내용을 바로 영어로 말할 수 있어요
        </>
      ),
      list: [
        {
          title: '5분 발음교정',
          teacher: '원예나',
          thumbnail: `${YANADOO_CDN_HOST}/promotion/bestAwardsDBOnly/img-teacher-thumbnail-06.png`,
          count: 20,
          keyword: '기초 발음'
        },
        {
          title: '리얼 영단어',
          teacher: '원예나',
          thumbnail: `${YANADOO_CDN_HOST}/promotion/bestAwardsDBOnly/img-teacher-thumbnail-01.png`,
          count: 141,
          keyword: '기초 단어'
        },
        {
          title: '리얼 영작문',
          teacher: '원예나',
          thumbnail: `${YANADOO_CDN_HOST}/promotion/bestAwardsDBOnly/img-teacher-thumbnail-02.png`,
          count: 36,
          keyword: '입문 영작문'
        },

        {
          title: '써먹는 보카',
          teacher: '에일린&설비',
          thumbnail: `${YANADOO_CDN_HOST}/promotion/bestAwardsDBOnly/img-teacher-thumbnail-07.png`,
          count: 67,
          keyword: '입문&초급 단어'
        },
        {
          title: '90일 패턴쉐도잉',
          teacher: '야나두 연구소',
          thumbnail: `${YANADOO_CDN_HOST}/promotion/bestAwardsDBOnly/img-teacher-thumbnail-08.png`,
          count: 90,
          keyword: '입문 영어 패턴'
        }
      ]
    },
    real: {
      step: 'Step.3',
      description: '원어민과 같은 표현으로 의사소통 할 수 있어요',
      list: [
        {
          title: '리얼 대화 영어',
          teacher: '오드리',
          thumbnail: `${YANADOO_CDN_HOST}/promotion/bestAwardsDBOnly/img-teacher-thumbnail-03.png`,
          count: 69,
          keyword: '실전 회화'
        },
        {
          title: '이디엄이 뭔대영',
          teacher: '원예나',
          thumbnail: `${YANADOO_CDN_HOST}/promotion/bestAwardsDBOnly/img-teacher-thumbnail-04.png`,
          count: 21,
          keyword: '원어민 회화'
        },
        {
          title: '리얼 데일리 영어',
          teacher: '오드리',
          thumbnail: `${YANADOO_CDN_HOST}/promotion/bestAwardsDBOnly/img-teacher-thumbnail-09.png`,
          count: 40,
          keyword: '원어민 회화'
        },
        {
          title: '콩글리시 타파하기',
          teacher: '피터',
          thumbnail: `${YANADOO_CDN_HOST}/promotion/bestAwardsDBOnly/img-teacher-thumbnail-10.png`,
          count: 15,
          keyword: '원어민 회화'
        },
        {
          title: '리얼 밀당 발음',
          teacher: '에리카',
          thumbnail: `${YANADOO_CDN_HOST}/promotion/bestAwardsDBOnly/img-teacher-thumbnail-11.png`,
          count: 20,
          keyword: '원어민 발음'
        },
        {
          title: '리얼 리듬 영어',
          teacher: '래릿',
          thumbnail: `${YANADOO_CDN_HOST}/promotion/bestAwardsDBOnly/img-teacher-thumbnail-12.png`,
          count: 20,
          keyword: '원어민 회화'
        },
        {
          title: '진짜 날라리 영어',
          teacher: '날라리데이브',
          thumbnail: `${YANADOO_CDN_HOST}/promotion/bestAwardsDBOnly/img-teacher-thumbnail-13.png`,
          count: 40,
          keyword: '원어민 회화'
        },
        {
          title: '리얼 몰입 영어',
          teacher: '지나',
          thumbnail: `${YANADOO_CDN_HOST}/promotion/bestAwardsDBOnly/img-teacher-thumbnail-14.png`,
          count: 20,
          keyword: '원어민 회화'
        },
        {
          title: '리얼 스피킹',
          teacher: '원예나',
          thumbnail: `${YANADOO_CDN_HOST}/promotion/bestAwardsDBOnly/img-teacher-thumbnail-15.png`,
          count: 100,
          keyword: '실전 회화'
        }
      ]
    }
  },
  aiTrainingStep: [
    {
      imgSrc: `${BEST_PERFORM_CDN}img_ai-training-step01.png`,
      alt: 'STEP 1 일단 시작하기 10초안에 원하는 수업을 찾을 수 있는 화면 쉽게 산만해지는 인간의 집중력은 단 10초니까! 동선을 방해하는 건 모조리 없애고 필요한 것만 남긴 직관적 인터페이스로 학습 효율을 극대화 하세요'
    },
    {
      imgSrc: `${BEST_PERFORM_CDN}img_ai-training-step02.png`,
      alt: 'STEP 2 내 수준 진단하기 쌓이는 실력이 눈에 보이는 NEW 학습 리포트 PT의 1:1 맞춤 밀착 관리를 도입하여 현 실력을 실시간으로 진단, 실력 향상을 위한 최단 루트를 제시하여 성공 확률을 2배 올려보세요'
    },
    {
      imgSrc: `${BEST_PERFORM_CDN}img_ai-training-step03.png`,
      alt: 'STEP 3 영어에 재미 붙이기 1:1 과외처럼 콕 찝어주는 자동 학습 큐레이션 당신의 학습 패턴을 분석해 오늘 배워야 할 강의와 AI 트레이닝 미션을 발송해 드립니다 맞춤 난이도로 영어 공부에 재미를 붙여보세요'
    },
    {
      imgSrc: `${BEST_PERFORM_CDN}img_ai-training-step04.png`,
      alt: 'STEP 4 뇌에 각인 시키기 입이 기억하게 만드는 1:1 음성 트레이닝 당신이 이미 알고 있는 수많은 영어 단어를 툭 내뱉을 수 있도록 물꼬를 터트려 드립니다'
    },
    {
      imgSrc: `${BEST_PERFORM_CDN}img_ai-training-step05.png`,
      alt: 'STEP 5 영어랑 친해지기 편하게 대화하며 영어가 툭 AI 원어민톡 당신의 학습 패턴을 분석해 오늘 배워야 할 강의와 AI 트레이닝 미션을 발송해 드립니다 맞춤 난이도로 영어 공부에 재미를 붙여보세요'
    },
    {
      imgSrc: `${BEST_PERFORM_CDN}img_ai-training-step06.png`,
      alt: 'STEP 6 평생 영어 습관 도전 깜빡할 일 없이 챙겨주는 스터디 알람 사람은 굳은 지보다 콕, 작은 신호에 움직이니까! 바쁜 당신의 옆구리를 콕 찔러줄 알람 기능으로 포기 없이 끝까지 영어 성공시켜드립니다'
    }
  ],
  precautions: {
    precautionsTitle: '※ 상품 유의사항',
    list: [
      {
        title: '1. 패키지 구성 및 수강 정보',
        text: [
          '① 10분 패키지 [베이직]',
          '<em> 1) 강의 구성 : 입문회화 + 앞자리영어1+ 앞자리영어2 + 마법패턴1 + 마법패턴2 + 1mm 스피킹 + 1분 원샷 영어 + 5분 발음 교정 + 리얼 영단어 + 리얼 영작문 + 써먹는 보카 (입문 / 초급) + 90일 패턴쉐도잉 + 리얼스피킹</em>',
          '<em> 2) 수강기간 : 기본 수강기간 3개월(90일) + 복습기간 9개월(275일) 총 12개월(1년) 복습기간은 무료 학습기간으로 제공</em>',
          '<em> 3) 추가 혜택 : 84,000원 장학금 + AI 음성 트레이닝 + PDF/MP3</em>',
          '② 10분 패키지 [프리미엄]',
          '<em> 1) 강의 구성 : 입문회화 + 앞자리영어1+ 앞자리영어2 + 마법패턴1 + 마법패턴2 + 1mm 스피킹 + 1분 원샷 영어 + 5분 발음 교정 + 리얼 영단어 + 리얼 영작문 + 써먹는 보카 (입문 / 초급) + 90일 패턴쉐도잉 + 리얼스피킹 + 리얼 대화 영어 (입문 / 초급) + 이디엄이 뭔대영 + 콩글리시 타파하기 + 리얼 밀당발음 + 리얼 리듬영어 + 진짜 날라리영어 + 리얼 몰입 영어 + 리얼 데일리 영어</em>',
          '<em> 2) 수강기간 : 기본 수강기간 3개월(90일) + 복습기간 9개월(275일) 총 12개월(1년) 복습기간은 무료 학습기간으로 제공</em>',
          '<em> 3) 추가 혜택 : 84,000원 장학금 + AI 음성 트레이닝 + PDF/MP3 + 트레이닝북 + AI 원어민톡 + 틈새단어 유료앱</em>',
          '③ 10분 패키지 [평생수강]',
          '<em> 1) 강의 구성 : 입문회화 + 앞자리영어1+ 앞자리영어2 + 마법패턴1 + 마법패턴2 + 1mm 스피킹 + 1분 원샷 영어 + 버디톡 + 5분 발음 교정 + 리얼 영단어 + 리얼 영작문 + 써먹는 보카 (입문 / 초급) + 90일 패턴 쉐도잉 + 리얼스피킹 + 리얼 대화 영어 (입문 / 초급) + 이디엄이 뭔대영 + 콩글리시 타파하기 + 리얼 밀당발음 + 리얼 리듬영어 + 진짜 날라리영어 + 리얼 몰입 영어 + 리얼 데일리 영어</em>',
          '<span>- 10분 패키지 [평생수강] 구매 수강생에게는 신규 강의를 포함한 모든 야나두 영어 강의가 제공됩니다. (야나두 클래스 제외)</span>',
          '<em> 2) 수강기간 : 조건 달성 시 평생 수강 / 기본 수강기간 3개월(90일) + 복습기간 9개월(275일) 총 12개월(1년) 복습기간은 무료 학습기간으로 제공</em>',
          '<span>- 평생수강 연장 방법 : 누적 출석일 77일 이상 달성 시(365일 기준), 종료 한 달 전 연장 안내 팝업에서 ‘연장하기’ 클릭시 연장 적용</span>',
          '<span>- 출석일 초기화 : 수강기간이 연장된 경우, 연장된 수강기간 종료일 기준 365일 전 출석일 수 초기화(매년 동일한 방법으로 연장됨)</span>',
          '<span>- 매년 연장된 수강기간 종료일 전까지 77일 출석 조건을 만족하는 경우 평생 동안 연장 가능합니다.</span>',
          '<span>- 혜택으로 지급되는 추가 강의는 평생수강 대상에서 제외될 수 있습니다.</span>',
          '<em> 3) 추가 혜택 : 84,000원 장학금 + AI 음성 트레이닝 + PDF/MP3 + 트레이닝북 + AI 원어민톡 + 틈새단어 유료앱</em>',
          '④ 패키지 별 구성은 사전고지 없이 제외 또는 변경 될 수 있습니다.',
          '⑤ 상황에 따라 사전 공지 없이 할인이 조기 마감 되거나 연장될 수 있습니다.',
          '⑥ 이벤트, 프로모션 등으로 판매가가 변동될 수 있으며, 이로 인한 환불이나 가격 보상은 불가합니다.',
          '⑦ 제공되는 상품 및 서비스는 회사 사정에 따라 변경 또는 제외 될 수 있습니다.',
          '※ 패키지에 따라 금액 인상에 차등이 있을 수 있습니다.'
        ]
      },
      {
        title: '2. 사용방법',
        text: [
          '① 본 상품의 학습은 구매결제 후 야나두 홈페이지 혹은 모바일 앱을 설치하여 회원가입 후 이용할 수 있습니다.',
          '② 본 상품의 영어 학습 유효기간은 평생수강 연장 안내에 따른 조건 달성시 1년씩 연장 가능하며, 평생수강 연장 조건 미달성시 1회에 한하여 1년의 연장기회를 제공합니다.',
          '③ 야나두 틈새단어 유료앱 설치 및 이용 방법',
          '<em> 1) 본 상품 구매 및 수강 시작하기</em>',
          '<em> 2) 야나두 앱 마이 클래스에서 카드 선택 및 스터디 알람 설정 후 수강 시작하기</em>',
          '<em> 3) 구글/앱스토어 통해 "틈새단어" 앱 다운받기</em>',
          '<span>- 앱 다운로드 https://bit.ly/3s8yDs6</span>',
          '<span>- 혹은, 구글 플레이스토어 및 애플 앱스토어에서 "틈새단어＂를 검색하여 찾으실 수 있습니다.</span>',
          '<em> 4) 틈새단어 앱 설치 후 야나두 ID로 로그인</em>',
          '<em> 5) 야나두 계정과 매칭 하여 유료 버전의 챕터 이용 가능 틈새단어 유료앱은 해당 상품의 유효 수강기간 동안 사용 가능하며, 기간종료 시 무료버전으로 전환됩니다.</em>',
          '④ 84,000원 장학금 참여 방법',
          '<em> 1) 야나두 앱 로그인하기</em>',
          '<em> 2) ‘장학금 미션’ 클릭하기</em>',
          '<em> 3) ‘장학금 유의사항’ 확인 후 참여하기</em>',
          '⑤ AI 원어민톡 참여 방법',
          '<em> 1) 야나두 앱 로그인하기</em>',
          '<em> 2) 우측 상단의 "AI" 아이콘을 클릭</em>',
          '<em> 3) 원하는 AI 친구와 대화를 시작하세요.</em>'
        ]
      },
      {
        title: '3. 배송안내',
        text: [
          '① 교재는 결제일로부터 순차적으로 배송되며 최대 1주 이내 수령 가능합니다. 단, 도서, 산간지역의 경우 배송기간이 더 소요될 수 있습니다.',
          '② 휴일 및 연휴 일정에 따라 배송이 지연 되는 경우 별도 안내 드립니다.',
          '③ 제품 수령지 미변경으로 인한 분실/배송비 부담에 대해서는 책임지지 않습니다.'
        ]
      },
      {
        title: '4. 반품 및 환불안내',
        text: [
          '① 본 상품의 환불 정책은 ‘야나두 패키지 환불 정책’을 따릅니다.',
          '<em> 1) 기본 학습 기간은 수강기간 3개월(90일) + 복습기간 9개월(275일) 총 12개월(1년)입니다.</em>',
          '<em> 2) 환불금액은 수강기간 3개월(90일)을 기준으로 책정합니다.</em>',
          '<em> 3) 무료로 제공되는 복습기간의 환불금액은 책정 할 수 있는 환불금이 없습니다.</em>',
          '<em> 4) 야나두 장학금을 받은 경우, 최종 환불 금액에서 장학금 지급 금액이 추가 차감됩니다.</em>',
          '<em> 5) 수강시작 후 7일 이내, 5강 미만 수강 시에는 100% 환불 가능합니다.</em>',
          '<em> 6) 수강시작 후 7일 초과, 5강 이상 수강 시에는 잔여일에 대한 환불이 가능합니다.</em>',
          '② 반품 및 환불 시 사은품을 포함한 모든 구성 상품은 개봉/사용하지 않은 상태로 반환해야 하며, 반품 배송비(상품 및 사은품별 별도 책정됨)는 소비자 부담입니다.',
          '<em> 1) 구매 후 15일 이전, 교재 훼손이 없는 경우 왕복 배송비 6,000원 차감 후 환불금액이 계산됩니다.</em>',
          '<em> 2) 교재는 구매 후 15일이 경과 된 경우, 트레이닝북 16,000원 차감 후 환불금액이 계산됩니다.</em>',
          '<em> 3) 본 환불정책은 소비자 보호원 및 공정거래위원회 규정 표준약관을 근거로 합니다.</em>',
          '<em> 4) 사은품 구성은 달라질 수 있으며, 사은품만 별도로 환불할 수 없습니다.</em>',
          '③ 더 자세한 환불 정책 및 정산 방법 등은 [환불 규정]을 참고해 주시기 바랍니다.',
          '④ 환불과 관련된 자세한 문의사항은 야나두 고객센터 1600–0563번으로 문의 주시기 바랍니다.'
        ]
      }
    ]
  }
}
