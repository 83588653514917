import React from 'react'

const aiMessages = {
  EMILY_ROSE: {
    intro: '러닝을 좋아하는 Emily와의 대화가 시작되었습니다.',
    suggestion: 'AI 친구니까! 부담없이 대화를 이어나가 보세요.😉'
  },
  SAM_ANDERSON: {
    intro: '탐험을 좋아하는 Sam과의 대화가 시작되었습니다.',
    suggestion: '익스트림 스포츠를 주제로 대화를 시작해 보세요.😉'
  },
  TONY_SCOTT: {
    intro: '축구를 좋아하는 Tony와의 대화가 시작되었습니다.',
    suggestion: '스포츠를 주제로 자유로운 대화를 나눠보세요!😉'
  },
  RACHAEL_SMITH: {
    intro: 'Rachael과의 대화가 시작되었습니다.',
    suggestion: '드라마를 주제로 자유로운 대화를 나눠보세요! 😉'
  },
  LILY_THOMPSON: {
    intro: 'Lily와의 대화가 시작되었습니다.',
    suggestion: '요리를 주제로 자유로운 대화를 나눠보세요! 😉'
  },
  LAURA_PARKER: {
    intro: 'Laura와의 대화가 시작되었습니다.',
    suggestion: '패션을 주제로 자유로운 대화를 나눠보세요! 😉'
  },
  TOM_KIM: {
    intro: 'Tom와의 대화가 시작되었습니다.',
    suggestion: '관심사로 대화를 나누며 기초 문법 퀴즈를 풀어보세요! 😉'
  },
  JENNY_JUNG: {
    intro: 'Jenny와의 대화가 시작되었습니다.',
    suggestion: '관심사로 대화를 나누며 신조어와 슬랭을 배워보세요! 😉'
  }
}

export default function AiFeedIntro({ ai }) {
  const aiData = aiMessages[ai]

  return (
    <>
      {aiData && (
        <>
          {aiData.intro}
          <br />
          {aiData.suggestion}
        </>
      )}
    </>
  )
}
