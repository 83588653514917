import React, { useState, useCallback, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import api from 'common/api'
import MobileHeader from 'components/common/MobileHeader'

import Coupon from './Coupon'
import TabCoupon from './TabCoupon'
import IcoNoCoupon from '../../assets/images/ico_no_coupon.png'
import ModalPortal from '../../ModalPortal'
import Lnb from '../Lnb'
import ModalCouponDownload from '../modal/ModalCouponDownload'
import ModalCouponRegister from '../modal/ModalCouponRegister'
import NoContent from '../NoContent'
import Pagination from '../Pagination'

export default function ItemCoupon() {
  const navigate = useNavigate()
  const [pageIdx, setPageIdx] = useState(1)
  const [couponListData, setCouponListData] = useState([])
  const [couponAllListData, setCouponAllListData] = useState([])
  const [couponDownloadData, setCouponDownloadData] = useState([])
  const [couponEndData, setCouponEndData] = useState([])
  const [isUrlButton, setUrlButton] = useState(false)
  const [toggleMenu, setToggleMenu] = useState(1)
  const [totalPages, setTotalPages] = useState()
  const [totalPages2, setTotalPages2] = useState()

  const [modalopen, setModalopen] = useState(false)
  const [modalopen2, setModalopen2] = useState(false)
  const location = useLocation()
  const pramChk = location.search.split('?')
  const pram = pramChk.length > 1 ? pramChk[1].split('&')[pramChk[1].split('&').length - 1] : ''

  const handleCurrentIdx = (idx) => {
    setPageIdx(idx)
  }

  // 사용가능 쿠폰 API
  const getCouponListData = () => {
    api
      .get(
        `/v2/coupon-publish/list?index=${pageIdx}&size=8&types&keys=couponUseYn&types=equal&values=N`
      )
      .then((response) => {
        setUrlButton(true)
        setCouponListData(response.data.data.content)
        setTotalPages(response.data.data.totalPages)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  // 다운로드 가능한 쿠폰 API
  const getCouponDownloadData = () => {
    api
      .get(`/v2/coupon/possible/download/list?size=100`)
      .then((response) => {
        setCouponDownloadData(response.data.data.content)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  // 사용가능 + 종료 쿠폰 API
  const getCouponAllListData = () => {
    api
      .get(
        `/v2/coupon-publish/list?index=${pageIdx}&size=8&types&keys=couponUseYn&types=equal&values=ALL`
      )
      .then((response) => {
        setCouponAllListData(response.data.data.content)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  // 종료 쿠폰 API
  const getCouponEndData = () => {
    api
      .get(
        `/v2/coupon-publish/list?index=${pageIdx}&size=8&types&keys=couponUseYn&types=equal&values=Y`
      )
      .then((response) => {
        setCouponEndData(response.data.data.content)
        setTotalPages2(response.data.data.totalPages)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  useEffect(() => {
    getCouponListData()
    getCouponAllListData()
    getCouponDownloadData()
    getCouponEndData()
  }, [pageIdx])

  const handleOpenModal = useCallback((e) => {
    document.body.classList.add('modal-open')
    setModalopen(true)
  }, [])

  const handleCloseModal = useCallback((e) => {
    document.body.classList.remove('modal-open')
    setModalopen(false)
  }, [])

  const handleOpenModal2 = useCallback((e) => {
    document.body.classList.add('modal-open')
    setModalopen2(true)
  }, [])

  const handleCloseModal2 = useCallback((e) => {
    document.body.classList.remove('modal-open')
    setModalopen2(false)
  }, [])

  const handleToggleMenu = (id) => {
    if (couponEndData.length > 0) {
      setToggleMenu(id)
      setPageIdx(1)
    }
  }

  useEffect(() => {
    if (pram === 'open') {
      handleOpenModal()
    }
  }, [pram])

  return (
    <>
      <div className="coupon-col">
        <div className="inner-col">
          <div className="container mypage-sub">
            <MobileHeader title="쿠폰 수강권" onClick={() => navigate('/mypage/1')} />
            <Lnb />
            <section className="coupon-lists-col">
              <TabCoupon activeTab={'COUPON'} />
              <div className="tab-content active">
                <div className="btn-flex-form btn-coupon">
                  <button className="btn" onClick={handleOpenModal}>
                    <span>다운 받을 수 있는 쿠폰 ({couponDownloadData.length})</span>
                  </button>
                  <button className="btn positive" onClick={handleOpenModal2}>
                    <span>쿠폰 등록</span>
                  </button>
                </div>
                <div className="toggle-col">
                  <strong>보유 쿠폰</strong>
                  <div className={'coupon-toggle-menu' + (toggleMenu === 2 ? ' active' : '')}>
                    <button
                      className={'toggle-item' + (toggleMenu === 1 ? ' active' : '')}
                      onClick={() => handleToggleMenu(1)}
                    >
                      <span>사용가능</span>
                    </button>
                    <button
                      className={'toggle-item' + (toggleMenu === 2 ? ' active' : '')}
                      onClick={() => handleToggleMenu(2)}
                    >
                      <span>종료</span>
                    </button>
                  </div>
                </div>
                <div className={'coupon-tab-content' + (toggleMenu === 1 ? ' active' : '')}>
                  {couponListData.length > 0 ? (
                    <ul className="coupon-lists">
                      {couponListData.map((couponData, idx) => {
                        return (
                          <Coupon
                            key={idx}
                            type={'list'}
                            couponData={couponData}
                            isUrlButton={isUrlButton}
                          />
                        )
                      })}
                    </ul>
                  ) : (
                    <NoContent icoImg={IcoNoCoupon} titleMsg={'보유하신 쿠폰이 없습니다.'} />
                  )}

                  {couponListData.length > 0 && (
                    <Pagination
                      type={'IDX'}
                      handleCurrentIdx={handleCurrentIdx}
                      totalPages={totalPages}
                      limitPage={5}
                      maxNum={5}
                      minNum={0}
                      pathName={'/mypage/coupon'}
                      pageIdx={pageIdx}
                    />
                  )}
                </div>
                <div className={'coupon-tab-content' + (toggleMenu === 2 ? ' active' : '')}>
                  <ul className="coupon-lists">
                    {couponEndData.map((couponData, idx) => {
                      return <Coupon key={idx} type={'list'} couponData={couponData} />
                    })}
                  </ul>
                  <Pagination
                    type={'IDX'}
                    handleCurrentIdx={handleCurrentIdx}
                    totalPages={totalPages2}
                    limitPage={5}
                    maxNum={5}
                    minNum={0}
                    pathName={'/mypage/coupon'}
                    pageIdx={pageIdx}
                  />
                </div>
                <div className="coupon-notice-col">
                  <strong>유의사항</strong>
                  <ul className="coupon-notice-lists">
                    <li>종료된 쿠폰은 최근 1년 내역까지만 조회 가능 합니다.</li>
                    <li>야나두 클래스의 클래스 상품은 쿠폰 적용 제외 대상입니다.</li>
                    <li>일부 상품은 쿠폰 할인 대상에서 제외됩니다. </li>
                    <li>쿠폰의 최소 주문금액 조건은 쿠폰의 종류에 따라 기준이 적용됩니다.</li>
                    <li>
                      주문 결제 시 쿠폰을 직접 선택하여 사용하지 않으면 쿠폰 할인금액은 자동으로
                      할인되지 않습니다.
                    </li>
                    <li>
                      주문 결제시 사용한 쿠폰은 주문 취소 및 반품시 재발급 되지 않습니다.
                      <br /> (단, 상품 발송전 주문을 전체 취소하는 경우 다시 발급될 수 있습니다.)
                    </li>
                    <li className="noti">
                      <span> 쿠폰 조건은 예고없이 변경되거나 조기종료 될 수 있습니다.</span>
                    </li>
                  </ul>
                </div>
                {modalopen ? (
                  <ModalPortal>
                    {couponDownloadData && (
                      <ModalCouponDownload
                        btnClose
                        handleCloseModal={handleCloseModal}
                        couponDownloadData={couponDownloadData}
                        getCouponDownloadData={getCouponDownloadData}
                      />
                    )}
                  </ModalPortal>
                ) : null}
                {modalopen2 ? (
                  <ModalPortal>
                    <ModalCouponRegister
                      btnClose
                      handleCloseModal={handleCloseModal2}
                      couponAllListData={couponAllListData}
                    />
                  </ModalPortal>
                ) : null}
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  )
}
