import React from 'react'

import styled from 'styled-components'
import SwiperCore, { Pagination, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import SectionTitle from './SectionTitle'
import { textRollingListValues } from '../config'
import IcoBottomQuotes from 'assets/images/ico_bottom_quotes.png'
import IcoBottomQuotesY from 'assets/images/ico_bottom_quotes_yellow.png'
import IcoTopQuotes from 'assets/images/ico_top_quotes.png'
import IcoTopQuotesY from 'assets/images/ico_top_quotes_yellow.png'

SwiperCore.use([Pagination, Autoplay])

export default function TextRollingSection() {
  return (
    <Container>
      <SectionTitle title={'야나두는 이렇게 다릅니다.'} desc={'무엇이 진정한 가치를 지니는지\n 야나두는 끊임없이 연구하고 개선합니다.'} />
      <RollingSwiperBox>
        <Swiper
          pagination={{
            clickable: true
          }}
          centeredSlides={true}
          spaceBetween={80}
          loop={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false
          }}
          breakpoints={{
            1024: {
              slidesPerView: 3.2
            },
            320: {
              slidesPerView: 1
            }
          }}
        >
          {textRollingListValues?.map((item, idx) => (
            <SwiperSlide key={idx}>
              <strong>{item.title}</strong>
              <p>{item.desc}</p>
            </SwiperSlide>
          ))}
        </Swiper>
      </RollingSwiperBox>
    </Container>
  )
}

const Container = styled.section`
  padding: 16rem 0;
  background: #f7f7fc;
  text-align: center;

  @media ${({ theme }) => theme.device.mobile} {
    padding: 14rem 0 4.8rem;
  }
`

const RollingSwiperBox = styled.div`
  margin-top: 10rem;

  strong {
    display: block;
    font-size: 2.4rem;
  }

  p {
    margin-top: 2.4rem;
    font-weight: 400;
    font-size: 2rem;
    color: #717188;
    line-height: 1.3;
  }

  .swiper-container {
    padding: 9.1rem 0;

    .swiper-slide {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: -9rem;
        left: 50%;
        width: 6.5rem;
        height: 4.3rem;
        margin-left: -3.25rem;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-image: url(${IcoTopQuotes});
        background-size: 100% auto;
      }

      &::after {
        content: '';
        position: absolute;
        bottom: -9rem;
        left: 50%;
        width: 6.5rem;
        height: 4.3rem;
        margin-left: -3.25rem;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-image: url(${IcoBottomQuotes});
        background-size: 100% auto;
      }

      &.swiper-slide-active {
        &::before {
          background-image: url(${IcoTopQuotesY});
        }

        &::after {
          background-image: url(${IcoBottomQuotesY});
        }
      }
    }
  }

  .swiper-pagination {
    display: none;
  }

  @media ${({ theme }) => theme.device.mobile} {
    margin-top: 8rem;
    padding: 0 10rem;

    .swiper-container {
      padding: 10.1rem 0 18.8rem;

      .swiper-slide {
        &::before {
          top: -10rem;
          left: 50%;
          width: 8rem;
          height: 5.3rem;
          margin-left: -4rem;
        }

        &::after {
          bottom: -10rem;
          left: 50%;
          width: 8rem;
          height: 5.3rem;
          margin-left: -4rem;
        }
      }
    }

    .swiper-pagination {
      display: block;
      bottom: 0;

      .swiper-pagination-bullet {
        width: 1.6rem;
        height: 1.6rem;
        margin: 0 0.4rem;
        opacity: 1;
        border: 1px solid #a0a0b6;
        background-color: #fff;
        box-sizing: border-box;
      }

      .swiper-pagination-bullet-active {
        background-color: #a0a0b6;
      }
    }

    strong {
      font-size: 3.2rem;
    }

    p {
      margin-top: 2.4rem;
      font-size: 2.8rem;
    }
  }
`
