const WEBVIEW_SERVICE = {
  image_host: 'https://english.yanadoocdn.com/upload/yanadoo/new/cs/',
  menu_list: [
    // { title: '학습 기기 관리', link: '/webview/mypage/device' },
    { title: '공지사항', link: '/webview/mypage/notice' },
    { title: '환불 규정 안내', link: '/webview/mypage/customer/refundInfo' }
    // { title: '장학금', link: '/webview/mypage/scholarship' },
    // { title: '개인정보 처리방침', link: '/webview/mypage/customer/policy' },
    // { title: '이용약관', link: '/webview/mypage/customer/serviceTerms' }
  ],
  svg_arrow_r: (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M5 2L11 8L5 14"
        stroke="#B7B7C8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  svg_alert: (
    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
      <circle cx="30.375" cy="30" r="25.1" stroke="#CFCFDA" strokeWidth="3.8" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.375 17.625C31.6176 17.625 32.625 18.8521 32.625 20.3659V31.3841C32.625 32.8979 31.6176 34.125 30.375 34.125C29.1324 34.125 28.125 32.8979 28.125 31.3841V20.3659C28.125 18.8521 29.1324 17.625 30.375 17.625Z"
        fill="#CFCFDA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.75 39.75C27.75 38.3003 28.9194 37.125 30.3619 37.125H30.3881C31.8306 37.125 33 38.3003 33 39.75C33 41.1997 31.8306 42.375 30.3881 42.375H30.3619C28.9194 42.375 27.75 41.1997 27.75 39.75Z"
        fill="#CFCFDA"
      />
    </svg>
  )
}

export { WEBVIEW_SERVICE }
