import ClassReview from 'pages/kakaoNotice/ClassReview'

const KakaoNoticeRoutes = [
  {
    path: 'kakaoNotice/*',
    children: [
      { path: 'classReview', element: <ClassReview /> } //
    ]
  }
]

export default KakaoNoticeRoutes
