import React, { useState, useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useMediaQuery } from 'react-responsive'
import { Link, useParams } from 'react-router-dom'

import styled, { ThemeProvider } from 'styled-components'

import api from 'common/api'
import * as config from 'common/config'
import theme from 'common/theme'
import DdayCounter from 'components/DdayCounter'
import FloatingBannerHeight from 'components/promotion/FloatingBannerHeight'

import MallError from './Error'

import 'react-loading-skeleton/dist/skeleton.css'

export default function MallIntro() {
  const targetDate = '20240830' //d-day 날짜 설정

  const { mallSeq } = useParams()
  const deviceCheck = useMediaQuery({ maxWidth: 1024 }) ? true : false
  const [isLoading, setLoarding] = useState(true)
  const [isError, setError] = useState(false)
  const [mallData, setMallData] = useState(null)
  const [isExpired, setExpired] = useState(false)

  const mallInfoGet = async (code) => {
    try {
      await api
        .get(`/v2/mall/` + code)
        .then((response) => {
          if (response.data.meta.code === -1) {
            setError(true)
          } else {
            const data = response.data.data
            setMallData(data)
          }
        })
        .catch((e) => {
          alert(config.MESSAGE['common-error'])
          setError(true)
        })
    } catch (e) {
      console.log(e)
      setError(true)
    } finally {
      setLoarding(false)
    }
  }

  useEffect(() => {
    mallInfoGet(mallSeq.toLowerCase())
  }, [])

  const getFormattedDate = () => {
    const today = new Date()
    const year = today.getFullYear()
    const month = String(today.getMonth() + 1).padStart(2, '0')
    const day = String(today.getDate()).padStart(2, '0')

    return `${year}${month}${day}`
  }

  useEffect(() => {
    const currentDate = getFormattedDate()
    setExpired(currentDate > targetDate)
  }, [targetDate])

  return (
    <>
      <ThemeProvider theme={theme}>
        {isLoading && isLoading ? (
          <>
            <IntroTemplete>
              <div className="inner">
                <Skeleton height={'100vh'} />
              </div>
            </IntroTemplete>
          </>
        ) : isError && isError ? (
          <MallError comment={`연결할 수 없는 페이지 입니다`} />
        ) : (
          <>
            {!!mallData ? (
              deviceCheck ? (
                <ImgWrap>
                  <img src={mallData.bannerImageMO} alt="" />
                </ImgWrap>
              ) : (
                <MallBanner
                  style={{
                    backgroundImage: `url('${mallData.bannerImagePC}')`
                  }}
                ></MallBanner>
              )
            ) : null}
            {!!mallData && mallData.videoCode && mallData.videoCode !== '' ? (
              <IntroVideo>
                <dl>
                  <dt>
                    미리 들어보는 성공비결<strong>164만 야나두 회원이 경험한 놀라운 변화!</strong>
                  </dt>
                  <dd>
                    <div className="media-box">
                      <iframe
                        width="100%"
                        height="100%"
                        src={mallData.videoCode}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      ></iframe>
                    </div>
                  </dd>
                </dl>
              </IntroVideo>
            ) : null}
            <IntroContentImg>
              {!!mallData && mallData.contentImagePC ? (
                <img src={deviceCheck ? mallData.contentImageMO : mallData.contentImagePC} alt="" />
              ) : null}
            </IntroContentImg>

            <FloatingBannerHeight>
              <IntroBottomBar id="fixedBanner">
                {mallSeq !== 'lllcard' || (mallSeq === 'lllcard' && isExpired) ? (
                  <p>
                    <strong>특별 할인가로</strong> <strong>만나볼 수 있어요!</strong>
                  </p>
                ) : (
                  <div className="DdayBoxWrap">
                    <p className="txt">{deviceCheck ? '마감까지' : '사용 마감까지'}</p>
                    <DdayCounter targetDate={targetDate} />
                  </div>
                )}
                <Link to={`/mall/${mallSeq}/list`}>
                  {mallSeq !== 'lllcard'
                    ? mallData && mallData.buttonName
                      ? mallData.buttonName
                      : '전용혜택관 입장하기'
                    : deviceCheck
                    ? '평생교육바우처 상품'
                    : '평생교육바우처 상품 바로보기'}
                </Link>
              </IntroBottomBar>
            </FloatingBannerHeight>
          </>
        )}
      </ThemeProvider>
    </>
  )
}

const IntroVideo = styled.section`
  padding: 16rem 0;
  background-color: #f7f7fc;
  dl {
    dt {
      font-weight: 400;
      font-size: 2.8rem;
      color: #28284b;
      text-align: center;
      strong {
        display: block;
        font-weight: 700;
        font-size: 4.8rem;
        color: #000;
        text-align: center;
      }
    }
    dd {
      padding-top: 7.2rem;
      .media-box {
        width: 88.6rem;
        margin: auto;
        iframe {
          width: 100%;
          height: 50rem;
        }
      }
    }
    @media ${({ theme }) => theme.device.mobile} {
      width: calc(100% - 6.4rem);
      max-width: calc(150rem - 6.4rem);
      margin: auto;
      box-sizing: border-box;
      dt {
        font-size: 2.8rem;
        text-align: left;
        strong {
          padding-top: 1rem;
          font-size: 4.8rem;
          text-align: left;
          word-break: keep-all;
        }
      }
      dd {
        .media-box {
          width: 100%;
          iframe {
            height: 53vw;
            max-height: 81rem;
          }
        }
      }
    }
  }
`

const ImgWrap = styled.section`
  text-align: center;
`

const MallBanner = styled.section`
  width: 100%;
  height: 42rem;
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: 50% 0;
`

const IntroTemplete = styled.section`
  .inner {
    display: block;
    width: 100%;
    max-width: 120rem;
    margin: auto;
    img {
      display: block;
      width: 100%;
    }
    .media-box {
      display: block;
      width: 75rem;
      height: 52rem;
      margin: auto;
      background-color: #000;
      & > div {
        width: 100%;
        height: 100%;
      }
      iframe {
        width: 100%;
        height: 100%;
      }
    }
  }
  a {
    display: block;
    width: 70rem;
    margin: auto;
    padding: 3rem 0;
    font-weight: 500;
    font-size: 2.8rem;
    color: #1a1a1a;
    background-color: #ffb900;
    text-align: center;
  }
`

const IntroContentImg = styled.div`
  img {
    display: block;
    max-width: 100%;
    margin: auto;
  }
`

// 하단 fixed bottom
const IntroBottomBar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10rem;
  background-color: #28284b;
  z-index: 10;
  p {
    font-weight: 700;
    font-size: 2.4rem;
    color: #fff;
    line-height: 1;
    & + a {
      margin-left: 5rem;
    }
  }
  a {
    display: inline-block;
    position: relative;
    padding: 1.8rem 4.8rem 1.6rem 2.3rem;
    font-weight: 700;
    font-size: 2rem;
    color: #28284b !important;
    background-color: #ffb900;
    border-radius: 12rem;
    &:after {
      content: '';
      display: inline-block;
      width: 2rem;
      height: 2rem;
      margin-left: 0.6rem;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      background: url('https://english.yanadoocdn.com/upload/yanadoo/new/common/ico_arrow_right.png')
        no-repeat center / contain;
    }
  }
  .DdayBoxWrap {
    display: flex;
    align-items: center;
    .txt {
      color: #cfcfda;
    }
    .DdayBox {
      margin-left: 1.6rem;
      margin-right: 5rem;
      font-size: 2.8rem;
      line-height: 3.6rem;
      font-weight: bold;
      color: #fff;
      .dash {
        display: inline-block;
        width: 23px;
        text-align: center;
      }
      span:not(.dash) {
        position: relative;
        display: inline-block;
        width: 3.6rem;
        height: 4.8rem;
        margin: 0 1px;
        border-radius: 0.4rem;
        text-align: center;
        line-height: 4.6rem;
        border: 2px solid #22223f;
        background-color: #484873;
        box-sizing: border-box;
        &::before {
          content: '';
          display: block;
          width: 100%;
          height: 1px;
          background: rgba(255, 255, 255, 0.2);
          position: absolute;
          top: 50%;
          transform: traslateY(-50%);
          margin-top: -1px;
        }
        &::after {
          content: '';
          display: block;
          width: 100%;
          height: 1px;
          background: #22223f;
          position: absolute;
          top: 50%;
          transform: traslateY(-50%);
        }
      }
    }
  }
  @media ${({ theme }) => theme.device.mobile} {
    height: 13.8rem;
    justify-content: space-between;
    p {
      flex: 1 auto;
      padding-left: 4.2rem;
      font-size: 2.8rem;
      text-align: left;
      box-sizing: border-box;
      strong {
        display: block;
        padding: 0.4rem 0;
        text-align: left;
      }
      & + a {
        margin-left: 0;
      }
    }

    a {
      margin-right: 4.2rem;
      padding: 2rem 7rem 1.8rem 4.1rem;
      font-size: 2.8rem;
      &:after {
        width: 2.4rem;
        height: 2.4rem;
      }
    }

    .DdayBoxWrap {
      .txt {
        width: 10rem;
        line-height: 3.4rem;
        flex-shrink: 0;
      }
      .DdayBox {
        margin-left: 2.4rem;
        flex-shrink: 0;
        span:not(.dash) {
          width: 4.8rem;
          height: 6.4rem;
          font-size: 3.6rem;
          line-height: 6.2rem;
        }
        .dash {
          width: 3.1rem;
        }
      }
    }
  }

  @media screen and (max-width: 750px) {
    height: 18.4vw;
    p {
      padding: 0 0 0 5.6vw;
      font-size: 3.73vw;
    }
    a {
      margin-right: 5.6vw;
      padding: 2.67vw 8.33vw 2.53vw 5.47vw;
      font-size: 3.73vw;
      &:after {
        width: 3.2vw;
        height: 3.2vw;
      }
    }
    .DdayBoxWrap {
      .txt {
        width: 13.33vw;
        line-height: 4.53vw;
      }
      .DdayBox {
        margin-left: 3.2vw;
        margin-right: 0;
        span:not(.dash) {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 6.4vw;
          height: 8.53vw;
          font-size: 4.8vw;
          line-height: 1;
        }
        .dash {
          width: 4.13vw;
        }
      }
    }
  }
`
