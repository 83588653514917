import React, { useState, useRef, useEffect } from 'react';
import CountryCode from './CountryCode';
import Certified from './Certified';
import api from 'common/api';
import * as config from 'common/config';

export default function CertificationType({
  defaultTypeUrl,
  defaultCertUrl,
  callBackFunc,
  handleToastStatus,
  handleCertificationValue,
}) {
  const phoneRef = useRef();
  const emailRef = useRef();

  const [validStatus, setValidStatus] = useState({
    phone: false,
    email: false,
  });

  const [validText, setValidText] = useState({
    email: '',
  });

  const [inputValue, setInputValue] = useState({
    phone: '',
    countryCode: '82',
    email: '',
    findType: 'CELLPHONE',
  });

  const certificationUrl =
    defaultCertUrl +
    (inputValue.findType === 'CELLPHONE'
      ? '&certType=CELLPHONE&cellphone=' +
        inputValue.phone +
        '&countryCode=' +
        inputValue.countryCode
      : '&certType=EMAIL&email=' + inputValue.email);

  const [certifiedComplete, setCertifiedComplete] = useState(false);
  const [certifiedCodeShow, setCertifiedCodeShow] = useState(false);
  const [timerStart, setTimerStart] = useState(0);

  const handleTypeCheck = (e) => {
    let { value } = e.target;

    setInputValue({
      ...inputValue,
      findType: value,
      phone: '',
      email: '',
    });

    setValidStatus({
      ...validStatus,
      phone: '',
      email: '',
    });

    setCertifiedCodeShow(false);
    setCertifiedComplete(false);
  };

  const handlePhoneCheck = (e) => {
    let phoneRegExp = /^[0-9]*$/;

    if (!phoneRegExp.test(e.target.value)) {
      phoneRef.current.focus();

      return false;
    }

    setInputValue({ ...inputValue, phone: e.target.value });

    if (e.target.value.length < 10) {
      setValidStatus({ ...validStatus, phone: false });

      return false;
    }

    setValidStatus({ ...validStatus, phone: true });

    return true;
  };

  const handleEmailValue = (e) => {
    let { value } = e.target;

    if (value) {
      setInputValue({ ...inputValue, email: value });
    } else {
      setInputValue({ ...inputValue, email: '' });
      setValidStatus({ ...validStatus, email: false });
      setValidText({ ...validText, email: '' });
    }
  };

  const handleEmailCheck = () => {
    let emailRegExp = /^((\w|[\-\.])+)@((\w|[\-\.])+)\.([A-Za-z]+)$/;

    if (!emailRegExp.test(inputValue.email)) {
      emailRef.current.focus();
      setValidText({ ...validText, email: '이메일 주소를 확인해주세요.' });
      setValidStatus({ ...validStatus, email: false });

      return false;
    }

    setValidText({ ...validText, email: '' });
    setValidStatus({ ...validStatus, email: true });

    return true;
  };

  const handleCountryCode = (e) => {
    setInputValue({ ...inputValue, countryCode: e.target.value });
  };

  const getCertNumber = () => {
    if (inputValue.findType === 'EMAIL' && !handleEmailCheck()) {
      return false;
    }

    api
      .post(
        config.AUTH_API_HOST +
          defaultTypeUrl +
          (inputValue.findType === 'CELLPHONE'
            ? '&certType=CELLPHONE&cellphone=' +
              inputValue.phone +
              '&countryCode=' +
              inputValue.countryCode
            : '&certType=EMAIL&email=' + inputValue.email)
      )
      .then((response) => {
        if (response.data.meta.code === 200) {
          alert('인증번호를 발송하였습니다.');
          setTimerStart((prevNum) => prevNum + 1);
          setCertifiedCodeShow(true);

          if (callBackFunc) {
            callBackFunc(response);
          }
        } else {
          alert(response.data.meta.message);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleCertifiedComplete = (status) => {
    setCertifiedComplete(status);
  };

  useEffect(() => {
    if (certifiedComplete) {
      if (validStatus.phone || validStatus.email) {
        inputValue.findType === 'CELLPHONE'
          ? handleCertificationValue(
              inputValue.findType,
              inputValue.phone,
              inputValue.countryCode
            )
          : handleCertificationValue(inputValue.findType, inputValue.email);
      }
    } else {
      handleCertificationValue();
    }
  }, [certifiedComplete]);

  return (
    <>
      <div className="type-check-box">
        <label htmlFor="cellphone">
          <input
            type="radio"
            id="cellphone"
            name="findType"
            value="CELLPHONE"
            onChange={handleTypeCheck}
            checked={inputValue.findType === 'CELLPHONE' ? true : false}
          />
          <span className="chk"></span>
          휴대전화로 인증
        </label>
        <label htmlFor="email">
          <input
            type="radio"
            id="email"
            name="findType"
            value="EMAIL"
            onChange={handleTypeCheck}
            checked={inputValue.findType === 'EMAIL' ? true : false}
          />
          <span className="chk"></span>
          이메일로 인증
        </label>
      </div>
      {inputValue.findType === 'CELLPHONE' ? (
        <dl>
          <dt>휴대전화</dt>
          <dd className="phone-box">
            <CountryCode handleCountryCode={handleCountryCode} />
            <div
              className={
                'certified-code-form' + (certifiedComplete ? ' disabled' : '')
              }
            >
              <input
                type="text"
                maxLength="11"
                placeholder="휴대전화 번호를 입력해주세요."
                name="phone"
                ref={phoneRef}
                value={inputValue.phone}
                onChange={handlePhoneCheck}
                readOnly={certifiedComplete ? true : false}
              />
              <button
                type="button"
                className={
                  'btn btn-certified-code' +
                  (validStatus.phone ? ' active' : ' disabled')
                }
                disabled={validStatus.phone ? false : true}
                onClick={getCertNumber}
              >
                {certifiedCodeShow ? (
                  <span>재전송</span>
                ) : (
                  <span>인증번호</span>
                )}
              </button>
            </div>
          </dd>
        </dl>
      ) : inputValue.findType === 'EMAIL' ? (
        <dl>
          <dt>이메일 주소</dt>
          <dd className="email-box">
            <div className={certifiedComplete ? ' disabled' : ''}>
              <input
                type="text"
                placeholder="이메일 주소를 입력해주세요."
                className={
                  validText.email && (validStatus.email ? 'success' : 'warning')
                }
                name="email"
                ref={emailRef}
                value={inputValue.email}
                onChange={handleEmailValue}
                readOnly={certifiedComplete ? true : false}
              />
              {validText.email && (
                <span
                  className={
                    'valid-text' +
                    (validStatus.email ? ' active success' : ' active warning')
                  }
                >
                  {validText.email}
                </span>
              )}
            </div>
            <button
              type="button"
              className={
                'btn btn-certified-code' +
                (inputValue.email ? ' active' : ' disabled')
              }
              disabled={inputValue.email ? false : true}
              onClick={getCertNumber}
            >
              {certifiedCodeShow ? <span>재전송</span> : <span>인증번호</span>}
            </button>
          </dd>
        </dl>
      ) : null}
      <Certified
        time={300}
        timerStart={timerStart}
        handleCertifiedComplete={handleCertifiedComplete}
        reset={inputValue.findType}
        checkCertUrl={certificationUrl}
        handleToastStatus={handleToastStatus}
      />
    </>
  );
}
