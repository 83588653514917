import React, { useState, useCallback } from 'react';
import ModalPortal from '../../ModalPortal';
import ModalJoin from '../../components/modal/ModalJoin';

export default function JoinPage() {
  const [modalopen, setModalopen] = useState(true);

  const handleCloseModalJoin = useCallback((e) => {
    document.body.classList.remove('modal-open');
    setModalopen(false);
  }, []);

  return (
    <>
      {modalopen ? (
        <ModalPortal>
          <ModalJoin page btnClose handleCloseModal={handleCloseModalJoin} />
        </ModalPortal>
      ) : null}
    </>
  );
}
