import styles from './ModalCounselComplete.module.scss'
import ModalWrap from '../ModalWrap'

export default function ModalCounselComplete({ onClose, buttonColor, buttonBackground }) {
  return (
    <ModalWrap onClose={onClose}>
      <div className={styles.counselCompleteModal}>
        <div onClick={onClose}></div>
        <div className={styles.popYndContent}>
          <p>
            <strong>영어 회화 목표에 다가가셨네요!</strong>요청하신 시간에 전화 연락 드리겠습니다.
          </p>
          <button
            type="button"
            onClick={onClose}
            style={{ background: buttonBackground, color: buttonColor }}
            className={styles.submitButton}
          >
            확인
          </button>
        </div>
      </div>
    </ModalWrap>
  )
}
