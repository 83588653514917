import React, { useCallback, useRef, useState } from 'react'

import cx from 'classnames'

import s from './CharacterVideo.module.scss'

export default function CharacterVideo({ name, poster }) {
  const videoRef = useRef(null)
  const [isMuted, setIsMuted] = useState(true)

  // 음소거 해제 함수
  const handleUnmute = useCallback(() => {
    if (isMuted) {
      setIsMuted(false)
      videoRef.current.muted = false
      videoRef.current.currentTime = 0
    } else {
      setIsMuted(true)
      videoRef.current.muted = true // 다시 음소거
    }
  }, [isMuted])

  return (
    <div className={s.videoContainer}>
      <video
        ref={videoRef}
        src={`https://english.yanadoocdn.com/upload/yanadoo/new/aitalk/video/${name.toLowerCase()}.mp4`} // name에 따른 비디오 선택, 기본값 EMILY_ROSE
        controls={false}
        playsInline
        autoPlay
        muted={isMuted}
        width="100%"
        style={{ position: 'absolute', top: 0, left: 0, right: 0 }}
        poster={poster}
      />
      <button className={cx(s.btnIsMuted, { [s.soundOn]: !isMuted })} onClick={handleUnmute}>
        {isMuted ? 'Sound ON' : 'Sound OFF'}
      </button>
    </div>
  )
}
