export default function ShareBand({ meta, pathname, className }) {
  const shareNaverBand = () => {
    if (meta) {
      let url =
        'http://www.band.us/plugin/share?body=' +
        encodeURIComponent(meta.title + '\n') +
        (pathname ? 'https://www.yanadoo.co.kr' + pathname : window.location.href)

      window.open(url, 'shareBand', 'width=400, height=500, resizable=yes')
    }
  }

  return (
    <button className={className} onClick={shareNaverBand}>
      BAND로 공유하기
    </button>
  )
}
