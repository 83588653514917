import { YANADOO_CDN_HOST } from 'common/config'
export const APPLE_PACKAGE_HOST_CDN = `${YANADOO_CDN_HOST}/promotion/applePackage`

export const reviewListValues = [
  {
    id: 0,
    pc: `${APPLE_PACKAGE_HOST_CDN}/2022/nov/img_escape01.png`,
    mo: `${APPLE_PACKAGE_HOST_CDN}/2022/nov/img_m_escape01.png`
  },
  {
    id: 1,
    pc: `${APPLE_PACKAGE_HOST_CDN}/2022/nov/img_escape02.png`,
    mo: `${APPLE_PACKAGE_HOST_CDN}/2022/nov/img_m_escape02.png`
  },
  {
    id: 2,
    pc: `${APPLE_PACKAGE_HOST_CDN}/2022/nov/img_escape03.png`,
    mo: `${APPLE_PACKAGE_HOST_CDN}/2022/nov/img_m_escape03.png`
  },
  {
    id: 3,
    pc: `${APPLE_PACKAGE_HOST_CDN}/2022/nov/img_escape04.png`,
    mo: `${APPLE_PACKAGE_HOST_CDN}/2022/nov/img_m_escape04.png`
  },
  {
    id: 4,
    pc: `${APPLE_PACKAGE_HOST_CDN}/2022/nov/img_escape05.png`,
    mo: `${APPLE_PACKAGE_HOST_CDN}/2022/nov/img_m_escape05.png`
  },
  {
    id: 5,
    pc: `${APPLE_PACKAGE_HOST_CDN}/2022/nov/img_escape06.png`,
    mo: `${APPLE_PACKAGE_HOST_CDN}/2022/nov/img_m_escape06.png`
  },
  {
    id: 6,
    pc: `${APPLE_PACKAGE_HOST_CDN}/2022/nov/img_escape07.png`,
    mo: `${APPLE_PACKAGE_HOST_CDN}/2022/nov/img_m_escape07.png`
  }
]

export const metaInfoValues = {
  title: '영어공부는 풀패키지로 완벽하게! 야나두 Apple 풀패키지',
  description: '170만 영어 성공 노하우로 만들어진 야나두영어',
  site_name: 'Apple 풀패키지로 더 완벽하게 실력을 업그레이드 하세요!',
  imgsrc: 'https://english.yanadoocdn.com/upload/yanadoo/new/banner/img_applePackage_og.png'
}

export const reviewSlideValues = {
  title: '10분영어 4차 완판 신화!',
  description: '170만 회원님들의 성원에 힘입어 준비했어요',
  background: `url(${APPLE_PACKAGE_HOST_CDN}/2022/nov/bg_pc_escape.png) 50%/cover`
}

export const curriCulumListValue = [
  {
    headTitle: '기초',
    headTitleInfo: '기초~중급 수준까지의 패턴 학습 & 영어로 말하는 연습',
    headId: 'basic',
    headClass: 'lectureBasic',
    item: [
      {
        title: '입문회화',
        popupImg: `https://english.yanadoocdn.com/app/2022/03/english01_1647601394264.png`,
        lectureNumber: '20강',
        lectureInfo: '영어 어순의 탄생 배경을 이해하고 가장 쉬운 방법으로 영어 시작하기',
        lectureRecommend: '영어 이론부터 차근차근 하고 싶은 분',
        afterLecture: '영어의 기본 원리 이해할 수 있어요'
      },
      {
        title: '마법패턴',
        popupImg: `https://english.yanadoocdn.com/app/2022/03/english05_1647602750349.png`,
        lectureNumber: '10강',
        lectureInfo: '문장 구조의 비밀을 파헤쳐 마법처럼 무한한 문장 만들기',
        lectureRecommend: '패턴학습을 통해 다양한 문장만들기를 응용하고 싶은 분',
        afterLecture: '패턴으로 다양한 문장을 응용하여 만들 수 있어요'
      },
      {
        title: '명대사패턴',
        popupImg: `https://english.yanadoocdn.com/app/2022/03/english06_1647602856398.png`,
        lectureNumber: '10강',
        lectureInfo: '명대사에서 찾는 유용한 회화! 명대사 응용하여 세련되게 문장 만들기',
        lectureRecommend:
          '영화/드라마 명대사 패턴학습을 통해 뉘앙스를 살려 다양한 문장만들기를 응용하고 싶은 분',
        afterLecture: '패턴으로 다양한 문장을 응용하여 만들 수 있어요'
      },
      {
        title: '앞자리 학습법',
        popupImg: `${YANADOO_CDN_HOST}/promotion/frontEnglish/2023/oct/img_front_teacher_1.png`,
        lectureNumber: '43강',
        lectureInfo: '핵심은 주어와 동사! 모든 영어가 쉬워지는 영어 문장을 만드는 핵심 원리',
        lectureRecommend: '모든 영어 공부 능률을 올리고 싶은 분',
        afterLecture: '짧은 영어 문장을 빠르게 말하고 작문할 수 있어요'
      },
      {
        title: '뒷자리 학습법',
        popupImg: `${YANADOO_CDN_HOST}/promotion/frontEnglish/2023/oct/img_front_teacher_2.png`,
        lectureNumber: '28강',
        lectureInfo: '문장의 완성은 뒷자리! 긴문장 만드는 법까지 익혀 문장 확장력 증가',
        lectureRecommend: '말하고 싶은 문장을 무한대로 만들고 싶은 분',
        afterLecture: '긴 영어 문장도 손쉽게 만들 수 있어요'
      },
      {
        title: '리얼스피킹',
        popupImg: `https://english.yanadoocdn.com/app/2022/03/english07_1647603032666.png`,
        lectureNumber: '100강',
        lectureInfo: '다양한 국적의 친구들과의 대화를 통해 실전 영어 감각 익히기',
        lectureRecommend:
          '다양한 나라에서 온 원어민의 발음과 억양을 익히고 리얼한 영어 표현을 배우고 싶은 분',
        afterLecture: '각 나라의 문화 및 원어민의 실전 발음을 익힐 수 있어요'
      },
      {
        title: '1mm스피킹',
        popupImg: `https://english.yanadoocdn.com/app/2022/03/english08_1647603324799.png`,
        lectureNumber: '23강',
        lectureInfo: '1mm 더 가까이! 한 끗 차이로 달라지는 입문회화',
        lectureRecommend:
          '왕초보의 답답함 해결을 원하는 분, 매번 중도포기 하는 왕초보에게 수준에 맞는 영어 트레이닝을 원하는 분',
        afterLecture: '머리 속에서만 맴돌던 영어 문장이 생각과 동시에 입 밖으로 나올 수 있어요'
      },
      {
        title: '1분원샷영어',
        popupImg: `https://english.yanadoocdn.com/app/2022/03/english09_1647603505336.png`,
        lectureNumber: '200강',
        lectureInfo: '현지에서 바로 쓰는 상황 별 원어민 1,000개 표현 완벽 마스터',
        lectureRecommend: '짧은 강의로 상황에 맞는 원어민식 표현을 배우고 싶은 분',
        afterLecture: '각 상황별로 원어민식 표현을 습득할 수 있어요'
      }
    ]
  },
  {
    headTitle: '특화',
    headTitleInfo: '어휘 확장 및 장문으로 말하기 & 발음 교정',
    headId: 'special',
    headClass: 'lectureSpecial',
    item: [
      {
        title: '5분 발음교정',
        popupImg: `https://english.yanadoocdn.com/app/2022/03/english10_1647603785099.png`,
        lectureNumber: '20강',
        lectureInfo: '한국인이 가장 힘들어하는 발음 20가지 5분안에 교정',
        lectureRecommend: '영어 발음의 필수 요소를 입으로 훈련하며 교정하고 자신감을 얻고 싶은 분',
        afterLecture:
          '기초 발음 뿐 아니라 강세, 연음, 억양 트레이닝을 통해 좀 더 원어민스러운 발음을 구사할 수 있어요'
      },
      {
        title: '리얼영단어',
        popupImg: `https://english.yanadoocdn.com/app/2022/03/english11_1647603974727.png`,
        lectureNumber: '141강',
        lectureInfo: '예문으로 정복하는 실생활 3,000단어의 진짜 뉘앙스 찾기',
        lectureRecommend: '두 단어의 뉘앙스 차이와 실제 쓰임새를 자연스럽게 체득하고 싶은 분',
        afterLecture: '3,000개 단어의 뉘앙스를 예문으로 습득할 수 있어요'
      },
      {
        title: '리얼영작문',
        popupImg: `https://english.yanadoocdn.com/app/2022/03/english12_1647604142837.png`,
        lectureNumber: '36강',
        lectureInfo: '6법칙 30패턴으로 리얼영작스킬 마스터',
        lectureRecommend: '동사를 통해 문장의 뼈대를 잡고 영작문 실력을 향상시키고 싶은 분',
        afterLecture: '30개의 패턴으로 6가지 영작문 법칙 습득할 수 있어요'
      },
      {
        title: '써먹는 보카 입문',
        popupImg: `https://english.yanadoocdn.com/app/2022/03/english13_1647604284670.png`,
        lectureNumber: '31강',
        lectureInfo: '웹툰으로 배우는 일상 속 자주 들리는 영단어 25개 깊이 학습',
        lectureRecommend: '시작이 겁나는 왕초보를 위한, 영단어 공부 시작을 쉽게 하고 싶은 분',
        afterLecture: '아는 단어의 반전 의미로 어휘 활용의 폭이 넓어져요'
      },
      {
        title: '써먹는 보카 초급',
        popupImg: `https://english.yanadoocdn.com/app/2022/03/english14_1647604388327.png`,
        lectureNumber: '36강',
        lectureInfo: '원어민이 자주 쓰는 기초 영단어 30개 깊이 학습',
        lectureRecommend: '영어를 잘하려고 단어암기를 했지만 실력이 제자리 걸음인 분',
        afterLecture: '한 가지 단어로도 의미를 확장하여 활용할 수 있어요'
      },
      {
        title: '90일 패턴쉐도잉',
        popupImg: `https://english.yanadoocdn.com/app/2022/03/english15_1647605345491.png`,
        lectureNumber: '90강',
        lectureInfo: '말하기를 위한 패턴 반복 훈련 : 자기소개&질문하기 / 감정표현 / 일상표현',
        lectureRecommend: '머리 속으로 문장은 만들어지나 입 밖으로 못 내 보내는 분',
        afterLecture:
          '문맥 속 학습을 통한 영어 언어의 구조 습득, 패턴 확장을 통한 다양한 문장을 구사할 수 있어요'
      }
    ]
  },
  {
    headTitle: '실전',
    headTitleInfo: '원어민 표현과 습득 & 현지 체험으로 실전 감각 향상',
    headId: 'actual',
    headClass: 'lectureActual',
    item: [
      {
        title: '리얼대화영어 입문',
        popupImg: `https://english.yanadoocdn.com/app/2022/03/english16_1647605486170.png`,
        lectureNumber: '38강',
        lectureInfo: '365일 매일 마주하는 90가지 주제 짧은 대화 도전',
        lectureRecommend: '외국인 앞에서면 말문이 막히고 영어로 간단한 일상대화가 어려우신 분',
        afterLecture:
          '데일리 루틴에 기반한 시뮬레이션을 통해 상황별 적절한 일상 표현을 활용해 대화할 수 있어요'
      },
      {
        title: '리얼대화영어 초급',
        popupImg: `https://english.yanadoocdn.com/app/2022/03/english17_1647605550263.png`,
        lectureNumber: '31강',
        lectureInfo: '오드리와 1:1 대화 시뮬레이션을 통해 영어 말하기 트레이닝',
        lectureRecommend: '영어 회화를 실제 맥락을 통해 실감나게 공부하고 싶은 분',
        afterLecture: '다양한 영어 표현을 실제 입을 통해 말할 수 있어요'
      },
      {
        title: '이디엄이 뭔대영',
        popupImg: `https://english.yanadoocdn.com/app/2022/03/english18_1647605708302.png`,
        lectureNumber: '21강',
        lectureInfo: '원어민이 일상에서 많이 쓰는 60가지 이디엄',
        lectureRecommend:
          '재미있는 상황극을 통해 원어민들이 데일리로 활용하는 IDIOM을 배우고 싶은 분',
        afterLecture: '원어민과 의사소통시 못 알아 듣던 이디엄을 이해하고 자유자재로 말할 수 있어요'
      },
      {
        title: '콩글리시 타파하기',
        popupImg: `https://english.yanadoocdn.com/app/2022/03/english22_1647606134152.png`,
        lectureNumber: '15강',
        lectureInfo: '잘못된 한국식 영어 바로잡고 영어와 친숙해지기',
        lectureRecommend: '콩글리시, 영어적 실수로 인한 창피를 모면하고 싶은 분',
        afterLecture: '영어에서 흔히 하는 실수를 교정할 수 있어요'
      },
      {
        title: '리얼 밀당발음',
        popupImg: `https://english.yanadoocdn.com/app/2022/03/english23_1647606188880.png`,
        lectureNumber: '20강',
        lectureInfo: '영어는 강약 밀당이다! 원어민 느낌나는 발음법',
        lectureRecommend: '연음때문에 영어가 들리지 않는 분',
        afterLecture: '리스닝 실력 향상되고 원어민 느낌나는 발음 구사할 수 있어요'
      },
      {
        title: '리얼 리듬영어',
        popupImg: `https://english.yanadoocdn.com/app/2022/03/english24_1647606231060.png`,
        lectureNumber: '20강',
        lectureInfo: '영어는 두둠칫 리듬이다! 원어민 느낌나게 리듬타기',
        lectureRecommend: '원어민 느낌나게 리듬타며 말하고 싶으신 분',
        afterLecture: '긴 문장도 쉽게 말할 수 있어요'
      },
      {
        title: '진짜 날라리영어',
        popupImg: `https://english.yanadoocdn.com/app/2022/03/english25_1647606298104.png`,
        lectureNumber: '40강',
        lectureInfo: '교과서엔 절대 없는 미국 인싸화법 그대로 배우기',
        lectureRecommend: '원어민과 대화 시 번역하려는 버릇으로 의미 이해가 어려우신 분',
        afterLecture: '원어민 표현에서 숨겨진 뉘앙스를 캐치할 수 있어요'
      },
      {
        title: '리얼 몰입 영어',
        popupImg: `https://english.yanadoocdn.com/app/2022/03/english26_1647606357434.png`,
        lectureNumber: '20강',
        lectureInfo: '해외 인기 영상을 즐기면서 리얼 표현 습득하기',
        lectureRecommend: '편하게 이야기를 들으며 영어를 배우고 싶은 분',
        afterLecture: '댜양한 상황별로 원어민 느낌나게 표현할 수 있어요'
      },
      {
        title: '리얼 데일리영어',
        popupImg: `https://english.yanadoocdn.com/app/2022/03/english27_1647606407944.png`,
        lectureNumber: '40강',
        lectureInfo: '원어민 영상으로 트렌디한 표현 배우기',
        lectureRecommend: '원어민들이 사용하는 일상표현 영어를 다양하게 활용하고 싶은 분',
        afterLecture: '영어로 자연스러운 일상 대화가 가능해요'
      }
    ]
  }
]

export const speakingPreviewValues = {
  system: [
    {
      pc: `${APPLE_PACKAGE_HOST_CDN}/2022/nov/img_pc_scholarship01.png`,
      alt: '장학금 시스템 1',
      mo: `${APPLE_PACKAGE_HOST_CDN}/2022/nov/img_m_scholarship01.png`
    },
    {
      pc: `${APPLE_PACKAGE_HOST_CDN}/2022/nov/img_pc_scholarship02.png`,
      alt: '장학금 시스템 2',
      mo: `${APPLE_PACKAGE_HOST_CDN}/2022/nov/img_m_scholarship02.png`
    },
    {
      pc: `${APPLE_PACKAGE_HOST_CDN}/2022/nov/img_pc_scholarship03.png`,
      alt: '장학금 시스템 3',
      mo: `${APPLE_PACKAGE_HOST_CDN}/2022/nov/img_m_scholarship03.png`
    },
    {
      pc: `${APPLE_PACKAGE_HOST_CDN}/2022/nov/img_pc_scholarship04.png`,
      alt: '장학금 시스템 4',
      mo: `${APPLE_PACKAGE_HOST_CDN}/2022/nov/img_m_scholarship04.png`
    },
    {
      pc: `${APPLE_PACKAGE_HOST_CDN}/2022/nov/img_pc_scholarship05.png`,
      alt: '장학금 시스템 5',
      mo: `${APPLE_PACKAGE_HOST_CDN}/2022/nov/img_m_scholarship05.png`
    }
  ],
  voice: [
    {
      pc: `${APPLE_PACKAGE_HOST_CDN}/2022/nov/img_pc_voicecare01.png`,
      alt: '보이스케어 1',
      mo: `${APPLE_PACKAGE_HOST_CDN}/2022/nov/img_m_voicecare01.png`
    },
    {
      pc: `${APPLE_PACKAGE_HOST_CDN}/2022/nov/img_pc_voicecare02.png`,
      alt: '보이스케어 2',
      mo: `${APPLE_PACKAGE_HOST_CDN}/2022/nov/img_m_voicecare02.png`
    },
    {
      pc: `${APPLE_PACKAGE_HOST_CDN}/2022/nov/img_pc_voicecare03.png`,
      alt: '보이스케어 3',
      mo: `${APPLE_PACKAGE_HOST_CDN}/2022/nov/img_m_voicecare03.png`
    },
    {
      pc: `${APPLE_PACKAGE_HOST_CDN}/2022/nov/img_pc_voicecare04.png`,
      alt: '보이스케어 4',
      mo: `${APPLE_PACKAGE_HOST_CDN}/2022/nov/img_m_voicecare04.png`
    }
  ]
}

export const dbModalValues = {
  form: {
    eventName: 'applePackage1',
    formName: 'applePackageForm',
    btnText: 'Apple 풀패키지 신청하기',
    btnColor: '#fff',
    banner: {
      src: `${APPLE_PACKAGE_HOST_CDN}/2024/bnr_pc_popup_applepackage.png`,
      width: 550,
      height: 430
    }
  },
  button: {
    buttonColor: '#fff',
    buttonBackground: `url(${APPLE_PACKAGE_HOST_CDN}/2022/nov/btn_pc_pop_ynd_content.png) cover`
  }
}
