import { useRef, useCallback, useEffect } from 'react'

export default function useScrollLock() {
  const scrollY = useRef(0)

  const lock = useCallback(() => {
    scrollY.current = window.scrollY
    document.body.style.cssText = `
      overflow: hidden;
      position: fixed;
      top: -${scrollY.current}px;
      width: 100%;
    `
  }, [])

  const unlock = useCallback(() => {
    document.body.style.cssText = ''
    window.scrollTo(0, scrollY.current)
    scrollY.current = 0
  }, [])

  useEffect(() => () => unlock(), [unlock])

  return { lock, unlock }
}
