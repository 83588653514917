import { useState } from 'react'

/**
 * 아코디언 기능을 관리하기 위한 커스텀 React 훅
 *
 * @param {number|null} preOpenIdx - 초기에 열린 항목의 인덱스 (기본 값: null)
 * @param {boolean} isToggle - 항목을 열었을 때 토글 기능을 사용할지 여부 (기본값: true)
 * @returns {number, function} - 현재 열린 항목의 인덱스와 아코디언 클릭 핸들러 함수를 반환
 *
 * @example
 * 사용 예시:
 * 초기에 첫 번째 항목을 열기 위해 0을 전달
 * 열린 항목에 대한 토글 기능 사용
 * const {currentIndex, handleAccordionClick} = useAccordion(0, false);
 *
 * JSX:
 *  <ul>
    {array.map((value, idx) => (
      <li
        key={}
        className={idx === currentIndex ? styles.active : ''}
        onClick={handleAccordionClick}
      >
        ...
      </li>
    ))}
  </ul>
 */
export default function useAccordion(preOpenIdx = null, isToggle = true) {
  const [currentIndex, setCurrentIndex] = useState(preOpenIdx)
  const handleAccordionClick = (e) => {
    const siblings = e.currentTarget.parentNode.children
    const thisIndex = [...siblings].indexOf(e.currentTarget)
    if (thisIndex === currentIndex) {
      if (!isToggle) return
      setCurrentIndex(null)
    } else {
      setCurrentIndex(thisIndex)
    }
  }

  return { currentIndex, handleAccordionClick }
}
