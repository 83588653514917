import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

import { skipMode } from './useLevelTestAdvancedExamStore'

//? Skip mode dummy data
const dummyDatas = {
  isInitial: false,
  selfCheck: {
    reason: '중도 포기 할까 봐 두려워요',
    method: '까먹지 않게 평생 공부하고 싶어요',
    goal: '기초 이론 및 문법 마스터를'
  },
  test: [
    {
      answer: 'wear',
      isCorrect: false,
      score: 2,
      category: 'vocabulary'
    },
    {
      answer: 'rest',
      isCorrect: true,
      score: 3,
      category: 'vocabulary'
    },
    {
      answer: 'put on',
      isCorrect: true,
      score: 3,
      category: 'vocabulary'
    },
    {
      answer: 'ran into',
      isCorrect: true,
      score: 8,
      category: 'vocabulary'
    },
    {
      answer: ['What', 'does', 'she', 'do?'],
      isCorrect: true,
      score: 2,
      category: 'grammar'
    },
    {
      answer: 'should',
      isCorrect: false,
      score: 1,
      category: 'grammar'
    },
    {
      answer: 'It says in the email.',
      isCorrect: false,
      score: 3,
      category: 'grammar'
    },
    {
      answer: 'old friend of mine',
      isCorrect: true,
      score: 5,
      category: 'grammar'
    },
    {
      answer: 'I will fix it.',
      isCorrect: false,
      score: 6,
      category: 'grammar'
    },
    {
      answer: '대신 말해 줄게.',
      isCorrect: false,
      score: 4,
      category: 'nuance'
    },
    {
      answer: '어쩐지 그런 거 같더라!',
      isCorrect: true,
      score: 4,
      category: 'nuance'
    },
    {
      answer: 'Not really.',
      isCorrect: false,
      score: 3,
      category: 'nuance'
    },
    {
      answer: 'Don’t worry.',
      isCorrect: false,
      score: 5,
      category: 'nuance'
    },
    {
      answer: 'Mr. Brown',
      isCorrect: true,
      score: 3,
      category: 'listening'
    },
    {
      answer: '커피 한 잔이랑 피넛버터 토스트',
      isCorrect: true,
      score: 8,
      category: 'listening'
    },
    {
      answer: 'ripe fruit',
      isCorrect: true,
      score: 10,
      category: 'listening'
    },
    {
      answer: 'No thanks.',
      isCorrect: false,
      score: 3,
      category: 'speaking'
    },
    {
      answer: 'I can’t get there today',
      isCorrect: false,
      score: 5,
      category: 'speaking'
    },
    {
      answer: 'Take your time.',
      isCorrect: true,
      score: 7,
      category: 'speaking'
    },
    {
      answer: ['Your', 'voice', 'is', 'breaking up.'],
      isCorrect: true,
      score: 15,
      category: 'speaking'
    }
  ]
}

const initialState = {
  isInitial: true,
  selfCheck: {
    reason: '',
    method: '',
    goal: ''
  },
  test: Array(20)
    .fill(1)
    .map(() => ({
      answer: null,
      isCorrect: false,
      score: 0,
      category: ''
    }))
}
//? Skip mode
const data = skipMode ? dummyDatas : initialState

const useLevelTestAdvancedStore = create(
  devtools(
    immer((set) => ({
      //? Skip mode
      ...data,
      onChangeAnswer: (index, value) =>
        set(
          (state) => {
            state.test[index] = value
          },
          false,
          'levelTestAdvanced/onChangeAnswer'
        ),
      onChangeSelfCheck: (key, value) =>
        set(
          (state) => {
            state.selfCheck = { ...state.selfCheck, [key]: value }
          },
          false,
          'levelTestAdvanced/onChangeSelfCheck'
        ),
      onChangeIsInitial: (is) =>
        set(
          (state) => {
            state.isInitial = is
          },
          false,
          'levelTestAdvanced/onChangeIsInitial'
        ),
      resetState: () =>
        set(
          (state) => {
            state.isInitial = true
            state.test = initialState.test
            state.selfCheck = initialState.selfCheck
          },
          false,
          'levelTestAdvanced/resetState'
        )
    })),
    { name: 'levelTestAdvancedStore', enabled: process.env.NODE_ENV === 'development' }
  )
)

export default useLevelTestAdvancedStore
