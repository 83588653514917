export const CHAT_COMPONENTS_CONFIG = {
  svg: {
    back: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M11 20L3 12L11 4"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M4 12H20" stroke="black" strokeWidth="2" strokeLinecap="round" />
      </svg>
    ),
    close: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M3 3L21 21M21 3L3 21"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    share: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <circle cx="18.5984" cy="5.7" r="2.7" stroke="black" strokeWidth="2" />
        <circle cx="5.39922" cy="12.3016" r="2.7" stroke="black" strokeWidth="2" />
        <circle cx="18.5984" cy="18.3016" r="2.7" stroke="black" strokeWidth="2" />
        <path d="M16.1445 6.41406L8.3007 11.1711" stroke="black" strokeWidth="2" />
        <path d="M7.92329 13.5653L15.7783 17.9378" stroke="black" strokeWidth="2" />
      </svg>
    ),
    dot: (
      <svg xmlns="http://www.w3.org/2000/svg" width="2" height="2" viewBox="0 0 2 2" fill="none">
        <circle cx="1" cy="1" r="1" fill="black" />
      </svg>
    ),
    speaker: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
      >
        <path
          d="M26.875 15.625C26.875 15.625 29.375 16.875 29.375 20C29.375 23.125 26.875 24.375 26.875 24.375M29.375 8.125C34.375 10.625 36.875 14.375 36.875 20C36.875 25.625 34.375 29.375 29.375 31.875M4.375 14.375V25.625H10.625L20.625 33.125V6.875L10.625 14.375H4.375Z"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    mute: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
      >
        <path
          d="M4.375 14.375V25.625H10.625L20.625 33.125V6.875L10.625 14.375H4.375Z"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M37 16L27 26" stroke="black" strokeWidth="3" strokeLinecap="round" />
        <path d="M27 16L37 26" stroke="black" strokeWidth="3" strokeLinecap="round" />
      </svg>
    ),
    mic: (
      <svg
        width="168"
        height="168"
        viewBox="0 0 168 168"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_4092_1217)">
          <path
            d="M149 84C149 119.899 119.899 149 84 149C48.1015 149 19 119.899 19 84C19 48.1015 48.1015 19 84 19C119.899 19 149 48.1015 149 84Z"
            fill="url(#paint0_linear_4092_1217)"
          />
        </g>
        <path
          d="M84 54C77.7186 54 72.6 59.1354 72.6 65.4375V84.5C72.6 90.8021 77.7186 95.9375 84 95.9375C90.2814 95.9375 95.4 90.8021 95.4 84.5V65.4375C95.4 59.1354 90.2814 54 84 54ZM65 85.0795C65 94.6603 72.543 102.381 82.1 103.319V111.188H74.5V115H93.5V111.188H85.9V103.319C95.457 102.381 103 94.6603 103 85.0795H99.2C99.2 93.1696 92.493 99.689 84 99.689C75.507 99.689 68.8 93.1696 68.8 85.0795H65Z"
          fill="white"
        />
        <defs>
          <filter
            id="filter0_d_4092_1217"
            x="6"
            y="10"
            width="156"
            height="156"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="6.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 1 0 0 0 0 0.359821 0 0 0 0 0.359821 0 0 0 0.25 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4092_1217" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_4092_1217"
              result="shape"
            />
          </filter>
          <linearGradient
            id="paint0_linear_4092_1217"
            x1="19"
            y1="33.8246"
            x2="126.193"
            y2="133.035"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.280578" stopColor="#FC7B6F" />
            <stop offset="1" stopColor="#FF4F3F" />
          </linearGradient>
        </defs>
      </svg>
    ),
    micbg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="108"
        height="108"
        viewBox="0 0 108 108"
        fill="none"
      >
        <g filter="url(#filter0_d_2490_31526)">
          <path
            d="M95 50C95 72.6437 76.6437 91 54 91C31.3563 91 13 72.6437 13 50C13 27.3563 31.3563 9 54 9C76.6437 9 95 27.3563 95 50Z"
            fill="url(#paint0_linear_2490_31526)"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_2490_31526"
            x="0"
            y="0"
            width="108"
            height="108"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="6.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 1 0 0 0 0 0.359821 0 0 0 0 0.359821 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_2490_31526"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_2490_31526"
              result="shape"
            />
          </filter>
          <linearGradient
            id="paint0_linear_2490_31526"
            x1="13"
            y1="18.3509"
            x2="80.614"
            y2="80.9298"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.280578" stopColor="#FC7B6F" />
            <stop offset="1" stopColor="#FF4F3F" />
          </linearGradient>
        </defs>
      </svg>
    ),
    send: (
      <svg
        width="168"
        height="168"
        viewBox="0 0 168 168"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_4092_1117)">
          <path
            d="M149 84C149 119.899 119.899 149 84 149C48.1015 149 19 119.899 19 84C19 48.1015 48.1015 19 84 19C119.899 19 149 48.1015 149 84Z"
            fill="url(#paint0_linear_4092_1117)"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M88.0244 77.8155L75.6544 86.9778C74.7683 87.634 73.7226 88.0408 72.6261 88.1559C71.5295 88.271 70.4222 88.0902 69.4192 87.6323L52.5441 79.9272C50.9962 79.2193 50.2882 77.4053 50.9624 75.8717C51.1291 75.4924 51.3734 75.1521 51.6797 74.873C51.9859 74.5938 52.3473 74.3819 52.7404 74.2509L108.457 55.6196C110.046 55.0884 111.795 55.9556 112.359 57.5579C112.589 58.2025 112.595 58.9028 112.38 59.5431L93.7479 115.258C93.6199 115.636 93.4177 115.984 93.1532 116.283C92.8886 116.582 92.567 116.825 92.2073 116.997C91.8476 117.17 91.457 117.269 91.0584 117.289C90.6599 117.309 90.2614 117.249 89.8864 117.112C89.4885 116.972 89.1229 116.752 88.8114 116.467C88.5 116.183 88.249 115.838 88.0735 115.454L80.3678 98.5802C79.9099 97.5773 79.7291 96.47 79.8442 95.3735C79.9593 94.2771 80.3661 93.2314 81.0223 92.3455L90.1853 79.9762C90.4083 79.6792 90.5166 79.3117 90.4903 78.9412C90.464 78.5707 90.305 78.2221 90.0423 77.9595C89.7797 77.6969 89.4311 77.5378 89.0606 77.5116C88.6901 77.4853 88.3214 77.5925 88.0244 77.8155Z"
          fill="white"
        />
        <defs>
          <filter
            id="filter0_d_4092_1117"
            x="6"
            y="10"
            width="156"
            height="156"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="6.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.14902 0 0 0 0 0.611765 0 0 0 0 1 0 0 0 0.25 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4092_1117" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_4092_1117"
              result="shape"
            />
          </filter>
          <linearGradient
            id="paint0_linear_4092_1117"
            x1="44.473"
            y1="34.8108"
            x2="125.284"
            y2="149"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#7BBCF2" />
            <stop offset="1" stopColor="#269CFF" />
          </linearGradient>
        </defs>
      </svg>
    ),
    tooltip: {
      recorder: (
        <svg
          width="375"
          height="79"
          viewBox="0 0 375 79"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="55" y="7" width="265" height="42" rx="21" fill="#FFEAEA" />
          <path
            d="M187.648 57.3718C187.264 58.0434 186.296 58.0434 185.912 57.3718L181.033 48.8361L192.527 48.8361L187.648 57.3718Z"
            fill="#FFEAEA"
          />
          <path
            d="M298.29 33.3822C297.504 33.3822 296.914 32.7474 296.914 31.9313C296.914 31.1152 297.504 30.4805 298.29 30.4805C299.091 30.4805 299.681 31.1152 299.681 31.9313C299.681 32.7474 299.091 33.3822 298.29 33.3822Z"
            fill="#FF5647"
          />
          <path
            d="M287.328 28.5754V31.2353H290.397V28.5754C289.913 28.6661 289.399 28.7114 288.855 28.7114C288.311 28.7114 287.812 28.6661 287.328 28.5754ZM288.855 22.8022C286.92 22.8022 285.635 23.5881 285.635 24.9785C285.635 26.3538 286.92 27.1548 288.855 27.1548C290.79 27.1548 292.09 26.3538 292.09 24.9785C292.09 23.5881 290.79 22.8022 288.855 22.8022ZM292.378 31.2353H295.174V32.8524H282.566V31.2353H285.348V27.8348C284.29 27.185 283.655 26.1875 283.67 24.9785C283.67 22.6964 285.862 21.2305 288.855 21.2305C291.864 21.2305 294.056 22.6964 294.056 24.9785C294.056 26.1875 293.421 27.185 292.378 27.8348V31.2353Z"
            fill="#FF5647"
          />
          <path
            d="M273.159 21.7715V24.0385C273.159 26.2903 273.763 28.6026 275.653 29.857L274.564 31.3985C273.385 30.6731 272.645 29.4943 272.206 28.0888C271.722 29.6303 270.906 30.9602 269.651 31.7159L268.457 30.2197C270.498 28.9804 271.239 26.4868 271.239 24.1141V21.7715H273.159ZM275.94 25.2626V20.7892H277.8V33.8619H275.94V26.8797H274.202V25.2626H275.94ZM278.767 20.5625H280.657V34.4816H278.767V20.5625Z"
            fill="#FF5647"
          />
          <path
            d="M258.084 25.1548V27.1044H264.04V25.1548H258.084ZM262.06 31.2453H267.381V32.8624H254.773V31.2453H260.065V28.6912H256.104V21.4219H258.084V23.583H264.04V21.4219H266.006V28.6912H262.06V31.2453Z"
            fill="#FF5647"
          />
          <path
            d="M244.212 29.8262C244.967 29.8262 245.512 29.2217 245.512 28.1789C245.512 27.1361 244.967 26.5165 244.212 26.5165C243.456 26.5165 242.911 27.1361 242.911 28.1789C242.911 29.2217 243.456 29.8262 244.212 29.8262ZM244.212 24.8541C245.995 24.8541 247.265 26.2294 247.265 28.1789C247.265 30.1134 245.995 31.4887 244.212 31.4887C242.428 31.4887 241.158 30.1134 241.158 28.1789C241.158 26.2294 242.428 24.8541 244.212 24.8541ZM245.194 20.9398V22.6627H247.507V24.2496H240.855V22.6627H243.229V20.9398H245.194ZM250.924 20.5469H252.813V34.4659H250.924V27.8313H249.865V33.8916H248.006V20.8189H249.865V26.2294H250.924V20.5469Z"
            fill="#FF5647"
          />
          <path
            d="M232.023 25.4289V22.769H229.302V25.4289H232.023ZM233.988 21.2124V27.0006H227.352V21.2124H233.988ZM230.708 31.867V32.7738H238.297V34.3153H228.727V30.4313H235.938V29.6001H228.712V28.0585H237.904V31.867H230.708ZM237.904 23.2375H239.733V24.8697H237.904V27.5145H235.908V20.5625H237.904V23.2375Z"
            fill="#FF5647"
          />
          <path
            d="M213.43 29.66C214.458 29.66 215.138 28.5568 215.138 26.4863C215.138 24.4309 214.458 23.3277 213.43 23.3277C212.417 23.3277 211.737 24.4309 211.737 26.4863C211.737 28.5568 212.417 29.66 213.43 29.66ZM219.311 20.5469H221.306V34.5113H219.311V27.1815H216.998C216.786 29.8414 215.365 31.4736 213.43 31.4736C211.359 31.4736 209.832 29.5542 209.832 26.4863C209.832 23.4335 211.359 21.5292 213.43 21.5292C215.305 21.5292 216.71 23.0556 216.983 25.5644H219.311V20.5469Z"
            fill="#FF5647"
          />
          <path
            d="M198.288 22.1035V29.3729C199.377 29.3426 200.571 29.252 201.902 29.0101L202.068 30.6877C200.254 31.0202 198.651 31.0957 197.26 31.0957H196.293V22.1035H198.288ZM205.772 20.5469H207.676V34.4659H205.772V27.5291H204.517V33.8161H202.657V20.8038H204.517V25.912H205.772V20.5469Z"
            fill="#FF5647"
          />
          <path
            d="M184.583 27.121V29.6298C186.7 29.6147 188.453 29.5089 190.419 29.1764L190.63 30.7935C188.363 31.1864 186.322 31.2771 183.797 31.2771H182.542V25.5493H187.063V23.3277H182.527V21.7408H189.103V27.121H184.583ZM191.386 20.5469H193.382V34.5113H191.386V20.5469Z"
            fill="#FF5647"
          />
          <path
            d="M180.103 26.9048L179.286 28.5521C176.837 28.1139 175.053 26.8141 174.146 25.0913C173.239 26.799 171.471 28.1139 169.037 28.5521L168.22 26.9048C171.365 26.391 173.088 24.26 173.088 22.3256V21.3281H175.22V22.3256C175.22 24.3054 176.928 26.391 180.103 26.9048ZM175.159 31.2271H180.526V32.8291H167.918V31.2271H173.164V28.1139H175.159V31.2271Z"
            fill="#FF5647"
          />
          <path
            d="M162.537 22.0128V23.5695H151.214V22.0128H155.9V20.5469H157.896V22.0128H162.537ZM159.604 26.5165C159.604 25.8666 158.712 25.5946 156.898 25.5946C155.084 25.5946 154.207 25.8666 154.207 26.5165C154.207 27.1361 155.084 27.4233 156.898 27.4233C158.712 27.4233 159.604 27.1361 159.604 26.5165ZM152.136 26.5165C152.136 24.975 153.92 24.1135 156.898 24.1135C159.876 24.1135 161.675 24.975 161.675 26.5165C161.675 28.0429 159.876 28.9044 156.898 28.9044C153.92 28.9044 152.136 28.0429 152.136 26.5165ZM163.217 29.5693V31.1713H157.896V34.4962H155.9V31.1713H150.609V29.5693H163.217Z"
            fill="#FF5647"
          />
          <path
            d="M142.327 23.3345V22.3824H134.769V20.8711H144.292V24.74H136.764V25.7072H144.565V27.2185H134.799V23.3345H142.327ZM136.809 30.4225V32.6441H144.61V34.231H134.814V30.4225H136.809ZM133.227 28.08H145.834V29.6669H133.227V28.08Z"
            fill="#FF5647"
          />
          <path
            d="M130.621 24.917V26.5039H120.9V21.1992H122.896V24.917H130.621ZM131.936 28.0756V29.6927H126.569V34.5138H124.574V29.6927H119.328V28.0756H131.936Z"
            fill="#FF5647"
          />
          <path
            d="M108.304 22.2316C106.294 22.2316 105.341 22.5187 105.341 23.1384C105.341 23.7882 106.294 24.0603 108.304 24.0603C110.33 24.0603 111.283 23.7882 111.283 23.1384C111.283 22.5187 110.33 22.2316 108.304 22.2316ZM108.304 25.5111C105.13 25.5111 103.27 24.6799 103.27 23.1384C103.27 21.612 105.13 20.7656 108.304 20.7656C111.494 20.7656 113.354 21.612 113.354 23.1384C113.354 24.6799 111.494 25.5111 108.304 25.5111ZM105.447 32.1004V32.8863H113.429V34.3824H103.467V30.7251H111.101V29.9846H103.452V28.5186H113.066V32.1004H105.447ZM102.016 26.1761H114.593V27.7327H102.016V26.1761Z"
            fill="#FF5647"
          />
          <path
            d="M99.364 25.7455V27.2417H89.6738V21H99.2733V22.5113H91.6542V23.3879H98.9407V24.8387H91.6542V25.7455H99.364ZM91.6088 30.3549V32.6521H99.4396V34.239H89.6133V30.3549H91.6088ZM88.1016 28.0729H100.709V29.6446H88.1016V28.0729Z"
            fill="#FF5647"
          />
          <path
            d="M79.4276 29.6449V26.5618H76.6913V29.6449H79.4276ZM83.8872 20.5469H85.8827V34.5113H83.8872V27.0455H81.3928V31.2318H74.7109V21.6501H76.6913V25.0052H79.4276V21.6501H81.3928V25.4435H83.8872V20.5469Z"
            fill="#FF5647"
          />
        </svg>
      )
    }
  },
  lottie: {
    loading: <></>
  }
}
