import { Link } from 'react-router-dom'

import ResponsiveImage from 'components/ResponsiveImage'

import { HOST_CDN, mainVideoValue, videoValues } from '../config'
import s from '../EnglishHome.module.css'

export default function ReviewSection() {
  const video = mainVideoValue
  return (
    <section className={s.review}>
      <div className={s.inner}>
        <hgroup>
          <h3 className={s.title}>
            <span>직접 확인하세요!</span>
            실제 수강생이
            <br className={s.disM} /> 증명하는 효과
          </h3>
        </hgroup>
        <div className={s.videoWrap}>
          <div className={s.mainVideo}>
            <Link to={video.link} target="_blank">
              <ResponsiveImage pc={video.pc} mo={video.mo} alt={video.alt} />
            </Link>
          </div>
          <ul className={s.videoList}>
            {videoValues.map(({ link, pc, mo, alt }, index) => {
              return (
                <li key={index}>
                  <Link to={link} target="_blank">
                    <ResponsiveImage pc={pc} mo={mo} alt={alt} maxWidth={1024} />
                  </Link>
                </li>
              )
            })}
          </ul>
        </div>
        <div className={s.more}>
          <h4 className={s.title}>
            <span>더 이상 망설이지 마세요!</span>
            중요한 건 결과,
            <br className={s.disM} /> 그래서 결국 야나두!
          </h4>
          <Link className={s.btnReview} to={'/courseReview?pageIdx=1&category=ALL'}>
            <ResponsiveImage pc={`${HOST_CDN}btn_review_more.png`} mo={`${HOST_CDN}btn_m_review_more.png`} alt={'리뷰 더보기'} maxWidth={1024} />
          </Link>
        </div>
      </div>
    </section>
  )
}
