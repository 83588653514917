import { useCallback, useEffect, useState } from 'react'

import '../../assets/styles/modal.scss'
import '../../assets/styles/coupon.scss'
import styled, { ThemeProvider } from 'styled-components'

import theme from 'common/theme'

import Toast from './Toast'
import icoImg from '../../assets/images/ico_no_coupon.png'
import icoNotice from '../../assets/images/ico_notice_line_30x30w.png'
import Coupon from '../coupon/Coupon'
import NoContent from '../NoContent'

const ModalCouponDuplicate = ({
  btnClose,
  handleCloseModal,
  couponListData,
  couponSelected,
  couponPriceLimit
}) => {
  const [selectedCoupon, setSelectedCoupon] = useState([]) // 선택된 쿠폰 리스트
  const [openToast, setOpenToast] = useState(false)
  const [totalPrice, setTotalPrice] = useState(0) // 토스트팝업에 넘겨주는 쿠폰 총 할인 금액

  const [flag, setFlag] = useState({
    disabled: false, // 중복 사용 가능 쿠폰 flag
    overlapDisabled: false, // 중복 사용 불가 쿠폰 flag
    shareDisabled: false, // 중복 사용 불가 쿠폰 flag
    priceLimitDisabled: false // 최대 할인 금액 flag
  })

  const handleToastStatus = useCallback((status) => {
    setOpenToast(status)
  }, [])

  const addComma = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  // 쿠폰 선택 시 발생하는 함수
  const handleSelectedCoupon = useCallback(
    (e) => {
      const { value, checked, name, id } = e.target

      if (checked) {
        // 중복 사용 불가 쿠폰인가
        if (name !== 'Y') {
          if (selectedCoupon.length > 0) {
            return false
          } else {
            setFlag({ ...flag, overlapDisabled: true })
          }
          // 중복 사용 가능 쿠폰인가
        } else {
          if (flag.overlapDisabled) {
            return false
          }
          setFlag({ ...flag, disabled: true })

          // 수익 배분 쿠폰 인가
          if (value === 'SHARE') {
            if (selectedCoupon.some((coupon) => coupon.couponKindCode === 'SHARE')) {
              return false
            }
            setFlag({ ...flag, shareDisabled: true })
          }
        }

        setSelectedCoupon([
          ...selectedCoupon,
          { overlapUseYn: name, number: id, couponKindCode: value }
        ])
      } else {
        // 중복 사용 불가 쿠폰인가
        if (name !== 'Y') {
          // 선택된 쿠폰 리스트에 중복 사용 가능 쿠폰이 있을 때
          if (selectedCoupon.some((coupon) => coupon.overlapUseYn === 'Y')) {
            return false
          }
          setFlag({ ...flag, overlapDisabled: false })
        } else {
          // 선택된 쿠폰 리스트에 클릭한 쿠폰이 있을 때
          if (selectedCoupon.some((coupon) => coupon.number === id)) {
            setFlag({ ...flag, disabled: false })
          }
          // 수익 배분 쿠폰인가
          if (value === 'SHARE') {
            setFlag({ ...flag, shareDisabled: false })
          }
        }

        setSelectedCoupon(selectedCoupon.filter((coupon) => String(coupon.number) !== id))
      }
    },
    [selectedCoupon]
  )

  // 총 할인금액 구하는 함수 (인자값: 배열)
  const calculation = (arr) => {
    let total = 0

    if (arr.length > 0) {
      arr.forEach((el) => {
        total += el
      })
    }
    setTotalPrice(total)
    return total
  }

  // 쿠폰 적용하기 클릭 시 발생하는 함수
  const applyCoupon = () => {
    const priceArray = []
    couponListData.forEach((coupon) => {
      selectedCoupon.forEach((selected) => {
        if (Number(selected.number) === coupon.couponSeq) {
          priceArray.push(coupon.discountPrice)
        }
      })
    })

    const couponSeqArray = selectedCoupon.map((coupon) => coupon.number)
    const priceSum = calculation(priceArray)

    if (
      selectedCoupon.some((coupon) => coupon.overlapUseYn === 'Y') &&
      priceSum > couponPriceLimit
    ) {
      setFlag({ ...flag, priceLimitDisabled: true })
    } else {
      couponSelected(couponSeqArray)
      setTimeout(() => {
        handleCloseModal()
      }, 1500)
    }

    handleToastStatus(true)
  }

  useEffect(() => {
    if (couponListData.filter((coupon) => coupon.couponChecked)) {
      const couponCheckedArray = couponListData.filter((coupon) => coupon.couponChecked)
      const data = []

      couponCheckedArray.forEach((coupon) => {
        data.push({
          overlapUseYn: coupon.overlapUseYn,
          number: String(coupon.couponSeq),
          couponKindCode: coupon.couponKindCode
        })
      })

      const checkedData = new Set(data)
      setSelectedCoupon([...checkedData])

      if (couponCheckedArray.some((coupon) => coupon.overlapUseYn !== 'Y')) {
        setFlag({ ...flag, overlapDisabled: true })
      }
      if (couponCheckedArray.some((coupon) => coupon.couponKindCode === 'SHARE')) {
        setFlag({ ...flag, shareDisabled: true })
      }
    }
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <div className="modal-wrap">
        <div className="dimmed" onClick={handleCloseModal}></div>
        <div className="modal-inner">
          {btnClose && (
            <button type="button" className="btn-modal-close" onClick={handleCloseModal}>
              <i className="blind">닫기</i>
            </button>
          )}
          <div className="modal-header">
            <strong>쿠폰 선택</strong>
          </div>
          <NoticeBox>
            {couponPriceLimit !== 0 ? (
              <p>
                중복쿠폰 사용은 최대 <span>{addComma(couponPriceLimit)}</span>원까지 가능합니다
              </p>
            ) : (
              <p>
                중복쿠폰 사용이 <span>불가능한</span> 상품입니다.
              </p>
            )}
          </NoticeBox>
          <div className="modal-content coupon type-fixed-button">
            <ul className="coupon-lists">
              {couponListData.length > 0 ? (
                couponListData.map((couponData, idx) => {
                  return (
                    couponData.couponDisplay && (
                      <Coupon
                        flag={flag}
                        key={idx}
                        type={'input'}
                        couponData={couponData}
                        selectedCoupon={selectedCoupon}
                        handleSelectedCoupon={handleSelectedCoupon}
                        couponPriceLimit={couponPriceLimit}
                      />
                    )
                  )
                })
              ) : (
                <NoContent icoImg={icoImg} titleMsg={'적용 가능한 쿠폰이 없습니다.'} />
              )}
            </ul>
            <div className="btn-flex-form">
              <button
                className={'btn' + (selectedCoupon.length > 0 ? ' positive' : ' disabled')}
                onClick={
                  couponListData.length !== 0
                    ? () => {
                        applyCoupon()
                      }
                    : undefined
                }
              >
                <span>쿠폰 적용하기</span>
              </button>
            </div>
          </div>
          <Toast
            type={'coupon'}
            subMsg={
              flag.priceLimitDisabled
                ? '중복 쿠폰 최대 사용 금액'
                : `${addComma(totalPrice)}원 할인`
            }
            msg={flag.priceLimitDisabled ? '을 초과하였습니다.' : '이 적용되었습니다.'}
            openToast={openToast}
            handleToastStatus={handleToastStatus}
          />
        </div>
      </div>
    </ThemeProvider>
  )
}

export default ModalCouponDuplicate

const NoticeBox = styled.div`
  padding: 1.7rem 2rem 1.5rem;
  background-color: #269cff;

  & + .modal-content {
    .coupon-lists {
      height: 56.8rem;
    }
  }

  p {
    position: relative;
    padding-left: 2.4rem;
    font-weight: 500;
    color: #fff;
    font-size: 1.6rem;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      width: 1.6rem;
      height: 1.6rem;
      margin: -0.9rem 0 0 0;
      background-image: url(${icoNotice});
      background-repeat: no-repeat;
      background-size: 100% auto;
    }

    span {
      /* color: #FFB900; */
    }
  }

  @media ${({ theme }) => theme.device.mobile} {
    padding: 2.9rem 3rem 2.9rem;

    & + .modal-content {
      .coupon-lists {
        height: 100%;
        padding: 3.5rem 3rem 26rem;
      }
    }

    p {
      padding-left: 4.2rem;
      font-weight: 700;
      font-size: 2.8rem;

      &::before {
        width: 3rem;
        height: 3rem;
        margin: -1.6rem 0 0 0;
      }
    }
  }
`
