import { useState, useEffect, useRef, useCallback } from 'react';
import { useMediaQuery } from 'react-responsive';

import '../../assets/styles/detailCards.scss';
import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';

import ModalPortal from '../../ModalPortal';
import ModalDetailCard from '../../components/modal/ModalDetailCard';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination } from 'swiper';

SwiperCore.use([Pagination]);

export default function DetailCard({ classData }) {
  const deviceCheck = useMediaQuery({ maxWidth: 1024 }) ? true : false;
  const thumbLists = useRef();
  const [currentCase, setCurrentCase] = useState(0);
  const [bgImage, setBgimage] = useState('');

  const [modalopen, setModalopen] = useState(false);
  const [imgWidth, setImageWidth] = useState(null);
  const [imgHeight, setImageHeight] = useState(null);

  useEffect(() => {
    if (classData.packageImages) {
      setBgimage(`${classData.packageImages[0]}`);
    }
  }, [classData.packageImages]);

  const removeClassName = (target) => {
    target.current.childNodes.forEach((item) => {
      return item.children[0].classList.remove('active');
    });
  };

  const activeClassName = (target) => {
    target.current.childNodes[0].children[0].classList.add('active');
  };

  function handleChange(e) {
    let urlRegex = /(?:https?\:\/\/)?([a-zA-Z0-9.:/_-ㄱ-ㅎ|ㅏ-ㅣ|가-힣]+)/gm;
    let url = e.target.style.backgroundImage;

    removeClassName(thumbLists);
    e.target.classList.add('active');

    setCurrentCase(e.target.dataset.idx);
    let urlResult = url.match(urlRegex);
    setBgimage(urlResult[1]);
  }

  const handleOpenModal = useCallback((e) => {
    e.stopPropagation();
    document.body.classList.add('modal-open');
    setModalopen(!modalopen);
    setImageWidth(e.target.naturalWidth);
    setImageHeight(e.target.naturalHeight);
  }, []);

  const handleCloseModal = useCallback((e) => {
    e.preventDefault();
    document.body.classList.remove('modal-open');
    setModalopen(false);
  }, []);

  useEffect(() => {
    if (
      !deviceCheck &&
      classData.packageImages &&
      classData.packageImages.length > 1
    ) {
      activeClassName(thumbLists);
    }
  }, [classData, deviceCheck]);

  return (
    <>
      {deviceCheck ? (
        <section className="detail-card-col">
          <div>
            {classData.packageImages && classData.packageImages.length > 1 ? (
              <Swiper
                className="swiper-container"
                spaceBetween={20}
                slidesPerView={1}
                loop={false}
                pagination={{ type: 'fraction' }}
              >
                {classData.packageImages.map((imgUrl, idx) => {
                  return (
                    <SwiperSlide key={idx} className="swiper-slide">
                      <button className="slide-item" onClick={handleOpenModal}>
                        <img src={imgUrl} alt="" />
                      </button>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            ) : (
              <button className="slide-item" onClick={handleOpenModal}>
                <img src={bgImage} alt="" />
              </button>
            )}
          </div>
        </section>
      ) : (
        <section className="detail-thumb-col">
          <button className="detail-thumb" onClick={handleOpenModal}>
            <img src={bgImage} alt="" />
          </button>

          {classData.packageImages && classData.packageImages.length > 1 ? (
            <ul className="detail-thumb-lists" ref={thumbLists}>
              {classData.packageImages.map((imgUrl, idx) => {
                return (
                  <li key={idx}>
                    <button
                      data-idx={idx}
                      onClick={currentCase !== `${idx}` ? handleChange : null}
                      style={{
                        backgroundImage: `url(${imgUrl})`,
                      }}
                    ></button>
                  </li>
                );
              })}
            </ul>
          ) : null}
        </section>
      )}

      {modalopen ? (
        <ModalPortal>
          <ModalDetailCard
            deviceCheck={deviceCheck}
            imgHeight={imgHeight}
            imgWidth={imgWidth}
            bgImage={bgImage}
            classData={classData}
            handleCloseModal={handleCloseModal}
          />
        </ModalPortal>
      ) : null}
    </>
  );
}
