import { useEffect, useState } from 'react'

export default function useDateComparison(target) {
  const [comparison, setComparison] = useState(false)

  const getComparison = (target) => {
    let originDate = new Date()
    let targetDate = new Date(target)

    if (originDate.getTime() <= targetDate.getTime()) {
      setComparison(true)
    }
  }

  useEffect(() => {
    if (!!target) {
      getComparison(target)
    }
  }, [target])

  return comparison
}
