import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router'

import { callIOS, callNative, closeWebView, userAgent } from 'webview/bridge'

import s from './AiTravel.module.scss'
import { AI_TRAVEL_CONFIG } from './config'
import useGetTravelTip from './hooks/useGetTravelTip'

export default function AiTravelEnglish() {
  const location = useLocation()
  const navigate = useNavigate()
  const queryParams = new URLSearchParams(location.search)
  const userSeq = queryParams.get('userSeq')
  const classSeq = queryParams.get('classSeq')
  const lectureSeq = queryParams.get('lectureSeq')
  const { meta, fetchMeta } = useGetTravelTip()
  const { TRAVEL_IMAGE_HOST } = AI_TRAVEL_CONFIG

  useEffect(() => {
    if (classSeq && lectureSeq) {
      fetchMeta({ classSeq, lectureSeq })
    }

    window['wingBannerDisplay']()

    callNative(
      () => callIOS({ action: 'hideTitleBar' }),
      () => window.Android.hideTitleBar()
    )
  }, [classSeq, lectureSeq, fetchMeta])

  useEffect(() => {
    const metaTag = document.querySelector('meta[name="viewport"]')
    let originalContent = ''

    if (metaTag && userAgent() === 'ios') {
      originalContent = metaTag.content
      metaTag.content = `${originalContent},viewport-fit=cover`
    }

    return () => {
      if (metaTag && userAgent() === 'ios') {
        metaTag.content = originalContent
      }
    }
  }, [])

  useEffect(() => {
    window.pressedBackButton = function () {
      pressedBackButton()
    }

    return () => {
      delete window.pressedBackButton
    }
  }, [])

  const pressedBackButton = () => {
    closeWebView()
  }

  const onClickMoveChat = () => {
    const chatState = { metaInfo: meta }
    navigate(
      `/webview/travel/chat?classSeq=${classSeq}&lectureSeq=${lectureSeq}&userSeq=${userSeq}`,
      { state: chatState }
    )
  }

  if (!meta) return null

  return (
    <article className={s.aiTravel}>
      <button className={s.btnCloseAppview} onClick={pressedBackButton}>
        close
      </button>
      <div className={s.tipContainer}>
        <div className={s.tipInner}>
          <hgroup className={s.titleGroup}>
            <div className={s.tipImg}>
              <img src={`${TRAVEL_IMAGE_HOST}img_tip.png`} alt="자연스러운 대화 Tip" />
            </div>
            <h3>자연스러운 대화 Tip</h3>
          </hgroup>
          <dl className={s.scenario}>
            <dt>상황</dt>
            <dd>
              {meta.scenario.split('\n').map((item, index) => (
                <p key={index}>{item}</p>
              ))}
            </dd>
          </dl>
          <div className={s.missions}>
            <h4>대화 문장 예시</h4>
            <ul className={s.missionList}>
              {meta.missions.map(({ subject, eng, kor }, index) => (
                <li key={index}>
                  <dl className={s.sample}>
                    <dt>{subject}</dt>
                    <dd>{eng}</dd>
                    <dd>{kor}</dd>
                  </dl>
                </li>
              ))}
            </ul>
          </div>
          <div className={s.noti}>
            <ul>
              <li>
                “AI와 복습하기”는 롤플레잉 형식으로 진행되며, 학습 주제에 벗어난 대화를 시도하는
                경우 AI가 답변을 거부할 수 있습니다.
              </li>
              <li>
                다양한 주제로 AI 원어민과 대화하기 원한다면 “AI 원어민톡” 서비스를 이용해 보세요.
              </li>
            </ul>
          </div>
          <button className={s.btnEnter} onClick={onClickMoveChat}>
            대화 시작하기
          </button>
        </div>
      </div>
    </article>
  )
}
