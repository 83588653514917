import { useCallback, useState } from 'react'

import ModalPortal from 'ModalPortal'

import ModalInstallments from 'components/modal/ModalInstallments'

import styles from '../StoreDetail.module.scss'
export default function InstallmentsButton() {
  const [installmentsModal, setInstallmentsModal] = useState(false)

  const handleOpenModalInstallments = useCallback((e) => {
    setInstallmentsModal(true)
  }, [])

  const handleCloseModalInstallments = useCallback((e) => {
    setInstallmentsModal(false)
  }, [])

  return (
    <>
      <button
        type="button"
        className={styles.installmentsButton}
        onClick={handleOpenModalInstallments}
      >
        <span>무이자 안내</span>
      </button>
      {installmentsModal ? (
        <ModalPortal>
          <ModalInstallments
            handleClose={handleCloseModalInstallments}
            btnClose
            title={'무이자 할부 혜택'}
          />
        </ModalPortal>
      ) : null}
    </>
  )
}
