import React, { useState, useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'

import api from 'common/api'
import * as config from 'common/config'

export default function CurriculumList({
  packageSeq,
  entrance // 입학 상세페이지일 때
}) {
  const [curriculumData, setCurriculumData] = useState([])

  // 커리큘럼 API
  const getCurriculum = (seq, entrance) => {
    ;(entrance === 'entrance' ? api.get(`/v2/store/sale/entrance-package/curriculum/list`) : api.get(`/v2/store/sale/package/${seq}/curriculum/list`))
      .then((response) => {
        setCurriculumData(response.data.data.curriculumClasses)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  // 무료영상보기
  const handleOpenFreePlayer = (mediaContentKey, curriculumAssetSeq, className, lectureTitle, lectureSortOrder) => {
    const lectureInfo = {
      apiHost: config.API_HOST,
      mediaContentKey: mediaContentKey,
      curriculumAssetSeq: curriculumAssetSeq,
      className: className,
      lectureTitle: lectureTitle,
      lectureSortOrder: lectureSortOrder
    }
    localStorage.setItem('freePlayerLectureInfo', JSON.stringify(lectureInfo))

    window.open('/promotion-landing/freePlayerV2.html', 'yndWebPlayer', 'width=773,height=562, scrollbars=no')
  }

  useEffect(() => {
    if (packageSeq) {
      getCurriculum(packageSeq, entrance)
    }
  }, [packageSeq])

  return (
    <>
      <section className="curriculum-col">
        <h3
          style={{
            borderBottom: 'none'
          }}
        >
          커리큘럼
        </h3>
        {curriculumData &&
          curriculumData.map((curriculumItem, idx) => {
            return (
              <div className="curriculum-lists" key={curriculumItem.productSeq}>
                <h4 key={idx}>
                  class {idx + 1}. {curriculumItem.className}
                </h4>
                <div className="item" key={curriculumItem.classSeq}>
                  {curriculumItem.courses &&
                    curriculumItem.courses.map((courseItem, idx) => {
                      return (
                        <Accordion className="title" title={courseItem.courseName} course={courseItem.courseSortOrder} key={courseItem.courseName + idx}>
                          {courseItem.lectures &&
                            courseItem.lectures.map((lecturesItem, idx) => {
                              return (
                                <tr key={lecturesItem.lectureTitle + idx}>
                                  <td>{lecturesItem.lectureSortOrder}강</td>
                                  <td className="tit">{lecturesItem.lectureTitle}</td>
                                  <td>
                                    {lecturesItem.freeYn === 'Y' && lecturesItem.freeAssetMedia && (
                                      <button className="btn-free-vod" onClick={(e) => handleOpenFreePlayer(lecturesItem.freeAssetMedia.mediaContentKey, lecturesItem.freeAssetMedia.curriculumAssetSeq, curriculumItem.className, lecturesItem.lectureTitle, lecturesItem.lectureSortOrder)}>
                                        무료 영상 보기
                                      </button>
                                    )}
                                  </td>
                                </tr>
                              )
                            })}
                        </Accordion>
                      )
                    })}
                </div>
              </div>
            )
          })}
      </section>
    </>
  )
}

const Accordion = ({ title, course, children }) => {
  const [isOpen, setIsOpen] = useState(false)
  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 1024 })

    return isMobile ? children : null
  }

  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1024 })

    return isDesktop ? children : null
  }

  return (
    <div className="accordion-wrapper">
      <div className={`title ${isOpen ? 'open' : ''}`} onClick={() => setIsOpen(!isOpen)}>
        <span>{title}</span>
      </div>
      <div className="accordion-item">
        <table>
          <colgroup>
            <Desktop>
              <col width="10%" />
              <col width="" />
              <col width="20%" />
            </Desktop>
            <Mobile>
              <col width="15%" />
              <col width="" />
              <col width="12%" />
            </Mobile>
          </colgroup>
          <tbody>{children}</tbody>
        </table>
      </div>
    </div>
  )
}
