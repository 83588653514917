import React, { useState, useEffect, useCallback } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link, useParams, useNavigate } from 'react-router-dom';


import ModalPortal from 'ModalPortal';

// import '../../../assets/styles/main.scss';
import styled, { ThemeProvider } from 'styled-components';

import api from 'common/api';
import * as config from 'common/config';
import MobileHeader from 'components/common/MobileHeader';
import AlertModal from 'components/modal/AlertModal';
import ModalLearningInquiry from 'components/modal/ModalLearningInquiry';

import icoDownload_54x54b from '../../../assets/images/ico_download_54x54b.png';
import theme from '../../../common/theme';
import Lnb from '../../../components/Lnb';

const ListDetailView = styled.section`
  padding: 8.2rem 0 0;

  .title-col {
    padding: 0 0 2rem;
    border-bottom: 0.3rem solid #000;

    strong {
      font-weight: 700;
      font-size: 2rem;
    }
  }

  pre {
    font-size: 1.6rem;
    line-height: 1.7;
    white-space: pre-wrap;
  }

  .detail-title-area {
    position: relative;
    padding: 2.5rem 2.8rem;
    border-bottom: 0.1rem solid #e7e7f0;

    .thumb-nail {
      width: 7rem;
      height: 7rem;
    }

    .tit-flex-form {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .sub-tit {
        flex: 0 1 70%;
        font-weight: 700;
        font-size: 1.6rem;
        color: #28284b;

        &.black {
          font-size: 1.8rem;
          color: #000;
        }
      }

      .info {
        .check {
          font-weight: 700;
          font-size: 1.6rem;
          color: #a0a0b6;

          & + .date {
            margin-left: 1rem;
          }

          &.completed {
            color: #17c624;
          }
        }

        .date {
          font-weight: 400;
          font-size: 1.2rem;
          color: #a0a0b6;
        }
      }
    }

    .main-tit {
      margin-top: 0.5rem;
      font-weight: 700;
      font-size: 2rem;
    }

    .btn-group {
      //position: absolute;
      //bottom: 2.5rem;
      //right: 2.8rem;
      text-align: right;

      button {
        width: auto;
        height: auto;
        font-weight: 700;
        font-size: 1.6rem;
        background-color: transparent;
        border: none;

        &.btn-delete {
          color: #ff5647;
        }

        & + button {
          &::before {
            content: '';
            display: inline-block;
            width: 0.1rem;
            height: 1.1rem;
            margin: 0 1.2rem;
            background-color: #cfcfda;
          }
        }
      }
    }

    &.board {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .thumb-area {
        flex: 0 1 auto;

        // width: 7rem;
        // height: 7rem;

        span {
          display: block;
          width: 7rem;
          height: 7rem;
          border-radius: 3px;
          background-color: #b7b7c8;
          background-repeat: no-repeat;
          background-position: 50% 50%;
          background-size: cover;
          overflow: hidden;
        }

        + .info-area {
          flex: 1 1 auto;
          width: 80%;
          margin-left: 1.9rem;
        }
      }

      .info-area {
        display: flex;
        align-items: center;
        flex: 0 1 auto;
        width: 100%;

        .main-tit {
          display: inline-block;
          max-width: 80%;
        }

        .star-point {
          height: 1.8rem;
          margin-top: 1rem;
        }

        .class {
          flex: 0 1 auto;
          width: 72%;

          .main-tit {
            display: block;
            max-width: 90%;
          }

          .sub-tit {
            font-size: 1.9rem;
            color: #a0a0b6;
          }
        }

        .reply-modify {
          flex: 1 1 auto;
          text-align: right;

          .modify {
            margin-top: 1rem;
          }
        }

        .reply {
          strong {
            font-weight: 700;
            font-size: 1.6rem;
            color: #a0a0b6;

            & + .date {
              margin-left: 1rem;
            }

            &.completed {
              color: #17c624;
            }
          }
        }

        .date {
          font-weight: 400;
          font-size: 1.2rem;
          color: #a0a0b6;
        }

        button {
          font-weight: 700;
          font-size: 1.6rem;

          &.btn-delete {
            color: #ff5647;
          }

          & + button {
            &::before {
              content: '';
              display: inline-block;
              width: 0.1rem;
              height: 1.1rem;
              margin: 0 1.2rem;
              background-color: #cfcfda;
            }
          }
        }
      }
    }

    &.inquiry {
      .info-area {
        align-items: flex-start;

        .reply-modify {
          .modify {
            margin-top: 3.5rem;
          }
        }
      }
    }
  }

  .detail-content {
    border-bottom: 0.1rem solid #b7b7c8;
  }

  .detail-question {
    padding: 2.9rem 2.8rem;
    font-size: 1.6rem;
    white-space: pre-line;
    line-height: 1.5;

    img {
      display: block;
    }
  }

  .detail-answer {
    padding: 2.9rem 2.8rem;
    background-color: #f7f7fc;

    .thumb-area {
      display: flex;
      align-items: center;

      .thumb {
        width: 5.4rem;
        height: 5.4rem;
        margin-right: 1.5rem;
        background-color: #fff;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: cover;
        border-radius: 50%;
        overflow: hidden;
      }

      .desc {
        strong {
          display: block;
          font-weight: 700;
          font-size: 1.7rem;
        }

        p {
          margin-top: 0.6rem;
          font-weight: 400;
          font-size: 1.3rem;
          color: #a0a0b6;
        }
      }
    }
  }

  .detail-download {
    padding: 1.4rem 2rem;
    font-weight: 700;
    font-size: 1.2rem;
    color: #28284b;
    background-color: #f7f7fc;
    text-align: right;

    &::before {
      content: '첨부파일';
      display: inline-block;
    }

    a {
      margin-left: 1rem;
      font-weight: 400;

      &::after {
        content: '';
        display: inline-block;
        width: 2rem;
        height: 2rem;
        margin-left: 0.5rem;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 50%;
        background-image: url(${icoDownload_54x54b});
        vertical-align: middle;
      }
    }
  }

  @media ${({ theme }) => theme.device.mobile} {
    padding: 0;

    .title-col {
      display: none;
    }

    .detail-title-area {
      padding: 5rem 4.2rem;

      .tit-flex-form {
        flex-direction: column;
        align-items: flex-start;

        .sub-tit {
          font-size: 2.5rem;

          &.black {
            order: 2;
            margin-top: 2.2rem;
            font-size: 4rem;
          }
        }

        .info {
          .check {
            font-size: 2.5rem;
          }
          .date {
            font-size: 2.3rem;
          }
        }
      }

      .btn-group {
        button {
          font-size: 2.5rem;
        }
      }

      &.board {
        min-height: 14rem;

        .thumb-area {
          span {
            width: 13.2rem;
            height: 13.2rem;
          }
        }

        .main-tit {
          font-size: 2.7rem;
        }

        .info {
          position: absolute;
          bottom: 5rem;
          font-size: 2.5rem;
        }

        .info-area {
          flex-direction: column;
          align-items: initial;

          .class {
            position: relative;
            width: 100%;

            .sub-tit {
              font-size: 2.5rem;
            }
          }

          .reply-modify {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 3.5rem;

            .reply {
              strong {
                font-size: 2.2rem;
              }
            }

            .modify {
              flex: 1 1 auto;
              margin-top: 0;
            }
          }

          .date {
            font-size: 2.4rem;
          }

          .star-point {
            top: 1rem;
            right: auto;
            left: 0;
            width: 17rem;
            height: 3rem;
            margin: auto;
          }

          .btn-group {
            position: static;
          }

          button {
            font-size: 2.5rem;
          }
        }
      }

      &.inquiry {
        align-items: flex-start;
        min-height: 12rem;
      }
    }

    .detail-content {
      &.line-unset {
        border-bottom: none;
      }
    }

    .detail-question {
      font-size: 2.6rem;
    }

    .detail-answer {
      padding: 2.9rem 2.8rem;
      background-color: #f7f7fc;

      .thumb-area {
        .thumb {
          width: 8.2rem;
          height: 8.2rem;
          margin-right: 2.2rem;
        }

        .desc {
          strong {
            font-size: 2.6rem;
          }

          p {
            font-size: 2rem;
          }
        }
      }
    }

    .detail-download {
      padding: 3.7rem 5.2rem;
      font-weight: 400;
      font-size: 2.7rem;

      &::before {
        content: '';
      }

      a {
        display: inline-block;
        position: relative;
        width: 100%;
        margin-left: 0;
        font-weight: 400;
        text-align: left;

        &::after {
          content: '';
          display: inline-block;
          position: absolute;
          top: -1rem;
          right: 0;
          width: 5rem;
          height: 5rem;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: 50% 50%;
          background-image: url(${icoDownload_54x54b});
        }
      }
    }

    pre {
      font-size: 2.6rem;

      table {
        width: auto !important;
      }
    }

    .detail-content {
      border-bottom: none;
    }

    .qna-lists-col {
      padding: 0;

      .board-btn-group {
        width: 90%;
      }
    }
  }
`;

export default function DetailLearning() {
  const navigate = useNavigate();
  const { questionSeq } = useParams();
  const [learningData, setLearningData] = useState([]);
  const [modalopenAlert, setModalopenAlert] = useState(false);
  const [modalopenInquiry, setModalopenInquiry] = useState(false);

  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1024 });
    return isDesktop ? children : null;
  };

  const handleOpenModalAlert = useCallback((e) => {
    document.body.classList.add('modal-open');
    setModalopenAlert(true);
  }, []);

  const handleCloseModalAlert = useCallback((e) => {
    document.body.classList.remove('modal-open');
    setModalopenAlert(false);
  }, []);

  const handleOpenModalInquiry = useCallback((e) => {
    document.body.classList.add('modal-open');
    setModalopenInquiry(true);
  }, []);

  const handleCloseModalInquiry = useCallback((e) => {
    document.body.classList.remove('modal-open');
    setModalopenInquiry(false);
  }, []);

  function formatDate(date) {
    if (date) {
      let getDate = date.split(' ');
      return getDate[0];
    } else {
      return null;
    }
  }

  const getLearningData = () => {
    api
      .get(`/v2/lecture-question/${questionSeq}`)
      .then((response) => {
        setLearningData(response.data.data);
      })
      .catch((e) => {
        alert(config.MESSAGE['common-error']);
      });
  };

  const deletLerningData = () => {
    api
      .delete(`/v2/lecture-question/${questionSeq}`)
      .then((response) => {
        alert('삭제가 완료되었습니다.');
        navigate('/mypage/qna/learning');
      })
      .catch((e) => {
        alert(config.MESSAGE['common-error']);
      });
  };

  useEffect(() => {
    getLearningData();

    // 페이지 벗어날때 모달 클래스 삭제
    return () => {
      document.body.classList.remove('modal-open');
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <div className="qna-col">
        <div className="inner-col">
          <div className="container mypage-sub">
            <MobileHeader title="학습 문의"  onClick={() =>
                  document.referrer == '' ? navigate('/mypage/qna/learning') : navigate(-1)
                }/>
            <Lnb />
            <ListDetailView>
              <div className="title-col">
                <strong>학습문의</strong>
              </div>
              <div className="detail-title-area board">
                <div className="info-area">
                  <div className="class">
                    <strong className="sub-tit">{learningData.classTitle}</strong>
                    <p className="ellipsis main-tit">{learningData.courseTitle}</p>
                  </div>
                  <div className="reply-modify">
                    <div className="reply">
                      <strong
                        className={'check' + (learningData.replyStatus === 'Y' ? ' completed' : '')}
                      >
                        {learningData.replyStatus === 'Y' ? '답변완료' : '답변대기'}
                      </strong>
                      <span className="date">{formatDate(learningData.registDate)}</span>
                    </div>
                    <div className="modify">
                      <Desktop>
                        {learningData.replyStatus === 'N' && (
                          <div className="btn-group">
                            <button className="btn-modify" onClick={handleOpenModalInquiry}>
                              <span>수정</span>
                            </button>
                            <button className="btn-delete" onClick={handleOpenModalAlert}>
                              <span>삭제</span>
                            </button>
                          </div>
                        )}
                      </Desktop>
                    </div>
                  </div>
                </div>
              </div>
              <div className="detail-content">
                <div className="detail-question">
                  <p>{learningData.contents}</p>
                  {learningData.questionFilePath && (
                    <img src={learningData.questionFilePath} alt="" />
                  )}
                </div>
                {learningData.replyStatus === 'Y' ? (
                  <div className="detail-answer">
                    <div className="thumb-area">
                      <span className="thumb">
                        <i className="blind">썸네일</i>
                        {learningData.answer.answerThumbnailPath && (
                          <img src={learningData.answer.answerThumbnailPath} alt="" />
                        )}
                      </span>
                      <div className="desc">
                        <strong>{learningData.answer.answerUserName}</strong>
                        <p>{formatDate(learningData.answer.answerDate)}</p>
                      </div>
                    </div>
                    <pre
                      className="answer-txt"
                      dangerouslySetInnerHTML={{
                        __html: learningData.answer.answerContent,
                      }}
                    />
                    {learningData.answer.answerFilePath && (
                      <img src={learningData.answer.answerFilePath} alt="" />
                    )}
                  </div>
                ) : null}
              </div>

              <div className="list-btn-col">
                <div className="inner-col">
                  <Link
                    to={{
                      pathname: '/mypage/qna/learning',
                    }}
                    className="btn btn-go-list"
                  >
                    목록보기
                  </Link>
                </div>
              </div>
            </ListDetailView>
          </div>
        </div>
      </div>
      <Desktop>
        {modalopenAlert ? (
          <ModalPortal>
            <AlertModal
              // btnClose
              btnGroup
              msg={'작성하신 문의를 삭제하시겠습니까? 삭제시 문의내역은 복구할 수 없습니다.'}
              handleCloseModal={handleCloseModalAlert}
              handleConfirmClose={deletLerningData}
            />
          </ModalPortal>
        ) : null}
        {modalopenInquiry ? (
          <ModalPortal>
            <ModalLearningInquiry
              btnClose
              learningData={learningData}
              activeCourseData={learningData}
              handleCloseModal={handleCloseModalInquiry}
            />
          </ModalPortal>
        ) : null}
      </Desktop>
    </ThemeProvider>
  );
}
