import React from 'react';

import styled, { ThemeProvider } from 'styled-components';
import theme from 'common/theme';

import Skeleton from 'react-loading-skeleton';

export default function ThumbNail({ backgroundColor, backgroundImage, size }) {
  return (
    <ThemeProvider theme={theme}>
      <ThumbBox>
        {backgroundImage ? (
          <i
            style={{
              width: size,
              height: size,
              backgroundColor: backgroundColor,
              backgroundImage: `url(${backgroundImage})`,
            }}
          ></i>
        ) : (
          <Skeleton circle={true} width={size} height={size} />
        )}
      </ThumbBox>
    </ThemeProvider>
  );
}

const ThumbBox = styled.div`
  i {
    display: inline-block;
    position: relative;
    width: 3.8rem;
    height: 3.8rem;
    background-color: #cfcfda;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    border-radius: 50%;
    box-sizing: border-box;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      border: 1px solid rgba(0, 0, 0, 0.05);
      border-radius: inherit;
    }
  }

  span {
    width: 3.8rem;
    height: 3.8rem;
  }

  @media ${({ theme }) => theme.device.mobile} {
    i {
      width: 6rem;
      height: 6rem;
    }
  }
`;
