import { Link } from 'react-router-dom'

import styles from './classItem.module.css'

export default function ClassItem({ item }) {
  return (
    <li className={styles.item}>
      <Link
        to={{
          pathname: `/store/detail/${item.packageSeq}`
        }}
      >
        <div className={styles.thumbnailBox}>
          <div className={styles.thumbnail}>
            <img src={item.thumbnailPath} alt={item.packageName} />
          </div>
          <SaleStatusBox status={item.saleStatus} />
          <ClassLength count={item.classCount} />
          <EarlyBird saleTypeCode={item.saleTypeCode} />
        </div>

        <div className={styles.row}>
          <span>{item.packageCategory}</span>
          &nbsp;&middot;&nbsp;
          <span>{item.teacherName}</span>
        </div>
        <p className={styles.packageName}>
          <strong>{item.packageName}</strong>
        </p>
      </Link>
    </li>
  )
}

/**
 * 판매 상태 표시 박스
 * @param {*} status : 판매상태
 * @returns
 */
function SaleStatusBox({ status }) {
  return (
    <div className={styles.saleStatusBox} status={status || null}>
      {
        {
          BEFORE: (
            <div>
              <span className={`${styles.saleStatusText} ${styles.saleBefore}`}>판매 예정</span>
            </div>
          ),
          END: (
            <div>
              <span className={styles.saleStatusText}>Sold out</span>
            </div>
          )
        }[status]
      }
    </div>
  )
}

/**
 *  클래스가 포함 되어 있을 경우 클래스 수 표시
 * @param {*} count : 클래스 갯수
 * @returns
 */
function ClassLength({ count }) {
  return count > 0 ? <div className={styles.classLength}>{count} 클래스 포함</div> : null
}

/**
 *  얼리버드일 경우
 * @param {*} saleTypeCode : 판매타입코드
 * @returns
 */
function EarlyBird({ saleTypeCode }) {
  return saleTypeCode === 'EARLY_BIRD' ? <div className={styles.earlyBirdText}>얼리버드</div> : null
}
