import React, { useState, useEffect } from 'react'

export default function AppDownload() {
  const [isDevice, setIsDevie] = useState(false)
  const isIOS = navigator.userAgent.match(/ipad|ipod|iphone|Mac/i)

  const chkDevice = () => {
    if (isIOS) {
      setIsDevie(true)
      window.location.href = 'https://itunes.apple.com/kr/app/id1539582420'
    } else {
      window.location.href = 'https://play.google.com/store/apps/details?id=kr.co.yanadoo.app2'
    }
  }

  useEffect(() => {
    chkDevice()
  }, [])

  return (
    <div>
      <h4>{isDevice ? 'true' : 'false'}</h4>
    </div>
  )
}
