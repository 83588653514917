import React, { useState, useRef } from 'react';
import Lnb from '../../components/Lnb';

export default function CancelOrder (props) {
	const selectRef = useRef();
	const [flag, setFlag] = useState(false);
	console.log(props.location.state.data);
	const orderData = props.location.state.data;

	const onChangeSelect = (e) => {
		const selectOptions = e.target.options;
		e.target.style.color = "#000";
		const selectValue = selectOptions[selectRef.current.selectedIndex].value
		if(selectValue === 'self') {
			setFlag(!flag);
		}
	}

	return (
		<>
			<div className="order-col">
				<div className="inner-col">
					<div className="container mypage-sub">
						<Lnb />
						<section className="order-list-col">
							<h3>결제 취소 신청</h3>
							<div className="order-cancel-col">
								<div className="order-tit">
									<div className="date">
										<span className="order-date">{orderData.paymentDate}</span>
									</div>
									<span className="light">주문번호 {orderData.packageSeq}</span>
								</div>
								<div className="order-item">
									<div className="order-desc">
										<a href="#;" className="thumb">
											<i className="blind">강의 상세페이지로 이동</i>
										</a>
										<div className="desc">
											{/* 상품 이름 */}
											<a href="#;" className="tit ellipsis">{orderData.packageName}</a>
											{orderData.orderCount &&
												<span className="quantity">수량 : {orderData.orderCount}개</span>
											}
											<div className="price">
												<span className="payment-price">{orderData.paymentPrice}원</span>
												<span className="discount-price"><strong>{orderData.discountPrice}</strong>원</span>
											</div>
										</div>
									</div>
								</div>
								<div className="order-cancel-box">
									<strong className="tit">취소 사유</strong>
									<select name="" className="modal-select-box" ref={selectRef} onChange={onChangeSelect} defaultValue="default">
										<option value="default" disabled hidden>취소사유를 선택해주세요</option>
										<option value="">어쩌구 저쩌구</option>
										<option value="">그냥</option>
										<option value="">일이삼사오육칠팔구십일이삼사오육칠팔구십일이삼사오육칠팔구십일이삼사오육칠팔구십</option>
										<option value="self">직접입력</option>
									</select>
									{ flag && 
										<textarea maxLength="200" placeholder="취소사유를 입력해주세요" className="basic-textarea"></textarea>
									}
								</div>
							</div>
							<h3 className="tit-mobile">배송/수거지 정보</h3>
							<div className="order-cancel-col">
								<div className="cancel-info delivery">
									<dl>
										<dt>주문자 정보</dt>
										<dd>{orderData.userName}</dd>
									</dl>
									<dl>
										<dt>배송지 정보</dt>
										<dd>{orderData.packageName}</dd>
									</dl>
									<dl className="requests-mobile">
										<dt>배송 요청사항</dt>
										<dd>일이삼사오육칠팔구십일이삼사오육칠팔구십일이삼사오육칠팔구십</dd>
									</dl>
								</div>
							</div>
							<h3 className="tit-mobile">결제 정보</h3>
							<div className="order-cancel-col payment">
								<div className="payment-info">
									<div className="cancel-info">
										<dl>
											<dt>결제방법</dt>
											<dd>신용카드 결제(삼성카드)</dd>
										</dl>
										<dl>
											<dt>상품 총 금액</dt>
											<dd>{orderData.paymentPrice}</dd>
										</dl>
										<dl>
											<dt>배송비</dt>
											<dd>+ 0원</dd>
										</dl>
									</div>
									<div className="cancel-price">
										<span>총 환불 금액</span>
										<strong>28,000원</strong>
									</div>
								</div>
							</div>
							<div className="btn-flex-form">
								<button className="btn">
									<span>취소</span>
								</button>
								<button className="btn active">
									<span>결제취소 신청</span>
								</button>
							</div>
						</section>
					</div>
				</div>
			</div>
		</>
	);
};

