import React, { useState, useEffect, useCallback } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Link, useNavigate } from 'react-router-dom'

import AuthService from 'services/authService'

import api from 'common/api'
import * as config from 'common/config'
import MobileHeader from 'components/common/MobileHeader'

import AlertModal from '../../../src/components/modal/AlertModal'
import Lnb from '../../components/Lnb'
import ModalPortal from '../../ModalPortal'
import '../../assets/styles/member.scss'

const checkboxes = [
  {
    id: '1',
    name: '컨텐츠가 부족하고 강의도 만족스럽지 않아요',
    key: 'checkBox1',
    label: 'Check Box 1'
  },
  {
    id: '2',
    name: '난이도가 저와 안 맞아요',
    key: 'checkBox2',
    label: 'Check Box 2'
  },
  {
    id: '3',
    name: '서비스를 이용하기 너무 힘들어요',
    key: 'checkBox3',
    label: 'Check Box 3'
  },
  {
    id: '4',
    name: '구매 가격이 불만이에요',
    key: 'checkBox4',
    label: 'Check Box 4'
  },
  {
    id: '5',
    name: '기타의견',
    key: 'checkBox5',
    label: 'Check Box 5'
  }
]

export default function MemberDrop() {
  const navigate = useNavigate()
  const [modalopen, setModalopen] = useState(false)
  const [modalClose, setModalClose] = useState(false)
  const [steps, setSteps] = useState([{ label: 1 }, { label: 2 }, { label: 3 }])
  const [stepIndex, setStepIndex] = useState(1)
  const [checkedItems, setCheckedItems] = useState([])
  const [btnLastSubmit, setBtnLastSubmit] = useState(false)
  const [isComplete, setIsComplete] = useState(null)
  const [textareaValue, setTextareaValue] = useState({
    reasonArea: '',
    wishArea: ''
  })
  const [benefitData, setBenefitData] = useState(null)
  const extendUseY = benefitData && benefitData.filter((list) => list.extendUseYn === 'Y')
  const extendUseN = benefitData && benefitData.filter((list) => list.extendUseYn === 'N')
  const benefitLength = {
    extendY: extendUseY && extendUseY.length,
    extendN: extendUseN && extendUseN.length
  }

  function formatDate(date) {
    if (date) {
      let getDate = date.split(' ')
      return getDate[0]
    } else {
      return null
    }
  }

  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1025 })
    return isDesktop ? children : null
  }

  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 1024 })
    return isMobile ? children : null
  }

  const handleOpenModal = useCallback((e) => {
    document.body.classList.add('modal-open')
    setModalopen(true)
  }, [])

  const handleCloseModal = useCallback((status) => {
    document.body.classList.remove('modal-open')
    setModalopen(false)
  }, [])

  const handleConfirmClose = (status) => {
    setModalClose(status)
    handleCloseModal()
  }

  const handleCheckItem = (checked, id) => {
    if (checked) {
      setCheckedItems([...checkedItems, id])
    } else {
      setCheckedItems(checkedItems.filter((e) => e !== id))
    }
  }

  const handleCheckFinal = (event) => {
    if (event.target.checked) {
      setBtnLastSubmit(true)
    } else {
      setBtnLastSubmit(false)
    }
  }

  const handleTextareaValue = (e) => {
    let { id, value } = e.target

    setTextareaValue({ ...textareaValue, [id]: value })
  }

  const isCheckCondition = () => {
    if (checkedItems.length > 0) {
      if (!checkedItems.includes('5')) {
        return true
      } else {
        if (textareaValue.reasonArea.length >= 10) {
          return true
        } else {
          return false
        }
      }
    }
  }

  const handleNextSteps = () => {
    if (stepIndex < 4) {
      setStepIndex(stepIndex + 1)
    } else {
      return
    }
  }

  const isStepCondition = (arg, stepIndex) => {
    if (arg === stepIndex) {
      return true
    } else {
      if (arg === 3 && stepIndex === 4) {
        return true
      }
    }
  }

  const postMemberRetire = () => {
    const data = {
      opinion: textareaValue.wishArea,
      reason: checkedItems.join('|'),
      reasonText: textareaValue.reasonArea,
      channel: 'YANADOO_WEB'
    }

    api
      .post(config.AUTH_API_HOST + '/auth/v2/user/retire', data)
      .then((response) => {
        if (response.data.meta.code === 200) {
          alert('회원 탈퇴가 완료되었습니다.')
          AuthService.signOut()
          navigate('/')
        } else {
          alert(response.data.meta.message)
          navigate('/member/auth')
        }
      })
      .catch((e) => {
        alert(config.MESSAGE['common-error'])
      })
  }

  const getDisappearBenefit = () => {
    api
      .get('/v2/retire/disappear-benefit')
      .then((response) => {
        setBenefitData(response.data.data)
      })
      .catch((e) => {
        alert(config.MESSAGE['common-error'])
      })
  }

  useEffect(() => {
    if (isCheckCondition() && btnLastSubmit) {
      setIsComplete(true)
    } else {
      setIsComplete(false)
    }
  }, [checkedItems, btnLastSubmit, textareaValue])

  useEffect(() => {
    if (modalClose) {
      postMemberRetire()
    }
  }, [modalClose])
  useEffect(() => {
    getDisappearBenefit()
  }, [])

  return (
    <>
      <div className="member-drop-col">
        <div className="inner-col">
          <div className="container mypage-sub">

          <MobileHeader title="회원탈퇴"  onClick={() =>
                  document.referrer == '' ? navigate('/member/editinfo') : navigate(-1)
                }/>
            <Lnb />
            <section>
              <h2>
                <i className="blind">회원탈퇴</i>
              </h2>
              <strong className="notice desktop">
                회원 탈퇴 신청에 앞서 아래의 사항을 반드시 확인해주세요!
              </strong>
              <strong className="notice mobile">
                <p>
                  회원 탈퇴 신청에 앞서 <br />
                  아래의 사항을 반드시 확인해주세요!
                </p>
              </strong>
              <div className="mobile-progress">
                <ul>
                  {steps.map((step, i) => {
                    return (
                      <li
                        key={i}
                        className={`${isStepCondition(step.label, stepIndex) ? 'active' : ''}`}
                      >
                        {step.label}
                      </li>
                    )
                  })}
                </ul>
              </div>
              <div className="step-component">
                <div className={'step-01' + (stepIndex === 1 ? ' active' : ' hidden')}>
                  <div className="title">
                    <span>1</span>
                    사라지는 혜택
                    <p>야나두 회원탈퇴시 사라지는 아래의 혜택을 확인해주세요.</p>
                  </div>
                  <div className="content">
                    <ul>
                      {extendUseN &&
                        extendUseN.map((list, idx) => (
                          <li key={idx}>
                            <span>혜택{idx + 1}</span>
                            <strong>{formatDate(list.closeDate)}</strong>까지 수강 가능한
                            <strong>{list.className}</strong>를 더 이상 수강하실 수 없습니다.
                          </li>
                        ))}
                      {extendUseY &&
                        extendUseY.map((list, idx) => (
                          <li key={idx}>
                            <span>혜택{benefitLength.extendN + 1 + idx}</span>
                            평생을 책임지는 <strong>{list.packageName}</strong>을 더이상 수강하실 수
                            없습니다.
                          </li>
                        ))}
                      <li>
                        <span>
                          혜택
                          {benefitLength.extendY + benefitLength.extendN + 1}
                        </span>
                        보유중인 <strong>모든 할인 쿠폰</strong>이 삭제됩니다.
                      </li>
                      <li>
                        <span>
                          혜택
                          {benefitLength.extendY + benefitLength.extendN + 2}
                        </span>
                        입학혜택으로 제공되었던 <strong>할인혜택</strong>이 사라지게 됩니다.
                      </li>
                      <li>
                        <span>
                          혜택
                          {benefitLength.extendY + benefitLength.extendN + 3}
                        </span>
                        영어발음이 쑥쑥!
                        <strong>1:1 보이스케어장학금</strong>에 더이상 참여하실 수 없습니다.
                      </li>
                      <li>
                        <span>
                          혜택
                          {benefitLength.extendY + benefitLength.extendN + 4}
                        </span>
                        단어가 쏙쏙! <strong>야나두 틈새단어</strong>
                        어플을 더이상 무료로 이용할 수 없습니다.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className={'step-02' + (stepIndex === 2 ? ' active' : ' hidden')}>
                  <div className="title">
                    <span>2</span>
                    탈퇴 시 유의사항
                    <p>아래 사항을 반드시 유의하여 읽어주세요.</p>
                  </div>
                  <div className="content">
                    <ul>
                      <li>
                        회원 탈퇴시 보유 중인 강의, 쿠폰은 모두 소멸되며 재가입시 복구가 불가합니다.
                      </li>
                      <li>
                        회원탈퇴 시 모든 정보는 삭제됩니다. 회원 탈퇴 후 문의사항은 야나두
                        학습운영센터(1600-0563)로 연락주십시오.
                      </li>
                      <li>탈퇴가 처리되면 동일한 ID로 재가입이 불가합니다.</li>
                      <li>
                        회원정보 및 포인트, 구매내역은 바로 삭제되며 포인트는 현금으로 환불
                        불가합니다.
                      </li>
                      <li>
                        회원 탈퇴시 틈새단어, 1:1 보이스케어 등 야나두 회원전용 서비스 또한 사용불가
                        합니다.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className={'step-03' + (stepIndex === 3 ? ' active' : ' hidden')}>
                  <div className="title">
                    <span>3</span>
                    회원탈퇴 사유
                    <p>더 나은 야나두가 될 수 있도록 탈퇴 사유를 알려주세요.</p>
                  </div>
                  <div className="content">
                    <form action="">
                      <ul>
                        {checkboxes &&
                          checkboxes.map((item) => (
                            <li key={item.key}>
                              <label className={checkedItems === '5' ? 'textarea' : null}>
                                <input
                                  type="checkbox"
                                  name={item.id}
                                  checked={checkedItems.includes(item.id) ? true : false}
                                  onChange={(e) => handleCheckItem(e.target.checked, item.id)}
                                />
                                <em>checkbox</em>
                                {item.name}
                                {item.id === '5' && checkedItems.includes('5') && (
                                  <textarea
                                    id="reasonArea"
                                    className="etc-textarea"
                                    rows="1"
                                    maxLength="2000"
                                    placeholder="기타 의견을 작성해주세요."
                                    onChange={handleTextareaValue}
                                    value={textareaValue.reasonArea}
                                    required
                                  />
                                )}
                              </label>
                            </li>
                          ))}
                      </ul>
                    </form>
                  </div>
                </div>
                <div className={'step-04' + (stepIndex === 4 ? ' active' : ' hidden')}>
                  <div className="title">
                    <span>3</span>
                    회원탈퇴 사유
                    <p>더 나은 야나두가 될 수 있도록 탈퇴 사유를 알려주세요.</p>
                  </div>
                  <div className="content">
                    <div className="sub-title">야나두에 바라는 점을 남겨주세요.</div>
                    <div className="sub-content">
                      <textarea
                        id="wishArea"
                        cols="30"
                        rows="10"
                        maxLength="2000"
                        placeholder="자유롭게 서술해주세요."
                        onChange={handleTextareaValue}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="confirm-area">
                <Desktop>
                  <label htmlFor="checkAgree" className="agree">
                    <input
                      type="checkbox"
                      id="checkAgree"
                      checked={btnLastSubmit ? 'checked' : ''}
                      onChange={handleCheckFinal}
                    />
                    <em>checkbox</em>
                    <p>
                      위 사항을 모두 확인하였으며, 탈퇴 처리 후 모든 정보를 삭제하는 것에
                      동의합니다.
                    </p>
                  </label>
                  <button
                    type="submit"
                    className={'btn btn-withdrawal' + (isComplete ? ' active' : '')}
                    // onClick={postMemberRetire}
                    onClick={handleOpenModal}
                    disabled={isComplete ? false : true}
                  >
                    <span>회원탈퇴</span>
                  </button>
                </Desktop>
                <Mobile>
                  {stepIndex === 3 ? (
                    <button
                      type="button"
                      className={'btn' + (isCheckCondition() ? ' active' : '')}
                      disabled={!isCheckCondition()}
                      onClick={() => {
                        setStepIndex(4)
                      }}
                      style={{
                        borderColor: isCheckCondition() ? null : '#CFCFDA'
                      }}
                    >
                      다음
                    </button>
                  ) : stepIndex === 4 ? (
                    <>
                      <label htmlFor="checkAgree" className="agree">
                        <input
                          type="checkbox"
                          id="checkAgree"
                          checked={btnLastSubmit ? 'checked' : ''}
                          onChange={handleCheckFinal}
                        />
                        <em>checkbox</em>
                        <p>
                          위 사항을 모두 확인하였으며, 탈퇴 처리 후 모든 정보를 삭제하는 것에
                          동의합니다.
                        </p>
                      </label>
                      <button
                        type="submit"
                        className={'btn btn-withdrawal' + (btnLastSubmit ? ' active' : '')}
                        onClick={handleOpenModal}
                        disabled={btnLastSubmit ? false : true}
                      >
                        <span>회원탈퇴</span>
                      </button>
                    </>
                  ) : (
                    <>
                      <Link to="/" className="btn active">
                        네, 계속 이용할래요
                      </Link>
                      <button className="btn-next" onClick={handleNextSteps}>
                        아니요, 그래도 탈퇴할래요
                      </button>
                    </>
                  )}
                </Mobile>
              </div>
            </section>
          </div>
        </div>
      </div>
      {modalopen ? (
        <ModalPortal>
          <AlertModal
            // btnClose
            btnGroup
            msg={'야나두 회원탈퇴를 하시겠습니까?'}
            handleCloseModal={handleCloseModal}
            handleConfirmClose={handleConfirmClose}
          />
        </ModalPortal>
      ) : null}
    </>
  )
}
