import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { getCookie, setCookie } from 'common/Cookie'

import ModalWrap from './ModalWrap'

export default function ModalPromotionEvent({ popupInfo }) {
  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  const handleCloseDate = (date) => {
    let day = 0
    switch (date) {
      case '오늘 하루 열지 않기':
        day = 1
        break
      case '7일간 열지 않기':
        day = 7
        break
      default:
        day = 0
        break
    }
    if (day !== 0) {
      setCookie(`popupClose${popupInfo.seq}`, 'N', day)
    }
    handleClose()
  }

  const LinkContent = ({ link, children }) => {
    if (link !== null) {
      return (
        <a href={link} onClick={handleClose}>
          {children}
        </a>
      )
    } else {
      return children
    }
  }

  useEffect(() => {
    if (popupInfo !== null && popupInfo !== undefined && popupInfo.displayYn === 'Y') {
      setOpen(getCookie(`popupClose${popupInfo.seq}`) !== 'N')
    }
    return () => setOpen(false)
  }, [popupInfo])

  return (
    <>
      {open ? (
        <ModalWrap
          onClose={handleClose}
          width={'550px'}
          dateClose={
            popupInfo.closeType === '옵션 없음'
              ? null
              : { onClick: () => handleCloseDate(popupInfo.closeType), title: popupInfo.closeType }
          }
          btn
        >
          <LinkContent link={popupInfo.link ? popupInfo.link : null}>
            <img src={popupInfo.image} alt={popupInfo.title} />
          </LinkContent>
        </ModalWrap>
      ) : null}
    </>
  )
}
