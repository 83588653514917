import ResponsiveImage from 'components/ResponsiveImage'

import { HOST_CDN } from '../config'
import s from '../EnglishHome.module.css'

export default function ChangeSection() {
  return (
    <section className={s.change}>
      <div className={s.imgText}>
        <ResponsiveImage
          pc={`${HOST_CDN}text_section_06_v2.png`}
          mo={`${HOST_CDN}text_m_section_06_v2.png`}
          alt={'이미 170만명이 변화하고 있어요'}
          maxWidth={1024}
        />
      </div>
    </section>
  )
}
