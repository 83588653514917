import TrainingStep01 from './TrainingStep01'
import TrainingStep02 from './TrainingStep02'
import TrainingStep03 from './TrainingStep03'
import TrainingStep04 from './TrainingStep04'
import TrainingStep05 from './TrainingStep05'
import TrainingStep06 from './TrainingStep06'

export default function TrainingStep({ activeIdx }) {
  switch (activeIdx) {
    case 1:
      return <TrainingStep02 />
    case 2:
      return <TrainingStep03 />
    case 3:
      return <TrainingStep04 />
    case 4:
      return <TrainingStep05 />
    case 5:
      return <TrainingStep06 />
    default:
      return <TrainingStep01 />
  }
}
