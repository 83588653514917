import api from 'common/api'
import * as config from 'common/config'
import { removeCookie, setCookieForSubDomain, setCookie, getCookie } from 'common/Cookie'

const KEY_USER = 'user'
const KEY_ACCESS_TOKEN = 'access_token'
const KEY_REFRESH_TOKEN = 'refresh_token'
const KEY_USER_DETAIL = 'user_detail'

const isEmpty = (val) => {
  return (
    val === undefined ||
    val === null ||
    val === '' ||
    (val !== null && typeof val === 'object' && !Object.keys(val).length)
  )
}

const b64_to_utf8 = (str) => {
  return decodeURIComponent(escape(window.atob(str)))
}
let observers = []

const addObserver = (observer) => {
  observers.push(observer)
}

const removeObserver = (observer) => {
  observers = observers.filter((obs) => obs !== observer)
}

const notify = () => {
  observers.forEach((observer) => observer())
}

const isLoggedIn = () => {
  const refreshToken = getLocalRefreshToken()
  if (!isActive(refreshToken)) {
    return false
  }
  return true
}

const isActive = (token) => {
  if (isEmpty(token)) {
    return false
  }

  if (token.split('.').length !== 3) {
    return false
  }

  try {
    const payload = b64_to_utf8(token.split('.')[1].replace(/_/g, '/').replace(/-/g, '+'))
    const exp = JSON.parse(payload).exp
    return new Date(exp * 1000) > new Date()
  } catch (e) {
    console.log(e)
    return false
  }
}

const getUserInfo = () => {
  // console.log(localStorage.getItem(KEY_USER));
  return JSON.parse(localStorage.getItem(KEY_USER))
}

const getLocalAccessToken = () => {
  return localStorage.getItem(KEY_ACCESS_TOKEN)
}

const getLocalRefreshToken = () => {
  return localStorage.getItem(KEY_REFRESH_TOKEN)
}

const setUserInfo = (userInfo) => {
  localStorage.setItem(KEY_USER, JSON.stringify(userInfo.user))
  localStorage.setItem(KEY_ACCESS_TOKEN, userInfo.accessToken)
  localStorage.setItem(KEY_REFRESH_TOKEN, userInfo.refreshToken)

  setCookieForSubDomain(KEY_REFRESH_TOKEN, userInfo.refreshToken, 2)
  setCookieForSubDomain(KEY_ACCESS_TOKEN, userInfo.accessToken, 1)
  if (window.setPixelInfo && typeof window.setPixelInfo === 'function') window.setPixelInfo()
}
const setCookieAccessToken = (token) => {
  setCookieForSubDomain(KEY_ACCESS_TOKEN, token, 1)
}
const setLocalAccessToken = (token) => {
  localStorage.setItem(KEY_ACCESS_TOKEN, token)
}

const setLocalRefreshToken = (token) => {
  localStorage.setItem(KEY_REFRESH_TOKEN, token)
}

const removeUserInfo = () => {
  localStorage.removeItem(KEY_USER)
  localStorage.removeItem(KEY_ACCESS_TOKEN)
  localStorage.removeItem(KEY_REFRESH_TOKEN)
  sessionStorage.removeItem(KEY_USER_DETAIL)

  removeCookie(KEY_REFRESH_TOKEN)
  removeCookie(KEY_ACCESS_TOKEN)
}

// bigin GTM 코드 삽입함수
const biginUserInfo = (isFrame) => {
  api
    .get(config.AUTH_API_HOST + '/auth/v2/user')
    .then((response) => {
      const userInfo = response.data.data
      let userDetail = {}
      if (userInfo) {
        userDetail = {
          id: userInfo.userId,
          name: userInfo.username,
          email: userInfo.email,
          cellPhone: userInfo.cellPhone
        }
        sessionStorage.setItem(KEY_USER_DETAIL, JSON.stringify(userDetail))

        if (isFrame !== null && isFrame !== undefined) {
          switch (isFrame) {
            case 'opener':
              window.opener.parent.postMessage({ type: 'login' }, '*')
              window.close()
              break
            default:
              window.parent.postMessage({ type: 'login' }, '*')
              break
          }
        } else {
          // historyUrl 쿠키값이 저장되어 있다면 해당 URL로 페이지 이동.
          if (getCookie('historyUrl') !== '') {
            setCookie('orderLoginSuccess', 'Y')
            if (window.location.href !== getCookie('historyUrl'))
              window.location.href = getCookie('historyUrl')
          } else {
            window.location.href = '/'
          }
        }
      }
    })
    .catch((e) => {
      console.log(e)
    })
}

let flag = true

const signIn = (userId, password, checked, callback) => {
  if (flag) {
    flag = false
    let formData = new FormData()
    formData.append('userId', userId)
    formData.append('password', password)
    formData.append('deviceType', 0)
    if (checked) {
      formData.append('isKeepLogin', checked)
    }

    api.post(config.SIGN_URL['get-token'], formData).then((response) => {
      signInSuccess(response, callback)
    })
  }
}

const signInSuccess = (response, callback) => {
  let responseData = response.data

  if (responseData.meta.code !== 200) {
    if (responseData.meta.code === 4013) {
      // 비밀번호를 5회 이상 틀렸을 경우
      callback.FAILURE(
        '비밀번호를 5회이상 잘못 입력했습니다. \n 비밀번호 찾기를 진행해주세요.',
        responseData.meta.code
      )
      flag = true
      return false
    }

    if (responseData.meta.code === 4023) {
      // 휴면회원인 경우
      callback.SLEEP()
      flag = true

      return
    }

    if (responseData.meta.message) {
      callback.FAILURE(responseData.meta.message, responseData.meta.code)
    } else {
      alert(config.MESSAGE['common-error'])
    }

    flag = true
    return
  }

  setUserInfo(responseData.data)

  // 비회원 수기결제인 경우
  if (responseData.data.user.isManualPaymentUser) {
    callback.PAYMENT(responseData.data.accessToken)
    flag = true
    return false
  }
  // 유저시퀀스 추가
  if (callback && callback.SUCCESS) {
    callback.SUCCESS(responseData.data.isNeedPasswordChange, responseData.data.user.userSeq)
  }

  flag = true
}

const tokenSignOut = (refreshToken, callback) => {
  try {
    api
      .post(config.SIGN_URL['sign-out'] + '?refreshToken=' + refreshToken)
      .then((response) => {
        removeUserInfo()
        notify()
        if (callback) {
          callback()
        }
      })
      .catch((e) => {
        console.log(e)
      })
  } catch (e) {
    console.log(e)
  }
}

const signOut = (refresh) => {
  const refreshToken = window.localStorage.getItem('refresh_token')
  if (!!refreshToken) {
    tokenSignOut(refreshToken, refresh)
  }
}

const getUser = (callback) => {
  api
    .get(config.SIGN_URL['get-user'])
    .then((response) => {
      if (callback) {
        callback(response.data?.data)
      }
    })
    .catch((error) => {
      console.log(error)
    })
}

const AuthService = {
  isLoggedIn,
  getUserInfo,
  getLocalAccessToken,
  getLocalRefreshToken,
  setLocalAccessToken,
  setLocalRefreshToken,
  setCookieAccessToken,
  signIn,
  signOut,
  setUserInfo,
  getUser,
  biginUserInfo,
  addObserver,
  removeObserver,
  notify,
  removeUserInfo
}

export default AuthService
