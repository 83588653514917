import React, { useEffect, useState } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import theme from 'common/theme'
import '../../assets/styles/modal.scss'

const ModalEntSale = ({ btnClose, data, MINIMUM_PRICE, MINIMUM_MONTH, handleCloseModal }) => {
  const addComma = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  return (
    <ThemeProvider theme={theme}>
      <div className="modal-wrap share-modal">
        <div className="dimmed" onClick={handleCloseModal}></div>
        <div className="modal-inner">
          {btnClose && (
            <BtnModalClose onClick={handleCloseModal}>
              <span>닫기</span>
            </BtnModalClose>
          )}
          <SaleInfoBox>
            <ModalHeader>
              <strong>입학회원 할인 안내</strong>
            </ModalHeader>
            <ModalContent>
              <dl>
                <dt>정가</dt>
                <dd>
                  <span>{addComma(Number(data.exceptOptionSalePrice))}</span>
                </dd>
              </dl>
              <dl>
                <dt>입학회원 할인 금액</dt>
                <dd>
                  <span>
                    &ndash; {addComma(Number(data.exceptOptionSalePrice - data.exceptOptionMembershipSalePrice))}
                  </span>
                </dd>
              </dl>
              <dl>
                <dt>최종 금액</dt>
                <dd>
                  {data && data.exceptOptionSalePrice !== data.exceptOptionMembershipSalePrice ? (
                    <span>{addComma(Number(data.exceptOptionMembershipSalePrice))}</span>
                  ) : (
                    <span>{addComma(Number(data.exceptOptionSalePrice))}</span>
                  )}
                </dd>
              </dl>
              {data.exceptOptionMembershipSalePrice >= MINIMUM_PRICE && data.installmentMonth >= MINIMUM_MONTH && (
                <dl>
                  <dt>{data.installmentMonth}개월 할부</dt>
                  <dd>
                    {data.exceptOptionSalePrice !== data.exceptOptionMembershipSalePrice ? (
                      <>
                        <mark>{data.exceptOptionDiscountPercent}&#37;</mark>
                        <strong>{addComma(Number(data.monthlyMembershipSalePrice))}</strong>
                      </>
                    ) : (
                      <strong>{addComma(Number(data.monthlySalePrice))}</strong>
                    )}
                  </dd>
                </dl>
              )}
            </ModalContent>
          </SaleInfoBox>
        </div>
      </div>
    </ThemeProvider>
  )
}

export const BtnModalClose = styled.button`
  position: absolute;
  top: 2.2rem;
  right: 2rem;
  width: 4.4rem;
  height: 4.4rem;
  z-index: 10;

  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 2.8rem;
    height: 1px;
    margin: auto;
    border-bottom: 2px solid #000;
  }

  &:before {
    left: 0;
    right: 2px;
    transform: rotate(-45deg);
  }

  &:after {
    left: 0;
    right: 0;
    transform: rotate(45deg);
  }

  span {
    position: absolute;
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
  }

  @media ${({ theme }) => theme.device.mobile} {
    position: absolute;
    right: 2.5rem;
    width: 7.2rem;
    height: 7.2rem;

    &:before,
    &:after {
      width: 3.4rem;
      border-bottom: 1px solid #000;
    }
  }
`

const SaleInfoBox = styled.div`
  width: 48rem;
  height: 35rem;
  padding: 0 2rem;
  font-size: 2rem;
  box-sizing: border-box;

  @media ${({ theme }) => theme.device.mobile} {
    width: 65rem;
    height: 48rem;
    padding: 0 2.5rem;
    font-size: 3rem;
  }
`

const ModalHeader = styled.div`
  margin-bottom: 3rem;
  padding: 3.3rem 0 3.2rem 2rem;
  font-size: 2.4rem;
  border-bottom: 1px solid #dfdfea;

  @media ${({ theme }) => theme.device.mobile} {
    margin-bottom: 5.9rem;
    padding: 4.2rem 0 4.2rem 3rem;
    font-size: 3.2rem;
  }
`

const ModalContent = styled.div`
  position: relative;
  padding: 0;
  overflow: auto;
  box-sizing: border-box;

  dl {
    display: flex;
    padding: 1.2rem 2.2rem;
    justify-content: space-between;
    align-items: center;
  }

  dt {
    color: #717188;
  }

  dd {
    span,
    strong {
      &:after {
        content: '원';
      }
    }
    mark {
      display: inline-block;
      margin-right: 0.9rem;
      color: #ff5647;
      background-color: transparent;
      font-size: 2rem;
      font-weight: 700;
    }
    strong {
      font-size: 700;

      &:before {
        content: '월';
        display: inline-block;
        margin-right: 0.5rem;
      }
    }
  }

  @media ${({ theme }) => theme.device.mobile} {
    dl {
      padding: 1.5rem 3rem;
    }

    dd {
      mark {
        font-size: 3rem;
      }
    }
  }
`

export default ModalEntSale
