import DbCollectionForm from 'pages/event/DbCollectionForm'
import EventDetail from 'pages/event/detail'
import Winner from 'pages/event/DetailWinner'
import EventEnd from 'pages/event/EventEnd'
import HomeEvent from 'pages/event/Home'
import EventWinnerAddress from 'pages/eventWinnerAddress'
import { Event } from 'router/OutletStore'

const EventRoutes = [
  {
    path: '/event/*',
    element: <Event />,
    children: [
      { path: 'list/:currentSeq', element: <HomeEvent /> },
      { path: 'end/:currentSeq', element: <EventEnd /> },
      { path: 'detail/:currentSeq', element: <EventDetail /> },
      { path: 'winner/:currentSeq', element: <Winner /> },
      { path: 'popup/:keyword', element: <DbCollectionForm /> },
      { path: 'winneraddress', element: <EventWinnerAddress /> }
    ]
  }
]

export default EventRoutes
