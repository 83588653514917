import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Lnb from '../../components/Lnb';
import CountryCode from '../../components/CountryCode';
import Certified from '../../components/Certified';
import api from 'common/api';
import * as config from 'common/config';
import '../../assets/styles/member.scss';

export default function MemberAuth() {
  const navigate = useNavigate();
  const phoneRef = useRef();

  // input value 값
  const [inputValue, setInputValue] = useState({
    phone: '',
    countryCode: '82',
  });

  // 유효성 검사 상태 - true(통과) / false(통과X)
  const [validStatus, setValidStatus] = useState({
    phone: false,
  });

  const [certifiedComplete, setCertifiedComplete] = useState(false);
  const [certifiedCodeShow, setCertifiedCodeShow] = useState(false);
  const [timerStart, setTimerStart] = useState(0);

  const checkCertUrl = config.AUTH_API_HOST + '/auth/v2/user/check-cert-number';

  // 휴대전화 체크
  const handlePhoneCheck = (e) => {
    // 숫자만 입력 가능
    let phoneRegExp = /^[0-9]*$/;

    if (!phoneRegExp.test(e.target.value)) {
      alert('숫자만 입력해주세요');
      phoneRef.current.focus();

      return false;
    }

    setInputValue({ ...inputValue, phone: e.target.value });

    if (e.target.value.length < 10) {
      setValidStatus({ ...validStatus, phone: false });

      return false;
    }

    setValidStatus({ ...validStatus, phone: true });

    return true;
  };

  const handleCertifiedComplete = (status) => {
    setCertifiedComplete(status);
  };

  // 국가 코드
  const handleCountryCode = (e) => {
    setInputValue({ ...inputValue, countryCode: e.target.value });
  };

  const getCertNumber = () => {
    api
      .post(
        config.AUTH_API_HOST +
          '/auth/v2/user/get-cert-number?cellphone=' +
          inputValue.phone +
          '&countryCode=' +
          inputValue.countryCode
      )
      .then((response) => {

        if (response.data.meta.code === 200) {
          alert('인증번호를 발송하였습니다.');
          setTimerStart((prevNum) => prevNum + 1);
          setCertifiedCodeShow(true);
        } else {
          alert(response.data.meta.message);
        }
      })
      .catch((e) => {
        alert(config.MESSAGE['common-error']);
      });
  };

  useEffect(() => {
    if (certifiedComplete) {
      navigate('/member/editinfo', {
        state: { authority: certifiedComplete },
      });
    }
  }, [certifiedComplete]);

  return (
    <div className="member-auth-col">
      <div className="inner-col">
        <div className="container mypage-sub">
          <Lnb />
          <section>
            <h2>회원정보 수정</h2>
            <div className="info">
              정보 보호를 위해 <strong>휴대전화 인증</strong>을 다시 확인합니다.
              <ul>
                <li>등록된 휴대폰번호로만 인증이 가능합니다.</li>
                <li>
                  휴대전화 번호가 변경된 경우 혹은 인증에 실패하는 경우야나두
                  고객센터 1600-0563으로 문의주시길 바랍니다.
                </li>
              </ul>
            </div>
            <div className="wrap">
              <dl className="phone-box">
                <dt>휴대폰 번호</dt>
                {/* <span className="sub-title">휴대전화</span> */}
                <dd>
                  <CountryCode handleCountryCode={handleCountryCode} />

                  <input
                    type="text"
                    maxLength="11"
                    placeholder="휴대전화 번호를 입력해주세요."
                    name="phone"
                    ref={phoneRef}
                    value={inputValue.phone}
                    onChange={handlePhoneCheck}
                    readOnly={certifiedComplete ? true : false}
                  />
                  <button
                    type="button"
                    className={
                      'btn btn-certified-code' +
                      (validStatus.phone ? ' active' : ' disabled')
                    }
                    disabled={validStatus.phone ? false : true}
                    onClick={getCertNumber}
                  >
                    {certifiedCodeShow ? (
                      <span>재전송</span>
                    ) : (
                      <span>인증번호</span>
                    )}
                  </button>
                </dd>
              </dl>
              <dl className="certified-box">
                <dt>인증번호</dt>
                <dd>
                  <Certified
                    type={'EXCLUSIVE'}
                    time={300}
                    timerStart={timerStart}
                    handleCertifiedComplete={handleCertifiedComplete}
                    reset={inputValue.findType}
                    checkCertUrl={checkCertUrl}
                  />
                </dd>
              </dl>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
