import CustomOrder from 'pages/cart/CustomOrder'
import DetailCart from 'pages/cart/Detail'
import ListCart from 'pages/cart/List'
import PromotionOrder from 'pages/cart/PromotionOrder'
import CancelOrder from 'pages/order/CancelOrder'
import ItemOrder from 'pages/order/ItemOrder'
import ListOrder from 'pages/order/ListOrder'
import PaymentComplete from 'pages/order/PaymentComplete'
import { Order } from 'router/OutletStore'

import PrivateRoute from 'common/PrivateRoute'

const OrderRoutes = [
  {
    path: 'mypage/order/*',
    element: (
      <PrivateRoute>
        <Order />
      </PrivateRoute>
    ),
    children: [
      { path: 'list/', element: <ListOrder /> },
      { path: 'detail/:currentSeq', element: <ItemOrder /> },
      { path: 'cancel/:currentSeq', element: <CancelOrder /> },
      { path: 'PaymentComplete', element: <PaymentComplete /> },
      { path: 'cart', element: <ListCart /> },
      { path: 'cart/promotion/:promotionPackageCode', element: <PromotionOrder /> },
      { path: 'cart/custom-order/:seq', element: <CustomOrder /> },
      { path: 'cart/detail/:orderNo', element: <DetailCart /> }
    ]
  }
]

export default OrderRoutes
