import React from 'react';
import { Link } from 'react-router-dom';

import styled, { ThemeProvider } from 'styled-components';
import theme from 'common/theme';

export default function TabBoard({ activeTab }) {
  return (
    <ThemeProvider theme={theme}>
      <TabMenuBox>
        <TabMenuList>
          <TabMenuItem className={activeTab === 'LIST' ? 'active' : null}>
            <Link to="/community/srr/list">스르르 학습지 Talk</Link>
          </TabMenuItem>
          <TabMenuItem className={activeTab === 'REVIEW' ? 'active' : null}>
            <Link to="/community/srr/review">학습지 후기</Link>
          </TabMenuItem>
        </TabMenuList>
      </TabMenuBox>
    </ThemeProvider>
  );
}
const TabMenuBox = styled.div`
  padding: 0 0 4.8rem;
  text-align: center;

  @media ${({ theme }) => theme.device.mobile} {
    padding: 10rem 0 5rem;
  }
`;

const TabMenuList = styled.ul`
  display: inline-flex;
  position: relative;
  border-radius: 12rem;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: 1px solid #28284b;
    border-radius: inherit;
  }
`;

const TabMenuItem = styled.li`
  position: relative;
  border-radius: inherit;

  &.active {
    a {
      font-weight: 700;
      color: #fff;
      background-color: #28284b;
    }
  }

  a {
    display: inline-block;
    height: 100%;
    padding: 1.4rem 3.4rem;
    font-weight: 500;
    font-size: 2rem;
    color: #28284b;
    border-radius: inherit;
    background-color: transparent;
    white-space: nowrap;
    box-sizing: border-box;
  }

  @media ${({ theme }) => theme.device.mobile} {
    a {
      padding: 1.6rem 3rem;
      font-size: 2.5rem;
    }
  }
`;
