import React, { useState } from 'react'

import cx from 'classnames'

import BtnMore from './BtnMore'
import FaqList from './FaqList'
import useGetFaqCategory from '../hooks/useGetFaqCategory'
import useGetFaqList from '../hooks/useGetFaqList'
import s from '../Service.module.scss'

// groupCode: {
//   "YANADOO_FAQ_GROUP": "야나두",
//   "YAFIT_FAQ_GROUP": "야핏사이클",
//   "B2B_FAQ_GROUP": "B2B"
// }

export default function SectionFaq() {
  const [tab, setTab] = useState({ yanadoo: true, yafit: false })
  const [cate, setCate] = useState('BEST')
  const groupCode = tab.yanadoo ? 'YANADOO_FAQ_GROUP' : 'YAFIT_FAQ_GROUP'
  const { category } = useGetFaqCategory(groupCode)
  const { faqList } = useGetFaqList({ cate, group: groupCode, page: 1, size: 5 })

  const handleTab = (key) => {
    setTab((prevTab) => ({
      yanadoo: key === 'yanadoo' ? !prevTab.yanadoo : false,
      yafit: key === 'yafit' ? !prevTab.yafit : false
    }))
    setCate('BEST')
  }

  return (
    <section className={s.csFaq}>
      <div className={s.csInner}>
        <h3>자주 묻는 질문</h3>
        <div className={s.csTabNav}>
          <button
            type="button"
            className={cx(s.csTabBtn, { [s.csTabActive]: tab.yanadoo })}
            onClick={() => handleTab('yanadoo')}
            disabled={tab.yanadoo}
          >
            야나두
          </button>
          <button
            type="button"
            className={cx(s.csTabBtn, { [s.csTabActive]: tab.yafit })}
            onClick={() => handleTab('yafit')}
            disabled={tab.yafit}
          >
            야핏 사이클
          </button>
        </div>
        <div className={s.csCategory}>
          {category.map((item, index) => (
            <button
              key={index}
              type="button"
              className={cx(s.csCateBtn, { [s.csCateActive]: item.code === cate })}
              onClick={() => setCate(item.code)}
            >
              <span>{item.value}</span>
            </button>
          ))}
        </div>
        <div className={s.csTabContent}>
          <FaqList list={faqList} />
          <div className={s.wrapBtnMore}>
            <BtnMore title="더보기" link="/service/faq" />
          </div>
        </div>
      </div>
    </section>
  )
}
