import { useState, useEffect, useCallback } from 'react'

const initialCategoryStats = {
  vocabulary: { amount: 0, correct: 0, score: 0, points: 0 },
  grammar: { amount: 0, correct: 0, score: 0, points: 0 },
  nuance: { amount: 0, correct: 0, score: 0, points: 0 },
  listening: { amount: 0, correct: 0, score: 0, points: 0 },
  speaking: { amount: 0, correct: 0, score: 0, points: 0 }
}

// 테스트 결과를 바탕으로 카테고리별 통계를 생성
export default function useSetCategoryStats(test) {
  const [categoryStats, setCategoryStats] = useState(initialCategoryStats)

  const assignCategoryStats = useCallback((category, score, isCorrect) => {
    setCategoryStats((prev) => ({
      ...prev,
      [category]: {
        amount: prev[category].amount + 1, // 항목별 문항 개수
        correct: prev[category].correct + (isCorrect ? 1 : 0), // 항목별 맞춘 개수
        score: prev[category].score + (isCorrect ? score : 0), // 항목별 획득 점수
        points: prev[category].points + score // 항목별 총 배점
      }
    }))
  }, [])

  const resetCategory = () => {
    setCategoryStats(initialCategoryStats)
  }

  useEffect(() => {
    if (!test) return

    resetCategory()

    const data = Object.values(test)

    for (let item of data) {
      const { category, score, isCorrect } = item
      assignCategoryStats(category, score, isCorrect)
    }
  }, [test, assignCategoryStats])

  return { categoryStats }
}
