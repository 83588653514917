import React, { useEffect, useState } from 'react';
import '../../assets/styles/modal.scss';
import styled, { ThemeProvider } from 'styled-components';
import theme from 'common/theme';

export default function ModalApplyCoupon({
  title,
  couponName,
  couponPackage,
  btnClose,
  handleCloseModal,
  couponUrl,
  isUrlButton,
}) {
  // 쿠폰 적용 상품 리스트
  const [couponPackageNameList, setCouponPackageNameList] = useState([]);
  const [couponUrlList, setCouponUrlList] = useState('');

  useEffect(() => {
    setCouponPackageNameList(
      couponPackage &&
        couponPackage.map((item) => {
          return item.packageName;
        })
    );
    setCouponUrlList(couponUrl);
  }, []);

  return (
    <>
      <ThemeProvider theme={theme}>
        <div className="modal-wrap apply-coupon">
          <div className="dimmed" onClick={handleCloseModal}></div>
          <div className="modal-inner">
            {btnClose && (
              <button type="button" className="btn-modal-close" onClick={handleCloseModal}>
                <i className="blind">닫기</i>
              </button>
            )}
            <div className="modal-header">
              <strong>{title}</strong>
            </div>
            <div className="modal-content">
              <CouponBox>
                {couponName && <strong>{couponName}</strong>}
                {couponPackageNameList && (
                  <div>
                    {couponPackageNameList.map((packageName, idx) => {
                      return <p key={idx}>{packageName}</p>;
                    })}
                  </div>
                )}
                {couponUrlList && isUrlButton ? (
                  <UrlBotton onClick={() => window.open(`${couponUrl}`, '_blank')}>
                    상품 보러가기
                  </UrlBotton>
                ) : null}
              </CouponBox>
            </div>
          </div>
        </div>
      </ThemeProvider>
    </>
  );
}

const CouponBox = styled.div`
  padding: 4rem 3.2rem;
  height: auto;
  strong {
    font-weight: 700;
    font-size: 2.2rem;
  }

  div {
    margin-top: 1.8rem;
    min-height: 7rem;
    p {
      font-size: 1.9rem;

      & + p {
        margin-top: 1.2rem;
      }
    }
  }
  @media ${({ theme }) => theme.device.mobile} {
    padding: 5.5rem 4.2rem;

    strong {
      font-size: 3.6rem;
    }

    div {
      margin-top: 3.2rem;
      min-height: 21rem;
      p {
        font-size: 3rem;

        & + p {
          margin-top: 1.8rem;
        }
      }
    }
  }
`;
const UrlBotton = styled.button`
  width: 100%;
  max-width: 100%;
  height: 6.4rem;
  margin-top: 2.9rem;
  font-weight: 400;
  font-size: 1.7rem;
  border-radius: 0.5rem;
  cursor: pointer;
  color: #000;
  border-color: #ffb900;
  background-color: #ffb900;
  @media ${({ theme }) => theme.device.mobile} {
    font-size: 2.7rem;
    height: 10rem;
    margin-top: 6rem;
  }
`;
