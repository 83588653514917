import React, { useState, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import useSubscription from 'hooks/useSubscription'
import ModalPortal from 'ModalPortal'
import AuthService from 'services/authService'

import api from 'common/api'
import * as config from 'common/config'
import { setCookie, getCookie } from 'common/Cookie'

import ModalMemberType from '../modal/ModalMemberType'
import NoContent from '../NoContent'

export default function FacebookAuth() {
  const navigate = useNavigate()
  const accessUserId = new URL(window.location.href).searchParams.get('id')
  const error = new URL(window.location.href).searchParams.get('error')
  const [encryptUserId, setEncryptUserId] = useState(null)
  const [modalopenSocialType, setModalopenSocialType] = useState(false)

  const signType = sessionStorage.getItem('signType')
  const { subScriptionModal, postSubscription } = useSubscription()

  const handleOpenModalMemberType = useCallback((e) => {
    document.body.classList.add('modal-open')
    setModalopenSocialType(true)
  }, [])

  const handleCloseModalMemberType = useCallback((e) => {
    document.body.classList.remove('modal-open')
    setModalopenSocialType(false)
    // historyUrl 쿠키값이 저장되어 있다면 해당 URL로 페이지 이동.
    if (getCookie('historyUrl') !== '') {
      setCookie('orderLoginSuccess', 'Y')
      if (window.location.href !== getCookie('historyUrl')) window.location.href = getCookie('historyUrl')
    } else {
      navigate('/')
    }
  }, [])

  const getEncrypToken = () => {
    api
      .get(config.AUTH_API_HOST + '/auth/v2/sign-in/encrypt-sns-token?snsToken=' + accessUserId)
      .then((response) => {
        setEncryptUserId(response.data.data.encryptSnsToken)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const handleLoginFacebook = async () => {
    try {
      const response = await api.post(config.AUTH_API_HOST + '/auth/v2/sign-in/social/get-token?deviceType=0' + '&snsToken=' + encryptUserId + '&snsType=2')

      if (response.data.meta.code !== 200) {
        handleOpenModalMemberType()

        return false
      }
      if (signType === 'uPlus') {
        const data = { userSeq: response.data.data.user.userSeq }

        // subscription post
        await postSubscription(data)

        AuthService.setUserInfo(response.data.data)
        return
      }

      AuthService.setUserInfo(response.data.data)
      AuthService.biginUserInfo()
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (accessUserId) {
      getEncrypToken()
    }
  }, [accessUserId])

  useEffect(() => {
    if (encryptUserId) {
      handleLoginFacebook()
    }
  }, [encryptUserId])

  useEffect(() => {
    if (error) {
      navigate('/login')
    }
  }, [])

  return (
    <>
      <div className="social-login facebook">
        <NoContent titleMsg={''} />
      </div>
      {modalopenSocialType ? (
        <ModalPortal>
          <ModalMemberType title={'회원가입'} btnClose handleCloseModal={handleCloseModalMemberType} snsType={2} accessToken={accessUserId} encryptToken={encryptUserId} />
        </ModalPortal>
      ) : null}

      {subScriptionModal}
    </>
  )
}
