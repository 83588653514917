import React, { useState, useEffect } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'

import styled, { ThemeProvider } from 'styled-components'
import { convertHtmlCode } from 'utils/convertHtmlCode'

import api from 'common/api'
import '../../assets/styles/notice.scss'
import theme from 'common/theme'
import MobileHeader from 'components/common/MobileHeader'

export default function DetailNotice() {
  const navigate = useNavigate()
  const { currentSeq } = useParams()
  const [noticeData, setNoticeData] = useState([])

  const getNoticeData = () => {
    api
      .get(`/v2/notice/${currentSeq}`)
      .then((response) => {
        const dataArr = { ...response.data.data, title: convertHtmlCode(response.data.data.title) }
        setNoticeData(dataArr)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  useEffect(() => {
    getNoticeData()
  }, [currentSeq])

  return (
    <ThemeProvider theme={theme}>
      <div className="notice-col">
        <div className="inner-col">
          <div className="container mypage-sub">
            <MobileHeader title="공지사항" onClick={() => (document.referrer === '' ? navigate('/service/notice/1') : navigate(-1))}/>
            <section className="list-detail-col">
              <div className="title-col">
                <strong>공지사항</strong>
              </div>
              <div className="detail-title-area">
                <div className="tit-flex-form">
                  <strong className="sub-tit black">{noticeData.title}</strong>
                  <div className="info">
                    <span className="date">{noticeData.registDate}</span>
                  </div>
                </div>
              </div>
              {noticeData.serviceNoticeAttachFiles && noticeData.serviceNoticeAttachFiles.length > 0 && (
                <div className="detail-attached-files">
                  <span>첨부파일</span>
                  <ul>
                    {noticeData.serviceNoticeAttachFiles.map((item, index) => {
                      return (
                        <li key={index}>
                          <a href={item.attachFilePath}>{item.attachFileName}</a>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              )}
              <div className="detail-content">
                <div className="detail-question">
                  <PreNotice dangerouslySetInnerHTML={{ __html: noticeData.contents }}></PreNotice>
                </div>
              </div>

              <div className="list-btn-col">
                <div className="inner-col">
                  <Link
                    to={{
                      pathname: '/service/notice/1'
                    }}
                    className="btn btn-go-list"
                  >
                    목록보기
                  </Link>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </ThemeProvider>
  )
}
const PreNotice = styled.pre`
  font-size: 1.6rem;
  font-family: 'Inter', 'NotoSansCJKkr', sans-serif;
  line-height: 1.3 !important;
  white-space: pre-wrap;
`
