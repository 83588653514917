import useLevelTestAdvancedExamStore from 'store/levelTestAdvanced/useLevelTestAdvancedExamStore'

import styles from '../LevelTestAdvancedExam.module.scss'

export default function ProgressBar() {
  const questionIndex = useLevelTestAdvancedExamStore((state) => state.questionIndex)
  return (
    <div className={styles.progress}>
      <p>{questionIndex}/20</p>
      <div className={styles.bar}>
        <span style={{ width: `${questionIndex * 5}%` }}></span>
      </div>
    </div>
  )
}
