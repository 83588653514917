import React, { useState, useEffect } from 'react';
import '../../assets/styles/ProductCategory.scss';
import { useMediaQuery } from 'react-responsive';
import { Swiper, SwiperSlide } from 'swiper/react';
import CategoryData from '../../data/CategoryData.json';
import * as main from '../../common/main';
import { BrowserRouter, Link, useLocation, useParams } from 'react-router-dom';
import ScrollToTop from '../../ScrollToTop';

export default function List() {
  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 1024 });

    return isMobile ? children : null;
  };
  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1024 });

    return isDesktop ? children : null;
  };
  // const [categoryItems, setCategoryItems] = useState();
  // const getCategoryItemsFromJson = () => {
  //   setCategoryItems(CategoryData)
  // }
  const { state } = useLocation();
  // const activeTabMenu = state && state.tabMenu;
  // const [tabMenu, setTabMenu] = useState(activeTabMenu ? activeTabMenu : 1);
  const pageNumb = new URL(window.location.href).searchParams.get('type');

  // const handleTabShow = (id) => {
  //   setTabMenu(id);
  // };

  useEffect(() => {
    // getCategoryItemsFromJson();
    console.log(CategoryData);
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/*<ScrollToTop/>*/}
      <section className="product-nav-col">
        <div className="product-tab">
          <strong className="title">더 많은 강의 보기</strong>
          <ul>
            <li className={pageNumb == 1 ? 'active' : null}>
              <Link className="btn-product" to="/categoryList?type=1">
                학습 레벨
              </Link>
            </li>
            <li className={pageNumb == 2 ? 'active' : null}>
              <Link className="btn-product" to="/categoryList?type=2">
                판매 가격
              </Link>
            </li>
            <li className={pageNumb == 3 ? 'active' : null}>
              <Link className="btn-product" to="/categoryList?type=3">
                수강 기간
              </Link>
            </li>
            <li className={pageNumb == 4 ? 'active' : null}>
              <Link className="btn-product" to="/categoryList?type=4">
                학습 방법
              </Link>
            </li>
          </ul>
        </div>
        <Desktop>
          {/*학습 레벨*/}
          <div
            className={'product-tab-cont' + (pageNumb == 1 ? ' active' : '')}
          >
            <div className="product-list">
              <div className="inner-col">
                <h3>종합 코스</h3>
                <ul>
                  {CategoryData.filter((item) => {
                    return item.classLevelInfo == parseInt(0);
                  }).map((abc, index) => {
                    return (
                      <li key={index}>
                        <Link to={abc.linkPath}>
                          <div
                            className="thumb"
                            style={{
                              backgroundImage: `url(${abc.imagePath1})`,
                            }}
                          />
                          <div className="contents">
                            <strong>{abc.subTitle}</strong>
                            <p>{abc.desc}</p>
                            <button>바로가기</button>
                            <div className="test-info">
                              <div>
                                판매가격(12개월) :{' '}
                                {abc.exceptOptionMembershipSalePrice / 12}
                              </div>
                              <div>학습레벨 : {abc.classLevelInfo} </div>
                              <div>수강기간(개월) : {abc.periodInfo / 30}</div>
                              <div>학습방법 : {abc.classType}</div>
                            </div>
                          </div>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className="product-list">
              <div className="inner-col">
                <h3>초급 코스</h3>
                <ul>
                  {CategoryData.filter((item) => {
                    return item.classLevelInfo == parseInt(1);
                  }).map((abc, index) => {
                    return (
                      <li key={index}>
                        <Link to={abc.linkPath}>
                          <div
                            className="thumb"
                            style={{
                              backgroundImage: `url(${abc.imagePath1})`,
                            }}
                          />
                          <div className="contents">
                            <strong>{abc.subTitle}</strong>
                            <p>{abc.desc}</p>
                            <button>바로가기</button>
                            <div className="test-info">
                              <div>
                                판매가격(12개월) :{' '}
                                {abc.exceptOptionMembershipSalePrice / 12}
                              </div>
                              <div>학습레벨 : {abc.classLevelInfo} </div>
                              <div>수강기간(개월) : {abc.periodInfo / 30}</div>
                              <div>학습방법 : {abc.classType}</div>
                            </div>
                          </div>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className="product-list">
              <div className="inner-col">
                <h3>초중급 코스</h3>
                <ul>
                  {CategoryData.filter((item) => {
                    return item.classLevelInfo == parseInt(2);
                  }).map((abc, index) => {
                    return (
                      <li key={index}>
                        <Link to={abc.linkPath}>
                          <div
                            className="thumb"
                            style={{
                              backgroundImage: `url(${abc.imagePath1})`,
                            }}
                          />
                          <div className="contents">
                            <strong>{abc.subTitle}</strong>
                            <p>{abc.desc}</p>
                            <button>바로가기</button>
                            <div className="test-info">
                              <div>
                                판매가격(12개월) :{' '}
                                {abc.exceptOptionMembershipSalePrice / 12}
                              </div>
                              <div>학습레벨 : {abc.classLevelInfo} </div>
                              <div>수강기간(개월) : {abc.periodInfo / 30}</div>
                              <div>학습방법 : {abc.classType}</div>
                            </div>
                          </div>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className="product-list">
              <div className="inner-col">
                <h3>중고급 코스</h3>
                <ul>
                  {CategoryData.filter((item) => {
                    return item.classLevelInfo == parseInt(3);
                  }).map((abc, index) => {
                    return (
                      <li key={index}>
                        <Link to={abc.linkPath}>
                          <div
                            className="thumb"
                            style={{
                              backgroundImage: `url(${abc.imagePath1})`,
                            }}
                          />
                          <div className="contents">
                            <strong>{abc.subTitle}</strong>
                            <p>{abc.desc}</p>
                            <button>바로가기</button>
                            <div className="test-info">
                              <div>
                                판매가격(12개월) :{' '}
                                {abc.exceptOptionMembershipSalePrice / 12}
                              </div>
                              <div>학습레벨 : {abc.classLevelInfo} </div>
                              <div>수강기간(개월) : {abc.periodInfo / 30}</div>
                              <div>학습방법 : {abc.classType}</div>
                            </div>
                          </div>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
          {/*판매 가격/12*/}
          <div
            className={'product-tab-cont' + (pageNumb == 2 ? ' active' : '')}
          >
            <div className="product-list">
              <div className="inner-col">
                <h3>~월 9,990원</h3>
                <ul>
                  {CategoryData.filter((item) => {
                    return (
                      item.exceptOptionMembershipSalePrice / 12 <=
                      parseInt(9990)
                    );
                  }).map((abc, index) => {
                    return (
                      <li key={index}>
                        <Link to={abc.linkPath}>
                          <div
                            className="thumb"
                            style={{
                              backgroundImage: `url(${abc.imagePath1})`,
                            }}
                          />
                          <div className="contents">
                            <strong>{abc.subTitle}</strong>
                            <p>{abc.desc}</p>
                            <button>바로가기</button>
                            <div className="test-info">
                              <div>
                                판매가격(12개월) :{' '}
                                {abc.exceptOptionMembershipSalePrice / 12}
                              </div>
                              <div>학습레벨 : {abc.classLevelInfo} </div>
                              <div>수강기간(개월) : {abc.periodInfo / 30}</div>
                              <div>학습방법 : {abc.classType}</div>
                            </div>
                          </div>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className="product-list">
              <div className="inner-col">
                <h3>월 10,000원~</h3>
                <ul>
                  {CategoryData.filter((item) => {
                    return (
                      item.exceptOptionMembershipSalePrice / 12 >=
                        parseInt(10000) &&
                      item.exceptOptionMembershipSalePrice / 12 <=
                        parseInt(19999)
                    );
                  }).map((abc, index) => {
                    return (
                      <li key={index}>
                        <Link to={abc.linkPath}>
                          <div
                            className="thumb"
                            style={{
                              backgroundImage: `url(${abc.imagePath1})`,
                            }}
                          />
                          <div className="contents">
                            <strong>{abc.subTitle}</strong>
                            <p>{abc.desc}</p>
                            <button>바로가기</button>
                            <div className="test-info">
                              <div>
                                판매가격(12개월) :{' '}
                                {abc.exceptOptionMembershipSalePrice / 12}
                              </div>
                              <div>학습레벨 : {abc.classLevelInfo} </div>
                              <div>수강기간(개월) : {abc.periodInfo / 30}</div>
                              <div>학습방법 : {abc.classType}</div>
                            </div>
                          </div>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className="product-list">
              <div className="inner-col">
                <h3>월 20,000원~</h3>
                <ul>
                  {CategoryData.filter((item) => {
                    return (
                      item.exceptOptionMembershipSalePrice / 12 >=
                        parseInt(20000) &&
                      item.exceptOptionMembershipSalePrice / 12 <=
                        parseInt(59999)
                    );
                  }).map((abc, index) => {
                    return (
                      <li key={index}>
                        <Link to={abc.linkPath}>
                          <div
                            className="thumb"
                            style={{
                              backgroundImage: `url(${abc.imagePath1})`,
                            }}
                          />
                          <div className="contents">
                            <strong>{abc.subTitle}</strong>
                            <p>{abc.desc}</p>
                            <button>바로가기</button>
                            <div className="test-info">
                              <div>
                                판매가격(12개월) :{' '}
                                {abc.exceptOptionMembershipSalePrice / 12}
                              </div>
                              <div>학습레벨 : {abc.classLevelInfo} </div>
                              <div>수강기간(개월) : {abc.periodInfo / 30}</div>
                              <div>학습방법 : {abc.classType}</div>
                            </div>
                          </div>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className="product-list">
              <div className="inner-col">
                <h3>월 60,000원~</h3>
                <ul>
                  {CategoryData.filter((item) => {
                    return (
                      item.exceptOptionMembershipSalePrice / 12 >=
                      parseInt(60000)
                    );
                  }).map((abc, index) => {
                    return (
                      <li key={index}>
                        <Link to={abc.linkPath}>
                          <div
                            className="thumb"
                            style={{
                              backgroundImage: `url(${abc.imagePath1})`,
                            }}
                          />
                          <div className="contents">
                            <strong>{abc.subTitle}</strong>
                            <p>{abc.desc}</p>
                            <button>바로가기</button>
                            <div className="test-info">
                              <div>
                                판매가격(12개월) :{' '}
                                {abc.exceptOptionMembershipSalePrice / 12}
                              </div>
                              <div>학습레벨 : {abc.classLevelInfo} </div>
                              <div>수강기간(개월) : {abc.periodInfo / 30}</div>
                              <div>학습방법 : {abc.classType}</div>
                            </div>
                          </div>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
          {/*수강 기간*/}
          <div
            className={'product-tab-cont' + (pageNumb == 3 ? ' active' : '')}
          >
            <div className="product-list">
              <div className="inner-col">
                <h3>3개월</h3>
                <ul>
                  {CategoryData.filter((item) => {
                    return item.periodInfo / 30 <= parseInt(3);
                  }).map((abc, index) => {
                    return (
                      <li key={index}>
                        <Link to={abc.linkPath}>
                          <div
                            className="thumb"
                            style={{
                              backgroundImage: `url(${abc.imagePath1})`,
                            }}
                          />
                          <div className="contents">
                            <strong>{abc.subTitle}</strong>
                            <p>{abc.desc}</p>
                            <button>바로가기</button>
                            <div className="test-info">
                              <div>
                                판매가격(12개월) :{' '}
                                {abc.exceptOptionMembershipSalePrice / 12}
                              </div>
                              <div>학습레벨 : {abc.classLevelInfo} </div>
                              <div>수강기간(개월) : {abc.periodInfo / 30}</div>
                              <div>학습방법 : {abc.classType}</div>
                            </div>
                          </div>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className="product-list">
              <div className="inner-col">
                <h3>6개월</h3>
                <ul>
                  {CategoryData.filter((item) => {
                    return (
                      item.periodInfo / 30 >= parseInt(4) &&
                      item.periodInfo / 30 <= parseInt(6)
                    );
                  }).map((abc, index) => {
                    return (
                      <li key={index}>
                        <Link to={abc.linkPath}>
                          <div
                            className="thumb"
                            style={{
                              backgroundImage: `url(${abc.imagePath1})`,
                            }}
                          />
                          <div className="contents">
                            <strong>{abc.subTitle}</strong>
                            <p>{abc.desc}</p>
                            <button>바로가기</button>
                            <div className="test-info">
                              <div>
                                판매가격(12개월) :{' '}
                                {abc.exceptOptionMembershipSalePrice / 12}
                              </div>
                              <div>학습레벨 : {abc.classLevelInfo} </div>
                              <div>수강기간(개월) : {abc.periodInfo / 30}</div>
                              <div>학습방법 : {abc.classType}</div>
                            </div>
                          </div>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className="product-list">
              <div className="inner-col">
                <h3>12개월</h3>
                <ul>
                  {CategoryData.filter((item) => {
                    return (
                      item.periodInfo / 30 >= parseInt(7) &&
                      item.periodInfo / 30 <= parseInt(13)
                    );
                  }).map((abc, index) => {
                    return (
                      <li key={index}>
                        <Link to={abc.linkPath}>
                          <div
                            className="thumb"
                            style={{
                              backgroundImage: `url(${abc.imagePath1})`,
                            }}
                          />
                          <div className="contents">
                            <strong>{abc.subTitle}</strong>
                            <p>{abc.desc}</p>
                            <button>바로가기</button>
                            <div className="test-info">
                              <div>
                                판매가격(12개월) :{' '}
                                {abc.exceptOptionMembershipSalePrice / 12}
                              </div>
                              <div>학습레벨 : {abc.classLevelInfo} </div>
                              <div>수강기간(개월) : {abc.periodInfo / 30}</div>
                              <div>학습방법 : {abc.classType}</div>
                            </div>
                          </div>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
          {/*학습 방법*/}
          <div
            className={'product-tab-cont' + (pageNumb == 4 ? ' active' : '')}
          >
            <div className="product-list">
              <div className="inner-col">
                <h3>케어 받으며</h3>
                <ul>
                  {CategoryData.filter((item) => {
                    return item.classType == parseInt(1);
                  }).map((abc, index) => {
                    return (
                      <li key={index}>
                        <Link to={abc.linkPath}>
                          <div
                            className="thumb"
                            style={{
                              backgroundImage: `url(${abc.imagePath1})`,
                            }}
                          />
                          <div className="contents">
                            <strong>{abc.subTitle}</strong>
                            <p>{abc.desc}</p>
                            <button>바로가기</button>
                            <div className="test-info">
                              <div>
                                판매가격(12개월) :{' '}
                                {abc.exceptOptionMembershipSalePrice / 12}
                              </div>
                              <div>학습레벨 : {abc.classLevelInfo} </div>
                              <div>수강기간(개월) : {abc.periodInfo / 30}</div>
                              <div>학습방법 : {abc.classType}</div>
                            </div>
                          </div>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className="product-list">
              <div className="inner-col">
                <h3>테마별 선택</h3>
                <ul>
                  {CategoryData.filter((item) => {
                    return item.classType == parseInt(2);
                  }).map((abc, index) => {
                    return (
                      <li key={index}>
                        <Link to={abc.linkPath}>
                          <div
                            className="thumb"
                            style={{
                              backgroundImage: `url(${abc.imagePath1})`,
                            }}
                          />
                          <div className="contents">
                            <strong>{abc.subTitle}</strong>
                            <p>{abc.desc}</p>
                            <button>바로가기</button>
                            <div className="test-info">
                              <div>
                                판매가격(12개월) :{' '}
                                {abc.exceptOptionMembershipSalePrice / 12}
                              </div>
                              <div>학습레벨 : {abc.classLevelInfo} </div>
                              <div>수강기간(개월) : {abc.periodInfo / 30}</div>
                              <div>학습방법 : {abc.classType}</div>
                            </div>
                          </div>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </Desktop>
        <Mobile>
          {/*학습 레벨*/}
          <div
            className={'product-tab-cont' + (pageNumb == 1 ? ' active' : '')}
          >
            <div className="product-list-slide">
              <h3>종합 코스</h3>
              <Swiper
                slidesPerView={1.2}
                spaceBetween={20}
                className="mySwiper"
              >
                {CategoryData.filter((item) => {
                  return item.classLevelInfo == parseInt(0);
                }).map((abc, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <Link to={abc.linkPath}>
                        <div
                          className="thumb"
                          style={{ backgroundImage: `url(${abc.imagePath1})` }}
                        />
                        <div className="contents">
                          <strong>{abc.subTitle}</strong>
                          <p>{abc.desc}</p>
                          <button>바로가기</button>
                          <div className="test-info">
                            <div>
                              판매가격(12개월) :{' '}
                              {abc.exceptOptionMembershipSalePrice / 12}
                            </div>
                            <div>학습레벨 : {abc.classLevelInfo} </div>
                            <div>수강기간(개월) : {abc.periodInfo / 30}</div>
                            <div>학습방법 : {abc.classType}</div>
                          </div>
                        </div>
                      </Link>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
            <div className="product-list-slide">
              <h3>초급 코스</h3>
              <Swiper
                slidesPerView={1.2}
                spaceBetween={20}
                className="mySwiper"
              >
                {CategoryData.filter((item) => {
                  return item.classLevelInfo == parseInt(1);
                }).map((abc, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <Link to={abc.linkPath}>
                        <div
                          className="thumb"
                          style={{ backgroundImage: `url(${abc.imagePath1})` }}
                        />
                        <div className="contents">
                          <strong>{abc.subTitle}</strong>
                          <p>{abc.desc}</p>
                          <button>바로가기</button>
                          <div className="test-info">
                            <div>
                              판매가격(12개월) :{' '}
                              {abc.exceptOptionMembershipSalePrice / 12}
                            </div>
                            <div>학습레벨 : {abc.classLevelInfo} </div>
                            <div>수강기간(개월) : {abc.periodInfo / 30}</div>
                            <div>학습방법 : {abc.classType}</div>
                          </div>
                        </div>
                      </Link>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
            <div className="product-list-slide">
              <h3>초중급 코스</h3>
              <Swiper
                slidesPerView={1.2}
                spaceBetween={20}
                className="mySwiper"
              >
                {CategoryData.filter((item) => {
                  return item.classLevelInfo == parseInt(2);
                }).map((abc, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <Link to={abc.linkPath}>
                        <div
                          className="thumb"
                          style={{ backgroundImage: `url(${abc.imagePath1})` }}
                        />
                        <div className="contents">
                          <strong>{abc.subTitle}</strong>
                          <p>{abc.desc}</p>
                          <button>바로가기</button>
                          <div className="test-info">
                            <div>
                              판매가격(12개월) :{' '}
                              {abc.exceptOptionMembershipSalePrice / 12}
                            </div>
                            <div>학습레벨 : {abc.classLevelInfo} </div>
                            <div>수강기간(개월) : {abc.periodInfo / 30}</div>
                            <div>학습방법 : {abc.classType}</div>
                          </div>
                        </div>
                      </Link>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
            <div className="product-list-slide">
              <h3>중고급 코스</h3>
              <Swiper
                slidesPerView={1.2}
                spaceBetween={20}
                className="mySwiper"
              >
                {CategoryData.filter((item) => {
                  return item.classLevelInfo == parseInt(3);
                }).map((abc, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <Link to={abc.linkPath}>
                        <div
                          className="thumb"
                          style={{ backgroundImage: `url(${abc.imagePath1})` }}
                        />
                        <div className="contents">
                          <strong>{abc.subTitle}</strong>
                          <p>{abc.desc}</p>
                          <button>바로가기</button>
                          <div className="test-info">
                            <div>
                              판매가격(12개월) :{' '}
                              {abc.exceptOptionMembershipSalePrice / 12}
                            </div>
                            <div>학습레벨 : {abc.classLevelInfo} </div>
                            <div>수강기간(개월) : {abc.periodInfo / 30}</div>
                            <div>학습방법 : {abc.classType}</div>
                          </div>
                        </div>
                      </Link>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </div>
          {/*판매 가격/12*/}
          <div
            className={'product-tab-cont' + (pageNumb == 2 ? ' active' : '')}
          >
            <div className="product-list-slide">
              <h3>~ 월 9,900원</h3>
              <Swiper
                slidesPerView={1.2}
                spaceBetween={20}
                className="mySwiper"
              >
                {CategoryData.filter((item) => {
                  return (
                    item.exceptOptionMembershipSalePrice / 12 <= parseInt(9990)
                  );
                }).map((abc, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <Link to={abc.linkPath}>
                        <div
                          className="thumb"
                          style={{ backgroundImage: `url(${abc.imagePath1})` }}
                        />
                        <div className="contents">
                          <strong>{abc.subTitle}</strong>
                          <p>{abc.desc}</p>
                          <button>바로가기</button>
                          <div className="test-info">
                            <div>
                              판매가격(12개월) :{' '}
                              {abc.exceptOptionMembershipSalePrice / 12}
                            </div>
                            <div>학습레벨 : {abc.classLevelInfo} </div>
                            <div>수강기간(개월) : {abc.periodInfo / 30}</div>
                            <div>학습방법 : {abc.classType}</div>
                          </div>
                        </div>
                      </Link>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
            <div className="product-list-slide">
              <h3>월 10,000원~</h3>
              <Swiper
                slidesPerView={1.2}
                spaceBetween={20}
                className="mySwiper"
              >
                {CategoryData.filter((item) => {
                  return (
                    item.exceptOptionMembershipSalePrice / 12 >=
                      parseInt(10000) &&
                    item.exceptOptionMembershipSalePrice / 12 <= parseInt(19999)
                  );
                }).map((abc, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <Link to={abc.linkPath}>
                        <div
                          className="thumb"
                          style={{ backgroundImage: `url(${abc.imagePath1})` }}
                        />
                        <div className="contents">
                          <strong>{abc.subTitle}</strong>
                          <p>{abc.desc}</p>
                          <button>바로가기</button>
                          <div className="test-info">
                            <div>
                              판매가격(12개월) :{' '}
                              {abc.exceptOptionMembershipSalePrice / 12}
                            </div>
                            <div>학습레벨 : {abc.classLevelInfo} </div>
                            <div>수강기간(개월) : {abc.periodInfo / 30}</div>
                            <div>학습방법 : {abc.classType}</div>
                          </div>
                        </div>
                      </Link>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
            <div className="product-list-slide">
              <h3>월 20,000원~</h3>
              <Swiper
                slidesPerView={1.2}
                spaceBetween={20}
                className="mySwiper"
              >
                {CategoryData.filter((item) => {
                  return (
                    item.exceptOptionMembershipSalePrice / 12 >=
                      parseInt(20000) &&
                    item.exceptOptionMembershipSalePrice / 12 <= parseInt(59999)
                  );
                }).map((abc, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <Link to={abc.linkPath}>
                        <div
                          className="thumb"
                          style={{ backgroundImage: `url(${abc.imagePath1})` }}
                        />
                        <div className="contents">
                          <strong>{abc.subTitle}</strong>
                          <p>{abc.desc}</p>
                          <button>바로가기</button>
                          <div className="test-info">
                            <div>
                              판매가격(12개월) :{' '}
                              {abc.exceptOptionMembershipSalePrice / 12}
                            </div>
                            <div>학습레벨 : {abc.classLevelInfo} </div>
                            <div>수강기간(개월) : {abc.periodInfo / 30}</div>
                            <div>학습방법 : {abc.classType}</div>
                          </div>
                        </div>
                      </Link>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
            <div className="product-list-slide">
              <h3>월 60,000원~</h3>
              <Swiper
                slidesPerView={1.2}
                spaceBetween={20}
                className="mySwiper"
              >
                {CategoryData.filter((item) => {
                  return (
                    item.exceptOptionMembershipSalePrice / 12 >= parseInt(60000)
                  );
                }).map((abc, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <Link to={abc.linkPath}>
                        <div
                          className="thumb"
                          style={{ backgroundImage: `url(${abc.imagePath1})` }}
                        />
                        <div className="contents">
                          <strong>{abc.subTitle}</strong>
                          <p>{abc.desc}</p>
                          <button>바로가기</button>
                          <div className="test-info">
                            <div>
                              판매가격(12개월) :{' '}
                              {abc.exceptOptionMembershipSalePrice / 12}
                            </div>
                            <div>학습레벨 : {abc.classLevelInfo} </div>
                            <div>수강기간(개월) : {abc.periodInfo / 30}</div>
                            <div>학습방법 : {abc.classType}</div>
                          </div>
                        </div>
                      </Link>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </div>
          {/*수강 기간*/}
          <div
            className={'product-tab-cont' + (pageNumb == 3 ? ' active' : '')}
          >
            <div className="product-list-slide">
              <h3>3개월</h3>
              <Swiper
                slidesPerView={1.2}
                spaceBetween={20}
                className="mySwiper"
              >
                {CategoryData.filter((item) => {
                  return item.periodInfo / 30 <= parseInt(3);
                }).map((abc, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <Link to={abc.linkPath}>
                        <div
                          className="thumb"
                          style={{ backgroundImage: `url(${abc.imagePath1})` }}
                        />
                        <div className="contents">
                          <strong>{abc.subTitle}</strong>
                          <p>{abc.desc}</p>
                          <button>바로가기</button>
                          <div className="test-info">
                            <div>
                              판매가격(12개월) :{' '}
                              {abc.exceptOptionMembershipSalePrice / 12}
                            </div>
                            <div>학습레벨 : {abc.classLevelInfo} </div>
                            <div>수강기간(개월) : {abc.periodInfo / 30}</div>
                            <div>학습방법 : {abc.classType}</div>
                          </div>
                        </div>
                      </Link>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
            <div className="product-list-slide">
              <h3>6개월</h3>
              <Swiper
                slidesPerView={1.2}
                spaceBetween={20}
                className="mySwiper"
              >
                {CategoryData.filter((item) => {
                  return (
                    item.periodInfo / 30 >= parseInt(4) &&
                    item.periodInfo / 30 <= parseInt(6)
                  );
                }).map((abc, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <Link to={abc.linkPath}>
                        <div
                          className="thumb"
                          style={{ backgroundImage: `url(${abc.imagePath1})` }}
                        />
                        <div className="contents">
                          <strong>{abc.subTitle}</strong>
                          <p>{abc.desc}</p>
                          <button>바로가기</button>
                          <div className="test-info">
                            <div>
                              판매가격(12개월) :{' '}
                              {abc.exceptOptionMembershipSalePrice / 12}
                            </div>
                            <div>학습레벨 : {abc.classLevelInfo} </div>
                            <div>수강기간(개월) : {abc.periodInfo / 30}</div>
                            <div>학습방법 : {abc.classType}</div>
                          </div>
                        </div>
                      </Link>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
            <div className="product-list-slide">
              <h3>12개월</h3>
              <Swiper
                slidesPerView={1.2}
                spaceBetween={20}
                className="mySwiper"
              >
                {CategoryData.filter((item) => {
                  return (
                    item.periodInfo / 30 >= parseInt(7) &&
                    item.periodInfo / 30 <= parseInt(13)
                  );
                }).map((abc, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <Link to={abc.linkPath}>
                        <div
                          className="thumb"
                          style={{ backgroundImage: `url(${abc.imagePath1})` }}
                        />
                        <div className="contents">
                          <strong>{abc.subTitle}</strong>
                          <p>{abc.desc}</p>
                          <button>바로가기</button>
                          <div className="test-info">
                            <div>
                              판매가격(12개월) :{' '}
                              {abc.exceptOptionMembershipSalePrice / 12}
                            </div>
                            <div>학습레벨 : {abc.classLevelInfo} </div>
                            <div>수강기간(개월) : {abc.periodInfo / 30}</div>
                            <div>학습방법 : {abc.classType}</div>
                          </div>
                        </div>
                      </Link>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </div>
          {/*학습 방법*/}
          <div
            className={'product-tab-cont' + (pageNumb == 4 ? ' active' : '')}
          >
            <div className="product-list-slide">
              <h3>케어 받으며</h3>
              <Swiper
                slidesPerView={1.2}
                spaceBetween={20}
                className="mySwiper"
              >
                {CategoryData.filter((item) => {
                  return item.classType == parseInt(1);
                }).map((abc, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <Link to={abc.linkPath}>
                        <div
                          className="thumb"
                          style={{ backgroundImage: `url(${abc.imagePath1})` }}
                        />
                        <div className="contents">
                          <strong>{abc.subTitle}</strong>
                          <p>{abc.desc}</p>
                          <button>바로가기</button>
                          <div className="test-info">
                            <div>
                              판매가격(12개월) :{' '}
                              {abc.exceptOptionMembershipSalePrice / 12}
                            </div>
                            <div>학습레벨 : {abc.classLevelInfo} </div>
                            <div>수강기간(개월) : {abc.periodInfo / 30}</div>
                            <div>학습방법 : {abc.classType}</div>
                          </div>
                        </div>
                      </Link>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
            <div className="product-list-slide">
              <h3>테마별 선택</h3>
              <Swiper
                slidesPerView={1.2}
                spaceBetween={20}
                className="mySwiper"
              >
                {CategoryData.filter((item) => {
                  return item.classType == parseInt(2);
                }).map((abc, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <Link to={abc.linkPath}>
                        <div
                          className="thumb"
                          style={{ backgroundImage: `url(${abc.imagePath1})` }}
                        />
                        <div className="contents">
                          <strong>{abc.subTitle}</strong>
                          <p>{abc.desc}</p>
                          <button>바로가기</button>
                          <div className="test-info">
                            <div>
                              판매가격(12개월) :{' '}
                              {abc.exceptOptionMembershipSalePrice / 12}
                            </div>
                            <div>학습레벨 : {abc.classLevelInfo} </div>
                            <div>수강기간(개월) : {abc.periodInfo / 30}</div>
                            <div>학습방법 : {abc.classType}</div>
                          </div>
                        </div>
                      </Link>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </div>
        </Mobile>
        <div className={'lecture-nav-col' + (pageNumb == 3 ? ' bg-gray' : '')}>
          <div className="inner-col">
            <h3>더 많은 강의 보기</h3>
            <p>내게 맞는 강의를 쉽게 찾아보세요</p>
            <ul>
              <li>
                <a href="/categoryList?type=1" className="level">
                  학습 레벨별 강의
                </a>
              </li>
              <li>
                <a href="/categoryList?type=2" className="price">
                  판매 가격별 강의
                </a>
              </li>
              <li>
                <a href="/categoryList?type=3" className="time">
                  수강 기간별 강의
                </a>
              </li>
              <li>
                <a href="/categoryList?type=4" className="way">
                  학습 방법별 강의
                </a>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
}
