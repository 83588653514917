import React, { useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import api from 'common/api';
import * as config from 'common/config';

export default function PromotionOrder() {
  const { promotionPackageCode } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const makePromotionOrder = () => {
    const query = new URLSearchParams(location.search);
    const URL_CART = '/mypage/order/cart';
    const errorReturnUrl = query.get('errorReturnUrl') || URL_CART;

    const data = {
      presentProductSeq: query.get('presentProductSeq') || '',
    };

    let productParamQueryString = '?presentProductSeq=' + (data.presentProductSeq ? data.presentProductSeq : '');

    let pathAndQueryString = `promotion/${promotionPackageCode}` + productParamQueryString;

    // 주문번호 재셋팅을 위한 productParam 값 셋팅.
    window.sessionStorage.setItem(
      'productParam',
      JSON.stringify({
        param:  pathAndQueryString,
        orderReset: false,
      })
    );

    api
      .post(`/payment/v2/order/` + pathAndQueryString)
      .then((response) => {
        if (response.data.meta?.code === 200) {
          navigate(`/mypage/order/cart/detail/${response.data.data}`);
        } else {
          if (response.data.meta.message) {
            alert(response.data.meta.message);
          } else {
            alert(config.MESSAGE['common-error']);
          }
          window.location.href = errorReturnUrl;
        }
      })
      .catch((e) => {
        console.log(e);
        alert(config.MESSAGE['common-error']);
        window.location.href = errorReturnUrl;
      });
  };

  useEffect(() => {
    makePromotionOrder();
  }, []);

  return null;
}
