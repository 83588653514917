export default function SubContentsItem({ title, contents, handleSubContent }) {
  return (
    <dd onClick={() => handleSubContent(title, contents)}>
      <span dangerouslySetInnerHTML={{ __html: title }}></span>
      <i>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="16"
          viewBox="0 0 17 16"
          fill="none"
        >
          <path
            d="M4 7.99573H13.3333M13.3333 7.99573L9.62667 4.28906M13.3333 7.99573L9.62667 11.7024"
            stroke="white"
            strokeWidth="1.25"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </i>
    </dd>
  )
}
