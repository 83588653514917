import { useEffect, useState } from 'react'
import { useLocation } from 'react-router'

import cx from 'classnames'
import useScrollLock from 'hooks/useScrollLock'
import AinadooTitleBar from 'webview/ai/components/AinadooTitleBar'
import SrrTitleBar from 'webview/ai/components/SrrTitleBar'

import s from './components/Chat.module.scss'
import ChatContainer from './components/ChatContainer'
import ChatFooter from './components/ChatFooter'
import QnaTip from './components/QnaTip'
import SrrTips from './components/SrrTips'

import { AINADOO_CONFIG } from './config'
import useAiFeeds from './hooks/useAiFeeds'
import useGetQnaId from './hooks/useGetQnaId'
import { NATIVE_FUNC } from './native'

export default function AiNadoo({ pageType }) {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const userSeq = Number(queryParams.get('userSeq'))
  const classSeq = Number(queryParams.get('classSeq'))
  const lectureSeq = Number(queryParams.get('lectureSeq'))
  const series = Number(queryParams.get('series'))
  const day = Number(queryParams.get('day'))
  const { lock } = useScrollLock()
  const { assistantId, threadId } = useGetQnaId({ classSeq, userSeq })
  const { addToFeed, isAiResponse, postPromptMessage } = useAiFeeds({
    classSeq,
    lectureSeq,
    userSeq,
    assistantId,
    threadId
  })

  const [outPage, setOutPage] = useState(false)
  const handleInquiry = () => {
    setOutPage(true)
    NATIVE_FUNC.loadURL('/webview/mypage/qna/inquiryWrite')
    NATIVE_FUNC.showTitleBar()
    NATIVE_FUNC.setTitle('1:1 문의')
  }

  useEffect(() => {
    NATIVE_FUNC.hideTitleBar(pageType)
    return () => {
      if (pageType === 'srr') {
        NATIVE_FUNC.showTitleBar(pageType)
      }
    }
  }, [pageType])

  useEffect(() => {
    window['wingBannerDisplay']()
    lock()
  }, [pageType])

  if (outPage) return null

  return (
    <>
      {pageType === 'srr' ? (
        <>
          <SrrTitleBar title="AI 스르르" type="close" />
          <SrrTips tips={AINADOO_CONFIG.DAILY_TIP[series][day - 1]?.content} />
        </>
      ) : (
        <>
          <AinadooTitleBar title="AI 나두" type="close" />
          <QnaTip handleInquiry={handleInquiry} />
        </>
      )}
      <article className={cx(s.chatLayout, { [s.nadooLayout]: pageType !== 'srr' })}>
        <ChatContainer
          isAiResponse={isAiResponse}
          lectureSeq={lectureSeq}
          userSeq={userSeq}
          pageType={pageType}
        />
        <ChatFooter
          addToFeed={addToFeed}
          postPromptMessage={postPromptMessage}
          isAiResponse={isAiResponse}
        />
      </article>
    </>
  )
}
