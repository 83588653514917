import { Link } from 'react-router-dom'

import classNames from 'classnames'
import { postGAEvent } from 'utils/postGAEvent'

import useHeaderStore from 'store/useHeaderStore'

import SideMenu from './sideMenu'
import { headerValues } from '../config'
import styles from '../styles/MainHeader.module.scss'

export default function MainHeader() {
  const currentSubIndex = useHeaderStore((state) => state.currentSubIndex)
  const onChangeCurrentSubIndex = useHeaderStore((state) => state.onChangeCurrentSubIndex)
  const gnbItems = useHeaderStore((state) => state.gnbItems)

  const onClickGnbMenu = (idx, gaItem) => {
    onChangeCurrentSubIndex(idx)
    postGAEvent({ ...gaItem })
  }

  return (
    <div className={styles.mainHeader}>
      <div className={styles.gnb}>
        <Link to="/" className={styles.logo}>
          <i className="blind">야나두</i>
        </Link>
        <ul className={styles.gnbList}>
          {gnbItems.length !== 0 &&
            gnbItems.map((item, idx) => (
              <li
                key={idx}
                className={classNames(styles.gnbItem, currentSubIndex === idx && styles.active)}
              >
                <Link
                  className={styles.gnbButton}
                  to={item.link}
                  onClick={() => onClickGnbMenu(idx, item.gaEventName || '')}
                >
                  <strong
                    className={
                      (styles.gnbName, styles[headerValues.gnbMenuSeq[item.gnbMenuSeq]] || '')
                    }
                  >
                    {item.menuName}
                  </strong>
                  <span className={styles.gnbDescription}>{item.description}</span>
                </Link>
              </li>
            ))}
        </ul>
      </div>
      <SideMenu />
    </div>
  )
}
