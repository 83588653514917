import React from 'react'

import s from './WebviewCustomer.module.scss'

export default function CustomerFooter() {
  return (
    <section className={s.customerFooter}>
      <dl>
        <dt>(주)야나두 전화상담</dt>
        <dd>1600-0563 (유료)</dd>
        <dd>주말/공휴일 제외 9:00-18:00</dd>
      </dl>
    </section>
  )
}
