import { useState, useEffect, useRef } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Link, NavLink, useNavigate } from 'react-router-dom'

import styled, { ThemeProvider } from 'styled-components'

import theme from 'common/theme'
import useHeaderHeightStore from 'store/useHeaderHeightStore'

import HeaderBanner from './HeaderBanner'
import logoUnivM from '../assets/images/bg_logo_class_614x112.png'
import logoUniv from '../assets/images/bg_logo_class_700x128.png'
import logoEngM from '../assets/images/img_logo_eng_462x72.png'
import logoEng from '../assets/images/img_logo_eng_540x83.png'
import logoYafit from '../assets/images/img_logo_yafit_176x44.png'
import logoYafitM from '../assets/images/img_logo_yafit_288x72.png'

export default function NavCompany({
  setEntry,
  gnbItems,
  currentIdx,
  onClickGnbMenu,
  gaEvent,
  depthCheck,
  currentPath,
  children
}) {
  const [depth2Active, setDepth2Active] = useState(null)
  const [isMoDepth3Chk, setMoDepth3Chk] = useState(false)
  const [gnbMovePoint, setGnbMovePoint] = useState(0)
  const isMobile = useMediaQuery({ maxWidth: 1024 })
  const gnbAreaRef = useRef()
  const gnbBoxRef = useRef()
  const [gnbBtnPrev, setGnbBtnPrev] = useState(true)
  const [gnbBtnNext, setGnbBtnNext] = useState(true)
  // GNB 컬러코드와 슬로건 텍스트를 배열로 갖고있는 상태값 입니다. currentIdx 번째 GNB의 정보를 초기값으로 갖습니다.
  const [companyItem, setCompanyItem] = useState([
    gnbItems[currentIdx].backgroundColor,
    gnbItems[currentIdx].description
  ])
  // pc에서 1024 이하의 해상도에서, GNB에서 드래그 기능 구현을 위한 상태값.
  const [isDrag, setIsDrag] = useState(false)
  const [beginX, setBeginX] = useState()
  const setIsShowBanner = useHeaderHeightStore((state) => state.setIsShowBanner)

  // GNB 상단바의 배경 컬러코드와 슬로건 텍스트를 변경하는 함수입니다.
  // GNB 상단바에 onMouseOver 또는 onMouseOut 이벤트가 일어났을때 해당 상태값을 업데이트 합니다.
  const gnbInfoSet = (idx) => {
    setCompanyItem([gnbItems[idx].backgroundColor, gnbItems[idx].description])
  }

  // 3depth 메뉴 리셋
  const depthActiveReset = () => {
    setDepth2Active(null)
    setMoDepth3Chk(false)
  }
  // mobile 사이즈에서 GNB 2depth 메뉴를 클릭 했을때, 3depth 메뉴를 보여주는 함수입니다.
  const moGnbActive = (idx) => {
    setDepth2Active(idx)
    setMoDepth3Chk(true)
    if (idx === depth2Active) {
      depthActiveReset()
    }
  }

  // GNB 컨텐츠 길이와, 보여지는 영역을 계산, GNB 위치 화살표 노출 여부를 판단하는 함수.
  const autoGnbCheck = () => {
    const gnbBoxWidth = gnbBoxRef.current.offsetWidth
    const gnbAreaBox = gnbAreaRef.current.offsetWidth - 236
    if (isMobile || gnbAreaBox >= gnbBoxWidth) {
      setGnbBtnPrev(false)
      setGnbBtnNext(false)
      setGnbMovePoint(0)
    } else {
      gnbMovePoint >= 0 ? setGnbBtnPrev(false) : setGnbBtnPrev(true)
      gnbMovePoint < gnbAreaBox - gnbBoxWidth ? setGnbBtnNext(false) : setGnbBtnNext(true)
    }
  }

  // GNB 이동 버튼 클릭 시 실행되는 함수.
  const gnbMoveEvent = (type) => {
    const moveWidth = 100 // 클릭 시 이동되는 gap.
    const movePoint = gnbMovePoint + moveWidth * (type === 'prev' ? 1 : -1)
    const gnbBoxWidth = gnbBoxRef.current.offsetWidth
    const gnbAreaBox = gnbAreaRef.current.offsetWidth - 236
    if (isMobile || gnbAreaBox >= gnbBoxWidth) {
      setGnbBtnPrev(false)
      setGnbBtnNext(false)
    } else {
      setGnbMovePoint(
        movePoint > 0
          ? 0
          : movePoint > gnbAreaBox - gnbBoxWidth
          ? movePoint
          : gnbAreaBox - gnbBoxWidth
      )
      movePoint >= 0 ? setGnbBtnPrev(false) : setGnbBtnPrev(true)
      movePoint < gnbAreaBox - gnbBoxWidth ? setGnbBtnNext(false) : setGnbBtnNext(true)
    }
  }

  const onDragStart = (e) => {
    e.preventDefault()
    setIsDrag(true)
    setBeginX(e.pageX + gnbBoxRef.current.scrollLeft)
  }

  const onDragEnd = () => {
    setIsDrag(false)
  }

  const onDragMove = (e) => {
    if (isDrag) gnbBoxRef.current.scrollLeft = beginX - e.pageX
  }

  useEffect(() => {
    if (depthCheck !== null && depth2Active === null) {
      setDepth2Active(depthCheck.depth2)
    }
  }, [depthCheck, currentIdx])

  let navigate = useNavigate()
  const goHome = () => {
    let path = `newPath`
    navigate(path)
    setEntry(true)
  }

  useEffect(() => {
    gnbInfoSet(currentIdx)
    autoGnbCheck()
  }, [currentIdx])

  useEffect(() => {
    window.addEventListener('scroll', depthActiveReset)
    return () => window.removeEventListener('scroll', depthActiveReset)
  }, [])

  useEffect(() => {
    window.addEventListener('resize', autoGnbCheck)
    return () => {
      window.removeEventListener('resize', autoGnbCheck)
    }
  }, [isMobile])

  useEffect(() => {
    //  헤더 띠 배너있을 경우 show hide
    setIsShowBanner(
      gnbItems[currentIdx].menuName === '야나두 영어' ||
        gnbItems[currentIdx].menuName === '야나두 클래스'
    )
  }, [currentIdx])

  return (
    <ThemeProvider theme={theme}>
      <HeaderBanner />
      <CompanyCol style={{ backgroundColor: companyItem[0] }}>
        <InnerCol className="top-inner-col flex">
          <Slogan>{companyItem[1]}</Slogan>
          <CompanyListCol>
            <CompanyItems>
              <button onClick={goHome}>HOME</button>
            </CompanyItems>
            {gnbItems &&
              gnbItems.map((item, idx) => (
                <CompanyItems key={idx} className={currentIdx === idx ? 'active' : null}>
                  <a
                    href={item.link}
                    onClick={() => onClickGnbMenu(idx, item.menuName)}
                    onMouseOver={() => gnbInfoSet(idx)}
                    onMouseOut={() => gnbInfoSet(currentIdx)}
                    target={item.target}
                  >
                    {item.menuName}
                  </a>
                </CompanyItems>
              ))}
          </CompanyListCol>
        </InnerCol>
      </CompanyCol>
      <NavCol className="sub-header-wrap">
        <div className="sub-header-inner">
          <MenuListCol className="sub-header-nav" ref={gnbAreaRef}>
            <H2
              className={
                currentIdx === 0
                  ? 'logo-eng '
                  : currentIdx === 1
                  ? 'logo-yu'
                  : currentIdx === 2
                  ? 'logo-yafit'
                  : null
              }
            >
              <Link
                to={
                  currentIdx === 0
                    ? gnbItems[currentIdx].link
                    : currentIdx === 1
                    ? gnbItems[currentIdx].link
                    : currentIdx === 2
                    ? gnbItems[currentIdx].link
                    : null
                }
              >
                야나두
              </Link>
            </H2>
            {gnbItems &&
              gnbItems
                .filter((item, idx) => {
                  return idx === currentIdx
                })
                .map((gnb, index) => (
                  // header sub scroll-x navigation
                  <MenuList
                    key={index}
                    className="scroll-nav active"
                    style={{ marginLeft: +gnbMovePoint + 'px' }}
                    ref={gnbBoxRef}
                    onMouseDown={onDragStart}
                    onMouseMove={onDragMove}
                    onMouseUp={onDragEnd}
                    onMouseLeave={onDragEnd}
                  >
                    {gnb.childGnbMenus.map((item, index, e) => (
                      <MenuItems key={index} bgColor={gnb.backgroundColor}>
                        {isMobile && item.childGnbMenus.length > 0 ? (
                          <ALink
                            href="#;"
                            onClick={() => moGnbActive(index)}
                            className={`${
                              !currentPath.includes('/store/detail') && depth2Active === index
                                ? 'active'
                                : ''
                            } ${depth2Active === index && isMoDepth3Chk ? ' check' : ''}`}
                            borderColor={companyItem[0]}
                          >
                            {item.menuName}
                          </ALink>
                        ) : item.link.includes('http') ? (
                          <a
                            href={item.link}
                            target={`_${item.linkTarget.toLowerCase()}`}
                            onClick={() => {
                              setDepth2Active(index)
                              gaEvent(item.gaEventName !== undefined ? item.gaEventName : '')
                            }}
                            className={`${
                              !currentPath.includes('/store/detail') && depth2Active === index
                                ? 'active'
                                : ''
                            } ${depth2Active === index && isMoDepth3Chk ? ' check' : ''}`}
                          >
                            <span
                              className={
                                gnb.gnbMenuSeq === 49
                                  ? `eng`
                                  : gnb.gnbMenuSeq === 105
                                  ? `yu`
                                  : gnb.gnbMenuSeq === 71
                                  ? `yafit`
                                  : ``
                              }
                            >
                              {item.menuName}
                            </span>
                          </a>
                        ) : (
                          <Link
                            to={item.link}
                            target={`_${item.linkTarget.toLowerCase()}`}
                            onClick={() => {
                              setDepth2Active(index)
                              gaEvent(item.gaEventName !== undefined ? item.gaEventName : '')
                            }}
                            className={`${
                              !currentPath.includes('/store/detail') &&
                              currentPath.includes(`${item.link}`)
                                ? 'active'
                                : ''
                            } ${depth2Active === index && isMoDepth3Chk ? ' check' : ''}`}
                          >
                            <span
                              className={
                                gnb.gnbMenuSeq === 49
                                  ? `eng`
                                  : gnb.gnbMenuSeq === 105
                                  ? `yu`
                                  : gnb.gnbMenuSeq === 71
                                  ? `yafit`
                                  : ``
                              }
                            >
                              {item.menuName}
                            </span>
                          </Link>
                        )}
                        {/* free 하드 코딩 20240307 */}
                        {item.childGnbMenus?.length > 0 && (
                          <ul className="depth3">
                            {item.childGnbMenus.map((sub, index, e) => (
                              <li
                                key={`${sub.depth}-${sub.gnbMenuSeq}`}
                                className={
                                  sub.iconType && sub.iconType !== 'NONE'
                                    ? sub.iconType.toLowerCase()
                                    : ''
                                }
                              >
                                {sub.link.includes('http') ? (
                                  <a
                                    href={sub.link}
                                    target={`_${sub.linkTarget.toLowerCase()}`}
                                    onClick={() => {
                                      setMoDepth3Chk(false)
                                      gaEvent(sub.gaEventName !== undefined ? sub.gaEventName : '')
                                    }}
                                  >
                                    {sub.menuName}
                                  </a>
                                ) : (
                                  <NavLink
                                    to={sub.link}
                                    target={`_${sub.linkTarget.toLowerCase()}`}
                                    onClick={() => {
                                      setMoDepth3Chk(false)
                                      gaEvent(sub.gaEventName !== undefined ? sub.gaEventName : '')
                                    }}
                                  >
                                    {sub.menuName}
                                  </NavLink>
                                )}
                              </li>
                            ))}
                          </ul>
                        )}
                      </MenuItems>
                    ))}
                  </MenuList>
                ))}
            <button
              type="button"
              className="btn-gnb-prev"
              onClick={() => gnbMoveEvent('prev')}
              style={{ display: gnbBtnPrev ? `block` : `none` }}
            >
              prev
            </button>
            <button
              type="button"
              className="btn-gnb-next"
              onClick={() => gnbMoveEvent('next')}
              style={{ display: gnbBtnNext ? `block` : `none` }}
            >
              next
            </button>
          </MenuListCol>
          {/* side menu */}
          {children}
        </div>
      </NavCol>
      <MoGnbDimed
        display={isMoDepth3Chk ? 'block' : 'none'}
        onClick={() => setMoDepth3Chk(false)}
      />
    </ThemeProvider>
  )
}

const CompanyCol = styled.section`
  transition: 0.2s;
  @media ${({ theme }) => theme.device.mobile} {
    padding: 2.1rem 0;
    & > div {
      justify-content: flex-end !important;
    }
  }
`

const Slogan = styled.strong`
  font-weight: 400;
  font-size: 1.2rem;
  color: #000;
  opacity: 0.6;
  @media ${({ theme }) => theme.device.mobile} {
    display: none !important;
  }
`

const NavCol = styled.section`
  display: block;
  position: relative;

  @media ${({ theme }) => theme.device.mobile} {
    padding: 0;
    &:before {
      display: none;
    }
    &:after {
      display: none;
    }
  }
`
const InnerCol = styled.div`
  position: relative;
  max-width: 1980px;
  margin: 0 auto;
  padding: 0 2.8rem;
  box-sizing: border-box;

  &.flex {
    display: flex;
    // padding-left: 2.8rem;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    align-items: center;
  }
  &.top-inner-col {
    max-width: 1920px;
    margin: 0 auot;
    padding: 0 100px;
  }
  @media screen and (max-width: 1440px) {
    &.top-inner-col {
      padding: 0 40px;
    }
  }
  @media ${({ theme }) => theme.device.mobile} {
    padding: 0;
    &.top-inner-col {
      padding: 0 30px;
    }
  }
`

const CompanyListCol = styled.ul`
  display: flex;
  align-items: center;
  // padding-right: 1rem;
  margin-right: -1.6rem;
`
const CompanyItems = styled.li`
  position: relative;

  &:before {
    content: '';
    display: inline-block;
    position: absolute;
    left: -1px;
    top: 50%;
    width: 3px;
    height: 3px;
    border-radius: 100%;
    background: #000;
    opacity: 0.2;
    transform: translateY(-50%);
  }

  &:first-child {
    &:before {
      display: none;
    }
  }
  &.active {
    button,
    a {
      font-weight: 700;
      opacity: 1;
    }
  }

  button,
  a {
    display: inline-block;
    padding: 1rem 1.6rem;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 2rem;
    color: #000;
    opacity: 0.6;

    &:hover {
      font-weight: 700;
      opacity: 1;
    }

    @media ${({ theme }) => theme.device.mobile} {
      font-size: 2.4rem;
    }
  }
`

const MenuList = styled.ul`
  display: none;
  transition: 0.3s;

  &.active {
    display: block;
    margin: 0 0 0 5.6rem;
    white-space: nowrap;

    @media ${({ theme }) => theme.device.mobile} {
      margin: 1rem 0 0 0;
      padding: 0 10px;
      overflow-x: auto;
      scroll-snap-type: x mandatory;
      white-space: nowrap;
    }
  }
  &::-webkit-scrollbar {
    display: none;
  }
`
const MenuItems = styled.li`
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  &:last-child {
    margin-right: 4rem;
  }
  a {
    display: block;
    height: auto;
    padding: 2.8rem 2.4rem 2.6rem;
    font-weight: 500;
    font-size: 1.6rem;

    &:hover {
      span {
        &:after {
          border-width: 0.5rem;
        }
      }
    }

    span {
      display: inline-block;
      position: relative;
      &:after {
        content: '';
        position: absolute;
        bottom: 0.2rem;
        left: 0;
        width: 100%;
        border-bottom: 0 solid transparent;
        transition: 0.2s;
        opacity: 0.5;
        z-index: -1;
      }
      &.eng {
        &:after {
          border-bottom-color: #ffb900;
        }
      }
      &.yu {
        &:after {
          border-bottom-color: #ff5647;
        }
      }
      &.yafit {
        &:after {
          border-bottom-color: #00cc66;
        }
      }
    }

    &:hover {
      font-weight: 700;

      & + ul {
        display: block;
      }
    }
    &.active {
      color: #000;
    }
    & + ul:hover {
      display: block;
    }
    & + ul {
      display: none;
      position: absolute;
      top: 6rem;
      left: 0;
      width: max-content;
      padding: 1.6rem 0;
      background: #fff;
      border-radius: 4px;
      box-shadow: 0px 0px 10px 0px #00000033;

      box-sizing: border-box;
      z-index: 2;

      li {
        &.new {
          a:after {
            content: '';
            display: inline-block;
            position: relative;
            width: 3rem;
            height: 1.5rem;
            margin-left: 0.5rem;
            background: url(https://english.yanadoocdn.com/upload/yanadoo/new/common/ico_gnb_new_60x30.png)
              0 50% / auto 100%;
            vertical-align: bottom;
            @media ${({ theme }) => theme.device.mobile} {
              width: 6rem;
              height: 3rem;
              margin-left: 1rem;
            }
          }
        }
        &.hot {
          a:after {
            content: '';
            display: inline-block;
            position: relative;
            width: 3rem;
            height: 1.5rem;
            margin-left: 0.5rem;
            background: url(https://english.yanadoocdn.com/upload/yanadoo/new/common/ico_gnb_hot_60x30.png)
              0 50% / auto 100%;
            vertical-align: bottom;
            @media ${({ theme }) => theme.device.mobile} {
              width: 6rem;
              height: 3rem;
              margin-left: 1rem;
            }
          }
        }
        &.best {
          a:after {
            content: '';
            display: inline-block;
            position: relative;
            width: 3rem;
            height: 1.5rem;
            margin-left: 0.5rem;
            background: url(https://english.yanadoocdn.com/upload/yanadoo/new/common/ico_gnb_best_60x30.png)
              0 50% / auto 100%;
            vertical-align: bottom;
            @media ${({ theme }) => theme.device.mobile} {
              width: 6rem;
              height: 3rem;
              margin-left: 1rem;
            }
          }
        }
        &.premium {
          a:after {
            content: '';
            display: inline-block;
            position: relative;
            width: 5.4rem;
            height: 1.5rem;
            margin-left: 0.5rem;
            background: url(https://english.yanadoocdn.com/upload/yanadoo/new/common/ico_gnb_premium_102x28.png)
              0 50% / auto 100%;
            vertical-align: bottom;
            @media ${({ theme }) => theme.device.mobile} {
              width: 9rem;
              height: 3rem;
              margin-left: 1rem;
            }
          }
        }
        &.event {
          a:after {
            content: '';
            display: inline-block;
            position: relative;
            width: 3.5rem;
            height: 1.5rem;
            margin-left: 0.5rem;
            background: url(https://english.yanadoocdn.com/upload/yanadoo/new/common/ico_gnb_event_70x30.png)
              0 50% / auto 100%;
            vertical-align: bottom;
            @media ${({ theme }) => theme.device.mobile} {
              width: 6rem;
              height: 3rem;
              margin-left: 1rem;
            }
          }
        }
        &.soldout {
          a:after {
            content: '';
            display: inline-block;
            position: relative;
            width: 2.5rem;
            height: 1.5rem;
            margin-left: 0.5rem;
            background: url(https://english.yanadoocdn.com/upload/yanadoo/new/common/ico_gnb_soldout_46x28.png)
              0 50% / auto 100%;
            vertical-align: bottom;
            @media ${({ theme }) => theme.device.mobile} {
              width: 5rem;
              height: 3rem;
              margin-left: 1rem;
            }
          }
        }
        &.free {
          a:after {
            content: '';
            display: inline-block;
            position: relative;
            width: 3.6rem;
            height: 1.4rem;
            margin-left: 0.5rem;
            background: url(https://english.yanadoocdn.com/upload/yanadoo/new/common/ico_gnb_free.png)
              0 50% / auto 100%;
            vertical-align: bottom;
            @media ${({ theme }) => theme.device.mobile} {
              width: 7rem;
              height: 3rem;
              margin-left: 1rem;
            }
          }
        }
      }
      li > a {
        position: relative;
        padding: 0.8rem 2.4rem;
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 1;

        &.active {
          font-weight: 700;
        }

        &:hover {
          background-color: #ececf6;
        }
      }
    }
  }

  @media ${({ theme }) => theme.device.mobile} {
    position: unset;
    /* padding: 0 4.8rem 0 0; */

    a {
      &:hover {
        & + ul {
          display: none;
        }
      }
      & + ul:hover {
        display: none;
      }
      &.check {
        & + ul {
          display: block;
        }
      }
    }
    & > a {
      position: relative;
      padding: 2.8rem 2.4rem 2.9rem 2.4rem;
      font-size: 2.8rem;
      border-bottom: 0 solid transparent;
      &.active {
        &:after {
          content: '';
          position: absolute;
          right: 0;
          bottom: 0;
          left: 0;
          width: calc(100% - 4rem);
          height: 0.6rem;
          margin: auto;
          background-color: ${(props) => props.bgColor || 'transparent'};
        }
      }
      & + ul {
        width: 100vw;
        top: 100%;
        padding: 2rem 0;
        background: #f7f7fc;
        border-radius: 0;
        box-shadow: unset;
        // left: -30px;
        li > a {
          padding: 2.8rem 4.2rem;
          font-weight: 400;
          font-size: 2.8rem;
          line-height: 1;

          &.active {
            font-weight: 700;
          }
        }
      }
    }
  }
`

const ALink = styled.a`
  @media ${({ theme }) => theme.device.mobile} {
    &.active {
      color: #000;

      &:after {
        content: '';
        display: inline-block;
        position: absolute;
        right: 0;
        left: 0;
        bottom: 0;
        width: calc(100% - 4.8rem);
        height: 0.4rem;
        margin: auto;
        background-color: ${(props) => props.borderColor || 'transparent'};
      }
    }
  }
`
const MenuListCol = styled.div`
  display: flex;
  flex: 1;
  height: 7.5rem;
  align-items: center;
  & > div {
    display: flex;
    align-items: stretch;
    position: absolute;
    right: 4.5rem;
  }

  h2 {
    flex-basis: 23.6rem;
    flex-grow: 0;
    flex-shrink: 0;
    height: 100%;
    z-index: 3;
    &.logo-eng {
      background: url(${logoEng}) no-repeat 0 50% / 18rem auto;
    }

    &.logo-yu {
      background: url(${logoUniv}) no-repeat 0 50% / 17.5rem auto;
    }

    &.logo-yafit {
      background: url(${logoYafit}) no-repeat 0 50% / 8.8rem auto;
    }
    @media ${({ theme }) => theme.device.mobile} {
      width: 40rem;
      height: 5.6rem;
      margin: 0 0 1rem 20px;
      background-position: 0 0;

      &.logo-eng {
        background-size: 32.9rem auto;
        /* width: 32rem;
        height: 3.6rem;
        background: url(${logoEngM}) no-repeat 4.2rem 0 / 23.1rem auto; */
      }

      &.logo-yu {
        background-size: 30.7rem auto;
        /* width: 32rem;
        height: 3.6rem;
        background: url(${logoUnivM}) no-repeat 4.2rem 0 / 26.7rem auto; */
      }

      &.logo-yafit {
        background-size: 14.4rem auto;
        /* width: 32rem;
        height: 3.6rem;
        background: url(${logoYafitM}) no-repeat 4.2rem 0 / 14.4rem auto; */
      }
    }
  }

  .btn-gnb-prev {
    // display: none;
    position: absolute;
    bottom: 0.2rem;
    left: 23rem;
    width: 5rem;
    height: calc(100% - 0.4rem);
    background-color: transparent;
    text-indent: -999rem;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 1.1rem;
      height: 2rem;
      margin: auto;
      background: url(https://english.yanadoocdn.com/upload/yanadoo/new/common/ico_gnb_arrow_22x40.png)
        no-repeat 50% 50% / 100% auto;
      transform: rotate(180deg);
      z-index: 2;
    }
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(90deg, rgba(255, 255, 255, 1) 80%, rgb(255, 255, 255, 0) 100%);
    }
  }
  .btn-gnb-next {
    // display: none;
    position: absolute;
    bottom: 0.2rem;
    right: 0;
    width: 5rem;
    height: calc(100% - 0.4rem);
    background-color: transparent;
    text-indent: -999rem;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 1.1rem;
      height: 2rem;
      margin: auto;
      background: url(https://english.yanadoocdn.com/upload/yanadoo/new/common/ico_gnb_arrow_22x40.png)
        no-repeat 50% 50% / 100% auto;
      z-index: 2;
    }
    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(270deg, rgba(255, 255, 255, 1) 80%, rgb(255, 255, 255, 0) 100%);
    }
  }

  @media ${({ theme }) => theme.device.mobile} {
    display: block;
    flex: 1;
    position: relative;
    width: 100%;
    padding: 3.5rem 0;
    height: 12.2rem;
    background: #fff;

    .btn-gnb-prev {
      display: none !important;
    }
    .btn-gnb-next {
      display: none !important;
    }

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 6rem;
      height: 10rem;
      background: linear-gradient(to right, rgba(255, 255, 255), rgb(255, 255, 255, 0));
      z-index: 2;
    }
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      width: 6rem;
      height: 10rem;
      background: linear-gradient(to right, rgba(255, 255, 255, 0), rgb(255, 255, 255));
      z-index: 2;
    }

    span[class^='ico'] {
      width: 5rem;
      height: 5rem;
    }
  }
`
const H2 = styled.h2`
  a {
    display: block;
    height: 100%;
    text-indent: -999rem;
  }

  @media ${({ theme }) => theme.device.mobile} {
    width: 23.1rem;
    height: 3.6rem;
    margin: 0 0 3rem;
  }
`
const MoGnbDimed = styled.div`
  display: none;
  @media ${({ theme }) => theme.device.mobile} {
    display: ${(props) => props.display};
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: -1;
  }
`
