import React from 'react'
import { Link } from 'react-router-dom'

import styled from 'styled-components'
import SwiperCore, { Pagination, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import 'assets/styles/banner.scss'
import 'swiper/swiper.scss'
import 'swiper/components/pagination/pagination.scss'
import ResponsiveImage from 'components/ResponsiveImage'

SwiperCore.use([Pagination, Autoplay])

const StoreBanner = ({ autoPlay, heightPC, bannerData }) => {
  return (
    <>
      {bannerData?.length > 0 && (
        <BannerBox bannerHeight={heightPC}>
          <Swiper
            className="swiper-container"
            slidesPerView={1}
            loop={true}
            pagination={{ clickable: true }}
            autoplay={
              autoPlay
                ? {
                    delay: 3000,
                    disableOnInteraction: false
                  }
                : ''
            }
          >
            {bannerData?.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <Link
                    to={item.link}
                    style={{
                      backgroundColor: item.bgColor
                    }}
                    target={item.linkType === 'NEW' ? '_blank' : '_self'}
                  >
                    <ResponsiveImage
                      mo={item.bannerFileMobile}
                      pc={item.bannerFilePc}
                      maxWidth={1024}
                    />
                  </Link>
                </SwiperSlide>
              )
            })}
          </Swiper>
        </BannerBox>
      )}
    </>
  )
}

export default StoreBanner

const BannerBox = styled.section`
  position: relative;
  width: 100%;
  margin-bottom: 7rem;
  @media ${({ theme }) => theme.device.mobile} {
    margin-bottom: 6rem;
  }
  picture {
    width: 100%;
    display: inline-block;
    height: 100%;
    text-align: center;
  }
  img {
    height: 100%;
    object-fit: cover;
  }
  .swiper-pagination {
    bottom: 2.6rem !important;
  }
  .swiper-slide {
    height: ${(props) => Number(props.bannerHeight) * 0.1 + 'rem'};
    a {
      display: block;
      height: 100%;
    }
    @media ${({ theme }) => theme.device.mobile} {
      height: auto;
      img {
        width: 100%;
        height: auto;
        max-width: 100%;
        object-fit: contain;
      }
    }
  }
  .swiper-pagination-bullet {
    width: 0.8rem;
    height: 0.8rem;
    margin: 0 0.4rem;
    background-color: #fff;
    opacity: 0.6;
    border-radius: 0.6rem;
    transition: 0.2s;
    &.swiper-pagination-bullet-active {
      width: 3.2rem;
      opacity: 1;
    }
    @media ${({ theme }) => theme.device.mobile} {
      width: 1.2rem;
      height: 1.2rem;
      margin: 0 1.6rem;
      &.swiper-pagination-bullet-active {
        width: 4.8rem;
      }
    }
  }
`
