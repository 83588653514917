import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import cx from 'classnames'
import useBodyClassToggle from 'hooks/useBodyClassToggle'
import ModalCaution from 'pages/aiNadoo/components/ModalCaution'
import { closeWebView, hideTitleBar, webviewHistorySave } from 'webview/bridge'
import CustomerTitleBar from 'webview/service/components/CustomerTitleBar'

import { getCookie, setCookie } from 'common/Cookie'

import s from './components/Chat.module.scss'
import ChatContainer from './components/ChatContainer'
import ChatFooter from './components/ChatFooter'
import FaqWebTitleBar from './components/FaqWebTitleBar'

import useAiFeeds from './hooks/useAiFeeds'

const CAUTION_DATA = [
  {
    title: '자주 묻는 질문을 참고하여 답변해줘요.',
    content: '개인정보 확인이 필요하거나 오류 문의는 카카오톡 상담 혹은 1:1 문의로 질문해 주세요.'
  },
  {
    title: '작성하신 내용을 활용할 수 있어요.',
    content:
      '홍보, 이벤트 등으로 활용 및 서비스 개선을 위해 모든 데이터를 저장하고 있어요. 필요 시 대화 내용을 확인할 수 있습니다.'
  },
  {
    title: '개인정보를 입력하지 마세요.',
    content: '개인정보를 포함한 민감한 정보는 절대로 노출하거나 공유하지 마세요.'
  },
  {
    title: '답변 내용을 신뢰하지 마세요.',
    content:
      '최근 정보가 업데이트 되거나 정책이 변경되었다면 바로 반영되지 않아 답변이 부정확할 수 있어요.'
  },
  { content: 'AI 상담원 서비스는 회사의 사정에 따라 사전고지 없이 변경 또는 종료될 수 있어요.' }
]

export default function AiFaq({ service, isPlatform }) {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const gubun = searchParams.get('service')
  const { addToFeed, isAiResponse, postPromptMessage, resetFeedList } = useAiFeeds({ gubun })
  const [terminate, setTerminate] = useState(false)
  const [caution, setCaution] = useState(false)
  const { addClass, removeClass } = useBodyClassToggle(s.scrollLock)

  useEffect(() => {
    window['wingBannerDisplay']()

    return () => resetFeedList()
  }, [resetFeedList])

  useEffect(() => {
    if (isPlatform) {
      console.log('hideTitleBar, webviewHistorySave')
      webviewHistorySave()
      hideTitleBar(service)
    }
  }, [isPlatform])

  useEffect(() => {
    const footerEl = document.querySelector('footer')

    if (footerEl && !isPlatform) {
      footerEl.style.setProperty('display', 'none')
    }

    addClass()

    return () => {
      if (footerEl && !isPlatform) {
        footerEl.style.removeProperty('display')
      }

      removeClass()
    }
  }, [isPlatform, addClass, removeClass])

  useEffect(() => {
    window.pressedBackButton = function () {
      pressedBackButton()
    }

    // 컴포넌트가 마운트 될 때 쿠키를 확인하고 모달을 표시할지 결정
    if (getCookie('FaqCautionModal') !== 'N') {
      setCaution(true) // 쿠키가 없거나 'N'이 아니면 모달을 표시
    }

    return () => {
      delete window.pressedBackButton
    }
  }, [])

  const pressedBackButton = () => {
    // setBack(!back)
  }

  const handleOpenCaution = () => {
    setCaution(true) // 버튼 클릭 시 쿠키와 관계없이 모달을 열기
  }

  const handleCloseCaution = () => {
    setCookie('FaqCautionModal', 'N', 1) // 모달 닫을 때 쿠키 설정, 1일 동안 유지
    setCaution(false) // 모달을 닫음
  }

  const handleOnClick = (type) => {
    if (type === 'caution') {
      setCaution(!caution)
    } else {
      navigate('/service/center')
    }
  }

  return (
    <>
      {isPlatform && !terminate && (
        <CustomerTitleBar
          title="AI 상담원"
          icon={{ visible: true, onClick: handleOpenCaution }}
          onBtnClick={
            service === 'SRRAPP'
              ? () => closeWebView(service)
              : () => navigate('/webview/service/center')
          }
        />
      )}
      <article
        className={cx(s.chatLayout, {
          [s.webview]: isPlatform
        })}
      >
        {!isPlatform && <FaqWebTitleBar handleOnClick={handleOnClick} />}
        <ChatContainer isAiResponse={isAiResponse} addToFeed={addToFeed} isPlatform={isPlatform} />
        <ChatFooter
          addToFeed={addToFeed}
          postPromptMessage={postPromptMessage}
          isAiResponse={isAiResponse}
          isPlatform={isPlatform}
          setTerminate={setTerminate}
          service={service}
        />
      </article>
      {caution && (
        <ModalCaution onClose={handleCloseCaution} notice={CAUTION_DATA} title={'AI 상담원'} />
      )}
    </>
  )
}
