import { Link } from 'react-router-dom'

import ResponsiveImage from 'components/ResponsiveImage'

import { lectureValues } from '../config'
import s from '../EnglishHome.module.css'

export default function LectureSection() {
  return (
    <section className={s.lecture}>
      <div className={s.inner}>
        <hgroup>
          <h3 className={s.title}>
            업그레이드 된 강의로
            <br /> 2024년
            <br className={s.disM} /> 왕초보 영어 탈출
            <span>나에게 딱 맞는 패키지로 시작하세요</span>
          </h3>
        </hgroup>
        <ul className={s.lectureList}>
          {lectureValues.map(({ link, pc, mo, alt }, index) => {
            return (
              <li key={index}>
                <Link to={link} target="_blank">
                  <ResponsiveImage pc={pc} mo={mo} alt={alt} maxWidth={1024} />
                </Link>
              </li>
            )
          })}
        </ul>
      </div>
    </section>
  )
}
