import React from 'react'

import ModalPortal from 'ModalPortal'

import s from './ModalAiNadoo.module.scss'

export default function ModalCaution({ title, onClose, notice }) {
  return (
    <ModalPortal>
      <div className={s.modalAinadoo}>
        <div className={s.ainadooNotice}>
          <div className={s.noticeBox}>
            <h3 className={s.noticeTitle}>{title} 서비스 유의사항</h3>
            <div className={s.list}>
              {notice.map(({ title, content }, index) => (
                <dl key={index}>
                  {title && (
                    <dt>
                      <span>{index + 1}.</span>
                      {title}
                    </dt>
                  )}
                  {content && <dd>{content}</dd>}
                </dl>
              ))}
            </div>
            <button type="button" onClick={onClose} className={s.btnClose}>
              확인
            </button>
          </div>
        </div>
      </div>
    </ModalPortal>
  )
}
