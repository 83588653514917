import { useEffect, useState } from 'react'
import api from 'common/api'

export default function useGetCurationList(cate, sortOrder = 0) {
  const [curationList, setCurationList] = useState([])

  useEffect(() => {
    function getCurationList() {
      const category = cate === 'ENGLISH' ? 'FOREIGN' : cate

      api
        .get(`/v2/store/curation/category/${category}/${sortOrder}`)
        .then((response) => {
          const res = response.data.data
          setCurationList(res)
        })
        .catch((e) => {
          console.log(e)
        })
    }

    if (cate) {
      getCurationList()
    }
  }, [cate, sortOrder])

  return curationList
}
