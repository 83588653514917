import React, { useState, useEffect, useRef } from 'react';
import '../assets/styles/password.scss';

export default function Password({
  mark,
  currentPwd,
  handleNewPwdComplete,
  handlePwdComplete,
  pwdCheckResult,
}) {
  // mark: 필수 입력 항목 표시
  // currentPwd: 비밀번호 변경시 필요한 현재 비밀번호
  // handleNewPwdComplete: 비밀번호 변경시 상태 업데이트
  // handlePwdComplete: 초기 비밀번호 설정시 상태 업데이트
  // pwdCheckResult: 비밀번호 변경시 현재 비밀번호 일치/불일치 값 (변경 버튼 클릭시 api 호출)

  const currentPwdRef = useRef();
  const pwdRef = useRef();
  const matchPwdRef = useRef();

  const [validStatus, setValidStatus] = useState({
    currentPassword: null,
    newPassword: false,
    matchPassword: false,
  });

  const [inputValue, setInputValue] = useState({
    currentPassword: '',
    newPassword: '',
    matchPassword: '',
  });

  const [validText, setValidText] = useState({
    currentPassword: '',
    newPassword: '',
    matchPassword: '',
  });

  const handlePasswordValue = (e) => {
    let { name, value } = e.target;

    if (value) {
      setInputValue({ ...inputValue, [name]: value });
    } else {
      setInputValue({ ...inputValue, [name]: '' });
    }
  };

  // 비밀번호 입력
  const handlePwdCheck = () => {
    let pwdRegExp =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&=\*()\-_?~])[A-Za-z\d!@#$%^&=\*()\-_?~]{8,12}$/;
    let koreanCheck = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;

    if (
      (inputValue.newPassword && !pwdRegExp.test(inputValue.newPassword)) ||
      koreanCheck.test(inputValue.newPassword)
    ) {
      pwdRef.current.focus();
      setValidText({ ...validText, newPassword: '사용불가' });
      setValidStatus({ ...validStatus, newPassword: false });
    } else {
      setValidText({ ...validText, newPassword: '사용가능' });
      setValidStatus({ ...validStatus, newPassword: true });
    }
  };

  // 비밀번호 확인 체크
  const handleMatchPasswordCheck = () => {
    if (inputValue.newPassword === inputValue.matchPassword) {
      setValidText({ ...validText, matchPassword: '일치' });
      setValidStatus({ ...validStatus, matchPassword: true });
    } else {
      setValidText({ ...validText, matchPassword: '불일치' });
      setValidStatus({ ...validStatus, matchPassword: false });
    }
  };

  useEffect(() => {
    if (inputValue.newPassword) {
      handlePwdCheck();
    }

    if (inputValue.newPassword && inputValue.matchPassword) {
      handleMatchPasswordCheck();
    }
  }, [inputValue.newPassword, inputValue.matchPassword]);

  useEffect(() => {
    if (currentPwd) {
      if (pwdCheckResult === false) {
        currentPwdRef.current.focus();
        setValidText({ ...validText, currentPassword: '불일치' });
        setValidStatus({ ...validStatus, currentPassword: false });
      } else {
        setValidText({ ...validText, currentPassword: '일치' });
        setValidStatus({ ...validStatus, currentPassword: true });
      }
    }
  }, [pwdCheckResult]);

  useEffect(() => {
    if (handleNewPwdComplete) {
      if (validStatus.newPassword && validStatus.matchPassword) {
        handleNewPwdComplete(true, inputValue.newPassword, inputValue.currentPassword);
      } else {
        handleNewPwdComplete(false);
      }
    }

    if (handlePwdComplete) {
      if (validStatus.newPassword && validStatus.matchPassword) {
        handlePwdComplete(true, inputValue.newPassword);
      } else {
        handlePwdComplete(false);
      }
    }
  }, [validStatus, inputValue]);

  return (
    <>
      {currentPwd && (
        <dl className="password-box">
          <dt className={mark ? 'mark' : ''}>현재 비밀번호</dt>
          <dd>
            <input
              type="password"
              placeholder="현재 비밀번호를 입력해주세요."
              className={
                inputValue.currentPassword && (validStatus.currentPassword ? null : 'warning')
              }
              name="currentPassword"
              ref={currentPwdRef}
              onChange={handlePasswordValue}
            />
            {!validStatus.currentPassword && (
              <span
                className={
                  'valid-text' +
                  (validStatus.currentPassword ? ' active success' : ' active warning')
                }
              >
                {validText.currentPassword}
              </span>
            )}
          </dd>
        </dl>
      )}

      <dl className="password-box">
        <dt className={mark ? 'mark' : ''}>비밀번호</dt>
        <dd className="password-box">
          <input
            type="password"
            maxLength="12"
            placeholder="영문, 숫자, 특수문자 (' &quot; , . / ' &lt; &gt; 제외) 8~12자"
            className={inputValue.newPassword && (validStatus.newPassword ? 'success' : 'warning')}
            name="newPassword"
            ref={pwdRef}
            onChange={handlePasswordValue}
            onBlur={handlePwdCheck}
          />
          {inputValue.newPassword && (
            <span
              className={
                'valid-text' + (validStatus.newPassword ? ' active success' : ' active warning')
              }
            >
              {validText.newPassword}
            </span>
          )}
        </dd>
      </dl>
      <dl className="password-box">
        <dt className={mark ? 'mark' : ''}>비밀번호 확인</dt>
        <dd className="password-box">
          <input
            type="password"
            maxLength="12"
            placeholder="비밀번호를 다시 입력해주세요."
            className={
              inputValue.matchPassword && (validStatus.matchPassword ? 'success' : 'warning')
            }
            name="matchPassword"
            ref={matchPwdRef}
            onChange={handlePasswordValue}
            onBlur={handleMatchPasswordCheck}
          />
          {inputValue.matchPassword && (
            <span
              className={
                'valid-text' + (validStatus.matchPassword ? ' active success' : ' active warning')
              }
            >
              {validText.matchPassword}
            </span>
          )}
        </dd>
      </dl>
    </>
  );
}
