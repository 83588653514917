import { useState } from 'react'

import ResponsiveImage from 'components/ResponsiveImage'

import s from './TrainingStep.module.css'
import { HOST_CDN } from '../../config'
import BtnPulse from '../BtnPulse'

export default function TrainingStep05() {
  const [target, setTarget] = useState({ one: false, two: false })

  const handleToggle = (key) => {
    setTarget({ ...target, [key]: !target[key] })
  }

  return (
    <div className={`${s.step} ${s.step05}`}>
      <span className={s.btn01}>
        <BtnPulse active event={() => handleToggle('one')} />
      </span>
      <span className={s.tooltip01}>
        {target.one && (
          <ResponsiveImage
            pc={`${HOST_CDN}step_05_hover_01.png`}
            mo={`${HOST_CDN}step_05_hover_01_m.png`}
            alt={'띠리링! 하루 10분 영어 습관 만들자'}
            maxWidth={1024}
          />
        )}
      </span>
    </div>
  )
}
