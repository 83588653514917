import React, { useEffect, useRef, useState } from 'react'

import { requestPermission } from 'webview/bridge'

import useAudioState from 'store/useAudioState'

import s from './ChatFooterAudio.module.scss'
import RecorderButton from './RecorderButton'
import RecorderTooltip from './RecorderTooltip'

// 브라우저에서 SpeechRecognition 객체 가져오기
const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition
const isSpeechRecognitionSupported = !!SpeechRecognition // 지원 여부 확인

export default function ChatFooterAudio({ addToFeed, isLoading, character = {} }) {
  const [text, setText] = useState('')
  const [isListening, setIsListening] = useState(false)
  const recognitionRef = useRef(null) // recognition 객체를 useRef로 관리
  const isRestartingRef = useRef(null) // 재시작 플래그 관리
  const { setIsAudioPlaying } = useAudioState()

  useEffect(() => {
    // navigator.permissions가 존재하는지 확인
    if (navigator.permissions) {
      navigator.permissions
        .query({ name: 'microphone' })
        .then((result) => {
          if (result.state !== 'granted') {
            console.error('마이크 권한이 필요합니다.')
          }
        })
        .catch((error) => {
          console.error('권한 조회 중 에러 발생:', error)
        })
    } else {
      console.warn('navigator.permissions가 지원되지 않는 환경입니다.')
    }

    if (isSpeechRecognitionSupported) {
      // 음성 인식을 지원하는 경우 recognition 초기화
      recognitionRef.current = new SpeechRecognition()
      recognitionRef.current.lang = 'en-US'
      recognitionRef.current.continuous = true // 연속 모드 설정
      recognitionRef.current.interimResults = true // 중간 결과도 사용

      console.log('[start] 음성 인식이 시작되었습니다.')

      let accumulatedTranscript = '' // 최종 결과를 누적할 변수

      recognitionRef.current.onresult = (event) => {
        let interimTranscript = ''

        for (let i = event.resultIndex; i < event.results.length; i++) {
          const transcript = event.results[i][0].transcript
          if (event.results[i].isFinal) {
            accumulatedTranscript += transcript + ' ' // 최종 텍스트 누적
          } else {
            interimTranscript += transcript // 중간 결과만 따로 저장
          }
        }

        // 최종 결과와 중간 결과를 결합하여 표시
        setText(accumulatedTranscript + interimTranscript)
      }

      recognitionRef.current.onerror = (event) => {
        console.error('[error]', event.error)
        if (event.error === 'service-not-allowed') {
          console.warn('[error][service-not-allowed] 음성 인식 권한이 없습니다.')
          // 음성인식 권한 요청
          requestPermission('speech')
          addToFeed({
            type: 'Error',
            msg: '음성인식 권한이 없습니다. 권한 설정 후 다시 한번 말씀해 주시겠어요?',
            mode: false
          })
        } else if (event.error === 'not-allowed') {
          console.warn('[error][not-allowed] 마이크 사용 권한이 없습니다.')
          // 마이크 사용 권한 요청
          requestPermission('mic')
          addToFeed({
            type: 'Error',
            msg: '마이크 권한이 없습니다. 권한 설정 후 다시 한번 말씀해 주시겠어요?',
            mode: false
          })
        } else if (event.error === 'no-speech') {
          console.warn('[error][no-speech] 아무런 음성 인식을 하지 못했습니다.')
          // 아무말 없을 때
          addToFeed({
            type: 'Error',
            msg: '죄송합니다. 이해하지 못했어요. 다시 한번 말씀해 주시겠어요?',
            mode: false
          })
        } else if (event.error === 'network') {
          console.warn('[error][network] 네트워크 오류로 인한 음성 인식이 중단되었습니다.')
          addToFeed({
            type: 'Error',
            msg: '죄송합니다. 네트워크 오류로 음성 인식이 중단되었습니다. 네트워크 상태를 확인해 주세요.',
            mode: false
          })
        } else if (event.error === 'aborted') {
          console.warn('[error][aborted] 강제로 음성 인식이 중단되었습니다.')
        }
        accumulatedTranscript = ''
      }

      recognitionRef.current.onend = () => {
        console.log('[end] 음성 인식이 종료되었습니다.')
        accumulatedTranscript = ''

        if (isRestartingRef.current) {
          console.log('[end][re] 재시작 플래그가 true 일때')
          try {
            setText('')
            recognitionRef.current.start()
            isRestartingRef.current = false
            console.log('[re] 음성 인식이 재시작되었습니다.')
          } catch (error) {
            console.error('[re][error] 음성 인식 재시작 오류:', error)
          }
        } else {
          setIsListening(false)
        }
      }
    }

    // 클린업 함수 추가
    return () => {
      if (recognitionRef.current) {
        recognitionRef.current.onend = null
        recognitionRef.current.onerror = null
        recognitionRef.current.stop() // 마이크 중지
      }
    }
  }, [])

  // listen start
  const handleListenStart = () => {
    console.log('[start][onClick] 음성 인식 시작 버튼 클릭.')
    setIsAudioPlaying(false)
    setIsListening(true)
    setText('')

    if (isSpeechRecognitionSupported && recognitionRef.current) {
      try {
        console.log('[start][try] 음성인식 시작')
        recognitionRef.current.start()
      } catch (error) {
        console.error('[start][error] 음성 인식 시작 오류:', error)
      }
    }
  }

  // listen stop
  const handleListenStop = () => {
    console.log('[end][onClick] 음성 인식 종료 버튼 클릭.')
    setIsAudioPlaying(true)
    setIsListening(false)
    setText('')

    if (isSpeechRecognitionSupported && recognitionRef.current && isListening) {
      console.log('[end] stop')
      isRestartingRef.current = false
      recognitionRef.current.stop()
    }
  }

  // send to api
  const handleSendMessage = () => {
    console.log('[send][onClick] 메세지 보내기 버튼 클릭.')
    if (text.length < 1 || text.trim() === '' || !text) {
      console.log('[send] 빈문자열')
      addToFeed({
        type: 'Error',
        msg: '죄송합니다. 이해하지 못했어요. 다시 한번 말씀해 주시겠어요?',
        mode: false
      })
      setText('')
      handleRestart()
    } else {
      console.log('[send] 메세지 보내기')
      addToFeed({ type: 'User', msg: text })
      handleListenStop()
    }
  }

  const handleRestart = () => {
    console.log('[re][onClick] 재시작 버튼 클릭.')
    isRestartingRef.current = true
    console.log('[re] 재시작 플래그 true 설정.')
    if (isSpeechRecognitionSupported && recognitionRef.current && isListening) {
      console.log('[re] 음성 인식 중지.')
      recognitionRef.current.stop()
    }
  }

  return (
    <section className={s.footerMicGroup}>
      <RecorderTooltip
        isListening={isListening}
        isLoading={isLoading}
        name={character?.info?.firstname}
        msg={text}
      />
      <RecorderButton
        isListening={isListening}
        isLoading={isLoading}
        handleRecorder={{
          send: handleSendMessage,
          start: handleListenStart,
          stop: handleListenStop,
          re: handleRestart
        }}
      />
    </section>
  )
}
