import React, { useState, useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { ThemeProvider } from 'styled-components';
import ModalPortal from '../ModalPortal';
import AuthService from 'services/authService';
import api from 'common/api';
import * as config from 'common/config';
import { timeCheck } from 'common/common';
import theme from 'common/theme';
import IcoSendOff from '../assets/images/ico_send_off_32x32.png';
import IcoSendOn from '../assets/images/ico_send_on_32x32.png';
import ModalModify from '../components/modal/ModalModify';
import CommunityInfo from 'components/CommunityInfo';

export default function CommentView({ getReviewData, data, isLoading }) {
  const [userInfo, setUserInfo] = useState(null);
  const [relationSeq, setRelationSeq] = useState(null); // 댓글이 등록될 상세 시퀀스 넘버 바뀌지 않음
  const [replySeq, setReplySeq] = useState(0); // 대댓글이 등록될 댓글의 시퀀스
  const commentRef = useRef();
  const [chkItemContent, setChkItemContent] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [userComment, setUserComment] = useState(false);
  const [userName, setUserName] = useState(null);
  const [modifyText, setModifyText] = useState(null);

  const [commentData, setCommentData] = useState(null);

  const [modalCommentSeq, setModalCommentSeq] = useState(null);
  const [modalCommentContent, setModalCommentContent] = useState(null);

  const [commentRegistTime, setCommentRegistTime] = useState(null);

  const handleChangeChk = (event) => {
    setChkItemContent(event.target.value);
  };

  const handleOpenModal = useCallback((seq, chat) => {
    document.body.classList.add('modal-open');
    setModalOpen(true);
    setModalCommentSeq(seq);
    setModalCommentContent(chat);
  }, []);

  const handleCloseModal = useCallback((e) => {
    document.body.classList.remove('modal-open');
    setModalOpen(false);
  }, []);

  const handleOpenComment = (commentSeq, name) => {
    setModalCommentContent(null);
    setChkItemContent('');
    setReplySeq(commentSeq === relationSeq ? 0 : commentSeq);

    if (commentSeq !== relationSeq && name) {
      setUserComment(true);
      setModifyText(null);
      setUserName(name);
    }
    commentRef.current.focus();
  };

  const handleCloseComment = () => {
    setUserComment(false);
    setReplySeq(0);
    setChkItemContent('');
    setModalCommentSeq(null);
    setModalCommentContent(null);
  };

  const getCommentData = (seq) => {
    (data.reviewType
      ? api.get(`/v2/comment/review-comment/${seq}`)
      : api.get(`/v2/comment/scholarship-comment/${seq}`)
    )
      .then((response) => {
        if (response.data.meta.code === 200) {
          setCommentData(response.data.data);
          setCommentRegistTime(response.data.meta.dateTime.replace('-', '/').replace('-', '/'));
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // 유저정보 가져오기
  const getUserInfo = () => {
    if (AuthService.getUserInfo()) {
      api
        .get(`${config.AUTH_API_HOST}/auth/v2/user`)
        .then((response) => {
          setUserInfo(response.data.data);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const isEmpty = (val) => {
    return (
      val === undefined ||
      val === null ||
      val === '' ||
      val.trim() === '' ||
      (val !== null && typeof val === 'object' && !Object.keys(val).length)
    );
  };

  // 댓글등록하기 눌렀을 때 함수
  const handleComment = (e, relationSeq, replySeq) => {
    if (modalCommentContent) {
      putComment(e, modalCommentSeq);
    } else {
      postComment(e, relationSeq, replySeq);
    }
  };

  // 댓글 등록
  const postComment = (e, relationSeq, parentSeq) => {
    e.preventDefault();

    // 로그인 여부 체크
    if (!AuthService.getUserInfo()) {
      alert('로그인이 필요합니다.');
      return false;
    }

    if (isEmpty(chkItemContent)) {
      alert('댓글을 입력해주세요.');
      commentRef.current.focus();
      return false;
    } else {
      let dataBody;
      if (data.reviewType) {
        dataBody = {
          contents: chkItemContent,
          parentSeq: parentSeq,
          relationSeq: relationSeq,
          relationType: 'REVIEW',
        };
      } else {
        dataBody = {
          commentDisplayType: 'ALL',
          contents: chkItemContent,
          parentSeq: parentSeq,
          scholarshipRefundHistorySeq: relationSeq,
        };
      }
      (data.reviewType
        ? api.post(`/v2/comment`, dataBody)
        : api.post(`/v2/scholarship-refund-history/comment`, dataBody)
      )
        .then((response) => {
          if (response.data.meta.code === 200) {
            setChkItemContent('');
            setUserComment(false);
            setReplySeq(0);
            getCommentData(relationSeq);
            if (data.scholarshipSeq) {
              getReviewData(data.scholarshipSeq, relationSeq);
            } else {
              getReviewData(relationSeq);
            }
          } else {
            alert(response.data.meta.message);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  // 댓글 삭제
  const deleteComment = (e, commentseq) => {
    e.preventDefault();

    let deleteData;

    if (!data.reviewType) {
      deleteData = {
        commentSeq: commentseq,
        scholarshipRefundHistorySeq: relationSeq,
      };
    }

    (data.reviewType
      ? api.delete(`/v2/comment/${commentseq}`)
      : api.delete(`/v2/scholarship-refund-history/comment`, { data: deleteData })
    )

      .then((response) => {
        if (response.data.meta.code === 200) {
          alert('삭제되었습니다.');
          handleCloseModal();
          setReplySeq(0);
          setModalCommentContent(null);
          setUserComment(false);
          setChkItemContent('');
          getCommentData(relationSeq);
          if (data.scholarshipSeq) {
            getReviewData(data.scholarshipSeq, relationSeq);
          } else {
            getReviewData(relationSeq);
          }
        } else {
          alert(response.data.meta.message);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleCommentModify = () => {
    handleCloseModal();
    commentRef.current.focus();
    setChkItemContent(modalCommentContent);
    setUserComment(true);
    setUserName(false);
    setModifyText(true);
  };

  // 댓글 수정
  const putComment = (e, commentSeq) => {
    e.preventDefault();

    if (modalCommentContent === chkItemContent) {
      alert('내용이 동일합니다. 수정해주세요!');
      return false;
    }

    if (isEmpty(chkItemContent)) {
      alert('댓글을 입력해주세요.');
      commentRef.current.focus();
      return false;
    }

    let commentData;
    if (data.reviewType) {
      commentData = {
        commentSeq: commentSeq,
        contents: chkItemContent,
        duration: 0,
      };
    } else {
      commentData = {
        commentSeq: commentSeq,
        contents: chkItemContent,
        scholarshipRefundHistorySeq: relationSeq,
      };
    }

    (data.reviewType
      ? api.put(`/v2/comment`, commentData)
      : api.put(`/v2/scholarship-refund-history/comment`, commentData)
    )
      .then((response) => {
        if (response.data.meta.code === 200) {
          setChkItemContent('');
          setUserComment(false);
          setReplySeq(0);
          setModalCommentSeq(null);
          setModalCommentContent(null);
          getCommentData(relationSeq);
          if (data.scholarshipSeq) {
            getReviewData(data.scholarshipSeq, relationSeq);
          } else {
            getReviewData(relationSeq);
          }
        } else {
          alert(response.data.meta.message);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const onToggleLike = (commentSeq) => {
    if (isLoading) {
      api
        .post(`v2/reaction/like?relationSeq=${commentSeq}&relationType=COMMENT`)
        .then((response) => {
          if (response.data.meta.code === 200) {
            getCommentData(relationSeq);
            if (data.scholarshipSeq) {
              getReviewData(data.scholarshipSeq, relationSeq);
            } else {
              getReviewData(relationSeq);
            }
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  useEffect(() => {
    if (isLoading && data) {
      if (data.learningReviewSeq) {
        setRelationSeq(data.learningReviewSeq);
      } else {
        setRelationSeq(data.scholarshipRefundHistorySeq);
      }
    }
  }, [isLoading]);

  const SingleComment = ({ children, ...rest }) => {
    if (
      rest.isDeleted &&
      rest.comments &&
      !rest.comments.filter((item) => item.isDeleted === false).length
    ) {
      return false;
    } else {
      return <ChatTemp {...rest} />;
    }
  };

  const ReplyComment = ({ children, ...rest }) => {
    return <ChatTemp type={'reply'} {...rest} />;
  };

  const ChatTemp = ({
    type,
    commentSeq,
    parentSeq,
    profile,
    name,
    chat,
    registDate,
    registUserSeq,
    isDeleted,
    isLiked,
    likeCount,
  }) => {
    return (
      <ChatCol>
        <ProfileBox type={type}>
          <div
            style={{
              backgroundImage: `url(${profile})`,
            }}
          ></div>
        </ProfileBox>
        <ChatBox>
          <ChatTextBox>
            <strong>{name}</strong>
            {chat}
          </ChatTextBox>
          <ChatInfoTemp
            registDate={registDate}
            commentSeq={commentSeq}
            parentSeq={parentSeq}
            name={name}
            registUserSeq={registUserSeq}
            isDeleted={isDeleted}
            chat={chat}
            isLiked={isLiked}
            likeCount={likeCount}
          />
        </ChatBox>
      </ChatCol>
    );
  };

  const ChatInfoTemp = ({
    commentSeq,
    parentSeq,
    registDate,
    name,
    registUserSeq,
    isDeleted,
    chat,
    isLiked,
    likeCount,
  }) => {
    return (
      <ChatInfoBox>
        <div>
          {!isDeleted && (
            <button
              type="button"
              onClick={() => {
                onToggleLike(commentSeq);
              }}
            >
              {isLiked ? <strong>좋아요</strong> : '좋아요'}{' '}
              {likeCount === 0 ? '' : isLiked ? <strong>{likeCount}</strong> : likeCount}{' '}
            </button>
          )}
          {parentSeq === 0 && !isDeleted && (
            <button
              type="button"
              onClick={() => {
                handleOpenComment(commentSeq, name);
              }}
            >
              답글달기
            </button>
          )}

          <span>
            {timeCheck(commentRegistTime, registDate.replace('-', '/').replace('-', '/'))}
          </span>
        </div>
        {userInfo && registUserSeq === userInfo.userSeq && !isDeleted && (
          <button
            type="button"
            onClick={() => {
              handleOpenModal(commentSeq, chat);
            }}
          >
            <span></span>
          </button>
        )}
      </ChatInfoBox>
    );
  };

  useEffect(() => {
    getUserInfo();

    if (isLoading && data.learningReviewSeq) {
      getCommentData(data.learningReviewSeq);
    }

    if (isLoading && data.scholarshipRefundHistorySeq) {
      getCommentData(data.scholarshipRefundHistorySeq);
    }
  }, [isLoading]);

  return (
    <ThemeProvider theme={theme}>
      <CommunityInfo
        type={'REAL'}
        handleOpenComment={() => {
          handleOpenComment(relationSeq);
        }}
        data={data}
        isLoading={isLoading}
      ></CommunityInfo>
      <CommentList>
        {commentData &&
          isLoading &&
          commentData.map((comment, idx) => {
            return (
              <div key={idx}>
                <SingleComment
                  key={comment.commentSeq}
                  profile={comment.registUserProfileImageUrl}
                  name={comment.registUserName}
                  chat={comment.contents}
                  commentSeq={comment.commentSeq}
                  parentSeq={comment.parentSeq}
                  registDate={comment.registDate}
                  registUserSeq={comment.registUserSeq}
                  isDeleted={comment.isDeleted}
                  isLiked={comment.isLiked}
                  likeCount={comment.likeCount}
                  comments={comment.comments}
                />
                {comment.comments &&
                  comment.comments
                    .filter((reReply) => reReply.isDeleted === false)
                    .map((reReply) => {
                      return (
                        <ReplyComment
                          key={reReply.commentSeq}
                          commentSeq={reReply.commentSeq}
                          parentSeq={reReply.parentSeq}
                          profile={reReply.registUserProfileImageUrl}
                          name={reReply.registUserName}
                          chat={reReply.contents}
                          registDate={reReply.registDate}
                          registUserSeq={reReply.registUserSeq}
                          isDeleted={reReply.isDeleted}
                          isLiked={reReply.isLiked}
                          likeCount={reReply.likeCount}
                        />
                      );
                    })}
              </div>
            );
          })}
      </CommentList>
      <CommentWrite>
        <form>
          <div>
            {commentData && userComment && (
              <div>
                {userName && <strong>{userName}</strong>}
                {modifyText && <span>댓글 수정중</span>}
                <button type="button" onClick={handleCloseComment}>
                  취소
                </button>
              </div>
            )}
          </div>
          <div>
            <div>
              <input
                className={chkItemContent ? 'active' : ''}
                value={chkItemContent}
                placeholder={'댓글을 작성해주세요.'}
                onChange={handleChangeChk}
                ref={commentRef}
              />
              <button
                onClick={(e) => {
                  handleComment(e, relationSeq, replySeq);
                }}
              >
                submit
              </button>
            </div>
          </div>
        </form>
      </CommentWrite>

      {modalOpen ? (
        <ModalPortal>
          <ModalModify
            btnClose
            handleCloseModal={handleCloseModal}
            handleCommentModify={handleCommentModify}
            handleCommentDelete={deleteComment}
            modalCommentSeq={modalCommentSeq}
          />
        </ModalPortal>
      ) : null}
    </ThemeProvider>
  );
}

const CommentList = styled.div`
  padding: 0.6rem 3.2rem 3rem 1.8rem;
  border-top: 1px solid #dfdfea;
`;

const ChatCol = styled.div`
  display: flex;
  padding-top: 2.3rem;

  @media ${({ theme }) => theme.device.mobile} {
    padding-top: 2.6rem;
  }
`;

const ProfileBox = styled.div`
  flex: 0 0 ${(props) => (props.type ? '3.6rem' : '4.6rem')};
  width: ${(props) => (props.type ? '3.6rem' : '4.6rem')};
  height: ${(props) => (props.type ? '3.6rem' : '4.6rem')};
  margin-left: ${(props) => props.type && '5.8rem'};
  background-color: #eee;
  border-radius: 50%;
  overflow: hidden;

  div {
    width: 100%;
    height: 100%;
    background-image: ${(props) => `url(${props.bgImage})`};
    background-size: cover;
    background-position: 50% 50%;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 50%;
    box-sizing: border-box;
  }

  @media ${({ theme }) => theme.device.mobile} {
    flex: 0 0 ${(props) => (props.type ? '5.2rem' : '6.8rem')};
    width: ${(props) => (props.type ? '5.2rem' : '6.8rem')};
    height: ${(props) => (props.type ? '5.2rem' : '6.8rem')};
    margin-left: ${(props) => props.type && '8rem'};
  }
`;

const ChatBox = styled.div`
  flex-grow: 1;
  margin-left: 1.2rem;

  @media ${({ theme }) => theme.device.mobile} {
    margin-left: 1.4rem;
  }
`;

const ChatTextBox = styled.div`
  width: 100%;
  padding: 1.8rem;
  background-color: #efeff6;
  font-size: 1.6rem;
  border-radius: 1rem;
  line-height: 1.5;
  box-sizing: border-box;

  strong {
    display: inline-block;
    margin-right: 1rem;
    font-weight: 700;
  }

  @media ${({ theme }) => theme.device.mobile} {
    font-size: 2.6rem;
  }
`;

const ChatInfoBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0.7rem 0 0.7rem;
  color: #8d8da0;
  font-size: 1.4rem;

  div {
    button {
      position: relative;

      & + button,
      & + span {
        &::before {
          content: '·';
          display: inline-block;
          margin: auto 0.3rem;
        }
      }
    }

    & + button {
      padding: 0 1.6rem;

      span {
        display: inline-block;
        position: relative;
        width: 0.3rem;
        height: 0.3rem;
        border-radius: 50%;
        background-color: #a0a0b6;

        &::before,
        &::after {
          content: '';
          position: absolute;
          top: 0;
          width: 0.3rem;
          height: 0.3rem;
          border-radius: 50%;
          background-color: #a0a0b6;
        }

        &::before {
          left: -0.6rem;
        }

        &::after {
          right: -0.6rem;
        }
      }
    }
  }

  @media ${({ theme }) => theme.device.mobile} {
    font-size: 2.2rem;

    div {
      & + button {
        span {
          width: 0.5rem;
          height: 0.5rem;

          &::before,
          &::after {
            width: 0.5rem;
            height: 0.5rem;
          }

          &::before {
            left: -1rem;
          }

          &::after {
            right: -1rem;
          }
        }
      }
    }
  }
`;

const CommentWrite = styled.div`
  margin-top: auto;

  form > div:first-child {
    position: relative;
    color: #717188;
    background-color: #f2f2fa;
    font-size: 1.6rem;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: -1.3rem;
      left: 0;
      width: 100%;
      height: 1.3rem;
      background: linear-gradient(#fff, 80%, #efefef);
    }

    & > div {
      padding: 0.86rem 2.6rem 0.83rem;
    }

    strong {
      font-weight: 700;

      &:after {
        content: '님에게 답글 남기는 중';
        font-weight: 400;
      }
    }

    button {
      color: #8d8da0;

      &:before {
        content: '·';
        display: inline-block;
        margin: auto 0.66rem auto 0.83rem;
      }
    }
  }

  div + div {
    padding: 2.2rem 2.6rem;

    div {
      display: flex;
      justify-content: space-between;
      justify-items: center;
      height: 4.6rem;
      padding: 0.9rem 1.8rem;
      border: 0.2rem solid #ececf6;
      border-radius: 0.5rem;
      box-sizing: border-box;

      input {
        flex-grow: 1;
        line-height: 1.9rem;
        padding: 0;
        font-size: 1.6rem;
        border: 0;

        &::placeholder {
          color: #b7b7c8;
        }

        & + button {
          flex: 0 0 3.2rem;
          align-self: center;
          width: 3.2rem;
          height: 3.2rem;
          background: url(${IcoSendOff}) 0 0/100% no-repeat;
          text-indent: -9999rem;
        }

        &.active + button {
          background-image: url(${IcoSendOn});
        }
      }
    }
  }

  @media ${({ theme }) => theme.device.mobile} {
    form > div:first-child {
      font-size: 2.6rem;

      & > div {
        padding: 1.3rem 2.8rem 1.5rem;
      }

      button {
        &:before {
          margin: auto 1rem;
        }
      }
    }

    div + div {
      div {
        height: 6.8rem;

        input {
          line-height: 4rem;
          font-size: 2.6rem;

          & + button {
            flex: 0 0 4.8rem;
            width: 4.8rem;
            height: 4.8rem;
          }
        }
      }
    }
  }
`;
