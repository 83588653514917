import React, { useState, useCallback, useEffect } from 'react';
import '../../assets/styles/modal.scss';
import '../../assets/styles/_modalMindsetIntro.scss';
import ModalPortal from '../../ModalPortal';
import AlertModal from './AlertModal';
import api from 'common/api';
import * as config from 'common/config';

export default function ModalMindsetResult ({ title, btnClose, handleCloseModal, resultText }) {

  const [modalopenAlert, setModalopenAlert] = useState(false);
  const [submitCheck, setSubmitCheck] = useState(false);
	const dateType = (type, date) => {
		let dateArray = date.split('-');
		let returnDate = '';
		if(type === 'kor'){
			returnDate = dateArray[0] + '년 ' + dateArray[1] + '월 ' + dateArray[2] + '일';
		} else if(type === 'dot') {
			returnDate = dateArray[0] + '.' + dateArray[1] + '.' + dateArray[2];
		}
		return returnDate;
	}

	const handleOpenModalAlert = useCallback((e) => {
		if(submitCheck){
			handleCloseModal();
		} else {
			document.body.classList.add('modal-open');
	    setModalopenAlert(true);
		}
  }, [submitCheck]);

  const handleCloseAlertModal = useCallback((e) => {
    document.body.classList.remove('modal-open');
    setModalopenAlert(false);
  }, []);

	// 초기 랜더링 시 현재 입학 상태 여부 체크(비정상 접근 시 예외처리)
  const getMindsetState = () => {
    api
      .get(config.AUTH_API_HOST + '/auth/v2/user')
      .then((response) => {
				let result = response.data.data.entrance;
				
				if(result.entranceStatus !== 'ENTRANCE'){ // 입학 상태가 아님
					alert('입학 상태가 아닙니다.');
					handleCloseModal();
				} else if(result.entranceStatus === 'ENTRANCE' && !result.isPledgePopTarget && !result.isWriteEntrancePledge){ // 입학 상태이면서, APP 팝업 발생 조건이 아니고, 서약서 미작성
					alert('서약서 작성 가능 상태가 아닙니다.');
					handleCloseModal();
				} else if(result.entranceStatus === 'ENTRANCE' && !result.isPledgePopTarget && result.isWriteEntrancePledge){ // 입학 상태이면서, APP 팝업 발생 조건이 아니고, 서약서 작성을 한경우
					MindsetResultView();
				}
      })
      .catch((e) => {
        console.log(e);
      });
  };

	// 작성된 서약 내용을 API로 보냄, 완료 후 MindsetResultView 처리
	const postMindset = () => {
		api
			.post('/v2/entrance/pledge?pledgeContent=' + resultText)
			.then((response) => {
				if (response.data.meta.code !== 200) {
					alert(response.data.meta.message);
					return false;
				} else {
					setSubmitCheck(true);
					alert('서약서가 등록되었습니다.');
					MindsetResultView();
				}
			})
			.catch((e) => {
				console.log(e);
			});
	}
	
	const MindsetResultView = () => {
		// 마인드셋이 작성된 상태, 작성된 API 조회 필요.
		api
      .get('/v2/entrance/pledge')
      .then((response) => {
				console.log(response);
				let result = response.data.data;
				setSubmitCheck(true);
				document.getElementById('pledgeContent').innerHTML = result.pledgeContent;
				document.querySelector('.mindset-result').classList.add('result-send');
				document.querySelector('.result-content').innerHTML = '<p>성장 마인드셋을 갖춘<br/>나는 오늘부터, 위와 같은 목표로<br/>도전은 즐겁게 받아들이고,<br/>좋은 습관을 형성해가며,<br/>중도포기 없이 끈기있게 앞으로<br/>나아갈 것을 나 자신과 약속합니다.</p><span>' + dateType('dot', result.pledgeRegistDate.split(' ')[0]) + '</span>';
      })
      .catch((e) => {
        console.log(e);
      });
	}

	let delayCheck = false;

	const handleConfirmClose = () => {
		if(delayCheck){
			alert('잠시후에 다시 시도해주세요');
		} else {
			delayCheck = true;
			api
      .post(
        config.AUTH_API_HOST +
				'/auth/v2/user/pledge/pop-close'
      )
      .then((response) => {
        if (response.data.meta.code === 200) {
          handleCloseAlertModal();
					handleCloseModal();
					delayCheck = false;
        } else {
          alert(response.data.meta.message);
					delayCheck = false;
        }
      })
      .catch((e) => {
        alert(config.MESSAGE['common-error']);
				delayCheck = false;
      });
		}
  };
	
	useEffect(() => {
		getMindsetState();
	},[])

	return (
		<>
			<div className="modal-wrap">
				<div className="dimmed" onClick={handleOpenModalAlert}></div>
				<div className="modal-inner" style={{height:'100%'}}>
					{btnClose && (
							<button type="button" className="btn-modal-close" onClick={handleOpenModalAlert}>
									<i className="blind">닫기</i>
							</button>
					)}
					<div className="modal-header">
						<strong>{title}</strong>
					</div>
					<div className="modal-content" style={{height:'100%'}}>
						<div className="mindset-result">
							<div className="mindset-inner">
								<dl>
									<dt><strong id="pledgeContent">{resultText}</strong></dt>
									<dd className="result-content">
										<p>을(를) 목표로 마인드셋 서약서를<br/>작성하시겠습니까?</p>
										<button type="button" onClick={postMindset}>서약하기</button>
									</dd>
								</dl>
							</div>
						</div>
					</div>
				</div>
			</div>
			{modalopenAlert ? (
        <ModalPortal>
          <AlertModal
            // btnClose
            btnGroup
						btnGroupTxtClose={'취소'}
						btnGroupTxtConfirm={'다음에 하기'}
            msg={'다음에 서약하기\n\'마이페이지 > 서약서 작성하기\'에서 이어서 진행하실 수 있습니다.'}
            handleCloseModal={handleCloseAlertModal}
            handleConfirmClose={handleConfirmClose}
          />
        </ModalPortal>
      ) : null}
		</>
	);
};
