import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { useMediaQuery } from 'react-responsive'

import styled, { ThemeProvider } from 'styled-components'

import theme from 'common/theme'
import CategoryFilter from 'components/Store/Category/CategoryList'
import ClassList from 'components/Store/List/ClassList'
import ItemStore from 'components/Store/List/ItemStore'

import StoreBanner from '../components/StoreBanner'
import useStoreCategory from '../hooks/useStoreCategory'

const StoreCategory = () => {
  const isMobile = useMediaQuery({ maxWidth: 1024 })

  const {
    categorySeq,
    cateList,
    handleCategory,
    categoryTitle,
    curationList,
    isLoading,
    storeData,
    setSort,
    sort,
    isEmpty,
    bannerList
  } = useStoreCategory()

  return (
    <>
      <ThemeProvider theme={theme}>
        <StoreBanner key={categorySeq} loop autoPlay heightPC={360} bannerData={bannerList} />
        <section className="class-list-col">
          <div className="inner-col">
            {cateList.length > 0 && categorySeq !== 'hobby' ? (
              <div className="category">
                <CategoryFilter
                  list={cateList}
                  handleCategory={handleCategory}
                  categoryTitle={categoryTitle}
                />
              </div>
            ) : null}

            {curationList && curationList.length > 0 && (
              <>
                {curationList.map((item, index) => {
                  return (
                    <ClassList
                      key={index}
                      title={item.storeCurationName}
                      listData={item.packageList}
                    />
                  )
                })}
              </>
            )}

            {isLoading ? (
              <LoadingBox>
                <div className="class-tit">
                  <Skeleton
                    width={isMobile ? '20rem' : '11rem'}
                    height={isMobile ? '4rem' : '4rem'}
                  />
                  <Skeleton
                    width={isMobile ? '17rem' : '12rem'}
                    height={isMobile ? '4rem' : '3rem'}
                  />
                </div>
                <Loader />
              </LoadingBox>
            ) : (
              <ItemStore
                title={categoryTitle}
                storeData={storeData}
                setSort={setSort}
                sort={sort}
                isEmpty={isEmpty}
              />
            )}
          </div>
        </section>
      </ThemeProvider>
    </>
  )
}

export default StoreCategory

const LoadingBox = styled.div`
  position: relative;
  max-width: 120rem;
  margin: 5rem auto 2rem;
  .class-tit {
    display: flex;
    justify-content: space-between;
    padding-bottom: 3rem;
  }

  @media ${({ theme }) => theme.device.mobile} {
    max-width: 100%;
    padding: 0;
    box-sizing: border-box;
  }
`

const Loader = styled.div`
  width: 28px;
  margin: 25rem auto;
  aspect-ratio: 1;
  border-radius: 50%;
  background: radial-gradient(farthest-side, rgb(255, 185, 0) 94%, #0000) top/8px 8px no-repeat,
    conic-gradient(#0000 30%, rgb(255, 185, 0));
  mask: radial-gradient(farthest-side, #0000 calc(100% - 3px), #000 0);
  animation: l13 1s infinite linear;

  @keyframes l13 {
    100% {
      transform: rotate(1turn);
    }
  }
`
