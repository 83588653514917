import { useCallback, useEffect, useState } from 'react'

/**
 * 무한 스크롤 기능을 구현하는 커스텀 훅
 *
 * @param {Array} data - 전체 데이터 셋
 * @param {Object} triggerPointRef - 더 많은 데이터를 로드하기 위한 트리거 포인트 역할을 하는 React ref 객체
 * @param {number} dataPerLoad - 한 번에 로드할 데이터 항목 수
 * @returns {Object} - 원본 데이터의 길이, 현재 데이터 배열, 로딩 상태 및 전체 목록 로드 여부를 포함하는 객체
 */
export default function useInfiniteScroll(data, triggerPointRef, dataPerLoad) {
  // 로드된 데이터의 수를 저장하는 상태
  const [loadedNum, setLoadedNum] = useState(0)
  // 로딩 상태를 저장하는 상태
  const [isLoading, setIsLoading] = useState(false)
  // 현재 로드된 데이터를 저장하는 상태
  const [currentData, setCurrentData] = useState([])

  // 총 데이터 개수
  const TOTAL_DATA = data.length
  // 데이터 로드가 한계에 도달했는지 여부를 확인하는 변수
  const isReachedLimit = loadedNum >= TOTAL_DATA

  // 데이터를 비동기로 가져오는 함수
  const fetchData = useCallback(
    (loadedNum) => {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(data.slice(loadedNum, loadedNum + dataPerLoad))
        }, 400)
      })
    },
    [data, dataPerLoad]
  )

  // 더 많은 데이터를 로드하는 함수
  const loadMoreData = useCallback(() => {
    if (isLoading || isReachedLimit) return

    setIsLoading(true)

    fetchData(loadedNum).then((result) => {
      setCurrentData((prevData) => [...prevData, ...result])
      setLoadedNum((prevLoadedNum) => prevLoadedNum + result.length)
      setIsLoading(false)
    })
  }, [isLoading, loadedNum, isReachedLimit, fetchData])

  // 트리거 포인트가 화면에 보일 때 데이터를 로드
  useEffect(() => {
    if (!triggerPointRef.current) return

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          loadMoreData()
        }
      },
      { rootMargin: '0px', threshold: 0.1 }
    )

    const target = triggerPointRef.current
    observer.observe(target)

    return () => {
      observer.unobserve(target)
    }
  }, [loadMoreData, triggerPointRef])

  // data prop이 변경될 때 상태를 초기화
  useEffect(() => {
    setCurrentData([])
    setIsLoading(false)
    setLoadedNum(0)
  }, [data])

  return { currentData, isLoading, isReachedLimit }
}
