import { Swiper, SwiperSlide } from 'swiper/react'

import 'swiper/swiper.scss'

import s from '../aiTalk.module.css'
import SpecialItem from './SpecialItem'
import { spacialAiValues } from '../config'

export default function SectionSpecial() {
  const swiperParams = {
    speed: 500,
    autoplay: {
      delay: 2000,
      disableOnInteraction: false
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    pagination: {
      el: '#specialPagination',
      clickable: true,
      bulletActiveClass: s.bulletActive
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 20,
        navigation: {
          enabled: false
        },
        allowTouchMove: true
      },
      751: {
        slidesPerView: 2,
        pagination: {
          enabled: false
        },
        navigation: {
          enabled: true
        },
        allowTouchMove: false
      },
      1280: {
        slidesPerView: 3,
        spaceBetween: 20
      }
    }
  }

  return (
    <section className={s.special}>
      <div className={s.inner}>
        <hgroup className={s.titleGroup}>
          <h3>
            야나두 AI 친구가
            <br />
            특별한 이유
          </h3>
        </hgroup>
        <div className={s.aiList}>
          <Swiper {...swiperParams}>
            <div>
              {spacialAiValues.map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <SpecialItem data={item} />
                  </SwiperSlide>
                )
              })}
            </div>
          </Swiper>
          <div className="swiper-button-next"></div>
          <div className="swiper-button-prev"></div>
          <div id="specialPagination" className={s.specialPagination}></div>
        </div>
      </div>
    </section>
  )
}
