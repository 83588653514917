import React, { useEffect, useMemo } from 'react'
import { useLocation, useParams } from 'react-router-dom'

export default function RedirectTo({ htmlFileName }) {
  const { state } = useLocation()
  const { hashParam } = useParams()

  const destinationUrl = useMemo(() => {
    const hash = hashParam ? `#${hashParam}` : ''
    const section = state?.params ? `#${state.params}` : ''
    return `/promotion-landing/${htmlFileName}.html${section}${hash}`
  }, [htmlFileName, hashParam, state])

  useEffect(() => {
    window.location.href = destinationUrl
  }, [destinationUrl])

  return (
    <h2 aria-hidden="true" style={{ opacity: '0' }}>
      Yanadoo
    </h2>
  )
}
