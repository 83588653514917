import { lazy } from 'react'

const AiInfo = lazy(() => import('pages/aiInfo'))
const AiTalkIntro = lazy(() => import('pages/aiTalk/landing'))
const ChatBot = lazy(() => import('pages/chatBot/ChatBot'))

const AiTalkRoutes = [
  { path: 'AInativespeakertalk', element: <AiTalkIntro /> },
  { path: 'AInativespeakertalk/chat', element: <ChatBot /> },
  { path: 'AInativespeakertalk/info', element: <AiInfo /> }
]

export default AiTalkRoutes
