import React, { useState, useCallback, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import ModalPortal from 'ModalPortal'

import { getCookie, setCookie } from 'common/Cookie'
import OAuth from 'common/OAuth'
import SocialLogin from 'components/SocialLogin'

import ModalFindId from './ModalFindId'
import ModalFindPwd from './ModalFindPwd'
import ModalJoin from './ModalJoin'
import Login from '../Login'

import 'assets/styles/modal.scss'
import 'assets/styles/modalJoin.scss'
import 'assets/styles/modalLogin.scss'
import ResponsiveImage from 'components/ResponsiveImage'

export default function ModalLogin({ page, title, stepType = 'MAIN', btnClose, handleCloseModal, iframe = false }) {
  // 로그인 인트로 + 메인 로그인
  const navigate = useNavigate()
  const location = useLocation()
  const pathName = location.pathname

  //step : INTRO, MAIN
  const [step, setStep] = useState(stepType ? stepType : 'INTRO')
  const [isChecked, setIsChecked] = useState(false)
  const [modalopenJoin, setModalopenJoin] = useState(false)
  const [modalopenFindId, setModalopenFindId] = useState(false)
  const [modalopenFindPwd, setModalopenPwd] = useState(false)
  const [joinPath, setJoinPath] = useState(null)

  const handleOpenModalJoin = () => {
    document.body.classList.add('modal-open')
    setModalopenJoin(true)
  }

  const handleCloseModalJoin = () => {
    setModalopenJoin(false)
  }

  const handleOpenModalFindId = useCallback((e) => {
    document.body.classList.add('modal-open')
    setModalopenFindId(true)
    setStep('MAIN')
  }, [])

  const handleCloseModalFindId = useCallback((e) => {
    document.body.classList.remove('modal-open')
    setModalopenFindId(false)
  }, [])

  const handleOpenModalFindPwd = useCallback((e) => {
    document.body.classList.add('modal-open')
    setModalopenPwd(true)
    setStep('MAIN')
  }, [])

  const handleCheckedItem = () => {
    setIsChecked(!isChecked)
  }

  const handleLoginPage = () => {
    setCookie('orderClickCheck', 'N')
    if (page) {
      // historyUrl 쿠키값이 저장되어 있다면 해당 URL로 페이지 이동.
      if (getCookie('historyUrl') !== '') {
        if (window.location.href !== getCookie('historyUrl')) window.location.href = getCookie('historyUrl')
      } else {
        navigate('/')
      }
    } else {
      if (iframe) {
        handleCloseModal('close')
      } else {
        handleCloseModal()
      }
    }
  }

  const checkWhereJoin = () => {
    if (pathName.includes('/store/detail')) {
      setJoinPath('CLASS_REGISTRATION') // 12
    } else {
      setJoinPath('YANADOO_WEB') // 90
    }
  }

  useEffect(() => {
    checkWhereJoin()
  }, [pathName])

  return (
    <>
      <div className="modal-wrap modal-form login">
        <div className="dimmed" onClick={handleLoginPage}></div>
        <div className="modal-inner">
          {btnClose && (
            <button type="button" className="btn-modal-close" onClick={handleLoginPage}>
              <i className="blind">닫기</i>
            </button>
          )}
          <div className="modal-header">
            <strong>{title}</strong>
          </div>
          <div className="modal-content">
            {step === 'INTRO' && (
              <h3>
                <i className="blind">야나두</i>
              </h3>
            )}
            <div className="flex-box">
              {step === 'INTRO' ? (
                <>
                  <ul className="login-select-box">
                    <li>
                      <a className="kakao" onClick={() => OAuth.handleKakaoLogin(joinPath)}>
                        카카오 계정으로 로그인
                      </a>
                    </li>
                    <li>
                      <a className="apple" onClick={() => OAuth.handleAppleLogin(joinPath)}>
                        Apple로 로그인
                      </a>
                    </li>
                    <li>
                      <a className="yanadoo" onClick={() => setStep('MAIN')}>
                        야나두 계정으로 로그인
                      </a>
                    </li>
                  </ul>
                  <ul className="find-account-box">
                    <li>
                      <a onClick={handleOpenModalFindId}>아이디 찾기</a>
                    </li>
                    <li>
                      <a onClick={handleOpenModalFindPwd}>비밀번호 찾기</a>
                    </li>
                  </ul>
                </>
              ) : (
                <>
                  <Login handleCloseModal={iframe ? () => handleCloseModal('login') : handleCloseModal} checked={isChecked} reloadType />
                  <div className="option-box">
                    <label htmlFor="smsAgree" className="checkbox-flex-form lg-chk">
                      <input type="checkbox" id="smsAgree" checked={isChecked ? true : false} onChange={handleCheckedItem} />
                      <span className="chk"></span>
                      로그인 상태 유지
                    </label>
                    <ul className="find-account-box">
                      <li>
                        <a onClick={handleOpenModalFindId}>아이디 찾기</a>
                      </li>
                      <li>
                        <a onClick={handleOpenModalFindPwd}>비밀번호 찾기</a>
                      </li>
                    </ul>
                  </div>
                </>
              )}
              <SocialLogin joinPath={joinPath} iframe={iframe} />
            </div>
            <div className="signup-group">
              <div className="signup-img">
                <ResponsiveImage pc="https://english.yanadoocdn.com/upload/yanadoo/new/common/img_pc_signin_tip.png" mo="https://english.yanadoocdn.com/upload/yanadoo/new/common/img_m_signin_tip.png" alt="회원가입" maxWidth={1024} />
              </div>
              <button type="button" className="btn-signup" onClick={handleOpenModalJoin}>
                <span>회원가입하기</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      {modalopenJoin ? (
        <ModalPortal>
          <ModalJoin btnClose type={'DEFAULT'} handleCloseModal={iframe ? () => handleCloseModal('close') : handleCloseModal} handleCloseModalJoin={handleCloseModalJoin} handleOpenModalFindId={handleOpenModalFindId} whereJoinType={joinPath} />
        </ModalPortal>
      ) : null}
      {modalopenFindId ? (
        <ModalPortal>
          <ModalFindId title={'ID 찾기'} btnClose handleCloseModalFindId={handleCloseModalFindId} handleCloseModal={iframe ? () => handleCloseModal('close') : handleCloseModal} />
        </ModalPortal>
      ) : null}
      {modalopenFindPwd ? (
        <ModalPortal>
          <ModalFindPwd title={'비밀번호 찾기'} btnClose handleCloseModal={iframe ? () => handleCloseModal('close') : handleCloseModal} />
        </ModalPortal>
      ) : null}
    </>
  )
}
