import React, { useEffect, useState } from 'react'
import s from './AgreeOrder.module.css'
import ModalTerms from 'components/modal/ModalTerms'
import ModalPrivacy from 'components/modal/ModalPrivacy'

const AGREE_VALUES = [
  {
    name: 'policy',
    title: '주문 상품정보 및 서비스 이용약관에 대한 동의(필수)',
    content: '주문하실 상품, 가격, 배송정보, 할인내역, 이용약관, 환불 규정 등을 최종 확인하였으며, 구매에 동의하시겠습니까? (전자상거래법 제8조 2항)'
  },
  {
    name: 'privacy',
    title: '개인정보 수집, 이용 동의(필수)',
    content: '주문, 배송을 위해 고객님께서 입력하신 정보의 관리는 당사의 개인정보처리방침을 준용합니다.'
  }
]

export default function AgreeOrder({ isChecked, setIsChecked }) {
  const [agree, setAgree] = useState({ policy: false, privacy: false })
  const [modal, setModal] = useState({ policy: false, privacy: false })

  const handleModal = (key) => {
    setModal({ ...modal, [key]: !modal[key] })
  }

  const handleAgreeState = (e) => {
    const agreeKey = e.target.name
    const agreeCehcked = e.target.checked

    switch (agreeKey) {
      case 'all':
        setAgree({ policy: agreeCehcked, privacy: agreeCehcked })
        break
      default:
        setAgree((prev) => ({ ...prev, [agreeKey]: agreeCehcked }))
        setIsChecked((prev) => ({ ...prev, agreeOrder: agree.policy && agree.privacy }))
        break
    }
  }

  useEffect(() => {
    if (agree) {
      const checked = agree.policy && agree.privacy
      setIsChecked((prev) => ({ ...prev, agreeOrder: checked }))
    }
  }, [agree])

  return (
    <>
      <section className={s.agree}>
        <hgroup>
          <label htmlFor="all">
            <input type="checkbox" id="all" name="all" checked={agree.policy && agree.privacy} onChange={handleAgreeState} />
            <span>아래 내용에 모두 동의합니다.</span>
          </label>
        </hgroup>
        <ul className={isChecked ? s.hide : s.active}>
          {AGREE_VALUES.map((item, index) => {
            return (
              <li key={index}>
                <label htmlFor={item.name}>
                  <input type="checkbox" id={item.name} name={item.name} checked={agree[item.name]} onChange={handleAgreeState} />
                  <span>{item.title}</span>
                </label>
                <div className={s.summary}>
                  <p>{item.content}</p>
                  <button onClick={() => handleModal(item.name)}>약관보기</button>
                </div>
              </li>
            )
          })}
        </ul>
      </section>
      {modal.policy && <ModalTerms close={handleModal} />}
      {modal.privacy && <ModalPrivacy close={handleModal} />}
    </>
  )
}
