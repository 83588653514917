import { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import api from 'common/api';

import styled, { ThemeProvider } from 'styled-components';
import theme from 'common/theme';
import BoardListReviewItem from 'components/board/BoardListReviewItem';
import Loading from 'components/common/Loading';

import ModalPortal from 'ModalPortal';
import ModalReviewDetail from './ModalReviewDetail';

import ico_all_b from '../../assets/images/ico_all_b.png';
import ico_all_w from '../../assets/images/ico_all_w.png';
import ico_check from '../../assets/images/ico_check_216x216w.png';
import ico_arrow from '../../assets/images/ico_arrow_24x42_b.png';
import ico_arrow_w from '../../assets/images/ico_arrow_60x60w.png';
import ico_close_b from '../../assets/images/ico_close_72x72b.png';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Navigation } from 'swiper';
//style
import '../../assets/styles/modal.scss';
import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';

SwiperCore.use([Pagination, Navigation]);

const ModalImageDetail = ({
  type,
  seq,
  deviceCheck,
  title,
  btnClose,
  handleCloseModal,
  handleOpenImageCollectAllModal,
  apiInfo,
  handleSettingSeq,
  categoryData,
  handleCategoryCodeList,
}) => {
  const [defaultSeq, setDefaultSeq] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isRollingLoading, setIsRollingLoading] = useState(true);
  const [index, setIndex] = useState(1);

  const [modalopen, setModalopen] = useState(false);
  const [communityInfo, setCommunityInfo] = useState(null);
  const [rollingData, setRollingData] = useState([]);

  const [images, setImages] = useState(null);

  const [activeAttachment, setActiveAttachment] = useState({});

  const onClickActive = (attachment, idx) => {
    if (activeAttachment.idx !== idx) {
      setActiveAttachment({ ...attachment, idx: idx });
    }
  };

  // 첨부파일 롤링 이전, 다음 버튼 클릭시
  const onClickRollingMove = (type) => {
    if (isRollingLoading) return;
    setIsRollingLoading(true);

    if (type === 'PREV') {
      setIndex(index - 1);
    } else {
      setIndex(index + 1);
    }
  };

  // 첨부파일 롤링 영역 아이템 클릭 시 데이터 변경
  const onClickChange = (data) => {
    setIsLoading(true);
    handleSettingSeq(data);
  };

  // 이미지 후기 상세 정보
  const getCommunityInfo = () => {
    (type === 'SRR'
      ? api.get(apiInfo.apiDetailUrl + `${seq.learningReviewSeq}`)
      : seq.scholarshipSeq
      ? api.get(
          apiInfo.apiDetailScholarshipUrl +
            `${seq.scholarshipSeq}/${seq.scholarshipRefundHistorySeq}`
        )
      : api.get(apiInfo.apiDetailUrl + `${seq.learningReviewSeq}`)
    )

      .then((response) => {
        setCommunityInfo(response.data.data);
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // 모바일에서 이전 글, 다음 글 클릭 시
  const onClickMove = (moveType) => {
    let categoryCode;
    if (categoryData) {
      categoryCode = handleCategoryCodeList(categoryData.category, 'categories');
    }
    (type === 'SRR'
      ? api.get(apiInfo.apiPrevNextUrl + `${moveType}&learningReviewSeq=${seq.learningReviewSeq}`)
      : seq.scholarshipSeq
      ? api.get(
          apiInfo.apiPrevNextUrl +
            `${moveType}&reviewType=SCHOLARSHIP&scholarshipSeq=${seq.scholarshipSeq}&scholarshipRefundHistorySeq=${seq.scholarshipRefundHistorySeq}${categoryCode}`
        )
      : api.get(
          apiInfo.apiPrevNextUrl +
            `${moveType}&reviewType=LEARNING&learningReviewSeq=${seq.learningReviewSeq}${categoryCode}`
        )
    )

      .then((response) => {
        if (response.data.meta.code !== 200) {
          alert(response.data.meta.message);
        } else {
          setIsLoading(true);
          handleSettingSeq(response.data.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // 첨부파일 롤링 조회
  const getRollingAttachment = (seq, index) => {
    let categoryCode;
    if (categoryData) {
      categoryCode = handleCategoryCodeList(categoryData.category, 'categories');
    }
    (type === 'SRR'
      ? api.get(apiInfo.apiRollingUrl + `${seq.learningReviewSeq}&pageIndex=${index}&pageSize=9`)
      : seq.scholarshipSeq
      ? api.get(
          apiInfo.apiRollingUrl +
            `SCHOLARSHIP&scholarshipRefundHistorySeq=${seq.scholarshipRefundHistorySeq}&scholarshipSeq=${seq.scholarshipSeq}&pageIndex=${index}&pageSize=9${categoryCode}`
        )
      : api.get(
          apiInfo.apiRollingUrl +
            `LEARNING&learningReviewSeq=${seq.learningReviewSeq}&pageIndex=${index}&pageSize=9${categoryCode}`
        )
    )

      .then((response) => {
        setRollingData(response.data.data);
        setIsRollingLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleOpenReviewDetailModal = useCallback((images) => {
    document.body.classList.add('modal-open');
    setImages(images);
    setModalopen(true);
  }, []);

  const handleCloseReviewDetailModal = useCallback(() => {
    document.body.classList.remove('modal-open');
    setModalopen(false);
  }, []);

  useEffect(() => {
    setDefaultSeq(seq);
  }, []);

  useEffect(() => {
    getCommunityInfo();
    setIsLoading(true);
  }, [seq]);

  useEffect(() => {
    if (Object.keys(defaultSeq).length !== 0) {
      getRollingAttachment(defaultSeq, index);
    }
  }, [defaultSeq, index, deviceCheck]);

  useEffect(() => {
    if (!isLoading) {
      seq.scholarshipSeq
        ? setActiveAttachment({ ...communityInfo.scholarshipRefundHistoryImageList[0], idx: 0 })
        : setActiveAttachment({ ...communityInfo.attachments[0], idx: 0 });
    }
  }, [isLoading]);

  return (
    <ThemeProvider theme={theme}>
      <div className="modal-wrap modal-image">
        <div className="dimmed" onClick={handleCloseModal}></div>
        <div className="modal-inner">
          {btnClose && (
            <ButtonClose type="button" onClick={handleCloseModal}>
              <i className="blind">닫기</i>
            </ButtonClose>
          )}
          <div className="modal-header">
            <ButtonAll
              onClick={() => {
                handleOpenImageCollectAllModal();
                handleCloseModal();
              }}
            >
              <theme.Blind>이미지 전체보기 팝업 열기</theme.Blind>
              <span>전체보기</span>
            </ButtonAll>
            <strong>{title}</strong>
          </div>
          <div className="modal-content">
            {!isLoading ? (
              <>
                <ImageDetailBox>
                  {!deviceCheck &&
                    (seq.scholarshipSeq ? (
                      <ThumbButton
                        onClick={() => {
                          handleOpenReviewDetailModal(
                            communityInfo.scholarshipRefundHistoryImageList
                          );
                        }}
                        style={{
                          backgroundImage: `url(${activeAttachment.imageUrl})`,
                        }}
                      ></ThumbButton>
                    ) : (
                      <ThumbButton
                        onClick={() => {
                          handleOpenReviewDetailModal(communityInfo.attachments);
                        }}
                        style={{
                          backgroundImage: `url(${
                            activeAttachment.url || activeAttachment.thumbnailPath
                          })`,
                        }}
                      ></ThumbButton>
                    ))}
                  {deviceCheck && (
                    <>
                      {seq.scholarshipSeq ? (
                        <SwiperBox>
                          <Swiper
                            className="swiper-container"
                            pagination={
                              communityInfo?.scholarshipRefundHistoryImageList.length > 1 && {
                                type: 'fraction',
                              }
                            }
                            centeredSlides={true}
                            slidesPerView="auto"
                            autoHeight={true}
                            loop={true}
                            // autoplay={{
                            //   delay: 3000,
                            //   disableOnInteraction: false,
                            // }}
                          >
                            {communityInfo?.scholarshipRefundHistoryImageList.length > 0 &&
                              communityInfo?.scholarshipRefundHistoryImageList.map(
                                (attachment, idx) => {
                                  return (
                                    <SwiperSlide
                                      key={idx}
                                      onClick={() => {
                                        handleOpenReviewDetailModal(
                                          communityInfo.scholarshipRefundHistoryImageList
                                        );
                                      }}
                                    >
                                      <span
                                        style={{
                                          backgroundImage: `url(${attachment.imageUrl})`,
                                        }}
                                      ></span>
                                    </SwiperSlide>
                                  );
                                }
                              )}
                          </Swiper>
                        </SwiperBox>
                      ) : (
                        <SwiperBox>
                          <Swiper
                            className="swiper-container"
                            pagination={
                              communityInfo?.attachments.length > 1 && { type: 'fraction' }
                            }
                            centeredSlides={true}
                            slidesPerView="auto"
                            autoHeight={true}
                            loop={true}
                            // autoplay={{
                            //   delay: 3000,
                            //   disableOnInteraction: false,
                            // }}
                          >
                            {communityInfo?.attachments.length > 0 &&
                              communityInfo?.attachments.map((attachment, idx) => {
                                return (
                                  <SwiperSlide
                                    key={idx}
                                    onClick={() => {
                                      handleOpenReviewDetailModal(communityInfo.attachments);
                                    }}
                                  >
                                    <span
                                      style={{
                                        backgroundImage: `url(${
                                          attachment.url || attachment.thumbnailPath
                                        })`,
                                      }}
                                    ></span>
                                  </SwiperSlide>
                                );
                              })}
                          </Swiper>
                        </SwiperBox>
                      )}
                      <PrevNextBox>
                        <button
                          onClick={() => {
                            onClickMove('PREV');
                          }}
                          className="prev"
                        >
                          이전 글
                        </button>
                        <button
                          onClick={() => {
                            onClickMove('NEXT');
                          }}
                          className="next"
                        >
                          다음 글
                        </button>
                      </PrevNextBox>
                    </>
                  )}
                  {seq.scholarshipSeq ? (
                    <DetailBox>
                      <BoardListReviewItem
                        type={'IMAGE'}
                        deviceCheck={deviceCheck}
                        title={'장학금 후기'}
                        registDate={communityInfo.registDate}
                        backgroundImage={communityInfo.profileImageUrl}
                        userName={communityInfo.username}
                        satisfaction={communityInfo.satisfaction}
                      />
                      {!deviceCheck && (
                        <ImageLists>
                          {communityInfo?.scholarshipRefundHistoryImageList.length > 0 &&
                            communityInfo?.scholarshipRefundHistoryImageList.map(
                              (attachment, idx) => {
                                return (
                                  <li key={idx}>
                                    <button
                                      className={activeAttachment.idx === idx ? 'active' : ''}
                                      onClick={() => {
                                        onClickActive(attachment, idx);
                                      }}
                                      style={{
                                        backgroundImage: `url(${attachment.imageUrl})`,
                                      }}
                                    ></button>
                                  </li>
                                );
                              }
                            )}
                        </ImageLists>
                      )}
                      <p>{communityInfo.contents}</p>
                      <Link
                        to={
                          `/courseReview/detail/` +
                          seq.scholarshipSeq +
                          '/' +
                          seq.scholarshipRefundHistorySeq
                        }
                      >
                        본문 보기
                      </Link>
                    </DetailBox>
                  ) : (
                    <DetailBox>
                      <BoardListReviewItem
                        type={'IMAGE'}
                        deviceCheck={deviceCheck}
                        title={communityInfo?.classes?.name || communityInfo.title}
                        registDate={communityInfo.registDate}
                        backgroundImage={
                          communityInfo?.registUser?.profileImageUrl || communityInfo.thumbnailPath
                        }
                        userName={communityInfo?.registUser?.nickName || communityInfo.nickName}
                        satisfaction={communityInfo.satisfaction}
                      />
                      {!deviceCheck && (
                        <ImageLists>
                          {communityInfo?.attachments.length > 0 &&
                            communityInfo?.attachments.map((attachment, idx) => {
                              return (
                                <li key={idx}>
                                  <button
                                    className={activeAttachment.idx === idx ? 'active' : ''}
                                    onClick={() => {
                                      onClickActive(attachment, idx);
                                    }}
                                    style={{
                                      backgroundImage: `url(${
                                        attachment.url || attachment.thumbnailPath
                                      })`,
                                    }}
                                  ></button>
                                </li>
                              );
                            })}
                        </ImageLists>
                      )}
                      <p>{communityInfo.contents}</p>
                      <Link
                        to={
                          type === 'SRR'
                            ? `/community/review/detail/` + seq.learningReviewSeq
                            : `/courseReview/detail/` + seq.learningReviewSeq + '/0'
                        }
                      >
                        본문 보기
                      </Link>
                    </DetailBox>
                  )}
                </ImageDetailBox>
                {!deviceCheck && (
                  <RollingImageBox>
                    <button
                      disabled={rollingData?.isFirstPage}
                      className="btn-prev"
                      onClick={() => {
                        onClickRollingMove('PREV');
                      }}
                    >
                      <theme.Blind>이전</theme.Blind>
                    </button>
                    {!isRollingLoading ? (
                      <ul>
                        {rollingData?.attachments.length > 0 ? (
                          rollingData.attachments.map((attachment, idx) => {
                            return (
                              <li key={idx}>
                                <button
                                  onClick={() => {
                                    onClickChange(attachment);
                                  }}
                                  style={{
                                    backgroundImage: `url(${attachment.url})`,
                                  }}
                                  className={
                                    attachment.learningReviewSeq === seq.learningReviewSeq ||
                                    attachment.scholarshipSeq === seq.scholarshipSeq
                                      ? 'active'
                                      : ''
                                  }
                                >
                                  {attachment.count > 1 && <span>{attachment.count}</span>}
                                </button>
                              </li>
                            );
                          })
                        ) : (
                          <></>
                        )}
                      </ul>
                    ) : (
                      <Loading />
                    )}

                    {rollingData && (
                      <button
                        disabled={rollingData?.isLastPage}
                        className="btn-next"
                        onClick={() => {
                          onClickRollingMove('NEXT');
                        }}
                      >
                        <theme.Blind>다음</theme.Blind>
                      </button>
                    )}
                  </RollingImageBox>
                )}
              </>
            ) : (
              <Loading />
            )}
          </div>
        </div>
      </div>
      {modalopen ? (
        <ModalPortal>
          <ModalReviewDetail
            deviceCheck={deviceCheck}
            handleCloseModal={handleCloseReviewDetailModal}
            images={images}
          />
        </ModalPortal>
      ) : null}
    </ThemeProvider>
  );
};

export default ModalImageDetail;

const ButtonClose = styled.button`
  position: absolute;
  top: 1.4rem;
  right: 2rem;
  z-index: 10;
  width: 4.2rem;
  height: 4.2rem;
  padding: 0;
  background-size: 100% auto;
  background-image: url(${ico_close_b});
  background-position: 50% 50%;
  background-color: transparent;

  @media ${({ theme }) => theme.device.mobile} {
    top: 1.8rem;
    right: 3.4rem;
    width: 7.2rem;
    height: 7.2rem;
  }
`;

const ButtonAll = styled.button`
  position: absolute;
  top: 1.5rem;
  right: 8.2rem;
  padding: 1rem 1.6rem 1rem 3.65rem;
  line-height: 1.5;
  background-repeat: no-repeat;
  background-size: 18% auto;
  background-image: url(${ico_all_w});
  background-position: 18% 54%;
  background-color: #28284b;
  border-radius: 0.4rem;

  span {
    font-size: 1.4rem;
    color: #fff;
  }

  @media ${({ theme }) => theme.device.mobile} {
    top: 1.8rem;
    left: 3.4rem;
    right: auto;
    width: 7.2rem;
    height: 7.2rem;
    padding: 0;
    background-size: 100% auto;
    background-image: url(${ico_all_b});
    background-position: 50% 50%;
    background-color: transparent;

    span {
      display: none;
    }
  }
`;

const ImageDetailBox = styled.div`
  display: flex;
  position: relative;
  align-items: flex-start;
  justify-content: space-between;
  padding: 3.2rem;

  @media ${({ theme }) => theme.device.mobile} {
    display: block;
    padding: 0;
  }
`;

const ThumbButton = styled.button`
  flex: 0 0 40.7rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  width: 100%;
  height: 40.7rem;
`;

const SwiperBox = styled.div`
  position: relative;
  overflow: hidden;

  .swiper-pagination {
    display: inline-block;
    width: 8rem;
    position: absolute;
    left: auto;
    bottom: 2rem;
    right: 2rem;
    padding: 1.1rem 0 0.9rem;
    background-color: rgba(40, 40, 75, 0.65);
    border-radius: 0.4rem;
    color: #fff;
    font-weight: 700;
    font-size: 2rem;
    white-space: nowrap;
  }

  .swiper-slide {
    position: relative;
    overflow: hidden;

    &::before {
      content: '';
      display: block;
      padding-top: 100%;
    }

    span {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover;
    }
  }
`;

const PrevNextBox = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 3.2rem 3.4rem;
  background-color: #28284b;
  box-sizing: border-box;

  button {
    position: relative;
    font-size: 2.7rem;
    color: #fff;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      width: 3.6rem;
      height: 3.6rem;
      margin-top: -1.8rem;
      background: url(${ico_arrow_w}) 50% 50% / 100% auto no-repeat;
    }

    &.prev {
      padding-left: 3.6rem;

      &::after {
        left: 0;
        transform: rotate(180deg);
      }
    }

    &.next {
      padding-right: 3.6rem;

      &::after {
        right: 0;
      }
    }
  }
`;
const DetailBox = styled.div`
  flex: 1 1 auto;
  margin-left: 2.6rem;
  box-sizing: border-box;

  p {
    ${theme.StyleEllipsis}
    margin-top: 2.7rem;
    font-size: 1.6rem;
    line-height: 1.5;
    -webkit-line-clamp: 3;
    word-break: break-word !important;
  }

  a {
    display: inline-block;
    margin-top: 2.9rem;
    padding: 1.6rem 6.55rem 1.3rem;
    background-color: #ffb900;
    border-radius: 0.4rem;
    font-size: 1.6rem;
  }

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    margin: 0;
    padding: 3rem 4.2rem;
    font-size: 2.6rem;

    p {
      margin-top: 6.5rem;
      font-size: 2.6rem;
      ${theme.StyleEllipsisTwo}
      -webkit-line-clamp: 3;
    }

    a {
      margin-top: 3rem;
      padding: 1.9rem 4.8rem 1.7rem;
      font-size: 2.4rem;
    }
  }
`;
const ImageLists = styled.ul`
  display: flex;
  margin-top: 2.3rem;

  li {
    flex: 0 1 7rem;

    button {
      display: block;
      position: relative;
      width: 100%;
      height: 7rem;
      background-repeat: no-repeat;
      background-size: cover;

      &.active {
        &::after {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          background: rgba(40, 40, 75, 0.65) url(${ico_check}) 50% 50% no-repeat;
          background-size: 33% auto;
        }
      }
    }

    & + li {
      margin-left: 0.5rem;
    }
  }
`;

const RollingImageBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 14.8rem;
  padding: 0 3.2rem;
  background-color: #f7f7fc;
  box-sizing: border-box;

  > button {
    flex: 0 0 2.4rem;
    height: 2.4rem;
    background: url(${ico_arrow}) 50% 50% no-repeat;
    background-size: 46% auto;

    &:disabled {
      opacity: 0.15;
      cursor: default;
    }
  }

  .btn-prev {
    margin-right: 1.5rem;
  }

  .btn-next {
    margin-left: 1.5rem;
    transform: rotate(180deg);
  }

  ul {
    display: flex;
    flex: 0 1 auto;
    width: 100%;

    li {
      flex: 0 1 8.4rem;
      margin: 0.4rem;

      button {
        display: block;
        position: relative;
        width: 100%;
        height: 8.4rem;
        background-repeat: no-repeat;
        background-size: cover;

        &.active {
          &::after {
            content: '';
            position: absolute;
            top: -0.5rem;
            right: -0.5rem;
            left: -0.5rem;
            bottom: -0.5rem;
            border: 0.5rem solid #ffb900;
          }
        }

        span {
          position: absolute;
          bottom: 0;
          right: 0;
          min-width: 2.8rem;
          padding: 0.64rem 0 0.5rem;
          font-family: 'Inter';
          font-weight: 700;
          font-size: 1.4rem;
          color: #fff;
          background-color: rgba(40, 40, 75, 0.6);
          text-align: center;
          box-sizing: border-box;
        }
      }
    }
  }
`;
