import { useEffect } from 'react'

import s from './MobileTitleBar.module.scss'

export default function MobileTitleBar({ title, onClick }) {
  useEffect(() => {
    document.querySelector('header').classList.add('header-overflow')
    return () => {
      document.querySelector('header').classList.remove('header-overflow')
    }
  }, [])
  return (
    <div className={s.mobileTitleBar}>
      <button type="button" onClick={onClick}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="76"
          height="76"
          viewBox="0 0 76 76"
          fill="none"
        >
          <path
            d="M36.8737 55.213L20.207 38.1675L36.8737 21.1221"
            stroke="black"
            strokeWidth="3.125"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M23.332 38.167H56.6654"
            stroke="black"
            strokeWidth="3.125"
            strokeLinecap="round"
          />
        </svg>
      </button>
      <h3>{title}</h3>
    </div>
  )
}
