import { useState } from 'react'

import ResponsiveImage from 'components/ResponsiveImage'

import s from './TrainingStep.module.css'
import { HOST_CDN } from '../../config'
import BtnPulse from '../BtnPulse'

export default function TrainingStep04() {
  const [target, setTarget] = useState({ one: false, two: false })

  const handleToggle = (key) => {
    setTarget({ ...target, [key]: !target[key] })
  }

  return (
    <div className={`${s.step} ${s.step04}`}>
      <span className={s.btn01}>
        <BtnPulse active event={() => handleToggle('one')} />
      </span>
      <span className={s.tooltip01}>
        {target.one && (
          <ResponsiveImage
            pc={`${HOST_CDN}step_04_hover_01.png`}
            mo={`${HOST_CDN}step_04_hover_01_m.png`}
            alt={'진짜 원어민처럼 교정해 줘요'}
            maxWidth={1024}
          />
        )}
      </span>
      <span className={s.btn02}>
        <BtnPulse event={() => handleToggle('two')} />
      </span>
      <span className={s.tooltip02}>
        {target.two && (
          <ResponsiveImage
            pc={`${HOST_CDN}step_04_hover_02.png`}
            mo={`${HOST_CDN}step_04_hover_02_m.png`}
            alt={'전화 영어보다 편한 24시간 OK 말하기 연습'}
            maxWidth={1024}
          />
        )}
      </span>
    </div>
  )
}
