import { useRef, useState, useCallback } from 'react'

import ModalPortal from 'ModalPortal'
import styled from 'styled-components'
import SwiperCore, { Pagination, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import ModalVod from 'components/modal/ModalVod'

import 'swiper/swiper.scss'
import SectionTitle from './SectionTitle'
import { vodListValues } from '../config'
import bgArrowGrey from 'assets/images/ico_arrow_168x168g.png'
SwiperCore.use([Pagination, Autoplay])
//data

export default function VodReviewSection() {
  const navigationPrevRef = useRef(null)
  const navigationNextRef = useRef(null)

  const [modalOpen, setModalOpen] = useState(false)
  const [mediaKey, setMediaKey] = useState(null)
  const handleOpenModal = useCallback((url) => {
    document.body.classList.add('modal-open')
    setModalOpen(true)
    setMediaKey(url)
  }, [])

  const handleCloseModal = useCallback((e) => {
    document.body.classList.remove('modal-open')
    setModalOpen(false)
  }, [])

  return (
    <>
      <VodSection>
        <InnerCol padding="0">
          <SectionTitle title={'리얼 성장 스토리로\n‘할 수 있는’ 자신감 UP!'} />
          <Swiper
            spaceBetween={0}
            centeredSlides={true}
            loop={true}
            className="mySwiper"
            navigation={{
              prevEl: navigationPrevRef.current,
              nextEl: navigationNextRef.current
            }}
            breakpoints={{
              1024: {
                slidesPerView: 2
              },
              320: {
                slidesPerView: 1.5
              }
            }}
            onSwiper={(swiper) => {
              setTimeout(() => {
                swiper.params.navigation.prevEl = navigationPrevRef.current
                swiper.params.navigation.nextEl = navigationNextRef.current
                swiper.navigation.destroy()
                swiper.navigation.init()
                swiper.navigation.update()
              })
            }}
          >
            {vodListValues?.map((list, idx) => {
              return (
                <SwiperSlide key={list.sortOrder}>
                  <button
                    type="button"
                    onClick={() => {
                      handleOpenModal(list.linkPath)
                    }}
                  >
                    <img src={list.imagePath1} alt="" />
                    <VodInfoBox>
                      <p>{list.subTitle}</p>
                      <strong>{list.title}</strong>
                    </VodInfoBox>
                  </button>
                </SwiperSlide>
              )
            })}
          </Swiper>
          <div className="btn-wrap">
            <button type="button" className="btn-slide prev" ref={navigationPrevRef}>
              <em className="blind">PREV</em>
            </button>
            <button type="button" className="btn-slide next" ref={navigationNextRef}>
              <em className="blind">NEXT</em>
            </button>
          </div>
        </InnerCol>
        {modalOpen ? (
          <ModalPortal>
            <ModalVod btnClose handleCloseModal={handleCloseModal} iframeUrl={mediaKey} />
          </ModalPortal>
        ) : null}
      </VodSection>
    </>
  )
}
const InnerCol = styled.div`
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 3rem;
  @media ${({ theme }) => theme.device.mobile} {
    padding: ${(props) => props.padding || `0 4.2rem`};
  }
`

const VodSection = styled.section`
  padding: 18rem 0;

  .swiper-container {
    margin-top: 8rem;
  }
  .swiper-slide {
    transition: all 0.3s;
  }
  .swiper-slide-active {
    transform: scale(1);
  }
  .swiper-slide-prev {
    transform: scale(0.485) translate(50%, -53%);
  }
  .swiper-slide-next {
    transform: scale(0.485) translate(-50%, -53%);
  }
  .btn-wrap {
    position: relative;
    width: 15.2rem;
    height: 5.6rem;
    margin: 5.6rem auto 0;

    .btn-slide {
      position: absolute;
      width: 5.6rem;
      height: 5.6rem;
      em {
        position: absolute;
        clip: rect(0 0 0 0);
        width: 1px;
        height: 1px;
        margin: -1px;
        overflow: hidden;
      }
      &:after {
        content: '';
        display: inline-block;
        width: inherit;
        height: inherit;
        background-size: 100%;
        background-repeat: no-repeat;
      }
    }

    .btn-slide.prev {
      left: 0;

      &::after {
        background-image: url(${bgArrowGrey});
        transform: rotate(-180deg);
      }
    }

    .btn-slide.next {
      right: 0;

      &::after {
        background-image: url(${bgArrowGrey});
      }
    }

    .btn-slide.swiper-button-disabled {
      /* display: block; */
      opacity: 0.1;
    }
  }

  @media ${({ theme }) => theme.device.mobile} {
    .swiper-container {
      margin-top: 7.2rem;
    }
    .swiper-slide-prev {
      transform: scale(0.6) translate(27%, -33%);
    }
    .swiper-slide-next {
      transform: scale(0.6) translate(-27%, -33%);
    }
    .swiper-slide-active {
      transform: scale(1);
    }

    .btn-wrap {
      display: none;
    }
  }
`
const VodInfoBox = styled.div`
  margin: 1.6rem 0 0;
  text-align: left;
  p {
    margin: 0 0 0.8rem;
    font-weight: 400;
    font-size: 2rem;
    line-height: 2.6rem;
    color: #717188;
  }
  strong {
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 3rem;
    letter-spacing: -0.5px;
  }

  @media ${({ theme }) => theme.device.mobile} {
    margin: 2.4rem 0 0;
    p {
      font-size: 2.4rem;
    }
    strong {
      font-size: 3.2rem;
    }
  }
`
