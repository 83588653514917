import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router'

import AuthService from 'services/authService'

import { setCookie } from 'common/Cookie'

export default function useInitialApp() {
  const navigate = useNavigate()
  const currentPath = useLocation().pathname

  //  채널톡 / 윙배너 보여주는 함수
  useEffect(() => {
    window['wingBannerDisplay']()

    // 현재 페이지 URL을 쿠키값 historyUrl 에 저장합니다. (특정문자열을 포함한 URL 제외)
    // /login : 로그인 페이지 URL 제외.
    // /mypage/1 : 마이페이지 홈 제외. /mypage/1로 지정한 이유는, 장바구니 페이지가 mypage/order/~ 타입으로 /1 문자열까지 체크 필요.
    // /oauth : 소셜 로그인 타입 URL 제외.
    if (
      window.location.href.indexOf('/login') === -1 &&
      window.location.href.indexOf('/mypage/1') === -1 &&
      window.location.href.indexOf('/oauth') === -1
    ) {
      setCookie('historyUrl', window.location.href)
    }
  }, [navigate])

  useEffect(() => {
    let checkAdn = setTimeout(() => {
      if (document.querySelector('body').classList.contains('adn-none')) {
        document.querySelector('html').style.height = ''
        document.querySelector('html').style.overflow = ''
        document.querySelector('body').style.height = ''
        document.querySelector('body').style.overflow = ''
      }
    }, 1000)

    return () => clearTimeout(checkAdn)
  }, [currentPath])

  // 첫 번째 로그인 시 신규회원 혜택 랜딩페이지로 이동
  useEffect(() => {
    const userInfo = AuthService.getUserInfo()
    if (!userInfo) return
    if (userInfo.isFirstLogin) {
      navigate('/promotion-landing/newBenefit')
      localStorage.setItem('user', JSON.stringify({ ...userInfo, isFirstLogin: false }))
    }
  }, [navigate])

  useEffect(() => {
    const bodyItem = document.querySelector('body')
    const footerItem = document.querySelector('footer')
    const sectionOptions = {
      threshold: 0
    }
    const sectionObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0) {
          bodyItem.classList.add('scroll-bottom')
        } else {
          bodyItem.classList.remove('scroll-bottom')
        }
      })
    }, sectionOptions)
    if (footerItem) sectionObserver.observe(footerItem)

    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])
}
