import { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'

import s from 'pages/english/EnglishHome.module.css'

import DigitCounter from 'components/DigitCounter'
import { StaggerMotion } from 'components/promotion/utile/utile'
import ResponsiveImage from 'components/ResponsiveImage'

import { shortsListValues } from '../config'

export default function YutubeSection() {
  const motionRef = useRef(null)
  const observeRef = useRef(null)
  const staggerInstance = new StaggerMotion(motionRef, 0, s.active)

  useEffect(() => {
    const observer = new IntersectionObserver(
      (els) => {
        els.forEach((el) => {
          if (el.isIntersecting) {
            setTimeout(() => {
              staggerInstance.staggerOn(motionRef)
            }, 300)
          } else {
            staggerInstance.staggerOff(motionRef)
          }
        })
      },
      {
        threshold: 1
      }
    )

    observer.observe(observeRef.current)

    return () => {
      observer.disconnect()
      staggerInstance.staggerOff(motionRef)
    }
  }, [staggerInstance])

  return (
    <section className={s.yutube}>
      <div className={s.inner}>
        <hgroup>
          <h3 className={s.title}>
            <span>조회수 1억 뷰</span>더 쉽고 재밌어진
            <br className={s.disM} /> 하루 10분 영어
          </h3>
        </hgroup>
        <div className={s.digitContainer}>
          <h5 className={s.title}>유튜브 누적 조회수</h5>
          <div className={s.digitBox}>
            <DigitCounter target={141504789} />
            <span className={s.date}>2023.11 기준</span>
          </div>
        </div>
        <div className={s.top3}>
          <h5 className={s.title} ref={observeRef}>
            <span>설마 아직도 못봤어?</span>
            인기 급상승 영어 TOP3
          </h5>
          <ul className={s.shortsList} ref={motionRef}>
            {shortsListValues.map(({ pc, mo, alt, link }, index) => {
              return (
                <li key={index}>
                  <Link to={link} target="_blank">
                    <ResponsiveImage pc={pc} mo={mo} alt={alt} maxWidth={1024} />
                  </Link>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </section>
  )
}
