import { useState, useEffect } from 'react';
import styled from 'styled-components';
import api from 'common/api';
import '../../assets/styles/modal.scss';
import ImgGoodsItemA from '../../assets/images/img_goods_item_518.png';
import ImgGoodsItemB from '../../assets/images/img_goods_item_517.png';
import ChkDefault from '../../assets/images/ico_check_38x30w.png';
import ChkActive from '../../assets/images/ico_check_38x30b.png';

const ModalGoods = ({ btnClose, handleCloseModal, goodsDirectOrder, showErrorMessage }) => {
  const [inputValue, setInputValue] = useState({
    goodsType: '',
  });
  const [goodsItem, setGoodsItem] = useState([]);

  const getGoodsItem = () => {
    api
      .get(`/v2/store/entrance-present`)
      .then((response) => {
        if (response.data.meta?.code === 200) {
          setGoodsItem(response.data.data);
        } else {
          showErrorMessage(response);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleInputValue = (e) => {
    let { name, value } = e.target;

    if (value) {
      setInputValue({ ...inputValue, [name]: value });
    } else {
      setInputValue({ ...inputValue, [name]: '' });
    }
  };

  useEffect(() => {
    getGoodsItem();
  }, []);

  const ModalInner = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 48rem;
    max-height: 81rem;
    min-height: 81rem;
    background-color: #fff;
    border-radius: 1rem;
    box-sizing: border-box;
    text-align: center;
    overflow: hidden;

    .modal-content {
      max-height: 100%;
    }

    .btn-flex-form {
      margin-top: 1.4rem;
    }

    .btn-default {
      color: #fff;
      background-color: #cfcfda;

      &:disabled {
        cursor: default;
      }
    }

    @media screen and (max-width: 1024px) {
      width: 100%;
      min-height: calc(100% - 8.7rem);
      max-height: calc(100% - 8.7rem);
      height: auto;
      border-radius: 2rem 2rem 0 0;
    }
  `;

  const YndUnivBox = styled.div`
    padding-top: 10rem;
    font-size: 2rem;
    line-height: 1.4;

    h4 {
      margin: 0 0 2.1rem;
    }

    strong {
      font-size: 3rem;
    }

    @media screen and (max-width: 1024px) {
      padding-top: 13.5rem;
      font-size: 3.2rem;

      strong {
        font-size: 4.8rem;
      }
    }
  `;

  const GoodsItemBox = styled.div`
    strong {
      display: inline-block;
      padding-right: 2.1rem;
      font-size: 2rem;
      font-weight: 700;
    }

    em {
      display: inline-block;
      font-size: 1.5rem;
      font-weight: 700;
      color: #b7b7b7;

      &:before {
        content: '*';
      }
    }

    ul {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 6.3rem 0 5.9rem;
    }

    li {
      position: relative;
      flex: 0 1 auto;

      div {
        width: 20.4rem;
        border-radius: 0.5rem;
        overflow: hidden;
        font-size: 2rem;
        font-weight: 700;
      }

      & + li {
        margin-left: 1.4rem;
      }

      label {
        display: block;
        width: inherit;
        height: inherit;

        input[type='radio'] {
          + .chk {
            width: 4rem;
            height: 4rem;
            background-color: #ececf6;
            border: 0;

            &:before {
              display: inline-block;
              width: 3.8rem;
              height: 3rem;
              background: url(${ChkDefault}) 50% 50%/60% no-repeat;
            }
          }

          &:checked + .chk {
            background-color: #FFB900;

            &:before {
              background-image: url(${ChkActive});
            }
          }
        }
      }
    }

    @media screen and (max-width: 1024px) {
      strong {
        padding-right: 3.5rem;
      }

      em {
        font-size: 2.4rem;
      }

      ul {
        margin: 8.7rem 0 6.7rem;
      }

      li {
        div {
          width: 32rem;
          font-size: 3.2rem;
        }

        label {
          display: block;
          width: inherit;
          height: inherit;

          input[type='radio'] {
            + .chk {
              width: 6.2rem;
              height: 6.2rem;

              &:before {
                display: inline-block;
                width: 4.5rem;
                height: 3.7rem;
              }
            }
          }
        }
      }
    }
  `;

  const GoodsThumb = styled.div`
    display: inline-block;
    margin-bottom: 1.2rem;
    padding-top: 19.6rem;
    background-image: ${(props) =>
      props.background === 0 ? `url(${ImgGoodsItemA})` : `url(${ImgGoodsItemB})`};
    background-size: 100%;
    background-repeat: no-repeat;

    @media screen and (max-width: 1024px) {
      padding-top: 30.7rem;
    }
  `;

  return (
    <>
      <div className="modal-wrap">
        <div className="dimmed" onClick={handleCloseModal}></div>
        <ModalInner>
          {btnClose && (
            <button type="button" className="btn-modal-close" onClick={handleCloseModal}>
              <i className="blind">닫기</i>
            </button>
          )}
          <div className="modal-content type-fixed-button">
            <YndUnivBox>
              <h4>
                <strong>
                  성공지원 입학템
                  <br />
                  웰컴 키트를 선택해 주세요.
                </strong>
              </h4>
              <p>
                Yanadoo University의
                <br />
                평생 입학 회원이 된 것을 환영합니다!
              </p>
            </YndUnivBox>
            <GoodsItemBox>
              <ul>
                {goodsItem.map((item, index) => (
                  <li key={item.productSeq}>
                    <label htmlFor={`goodsChk_${item.productSeq}`}>
                      <div>
                        <GoodsThumb background={index} />
                        <input
                          type="radio"
                          id={`goodsChk_${item.productSeq}`}
                          name="goodsType"
                          value={item.productSeq}
                          onChange={handleInputValue}
                          checked={inputValue.goodsType === String(item.productSeq) ? true : false}
                        />
                        <span className="chk"></span>
                        <strong>{item.name.substr(10)}</strong>
                      </div>
                    </label>
                  </li>
                ))}
              </ul>
              <p>
                <em>웰컴 키트 선택 후 변경 불가</em>
              </p>
            </GoodsItemBox>
            <div className="btn-flex-form">
              <button
                className={`btn ${!inputValue.goodsType ? 'btn-default' : 'active'}`}
                onClick={() => goodsDirectOrder(inputValue.goodsType)}
                disabled={inputValue.goodsType ? false : true}
              >
                <span>수강 신청하기</span>
              </button>
            </div>
          </div>
        </ModalInner>
      </div>
    </>
  );
};

export default ModalGoods;
