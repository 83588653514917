import React, { useEffect } from 'react'

import s from './Chat.module.scss'

export default function FaqWebTitleBar({ handleOnClick }) {
  useEffect(() => {
    document.querySelector('header').classList.add('header-overflow')

    return () => {
      document.querySelector('header').classList.remove('header-overflow')
    }
  }, [])
  return (
    <hgroup className={s.faqWebTitle}>
      <div className={s.webTitleInner}>
        <h3>
          <span>AI 상담원</span>
        </h3>
        <div className={s.titleBtns}>
          <button className={s.btnCaution} onClick={() => handleOnClick('caution')}>
            도움말
          </button>
          <span>bar</span>
          <button className={s.btnClose} onClick={() => handleOnClick('close')}>
            닫기
          </button>
        </div>
      </div>
    </hgroup>
  )
}
