import { useState, useEffect, useCallback } from 'react'

import ModalPortal from 'ModalPortal'

import api from 'common/api'
import * as config from 'common/config'

import 'assets/styles/modal.scss'
import 'assets/styles/modalJoin.scss'
import ModalLogin from './modal/ModalLogin'
import useModalLogin from 'store/useModalLogin'

export default function JoinComplete({ token, userId, handleCloseModal }) {
  const [completeInfo, setCompleteInfo] = useState(null)
  const { setIsModalLogin } = useModalLogin()

  const handleOpenModalLogin = useCallback((e) => {
    document.body.classList.add('modal-open')
    setIsModalLogin(true)
  }, [])

  const getCompleteInfo = () => {
    api
      .get(config.AUTH_API_HOST + '/auth/v2/sign-up/complete?signUpResultApiToken=' + token + '&userId=' + userId)
      .then((response) => {
        setCompleteInfo(response.data.data)

        const userDetail = {
          id: response.data.data.userId,
          name: response.data.data.userName,
          email: response.data.data.email,
          cellPhone: response.data.data.cellPhone
        }

        sessionStorage.setItem('user_detail', JSON.stringify(userDetail))
      })
      .catch((e) => {
        console.log(e)
      })
  }

  useEffect(() => {
    getCompleteInfo()
  }, [])

  return (
    completeInfo && (
      <>
        <div className="modal-content type-fixed-button">
          <div className="complete-box">
            <dl>
              <dt>
                {completeInfo.userName}님의 <br /> 회원가입을 축하합니다
              </dt>
              <dd>
                <ul>
                  <li>
                    <span>ID</span>
                    {completeInfo.userId}
                  </li>
                  {completeInfo.connectedSnsName && (
                    <li>
                      <span>SNS 연동</span>
                      {completeInfo.connectedSnsName}
                    </li>
                  )}
                </ul>
              </dd>
            </dl>
          </div>
          <div className="complete-info-box">
            <strong>광고성정보 수신동의 처리 결과 안내</strong>
            <dl>
              <dt>전송자명</dt>
              <dd>{completeInfo.companyName}</dd>
            </dl>
            <dl>
              <dt>요청일</dt>
              <dd>{completeInfo.requestDate}</dd>
            </dl>
            <dl>
              <dt>처리결과</dt>
              <dd>
                <p>{completeInfo.emailYnResult}</p>
                <p>{completeInfo.smsYnResult}</p>
              </dd>
            </dl>
          </div>
        </div>
        <div className="btn-flex-form">
          <button type="button" className="btn active" onClick={handleOpenModalLogin}>
            <span>로그인</span>
          </button>
        </div>
      </>
    )
  )
}
