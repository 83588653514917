import { useState } from 'react'

import useLevelTestAdvancedExamStore from 'store/levelTestAdvanced/useLevelTestAdvancedExamStore'
import useLevelTestAdvancedStore from 'store/levelTestAdvanced/useLevelTestAdvancedStore'

import BubbleMessage from './BubbleMessage'
import Navigation from './Navigation'
import Timer from './Timer'
import { LTA_ALERT_MESSAGE } from '../config'

export default function QuestionNavigation({ onClickPrev, onClickNext }) {
  const [isShowBubble, setIsShowBubble] = useState(false)

  const questionIndex = useLevelTestAdvancedExamStore((state) => state.questionIndex)
  const selected = useLevelTestAdvancedStore((state) => state.test)[questionIndex - 1]?.answer

  const prevDisabled = questionIndex === 1 || questionIndex === 10 || questionIndex === 14

  const handleCheckValidation = () => {
    if (!selected) {
      const alertMessage = questionIndex === 5 || questionIndex === 20 ? LTA_ALERT_MESSAGE.COMPLETE_SENTENCE_ANSWER_NONE : LTA_ALERT_MESSAGE.DEFAULT_ANSWER_NONE
      alert(alertMessage)
      return
    }
    setIsShowBubble(false)
    onClickNext()
  }

  const handleClickPrev = () => {
    setIsShowBubble(false)
    onClickPrev()
  }

  return (
    <Navigation prevDisabled={prevDisabled} onClickPrev={handleClickPrev} onClickNext={handleCheckValidation}>
      {questionIndex > 10 && <BubbleMessage isShow={isShowBubble} onChangeShowBubble={setIsShowBubble} questionIndex={questionIndex} />}
      <Timer />
    </Navigation>
  )
}
