import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import api from 'common/api';
import { scrollMove } from '../../common/common';

import styled, { ThemeProvider } from 'styled-components';
import theme from 'common/theme';

import IcoArrowRight from 'assets/images/ico_arrow_24x42_b.png';

export default function ButtonList({ currentSeq, handleCurrentIdx, type, boardDetailInfo, param }) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [postSeq, setPostSeq] = useState(null);
  // 이전 다음 버튼 API
  const getMove = (seq, moveType) => {
    api
      .get(`${boardDetailInfo.apiPrevNextUrl}${seq}&moveType=${moveType}`)
      .then((response) => {
        if (response.data.meta.code !== 200) {
          setPostSeq(currentSeq);
          alert(response.data.meta.message);
        } else {
          setPostSeq(
            type !== 'STUDY' ? response.data.data.communityPostSeq : response.data.data.questionSeq
          );
        }
        setIsLoading(true);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (isLoading && currentSeq !== postSeq) {
      setIsLoading(false);
      navigate(`/community/${type.toLowerCase()}/detail/${postSeq}`);
      handleCurrentIdx(1);
      scrollMove(0, 0);
    }
  }, [isLoading, postSeq]);

  return (
    <ThemeProvider theme={theme}>
      <BtnListBox>
        <ButtonItem
          type="MOVE"
          onClick={() => {
            getMove(currentSeq, 'PREV');
          }}
        >
          이전
        </ButtonItem>
        <LinkItem type="LIST" to={`/community/${type.toLowerCase()}/list?${param}`}>
          목록보기
        </LinkItem>
        <ButtonItem
          type="MOVE"
          onClick={() => {
            getMove(currentSeq, 'NEXT');
          }}
        >
          다음
        </ButtonItem>
      </BtnListBox>
    </ThemeProvider>
  );
}

const BtnListBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5rem 14rem 0;
  border-top: 1px solid #b7b7c8;

  a,
  button {
    font-weight: 400;
    font-size: 2rem;

    &:first-child {
      padding-left: 4rem;

      &::before {
        left: 0;
      }
    }

    &:last-child {
      padding-right: 4rem;

      &::before {
        right: 0;
        transform: rotate(180deg);
      }
    }
  }

  @media ${({ theme }) => theme.device.mobile} {
    padding: 5rem 4.2rem 0;

    a,
    button {
      font-size: 2.7rem;
    }
  }
`;

const ButtonItem = styled.button`
  position: relative;
  color: #28284b;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    width: 5rem;
    height: 4rem;
    margin-top: -2.1rem;
    background-image: url(${IcoArrowRight});
    background-repeat: no-repeat;
    background-size: auto 1.6rem;
    background-position: 50% 50%;
  }
  @media ${({ theme }) => theme.device.mobile} {
    &::before {
      width: 5rem;
      height: 4rem;
      margin-top: -2rem;
      background-size: auto 2.2rem;
    }
  }
`;

const LinkItem = styled(Link)`
  display: inline-block;
  width: 27rem;
  height: 6.8rem;
  padding: 2rem 0 1.8rem;
  text-align: center;
  border: 1px solid #cfcfda;
  border-radius: 0.3rem;
  box-sizing: border-box;

  @media ${({ theme }) => theme.device.mobile} {
    width: 32.4rem;
    height: 10rem;
    padding: 3.2rem 0 2.9rem;
    border-radius: 0.6rem;
    box-sizing: border-box;
  }
`;
