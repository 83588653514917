import React from 'react'

import useGoOrderCode from 'hooks/useGoOrderCode'
import { v4 as uuid } from 'uuid'

import useLevelTestAdvancedResultStore from 'store/levelTestAdvanced/useLevelTestAdvancedResultStore'

import { recommendCourseValues } from '../config'
import styles from '../LevelTestAdvancedResult.module.scss'

function RecommendProduct({
  name,
  value: { productTitle, productImageSrc, productAlt, productText, keywords }
}) {
  return (
    <div className={styles.recommendProduct}>
      <hgroup className={styles.hgroup}>
        <h3>
          {productTitle}
          <br />
          {name}님을 위한 추천 코스
        </h3>
      </hgroup>

      <figure>
        <img src={productImageSrc} alt={productAlt} />
      </figure>
      <p>{productText}</p>
      <ul>
        {keywords.map((keyword) => (
          <li key={uuid()}>{keyword}</li>
        ))}
      </ul>
    </div>
  )
}

function RecommendLevel({ value: { levelTitle, levelImageSrc, levelText } }) {
  return (
    <div className={styles.recommendLevel}>
      <hgroup className={styles.hgroup}>{levelTitle}</hgroup>
      <figure>
        <img src={levelImageSrc} alt={levelText} />
      </figure>
      <p>{levelText}</p>
    </div>
  )
}

function RecommendButtons({ value: { productUrl, productCode } }) {
  const { handleOrderCode } = useGoOrderCode()
  return (
    <div className={styles.recommendButtons}>
      <button
        type="button"
        onClick={() => {
          window.open(productUrl)
        }}
      >
        상품소개 보기
      </button>
      <button
        type="button"
        onClick={() => {
          handleOrderCode(productCode, true)
        }}
      >
        바로 구매하기
      </button>
    </div>
  )
}

export default function RecommendCourse() {
  const { level, name } = useLevelTestAdvancedResultStore()

  return (
    <section id="recommendCourse" className={styles.recommend}>
      <RecommendProduct name={name} value={recommendCourseValues[level]} />
      {level !== 'lv5' && level !== 'lv6' && (
        <RecommendLevel value={recommendCourseValues[level]} />
      )}
      <RecommendButtons value={recommendCourseValues[level]} />
    </section>
  )
}
