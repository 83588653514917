import React from 'react'
import { useNavigate } from 'react-router'

import { LTA_HOST_CDN } from 'pages/levelTestAdvanced/config'

import styles from '../LevelTestAdvancedAnswer.module.scss'

export default function Top() {
  const navigate = useNavigate()

  return (
    <hgroup className={styles.hgroup}>
      <button
        type="button"
        onClick={() => {
          navigate(-1)
        }}
      >
        <img src={`${LTA_HOST_CDN}ico_back.svg`} alt="뒤로가기" />
      </button>
      <h3>심화 레벨테스트</h3>
    </hgroup>
  )
}
