import React from 'react'

import styles from '../../LevelTestAdvancedResult.module.scss'

export default function Button({ text, onClick }) {
  return (
    <div className={styles.btnWrap}>
      <button type="button" onClick={onClick}>
        {text}
      </button>
    </div>
  )
}
