import { useState, useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'

import styled, { ThemeProvider } from 'styled-components'

import theme from 'common/theme'
import HeaderHamburger from 'components/header/components/sideMenu/HeaderHamburger'
import HeaderLoginButton from 'components/header/components/sideMenu/HeaderLoginButton'

import api from '../../common/api'
import NavCompany from '../../components/NavCompany'
import icoArrow from 'assets/images/ico_check_36x21.png'
import logoNew from 'assets/images/img_logo_474x113.png'

export default function GnbPreview() {
  const deviceCheck = useMediaQuery({ maxWidth: 1024 }) ? true : false
  const [isActive, setIsActive] = useState(false)
  const [isOver, setIsOver] = useState(false)
  const [isHamburger, setHamburger] = useState(false)
  const [isEntry, setEntry] = useState(true) // 어떤 GNB를 보여줄지 체크하기 위한 상태값. (메인 GNB 컴포넌트 호출 : true, 상세 GNB 컴포넌트 호출 : false)
  const [gnbItems, setGnbItems] = useState([]) // '이벤트' 메뉴를 제외한 GNB 데이터 상태값.
  const [gnbEventItems, setGnbEventItems] = useState(null) // '이벤트' 메뉴의 GNB 데이터 상태값.
  const [currentIdx, setCurrentIdx] = useState(null) // 상세 GNB를 호출 할때, 몇번째 GNB를 호출할지 체크하는 상태값.
  const [depthCheck, setDepthCheck] = useState(null)

  const currentPath = useLocation().pathname
  const onClickGnbMenu = (idx) => {
    setCurrentIdx(idx)
  }

  const getGnbPreviewList = () => {
    api
      .get(`/v2/gnb-menu/preview-list`)
      .then((response) => {
        const gnbItem = response.data.data.filter((item) => item.menuName !== '이벤트')
        const eventItem = response.data.data.filter((item) => item.menuName !== '이벤트')
        setGnbItems(gnbItem)
        setGnbEventItems(eventItem)
        gnbActiveCheck(gnbItem)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  // 현재 URL을 체크하고, 세부메뉴 URL과 비교. 존재한다면, 해당 의 메뉴를 노출시키는 함수입니다.
  // 현재 URL과 세부메뉴 URL이 매칭되지 않는다면, 메인 GNB를 노출합니다.
  const gnbActiveCheck = (gnbItemsArr) => {
    let depthCheckBoolen = true
    gnbItemsArr.forEach((item, idx) => {
      item.childGnbMenus.forEach((subItem, subIdx) => {
        subItem.childGnbMenus.forEach((detailItem, detailIdx) => {
          if (window.location.href.indexOf(detailItem.link) !== -1) {
            setCurrentIdx(idx)
            setEntry(false)
            setDepthCheck({ depth1: idx, depth2: subIdx, depth3: detailIdx })
            depthCheckBoolen = false
            return false
          }
        })
      })
    })
    if (depthCheckBoolen) setDepthCheck({ depth1: 0, depth2: 0, depth3: 0 })
    // 야나두 메인 페이지라면, 메인 GNB를 노출합니다.
    if (window.location.href === '/') setEntry(true)
  }

  const handleScroll = () => {
    const ScrollChk = window.scrollY || document.documentElement.scrollTop
    const setScroll = document.querySelector('body').classList
    const footerOft = document.querySelector('footer').offsetTop
    const diff = footerOft - ScrollChk
    const $lnb = document.querySelector('#lnb .lnb-col')
    const $container = document.querySelector('.container')
    const agent = navigator.userAgent.toLowerCase()

    !ScrollChk <= 0 ? setScroll.add('fixed') : setScroll.remove('fixed')

    if (
      (navigator.appName === 'Netscape' && agent.indexOf('trident') !== -1) ||
      agent.indexOf('msie') !== -1
    ) {
      // ie일 경우
      $container.classList.add('lnb-fixed')
      $lnb && diff <= 800 ? $lnb.classList.add('remove') : $lnb.classList.remove('remove')
    } else {
      // ie가 아닐 경우
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
      document.body.classList.remove('modal-open')
    }
  }, [])

  useEffect(() => {
    getGnbPreviewList()
    setHamburger(false)
  }, [currentPath])

  return (
    <ThemeProvider theme={theme}>
      <HeaderCol
        className={
          (deviceCheck && isActive ? 'active-mobile ' : '') +
          (!deviceCheck && isOver ? 'active-pc' : '')
        }
      >
        {isEntry ? (
          <EntryNav>
            <InnerCol className="flex">
              <H1>
                <Link to="/">
                  <i className="blind">야나두</i>
                </Link>
              </H1>
              <GnbList>
                {gnbItems &&
                  gnbItems.map((item, idx) => (
                    <li key={idx} className={currentIdx === idx ? 'active ' : ''}>
                      <a
                        href={item.link}
                        onClick={() =>
                          onClickGnbMenu(
                            idx,
                            item.gaEventName !== undefined ? item.gaEventName : ''
                          )
                        }
                      >
                        <strong
                          className={
                            item.gnbMenuSeq === 49
                              ? `eng`
                              : item.gnbMenuSeq === 105
                              ? `yu`
                              : item.gnbMenuSeq === 71
                              ? `yafit`
                              : ``
                          }
                        >
                          {item.menuName}
                        </strong>
                        <span>{item.description}</span>
                      </a>
                    </li>
                  ))}
              </GnbList>
            </InnerCol>
          </EntryNav>
        ) : (
          <NavCompany
            setEntry={setEntry}
            currentIdx={currentIdx}
            gnbItems={gnbItems}
            onClickGnbMenu={onClickGnbMenu}
            depthCheck={depthCheck}
            currentPath={currentPath}
          />
        )}
        <SideMenu className={isEntry ? '' : 'active'}>
          <BtnEvent>이벤트</BtnEvent>
          <ul>
            {gnbEventItems &&
              gnbEventItems[0].childGnbMenus.map((item, idx) => (
                <li key={idx} className={currentIdx === idx ? 'active ' : ''}>
                  {item.link.includes('http') ? (
                    <a
                      href={item.link}
                      target={`_${item.linkTarget.toLowerCase()}`}
                      onClick={() => window.dataLayer.push}
                    >
                      {item.menuName}
                    </a>
                  ) : (
                    <Link to={item.link} target={`_${item.linkTarget.toLowerCase()}`}>
                      {item.menuName}
                    </Link>
                  )}
                </li>
              ))}
          </ul>
          <HeaderLoginButton />
          <HeaderHamburger
            isHamburger={isHamburger}
            setHamburger={setHamburger}
            gnbItems={gnbItems}
            depthCheck={depthCheck}
          />
        </SideMenu>
      </HeaderCol>
      <GnbControlBox>
        <p>
          GNB 미리보기 페이지 입니다.
          <br />
          아래에서 메뉴를 선택해 주세요.
        </p>
        <ul>
          <li>
            <button type="button" onClick={() => setEntry(true)}>
              GNB 메인
            </button>
          </li>
          {gnbItems &&
            gnbItems.map((item, idx) => (
              <li key={idx} className={currentIdx === idx ? 'active ' : ''}>
                <button
                  type="button"
                  onClick={() => {
                    onClickGnbMenu(idx)
                    setEntry(false)
                  }}
                >
                  {item.menuName}
                </button>
              </li>
            ))}
        </ul>
      </GnbControlBox>
    </ThemeProvider>
  )
}

const GnbControlBox = styled.section`
  display: block;
  height: 50rem;
  text-align: center;
  p {
    padding: 8rem 0 2rem;
    font-weight: 400;
    font-size: 2.4rem;
    line-height: 1.4;
    text-align: center;
  }
  ul {
    display: block;
    height: 30rem;
    padding: 4rem 0 3rem;
    li {
      display: inline-block;
      padding: 1rem;
      font-size: 2.2rem;
      button {
        display: inline-block;
        padding: 3rem;
        background-color: #ffb900;
        border-radius: 1.4rem;
      }
    }
  }
`
const HeaderCol = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  font-size: 1.5rem;
  transition: all 0.3s;
  z-index: 30;
`
const InnerCol = styled.div`
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 4.2rem;
  box-sizing: border-box;

  &.flex {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-items: center;

    @media ${({ theme }) => theme.device.mobile} {
      flex-direction: column;
      align-items: flex-start;
    }
  }
`

const EntryNav = styled.div`
  padding: 3.8rem 0;
  @media ${({ theme }) => theme.device.mobile} {
    padding: 4.6rem 0 3.6rem;
  }
`
const GnbList = styled.ul`
  display: flex;
  margin: 0 0 0 10%;

  li {
    padding: 0 0 0 8.8rem;
    @media ${({ theme }) => theme.device.mobile} {
      padding: 0 0 0 25px;
    }

    &:hover {
      a,
      button {
        strong {
          &:after {
            border-width: 0.5rem;
            @media ${({ theme }) => theme.device.mobile} {
              border-width: 0;
            }
          }
        }
        span {
          color: #000;
        }
      }
    }

    &:first-child {
      padding: 0;
    }
    a,
    button {
      display: block;
      font-size: 1.8rem;
      font-weight: 700;
      text-align: left;

      strong {
        display: inline-block;
        position: relative;
        &:after {
          content: '';
          position: absolute;
          bottom: 0.2rem;
          left: 0;
          width: 100%;
          border-bottom: 0 solid transparent;
          transition: 0.2s;
          opacity: 0.5;
          z-index: -1;
        }
        &.eng {
          &:after {
            border-bottom-color: #ffb900;
          }
        }
        &.yu {
          &:after {
            border-bottom-color: #ff5647;
          }
        }
        &.yafit {
          &:after {
            border-bottom-color: #00cc66;
          }
        }
      }
      span {
        display: block;
        margin: 4px 0 0;
        font-weight: 400;
        font-size: 1.2rem;
        color: #717188;
        transition: 0.2s;
      }
    }
  }

  @media ${({ theme }) => theme.device.mobile} {
    margin: 0;

    li a,
    button {
      font-size: 2.8rem;

      span {
        display: none;
      }
    }
  }
`
const H1 = styled.h1`
  width: 16rem;
  height: 3.9rem;
  background: url(${logoNew}) no-repeat 0 0 / 100%;

  @media ${({ theme }) => theme.device.mobile} {
    width: 24.8rem;
    height: 5.7rem;
    margin: 0px 0px 4.9rem;
  }
  a {
    display: block;
    height: inherit;
  }
`
const SideMenu = styled.div`
  position: absolute;
  top: 4rem;
  left: 50%;
  min-width: 15rem;
  margin-left: 40rem;
  text-align: right;
  z-index: 5;

  &.active {
    top: 6rem;

    .btn-login {
      border: 1px solid #cfcfda;
      background: #fff;
    }
  }

  .mypage-box {
    padding: unset;

    button {
      display: inline-block;
      vertical-align: middle;

      span {
        width: 3rem;
        height: 3rem;
        background-size: 100%;
        border-radius: 100%;
        border: unset !important;
      }
      &:hover {
        & + ul {
          display: block;
        }
      }
      & + ul:hover {
        display: block;
      }

      & + ul {
        display: none;
        position: absolute;
        top: 3rem;
        right: 0rem;
        width: 12.5rem;
        padding: 2rem 0 0.4em;
        background: #fff;
        border-radius: 4px;
        box-shadow: 0px 0px 10px 0px #00000033;
        box-sizing: border-box;
        z-index: 2;
        li {
          text-align: right;

          a,
          button {
            display: block;
            height: unset;
            padding: 0rem 2rem 1.2rem;
            font-weight: 500;
            font-size: 1.2rem;
            text-align: right;

            &:hover {
              font-weight: 700;
              background: none;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1200px) {
    right: 4%;
    left: auto;
    margin-left: auto;
  }
  @media ${({ theme }) => theme.device.mobile} {
    display: flex;
    top: 4rem;
    right: 0;
    left: auto;
    margin-left: auto;

    &.active {
      top: 11rem;
    }

    span[class^='ico'] {
      width: 5rem;
      height: 5rem;
    }

    button {
      width: 100%;
      background-size: contain;
      .user-thumb-box {
        display: inline-block;
        width: 5rem;
        height: 5rem;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 50%;
        box-sizing: border-box;
        border-radius: 100%;
      }
    }
  }
`
const BtnEvent = styled.button`
  margin-top: -2.8rem;
  padding: 2.8rem 1.5rem;
  font-weight: 500;
  font-size: 1.2rem;

  &:after {
    content: '';
    display: inline-block;
    width: 8px;
    height: 8px;
    margin: 0 0 0 4px;
    background: url(${icoArrow}) 91% 50% / 100% no-repeat;
  }

  &:hover {
    & + ul {
      display: block;
    }
  }
  & + ul:hover {
    display: block;
  }
  & + ul {
    display: none;
    position: absolute;
    top: 3rem;
    right: 4.5rem;
    padding: 1.4rem 0;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0px 0px 10px 0px #00000033;
    box-sizing: border-box;
    z-index: 2;

    li {
      &:hover {
        background-color: #ececf6;
      }
    }
    li > a {
      display: block;
      width: max-content;
      padding: 0.6rem 2rem;
      font-weight: 500;
      font-size: 1.2rem;
      text-align: right;

      &:hover {
        font-weight: 700;
      }
    }
  }
  & + ul + button.btn-login {
    padding: 0.8rem 1.5rem;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.6rem;
    border: 1px solid #ffb900;
    border-radius: 4px;
    background: #ffb900;
  }

  @media ${({ theme }) => theme.device.mobile} {
    display: none;

    & + ul {
      display: none;
    }
  }
`
