import React from 'react'

import BtnMore from './BtnMore'
import NoticeList from './NoticeList'
import s from '../Service.module.scss'

export default function SectionNotice() {
  return (
    <section className={s.csNotice}>
      <div className={s.csInner}>
        <h3 className={s.sectionTitle}>공지사항</h3>
        <NoticeList />
        <BtnMore title="전체보기" link="/service/notice/1" />
      </div>
    </section>
  )
}
