import React from 'react'

import styled from 'styled-components'

import SectionTitle from './SectionTitle'
import ImgFirstMedal from 'assets/images/img_first_medal.png'
import ImgGoldMedal from 'assets/images/img_gold_medal.png'
import ImgStarMedal from 'assets/images/img_star_medal.png'
import ImgTrophy from 'assets/images/img_trophy.png'

function MedalItem({ title, desc, img }) {
  return (
    <li>
      <img src={img} alt="" />
      <strong>{title}</strong>
      <p>{desc}</p>
    </li>
  )
}

export default function MedalSection() {
  return (
    <Container>
      <InnerCol>
        <FlexBox>
          <div>
            <SectionTitle title={'많은 사람들이\n야나두를 찾는\n이유를 아시나요?'} desc={'아무도 흉내 낼 수 없는 수년간의\n 노하우로 야나두가 만들면 1등입니다.'} />
          </div>
          <div>
            <ul>
              <MedalItem title={'서비스 만족도 1위'} desc={'2017년- 온라인 영어\n 5개 부문'} img={ImgStarMedal} />
              <MedalItem title={'매출 1위'} desc={'2018 위메프 티몬\n홈쇼핑 온라인 영어 부문'} img={ImgTrophy} />
            </ul>
            <ul>
              <MedalItem title={'올해의 브랜드 대상'} desc={'2022년 올해의 영어 회화 부문\n(4년 연속)'} img={ImgGoldMedal} />
              <MedalItem title={'검색 1위'} desc={'2018-2020년\n온라인 영어 부문'} img={ImgFirstMedal} />
            </ul>
          </div>
        </FlexBox>
      </InnerCol>
    </Container>
  )
}

const Container = styled.section`
  padding: 18rem 0 34rem;

  h3 {
    text-align: left;
  }

  p {
    text-align: left;
  }

  @media ${({ theme }) => theme.device.mobile} {
  }
`

const FlexBox = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  div {
    flex: 0 0 auto;

    &:first-child {
      flex: 0 1 auto;
      max-width: 36rem;
      margin-top: 1.2rem;
    }
  }

  ul {
    display: inline-block;
    position: relative;
    width: 46%;

    &:first-child {
      top: 16rem;

      li {
        &:first-child {
          padding: 2.4rem 5.1rem;

          img {
            width: 23rem;
            height: 23rem;
          }
        }
      }
    }

    &:last-child {
      li {
        &:first-child {
          padding: 0 4.4rem 3.2rem 4.4rem;

          img {
            width: 19.4rem;
            height: 23.3rem;
            padding-left: 3.8rem;
          }
        }
      }
    }

    & + ul {
      margin-left: 4rem;
    }

    li {
      min-height: 39.7rem;
      padding: 3.2rem 6rem;
      border-radius: 1.6rem;
      box-shadow: 0 0 2rem rgba(0, 0, 0, 0.1);
      text-align: center;
      box-sizing: border-box;

      & + li {
        margin-top: 4rem;
      }

      img {
        display: block;
        width: 21rem;
        height: 21rem;
        margin: auto;
      }

      strong {
        display: block;
        margin-top: 3rem;
        font-size: 2.4rem;
      }

      p {
        margin-top: 1rem;
        white-space: pre-wrap;
        font-weight: 400;
        font-size: 2rem;
        color: #717188;
        line-height: 1.4;
        letter-spacing: -0.15rem;
        text-align: center;
      }
    }
  }

  @media ${({ theme }) => theme.device.mobile} {
    flex-direction: column;

    div {
      width: 100%;

      &:first-child {
        max-width: 100%;
        margin-top: 0;
        margin-bottom: 5.6rem;
      }
    }

    ul {
      &:first-child {
        li {
          &:first-child {
            padding: 2.4rem 4.3rem 3.2rem;

            img {
              width: 20rem;
              height: 20rem;
            }
          }
        }
      }

      &:last-child {
        li {
          &:first-child {
            padding: 0 2.2rem 3.2rem 2.2rem;

            img {
              width: 17.7rem;
              height: 20.5rem;
              padding-left: 6.5rem;
            }
          }
        }
      }

      & + ul {
        margin-left: 3.2rem;
      }

      li {
        min-height: 37rem;
        padding: 3.2rem 4.5rem;

        img {
          width: 18rem;
          height: 18rem;
        }

        p {
          font-size: 2rem;
        }
      }
    }
  }
`

const InnerCol = styled.div`
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 3rem;

  @media ${({ theme }) => theme.device.mobile} {
    padding: 0 7.2rem;
  }
`
