import { useLocation } from 'react-router'

import classNames from 'classnames'
import useHideHeader from 'hooks/useHideHeader'

import HeaderBanner from 'components/HeaderBanner'
import { TopFixedBanner as BlackFridayTopBanner } from 'components/promotion/blackfridayBanner/TopFixedBanner'

import MainHeader from './components/MainHeader'
import SubHeader from './components/subHeader'

import styles from './Header.module.scss'
import useHeader from './hooks/useHeader'

export default function Header() {
  const { headerRef, currentSubIndex, isMain } = useHeader()
  const { isHideHeader, headerHeight } = useHideHeader()
  const top = isHideHeader ? `-${headerHeight + 1}px` : 0

  // 영어 상품 페이지 분기
  const isEnglishProductPage = useLocation().pathname.includes('/promotion-landing')

  return (
    <>
      {!isMain && <HeaderBanner />}
      <header
        ref={headerRef}
        className={classNames(styles.header)}
        style={{ top, borderBottom: isEnglishProductPage ? 'none' : '1px solid #e7e7f0' }}
      >
        {isMain && <MainHeader />}
        {!isMain && currentSubIndex !== null && <SubHeader />}
        {isEnglishProductPage && <BlackFridayTopBanner />}
      </header>
    </>
  )
}
