import React from 'react'
import { Link } from 'react-router-dom'

import bannerImage from '.././images/img_result-bottom-banner.jpg'
import styles from '../LevelTestAdvancedResult.module.scss'

export default function BottomBanner() {
  return (
    <figure className={styles.banner}>
      <Link to="/promotion-landing/applePackage" target="_blank">
        <img src={bannerImage} alt="현지에서 먹히는 실제 영어 현실영어 (+35만원 상당 영화영어 100% 증정) / 공무원 연금, 삼성, 현대, 하나, 롯데카드 결제 시 3% 청구할인" />
      </Link>
    </figure>
  )
}
