import { useState, useCallback } from 'react'

import api from 'common/api'

import { AI_TRAVEL_CONFIG } from '../config'

export default function useGetTravelTip() {
  const [meta, setMeta] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const fetchMeta = useCallback(async ({ classSeq, lectureSeq }) => {
    if (!classSeq || !lectureSeq) return

    setLoading(true)
    setError(null)

    try {
      const response = await api.post(`${AI_TRAVEL_CONFIG.TRAVEL_META_HOST}`, {
        classSeq,
        lectureSeq
      })
      setMeta(response.data)
    } catch (e) {
      console.error('🚀  error:', e)
      setError(e)
    } finally {
      setLoading(false)
    }
  }, [])

  return { meta, error, loading, fetchMeta }
}
