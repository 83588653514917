import React from 'react'
import { useMediaQuery } from 'react-responsive'

import styled, { ThemeProvider } from 'styled-components'

import theme from 'common/theme'

import icoPayKakao from '../../assets/images/ico_pay_kakao_170x60.png'
import icoPayNaver from '../../assets/images/ico_pay_naver_246x102.png'
import icoPayToss from '../../assets/images/ico_pay_toss_246x102.png'

const PaymentType = ({ paymentTypeList, returnFn, childRef }) => {
  const isMobile = useMediaQuery({ maxWidth: 1024 })
  const paymentItem = (code) => {
    switch (code) {
      case 'CARD':
        return '카드결제'
      case 'REMITTANCE':
        return '무통장입금'
      case 'ACCOUNT':
        return '계좌이체'
      case 'NPAY':
        return <em className="naver">네이버페이</em>
      case 'TOSS':
        return <em className="toss">토스</em>
      case 'KAKAO':
        return <em className="kakaopay">카카오페이</em>
      case 'ONLYKAKAOPAY':
        return <em className="kakaopay">카카오페이</em>
      default:
    }
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <div className="inner-col" ref={childRef}>
          <PaymentTypeBox>
            <h1>결제 수단</h1>
            <PaymentTypeList listLength={paymentTypeList.length}>
              <ul>
                {paymentTypeList.map((item, idx) => (
                  <li
                    key={idx}
                    className={
                      (paymentTypeList.length === 1 ? `item-one` : ``,
                      item === `REMITTANCE` && isMobile ? ` mobile-none` : ``)
                    }
                  >
                    <label>
                      <input
                        type="radio"
                        name="paymentType"
                        onChange={() => {
                          returnFn(item)
                        }}
                      />
                      <strong>{paymentItem(item)}</strong>
                    </label>
                  </li>
                ))}
              </ul>
            </PaymentTypeList>
          </PaymentTypeBox>
        </div>
      </ThemeProvider>
    </>
  )
}
export default PaymentType

const PaymentTypeBox = styled.div`
  padding-top: 8rem;
  h1 {
    font-weight: 700;
    font-size: 3rem;
    color: #000;
    text-align: left;
  }
  @media ${({ theme }) => theme.device.mobile} {
    padding: 5rem 4.2rem;
    border-top: 1.2rem solid #ececf6;
  }
`

const PaymentTypeList = styled.div`
  width: 100%;
  margin-top: 3rem;
  padding-top: 3rem;
  border-top: 0.3rem solid #000;
  ul {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    li {
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
      width: ${(props) => (props.listLength > 5 ? '33.3%' : 'auto')};
      height: 9.5rem;
      border-top: ${(props) => (props.listLength > 5 ? '0' : '0.1rem solid #cfcfda')};
      border-right: 0.1rem solid #cfcfda;
      border-bottom: 0.1rem solid #cfcfda;
      box-sizing: border-box;
      flex-shrink: 0;
      flex-basis: ${(props) => (props.listLength > 5 ? 'auto' : 'none')};
      &:nth-child(1),
      &:nth-child(2) {
        border-top: 0.1rem solid #cfcfda;
      }
      &:nth-child(3) {
        border-top: 0.1rem solid #cfcfda;
        @media ${({ theme }) => theme.device.mobile} {
          border-top: 0;
        }
      }
      &:nth-child(3n + 1) {
        border-left: ${(props) => (props.listLength > 5 ? '0.1rem solid #cfcfda' : '0')};
        @media ${({ theme }) => theme.device.mobile} {
          border-left: 0;
        }
      }
      &.mobile-none {
        display: none;
      }
      &.item-one {
        justify-content: center;
        border: 0;
        border-left: 0 !important;
        label {
          width: 49.5rem;
          flex-grow: 0;
          flex-shrink: 0;
          flex-basis: auto;
          border: 0.1rem solid #cfcfda;
          box-sizing: border-box;
        }
        @media ${({ theme }) => theme.device.mobile} {
          width: 100%;
          border-top: 0 !important;
          label {
            width: 100%;
          }
        }
      }
      &:first-child {
        border-left: 0.1rem solid #cfcfda;
      }
      label {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        margin: 0;
        padding: 0;
        font-size: 2rem;
        color: #000;
        height: 100%;
        cursor: pointer;
        strong {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          width: 100%;
          height: 100%;
          font-weight: 400;
          box-sizing: border-box;
          border: 0.2rem solid transparent;
          transition: 0.15s;
        }
        input:checked + strong {
          background-color: #ffefc8 !important;
          border: 0.2rem solid #ffb900 !important;
        }
        em {
          display: block;
          width: 100%;
          height: 100%;
          text-indent: -9999rem;
          &.toss {
            background: url(${icoPayToss}) no-repeat 50% 50% / 9.7rem auto;
            @media ${({ theme }) => theme.device.mobile} {
              background: url(${icoPayToss}) no-repeat 50% 50% / 12rem auto;
            }
          }
          &.naver {
            background: url(${icoPayNaver}) no-repeat 50% 50% / 9.7rem auto;
            @media ${({ theme }) => theme.device.mobile} {
              background: url(${icoPayNaver}) no-repeat 50% 50% / 13rem auto;
            }
          }
          &.kakaopay {
            background: url(${icoPayKakao}) no-repeat 50% 50% / 8rem auto;
            @media ${({ theme }) => theme.device.mobile} {
              background: url(${icoPayKakao}) no-repeat 50% 50% / 12rem auto;
            }
          }
        }
      }
    }
  }
  @media ${({ theme }) => theme.device.mobile} {
    margin-top: 3.7rem;
    padding-top: 0;
    border-top: 0;
    ul {
      flex-wrap: wrap;
      li {
        flex-basis: auto;
        flex-wrap: wrap;
        flex-grow: inherit;
        width: 50%;
        height: 13.6rem;
        border-top: 0;
        &:first-child,
        &:nth-child(2) {
          border-top: 0.1rem solid #cfcfda;
        }
        &:nth-child(2n + 1) {
          border-left: 0.1rem solid #cfcfda;
        }
        label {
          font-size: 2.8rem;
        }
      }
    }
  }
`
