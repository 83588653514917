import React from 'react';
import { ThemeProvider } from 'styled-components';
import styled from 'styled-components';
import theme from 'common/theme';

import IcoClose from '../../assets/images/ico_close_90x90w.png';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Navigation } from 'swiper';
//style
import '../../assets/styles/modal.scss';
import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';

SwiperCore.use([Pagination, Navigation]);

export default function ModalReviewDetail({ handleCloseModal, images }) {
  return (
    <>
      <ThemeProvider theme={theme}>
        <ModalSection>
          {images?.type ? (
            <>
              <SlideItem
                style={{
                  backgroundImage: `url(${images.url})`,
                }}
              ></SlideItem>
              <ButtonClose onClick={handleCloseModal}>
                <span>닫기</span>
              </ButtonClose>
            </>
          ) : images?.length > 1 ? (
            <>
              <Swiper
                className="swiper-container"
                spaceBetween={20}
                slidesPerView={1}
                navigation
                loop={true}
                pagination={{ type: 'fraction' }}
              >
                {images.map((item, idx) => {
                  return (
                    <SwiperSlide key={idx} className="swiper-slide">
                      <SlideItem
                        style={{
                          backgroundImage: `url(${
                            item.attachmentPath
                              ? item.attachmentPath
                              : item.imageUrl
                              ? item.imageUrl
                              : "'" + item.url + "'"
                          })`,
                        }}
                      ></SlideItem>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
              <ButtonClose onClick={handleCloseModal}>
                <span>닫기</span>
              </ButtonClose>
            </>
          ) : (
            <>
              <SlideItem
                style={{
                  backgroundImage: `url(${
                    images[0].attachmentPath
                      ? images[0].attachmentPath
                      : images[0].imageUrl
                      ? images[0].imageUrl
                      : "'" + images[0].url + "'"
                  })`,
                }}
              ></SlideItem>
              <ButtonClose onClick={handleCloseModal}>
                <span>닫기</span>
              </ButtonClose>
            </>
          )}
        </ModalSection>
      </ThemeProvider>
    </>
  );
}

const ModalSection = styled.section`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 999;
  background-color: #00000099;

  .swiper-container {
    height: 100%;
    padding: 10.8rem 0;
    box-sizing: border-box;

    .swiper-button-prev {
      left: 6rem;
    }

    .swiper-button-next {
      right: 6rem;
    }

    .swiper-button-prev,
    .swiper-button-next {
      color: #fff;

      &::after {
        font-size: 3rem;
      }
    }
  }

  .swiper-pagination {
    display: inline-block;
    top: 3.6rem;
    left: 5rem;
    bottom: auto;
    width: auto;
    font-weight: 700;
    font-size: 2rem;
    color: #fff;
  }

  @media ${({ theme }) => theme.device.mobile} {
    .swiper-pagination {
      display: block;
      width: 100%;
      left: 0;
      font-size: 3.1rem;
    }

    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }
  }
`;

const SlideItem = styled.span`
  display: block;
  max-width: 90rem;
  height: 100%;
  margin: 0 auto;
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;

  @media ${({ theme }) => theme.device.mobile} {
    max-width: 100%;
    height: 100%;
  }
`;

const ButtonClose = styled.button`
  position: absolute;
  top: 3rem;
  right: 5rem;
  z-index: 10;
  width: 2.8rem;
  height: 2.8rem;
  padding: 1rem;
  background: url(${IcoClose}) 50% 50%/100% auto no-repeat;

  span {
    display: none;
  }

  @media ${({ theme }) => theme.device.mobile} {
    top: 3.7rem;
    right: 5.3rem;
    width: 3.4rem;
    height: 3.4rem;
  }
`;
