import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import theme from 'common/theme';

const TitleCol = styled.div`
  text-align: left;

  h3 {
    margin-bottom: 2.4rem;
    font-size: 4.8rem;
    line-height: 1.3;
  }

  p {
    color: #717188;
    font-size: 2.4rem;
    white-space: pre-line;

    @media ${({ theme }) => theme.device.mobile} {
      font-size: 2.8rem;
    }
  }
`;

const InnerCol = styled.section`
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 3rem 15rem;
`;

const ListText = styled.div`
  display: flex;
  margin-top: 5rem;
  font-size: 3rem;
  align-items: center;
  word-break: break-word;

  h4,
  div {
    margin-bottom: 2rem;
  }

  h4 {
    flex-basis: 30rem;
    min-width: 30rem;
  }

  p {
    font-weight: ${(props) => props.fontWeight || 400};
    text-transform: uppercase;
    line-height: 1.5;
  }

  span {
    text-transform: lowercase;
  }

  @media ${({ theme }) => theme.device.mobile} {
    font-size: 2.5rem;

    h4 {
      flex-basis: 22rem;
      min-width: 22rem;
      font-size: 2.5rem;
    }
  }
`;

export default function Temp() {
  const TitleTemp = ({ title, desc }) => {
    return (
      <TitleCol>
        <h3>{title}</h3>
        <p>{desc}</p>
      </TitleCol>
    );
  };

  const ListTemp = ({ fontStyle, fontWeight }) => {
    return (
      <ListText fontStyle={fontStyle} fontWeight={fontWeight}>
        <>
          <h4>
            {fontStyle}
            <br />
            {fontWeight}
          </h4>
          {fontStyle.indexOf('Inter') != -1 ? (
            <div>
              <p>!@#$%^&amp;*()</p>
              <p>1234567890</p>
              <p>
                <span>abcdefghijklnmopqrstuvwxyz</span>
              </p>
              <p>abcdefghijklnmopqrstuvwxyz</p>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            </div>
          ) : (
            <div>
              <p>가나다라마바사아자차카타파하</p>
              <p>다람쥐 헌 쳇바퀴에 타고파</p>  
            </div>
          )}
        </>
      </ListText>
    );
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <InnerCol>
          <TitleTemp title={'Fonts'} desc={'한글 : NotoSansCJKkr, 그 외 : Inter'} />
          <ListTemp fontStyle={'NotoSansCJKkr Light'} fontWeight={200} />
          <ListTemp fontStyle={'NotoSansCJKkr Regular'} fontWeight={400} />
          <ListTemp fontStyle={'NotoSansCJKkr Medium'} fontWeight={500} />
          <ListTemp fontStyle={'NotoSansCJKkr Bold'} fontWeight={700} />
          <ListTemp fontStyle={'NotoSansCJKkr Black'} fontWeight={800} />
          <ListTemp fontStyle={'Inter Light'} fontWeight={200} />
          <ListTemp fontStyle={'Inter Regular'} fontWeight={400} />
          <ListTemp fontStyle={'Inter Medium'} fontWeight={500} />
          <ListTemp fontStyle={'Inter Bold'} fontWeight={700} />
          <ListTemp fontStyle={'Inter Black'} fontWeight={800} />
        </InnerCol>
      </ThemeProvider>
    </>
  );
}
