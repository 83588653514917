import React, { useCallback, useEffect, useState } from 'react'

import api from 'common/api'
import { API_HOST } from 'common/config'
import ResponsiveImage from 'components/ResponsiveImage'

import styles from './ModalQuestionForm.module.scss'
import ModalWrap from '../../modal/ModalWrap'
import { dbCollectionFormValues } from '../config'

export default function ModalQuestionForm({
  promotion,
  formData,
  formDataInit,
  modalControl,
  handleModalToggle,
  setFormData,
  setAllChecked
}) {
  const { category, alertModalBanner } = dbCollectionFormValues[promotion]
  const [userSelected, setUserSelected] = useState(initialState)

  const handleSelectChange = (e) => {
    const { name, value } = e.target
    setUserSelected({
      ...userSelected,
      [name]: value
    })
  }

  const checkSelectValid = () => {
    const emptyIndex = Object.values(userSelected).findIndex((ele) => ele.length === 0)

    if (emptyIndex > -1) {
      window.alert(modalQuestionList[emptyIndex].errorMessage)
      return false
    }

    return true
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!checkSelectValid()) return

    const content = Object.entries(userSelected)
      .map((ele) => ele.join('.'))
      .join(' ')

    // 요청
    const reqBody = {
      ...formData,
      content,
      privacyAgreeYn: formData.privacyAgreeYn ? 'Y' : 'N',
      smsAgreeYn: formData.smsAgreeYn ? 'Y' : 'N'
    }

    try {
      const res = await api.post(`${API_HOST}/v2/inquiry/consultation/tel`, reqBody)
      const isDuplication = !res.data.data

      isDuplication ? handleModalToggle('duplicate') : handleModalToggle('success')
      !isDuplication && window.dataLayer.push({ event: 'lead', product_name: `${category}1` })
    } catch (error) {
      console.error(error)
    } finally {
      setFormData(formDataInit)
      setAllChecked(false)
    }
  }

  const handleBeforeUnload = useCallback(
    (e) => {
      const reqBody = {
        ...formData,
        privacyAgreeYn: formData.privacyAgreeYn ? 'Y' : 'N',
        smsAgreeYn: formData.smsAgreeYn ? 'Y' : 'N'
      }

      api.post(`${API_HOST}/v2/inquiry/consultation/tel`, reqBody)

      e.returnValue = '사이트에서 나가시겠습니까? 변경사항이 저장되지 않을 수 있습니다.'
    },
    [formData]
  )

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [formData, handleBeforeUnload])

  return (
    <>
      {!modalControl.success && !modalControl.duplicate && (
        <ModalWrap>
          <div className={styles.container}>
            {alertModalBanner && (
              <div>
                <ResponsiveImage
                  pc={alertModalBanner.pc}
                  mo={alertModalBanner?.pc ? alertModalBanner.pc : alertModalBanner.mo}
                  alt={alertModalBanner.alt}
                />
              </div>
            )}

            <form onSubmit={handleSubmit} className={styles.form}>
              <ul className={styles.list}>
                {modalQuestionList.map((question) => (
                  <li key={question.id} className={styles.question}>
                    <label className={styles.title}>
                      {question.id}. <p>{question.label}</p>
                    </label>
                    <div className={styles.choice}>
                      {question.options.map((option) => (
                        <label
                          htmlFor={`${question.id}_${option.value}`}
                          key={option.value}
                          className={styles.label}
                        >
                          <input
                            type="radio"
                            id={`${question.id}_${option.value}`}
                            name={question.id}
                            value={option.value}
                            onChange={handleSelectChange}
                          />
                          <em></em>
                          {option.label}
                        </label>
                      ))}
                    </div>
                  </li>
                ))}
              </ul>

              <button type="submit" className={styles.submitButton}>
                제출하기
              </button>
            </form>
          </div>
        </ModalWrap>
      )}
    </>
  )
}

const initialState = {
  1: '',
  2: '',
  3: '',
  4: '',
  5: ''
}

const modalQuestionList = [
  {
    id: '1',
    label: (
      <>
        고객님의 <strong>성별</strong>을 선택해주세요.
      </>
    ),
    options: [
      { value: '여자', label: '여자' },
      { value: '남자', label: '남자' }
    ],
    errorMessage: '성별을 선택해주세요!'
  },
  {
    id: '2',
    label: (
      <>
        고객님의 <strong>연령대</strong>를 선택해주세요.
      </>
    ),
    options: [
      { value: '10대', label: '10대' },
      { value: '20대', label: '20대' },
      { value: '30대', label: '30대' },
      { value: '40대', label: '40대' },
      { value: '50대', label: '50대' },
      { value: '60대 이상', label: '60대 이상' }
    ],
    errorMessage: '연령대를 선택해주세요!'
  },
  {
    id: '3',
    label: (
      <>
        고객님의 <strong>직업</strong>을 선택해주세요.
      </>
    ),
    options: [
      { value: '회사원', label: '회사원' },
      { value: '주부', label: '주부' },
      { value: '학생', label: '학생' },
      { value: '자영업', label: '자영업' },
      { value: '기타', label: '기타' }
    ],
    errorMessage: '직업을 선택해주세요!'
  },
  {
    id: '4',
    label: (
      <>
        고객님의 <strong>학습목표</strong>를 선택해주세요.
      </>
    ),
    options: [
      { value: '자기개발', label: '자기개발' },
      { value: '해외여행', label: '해외여행' },
      { value: '커리어 향상', label: '커리어 향상' },
      { value: '외국인과 대화', label: '외국인과 대화' },
      { value: '기타', label: '기타' }
    ],
    errorMessage: '학습목표를 선택해주세요!'
  },
  {
    id: '5',
    label: (
      <>
        고객님이 영어 공부에 투자하는
        <br /> <strong>월 평균 비용</strong>을 선택해주세요.
      </>
    ),
    options: [
      { value: '5만원 이내', label: '5만원 이내' },
      { value: '5 ~ 10만원', label: '5 ~ 10만원' },
      { value: '10 ~ 20만원', label: '10 ~ 20만원' },
      { value: '20 ~ 30만원', label: '20 ~ 30만원' }
    ],
    errorMessage: '월 평균 비용을 선택해주세요!'
  }
]
