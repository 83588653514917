export default function useBodyClassToggle(toggleClass) {
  const addClass = () => {
    document.body.classList.add(toggleClass)
  }

  const removeClass = () => {
    document.body.classList.remove(toggleClass)
  }

  return { addClass, removeClass }
}
