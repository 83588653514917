import React from 'react'

import MessageAi from './MessageAi'
import MessageUser from './MessageUser'

export default function ChatFeedItem({ type, message, metaInfo, userSeq }) {
  if (type === 'AI') {
    return <MessageAi message={message} metaInfo={metaInfo} userSeq={userSeq} />
  } else {
    return <MessageUser message={message} userSeq={userSeq} />
  }
}
