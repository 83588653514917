import React from 'react'
import { Route, Routes } from 'react-router'

import { DefaultLayout } from './OutletStore'
import AiTalkRoutes from './routes/AiTalkRoutes'
import ClassRoutes from './routes/ClassRoutes'
import CommunityRoutes from './routes/CommunityRoutes'
import EtcRoutes from './routes/EtcRoutes'
import EventRoutes from './routes/EventRoutes'
import FramelessRoutes from './routes/FramelessRoutes'
import KakaoNoticeRoutes from './routes/KakaoNoticeRoutes'
import LevelTestRoutes from './routes/LevelTestRoutes'
import MainRoutes from './routes/MainRoutes'
import MallRoutes from './routes/MallRoutes'
import MemberRoutes from './routes/MemberRoutes'
import MyclassRoutes from './routes/MyclassRoutes'
import MypageRoutes from './routes/MypageRoutes'
import OrderRoutes from './routes/OrderRoutes'
import PromotionRoutes from './routes/PromotionRoutes'
import ReviewRoutes from './routes/ReviewRoutes'
import ServiceRoutes from './routes/ServiceRoutes'

export default function AppRouter() {
  const routes = [
    ...MainRoutes,
    ...PromotionRoutes,
    ...MemberRoutes,
    ...CommunityRoutes,
    ...EventRoutes,
    ...MypageRoutes,
    ...MyclassRoutes,
    ...MallRoutes,
    ...LevelTestRoutes,
    ...OrderRoutes,
    ...ClassRoutes,
    ...ServiceRoutes,
    ...ReviewRoutes,
    ...AiTalkRoutes,
    ...EtcRoutes,
    ...KakaoNoticeRoutes
  ]
  const frameless = [...FramelessRoutes]

  return (
    <Routes>
      <Route element={<DefaultLayout />}>
        {
          // layout routes
          routes.map((route, index) => (
            <Route key={index} path={route.path} element={route.element}>
              {route.children &&
                route.children.map((childRoute, childIndex) => (
                  <Route key={childIndex} path={childRoute.path} element={childRoute.element} />
                ))}
            </Route>
          ))
        }
      </Route>
      {
        // frameless (webview, iframe) routes
        frameless.map((route, index) => (
          <Route key={index} path={route.path} element={route.element}>
            {route.children &&
              route.children.map((childRoute, childIndex) => (
                <Route key={childIndex} path={childRoute.path} element={childRoute.element} />
              ))}
          </Route>
        ))
      }
    </Routes>
  )
}
