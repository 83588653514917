import classNames from 'classnames'

import useSubHeaderGnb from 'components/header/hooks/useSubHeaderGnb'

import GnbItem from './GnbItem'
import SubLogo from './SubLogo'
import styles from '../../styles/SubHeader.module.scss'
import SideMenu from '../sideMenu'

export default function SubHeaderGnb({ companyItem }) {
  const {
    gnbAreaRef,
    gnbItems,
    gnbMovePoint,
    gnbBoxRef,
    depth2Active,
    depth2Selected,
    isMoDepth3Chk,
    hasPrevButton,
    hasNextButton,
    currentPath,
    setDepth2Selected,
    setMoDepth3Chk,
    gnbMoveEvent,
    depthSelected,
    onActiveMobileGnb,
    currentSubIndex,
    hasDragNext,
    hasDragPrev,
    onDragStart,
    onDragMove,
    onDragEnd
  } = useSubHeaderGnb()

  return (
    <>
      <section className={styles.subHeaderGnb}>
        <div className={styles.inner}>
          <div className={styles.leftArea} ref={gnbAreaRef}>
            <SubLogo currentIdx={currentSubIndex} gnbItems={gnbItems} />
            {gnbItems
              ?.filter((item, idx) => idx === currentSubIndex)
              ?.map((gnb, index) => (
                <ul
                  key={index}
                  className={classNames(
                    styles.gnbList,
                    hasDragNext && styles.hasDragNext,
                    hasDragPrev && styles.hasDragPrev
                  )}
                  onMouseDown={onDragStart}
                  onMouseMove={onDragMove}
                  onMouseUp={onDragEnd}
                  onMouseLeave={onDragEnd}
                  style={{ marginLeft: +gnbMovePoint + 'px' }}
                  ref={gnbBoxRef}
                >
                  {gnb.childGnbMenus.map((item, index, e) => (
                    <GnbItem
                      key={index}
                      item={item}
                      index={index}
                      currentPath={currentPath}
                      onActiveMobileGnb={onActiveMobileGnb}
                      isMoDepth3Chk={isMoDepth3Chk}
                      depth2Active={depth2Active}
                      depth2Selected={depth2Selected}
                      companyItem={companyItem}
                      depthSelected={depthSelected}
                      setDepth2Selected={setDepth2Selected}
                      setMoDepth3Chk={setMoDepth3Chk}
                    />
                  ))}
                </ul>
              ))}
            {hasPrevButton && (
              <button
                type="button"
                className={styles.buttonGnbPrev}
                onClick={() => gnbMoveEvent('prev')}
              >
                prev
              </button>
            )}
            {hasNextButton && (
              <button
                type="button"
                className={styles.buttonGnbNext}
                onClick={() => gnbMoveEvent('next')}
              >
                next
              </button>
            )}
          </div>
          {/* side menu */}
          <SideMenu />
        </div>
      </section>
      <div
        className={styles.modalDim}
        display={isMoDepth3Chk ? 'block' : 'none'}
        onClick={() => setMoDepth3Chk(false)}
      />
    </>
  )
}
