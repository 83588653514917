import api from 'common/api'
import * as config from 'common/config'

//  배너 조회
export const getBannerList = async (category, bannerType) => {
  return await api
    .get(`/v2/store/banner?category=${category}${bannerType ? `&bannerType=${bannerType}` : ''}`)
    .then((response) => {
      return response.data.data[0]?.bannerList
    })
    .catch((e) => {
      alert(config.MESSAGE['common-error'])
    })
}
