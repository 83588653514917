import React from 'react'

import icoImg from '../../assets/images/ico_time.png'
import NoContent from '../../components/NoContent'

export default function Expiration() {
  return (
    <div className="class-empty expiration">
      <NoContent
        icoImg={icoImg}
        titleMsg={
          <>
            인증이 완료되었거나,
            <br /> 유효시간이 지났습니다.
          </>
        }
        descMsg={
          <>
            인증을 다시 시도하려면 고객센터로
            <br /> 문의해주시기 바랍니다.
          </>
        }
      />
      <a href="tel:1600-0563" className="btn positive">
        <span className="call">call</span>1600 - 0563
      </a>
    </div>
  )
}
