import { useCallback, useState, useRef, useEffect } from 'react';
import ModalPortal from '../../ModalPortal';
import AlertModal from '../modal/AlertModal';
import api from 'common/api';
import styled, { ThemeProvider } from 'styled-components';
import theme from 'common/theme';

const CouponRegister = ({ couponAllListData, refleshFn }) => {
  const [couponCodeList, setCouponCodeList] = useState([]);
  const [flag, setFlag] = useState(false);
  const [modalopen, setModalopen] = useState(false);
  const [couponCode, setCouponCode] = useState('');
  const [couponCodeLength, setCouponCodeLength] = useState(null);
  const couponRef = useRef();

  useEffect(() => {
    // 등록 된 쿠폰 리스트
    setCouponCodeList(
      couponAllListData.map((item, idx) => {
        return item.couponCode;
      })
    );
  }, [couponAllListData]);

  const handleCloseModal2 = useCallback((e) => {
    document.body.classList.remove('modal-open');
    setModalopen(false);
    couponRef.current.value = '';
  }, []);

  // 쿠폰번호 validation check.
  const changeRegisterNumber = (e) => {
    let value = e.target.value;
    let regCheck = /^[A-Za-z0-9\-\_]{1,20}$/;

    setCouponCodeLength(value.length);
    setCouponCode(value);

    if (regCheck.test(value) && value.length > 0) {
      setFlag(true);
    } else {
      setFlag(false);
    }
  };

  // 쿠폰 등록.
  const postCouponCode = (e) => {
    e.preventDefault();

    if (couponCode === '' || !flag) {
      alert('쿠폰 번호를 확인해주세요.');
      couponRef.current.focus();
      return false;
    }

    if (couponCodeList.includes(couponCode)) {
      couponRef.current.focus();
      return false;
    }

    const data = {
      couponCode: couponCode,
    };

    api
      .post(`/v2/coupon-publish?couponCode=${data.couponCode}`)
      .then((response) => {
        if (response.data.meta.code !== 200) {
          alert(response.data.meta.message);
        } else {
          alert('쿠폰 등록이 완료되었습니다!');
          setCouponCode('');
          refleshFn();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <CouponDownloadBox>
          <form name="registerForm" action="#">
            <input
              type="text"
              className={'coupon-code' + (flag === true ? ' active' : '')}
              maxLength="20"
              placeholder="쿠폰 코드를 입력해주세요."
              onChange={changeRegisterNumber}
              ref={couponRef}
              value={couponCode}
            />
            <button
              type="submit"
              className={flag === true ? ' active' : ' disabled'}
              onClick={postCouponCode}
            >
              쿠폰 등록
            </button>
          </form>
        </CouponDownloadBox>
        {modalopen ? (
          <ModalPortal>
            <AlertModal
              btnGroup
              msg={'쿠폰이 등록이 완료되었습니다.'}
              handleCloseModal={handleCloseModal2}
            />
          </ModalPortal>
        ) : null}
      </ThemeProvider>
    </>
  );
};

export default CouponRegister;

const CouponDownloadBox = styled.div`
  display: flex;
  padding: 2rem 2rem 1rem;
  form {
    display: flex;
    width: 100%;
  }
  input {
    flex: 1 auto;
    height: 5rem;
    padding: 0 2rem;
    background-color: #fff;
    border: 0.1rem solid #dfdfea;
    border-radius: 0.3rem;
    box-sizing: border-box;
  }
  button {
    width: 11.3rem;
    height: 5rem;
    margin-left: 1.2rem;
    padding: 0;
    font-size: 1.4rem;
    color: #fff;
    background-color: #cfcfda;
    border-radius: 0.3rem;
    transition: 0.3s;
    outline: 0;
    border: 0;
    &.active {
      background-color: #28284b;
    }
  }
  @media ${({ theme }) => theme.device.mobile} {
    padding: 3rem 3.1rem 1rem;
    input {
      min-width: 2rem;
      height: 10rem;
      padding: 0 5.2rem;
      font-size: 2.7rem;
      border-radius: 0.5rem;
    }
    button {
      width: 21rem;
      height: 10rem;
      margin-left: 1.8rem;
      font-size: 2.7rem;
      border-radius: 0.5rem;
    }
  }
`;
