import React, { useState, useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ModalPortal from '../../ModalPortal';
import ModalJoinOverseas from '../../components/modal/ModalJoinOverseas';
import api from 'common/api';
import * as config from 'common/config';

export default function JoinOverseasPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const overseasToken = location.search.split('=')[1];
  const [overseasModalopen, setOverseasModalopen] = useState(false);
  const [tokenData, setTokenData] = useState(null);

  const handleCloseModalJoin = useCallback((e) => {
    document.body.classList.remove('modal-open');
    setOverseasModalopen(false);
  }, []);

  const handleOverseasToken = () => {
    api
      .get(
        config.AUTH_API_HOST +
          '/auth/v2/overseas-user/ticket?signature=' +
          overseasToken
      )
      .then((response) => {
        setTokenData(response.data.data);

        if (response.data.data.verify !== true) {
          navigate('/member/expiration');
        }
      })
      .catch((e) => {
        alert(config.MESSAGE['common-error']);
      });
  };

  useEffect(() => {
    handleOverseasToken();
  }, []);

  useEffect(() => {
    if (tokenData && tokenData.verify) {
      setOverseasModalopen(true);
    }
  }, [tokenData]);

  return (
    <>
      <div className="no-content" style={{padding: '28rem 0'}}></div>
      {overseasModalopen ? (
        <ModalPortal>
          <ModalJoinOverseas
            title={'해외거주자'}
            btnClose
            handleCloseModal={handleCloseModalJoin}
            overseasToken={overseasToken}
            tokenData={tokenData}
          />
        </ModalPortal>
      ) : null}
    </>
  );
}
