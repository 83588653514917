import { useReducer } from 'react'
import { Link } from 'react-router-dom'

import { getCookie, setCookie } from 'common/Cookie'

export default function LearnInquiryBtn() {
  const getModalStatusByCookie = getCookie('learnInquiryPopupClose')
  const [isModalOpen, toggleModal] = useReducer((prev) => !prev, getModalStatusByCookie !== 'N')

  const handleCloseDate = (cookies) => {
    setCookie(cookies, 'N', 1)
    toggleModal()
  }

  return (
    <>
      <button type="button" className="learn-inquiry" onClick={toggleModal}>
        <InfoIcon />
        <p>학습문의 안내</p>
      </button>

      {isModalOpen && (
        <>
          <div
            className="ai-nadoo-modal-dim"
            onClick={() => handleCloseDate('learnInquiryPopupClose')}
          ></div>
          <div className="ai-nadoo-modal">
            <div>
              <img
                src="https://english.yanadoocdn.com/upload/yanadoo/new/my/myclass/ai-nadoo-modal.png"
                alt="영어 학습 궁금증이 있나요? AI 나두에게 언제든지 물어보세요!"
              />
              <div className="ai-nadoo-modal__link">
                <p>이용방법</p>
                <Link to="https://www.yanadoo.co.kr/service/notice/detail/1041" target="_blank">
                  [업데이트] AI나두, 학습 Q&A에 AI 기능이 추가되었어요!
                </Link>
              </div>
            </div>
            <button
              type="button"
              className="close-btn"
              onClick={() => handleCloseDate('learnInquiryPopupClose')}
            >
              <CloseIcon />
            </button>
          </div>
        </>
      )}
    </>
  )
}

const InfoIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <circle cx="8" cy="8.00012" r="7" stroke="#28284B" strokeWidth="1.5" />
      <path
        d="M8 11.0978V7.06238"
        stroke="#28284B"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeslinejoin="round"
      />
      <path
        d="M8 4.00012C7.44772 4.00012 7 4.44784 7 5.00012C7 5.55241 7.44772 6.00012 8 6.00012C8.55228 6.00012 9 5.55241 9 5.00012C9 4.44784 8.55228 4.00012 8 4.00012Z"
        fill="#28284B"
      />
    </svg>
  )
}

const CloseIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <path
        d="M1 1L17 17M17 1L1 17"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
