import React, { useState, useRef, useEffect } from 'react';
import api from 'common/api';
import * as config from 'common/config';
import '../../assets/styles/modal.scss';
import '../../assets/styles/eventModal.scss';

let today = new Date();

let year = today.getFullYear();
let month = today.getMonth() + 1;
let date = today.getDate();

let dateString = year + '-' + month + '-' + date;

const ModalEventReview = ({
  btnClose,
  handleCloseModal,
  eventSeq,
  userInfo,
}) => {
  const commentInput = useRef();
  const [comment, setComment] = useState('');
  const [thumbList, setThumbList] = useState();
  const [selectImgUrl, setSelectImgUrl] = useState();
  const [getImgData, setGetImgData] = useState();

  const uploadImgData =
    getImgData &&
    getImgData.map((list) => {
      return {
        attachFileType: 'PHOTO',
        attachFilePath: list.filePath,
        attachFileName: list.saveFileName,
      };
    });

  const handleCommentValue = (e) => {
    setComment(e.target.value);
  };

  const handleImageUpload = (e) => {
    const fileArr = e.target.files; // 첨부한 여러개의 이미지 파일을 불러옴.
    let fileURLs = [];
    let file;

    if (fileArr.length > 5) {
      alert('이미지는 5장까지만 등록 가능합니다.');
    } else {
      for (let i = 0; i < fileArr.length; i++) {
        file = fileArr[i];

        let reader = new FileReader(); // FileReader API를 사용하기 위해 선언.

        reader.onload = () => {
          // 파일 업로드 기능이 끝났을 때 실행.
          fileURLs[i] = reader.result; // 결과값을 배열로 담아줌.
          setThumbList([...fileURLs]); // thumbList 배열값 업데이트.
        };

        reader.readAsDataURL(file); // 파일을 Base64 Encode 문자열로 반환
      }

      setSelectImgUrl([...fileArr]); // aws에 등록할 이미지 리스트
    }
  };

  // aws에 이미지 등록
  const postImage = () => {
    const formData = new FormData();

    selectImgUrl &&
      selectImgUrl.map((file) => {
        formData.append('files', file);
      });
    formData.append('attachFileType', 'IMAGE');
    formData.append('directoryType', 'EVENT');
    formData.append('directory', `/${eventSeq}/`);

    api
      .post('/v2/file/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        if (response.data.meta.code !== 200) {
          if (response.data.meta.message) {
            alert(response.data.meta.message);
          } else {
            alert(config.MESSAGE['common-error']);
          }
          return;
        }

        setGetImgData(response.data.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // 댓글 이벤트 등록
  const postReply = () => {
    if (comment === '') {
      alert('댓글을 입력해주세요.');
      commentInput.current.focus();
      return false;
    }

    if (!thumbList) {
      alert('이미지는 최소 1개 이상 등록되어야 합니다.');
      return false;
    }

    const data = {
      eventSeq: eventSeq,
      content: comment,
      eventApplyBoardAttachFiles: uploadImgData,
    };

    api
      .post('/v2/event/apply/board', data, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        if (response.data.meta.code !== 200) {
          alert(response.data.meta.message);
          commentInput.current.focus();

          return false;
        }

        alert('댓글 작성이 완료되었습니다!');
        setComment('');
        window.location.reload();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (selectImgUrl) {
      postImage();
    }
  }, [selectImgUrl]);

  return (
    <>
      <div className="modal-wrap review-modal">
        <div className="dimmed" onClick={handleCloseModal}></div>
        <div className="modal-inner">
          {btnClose && (
            <button
              type="button"
              className="btn-modal-close"
              onClick={handleCloseModal}
            >
              <i className="blind">닫기</i>
            </button>
          )}
          <div className="modal-header">
            <strong>새글작성</strong>
          </div>
          <div className="modal-content">
            <form name="eventReviewFrm" actiom="#">
              <div className="inner">
                <div className="modal-desc">
                  <dl className="user-thumb-box">
                    <dt
                      style={{
                        backgroundImage: `url(${userInfo.profileImageUrl})`,
                      }}
                    >
                      <i className="blind">프로필 이미지</i>
                    </dt>
                    <dd>
                      <p>
                        <strong>{userInfo.nickName}</strong>
                      </p>
                      <p>
                        <span>{dateString}</span>
                      </p>
                    </dd>
                  </dl>
                  <textarea
                    placeholder="내용을 입력해주세요."
                    value={comment}
                    ref={commentInput}
                    onChange={handleCommentValue}
                  />
                </div>
                <div className="img-thumb-area">
                  <label className="select-btn" htmlFor="select-image-file">
                    <em>사진은 최대 5장 까지 첨부됩니다.</em>
                  </label>
                  <input
                    type="file"
                    accept="image/*"
                    name="select-image-file"
                    id="select-image-file"
                    multiple
                    onChange={handleImageUpload}
                  />
                  <ul className={thumbList ? ' active' : null}>
                    {thumbList &&
                      thumbList.map((item, idx) => (
                        <li
                          key={idx}
                          style={{ backgroundImage: `url(${item})` }}
                        ></li>
                      ))}
                  </ul>
                </div>
                <div className="btn-group">
                  <button type="button" onClick={postReply}>
                    <span>등록</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalEventReview;
