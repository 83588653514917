import { useState } from 'react'

import api from 'common/api'

export default function useGetCouponPossibleDownload() {
  const [couponDownloadData, setCouponDownloadData] = useState([])

  const getCouponDownload = async (existDownloadCoupon, packageSeq) => {
    let couponViewList = []
    try {
      if (existDownloadCoupon && existDownloadCoupon === 'Y') {
        //1. 유저보유 쿠폰(사용가능한)
        await api
          .get(`/v2/coupon/possible/download/list?keys=packageSeq&types=equal&values=${packageSeq}`)
          .then((response) => {
            couponViewList = response.data.data.content
          })
          .catch((e) => {
            console.log(e)
          })
      }
      // 2. 다운로드 가능 쿠폰
      await api
        .get(`/v2/coupon-publish/list?index=1&size=100&types&keys=couponUseYn&types=equal&values=N`)
        .then((response) => {
          const couponData = response.data.data.content

          couponData.forEach((item) => {
            item.couponToPackageInfoDTOList.forEach((detailItem, idx) => {
              if (Number(detailItem.packageSeq) === Number(packageSeq)) {
                item.getCouponYn = true
                couponViewList.push(item)
              }
            })
          })
        })
        .catch((e) => {
          console.log(e)
        })
      setCouponDownloadData(couponViewList)
    } catch (error) {
      console.log(error)
    }
  }

  return { couponDownloadData, getCouponDownload }
}
