import { useEffect, useState, useRef } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import theme from 'common/theme';
import Loading from 'components/common/Loading';
import api from 'common/api';
import ico_close_b from '../../assets/images/ico_close_72x72b.png';

const ModalImageCollectAll = ({
  type,
  title,
  btnClose,
  handleCloseModal,
  handleOpenImageDetailModal,
  apiInfo,
  categoryData,
  handleCategoryCodeList,
}) => {
  const imageCountRef = useRef(28);
  const scrollCheckRef = useRef();
  const [cardImages, setCardImages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [lastCheckFlag, setLastCheckFlag] = useState(false);
  const [imageIdx, setImageIdx] = useState(1);

  const options = {
    root: null,
    rootMargin: '0px 0px 0px 0px',
    threshold: 0,
  };

  const getAttachments = (idx, size, category) => {
    let categoryCode;
    if (category) {
      categoryCode = handleCategoryCodeList(categoryData.category, 'categories');
    }
    (type === 'SRR'
      ? api.get(apiInfo.apiAttachmentsUrl + `${idx}&pageSize=${size}`)
      : api.get(apiInfo.apiAttachmentsUrl + `${idx}&pageSize=${size}${categoryCode}`)
    )
      .then((response) => {
        if (response.data.data) {
          setIsLoading(false);
          handleMoreData(response.data.data?.attachments);
        } else {
          setLastCheckFlag(true);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleMoreIndex = () => {
    setImageIdx((prev) => prev + 1);
  };

  const handleMoreData = (data) => {
    const imageData = [...new Set(data.map(JSON.stringify))].map(JSON.parse);
    setCardImages([...cardImages, ...imageData]);
  };

  useEffect(() => {
    if (type === 'SRR') {
      getAttachments(imageIdx, imageCountRef.current);
    } else if (type === 'REAL' && categoryData) {
      getAttachments(imageIdx, imageCountRef.current, categoryData.category);
    }
  }, []);

  useEffect(() => {
    if (!isLoading && !lastCheckFlag) {
      if (type === 'SRR') {
        getAttachments(imageIdx, imageCountRef.current);
      } else if (type === 'REAL' && categoryData) {
        getAttachments(imageIdx, imageCountRef.current, categoryData.category);
      }
    }
  }, [imageIdx, categoryData]);

  useEffect(() => {
    if (!isLoading && !lastCheckFlag) {
      //로딩되었을 때만 실행
      const observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          handleMoreIndex();
        }
      }, options);

      observer.observe(scrollCheckRef.current);
    }
  }, [isLoading]);

  return (
    <ThemeProvider theme={theme}>
      <div className="modal-wrap modal-image">
        <div className="dimmed" onClick={handleCloseModal}></div>
        <div className="modal-inner">
          {btnClose && (
            <ButtonClose type="button" onClick={handleCloseModal}>
              <i className="blind">닫기</i>
            </ButtonClose>
          )}
          <div className="modal-header">
            <strong>{title}</strong>
          </div>
          <div className="modal-content">
            {!isLoading && cardImages.length > 0 ? (
              <CardLists>
                {cardImages
                  .filter((item) => item.url !== '')
                  .map((cardImage, idx) => {
                    return (
                      <li key={idx} style={{ backgroundImage: `url(${cardImage.url})` }}>
                        {cardImage?.count > 1 && <span>{cardImage?.count}</span>}
                        <button
                          onClick={() => {
                            handleOpenImageDetailModal(cardImage);
                            handleCloseModal();
                          }}
                        >
                          <theme.Blind>상세 보기 팝업</theme.Blind>
                        </button>
                      </li>
                    );
                  })}
              </CardLists>
            ) : (
              <Loading />
            )}
            <div ref={scrollCheckRef}></div>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default ModalImageCollectAll;

const ButtonClose = styled.button`
  position: absolute;
  top: 1.4rem;
  right: 2rem;
  z-index: 10;
  width: 4.2rem;
  height: 4.2rem;
  padding: 0;
  background-size: 100% auto;
  background-image: url(${ico_close_b});
  background-position: 50% 50%;
  background-color: transparent;

  @media ${({ theme }) => theme.device.mobile} {
    top: 1.8rem;
    right: 3.4rem;
    width: 7.2rem;
    height: 7.2rem;
  }
`;

const CardLists = styled.ul`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 2.8rem;
  box-sizing: border-box;

  li {
    flex: 0 1 13.4%;
    position: relative;
    margin: 0.4rem;
    padding-bottom: 13.49%;
    background-color: #e7e7f0;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;

    span {
      position: absolute;
      bottom: 0;
      right: 0;
      min-width: 2.8rem;
      padding: 0.64rem 0 0.5rem;
      font-family: 'Inter';
      font-weight: 700;
      font-size: 1.4rem;
      color: #fff;
      background-color: rgba(40, 40, 75, 0.6);
      text-align: center;
      box-sizing: border-box;
    }

    button {
      position: absolute;
      width: 100%;
      height: 100%;
      bottom: 0;
      right: 0;
      cursor: pointer;
    }
  }

  & + div {
    height: 1px;
  }

  @media ${({ theme }) => theme.device.mobile} {
    padding: 3.8rem;

    li {
      flex: 0 1 32.05%;
      padding-bottom: 32.05%;

      span {
        min-width: 4.4rem;
        padding: 1rem 1rem;
        font-size: 2rem;
      }
    }
  }
`;
