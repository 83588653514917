import React from 'react';
import { NavLink } from 'react-router-dom';

const AlertModal = ({
  imgURL,
  titleMsg,
  subTitleMsg,
  msg,
  customTag,
  btnClose,
  btnGroup,
  btnGroupTxtClose,
  btnGroupTxtConfirm,
  btnGroupLink,
  btnGroupLinkTxt,
  btnConfirm,
  handleCloseModal,
  handleConfirmClose,
  handleReload,
}) => {
  return (
    <>
      <div className="modal-wrap alert">
        <div className="dimmed" onClick={handleCloseModal}></div>

        <div className="alert-modal-content">
          {btnClose && (
            <button type="button" className="btn-modal-close" onClick={handleCloseModal}>
              <i className="blind">닫기</i>
            </button>
          )}
          <div className="modal-table-form">
            <div className="table-form-inner">
              {imgURL && <img src={imgURL} alt="" />}
              {titleMsg && <strong>{titleMsg}</strong>}
              {msg && <p>{msg}</p>}
              {subTitleMsg && <strong className="sub-title">{subTitleMsg}</strong>}
              {customTag && customTag}
            </div>
          </div>

          {btnGroup && (
            <div className="btn-flex-form">
              <button className="btn" onClick={handleCloseModal}>
                <span>{btnGroupTxtClose ? btnGroupTxtClose : '취소'}</span>
              </button>
              <button className="btn" onClick={(e) => handleConfirmClose(true)}>
                <span>{btnGroupTxtConfirm ? btnGroupTxtConfirm : '확인'}</span>
              </button>
            </div>
          )}

          {btnGroupLink && (
            <div className="btn-flex-form">
              <button className="btn" onClick={handleCloseModal}>
                <span>닫기</span>
              </button>
              <NavLink className="btn" to={'/mypage/1'}>
                <span>{btnGroupLinkTxt}</span>
              </NavLink>
            </div>
          )}

          {btnConfirm && (
            <div className="btn-flex-form">
              <button className="btn" onClick={handleCloseModal}>
                <span>확인</span>
              </button>
            </div>
          )}

          {handleReload && (
            <div className="btn-flex-form">
              <button className="btn" onClick={handleReload}>
                <span>확인</span>
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AlertModal;
