import { useState, useEffect, useCallback, useRef } from 'react'
import { useMediaQuery } from 'react-responsive'
import { NavLink, useNavigate, useLocation } from 'react-router-dom'

import classNames from 'classnames'
import ModalPortal from 'ModalPortal'
import AuthService from 'services/authService'

import 'assets/styles/headerLogin.scss'
import api from 'common/api'
import * as config from 'common/config'
import AlertModal from 'components/modal/AlertModal'
import useModalLogin from 'store/useModalLogin'

import styles from '../../styles/SideMenu.module.scss'

export default function HeaderLoginButton() {
  const navigate = useNavigate()
  const currentPath = useLocation().pathname
  const isDesktop = useMediaQuery({ minWidth: 1025 })
  const [userInfo, setUserInfo] = useState(null)
  const [modalopenAlert, setModalopenAlert] = useState(false)
  const [mouseState, setMouseState] = useState(false)
  const [isLogin, setIsLogin] = useState(AuthService.isLoggedIn())
  const isMounted = useRef(true)
  const { setIsModalLogin } = useModalLogin()

  const userProfileBackgroundImage = {
    backgroundImage: `url(${
      userInfo ? userInfo.profileImageUrl : `${config.YANADOO_CDN_HOST}/common/header/icon-user.png`
    })`
  }

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  const getUserInfo = useCallback(() => {
    api
      .get(config.AUTH_API_HOST + '/auth/v2/user')
      .then((response) => {
        if (isMounted.current) {
          setUserInfo(response.data.data)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }, [])

  const handleOpenModalLogin = () => {
    document.body.classList.add('modal-open')
    setIsModalLogin(true)
  }

  const handleOpenModalAlert = useCallback((e) => {
    document.body.classList.add('modal-open')
    setModalopenAlert(true)
  }, [])

  const handleCloseAlertModal = useCallback((e) => {
    document.body.classList.remove('modal-open')
    setModalopenAlert(false)
  }, [])

  const handleGoMypage = () => {
    if (!AuthService.getUserInfo()) {
      handleOpenModalLogin()

      return false
    }

    navigate('/mypage/1')
  }

  const handleSignOut = () => {
    handleOpenModalAlert()
  }

  const handleConfirmClose = (status) => {
    setModalopenAlert(!status)
    // 모달 확인 버튼 누를 시 로그아웃
    AuthService.signOut(() => (window.location.href = currentPath))
  }

  const updateIsLogin = useCallback(async () => {
    const loggedIn = await AuthService.isLoggedIn()
    setIsLogin(loggedIn)
  }, [])

  useEffect(() => {
    AuthService.addObserver(updateIsLogin)
    return () => {
      AuthService.removeObserver(updateIsLogin)
    }
  }, [updateIsLogin]) // 의존성 배열에 updateIsLogin를 포함시킵니다.

  useEffect(() => {
    if (!!isLogin) {
      getUserInfo()
    } else {
      setUserInfo(null)
    }
  }, [getUserInfo, isLogin])

  return (
    <>
      {isDesktop ? (
        <>
          {isLogin ? (
            <div
              className={classNames(styles.mypageButton, mouseState && styles.active)}
              onMouseOver={() => setMouseState(true)}
              onMouseLeave={() => setMouseState(false)}
            >
              <button
                type="button"
                className={styles.buttonMyPage}
                style={userProfileBackgroundImage}
              ></button>

              <ul className={styles.buttonList}>
                <li>
                  <NavLink className={styles.button} to={'/mypage/1'}>
                    마이페이지
                  </NavLink>
                </li>
                <li>
                  <NavLink className={styles.button} to={'/member/auth'}>
                    회원정보수정
                  </NavLink>
                </li>
                <li>
                  <button type="button" className={styles.button} onClick={handleSignOut}>
                    로그아웃
                  </button>
                </li>
              </ul>
            </div>
          ) : (
            <button type="button" className={styles.loginButton} onClick={handleOpenModalLogin}>
              <span>로그인</span>
            </button>
          )}
        </>
      ) : (
        <button
          className={styles.mypageButtonMobile}
          type="button"
          style={userProfileBackgroundImage}
          onClick={handleGoMypage}
        >
          <i className="blind">mypage</i>
        </button>
      )}

      {modalopenAlert && (
        <ModalPortal>
          <AlertModal
            btnGroup
            msg={'로그아웃을 하시겠습니까?'}
            handleCloseModal={handleCloseAlertModal}
            handleConfirmClose={handleConfirmClose}
          />
        </ModalPortal>
      )}
    </>
  )
}
