import React, { useCallback, useEffect } from 'react'
import { useLocation, useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'

import api from 'common/api'

const Performance = () => {
  const { performanceSeq } = useParams()
  const { search:queryString } = useLocation()
  const navigate = useNavigate()
  const getAbsoluteLinkData = useCallback(() => {
    api
      .get(`/v2/ad-absolute-link/${performanceSeq}`)
      .then((response) => {
        if (response.data.meta.code === 200) {
          window.location.href = response.data.data + queryString;
        } else {
          navigate('/');
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, [performanceSeq, navigate, queryString])
  
  useEffect(() => {
    getAbsoluteLinkData()
  }, [getAbsoluteLinkData])

  return (
    <></>
  )
}

export default Performance