import React from 'react'
import styled from 'styled-components'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const SkeletonReview = ({ deviceCheck }) => {
  return (
    <>
      <SkeletonBox>
        <div>
          <div className="left-box">
            <Skeleton circle={true} width={deviceCheck ? '8.2rem' : '5.4rem'} height={deviceCheck ? '8.2rem' : '5.4rem'} inline={true} />
            <div>
              <Skeleton width={'8rem'} />
              <p>
                <Skeleton width={'8rem'} />
              </p>
            </div>
          </div>
          <div className="right-box">
            <Skeleton width={'8rem'} />
            {!deviceCheck && (
              <p>
                <Skeleton width={'8rem'} />
              </p>
            )}
          </div>
        </div>
        {deviceCheck && (
          <p>
            <Skeleton />
          </p>
        )}
        <p>
          <Skeleton />
        </p>
      </SkeletonBox>
    </>
  )
}

export default SkeletonReview

const SkeletonBox = styled.div`
  position: relative;
  margin-bottom: 1.5rem;
  padding: 3.5rem;
  background-color: #fff;
  border-radius: 2rem;
  box-shadow: 0 0 2rem rgb(0 0 0 / 10%);
  overflow: hidden;

  .react-loading-skeleton {
    height: 1.4rem;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left-box {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      div {
        margin-left: 2rem;
      }
    }

    & + p {
      margin-top: 1.5rem;
    }
  }

  p {
    margin-top: 0.5rem;
  }

  @media ${({ theme }) => theme.device.mobile} {
    padding: 4rem;

    .react-loading-skeleton {
      height: 2.8rem;
    }

    > div {
      align-items: flex-start;

      & + p {
        margin-top: 3rem;
      }
    }
  }
`
