import { useEffect, useState, useMemo, useCallback } from 'react'

import api from 'common/api'

export default function useGetFaqFilter(keyword) {
  const [allList, setAllList] = useState([])
  const [faqList, setFaqList] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  // YANADOO_FAQ_GROUP 및 YAFIT_FAQ_GROUP 호출 후 합침
  const fetchFaqList = useCallback(async () => {
    setLoading(true)
    setError(null)
    try {
      const yanadooResponse = await api.get(
        `/v2/faq/list?faqGroup=ALL&parentFaqGroup=YANADOO_FAQ_GROUP&size=999`
      )
      const yafitResponse = await api.get(
        `/v2/faq/list?faqGroup=ALL&parentFaqGroup=YAFIT_FAQ_GROUP&size=999`
      )
      const yanadooData = yanadooResponse.data.data.content
      // console.log('🚀  yanadooData:', yanadooData)
      const yafitData = yafitResponse.data.data.content
      // console.log('🚀  yafitData:', yafitData)
      setAllList([...yanadooData, ...yafitData])
    } catch (e) {
      console.error('fetch faq list error:', e)
      setError(e)
    } finally {
      setLoading(false)
    }
  }, [])

  const listFilter = useMemo(() => {
    if (keyword.trim() !== '') {
      return allList.filter(
        (item) =>
          item.title.toLowerCase().includes(keyword.toLowerCase()) || item.aiKeyword === keyword
      )
    }
    return allList
  }, [keyword, allList])

  useEffect(() => {
    fetchFaqList()
  }, [fetchFaqList])

  useEffect(() => {
    setFaqList(listFilter)
  }, [listFilter])

  return { faqList, loading, error }
}
