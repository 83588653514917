import React from 'react';
import '../../assets/styles/notice.scss';

export default function DetailPolicyJoin () {
	return (
		<>
			<div className="refund-col">
				<div className="inner-col">
					<div className="container mypage-sub">
						<section className="list-detail-col">
							<div className="title-col">
								<strong>개인정보 수집 / 이용 동의 약관</strong>
							</div>
							<div className="detail-title-area">
								<div className="tit-flex-form">
									<strong className="sub-tit black" style={{marginTop:'0'}}>개인정보 수집 / 이용 동의 약관</strong>
									<div className="info">
										<span className="date"/>
									</div>
								</div>
							</div>
                            <div className="policy-content">
                                <p>야나두는 귀하의 이벤트 및 문의에 대한 처리 및 회신을 위하여 귀하의 개인정보를 수집 및 처리하고자 합니다. <br />내용을 자세히 읽으신 후 동의 여부를 결정하여 주시기 바랍니다.</p>
                                <table>
                                    <colgroup>
                                        <col width="40%" />
                                        <col width="30%" />
                                        <col width="30%" />
                                    </colgroup>
                                    <tbody>
                                        <tr>
                                            <th>이용목적</th>
                                            <th>수집항목</th>
                                            <th>보유기간</th>
                                        </tr>
                                        <tr>
                                            <td>보이스케어 서비스 이용 홈페이지 게시, 보이스케어 고객 케어 후 상품 지급 및 우수 수강후기 및 음성파일 홈페이지 노출</td>
                                            <td>회원 아이디, 휴대전화 번호, 보이스케어 음성 파일</td>
                                            <td>1년 보관 후 파기</td>
                                        </tr>
                                        
                                    </tbody>
                                </table>
                                <p>개인정보 이용에 대하여 동의를 거부할 권리가 있으며, <br /> 개인정보 수집, 이용에 대한 미동의 시 보이스케어 서비스 이용에 제한을 받을 수 있습니다.</p>
                            </div>
						</section>
					</div>
				</div>
			</div>
		</>
	);
};
