import { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'

import useGetAiMessage from 'hooks/useGetFeeds'
import ModalEnd from 'pages/aiTalk/components/ModalEnd'
import ModalRecommend from 'pages/aiTalk/components/ModalRecommend'
import ModalTalkShare from 'pages/aiTalk/components/ModalTalkShare'
import useScrollStore from 'pages/aiTalk/store/useScrollStore'
import s from 'pages/chatBot/ChatBot.module.scss'
import AiTalkTitle from 'webview/service/components/AiTalkTitle'

import ChatContainer from 'components/chat/ChatContainer'
import ChatFooter from 'components/chat/ChatFooter'
import ChatFooterAudio from 'components/chat/ChatFooterAudio'
import ChatMode from 'components/chat/ChatMode'
import CoachMask from 'components/chat/CoachMask'
import ModalTransAudio from 'components/chat/ModalTransAudio'
import MetaInfo from 'components/common/MetaInfo'

export default function AppAInativespeakerChat() {
  const navigate = useNavigate()
  const location = useLocation()
  const messageBoxRef = useRef()
  const modeTimerRef = useRef(null)
  const [searchParams] = useSearchParams()
  const userSeq = searchParams.get('seq')
  const { scroll, setScroll } = useScrollStore()
  const [mode, setMode] = useState('chat')
  const [mute, setMute] = useState(false)
  const [toastType, setToastType] = useState('')
  const [coachMask, setCoachMask] = useState(false)
  const { feed, isLoading, addedFeed, postMessage } = useGetAiMessage({
    character: location.state !== null ? location.state.promptType : null,
    mode,
    mute
  })

  const [endModal, setEndModal] = useState(false)
  const [rcmModal, setRcmModal] = useState(false)
  const [shareModal, setShareModal] = useState(false)
  const [audioModal, setAudioModal] = useState(false)

  const getOSVersion = () => {
    const userAgent = typeof window !== 'undefined' ? window.navigator.userAgent : null

    if (!userAgent) return 'Unknown OS Version'

    let osVersion = undefined

    // iOS
    if (/iPhone OS \d+_\d+/i.test(userAgent)) {
      osVersion = userAgent
        .match(/iPhone OS \d+_\d+/i)[0]
        .replace(/_/g, '.') // 소수점으로 변경
        .replace('iPhone OS ', '') // "iPhone OS " 제거
      osVersion = parseInt(osVersion, 10) // 정수로 변환, 소수점 아래 버림
    }

    return osVersion
  }

  const iosVersion = getOSVersion()
  console.log('🚀  iosVersion:', iosVersion)

  const scrollToBottom = () => {
    if (messageBoxRef.current) {
      messageBoxRef.current.scrollTop = messageBoxRef.current.scrollHeight
    }
    setScroll(false)
  }

  const handleCloseEndModal = () => {
    setEndModal(false)
  }
  const handleOpenEndModal = () => {
    setEndModal(true)
  }
  const handleOpenRcm = () => {
    handleCloseEndModal()
    setRcmModal(true)
  }
  const handleCloseRcm = () => {
    setRcmModal(false)
    navigate(`/webview/ai/intro?seq=${userSeq}`)
  }

  const handleType = (type) => {
    setToastType(type)
  }

  const handleModeChange = (type) => {
    clearTimeout(modeTimerRef.current)

    if (type === 'audio') {
      setAudioModal(true)
      modeTimerRef.current = setTimeout(() => {
        setMode(type)
        handleType(type)
      }, 3000)
    } else {
      setMode(type)
      handleType(type)
    }
  }

  const handleSpeaker = () => {
    setMute(!mute)
    if (mute) {
      handleType('sound_on')
    } else {
      handleType(null)
    }
  }

  const handleCoachMask = () => {
    setCoachMask(false)
  }

  useEffect(() => {
    window['wingBannerDisplay']()
  }, [])

  useEffect(() => {
    let setTimeMessage
    if (userSeq) {
      setTimeMessage = setTimeout(() => {
        postMessage({ userSeq: userSeq, message: 'hi' })
      }, 100)
    }

    return () => clearTimeout(setTimeMessage)
  }, [userSeq])

  useEffect(() => {
    scrollToBottom()
  }, [feed, scroll])

  return (
    <>
      <MetaInfo
        props={{
          title: '나만의 AI 원어민 친구! AI 원어민톡',
          description: '언제 어디서나 편하게 찐친과 톡하듯이!',
          site_name: '나와 관심사가 딱 맞는 AI 친구와 대화하기',
          imgsrc: 'https://english.yanadoocdn.com/upload/yanadoo/new/aitalk/img_og_aiTalk_v2.png'
        }}
      />
      {/* {!rcmModal && <TitleBar title={'AI 원어민 톡'} onBtnClick={handleOpenEndModal} />} */}
      <article className={s.aiChat}>
        {(iosVersion > 14 || iosVersion === undefined) && (
          <ChatMode handleModeChange={handleModeChange} toastType={toastType} mode={mode} />
        )}
        <AiTalkTitle
          handleEndedModal={handleOpenEndModal}
          handleSpeaker={handleSpeaker}
          mode={mode}
          mute={mute}
        />
        <ChatContainer
          messageBoxRef={messageBoxRef}
          location={location}
          feed={feed}
          isLoading={isLoading}
          userSeq={userSeq}
          mode={mode}
          isSupported={iosVersion > 14 || iosVersion === undefined}
        />
        {mode === 'chat' ? (
          <ChatFooter addedFeed={addedFeed} isLoading={isLoading} />
        ) : (
          <ChatFooterAudio
            addToFeed={addedFeed}
            postPromptMessage={postMessage}
            isLoading={isLoading}
            character={location.state.info}
          />
        )}
        {coachMask && <CoachMask handleCoachMask={handleCoachMask} />}
      </article>
      {endModal && (
        <ModalEnd
          close={location.state && !location.state.payUser ? handleOpenRcm : handleCloseRcm}
          confirm={handleCloseEndModal}
        />
      )}
      {rcmModal && <ModalRecommend close={handleCloseRcm} />}
      {shareModal && <ModalTalkShare close={() => setShareModal(false)} />}
      {audioModal && (
        <ModalTransAudio
          character={location.state.info}
          handleModal={setAudioModal}
          handleMode={handleModeChange}
        />
      )}
    </>
  )
}
