import styled from 'styled-components'

export default function SectionTitle({ title, desc }) {
  return (
    <TitleCol>
      <h3>{title}</h3>
      <p>{desc}</p>
    </TitleCol>
  )
}
const TitleCol = styled.div`
  text-align: center;

  h3 {
    margin-bottom: 2.4rem;
    font-size: 4.8rem;
    line-height: 1.3;
    white-space: pre-line;
  }

  p {
    color: #717188;
    font-size: 2.4rem;
    white-space: pre-line;

    @media ${({ theme }) => theme.device.mobile} {
      font-size: 2.8rem;
    }
  }
`
