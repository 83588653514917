import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link, useParams, useNavigate } from 'react-router-dom';

import NoContent from '../../components/NoContent';
import Pagination from '../../components/Pagination';

import '../../assets/styles/notice.scss';
import api from 'common/api';
import MobileHeader from 'components/common/MobileHeader';

export default function Official(props) {
  const navigate = useNavigate();
  // const currentSeq = props.match.params.id;

  const { currentSeq } = useParams();

  const [officialData, setOfficialData] = useState([]);
  const [totalPages, setTotalPages] = useState();

  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1024 });
    return isDesktop ? children : null;
  };
  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 1024 });
    return isMobile ? children : null;
  };

  const getOfficialData = () => {
    api
      .get('/v2/ir-notice/list?index=' + currentSeq + '&size=10')
      .then((response) => {
        setOfficialData(response.data.data.content);
        setTotalPages(response.data.data.totalPages);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const onChangeDash = (date) => {
    return date.replace(/-/g, '.');
  };

  useEffect(() => {
    getOfficialData();
  }, [currentSeq]);

  return (
    <>
      <div className="notice-col official">
        <div className="inner-col">
          <div className="container mypage-sub">
            <MobileHeader title="공고사항"  onClick={() => (document.referrer == '' ? navigate('/') : navigate(-1))}/>
            <section className="notice-table-col">
              <h3>공고사항</h3>
              <Desktop>
                <table className="table-form">
                  <colgroup>
                    <col width="" />
                    <col width="20%" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>제목</th>
                      <th>등록일</th>
                    </tr>
                  </thead>
                  <tbody>
                    {officialData.length > 0 ? (
                      officialData.map((officialItem, index) => {
                        return (
                          <tr key={index}>
                            <td className="tal">
                              <Link
                                to={{
                                  pathname: `/service/official/detail/${officialItem.serviceNoticeSeq}`,
                                }}
                              >
                                <strong
                                  className={
                                    'ellipsis' + (officialItem.fixYn === 'Y' ? ' ico-new' : '')
                                  }
                                >
                                  {officialItem.title}
                                </strong>
                              </Link>
                            </td>
                            <td>
                              <p>{onChangeDash(officialItem.registDate.substring(0, 10))}</p>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="3">
                          <NoContent titleMsg={'등록된 문의가 없습니다.'} />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </Desktop>
              <Mobile>
                <div className="table-form-mobile">
                  {officialData.length > 0 ? (
                    <ul className="table-lists">
                      {officialData.map((officialItem, index) => {
                        return (
                          <li key={index}>
                            <Link
                              to={{
                                pathname: `/service/official/detail/${officialItem.serviceNoticeSeq}`,
                              }}
                              className={'ico-arrow'}
                            >
                              <strong
                                className={
                                  'ellipsis' + (officialItem.fixYn === 'Y' ? ' ico-new' : '')
                                }
                              >
                                {officialItem.title}
                              </strong>
                              <div className="info">
                                <span className="date">
                                  {officialItem.registDate.substring(0, 10)}
                                </span>
                              </div>
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  ) : (
                    <NoContent titleMsg={'등록된 문의가 없습니다.'} />
                  )}
                </div>
              </Mobile>
              {officialData.length > 0 ? (
                <Pagination
                  totalPages={totalPages}
                  type={'SEQ'}
                  limitPage={10}
                  maxNum={10}
                  minNum={0}
                  pathName={'/service/official'}
                  currentSeq={currentSeq}
                />
              ) : null}
            </section>
          </div>
        </div>
      </div>
    </>
  );
}
