import { create } from 'zustand'

// header의 height 값 전역 상태 관리
const useHeaderHeightStore = create((set) => ({
  headerHeight: 0,
  headerBannerHeight: 0,
  isShowBanner: false,
  isHideHeader: false,
  setHeaderHeight: (height) => set({ headerHeight: height }),
  setHeaderBannerHeight: (height) => set({ headerBannerHeight: height }),
  setIsShowBanner: (isShow) => set({ isShowBanner: isShow }),
  setIsHideHeader: (isShow) => set({ isHideHeader: isShow })
}))

export default useHeaderHeightStore
