import { useEffect, useState } from 'react'

import api from 'common/api'

export default function useGetTerms(brand, item) {
  const [terms, setTerms] = useState(null)
  /**
   * @param {string} brandSeq 브랜드 구분 코드 (YANADOO: 야나두, YAFIT_RIDER: 야핏 라이더, YAFIT_CYCLE: 야핏 사이클)
   * @param {string} itemSeq 약관 구분 코드 (SERVICE: 이용약관, PRIVACY_POLICY: 개인정보처리방침, LOCATION_SERVICE: 위치기반 서비스 이용약관, OPERATION_POLICY: 운영 정책, REFUND: 환불 규정)
   * @returns {object}
   */
  const getTerms = async (brandSeq, itemSeq) => {
    await api
      .get(`/v2/terms?brandCode=${brandSeq.toUpperCase()}&termsType=${itemSeq.toUpperCase()}`)
      .then((response) => {
        setTerms(response.data.data)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  useEffect(() => {
    if (brand && item) {
      getTerms(brand, item)
    }
  }, [brand, item])

  return terms
}
