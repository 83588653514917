import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';

import { useMediaQuery } from 'react-responsive';
import { ThemeProvider } from 'styled-components';
import theme from 'common/theme';

import ModalPortal from '../ModalPortal';
import ModalReviewDetail from '../components/modal/ModalReviewDetail';

export default function ImgView({ data, isLoading, type }) {
  const deviceCheck = useMediaQuery({ maxWidth: 1024 }) ? true : false;
  const [modalopen, setModalopen] = useState(false);
  const [imageData, setImageData] = useState([]);
  const [boardImageData, setBoardImageData] = useState([]);

  const [images, setImages] = useState(null);

  const handleOpenModal = useCallback((images) => {
    document.body.classList.add('modal-open');
    setImages(images);
    setModalopen(!modalopen);
  }, []);

  const handleCloseModal = useCallback(() => {
    document.body.classList.remove('modal-open');
    setModalopen(false);
  }, []);

  useEffect(() => {
    if (isLoading && data) {
      setImageData(data.attachments ? data.attachments : data.scholarshipRefundHistoryImageList);
    }
  }, [isLoading, data]);

  return (
    <>
      <ThemeProvider theme={theme}>
        {imageData && imageData.length > 0 && (
          <ImgBox className={`item-${imageData.length > 5 ? 5 : imageData.length}`}>
            {imageData.map((item, idx) => {
              return (
                idx < 5 && (
                  <div
                    key={idx}
                    style={{
                      backgroundImage: `url(${
                        item.attachmentPath
                          ? item.attachmentPath
                          : item.imageUrl
                          ? item.imageUrl
                          : "'" + item.url + "'"
                      })`,
                    }}
                    onClick={() => {
                      handleOpenModal(imageData);
                    }}
                  ></div>
                )
              );
            })}
          </ImgBox>
        )}
        {modalopen ? (
          <ModalPortal>
            <ModalReviewDetail
              deviceCheck={deviceCheck}
              handleCloseModal={handleCloseModal}
              images={images}
            />
          </ModalPortal>
        ) : null}
      </ThemeProvider>
    </>
  );
}
const ImgBox = styled.div`
  display: flex;
  width: 100%;
  height: 80.6rem;
  margin: 0 auto;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-around;
  align-content: space-around;
  box-shadow: 0 0 2rem rgba(0, 0, 0, 0);
  overflow: hidden;

  div {
    background-size: cover;
    background-position: 50% 50%;
  }

  &.item-1 div {
    width: inherit;
    height: inherit;
  }

  &.item-2 div {
    display: inline-block;
    width: 40.1rem;
    height: inherit;

    &:first-child {
      margin-right: 0.4rem;
    }
  }

  &.item-3 div {
    display: inline-block;
    width: 40.1rem;
    height: 40.1rem;

    &:first-child {
      height: 80.6rem;
      margin-right: 0.4rem;
    }

    &:nth-child(2) {
      margin-bottom: 0.4rem;
    }
  }

  &.item-4 div {
    display: inline-block;
    width: 26.6rem;
    height: 26.6rem;

    &:first-child {
      width: 53.5rem;
      height: 80.6rem;
      margin-right: 0.5rem;
    }

    &:nth-child(3) {
      margin: 0.5rem 0;
    }
  }

  &.item-5 div {
    display: inline-block;
    width: 26.6rem;
    height: 26.6rem;

    &:first-child {
      margin-bottom: 0.4rem;
    }

    &:first-child,
    &:nth-child(2) {
      width: 53.5rem;
      height: 40.1rem;
      margin-right: 0.5rem;
    }

    &:nth-child(4) {
      margin: 0.5rem 0;
    }
  }

  &.item-4 div,
  &.item-5 div {
    &:last-child {
      height: 26.4rem;
    }
  }

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    height: 66.6rem;

    &.item-2 div {
      display: inline-block;
      width: 50%;
      height: inherit;

      &:first-child {
        margin-right: 0.4rem;
      }
    }

    &.item-3 div {
      display: inline-block;
      width: 50%;
      height: 33.1rem;

      &:first-child {
        height: 66.6rem;
        margin-right: 0.4rem;
      }

      &:nth-child(2) {
        margin-bottom: 0.4rem;
      }
    }

    &.item-4 div {
      display: inline-block;
      width: 40%;
      height: 22rem;

      &:first-child {
        width: 60%;
        height: 66.6rem;
        margin-right: 0.4rem;
      }

      &:nth-child(3) {
        margin: 0.4rem 0;
      }
    }

    &.item-5 div {
      display: inline-block;
      width: 40%;
      height: 22rem;

      &:first-child {
        margin-bottom: 0.4rem;
      }

      &:first-child,
      &:nth-child(2) {
        width: 60%;
        height: 33.1rem;
        margin-right: 0.5rem;
      }

      &:nth-child(4) {
        margin: 0.4rem 0;
      }
    }

    &.item-4 div,
    &.item-5 div {
      &:last-child {
        height: 21.8rem;
      }
    }
  }
`;
