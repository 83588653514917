import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import api from 'common/api'
import * as config from 'common/config'

import LearnInquiryBtn from './components/LearnInquiryBtn'

export default function TabClassListENG({ myClassData, lectureData, activeClassData, studySeq }) {
  const navigate = useNavigate()
  const [activeClassName, setActiveClassName] = useState('')

  const handleOpenPlayer = (courseSeq, subjectSeq) => {
    window.open(
      `/promotion-landing/player.html?studyGroupSeq=${studySeq}&courseSeq=${courseSeq}&subjectSeq=${subjectSeq}&apiHost=${encodeURIComponent(
        config.API_HOST
      )}`,
      'yndWebPlasyer',
      'width=1048,height=562, scrollbars=no'
    )
  }

  const formatProgressRate = (complete, total) => {
    var progressRate = 0
    if (complete !== 0) {
      progressRate = Math.trunc((100 * complete) / total)
    }

    return progressRate
  }

  const handleClassCheck = (e) => {
    const targetSeq = e.target.value
    const targetName = e.target.name

    setActiveClassName(targetName)
    navigate('/myclass/detail/eng/' + targetSeq)
  }

  const [isAiNadoo, setIsAiNadoo] = useState(null)
  const fetchAiNadoo = useCallback(async () => {
    try {
      const res = await api.get(
        `${config.API_HOST}/v2/class-study-support/study-group-user-seq?studyGroupUserSeq=${studySeq}`
      )
      setIsAiNadoo(res?.data?.data?.aiNadoo)
    } catch {
      setIsAiNadoo(false)
    }
  }, [studySeq])

  useEffect(() => {
    fetchAiNadoo()
    setActiveClassName(activeClassData.studyGroupSeq)
  }, [activeClassData, fetchAiNadoo])

  return (
    <>
      <div className="btn-wrap">
        {isAiNadoo && <LearnInquiryBtn />}
        <div className="search-box">
          <select
            id="class-select"
            name="lectureTitle"
            value={activeClassName}
            onChange={handleClassCheck}
          >
            {myClassData &&
              myClassData.map((list) => (
                <option key={list.studyGroupSeq} value={list.studyGroupSeq}>
                  {list.className}
                </option>
              ))}
          </select>
        </div>
      </div>

      {lectureData &&
        lectureData.map((list) => (
          <dl key={list.courseSortOrder} className="class-ynd2">
            <dt>
              <p>{list.courseName}</p>
            </dt>
            <dd>
              <ul className="list-area">
                {list.subjects &&
                  list.subjects.map((subList, idx) => (
                    <li key={idx}>
                      <div>
                        <p className="subjects-title ellipsis">
                          <strong>{subList.subjectLevelName}</strong> {subList.subjectTitle}
                        </p>
                        {subList.subjectTypeName === 'VOD' ? (
                          <button
                            type="button"
                            className="btn-play video"
                            onClick={(e) => handleOpenPlayer(list.courseSeq, subList.subjectSeq)}
                          >
                            <i className="blind">강의재생</i>
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn-play audio"
                            onClick={(e) => handleOpenPlayer(list.courseSeq, subList.subjectSeq)}
                          >
                            <i className="blind">오디오재생</i>
                          </button>
                        )}
                      </div>

                      <p className="subjects-info">
                        <span>{subList.teacherName} 선생님</span>
                        <span>
                          {subList.subjectTypeName}강의, 총{subList.lecturesTotalCount}강
                        </span>
                      </p>
                      <p className="progress-rate">
                        진도율{' '}
                        <span>
                          {formatProgressRate(
                            subList.lecturesCompleteCount,
                            subList.lecturesTotalCount
                          )}
                        </span>
                      </p>
                    </li>
                  ))}
              </ul>
            </dd>
          </dl>
        ))}
    </>
  )
}
