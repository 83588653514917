import React from 'react'

import s from 'pages/chatBot/ChatBot.module.scss'

import ChatInput from './ChatInput'

export default function ChatFooter({ addedFeed, isLoading }) {
  return (
    <section className={s.chatFooter}>
      <div className={s.inner}>
        <ChatInput handleMessages={addedFeed} isLoading={isLoading} />
      </div>
    </section>
  )
}
