import React, { useState, useEffect, useRef, useCallback } from 'react';
import ModalPortal from '../../ModalPortal';
import AlertModal from './AlertModal';
import Password from '../Password';
import DateBirth from '../DateBirth';
import CertificationType from 'components/CertificationType';
import Toast from './Toast';
import api from 'common/api';
import * as config from 'common/config';
import '../../../src/assets/styles/modal.scss';
import '../../../src/assets/styles/modalJoin.scss';
import '../../../src/assets/styles/modalFind.scss';

export default function ModalFindPwd({ title, btnClose, handleCloseModal }) {
  const idRef = useRef();
  const nameRef = useRef();

  const [validStatus, setValidStatus] = useState({
    id: false,
    name: false,
    phone: false,
    email: false,
    birthYear: false,
    birthMonth: false,
    birthDay: false,
  });

  const [inputValue, setInputValue] = useState({
    id: '',
    name: '',
    phone: '',
    countryCode: '82',
    email: '',
    birthYear: '',
    birthMonth: '',
    birthDay: '',
    findType: 'CELLPHONE',
  });

  const [userSeq, setUserSeq] = useState(false);
  const [openToast, setOpenToast] = useState(false);
  const [step1Complete, setStep1Complete] = useState(false);
  const [step2Complete, setStep2Complete] = useState(false);
  const [newPassword, setNewPassword] = useState(null);
  const [pwdComplete, setPwdComplete] = useState(false);
  const [certifiedComplete, setCertifiedComplete] = useState(false);
  const [modalopenAlert, setModalopenAlert] = useState(false);
  const [stepStatus, setStepStatus] = useState(1);

  const handleToastStatus = useCallback((status) => {
    setOpenToast(status);
  }, []);

  const defaultCertUrl =
    config.AUTH_API_HOST +
    '/auth/v2/sign-in/find-password/check-cert-number?userSeq=' +
    userSeq;

  const defaultTypeUrl =
    '?birthday=' +
    inputValue.birthYear +
    inputValue.birthMonth +
    inputValue.birthDay +
    '&userName=' +
    inputValue.name +
    '&userId=' +
    inputValue.id;

  const handleNewPwdComplete = (status, newPwd) => {
    setPwdComplete(status);
    setNewPassword(newPwd);
  };

  // 아이디 체크
  const handleIdCheck = (e) => {
    let idRegExp = /^[A-Za-z0-9+]*$/;

    if (!idRegExp.test(e.target.value)) {
      idRef.current.focus();

      return false;
    }

    if (!e.target.value) {
      setInputValue({ ...inputValue, id: '' });
      setValidStatus({ ...validStatus, id: false });

      return false;
    }

    setInputValue({ ...inputValue, id: e.target.value });
    setValidStatus({ ...validStatus, id: true });

    return true;
  };

  const handleNameCheck = (e) => {
    let nameRegExp = /^[ㄱ-ㅎ가-힣a-zA-Z|]*$/;

    if (!nameRegExp.test(e.target.value)) {
      nameRef.current.focus();

      return false;
    }

    if (!e.target.value) {
      setInputValue({ ...inputValue, name: '' });
      setValidStatus({ ...validStatus, name: false });

      return false;
    }

    setInputValue({ ...inputValue, name: e.target.value });
    setValidStatus({ ...validStatus, name: true });

    return true;
  };

  const handleCertificationValue = (checkType, typeValue, countryCodeValue) => {
    const currentType =
      checkType === 'CELLPHONE' ? 'phone' : 'EMAIL' ? 'email' : null;

    if (checkType) {
      if (countryCodeValue) {
        setInputValue({
          ...inputValue,
          [currentType]: typeValue,
          countryCode: countryCodeValue,
          findType: checkType,
        });
      } else {
        setInputValue({
          ...inputValue,
          [currentType]: typeValue,
          findType: checkType,
        });
      }
      setValidStatus({ ...validStatus, [currentType]: true });

      setCertifiedComplete(true);
    } else {
      setCertifiedComplete(false);
    }
  };

  const checkUserSeq = (response) => {
    setUserSeq(response.data.data.userSeq);
  };

  const handleBirthCheck = (e) => {
    let { name, value } = e.target;

    setInputValue({ ...inputValue, [name]: value });

    if (value === 'default') {
      setValidStatus({ ...validStatus, [name]: false });
      return false;
    }

    setValidStatus({ ...validStatus, [name]: true });
  };

  const handleOpenModalAlert = useCallback((e) => {
    document.body.classList.add('modal-open');
    setModalopenAlert(true);
  }, []);

  const handleCloseModalAlert = useCallback((e) => {
    document.body.classList.remove('modal-open');
    setModalopenAlert(false);
    window.location.reload();
  }, []);

  const postEditPassword = () => {
    let formData = new FormData();

    formData.append('userSeq', userSeq);
    formData.append('newPassword', newPassword);

    api
      .post(
        config.AUTH_API_HOST + '/auth/v2/sign-in/find-password/change-password',
        formData
      )
      .then((response) => {
        if (response.data.meta.code !== 200) {
          alert(response.data.meta.message);
        } else {
          handleOpenModalAlert();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (
      stepStatus === 1 &&
      validStatus.id &&
      validStatus.name &&
      validStatus.birthYear &&
      validStatus.birthMonth &&
      validStatus.birthDay &&
      (validStatus.phone || validStatus.email) &&
      certifiedComplete
    ) {
      setStep1Complete(true);
    } else {
      setStep1Complete(false);
    }
  }, [validStatus, certifiedComplete]);

  useEffect(() => {
    if (stepStatus === 2 && pwdComplete) {
      setStep2Complete(true);
    } else {
      setStep2Complete(false);
    }
  }, [validStatus, pwdComplete]);

  return (
    <>
      <div className="modal-wrap modal-form find">
        <div className="dimmed" onClick={handleCloseModal}></div>
        <div
          className={'modal-inner' + (stepStatus === 1 ? ' step-1' : ' step-2')}
        >
          {btnClose && (
            <button
              type="button"
              className="btn-modal-close"
              onClick={handleCloseModal}
            >
              <i className="blind">닫기</i>
            </button>
          )}
          <div className="modal-header">
            {stepStatus === 1 ? (
              <strong>{title}</strong>
            ) : (
              <strong>새 비밀번호 설정</strong>
            )}
          </div>
          <div className="modal-content">
            <div className="inner">
              <form name="findPwdFrm" action="#" className="frm">
                <div className="form-box">
                  {stepStatus === 1 ? (
                    <>
                      <dl>
                        <dt>아이디</dt>
                        <dd>
                          <input
                            type="text"
                            maxLength="12"
                            placeholder="아이디를 입력해주세요."
                            name="id"
                            ref={idRef}
                            value={inputValue.id}
                            onChange={handleIdCheck}
                          />
                        </dd>
                      </dl>
                      <dl>
                        <dt>이름</dt>
                        <dd>
                          <input
                            type="text"
                            placeholder="이름을 입력해주세요."
                            name="name"
                            ref={nameRef}
                            value={inputValue.name}
                            onChange={handleNameCheck}
                          />
                        </dd>
                      </dl>
                      <DateBirth
                        yearValue={inputValue.birthYear}
                        monthValue={inputValue.birthMonth}
                        dayValue={inputValue.birthDay}
                        handleBirthCheck={handleBirthCheck}
                      />
                      <CertificationType
                        defaultTypeUrl={
                          '/auth/v2/sign-in/find-password/get-cert-number' +
                          defaultTypeUrl
                        }
                        defaultCertUrl={defaultCertUrl}
                        callBackFunc={checkUserSeq}
                        handleToastStatus={handleToastStatus}
                        handleCertificationValue={handleCertificationValue}
                      />
                    </>
                  ) : (
                    <Password handleNewPwdComplete={handleNewPwdComplete} />
                  )}
                </div>
                <div className="btn-flex-form">
                  {stepStatus === 1 ? (
                    <button
                      type="button"
                      className={
                        'btn btn-edit-submit' +
                        (step1Complete ? ' active' : ' disabled')
                      }
                      disabled={step1Complete ? false : true}
                      onClick={() => setStepStatus(2)}
                    >
                      비밀번호 찾기
                    </button>
                  ) : (
                    <button
                      type="button"
                      className={
                        'btn btn-edit-submit' +
                        (step2Complete ? ' active' : ' disabled')
                      }
                      disabled={step2Complete ? false : true}
                      onClick={postEditPassword}
                    >
                      비밀번호 변경
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
          <Toast
            msg={'인증이 완료되었습니다.'}
            openToast={openToast}
            handleToastStatus={handleToastStatus}
          />
        </div>
      </div>
      {modalopenAlert ? (
        <ModalPortal>
          <AlertModal
            btnConfirm
            msg={'비밀번호가 변경되었습니다. 다시 로그인해주세요!'}
            handleCloseModal={handleCloseModalAlert}
          />
        </ModalPortal>
      ) : null}
    </>
  );
}
