import React, { useState, useEffect, useRef, useCallback } from "react";
import DaumPostCode from "react-daum-postcode";
import { Link, useLocation, useNavigate } from "react-router-dom";

import moment from "moment";

import api from "common/api";
import * as config from "common/config";
import MobileHeader from "components/common/MobileHeader";

import DateBirth from "../../components/DateBirth";
import Lnb from "../../components/Lnb";
import AlertModal from "../../components/modal/AlertModal";
import ModalPassword from "../../components/modal/ModalPassword";
import ModalPortal from "../../ModalPortal";


import "../../assets/styles/editinfo.scss";

export default function EditInfo() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const authority = state && state.authority;

  // 수정가능 : 이메일 o, 성별o, 생년월일-셀렉트박스 필요, 주소o, 비밀번호(팝업)o
  const emailRef = useRef();
  const addressRef = useRef();

  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(null);
  const [modalopen, setModalopen] = useState(false);
  const [modalopenAgree, setModalopenAgree] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [isChecked, setIsChecked] = useState({
    smsAgree: false,
    emailAgree: false,
  });
  const [isTargetChecked, setTargetChecked] = useState(false);

  // 유효성 검사 상태 - true(통과) / false(통과X)
  const [validStatus, setValidStatus] = useState({
    email: false,
  });

  // 유효성 검사 문구
  const [validText, setValidText] = useState({
    email: "",
  });

  // input 상태값
  const [inputValue, setInputValue] = useState({
    id: "",
    name: "",
    cellPhone: "",
    email: "",
    gender: "",
    birthYear: "",
    birthMonth: "",
    birthDay: "",
    zoneCode: "",
    defaultAddress: "",
    detailAddress: "",
  });

  const handleOpenModal = useCallback((e) => {
    document.body.classList.add("modal-open");
    setModalopen(true);
  }, []);

  const handleCloseModal = useCallback((e) => {
    document.body.classList.remove("modal-open");
    setModalopen(false);
  }, []);

  const handleOpenModalAgree = useCallback((e) => {
    document.body.classList.add("modal-open");
    setModalopenAgree(true);
  }, []);

  const handleCloseModalAgree = useCallback((e) => {
    document.body.classList.remove("modal-open");
    setModalopenAgree(false);
  }, []);

  const handleCheckedItem = (e) => {
    let { id } = e.target;
    setTargetChecked(e.target.checked);
    setIsChecked({ ...isChecked, [id]: !isChecked[id] });
    handleOpenModalAgree();
  };

  // input 상태값 관리 함수
  const handleInputValue = (e) => {
    const { name, value } = e.target;

    setInputValue({ ...inputValue, [name]: value });
  };

  // 이메일 상태값 관리
  const handleEmailValue = (e) => {
    let { value } = e.target;

    if (value) {
      setInputValue({ ...inputValue, email: value });
    } else {
      setInputValue({ ...inputValue, email: "" });
      setValidStatus({ ...validStatus, email: false });
      setValidText({ ...validText, email: "" });
    }
  };

  // 이메일 유효성 체크
  const handleEmailCheck = (e) => {
    let emailRegExp =
      /^([0-9a-zA-Z_\.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/;

    if (!emailRegExp.test(e.target.value)) {
      emailRef.current.focus();
      setValidText({ ...validText, email: "이메일 주소를 확인해주세요." });
      setValidStatus({ ...validStatus, email: false });

      return false;
    }

    setValidText({ ...validText, email: "" });
    setValidStatus({ ...validStatus, email: true });

    return true;
  };

  // 주소 팝업
  const handlePostCode = (e) => {
    e.preventDefault();
    new window.daum.Postcode({
      onComplete: (data) => {
        let fullAddress = data.address;
        let extraAddress = "";

        if (data.addressType === "R") {
          if (data.bname !== "") {
            extraAddress += data.bname;
          }
          if (data.buildingName !== "") {
            extraAddress +=
              extraAddress !== ""
                ? `, ${data.buildingName}`
                : data.buildingName;
          }
          fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
        }
        setInputValue({
          ...inputValue,
          defaultAddress: fullAddress,
          zoneCode: data.zonecode,
        });
      },
    }).open();
  };

  const getUserData = () => {
    api
      .get(config.AUTH_API_HOST + "/auth/v2/user")
      .then((response) => {
        setUserData(response.data.data);
      })
      .catch((e) => {
        alert(config.MESSAGE["common-error"]);
      });
  };

  const postEditInfo = () => {
    const data = {
      address: {
        address: inputValue.defaultAddress,
        addressDetail: inputValue.detailAddress,
        zipcode: inputValue.zoneCode,
      },
      allowance: {
        email: isChecked.emailAgree ? 1 : 0,
        sms: isChecked.smsAgree ? 1 : 0,
      },
      birthday:
        inputValue.birthYear +
        "-" +
        inputValue.birthMonth +
        "-" +
        inputValue.birthDay,
      email: inputValue.email,
      userSex: inputValue.gender,
    };

    api
      .put(config.AUTH_API_HOST + "/auth/v2/user", data)
      .then((response) => {
        alert("수정이 완료되었습니다.");
        window.location.reload();
      })
      .catch((e) => {
        alert(config.MESSAGE["common-error"]);
      });
  };

  const realTimeFormatKo = (dateItem) => {
    return dateItem;
  };

  useEffect(() => {
    if (!validStatus.email) {
      setIsComplete(false);
    } else {
      setIsComplete(true);
    }
  }, [validStatus.email]);

  useEffect(() => {
    setLoading(true);
    getUserData();
    if (!authority) {
      navigate("/member/auth");
    }
    return () => setLoading(false);
  }, []);

  useEffect(() => {
    if (userData) {
      const birthSplit = userData.birthDate.split(" ");
      const birthFormat = birthSplit[0].split("-");

      setInputValue({
        ...inputValue,
        id: userData.userId,
        name: userData.username,
        cellPhone: userData.cellPhone,
        email: userData.email,
        gender: userData.userSex,
        birthYear: birthFormat[0],
        birthMonth: birthFormat[1],
        birthDay: birthFormat[2],
        zoneCode: userData.address.zipcode ? userData.address.zipcode : "",
        defaultAddress: userData.address.address
          ? userData.address.address
          : "",
        detailAddress: userData.address.addressDetail
          ? userData.address.addressDetail
          : "",
      });

      setIsChecked({
        smsAgree: userData.smsYn === 1 ? true : false,
        emailAgree: userData.emailYn === 1 ? true : false,
      });

      if (userData.email) {
        setValidStatus({ ...validStatus, email: true });
      }
    }

  }, [userData]);

  return (
    <>
      <div className="member-col">
        <div className="inner-col">
          <div className="container mypage-sub">
          <MobileHeader title="회원정보 수정" onClick={() =>
                  document.referrer == ""
                    ? navigate("/member/auth")
                    : navigate(-1)
                }/>
            {loading && <Lnb />}
            <section className="info-edit-box">
              <p className="title">
                <strong>회원 정보 수정</strong>
                <span>필수 입력 항목</span>
              </p>
              <form name="EditFrm" action="#">
                <div className="edit-form">
                  <dl>
                    <dt>아이디</dt>
                    <dd>
                      <input
                        type="text"
                        name="id"
                        value={inputValue.id}
                        readOnly
                      />
                    </dd>
                  </dl>
                  <dl>
                    <dt>이름</dt>
                    <dd>
                      <input
                        type="text"
                        name="name"
                        value={inputValue.name}
                        readOnly
                      />
                    </dd>
                  </dl>
                  <dl>
                    <dt>비밀번호 변경</dt>
                    <dd>
                      <button
                        type="button"
                        className="btn-password"
                        onClick={handleOpenModal}
                      >
                        <span>새 비밀번호 설정</span>
                      </button>
                    </dd>
                  </dl>
                  <dl className="line">
                    <dt>휴대전화</dt>
                    <dd>
                      <input
                        type="text"
                        name="cellPhone"
                        value={inputValue.cellPhone}
                        readOnly
                      />
                      <em className="notice">
                        휴대전화 번호 변경은 고객센터 1600-0563으로 문의주시기
                        바랍니다.
                      </em>
                    </dd>
                  </dl>
                  <dl>
                    <dt>이메일 주소</dt>
                    <dd className="email-box">
                      <div>
                        <input
                          type="text"
                          placeholder="이메일 주소를 입력해주세요."
                          className={
                            validText.email &&
                            (validStatus.email ? "success" : "warning")
                          }
                          name="email"
                          ref={emailRef}
                          value={inputValue.email}
                          onChange={handleEmailValue}
                          onBlur={handleEmailCheck}
                        />
                        {validText.email && (
                          <span
                            className={
                              "valid-text" +
                              (validStatus.email
                                ? " active success"
                                : " active warning")
                            }
                          >
                            {validText.email}
                          </span>
                        )}
                      </div>
                      <div className="agree-flex-form">
                        <p>
                          야나두에서 제공하는 컨텐츠 안내 및 이벤트, 각종 소식에
                          대한 알림을 이메일로 받으시겠습니까?
                        </p>
                        <label
                          htmlFor="smsAgree"
                          className="checkbox-flex-form"
                        >
                          <input
                            type="checkbox"
                            id="smsAgree"
                            checked={isChecked.smsAgree ? true : false}
                            onChange={handleCheckedItem}
                          />
                          <span className="chk"></span>
                          <p>(선택) SMS 광고 및 마케팅 이용 동의</p>
                        </label>
                        <label
                          htmlFor="emailAgree"
                          className="checkbox-flex-form"
                        >
                          <input
                            type="checkbox"
                            id="emailAgree"
                            checked={isChecked.emailAgree ? true : false}
                            onChange={handleCheckedItem}
                          />
                          <span className="chk"></span>
                          <p>(선택) e-mail 광고 및 마케팅 이용 동의</p>
                        </label>
                      </div>
                    </dd>
                  </dl>
                  <dl>
                    <dt>성별</dt>
                    <dd className="gender-box">
                      <label
                        htmlFor="genderWoman"
                        className={inputValue.gender == 2 ? "active" : null}
                      >
                        <input
                          type="radio"
                          id="genderWoman"
                          name="gender"
                          value="2"
                          onChange={handleInputValue}
                          readOnly
                        />
                        여자
                      </label>
                      <label
                        htmlFor="genderMan"
                        className={inputValue.gender == 1 ? "active" : null}
                      >
                        <input
                          type="radio"
                          id="genderMan"
                          name="gender"
                          value="1"
                          onChange={handleInputValue}
                          readOnly
                        />
                        남자
                      </label>
                    </dd>
                  </dl>
                  <DateBirth
                    yearValue={inputValue.birthYear}
                    monthValue={inputValue.birthMonth}
                    dayValue={inputValue.birthDay}
                    handleBirthCheck={handleInputValue}
                  />
                  <dl>
                    <dt>주소</dt>
                    <dd className="address-box">
                      <div className="flex-form">
                        <input
                          type="text"
                          placeholder="우편번호"
                          className={
                            "input-value zone-code ellipsis" +
                            (inputValue.zoneCode ? " active" : "")
                          }
                          name="zoneCode"
                          value={inputValue.zoneCode}
                          readOnly
                        ></input>
                        <button className="btn active" onClick={handlePostCode}>
                          <span>우편번호</span>
                        </button>
                      </div>
                      <input
                        type="text"
                        placeholder="주소를 입력해주세요."
                        className={
                          "input-value" +
                          (inputValue.defaultAddress ? " active" : "")
                        }
                        name="defaultAddress"
                        value={inputValue.defaultAddress}
                        readOnly
                      />
                      <input
                        type="text"
                        placeholder="상세 주소를 입력해주세요."
                        name="detailAddress"
                        ref={addressRef}
                        value={inputValue.detailAddress}
                        onChange={handleInputValue}
                      />
                      <em className="notice">
                        무료 교재 및 이벤트 상품은 해당 입력 주소로 발송이
                        되오니 정확한 주소 입력 부탁드립니다.
                      </em>
                    </dd>
                  </dl>
                </div>
                <button
                  type="button"
                  onClick={postEditInfo}
                  className={
                    "btn btn-edit-submit " +
                    (isComplete ? "active" : "disabled")
                  }
                  disabled={isComplete ? false : true}
                >
                  저장
                </button>
              </form>
              <div className="withdrawal-area">
                <p>야나두를 더이상 사용하지 않는다면?</p>
                <Link to="/member/drop" className="btn-withdrawal">
                  회원탈퇴 바로가기
                </Link>
              </div>
            </section>
          </div>
        </div>
      </div>
      <DaumPostCode
        className="post-code"
        onComplete={handlePostCode}
        autoClose
        animation
      />
      {modalopen ? (
        <ModalPortal>
          <ModalPassword btnClose handleCloseModal={handleCloseModal} />
        </ModalPortal>
      ) : null}
      {modalopenAgree ? (
        <ModalPortal>
          <AlertModal
            btnConfirm
            titleMsg="마케팅 정보 안내 서비스 알림"
            customTag={
              <p style={{ color: "#717188", paddingTop: ".4rem" }}>
                전송자 : (주)야나두
                <br />
                수신동의 일시 : {moment().format("YYYY년 M월 D일 H시")}
                <br />
                처리내용 : 광고 및 마케팅 이용{" "}
                {isTargetChecked ? `동의` : `철회`}
                <span style={{ display: "block", paddingTop: "1.6rem" }}>
                  마이페이지에서 설정 변경 가능
                </span>
              </p>
            }
            handleCloseModal={handleCloseModalAgree}
          />
        </ModalPortal>
      ) : null}
    </>
  );
}
