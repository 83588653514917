import { useNavigate } from 'react-router-dom';

import TabMain from './TabMain';

import '../../assets/styles/notice.scss';
import React, { useEffect, useState } from 'react';

import api from '../../common/api';

import MobileHeader from 'components/common/MobileHeader';

export default function DetailTerms() {
  const navigate = useNavigate();
  const [termsData, setTermsData] = useState([]);
  const [termsDataList, setTermsDataList] = useState([]);
  const [currentSeq, setCurrentSeq] = useState([]);
  // console.log(termsDataList)

  const getTermsData = () => {
    api
      .get('/v2/service-terms/?seq=' + currentSeq)
      .then((response) => {
        setTermsData(response.data.data.contents);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getTermsDataList = () => {
    api
      .get('/v2/service-terms/history-titles/')
      .then((response) => {
        setTermsDataList(response.data.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleTermsChange = (e) => {
    const targetValue = e.target.value;
    setCurrentSeq(targetValue);
  };

  useEffect(() => {
    getTermsData();
  }, [currentSeq]);

  useEffect(() => {
    getTermsDataList();
  }, []);

  return (
    <>
      <div className="terms-col">
        <div className="inner-col">
          <div className="container mypage-sub">
            <MobileHeader title="이용약관"   onClick={() =>
                  document.referrer == '' ? navigate('/service/center') : navigate(-1)
                }/>
            <section className="list-detail-col">
              <div className="title-col">
                <TabMain activeTab={'TERMS'} />
              </div>
              <div className="detail-title-area">
                <div className="tit-flex-form">
                  <strong className="sub-tit black" style={{ marginTop: '0' }}>
                    야나두 이용약관
                  </strong>
                  <div className="info">
                    <span className="date" />
                  </div>
                </div>
              </div>
              <div className="detail-content line-unset">
                <div className="detail-question">
                  {termsData && <pre dangerouslySetInnerHTML={{ __html: termsData }}></pre>}
                </div>
              </div>
              <div className="select-box-col">
                <select
                  name="termsPast"
                  id="termsPast"
                  defaultValue="default"
                  onChange={handleTermsChange}
                >
                  {termsDataList &&
                    termsDataList.map((termsList) => (
                      <option value={termsList.seq} key={termsList.seq}>
                        {termsList.title}
                      </option>
                    ))}
                </select>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
}
