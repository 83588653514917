import React from 'react'
import theme from 'common/theme'
import styled, { ThemeProvider } from 'styled-components'

const TotalPriceInfo = ({ priceViewType, totalOrderPrice, deliveryFee, couponDiscountSum, totalDiscountPrice, totalPrice, isCheck, handleCheckedItem }) => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <div className="inner-col">
          <PriceInfoBox>
            <h1>결제 정보</h1>
            {priceViewType ? (
              <PriceBox>
                <dl>
                  <dt>총 주문금액</dt>
                  <dd>
                    <strong>{totalOrderPrice}</strong>
                  </dd>
                </dl>
                <dl className="plus">
                  <dt>배송비</dt>
                  <dd>
                    <strong>{deliveryFee}</strong>
                  </dd>
                </dl>
                {couponDiscountSum && couponDiscountSum !== '0' ? (
                  <dl className="minus">
                    <dt>쿠폰 할인 금액</dt>
                    <dd>
                      <strong>{couponDiscountSum}</strong>
                    </dd>
                  </dl>
                ) : null}
                <dl className="result">
                  <dt>
                    <strong>최종 결제 금액</strong>
                  </dt>
                  <dd>{totalPrice}</dd>
                </dl>
              </PriceBox>
            ) : (
              <PriceBox>
                <dl>
                  <dt>총 주문금액</dt>
                  <dd>
                    <strong>{totalOrderPrice}</strong>
                  </dd>
                </dl>
                <dl className="plus">
                  <dt>배송비</dt>
                  <dd>
                    <strong>{deliveryFee}</strong>
                  </dd>
                </dl>
                <dl className="minus">
                  <dt>총 할인 받은 금액</dt>
                  <dd>
                    <strong>{totalDiscountPrice}</strong>
                  </dd>
                </dl>
                <dl className="result">
                  <dt>
                    <strong>최종 결제 금액</strong>
                  </dt>
                  <dd>{totalPrice}</dd>
                </dl>
              </PriceBox>
            )}
          </PriceInfoBox>
          {/* <AgreeCheckBox>
            <label htmlFor="agreeOrder">
              <input type="checkbox" id="agreeOrder" name="agreeOrder" checked={isCheck ? true : false} onChange={handleCheckedItem} />
              <i className="chk"></i>
              <span>주문할 상품정보에 동의하십니까?</span> <em>(전자상거래법 제8조 2항)</em>
            </label>
          </AgreeCheckBox> */}
        </div>
      </ThemeProvider>
    </>
  )
}

export default TotalPriceInfo

const PriceInfoBox = styled.div`
  padding-top: 8rem;
  h1 {
    font-weight: 700;
    font-size: 3rem;
    color: #000;
    text-align: left;
  }
  @media ${({ theme }) => theme.device.mobile} {
    padding: 5rem 4.2rem;
    border-top: 1.2rem solid #ececf6;
  }
`

const PriceBox = styled.div`
  display: flex;
  width: 100%;
  margin-top: 3rem;
  border-top: 0.3rem solid #000;
  dl {
    flex: 1;
    &.plus {
      dd:before {
        content: '+';
      }
    }
    &.minus {
      dd:before {
        content: '-';
      }
    }
    &.result {
      dd:before {
        content: '=';
      }
    }
    dt {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.6rem;
      height: 7rem;
      border-bottom: 0.1rem solid #e7e7f0;
      strong {
        font-weight: 400;
      }
    }
    dd {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      font-size: 1.6rem;
      height: 14.6rem;
      border-bottom: 0.1rem solid #e7e7f0;
      &:before {
        position: absolute;
        top: 0;
        bottom: 0;
        left: -1.2rem;
        margin: auto;
        width: 2.4rem;
        height: 2.4rem;
        font-size: 2.4rem;
        color: #999;
      }
      strong {
        display: block;
        width: 100%;
        position: relative;
        font-size: 2.6rem;
        &:after {
          content: '원';
          font-size: 2.2rem;
        }
      }
      .point {
        color: #ff5647;
      }
      span {
        display: block;
        width: 100%;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        margin: 0.5rem 0 0;
        color: #717188;
        font-size: 1.4rem;
        font-weight: 400;
      }
    }
  }
  @media ${({ theme }) => theme.device.mobile} {
    display: block;
    border-top: 0;
    dl {
      display: flex;
      flex: auto;
      flex-shrink: 0;
      width: 100%;
      box-sizing: border-box;
      &.plus {
        dd:before {
          display: none;
        }
      }
      &.minus {
        dd {
          strong {
            &:before {
              content: '-';
            }
          }
          &:before {
            display: none;
          }
        }
      }
      &.result {
        dd:before {
          display: none;
        }
      }
      &.result {
        border-top: 0.1rem solid #dfdfea;
        margin-top: 3.4rem;
        padding-top: 3.4rem;
        dd {
          strong {
            font-size: 4rem;
            &:after {
              font-size: 4rem;
            }
            span {
              margin-top: 1rem;
              font-size: 2.2rem;
            }
          }
        }
      }
      dt {
        border-bottom: 0;
        justify-content: flex-start;
        font-size: 2.6rem;
        strong {
          font-weight: 700;
          font-size: 3rem;
        }
      }
      dd {
        flex: auto 1;
        justify-content: flex-end;
        height: auto;
        border-bottom: 0;
        strong {
          display: inline-block;
          width: auto;
          min-width: 44rem;
          font-weight: 700;
          font-size: 2.6rem;
          text-align: right;
        }
      }
    }
  }
`

const AgreeCheckBox = styled.div`
  padding-top: 3rem;
  label {
    display: block;
    text-align: left;
    span {
      font-weight: 700;
    }
    em {
      font-size: 1.4rem;
      color: #717188;
    }
  }
  @media ${({ theme }) => theme.device.mobile} {
    display: none;
  }
`
