import { YANADOO_AI_HOST } from 'common/config'

const TRAVEL_META_HOST = `${YANADOO_AI_HOST}/travel/chat-meta`
const TRAVEL_CHAT_HOST = `${YANADOO_AI_HOST}/travel/chat`
const TRAVEL_FEEDBACK_HOST = `${YANADOO_AI_HOST}/travel/feedback`
const TRAVEL_REVIEW_HOST = `${YANADOO_AI_HOST}/travel/reviews`
const TRAVEL_IMAGE_HOST = 'https://english.yanadoocdn.com/upload/yanadoo/new/aiTravel/'
const TRAVEL_THUMBNAIL_HOST = `${YANADOO_AI_HOST}/images/travel/titleImg/`
const TRAVEL_PROFILE_IMAGE = `${YANADOO_AI_HOST}/images/travel/profile/profile.png`

export const AI_TRAVEL_CONFIG = {
  TRAVEL_META_HOST,
  TRAVEL_CHAT_HOST,
  TRAVEL_FEEDBACK_HOST,
  TRAVEL_REVIEW_HOST,
  TRAVEL_IMAGE_HOST,
  TRAVEL_PROFILE_IMAGE,
  TRAVEL_THUMBNAIL_HOST
}
