import React, { useEffect } from 'react'
import { useNavigate } from 'react-router'

import cx from 'classnames'
import { NATIVE_FUNC } from 'pages/aiNadoo/native'

import s from './Webview.module.scss'

export default function SrrTitleBar({ title, type, onBtnClick }) {
  const navigate = useNavigate()

  useEffect(() => {
    window.pressedBackButton = function () {
      pressedBackButton()
    }

    return () => {
      delete window.pressedBackButton
    }
  }, [])

  const pressedBackButton = () => {
    if (onBtnClick) {
      onBtnClick()
    } else {
      switch (type) {
        case 'close':
          NATIVE_FUNC.showTitleBar('srr')
          NATIVE_FUNC.closeWebView('srr')
          break
        default:
          navigate(-1)
          break
      }
    }
  }

  return (
    <hgroup className={cx(s.titleBar, s.srrTitleBar)}>
      <button className={s.btnBack} onClick={pressedBackButton}>
        webview out
      </button>
      <h1 className={s.title}>{title}</h1>
    </hgroup>
  )
}
