import React from 'react';
import qrCodeImg from '../../../src/assets/images/img_download_qrcode.png';

export default function ModalAppDownload({ btnClose, handleCloseModal }) {
  return (
    <>
      <div className="modal-wrap">
        <div className="dimmed" onClick={handleCloseModal}></div>
        <div className="modal-inner">
          {btnClose && (
            <button type="button" className="btn-modal-close" onClick={handleCloseModal}>
              <i className="blind">닫기</i>
            </button>
          )}
          <div className="modal-header">
            <strong>앱 다운로드</strong>
          </div>
          <div className="modal-content app-download">
            <p>
              세상의 모든 클래스를 모아놨다!
              <strong>다양한 학습을 앱으로 이용해보세요!</strong>
            </p>
            <img src={qrCodeImg} className="qrcode" alt="앱 다운로드" />
            <div className="description">
              새로워진 야나두에서 당신의
              <br />
              <strong>성공 잠재력을 깨워줄</strong> 클래스를 만나보세요.
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
