import React, { useState } from 'react'

import cx from 'classnames'
import ModalPortal from 'ModalPortal'

import s from './ModalProfile.module.scss'

const HELLO_TEXT = {
  emily: {
    text: '안녕🖐🏻 나는 Emily야.\n나는 평소 러닝하는 걸 좋아해.\n지금은 UX 디자이너로 일하고 있어.',
    row: 3
  },
  sam: {
    text: '안녕 반가워, 나는 Sam이야.\n현재 LA에서 헬스 트레이너로 일하고 있지💪',
    row: 2
  },
  tony: {
    text: '안녕! 나는 Tony라고 해.\n나는 영국에서 소프트웨어 엔지니어로 일하고 있어.',
    row: 2
  },
  rachael: {
    text: '안녕. 나는 Rachael이야.\n런던에서 초등학교 선생님을 하고 있어.',
    row: 2
  },
  lily: {
    text: '안녕. 내 이름은 Lily야.\n영국에서 게스트 하우스를 운영하고 있어👩‍🍳',
    row: 2
  },
  laura: {
    text: '안녕. 나는 Laura라고 해.\n뉴욕의 한 글로벌 기업에서 마케터로 일하고 있어.',
    row: 2
  },
  tom: {
    text: '안녕. 나는 Tom이라고 해.\n부모님은 모두 한국 분이시고, 난 태어났을 때부터 쭉\n보스턴에 살고 있어.',
    row: 3
  },
  jenny: {
    text: '반가워 나는 Jenny야.\nUC버클리에서 영문학을 전공하고 있어.',
    row: 2
  }
}

export default function ModalProfile({ character, handleProfileModal }) {
  const { info } = character
  const { fullname, age, job, location, mbti, hashtag, firstname } = info
  const [more, setMore] = useState(false)
  const key = firstname.toLowerCase()

  const onClickMore = () => {
    setMore(!more)
  }

  return (
    <ModalPortal>
      <article className={s.modalProfile}>
        <div className={cx(s.modalContainer, s[key])}>
          <div className={s.profileContent}>
            <dl className={s.profile}>
              <dt className={s.name}>{fullname}</dt>
              <dd className={s.info}>
                {age} / {mbti} / {job}
                <i className={s.dot}>dot</i>
                {location}
              </dd>
              <dd className={s.hash}>
                {hashtag.map((hash, index) => (
                  <span key={index}>{hash}</span>
                ))}
              </dd>
            </dl>
            <div className={s.helloText}>
              <p className={cx({ [s.active]: more, [s.two]: HELLO_TEXT[key].row === 2 })}>
                {HELLO_TEXT[key].text}
              </p>
              <button className={cx({ [s.active]: more })} onClick={onClickMore}>
                {more ? '접기' : '더보기'}
              </button>
            </div>
          </div>
          <button className={s.btnClose} onClick={handleProfileModal}>
            close
          </button>
        </div>
      </article>
    </ModalPortal>
  )
}
