import React from 'react';
import styled, { css } from 'styled-components';

// fontSize 컨벤션 (pc / mobile)
// sm : 1.1rem / 2.2rem
// md : 1.4rem / 2.8rem
// lg : 1.8rem / 3.6rem
const StyleFontSize = css`
  ${(props) =>
    props.fontSize === `sm` &&
    css`
      font-size: 1.1rem;

      @media ${({ theme }) => theme.device.mobile} {
        font-size: 2.2rem;
      }
    `}

  ${(props) =>
    props.fontSize === `md` &&
    css`
      font-size: 1.4rem;

      @media ${({ theme }) => theme.device.mobile} {
        font-size: 2.8rem;
      }
    `}

  ${(props) =>
    props.fontSize === `lg` &&
    css`
      font-size: 1.8rem;

      @media ${({ theme }) => theme.device.mobile} {
        font-size: 3.6rem;
      }
    `}
`;

// StyleBackgroundColor 컨벤션
const StyleBackgroundColor = css`
  ${(props) =>
    props.ThemeColor === `dark` &&
    css`
      color: #fff;
      background-color: #28284b;
    `}
`;

// 말줄임 컨벤션
const StyleEllipsis = css`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const StyleEllipsisTwo = css`
  ${StyleEllipsis}
  -webkit-line-clamp: 2;
`;

const InnerStyle = styled.div`
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 3rem;
  box-sizing: border-box;

  @media ${({ theme }) => theme.device.mobile} {
    padding: 0;
  }
`;

function InnerBox({ children, ...rest }) {
  return <InnerStyle {...rest}>{children}</InnerStyle>;
}

// max-width: 806px인 스타일 : 스르르 학습지 후기에서 사용
const CardInnerStyle = styled.div`
  position: relative;
  max-width: 866px;
  margin: 0 auto;
  padding: 0 3rem;
  box-sizing: border-box;

  @media ${({ theme }) => theme.device.mobile} {
    padding: 0;
  }
`;

function CardInnerBox({ children, ...rest }) {
  return <CardInnerStyle {...rest}>{children}</CardInnerStyle>;
}

// max-width: 888px인 스타일 : 커뮤니티에서 사용
const SmInnerStyle = styled.div`
  position: relative;
  max-width: 948px;
  margin: 0 auto;
  padding: 0 3rem;
  box-sizing: border-box;

  @media ${({ theme }) => theme.device.mobile} {
    padding: 0;
  }
`;

function SmInnerBox({ children, ...rest }) {
  return <SmInnerStyle {...rest}>{children}</SmInnerStyle>;
}

const BlindStyle = styled.i`
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  overflow: hidden;
`;

function Blind({ children, ...rest }) {
  return <BlindStyle {...rest}>{children}</BlindStyle>;
}

const deviceSizes = {
  mobile: '1024px',
};

const device = {
  mobile: `screen and (max-width: ${deviceSizes.mobile})`,
};

const theme = {
  InnerBox,
  SmInnerBox,
  CardInnerBox,
  Blind,
  device,
  StyleFontSize,
  StyleBackgroundColor,
  StyleEllipsis,
  StyleEllipsisTwo,
};

export default theme;
