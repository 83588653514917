import React from 'react'
import '../../assets/styles/modal.scss'

const ModalVod = ({ btnClose, handleCloseModal, iframeUrl }) => {
  return (
    <>
      <div className="modal-wrap vod">
        <div className="dimmed" onClick={handleCloseModal} />
        <div className="modal-inner">
          {btnClose && (
            <button type="button" className="btn-modal-close white" onClick={handleCloseModal}>
              <i className="blind">닫기</i>
            </button>
          )}
          <div className="modal-content">
            <iframe width="100%" height="100%" src={iframeUrl} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
          </div>
        </div>
      </div>
    </>
  )
}

export default ModalVod
