import React from 'react'

import ModalPortal from 'ModalPortal'
import { closeWebView } from 'webview/bridge'

import s from './ModalAiNadoo.module.scss'

export default function ModalBack({ onConfirm, onClose }) {
  return (
    <ModalPortal>
      <div className={s.modalAinadoo}>
        <div className={s.ainadooBack}>
          <div className={s.backContent}>
            <h3>대화를 종료하시겠습니까?</h3>
            <p>대화 종료 시 대화 내용은 저장되지 않습니다.</p>
          </div>
          <div className={s.btnGroup}>
            <button onClick={() => closeWebView()}>종료</button>
            <button className={s.btnConfirm} onClick={onClose}>
              계속 대화하기
            </button>
          </div>
        </div>
      </div>
    </ModalPortal>
  )
}
