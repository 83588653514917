import { YANADOO_CDN_HOST } from 'common/config'

import styles from '../searchResult.module.css'

export default function ResultNone() {
  return (
    <div className={styles.resultNone}>
      <img src={`${YANADOO_CDN_HOST}/search/icon_result-none.png`} alt="icon none" />
      <p className={styles.description}>검색 결과가 없습니다.</p>

      <div className={styles.tip}>
        <p className={styles.tipTitle}>검색 TIP</p>
        <ul className={styles.tipList}>
          <li>단어의 철자가 정확한지 확인해 주세요.</li>
          <li>검색어의 단어 수를 줄이거나 다른 검색어로 검색해 보세요.</li>
          <li>일반적인 단어로 다시 검색해 보세요.</li>
        </ul>
      </div>
    </div>
  )
}
