import React from 'react'

import ModalPortal from 'ModalPortal'
import { closeWebView } from 'webview/bridge'

import s from './ModalAiTravel.module.scss'

export default function ModalBack({ onClick }) {
  return (
    <ModalPortal>
      <div className={s.modalAiTravel}>
        <div className={s.modalContent}>
          <h3>대화를 종료하시겠습니까?</h3>
          <p>
            대화 종료 시 대화 내용(피드백)은 <br />
            저장되지 않습니다.
          </p>
          <div className={s.btnGroup}>
            <button onClick={onClick}>취소</button>
            <button className={s.btnConfirm} onClick={() => closeWebView()}>
              종료
            </button>
          </div>
        </div>
      </div>
    </ModalPortal>
  )
}
