import React from 'react';
import { ThemeProvider } from 'styled-components';
import styled from 'styled-components';
import theme from 'common/theme';

export default function LikedCount({ count, type }) {
  const countReturn = (num) => {
    num = Number(num);
    return num / 10000 >= 1
      ? Math.floor(num / 10000) +
          '.' +
          (Math.floor(num / 1000) - Math.floor(num / 10000) * 10) +
          '만'
      : num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  return (
    <ThemeProvider theme={theme}>
      <CountItem>
        {type} {countReturn(count)}개
      </CountItem>
    </ThemeProvider>
  );
}

const CountItem = styled.span`
  flex: 0 auto;
  position: relative;
  margin-top: 0.5rem;
  font-weight: 400;
  font-size: 1.6rem;
  color: #717188;

  @media ${({ theme }) => theme.device.mobile} {
    font-size: 2.2rem;
  }
`;
