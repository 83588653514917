import { useEffect, useState } from 'react'

import api from 'common/api'

import { CS_CONFIG } from '../config'

export default function useGetKeywords() {
  const [keywords, setKeywords] = useState([])

  const fetchFaqCategory = async () => {
    try {
      const response = await api.get(`${CS_CONFIG.AI_FAQ_KEYWORD}`)
      const data = response.data.slice(0, 5)
      setKeywords(data)
    } catch (e) {
      console.error(`fetch faq keywords error : `, e)
    }
  }

  useEffect(() => {
    fetchFaqCategory()
    return () => setKeywords([])
  }, [])

  return { keywords }
}
