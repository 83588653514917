import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from 'common/api';
import * as config from 'common/config';
import '../../../src/assets/styles/modal.scss';
import '../../assets/styles/member.scss';

export default function ModalNonMember({ title, btnClose, userId, handleChangeJoinModal }) {
  const navigate = useNavigate();

  const handleCloseBtn = () => {
    navigate('/');
  };

  const [orderInfo, setOrderInfo] = useState();
  // 비회원 수기결제 상품 정보 조회
  const getManualPaymentInfo = () => {
    api
      .get('/v2/manual-payment/order/info?orderNo=' + userId)
      .then((response) => {
        setOrderInfo(response.data.data);
      })
      .catch((e) => {
        alert(config.MESSAGE['common-error']);
      });
  };

  useEffect(() => {
    getManualPaymentInfo();
  }, []);

  return (
    <>
      <div className="modal-wrap nonmember">
        <div className="dimmed" onClick={handleCloseBtn}></div>
        <div className="modal-inner">
          {btnClose && (
            <button type="button" className="btn-modal-close" onClick={handleCloseBtn}>
              <i className="blind">닫기</i>
            </button>
          )}
          <div className="modal-header">
            <strong>{title}</strong>
          </div>
          <div className="modal-content">
            <div className="inner">
              <h3>
                주문상품을 확인하고 <br /> 회원가입을 진행해주세요.
              </h3>

              {orderInfo && (
                <>
                  <div className="order-info">
                    <span className="title">주문상품</span>
                    <p className="product">{orderInfo.orderPackageName}</p>

                    {orderInfo.classBeginDate && (
                      <div className="class-info">
                        <span>클래스 시작</span>
                        <span className="date">{orderInfo.classBeginDate}</span>
                      </div>
                    )}
                  </div>
                  <div className="order-notice">
                    {orderInfo.classBeginDate && (
                      <p>
                        ※ {orderInfo.classBeginDate} 클래스가 자동으로 시작됩니다.
                        <br />
                        지금 바로 회원가입을 하고 클래스 수강을 시작해주세요.
                      </p>
                    )}
                    {orderInfo.existRealProduct === 'Y' && (
                      <p>
                        ※ 주문하신 배송상품은
                        <strong>결제 완료와 함께 배송접수처리</strong> 되었습니다.
                        <br />
                        마이페이지에서 배송현황을 확인해주세요.
                      </p>
                    )}
                  </div>
                </>
              )}
              <div className="notice-col">
                <h4>가입 유의사항</h4>
                <ul>
                  <li>비회원 결제 시 사용한 휴대폰 번호로만 회원 가입 가능</li>
                  <li>
                    결제 시 사용한 휴대폰 번호가 아닌
                    <strong>다른 휴대폰 번호로 가입 시 결제내역이 연동되지 않으니</strong>
                    주의하시기 바랍니다.
                  </li>
                </ul>
              </div>

              <div>
                <button type="button" className="btn active" onClick={handleChangeJoinModal}>
                  회원가입
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
