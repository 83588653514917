import { useParams } from 'react-router-dom'

import NotFound from 'components/common/NotFound'
import EventPopup from 'components/promotion/dbCollectionForm/components/EventPopup'
import { dbCollectionFormValues } from 'components/promotion/dbCollectionForm/config'

export default function DbCollectionForm() {
  const { keyword } = useParams()

  if (!dbCollectionFormValues[keyword] || !keyword) {
    return <NotFound />
  }

  return <EventPopup promotion={keyword} />
}
