import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { ThemeProvider } from 'styled-components';
import styled from 'styled-components';
import theme from 'common/theme';

import ModalPortal from '../ModalPortal';
import ModalShare from './modal/ModalShare';

import LikedCount from 'components/LikedCount';
import LikedButton from 'components/LikedButton';

import IcoShare from '../assets/images/ico_share_114x114_w.png';
import IcoComment from '../assets/images/ico_comment_114x114.png';

import api from 'common/api';

export default function CommunityInfo({
  type, // 리얼수강후기, 스르르 학습지 후기 분기처리
  link, // 목록 페이지에서 '좋아요, 댓글달기' 영역 클릭 시 이동 될 url
  isLoading,
  data,
  handleOpenComment,
}) {
  const location = useLocation();
  const pathname = location.pathname;
  const [shareItemData, setShareItemData] = useState(false);
  const [shareModalOpen, setShareModalOpen] = useState(false);

  const [likeNum, setLikeNum] = useState(0);
  const [liked, setLiked] = useState(false);

  const moveUrlLists = {
    // 스르르 학습지 후기
    REVIEW: {
      moveUrl: '/community/review/detail/',
    },

    // 리얼 수강 후기
    REAL: {
      moveUrl: '/courseReview/detail/',
    },
  }[type];

  const postShare = (seq, relationType) => {
    api
      .post(
        `v2/reaction/increase?reactionType=SHARE&relationSeq=${seq}&relationType=${relationType}`
      )
      .then((response) => {
        if (response.data.meta.code === 200) {
          console.log('공유 성공');
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const onToggleLike = () => {
    const seq = data.scholarshipRefundHistorySeq
      ? data.scholarshipRefundHistorySeq
      : data.learningReviewSeq
      ? data.learningReviewSeq
      : data.seq;
    const relationType =
      data.type === 'LEARNING' || data.reviewType === 'LEARNING' || type === 'REVIEW'
        ? 'REVIEW'
        : 'SCHOLARSHIP_REFUND_HISTORY';

    if (isLoading) {
      api
        .post(`v2/reaction/like?relationSeq=${seq}&relationType=${relationType}`)
        .then((response) => {
          if (response.data.meta.code === 200) {
            setLikeNum(response.data.data.likeCount);
            setLiked(response.data.data.isActive === 'Y' ? 'active' : false);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  useEffect(() => {
    if (isLoading) {
      setLikeNum(data.likeCount);
      setLiked(data.liked || data.isLiked ? 'liked' : false);
    }
  }, [isLoading, data]);

  const handleOpenShareModal = useCallback((e, data) => {
    document.body.classList.add('modal-open');
    setShareModalOpen(true);
    setShareItemData(data);
  }, []);

  const handleCloseShareModal = useCallback((e) => {
    document.body.classList.remove('modal-open');
    setShareModalOpen(false);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <ButtonListBox>
        <div>
          {link ? (
            <Link to={link}>
              <LikedCount count={likeNum} type={'좋아요'}>
                {likeNum}
              </LikedCount>
              <LikedCount count={data.commentCount} type={'댓글'}></LikedCount>
            </Link>
          ) : (
            <>
              <LikedCount count={likeNum} type={'좋아요'}></LikedCount>
              <LikedCount count={data.commentCount} type={'댓글'}></LikedCount>
            </>
          )}
        </div>
        <div>
          <ShareButton
            onClick={(e) => {
              handleOpenShareModal(e, data);
            }}
          >
            <span>공유하기</span>
          </ShareButton>
          <LikedButton
            onToggleLike={onToggleLike}
            liked={liked}
            isLoading={isLoading}
            data={data}
          ></LikedButton>
          {link ? (
            <CommentLink to={link}>댓글달기</CommentLink>
          ) : (
            <CommentButton onClick={handleOpenComment}>댓글달기</CommentButton>
          )}
        </div>
      </ButtonListBox>
      {shareModalOpen ? (
        <ModalPortal>
          <ModalShare
            title={'수강생들의 리뷰를'}
            snsShareTitle={
              type === 'REVIEW' ? '학습지 후기' : data.title ? data.title : '장학금 리뷰'
            }
            btnClose
            data={shareItemData}
            postShare={postShare}
            banner={data.thumbnailPath}
            handleCloseModal={handleCloseShareModal}
            pathname={
              // 스르르 학습지 후기면
              type === 'REVIEW'
                ? moveUrlLists.moveUrl + data.learningReviewSeq
                : !data.type
                ? pathname
                : // 장학금 후기 시퀀스가 있다면
                data.scholarshipRefundHistorySeq
                ? moveUrlLists.moveUrl + data.seq + '/' + data.scholarshipRefundHistorySeq // 장학금 후기
                : // 일반 리얼후기 시퀀스가 있다면
                data.seq
                ? moveUrlLists.moveUrl + data.seq + '/' + 0 // 일반 리얼수강후기
                : moveUrlLists.moveUrl + data.learningReviewSeq // 스르르 학습지 후기
            }
          />
        </ModalPortal>
      ) : null}
    </ThemeProvider>
  );
}

const ButtonListBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 7rem;
  padding: 0 3.5rem;
  border-top: 1px solid #dfdfea;
  box-sizing: border-box;

  div {
    display: flex;
    align-items: center;

    span {
      & + span {
        margin-left: 1rem;
      }
    }

    button {
      & + a {
        margin-left: 1.6rem;
      }
    }

    a {
      position: relative;
      font-weight: 700;
      font-size: 1.6rem;
      color: #8d8da0;
    }
  }

  @media ${({ theme }) => theme.device.mobile} {
    padding: 1.3rem 4rem;
    height: 10rem;

    div {
      span {
        & + span {
          margin-left: 1.4rem;
        }
      }

      button {
        & + a {
          margin-left: 2.6rem;
        }
      }

      a {
        font-size: 2.2rem;

        &::before {
          width: 3.8rem;
          height: 3.8rem;
        }
      }
    }
  }
`;

const CommentLink = styled(Link)`
  padding: 0;

  &::before {
    content: '';
    display: inline-block;
    width: 2.4rem;
    height: 2.4rem;
    margin-top: -0.2rem;
    margin-right: 0.2rem;
    background: url(${IcoComment}) 50% 50% no-repeat;
    background-size: 100% auto;
    vertical-align: middle;
  }
`;

const ShareButton = styled.button`
  width: 2.4rem;
  height: 2.4rem;
  background: url(${IcoShare}) 50% 50% no-repeat;
  background-size: 100% auto;

  span {
    position: absolute;
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
  }

  @media ${({ theme }) => theme.device.mobile} {
    width: 3.8rem;
    height: 3.8rem;
  }
`;

const CommentButton = styled.button`
  position: relative;
  margin-left: 1.6rem;
  padding: 0;
  font-weight: 700;
  font-size: 1.6rem;
  color: #8d8da0;

  &::before {
    content: '';
    display: inline-block;
    width: 2.4rem;
    height: 2.4rem;
    margin-top: -0.2rem;
    margin-right: 0.2rem;
    background: url(${IcoComment}) 50% 50% no-repeat;
    background-size: 100% auto;
    vertical-align: middle;
  }

  @media ${({ theme }) => theme.device.mobile} {
    margin-left: 2.6rem;
    font-size: 2.2rem;

    &::before {
      width: 3.8rem;
      height: 3.8rem;
    }
  }
`;
