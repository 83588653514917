import s from './ModalPrivacyAgree.module.css'

export default function ModalPrivacyAgree({ toggle, style }) {
  return (
    <>
      <div className={s['pop-agree-marketing']}>
        <div className={s['pop-ynd-bg']} style={{ ...style }} onClick={toggle}></div>
        <div className={s['pop-ynd-content']}>
          <button type="button" className={s['pop-ynd-close']} onClick={toggle}>
            닫기
          </button>
          <h3>개인정보 수집 및 이용 동의 약관</h3>
          <div className={s['content-scroll']}>
            <p>
              야나두는 귀하의 이벤트 및 문의에 대한 처리 및 회신을 위하여 귀하의 개인정보를 수집 및
              처리하고자 합니다. 내용을 자세히 읽으신 후 동의여부를 결정하여 주시기 바랍니다.
              <br />
              <br />
              <br />
              1. 개인정보 수집 이용목적
              <br />
              - 야나두 교육 상품 안내, 고객문의(상담신청) 처리 및 회신
              <br />
              - 야나두 피트니스 상품 안내, 고객문의(상담신청) 처리 및 회신
              <br />
              <br />
              2. 개인정보 수집 항목
              <br />
              - 이름, 휴대전화번호
              <br />
              <br />
              3. 개인정보 이용기간 및 보유기간
              <br />
              - 상담 완료 후 파기
              <br />
              <br />
              4. 동의를 거부할 권리 및 동의 거부에 따른 불이익(TM 동의 후 철회 방법)
              <br />
              - 귀하는 개인정보 수집 및 이용동의에 대하여 동의를 거부할 권리가 있으며, 동의를
              <br />
              거부하는 경우 상담신청이 불가능합니다
              <br />
              - TM 동의 후 철회를 원하실 경우 야나두 고객센터(1600-0563)으로 연락주시면 언제든 철회
              가능합니다
              <br />
            </p>
          </div>
        </div>
      </div>
    </>
  )
}
