import useGetTerms from 'hooks/useGetTerms'

import s from './Terms.module.css'

export default function Terms({ brand, type }) {
  const terms = useGetTerms(brand, type)

  if (!terms || terms === null) return <></>

  return <div className={s.termsContent} dangerouslySetInnerHTML={{ __html: terms.contents }} />
}
