import React from 'react'
import { useLocation, useNavigate, useParams } from 'react-router'

import s from './SearchPopular.module.scss'

export default function SearchPopular({ keys }) {
  const navigate = useNavigate()
  const location = useLocation()
  const { currentSeq } = useParams()
  const setCurrentSeq = currentSeq ? currentSeq : location.pathname === '/service/center' ? 1 : 2

  const onClickButton = (keyword) => {
    navigate(`/service/faq/search/${setCurrentSeq}?key=${keyword}`)
  }

  return (
    <div className={s.searchPopular}>
      {keys.map((item, index) => (
        <button type="button" key={index} onClick={() => onClickButton(item)}>
          <span>{item}</span>
        </button>
      ))}
    </div>
  )
}
