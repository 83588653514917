import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import api from 'common/api'

import TabQna from './TabQna'
import icoImg from '../../../assets/images/ico_no_qna.png'
import Lnb from '../../../components/Lnb'
import NoContent from '../../../components/NoContent'
import QnaDate from '../../../data/QnaData.json'
import '../../../assets/styles/main.scss'

const Qna = (props) => {
  const [tabMenu, setTabMenu] = useState(1)

  const handleTabMenu = (id) => {
    setTabMenu(id)
  }

  useEffect(() => {
    getData()
    // console.log(props)
  })

  const getData = () => {
    api
      .get('/v2/event/list?index=1&size=10&keys=eventStatus&types=equal&values=PROGRESS')
      .then((response) => {
        // console.log(response.data);
      })
      .catch((e) => {
        console.log(e)
      })
  }

  return (
    <>
      <div className="qna-col">
        <div className="inner-col">
          <div className="container mypage-sub">
            <Lnb />
            <section className="qna-lists-col">
              {/*<ul className="tab-menu-col">
								<li className={(tabMenu === 1 ? "active" : null )}>
									<a href="#;" className="tab-menu" onClick={() => handleTabMenu(1)}>1:1 문의</a>
								</li>
								<li className={(tabMenu === 2 ? "active" : null )}>
									<a href="#;" className="tab-menu" onClick={() => handleTabMenu(2)}>상품 문의</a>
								</li>
								<li className={(tabMenu === 3 ? "active" : null )}>
									<a href="#;" className="tab-menu" onClick={() => handleTabMenu(3)}>학습 문의</a>
								</li>
								<li className={(tabMenu === 4 ? "active" : null )}>
									<a href="#;" className="tab-menu" onClick={() => handleTabMenu(4)}>후기</a>
								</li>
							</ul>*/}
              <TabQna activeTab={'TEST'} />
              <div>
                {/*<div className={"tab-content" + (tabMenu === 3 ? " active" : "")}>*/}
                <div className="search-box-col">
                  <div className="search-txt">
                    <ul>
                      <li>답변이 등록된 후에는 문의를 수정 또는 삭제하실 수 없습니다.</li>
                      <li>학습문의는 MY 클래스에서 등록가능합니다.</li>
                    </ul>
                  </div>
                  <div className="search-box">
                    <select name="" id="periodSelect" defaultValue="default">
                      <option value="default" disabled hidden>
                        3개월
                      </option>
                      <option value="">6개월</option>
                      <option value="">9개월</option>
                    </select>
                    <select name="" id="sortSelect" defaultValue="default">
                      <option value="default" disabled hidden>
                        전체
                      </option>
                      <option value="">내 문의</option>
                    </select>
                  </div>
                </div>
                <table className="table-form">
                  <colgroup>
                    <col width="24%" />
                    <col width="" />
                    <col width="20%" />
                    <col width="12%" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>클래스</th>
                      <th>문의 내용</th>
                      <th>작성일</th>
                      <th>답변여부</th>
                    </tr>
                  </thead>
                  <tbody>
                    {QnaDate.length > 0 ? (
                      QnaDate.map((qnaItem, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <p className="ellipsis">{qnaItem.className}</p>
                            </td>
                            <td>
                              <Link
                                to={{
                                  pathname: `/mypage/qna/detail/${qnaItem.id}`,
                                  state: {
                                    qnaItem
                                  }
                                }}
                              >
                                <strong className="ellipsis">{qnaItem.qnaContent}</strong>
                              </Link>
                            </td>
                            <td>
                              <p>{qnaItem.date}</p>
                            </td>
                            <td>
                              <p
                                className={
                                  'check' + (qnaItem.answerCheck === 'Y' ? ' completed' : '')
                                }
                              >
                                {qnaItem.answerCheck === 'Y' ? '답변완료' : '답변대기'}
                              </p>
                            </td>
                          </tr>
                        )
                      })
                    ) : (
                      <tr>
                        <td colSpan="4">
                          <NoContent icoImg={icoImg} titleMsg={'등록된 문의가 없습니다.'} />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <div className="table-form-mobile">
                  {QnaDate.length > 0 ? (
                    <ul className="table-lists">
                      {QnaDate.map((qnaItem, index) => {
                        return (
                          <li key={index}>
                            <Link
                              to={{
                                pathname: `/mypage/qna/detail/${qnaItem.id}`,
                                state: {
                                  qnaItem: qnaItem
                                }
                              }}
                            >
                              <span className="ellipsis">{qnaItem.className}</span>
                              <strong className="ellipsis line-clamp-2">
                                {qnaItem.qnaContent}
                              </strong>
                              <div className="info">
                                <span
                                  className={
                                    'check' + (qnaItem.answerCheck === 'Y' ? ' completed' : '')
                                  }
                                >
                                  {qnaItem.answerCheck === 'Y' ? '답변완료' : '답변대기'}
                                </span>
                                <span className="date">{qnaItem.date}</span>
                              </div>
                            </Link>
                          </li>
                        )
                      })}
                    </ul>
                  ) : (
                    <NoContent icoImg={icoImg} titleMsg={'등록된 문의가 없습니다.'} />
                  )}
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  )
}

export default Qna
