import alertMessage from 'common/alertMessage'
import { NOT_KOREAN_REGEXR } from 'common/regExr'

/**
 * form validation 관련 공통 hook
 */
export default function useValidation() {
  /**
   * 한글만 받는 Input에 onKeyUp이벤트가 발생했을 경우
   * @param {*} value <string> : input 값
   * @param {*} alertMessageType <string>  : alert Message Type > alertMessage값 참고
   * @returns 한글이 아닌 문자를 제거한 문자
   */
  const onKeyUpKoreanInput = (value, alertMessageType) => {
    if (NOT_KOREAN_REGEXR.test(value)) {
      alert(alertMessage[alertMessageType])
      return value.replace(NOT_KOREAN_REGEXR, '')
    }
    return value
  }

  /**
   * 최소글자수 체크 후 > 포커스
   * @param {*} ele <ref> : 해당 element ref
   * @param {*} min <number>  : 최소 글자수
   * @param {*} alertMessageType <string>  : alert Message Type > alertMessage값 참고
   * @returns
   */
  const checkMinLength = (ele, min, alertMessageType) => {
    const numLength = ele.current.value.trim().length

    if (ele.current.value && numLength < min) {
      alert(alertMessage[alertMessageType])
      ele.current.focus()
      return false
    }
    return true
  }

  /**
   * 최대글자수 체크 후 > 포커스
   * @param {*} ele <React.Element> : 해당 element
   * @param {*} max <number>  : 최대 글자수
   * @param {*} alertMessageType <string>  : alert Message Type > alertMessage값 참고
   * @returns
   */
  const checkMaxLength = (ele, max, alertMessageType) => {
    const numLength = ele.target.value.trim().length

    if (ele.target.value && numLength > max) {
      alert(alertMessage[alertMessageType])
      return false
    }
    return true
  }

  /**
   * 필수값 체크 후 > 포커스
   * @param {*} ele <ref> 해당 element ref
   * @param {*} alertMessageType <string> alert Message Type > alertMessage값 참고
   * @returns
   */
  const checkIsEmpty = (ele, alertMessageType) => {
    const numLength = ele.current.value.trim().length

    if (numLength === 0) {
      alert(alertMessage[alertMessageType])
      ele.current.focus()
      return false
    }
    return true
  }

  /**
   * 폼 엘리먼트로 유효성 검사 및 포커스
   * @param {ReactElement} formRef - form element ref
   * @param {string} id - 대상 요소의 ID
   * @param {string} alertMessageType - 경고 메시지 유형
   * @param {boolean} isCheckbox - 체크박스 여부
   * @returns {boolean} - 유효성 검사 결과
   */
  const validateForm = (formRef, id, alertMessageType, isCheckbox) => {
    if (!formRef.current) return

    if (isCheckbox) {
      if (!formRef.current.querySelector(`#${id}`).checked) {
        alert(alertMessage[alertMessageType])
        return false
      }
      return true
    }

    if (formRef.current.querySelector(`#${id}`).value === '') {
      alert(alertMessage[alertMessageType])
      formRef.current.querySelector(`#${id}`).focus()

      return false
    }
    return true
  }

  /**
   * 연락처 유효성 검사
   *
   * @param {string} 연락처
   * @param {string} alertMessageType - 경고 메시지 유형
   * @returns {boolean} - 전화번호가 유효하면 true, 아니면 false를 반환
   */
  const validateTelNumber = (연락처, alertMessageType) => {
    const telRegex = /^(070|02|0[3-9]{1}[0-9]{1})[0-9]{3,4}[0-9]{4}$/
    const phoneRegex = /^(01[016789]{1})[0-9]{3,4}[0-9]{4}$/

    if (!phoneRegex.test(연락처) && !telRegex.test(연락처)) {
      alert(alertMessage[alertMessageType])
      return false
    }

    return true
  }

  return {
    onKeyUpKoreanInput,
    checkMinLength,
    checkIsEmpty,
    checkMaxLength,
    validateForm,
    validateTelNumber
  }
}
