import { Swiper, SwiperSlide } from 'swiper/react'

import ResponsiveImage from 'components/ResponsiveImage'

import { trainingStepValues } from 'pages/english/config'

import 'swiper/swiper.scss'

export default function StepSwiper({ setActiveIdx }) {
  return (
    <Swiper
      // autoplay={{
      //   delay: 3000,
      //   disableOnInteraction: false,
      //   pauseOnMouseEnter: true,
      // }}
      onSlideChange={(swiper) => {
        setActiveIdx(swiper.realIndex)
      }}
      loop
    >
      {trainingStepValues.map(({ pc, mo, alt }, index) => {
        return (
          <SwiperSlide key={index}>
            <ResponsiveImage pc={pc} mo={mo} alt={alt} maxWidth={1024} />
          </SwiperSlide>
        )
      })}
    </Swiper>
  )
}
