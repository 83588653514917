import styles from './form.module.scss'

const mobileValues = ['010', '011', '016', '017', '018', '019']

export default function PhoneInput({ phone, onChange, hp2Ref, hp3Ref }) {
  const onChangeValue = (e) => {
    if (e.target.value.length > 4) return false
    onChange(e)
  }

  return (
    <div className={`${styles.row} row`}>
      <label className={styles.label}>전화번호</label>
      <div className={styles.selectRow}>
        <select
          name="hp1"
          value={phone.hp1}
          className={styles.phoneSelect}
          onChange={onChangeValue}
        >
          {mobileValues.map((mobile, index) => (
            <option key={index}>{mobile}</option>
          ))}
        </select>
        <input
          name="hp2"
          type="number"
          pattern="[0-9]+"
          inputMode="numeric"
          ref={hp2Ref}
          value={phone.hp2}
          className={styles.inputBox}
          onChange={onChangeValue}
        />
        <input
          name="hp3"
          type="number"
          pattern="[0-9]+"
          inputMode="numeric"
          ref={hp3Ref}
          value={phone.hp3}
          className={styles.inputBox}
          onChange={onChangeValue}
        />
        <input type="hidden" name="phone" id="phone1" />
      </div>
    </div>
  )
}
