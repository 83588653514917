import { useCallback, useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'

import useHeaderStore from 'store/useHeaderStore'

export default function useSubHeader() {
  const isMobile = useMediaQuery({ maxWidth: 750 })
  const currentSubIndex = useHeaderStore((state) => state.currentSubIndex)
  const gnbItems = useHeaderStore((state) => state.gnbItems)
  // GNB 컬러코드와 슬로건 텍스트를 배열로 갖고있는 상태값 입니다. currentSubIndex 번째 GNB의 정보를 초기값으로 갖습니다.

  const [companyItem, setCompanyItem] = useState([
    gnbItems[currentSubIndex]?.backgroundColor,
    gnbItems[currentSubIndex]?.description,
    gnbItems[currentSubIndex]?.gnbMenuSeq
  ])

  // GNB 상단바의 배경 컬러코드와 슬로건 텍스트를 변경하는 함수입니다.
  // GNB 상단바에 onMouseOver 또는 onMouseOut 이벤트가 일어났을때 해당 상태값을 업데이트 합니다.
  const gnbInfoSet = useCallback(
    (idx) => {
      const item = [
        gnbItems[idx]?.backgroundColor,
        gnbItems[idx]?.description,
        gnbItems[idx]?.gnbMenuSeq
      ]
      return item
    },
    [gnbItems]
  )

  useEffect(() => {
    const item = [
      gnbItems[currentSubIndex]?.backgroundColor,
      gnbItems[currentSubIndex]?.description,
      gnbItems[currentSubIndex]?.gnbMenuSeq
    ]
    setCompanyItem(item)
  }, [currentSubIndex, gnbInfoSet, gnbItems])

  return { isMobile, companyItem, gnbInfoSet }
}
