import { useState } from 'react'
import { Link } from 'react-router-dom'

import styles from './TopFixedBanner.module.scss'

export function TopFixedBanner() {
  const [isShow, setIsShow] = useState(true)

  if (!isShow) return null

  return (
    <section className={styles.banner}>
      <div className={styles.inner}>
        <Link to="/event/detail/114">
          돌아온 최저가! AI로 더 강력하게 <strong>&nbsp;블프 핫딜</strong>
          <ArrowIcon />
        </Link>

        <CloseIcon onClick={() => setIsShow(false)} />
      </div>
    </section>
  )
}

function ArrowIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="5" viewBox="0 0 16 5" fill="none">
      <path d="M0.5 4H14.5L11 0.5" stroke="white" />
    </svg>
  )
}

function CloseIcon({ onClick }) {
  return (
    <button type="button" className={styles.close} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="17"
        viewBox="0 0 17 17"
        fill="none"
      >
        <path
          d="M3.5 3.5L13.5 13.5M13.5 3.5L3.5 13.5"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </button>
  )
}
