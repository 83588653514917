import styles from './Checkbox.module.css'

export default function CheckBox({ id, label, isChecked, onChange }) {
  return (
    <label htmlFor={id} className={styles.container}>
      <input id={id} type="checkbox" name={id} checked={isChecked} onChange={onChange} />
      <i className={styles.icoCheckbox}></i>
      <span>{label}</span>
    </label>
  )
}
