import { useEffect, useRef } from 'react'

export default function useOnClickOutside(handler) {
  const ref = useRef(null)

  useEffect(() => {
    const listener = (e) => {
      if (!ref.current || ref.current.contains(e.target)) {
        return
      }
      handler && handler(e)
    }
    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)
    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [ref])

  return ref
}
