import useSubHeader from 'components/header/hooks/useSubHeader'

import SubHeaderCompany from './SubHeaderCompany'
import SubHeaderGnb from './SubHeaderGnb'

export default function SubHeader() {
  const { isMobile, companyItem, gnbInfoSet } = useSubHeader()

  return (
    <>
      {/* 상단 서브메뉴(영어/클래스/피트니스) - pc만 노출 */}
      {!isMobile && <SubHeaderCompany gnbInfoSet={gnbInfoSet} />}
      {/* 하단 서브메뉴 - GNB 메뉴 */}
      <SubHeaderGnb companyItem={companyItem} />
    </>
  )
}
