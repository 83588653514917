import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

//? 개발 테스트 목적으로 문제 풀이를 건너뛰기 위한 전역 변수 (Skip mode)
export const skipMode = false

const initialState = {
  currentSection: skipMode ? 'analyzing' : 'question', // 'question':문항 || 'selfCheck':자가진단 || 'analyzing' : 분석중
  questionIndex: 1,
  leadTime: null,
  startTime: null
}

const useLevelTestAdvancedExamStore = create(
  devtools(
    (set) => ({
      ...initialState,
      onChangeQuestionIndex: (questionIndex) =>
        set(
          (state) => {
            state.questionIndex = questionIndex
          },
          false,
          'LevelTestAdvancedExam/onChangeQuestionIndex'
        ),
      onChangeCurrentSection: (currentSection) =>
        set(
          (state) => {
            state.currentSection = currentSection
          },
          false,
          'LevelTestAdvancedExam/onChangeCurrentSection'
        ),
      onChangeStartTime: (startTime) =>
        set(
          (state) => {
            state.startTime = startTime
          },
          false,
          'LevelTestAdvancedExam/onChangeStartTime'
        ),
      onChangeLeadTime: (leadTime) =>
        set(
          (state) => {
            state.leadTime = leadTime
          },
          false,
          'LevelTestAdvancedExam/onChangeLeadTime'
        ),
      resetState: () =>
        set(
          (state) => {
            state.currentSection = initialState.currentSection
            state.questionIndex = initialState.questionIndex
            state.leadTime = initialState.leadTime
            state.startTime = initialState.startTime
          },
          false,
          'LevelTestAdvancedExam/resetState'
        )
    }),
    { name: 'LevelTestAdvancedExamStore', enabled: process.env.NODE_ENV === 'development' }
  )
)

export default useLevelTestAdvancedExamStore
