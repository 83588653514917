import { useCallback, useEffect, useRef } from 'react'
import { isDesktop, osName } from 'react-device-detect'
import { useNavigate } from 'react-router-dom'

import Loading from 'components/common/Loading'

export default function ClassReview() {
  const timerRef = useRef(null)
  const navigate = useNavigate()

  const launchAppFail = useCallback(() => {
    // 수강 내역 웹으로 이동
    navigate(
      '/myclass/history?utm_source=push&utm_medium=kakaobiz&utm_campaign=membership_rivew&utm_content=needs_review'
    )
  }, [navigate])

  const launchApp = useCallback(() => {
    // AOS iOS 분기
    const scheme = osName === 'Android' ? 'yanadoo://app2?' : 'yanadooapp2://?'
    const parameter =
      'type=webview&link=https://api.yanadoo.co.kr/webview/mypage/coursehistory/classlist'
    const url = scheme + parameter

    window.location.href = url // 앱 실행

    timerRef.current = setTimeout(launchAppFail, 2000) // 앱 실행이 되지 않을 때 (앱 미설치 시)
  }, [launchAppFail])

  useEffect(() => {
    if (isDesktop) {
      navigate('/') // 데스크탑일 경우 예외 처리
      return
    }

    launchApp() // 야나두 앱 실행

    return () => {
      clearTimeout(timerRef.current)
    }
  }, [navigate, launchApp])

  return (
    <div style={{ paddingBlock: '30vw', position: 'relative' }}>
      <Loading />
    </div>
  )
}
