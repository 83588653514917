import React from 'react'
import { useNavigate } from 'react-router'

import s from '../Service.module.scss'

export default function BtnMore({ link, title }) {
  const navigate = useNavigate()

  const handleBtnClick = (url) => {
    navigate(url)
  }
  return (
    <button type="button" className={s.btnMore} onClick={() => handleBtnClick(link)}>
      {title}
    </button>
  )
}
