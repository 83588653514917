import { useEffect, useState } from 'react'

import { getBannerList } from 'api/storeApi'

import * as config from 'common/config'

export default function useHome() {
  const [bannerList, setBannerList] = useState([])

  const handleGetBannerList = async () => {
    try {
      const list = await getBannerList('YANADOO')
      setBannerList(list)
    } catch (error) {
      alert(config.MESSAGE['common-error'])
    }
  }

  useEffect(() => {
    handleGetBannerList()
  }, [])

  return { bannerList }
}
