import React from 'react'

import FaqSearchForm from './FaqSearchForm'
import SearchPopular from './SearchPopular'
import useGetKeywords from '../hooks/useGetKeywords'
import s from '../Service.module.scss'

export default function SectionCSSearch() {
  const { keywords } = useGetKeywords()

  return (
    <section className={s.csSearch}>
      <div className={s.csInner}>
        <h3>무엇을 도와드릴까요?</h3>
        <FaqSearchForm styleName={s.csSearchForm} />
        <SearchPopular keys={keywords} />
      </div>
    </section>
  )
}
