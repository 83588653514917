import React from 'react'

import s from './ChatTopQna.module.scss'

export default function ChatTopQna() {
  return (
    <div className={s.chatTopQna}>
      <p>
        Chat Gpt <span>기반</span>
      </p>
      <h5>학습 중 궁금한 점을 해결해드릴게요!</h5>
    </div>
  )
}
