import React, { useEffect } from 'react'

import moment from 'moment'
import styled from 'styled-components'

export default function NumberMotion() {
  let motionCountUser = 160
  let motionCountMoney = 86
  let motionCountKm = 3073
  let motionCountUserNum = 10

  function countMotion(targetName, count) {
    const target = document.querySelector(targetName)
    const countBox = target.querySelector('.count-motion')

    let checkPoint = true
    let intervalCount
    let countLength = 1

    for (let i = 0; i < String(count).length; i++) {
      countLength *= 10
    }

    clearInterval(intervalCount)

    if (countBox.classList.value.indexOf('motion-fixed') === -1) {
      intervalCount = setInterval(() => {
        if (checkPoint) {
          countBox.innerHTML = Math.floor(Math.random() * countLength)
        } else {
          countBox.innerHTML = count
          countBox.classList.add('motion-fixed')
          clearInterval(intervalCount)
        }
      }, 20)
      setTimeout(() => {
        checkPoint = false
      }, 700)
    }
  }

  useEffect(() => {
    countMotion('.number-motion', motionCountUser)
    countMotion('.number-motion2', motionCountMoney)
    countMotion('.number-motion3', motionCountKm)
    countMotion('.number-motion4', motionCountUserNum)
  }, [])

  return (
    <NumberMotionSection>
      <InnerCol>
        <ul className="motion-item">
          <li className="number-motion">
            <strong>
              <em className="count-motion">0</em>
              <span>만</span>
            </strong>
            <p>누적 회원 수</p>
          </li>
          <li className="number-motion2">
            <strong>
              <em className="count-motion">0</em>
              <span>억</span>
            </strong>
            <p>누적 장학금액</p>
          </li>
          <li className="number-motion3">
            <strong>
              <em className="count-motion">0</em>
              <span>만</span>
            </strong>
            <p>총 라이딩 거리 Km</p>
          </li>
          <li className="number-motion4">
            <strong>
              <em className="count-motion">0</em>
              <span>억</span>
            </strong>
            <p>총 소모 칼로리</p>
          </li>
        </ul>
        <BasisParagraph>*위의 데이터는 {moment().subtract(1, 'months').format('YYYY년 M월')} 기준입니다.</BasisParagraph>
      </InnerCol>
    </NumberMotionSection>
  )
}

const NumberMotionSection = styled.section`
  position: absolute;
  bottom: -13rem;
  left: 0;
  right: 0;
  z-index: 5;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    right: 0;
    bottom: 0;
    margin-left: -60rem;
    background-color: #fff;
    border-top-left-radius: 0.8rem;
    border-bottom-left-radius: 0.8rem;
    box-shadow: 0 0 2rem rgba(0, 0, 0, 0.1);
  }

  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8rem 12rem;
    box-sizing: border-box;

    li {
      strong {
        position: relative;
        font-size: 4rem;

        &::before {
          content: '+';
          position: absolute;
          top: 50%;
          right: -2.8rem;
          color: #ffb900;
          transform: translateY(-50%);
        }

        span {
          display: inline-block;
          font-size: 3.2rem;
          vertical-align: middle;
        }
      }

      p {
        margin-top: 0.8rem;
        font-weight: 400;
        font-size: 2rem;
        color: #717188;
      }
    }
  }

  @media ${({ theme }) => theme.device.mobile} {
    padding-left: 4.2rem;

    > div {
      padding: 0;
    }

    &::before {
      display: none;
    }

    ul {
      padding: 5.6rem 4.8rem 8.7rem 4.8rem;
      background-color: #fff;
      border-top-left-radius: 0.8rem;
      border-bottom-left-radius: 0.8rem;
      box-shadow: 0 0 2rem rgba(0, 0, 0, 0.1);
    }
  }
`

const InnerCol = styled.div`
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 3rem;

  @media ${({ theme }) => theme.device.mobile} {
    padding: 0 7.2rem;
  }
`

const BasisParagraph = styled.p`
  position: absolute;
  bottom: 2rem;
  right: 15rem;
  font-weight: 400;
  font-size: 1.2rem;
  color: #b7b7c8;

  @media ${({ theme }) => theme.device.mobile} {
    right: 3rem;
    font-size: 1.6rem;
  }
`
