import html2canvas from 'html2canvas'

import styles from '../LevelTestAdvancedResult.module.scss'

/**
 * 심화 레벨테스트 결과 화면 다운로드를 처리
 * - 결과화면 내에 아코디언 목록을 캡쳐 시 모든 항목 펼치고 완료 시 다시 접어둠
 * @param {React.RefObject} - 결과를 캡쳐할 Ref 객체
 * @returns {handleDownloadResult} - 결과화면을 캡쳐하여 이미지로 다운로드하는 함수
 */
export default function useDownloadResult(captureAreaRef) {
  const toggleAccordion = (ref, isOpen) => {
    const accordionChildren = ref.current.querySelector('.assessment-accordion').children
    const accordionList = [...accordionChildren]

    accordionList.forEach((li, idx) => {
      if (isOpen) {
        li.classList.add(styles.active)
      } else {
        idx === 0 ? li.classList.add(styles.active) : li.classList.remove(styles.active)
      }
    })
  }

  const handleDownloadResult = async () => {
    if (!captureAreaRef) return

    await toggleAccordion(captureAreaRef, true)

    const canvas = await html2canvas(captureAreaRef.current)

    const data = canvas.toDataURL('image/jpg')
    const fileName = 'levelTestAdvanced_' + Date.now()

    let link = document.createElement('a')
    if (typeof link.download === 'string') {
      link.href = data
      link.download = fileName
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } else {
      window.open(data)
    }

    await toggleAccordion(captureAreaRef, false)
  }

  return { handleDownloadResult }
}
