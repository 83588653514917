import React, { useState } from 'react'
import { useLocation } from 'react-router'

import FaqItem from './FaqItem'
import useGetFaqContent from '../hooks/useGetFaqContent'

export default function FaqList({ list }) {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const faqSeq = Number(queryParams.get('faqSeq'))
  const [selected, setSelected] = useState(faqSeq ? faqSeq : null)
  const { answer } = useGetFaqContent(selected)

  const handleFaqSeq = (faqSeq) => {
    setSelected((prev) => (prev !== faqSeq ? faqSeq : null))
  }

  return (
    <>
      {list.map((item, index) => (
        <FaqItem
          faq={item}
          answer={answer}
          key={index}
          handleFaqSeq={handleFaqSeq}
          open={selected && selected === item.faqSeq}
        />
      ))}
    </>
  )
}
