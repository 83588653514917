import React from 'react'
import { BrowserRouter } from 'react-router-dom'

import ModalPortal from 'ModalPortal'
import AppRouter from 'router/AppRouter'
import ScrollToTop from 'ScrollToTop'

import ModalLogin from 'components/modal/ModalLogin'
import useModalLogin from 'store/useModalLogin'

import 'assets/styles/common.scss'

export default function App() {
  const { isModalLogin, setIsModalLogin } = useModalLogin()

  const handleCloseLogin = () => {
    document.body.classList.remove('modal-open')
    setIsModalLogin(false)
  }

  return (
    <BrowserRouter>
      <ScrollToTop />
      <AppRouter />
      {isModalLogin && (
        <ModalPortal>
          <ModalLogin title={'로그인'} btnClose handleCloseModal={handleCloseLogin} />
        </ModalPortal>
      )}
    </BrowserRouter>
  )
}
