import React from 'react';
import { Link } from 'react-router-dom';

import styled, { ThemeProvider } from 'styled-components';
import theme from 'common/theme';

const TabMenuList = styled.ul`
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;

  @media ${({ theme }) => theme.device.mobile} {
    padding: 0 4.2rem;
    border-bottom: 0.1rem solid #dfdfea;
    background-color: #fff;
  }
`;

const TabMenuItem = styled.li`
  & + li {
    margin-left: 4.8rem;
  }

  &.active {
    a {
      font-weight: 700;
      color: #000;
      border-bottom: 0.3rem solid #FFB900;
    }
  }

  a {
    display: block;
    padding: 0.2rem 0;
    font-size: 2rem;
    color: #a0a0b6;
  }

  @media ${({ theme }) => theme.device.mobile} {
    a {
      padding: 4rem 0;
      font-size: 2.8rem;
    }

    &.active {
      a {
        border-bottom-width: 0.4rem;
      }
    }

    & + li {
      margin-left: 5.5rem;
    }
  }
`;

export default function TabCoupon({ activeTab }) {
  return (
    <ThemeProvider theme={theme}>
      <TabMenuList>
        <TabMenuItem className={activeTab === 'COUPON' ? 'active' : null}>
          <Link to="/mypage/coupon">쿠폰</Link>
        </TabMenuItem>
        <TabMenuItem className={activeTab === 'VOUCHER' ? 'active' : null}>
          <Link to="/mypage/voucher">수강권</Link>
        </TabMenuItem>
      </TabMenuList>
    </ThemeProvider>
  );
}
