import React, { useState, useEffect, useRef, Suspense } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import api from 'common/api'

import { useMediaQuery } from 'react-responsive'

import styled, { css, ThemeProvider } from 'styled-components'
import theme from 'common/theme'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { scrollMove } from '../../common/common'

import ImgView from 'components/ImgView'
import ThumbNail from './ThumbNail'
import AdminAnswer from './AdminAnswer'
import Comment from './Comment'
import AttachmentLink from './AttachmentLink'
import ButtonList from './ButtonList'
import AudioPlayer from './AudioPlayer'
import FileDownLoad from './FileDownLoad'
import Pagination from 'components/Pagination'

import IcoComment from 'assets/images/ico_comment_114x114.png'
import IcoHeart from 'assets/images/ico_heart_216x216g.png'
import IcoHeartRed from 'assets/images/ico_heart_216x216r.png'

export default function DetailBoard({ type }) {
  const location = useLocation()
  const param = location.search
  const { currentSeq } = useParams()
  const deviceCheck = useMediaQuery({ maxWidth: 1024 }) ? true : false
  const [pageIdx, setPageIdx] = useState(1)
  const [totalPages, setTotalPages] = useState()
  const [communityInfo, setCommunityInfo] = useState(null)
  const [commentData, setCommentData] = useState(null)

  const [commentRegistTime, setCommentRegistTime] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [imageFlag, setImageFlag] = useState(false)

  const commentListBoxRef = useRef(null)

  const boardDetailInfo = {
    FREE: {
      title: '수강생 Talk',
      apiContentUrl: '/v2/community-tab/community/post?communityPostSeq=',
      apiCommentUrl: '/v2/community-tab/community/comment/list?communityPostSeq=',
      apiPrevNextUrl: '/v2/community-tab/community/move-and-post?communityPostSeq='
    },
    STUDY: {
      title: '학습 Q&A',
      apiContentUrl: '/v2/community-tab/lecture-question/detail?questionSeq=',
      apiPrevNextUrl: '/v2/community-tab/lecture-question/move?questionSeq='
    },
    SRR: {
      title: '스르르 학습지 Talk',
      apiContentUrl: '/v2/community-tab/community/post?communityPostSeq=',
      apiCommentUrl: '/v2/community-tab/community/comment/list?communityPostSeq=',
      apiPrevNextUrl: '/v2/community-tab/community/move-and-post?communityPostSeq='
    }
  }[type]

  const addComma = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  const handleCurrentIdx = (idx) => {
    setPageIdx(idx)
  }

  // 커뮤니티 상세 정보
  const getCommunityInfo = (seq) => {
    api
      .get(`${boardDetailInfo.apiContentUrl}${seq}`)
      .then((response) => {
        setCommunityInfo(response.data.data)
        setImageFlag(response.data.data?.attachments?.[0].type === 'IMAGE')
        setIsLoading(true)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  // 댓글 조회 API
  const getCommentData = (seq) => {
    api
      .get(`${boardDetailInfo.apiCommentUrl}${seq}&pageIndex=${pageIdx}&pageSize=10`)
      .then((response) => {
        if (response.data.meta.code === 200) {
          setCommentData(response.data.data?.comments)
          if (pageIdx === 1) {
            setTotalPages(response.data.data?.totalPage)
          }
          setCommentRegistTime(response.data.meta.dateTime.replace('-', '/').replace('-', '/'))
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  useEffect(() => {
    getCommunityInfo(currentSeq)
    if (type !== 'STUDY') {
      getCommentData(currentSeq)
    }
    setIsLoading(false)
  }, [currentSeq])

  useEffect(() => {
    if (type !== 'STUDY') {
      getCommentData(currentSeq)
    }
    if (commentListBoxRef.current) {
      scrollMove(0, commentListBoxRef.current.getBoundingClientRect().top + window.pageYOffset - 150)
    }
  }, [pageIdx])

  return (
    <ThemeProvider theme={theme}>
      <theme.SmInnerBox>
        <DetailBoardBox>
          <h3>{boardDetailInfo.title}</h3>
          <TitleBox>
            {type === 'STUDY' ? (
              <p>
                {isLoading ? communityInfo.classes.name : <Skeleton width={'100%'} />}
                {communityInfo?.isReply && <strong>답변완료</strong>}
              </p>
            ) : (
              <p>{isLoading ? communityInfo?.community?.title : <Skeleton width={'100%'} />}</p>
            )}
            <p>{isLoading ? communityInfo?.title : <Skeleton />}</p>
            <div>
              <ProfileBox>
                <ThumbNail backgroundImage={communityInfo?.registUser?.profileImageUrl} />
                <strong>{isLoading ? communityInfo.registUser.nickName : <Skeleton width={deviceCheck ? '20rem' : '10rem'} />}</strong>
              </ProfileBox>
              <InfoBox>
                {isLoading && (
                  <>
                    <span>{communityInfo.registDate}</span>
                    <span>조회수 {addComma(communityInfo.hitCount)}</span>
                  </>
                )}
              </InfoBox>
            </div>
          </TitleBox>
          <ContentBox>
            <p>{communityInfo?.contents || <Skeleton />}</p>
            {communityInfo &&
              communityInfo.attachments &&
              type !== 'STUDY' &&
              communityInfo.attachments.map((attach, idx) => {
                return (
                  <div key={idx}>
                    {attach.type === 'LINK' ? <AttachmentLink data={attach} /> : undefined}
                    {attach.type === 'AUDIO' ? <AudioPlayer data={attach} /> : undefined}
                    {attach.type === 'ALL' ? <FileDownLoad data={attach} /> : undefined}
                  </div>
                )
              })}
            {type === 'STUDY' && isLoading && communityInfo.attachment?.url && <img src={communityInfo.attachment.url} alt="학습관리자에서 등록한 이미지" />}
            {imageFlag && (
              <div style={{ marginTop: deviceCheck ? '5rem' : '3rem' }}>
                <ImgView data={communityInfo} isLoading={isLoading} imageFlag={imageFlag} />
              </div>
            )}
            {type !== 'STUDY' && (
              <CountBox>
                <LikedCountItem>좋아요 {communityInfo?.likeCount}</LikedCountItem>
                <CommentCountItem>댓글 {communityInfo?.commentCount}</CommentCountItem>
              </CountBox>
            )}
          </ContentBox>
          {communityInfo && communityInfo?.isReply && <AdminAnswer data={communityInfo.answer} deviceCheck={deviceCheck} backgroundColor={'#F7F7FC'} />}
          {commentData && (
            <CommentListBox ref={commentListBoxRef}>
              {commentData.map((item, idx) => {
                return <Comment data={item} key={idx} commentRegistTime={commentRegistTime} isLoading={isLoading}></Comment>
              })}
            </CommentListBox>
          )}
          {commentData && <Pagination type={'IDX'} handleCurrentIdx={handleCurrentIdx} totalPages={totalPages} limitPage={5} maxNum={5} minNum={0} pathName={`/community/free/detail/${currentSeq}`} pageIdx={pageIdx} jumpingPrevBtn={true} jumpingNextBtn={true} />}
          <ButtonList type={type} handleCurrentIdx={handleCurrentIdx} currentSeq={currentSeq} boardDetailInfo={boardDetailInfo} param={param} />
        </DetailBoardBox>
      </theme.SmInnerBox>
    </ThemeProvider>
  )
}

const DetailBoardBox = styled.div`
  padding: 8rem 0 12rem;

  h3 {
    padding-bottom: 1.3rem;
    margin-bottom: 2.4rem;
    font-weight: 700;
    font-size: 2rem;
    border-bottom: 3px solid #000;
  }

  .pagination {
    padding: 4rem 0;
    background-color: #f7f7fc;
  }

  .react-loading-skeleton {
    --base-color: #f4f4f4;
    --highlight-color: #f8f8f8;
  }

  @media ${({ theme }) => theme.device.mobile} {
    padding-top: 4.4rem;

    h3 {
      display: none;
    }

    .pagination {
      padding: 8rem 0;
    }
  }
`

const TitleBox = styled.div`
  padding: 0 2.5rem 2.1rem;
  border-bottom: 1px solid #e7e7f0;

  > p {
    display: block;
    position: relative;
    padding-right: 5rem;
    font-weight: 700;
    font-size: 1.6rem;
    color: #a0a0b6;

    strong {
      position: absolute;
      top: 0;
      right: 0;
      color: #17c624;
    }

    + p {
      margin-top: 1rem;
      font-size: 2rem;
      color: #000;
      ${theme.StyleEllipsisTwo}
    }
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2rem;
  }

  @media ${({ theme }) => theme.device.mobile} {
    padding: 0 4.2rem 2.4rem;

    > p {
      font-size: 2.5rem;
      padding-right: 0;

      + p {
        margin-top: 2rem;
        font-size: 4rem;
      }
    }

    > div {
      display: flex;
      flex-direction: column-reverse;
      margin-top: 3.4rem;
    }
  }
`

const ProfileBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  strong {
    margin-left: 1.3rem;
    font-weight: 400;
    font-size: 1.6rem;
  }

  @media ${({ theme }) => theme.device.mobile} {
    margin-top: 3.4rem;
    margin-left: auto;

    strong {
      margin-left: 1.7rem;
      font-size: 2.4rem;
    }
  }
`

const InfoBox = styled.div`
  span {
    font-weight: 400;
    font-size: 1.2rem;
    color: #a0a0b6;

    & + span {
      margin-left: 1rem;
    }
  }

  @media ${({ theme }) => theme.device.mobile} {
    margin-right: auto;

    span {
      font-size: 2.3rem;

      & + span {
        margin-left: 1.4rem;
      }
    }
  }
`

const ContentBox = styled.div`
  padding: 3rem 2.5rem;

  p {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.5;
  }

  @media ${({ theme }) => theme.device.mobile} {
    padding: 5rem 4.2rem;

    p {
      font-size: 2.6rem;
    }
  }
`

const CommentListBox = styled.div`
  margin-top: 1rem;
  border-bottom: 1px solid #e7e7f0;
  background-color: #f7f7fc;

  @media ${({ theme }) => theme.device.mobile} {
    padding: 0 4.2rem;
  }
`

const CountBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 4rem;

  span {
    position: relative;
    font-weight: 700;
    font-size: 1.4rem;
    color: #8d8da0;

    & + span {
      margin-left: 2.6rem;
    }

    &::before {
      content: '';
      display: inline-block;
      width: 2.4rem;
      height: 2.4rem;
      margin-top: -0.2rem;
      margin-right: 0.6rem;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: 100% auto;
      vertical-align: middle;
    }
  }

  @media ${({ theme }) => theme.device.mobile} {
    margin-top: 5rem;

    span {
      font-size: 2rem;

      & + span {
        margin-left: 3.2rem;
      }

      &::before {
        width: 3.8rem;
        height: 3.8rem;
      }
    }
  }
`

const LikedCountItem = styled.span`
  &::before {
    background-image: url(${IcoHeart});
  }

  ${(props) =>
    props.type === 'ACTIVE' &&
    css`
      &::before {
        background-image: url(${IcoHeartRed});
      }
    `}
`

const CommentCountItem = styled.span`
  &::before {
    background-image: url(${IcoComment});
  }
`
