import { useMediaQuery } from 'react-responsive'

import ClassItem from './ClassItem'

export default function ClassList({ title, listData }) {
  const isMobile = useMediaQuery({ maxWidth: 1024 })

  return (
    <>
      <section>
        <ClassItem title={title} listData={listData} />
      </section>
    </>
  )
}
