

export const redirectPromotionLandingPath = {
  '/promotion-landing/applePackage.html': '/promotion-landing/applePackage',
  '/promotion-landing/bestAwards_db_only.html': '/promotion-landing/bestAwardsDBOnly',
  '/promotion-landing/bestAwards.html': '/promotion-landing/bestAwards',
  '/promotion-landing/b2bLanding.html': '/promotion-landing/b2bLanding',
  '/promotion-landing/brain.html': '/promotion-landing/brain',
  '/promotion-landing/curriculum.html': '/promotion-landing/curriculum',
  '/promotion-landing/frontEnglish.html': '/promotion-landing/frontEnglish',
  '/promotion-landing/laptopPackage.html': '/promotion-landing/laptopPackage',
  '/promotion-landing/macbookPackage.html': '/promotion-landing/macbookPackage',
  '/promotion-landing/n_nipowoopo2023.html': '/promotion-landing/n_nipowoopo2024',
  '/promotion-landing/n_nipowoopo2024.html': '/promotion-landing/n_nipowoopo2024',
  '/promotion-landing/scholarShip.html': '/promotion-landing/scholarShip',
  '/promotion-landing/SRRfullpackage.html': '/promotion-landing/SRRfullpackage',
  '/promotion-landing/SRRworkbookbrand.html': '/promotion-landing/SRRworkbookbrand',
  '/promotion-landing/tabPackage_air.html': '/promotion-landing/tabPackageAir',
  '/promotion-landing/tabPackage_air5th.html': '/promotion-landing/tabPackageAir',
  '/promotion-landing/tabPackage10th.html': '/promotion-landing/tabPackage10th'
}
