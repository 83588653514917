import ReactDOM from 'react-dom';

const ModalPortal = ({ children }) => {
  const el = document.getElementById('modal');
  // 20240104 : 모달 스크롤 방지 해제 요청
  // const { scrollLock, scrollUnLock } = useScrollLock();
  // useEffect(() => {
  //   scrollLock();
  //   return () => scrollUnLock();
  // });
  return ReactDOM.createPortal(children, el);
};

export default ModalPortal;
