import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import { useState, useEffect } from 'react'
export default function SortSelectBox({ setSort, sort }) {
  const [list, setList] = useState([])

  const sortEng = (list) => {
    switch (list) {
      case '최신순':
        return 'RECENT'
      default:
        return 'POPULAR'
    }
  }
  const sortOnClick = (e, sortList) => {
    setSort(sortEng(sortList))
  }
  useEffect(() => {
    setList(['인기순', '최신순'])
  }, [])
  const location = useLocation()

  // 현재 URL 경로를 가져온다
  const currentPath = location.pathname

  // 전체보기 페이지('/store/list')에서는 컴포넌트를 표시하지 않는다
  if (currentPath === '/store/list') {
    return null
  }
  return (
    <>
      <SelectBox>
        {list.map((item) => (
          <button key={item} onClick={(e) => sortOnClick(e, item)} className={sort === sortEng(item) ? 'active' : ''}>
            {item}
          </button>
        ))}
      </SelectBox>
    </>
  )
}
const SelectBox = styled.div`
  display: flex;

  button {
    display: flex;
    font-weight: 500;
    font-size: 1.4rem;
    color: #b7b7c8;
    align-items: flex-end;

    :first-child {
      ::after {
        content: '';
        display: inline-block;
        width: 0.1rem;
        height: 1.6rem;
        margin-right: 1.8rem;
        margin-left: 1.8rem;
        background: #cfcfda;
      }
    }
  }
  .active {
    font-weight: 700;
    color: #000;
  }

  @media ${({ theme }) => theme.device.mobile} {
    button {
      font-size: 2.4rem;
      align-items: center;
      :first-child {
        ::after {
          width: 0.2rem;
          height: 1.8rem;
          margin-right: 1.4rem;
          margin-left: 2.4rem;
        }
      }
    }
  }
`
