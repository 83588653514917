import { YANADOO_CDN_HOST } from 'common/config'

export const IPAD_DOUBLE_HOST_CDN = `${YANADOO_CDN_HOST}/promotion/ipadDouble/`

export const ipadDoubleValues = {
  metaInfo: {
    title: 'BEST 패키지 깜짝 앵콜! iPad 10 더블 득템',
    description: '하루 10분 야나두 영어, iPad 10세대와 함께 평생수강하세요!',
    imgsrc: `${YANADOO_CDN_HOST}/banner/img_ipadDouble_og.png`,
    site_name: '하루 10분 야나두'
  },
  solution: {
    1: {
      index: 1,
      title: (
        <>
          영어 초보에 딱 맞는 <b>최적의 학습 시간 ‘10분’</b>
        </>
      ),
      description: '많은 논문과 과학적 근거가 증명했어요',
      background: '#f3f4f6'
    },
    2: {
      index: 2,
      classname: 'solutionSection02',
      title: (
        <>
          저절로 영어가 되는
          <b>체계적인 ‘커리큘럼’</b>
        </>
      ),
      description: '기초 이론부터 회화까지 단번에 끝',
      background: '#fff'
    },
    3: {
      index: 3,
      classname: 'solutionSection03',
      title: (
        <>
          말하다보면 영어가 툭<b>AI 친구 ‘AI 원어민톡’</b>
        </>
      ),
      description: '10년 누적 170만 수강생 데이터 기반',
      background: '#f3f4f6'
    },
    4: {
      index: 4,
      classname: 'solutionSection04',
      title: (
        <>
          완강률 3배 오르는
          <b>동기부여 학습 장학금</b>
        </>
      ),
      description: '바쁜 직장인도 끝까지 할 수 있도록',
      background: '#fff'
    }
  },
  solution1Slide: [
    {
      title: '오늘 강의 들으며 표현을 익히고',
      src: 'https://english.yanadoocdn.com/upload/yanadoo/new/promotion/bestawards/2023/dec/renew/img_pc_point02.gif'
    },
    {
      title: 'AI 트레이닝으로 실전 감각 UP',
      src: 'https://english.yanadoocdn.com/upload/yanadoo/new/promotion/bestawards/2023/dec/renew/img_pc_point0202.gif'
    },
    {
      title: '틈새 단어 앱으로 꼼꼼히 단어 암기',
      src: 'https://english.yanadoocdn.com/upload/yanadoo/new/promotion/bestawards/2023/dec/renew/img_pc_point0203.gif'
    },
    {
      title: '1:1 Q&A로 헷갈리는 내용까지 해결',
      description: '*주말 문의는 월요일부터 순차 답변해드립니다',
      src: 'https://english.yanadoocdn.com/upload/yanadoo/new/promotion/bestawards/2023/dec/renew/img_mo_point0204.png'
    }
  ],
  systemList: [2, 1, 1, 2, 1, 1],
  reviewList: [
    {
      name: '티는놈',
      date: '2024.04',
      summary: (
        <>
          안녕하세요~ 직장에서 업무 효율성을 위해 영어공부를 시작하게 됐습니다.
          <b>직장인이라 학원 다니기도 쉽지 않고,</b> 검색을 하다보니 야나두를 알게 되어 등록을 해서
          하루도 빠짐없이 공부했네요. 처음부터...
        </>
      ),
      description: (
        <>
          안녕하세요~ 직장에서 업무 효율성을 위해 영어공부를 시작하게 됐습니다.
          <b>직장인이라 학원 다니기도 쉽지 않고,</b> 검색을 하다보니 야나두를 알게 되어 등록을 해서
          하루도 빠짐없이 공부했네요. 처음부터 차근차근 배우다보니,
          <b>이제는 어느정도 감이 오는거 같네요.</b> 문법 순서도 조금은 이해가 가고요. 영어라는게
          단기간에 할수 있는게 아니다보니 꾸준히 계속 해볼겁니다~ 감사합니다~
        </>
      )
    },
    {
      name: '유니앙마',
      date: '2024.05',
      summary: (
        <>
          나이가 들어 다시 시작하는 영어라 처음에는 두려움도 있었고 중간에 하다가 못하진 않을까라는
          걱정이 앞서 있어지만 <b>66일동안 빠지지 않고 꾸준하게 한</b> 나 자신이 대견도 하고 아직
          시작점이긴 하지만 더 해볼 용기...
        </>
      ),
      description: (
        <>
          나이가 들어 다시 시작하는 영어라 처음에는 두려움도 있었고 중간에 하다가 못하진 않을까라는
          걱정이 앞서 있어지만 <b>66일동안 빠지지 않고 꾸준하게 한</b> 나 자신이 대견도 하고 아직
          시작점이긴 하지만 <b>더 해볼 용기</b>가 생겼습니다. 야나두 선생님들의 좋은 가르침으로
          영어라는 벽들이 미세하지만 금이 가고 있습니다. 좀더 분발해 보겠습니다~
        </>
      )
    },
    {
      name: '유긍정2',
      date: '2024.04',
      summary: (
        <>
          영어공부가 필요하다는건 알겠는데 <b>워킹맘</b> 이다보니 늘 시간이 부족하다는 핑계로 미뤄만
          오다가 큰 맘먹고 시작하게 되었어요. 우선 10분 내 강의라 부담이 적었고 근무 시작 전 잠시
          짬내서 하고 있어요. 중...
        </>
      ),
      description: (
        <>
          영어공부가 필요하다는건 알겠는데 <b>워킹맘</b> 이다보니 늘 시간이 부족하다는 핑계로 미뤄만
          오다가 큰 맘먹고 시작하게 되었어요. 우선
          <b> 10분 내 강의라 부담이 적었고 근무 시작 전 잠시 짬내서 하고 있어요.</b> 중간중간
          고비가? 있었는데 장학금 미션으로 끝까지 잘 완료 할 수 있었고
          <b>루틴이 만들어 진거 같아 너무 좋네요~</b>
          이저도 해외여행 다닐때 자신있게 다니고 싶어요!!!ㅋㅋ 그때까지 파이팅~!!!!
        </>
      )
    },
    {
      name: '태양의나라',
      date: '2024.05',
      summary: (
        <>
          <b>학교에서 근무</b>하고 있습니다. 원어민 교사만 보면 웃기만 하는 제 모습이 너무 한심해서
          늦었지만 영어 공부를 시작해야겠다고 생각했습니다. 각 잡고 앉아 공부하는 것에 익숙한 제가
          '10분으로 공부가 되...
        </>
      ),
      description: (
        <>
          <b>학교에서 근무</b>하고 있습니다. 원어민 교사만 보면 웃기만 하는 제 모습이 너무 한심해서
          늦었지만 영어 공부를 시작해야겠다고 생각했습니다. 각 잡고 앉아 공부하는 것에 익숙한 제가
          <b>'10분으로 공부가 되겠어?'라는 생각에 반신반의하며 공부를 시작했는데 되더라구요.</b>
          목표는 회식 때 원어민 교사와 간단하나마 대화 나누며 식사하는 것입니다.
          <b>조금씩 뱉어지는 것 보니 곧 목표를 이룰 것 같아요.</b>
        </>
      )
    },
    {
      name: '똑똑한 시리우스 1491',
      date: '2024.04',
      summary: (
        <>
          간단한 영어회화라도 해보고 싶은 마음에 시작하게 되었는데, 일상의 일부분이 되면서
          <b>하루하루 성취감을 느낄 수 있었다는게 너무 좋았습니다.</b> ‘내가 할 수 있을까?’라는
          계속된 의심에서 반복 수강하고 복습하다보니 자...
        </>
      ),
      description: (
        <>
          간단한 영어회화라도 해보고 싶은 마음에 시작하게 되었는데, 일상의 일부분이 되면서
          <b>하루하루 성취감을 느낄 수 있었다는게 너무 좋았습니다.</b> ‘내가 할 수 있을까?’라는
          계속된 의심에서 <b>반복 수강하고 복습하다보니 자연스레 습득되었고</b> 역시 영어에 계속
          노출되는게 효과적이라는걸 느낄 수 있었습니다. 습관은 잡혔으니 지속해서 1일 1수강을 해보며
          더 많은 표현들을 얻어 갈 예정입니다 ! 😃
        </>
      )
    }
  ],
  dbModal: {
    pCategory: 'ipadDouble',
    eventName: 'ipadDouble',
    formName: 'ipadDoubleForm',
    btnBg: `url(${IPAD_DOUBLE_HOST_CDN}bg-modal-button.png)`,
    banner: { src: `${IPAD_DOUBLE_HOST_CDN}img-db-modal.png`, width: 550, height: 240 }
  },
  modalButton: {
    buttonColor: '#222',
    buttonBackground: `url(${IPAD_DOUBLE_HOST_CDN}img-btn-bg.png) center / cover`
  },
  precaution: {
    title: '아이패드 더블득템 유의사항',
    list: [
      {
        title: ['[상품구성]'],
        text: [
          '① 본 상품은 태블릿PC와 영어 콘텐츠가 포함된 패키지 상품입니다.',
          "② 본 상품은 '입문회화, 앞자리영어 1· 2, 마법패턴 1· 2, 리얼스피킹, 1mm 스피킹, 1분 원샷 영어, 5분 발음 교정, 리얼 영단어, 리얼 영작문, 써먹는 VOCA 입문/초급, 90일 패턴 쉐도잉, 리얼 대화영어 입문/초급, 이디엄이 뭔대영, 콩글리시 타파하기, 리얼 밀당발음, 리얼 리듬영어, 진짜 날라리영어, 리얼 몰입 영어, 리얼 데일리 영어, 오트립, 동남아에서 먹히는 실전영어 방콕편, 여행영어, 리얼 컬쳐톡, 컬쳐톡: 중동&유럽편, 컬쳐톡: 미국편, 리얼 세계영어, 리얼 아메리칸 영어, 비즈니스 응급키트, 비즈니스 영어 스킬업, 야나두 오디오 영어, 스토리북, 영어동화, 세계명작, 미국교과서' 콘텐츠가 옵션 별로 포함된 '패키지입니다.",
          '③ 본 상품의 전체 혜택은 고객의 요청으로 연장되거나, 조기에 마감될 수 있습니다.',
          '④ 패키지 별 구성은 사전고지 없이 제외 또는 변경 될 수 있습니다.',
          '⑤ 상황에 따라 사전 공지 없이 할인이 조기 마감 되거나 연장될 수 있습니다.',
          '⑥ 이벤트, 프로모션 등으로 판매가가 변동될 수 있으며, 이로 인한 환불이나 가격 보상은 불가합니다.',
          '⑦ 제공되는 상품 및 서비스는 회사 사정에 따라 변경 또는 제외 될 수 있습니다.'
        ]
      },
      {
        title: ['[사용방법 및 기간]'],
        text: [
          '① 본 상품의 학습은 구매결제 후 야나두 모바일 앱을 설치 및 회원가입 후 이용할 수 있습니다.',
          '② 본 상품의 영어 학습 유효기간은 평생수강 연장 안내에 따른 조건 달성시 1년씩 연장 가능하며, 평생수강 연장 조건 미달성시 1회에 한하여 1년의 연장기회를 제공합니다.'
        ]
      },
      {
        title: ['[기기 및 사은품 기본 정보]'],
        text: [
          '① 애플 아이패드 10세대 : iPad 10세대 WI-FI 64GBㅣ본체+충전케이블+전원 어댑터 포함ㅣ색상: 랜덤',
          '② 사은품 : 기본 제공되는 사은품은 트레이닝북이며 고객이 선택한 옵션에 따라 야나두 피트니스 강의, 야나두 클래스 강의, 리얼스피킹 5권이 사은혜택으로 제공됩니다.',
          '③ 휴일 및 연휴 일정에 따라 배송이 지연 되는 경우 별도 안내 드립니다.',
          '④ 제공되는 기기는 재고 수량에 따라 사전 동의 후 다른 기종으로 대체될 수 있습니다.'
        ]
      },
      {
        title: ['[배송안내]'],
        text: [
          '① 교재는 결제일로부터 순차적으로 배송되며 최대 1주 이내 수령 가능합니다. 기기는 결제일로부터 순차적으로 배송되며 최대 4~6주 이내 수령 가능합니다. 단, 도서, 산간지역의 경우 배송기간이 더 소요될 수 있습니다.',
          '② 고객님이 선택한 사은품은 기기 및 교재와 별도로 배송되며 결제일로부터 순차적으로 배송되어 최대 4~6주 이내 수령 가능합니다. 단, 도서, 산간지역의 경우 배송기간이 더 소요될 수 있습니다.',
          '③ 제품 수령지 미변경으로 인한 분실/배송비 부담에 대해서는 책임지지 않습니다.'
        ]
      },
      {
        title: ['[전자 기기 AS 안내]'],
        text: [
          `① 전자 기기 제품의 A/S는 각 제조사의 정책을 따릅니다.\n– 애플 고객지원 센터: https://support.apple.com/ko-kr`,
          `② 이외에 기기를 통한 수강 불편사항은 야나두 고객센터로 문의 바랍니다.\n 1. 운영시간 : 평일 09:00 ~ 18:00\n 2. 대표번호 : 1600–0563`
        ]
      },
      {
        title: ['[야나두 전자기기 환불 규정]'],
        text: [
          '① 교환, 반품 시 수령 후 7일 이내 신청해 주셔야 하며, 왕복 배송 비용(특수 배송비 7,000원)를 부담하셔야 합니다. (초기제품 불량 시에도 수령 후 7일 이내)',
          '② 수령 후 7일 이후(전자상거래법에 의거 청약철회 가능시기는 7일 이내) 또는 상품을 개봉 또는 사용(훼손)한 경우(가령, 오염, 파손, 외관손상, 포장훼손, 사용기록 등)에는 환불이 불가합니다.',
          '③ 전자기기를 제외하고 환불을 진행할 경우, 기기의 소비자가격을 기준으로 금액을 공제한 후 야나두 환불정책에 따라 환불이 진행됩니다.',
          '④ 반송된 제품을 확인하여 제품 검수(개봉 여부 및 포장상태 확인 등) 후 최종 환불이 완료됩니다. (제품 반송 후 최대 10일 소요)',
          '⑤ 초기 제품 불량일 경우에라도 제품 흠집이나 훼손, 포장박스 분실 시 반품이 불가하거나 수수료를 부담해야 합니다.',
          '⑥ 초기 제품 불량으로 인한 상품 교환시, 동일 상품으로만 가능합니다. (타 상품 교환 불가)',
          '⑦ 제품 보증기간은 각 제조사의 품질 보증기간 약관 및 법률에 따릅니다.'
        ]
      },
      {
        title: ['[야나두 틈새단어 유료앱 설치 및 이용 방법]'],
        text: [
          '① 상품 구매 및 수강 시작하기',
          '② 마이 클래스에서 카드 선택 및 스터디 알람 설정 후 수강 시작하기',
          '③ 구글/앱스토어 통해 “틈새단어” 앱 다운받기\n– 다운로드 https://bit.ly/3s8yDs6 \n– 각 앱 마켓에서 “틈새단어” 검색하여 찾기 가능',
          '④ 틈새단어 앱에서 야나두 ID로 로그인',
          '⑤ 야나두 계정과 매칭 하여 유료 버전의 챕터 이용 가능 틈새단어 유료앱은 해당 상품의 유효 수강기간 동안 사용 가능하며, 기간종료 시 무료버전으로 전환'
        ]
      },
      {
        title: ['[84,000원 장학금 참여 방법]'],
        text: [
          '① 야나두 앱 로그인하기',
          "② '장학금 미션' 클릭하기",
          "③ '장학금 유의사항' 확인 후 참여하기"
        ]
      },
      {
        title: ['[AI 원어민톡 참여 방법]'],
        text: [
          '① 야나두 앱 로그인하기',
          "② 우측 상단의 'AI' 아이콘을 클릭",
          '③ 원하는 AI 친구와 대화를 시작하세요.'
        ]
      },
      {
        title: ['[평생수강 연장 안내]'],
        text: [
          '① 평생수강 연장 대상: 평생수강 클래스 수강생',
          '② 평생수강 연장 방법 : 누적 출석일 77일 이상 달성 시(365일 기준), 종료 한 달 전 연장 안내 팝업에서 ‘연장하기’ 클릭 시 연장 적용',
          '③ 출석일 초기화 안내 : 수강기간이 연장된 경우, 연장 된 수강기간 종료일 기준 365일 전 출석일 수 초기화 (매년 동일한 방법으로 연장)',
          '④ 매년 연장된 수강기간 종료일 전까지 77일 출석 조건을 만족하는 경우 평생 동안 연장 가능합니다.',
          '⑤ 혜택으로 지급되는 추가강의는 평생수강 대상에서 제외됩니다.'
        ]
      },
      {
        title: ['[평생수강 패키지 환불안내]'],
        text: [
          `① 평생수강 패키지 환불 정책은 야나두 패키지 환불 정책을 따릅니다.
          - 기본 학습 기간은 수강기간 3개월(90일) + 복습기간 9개월(275일) 총 12개월(1년)입니다.
          - 환불금액은 수강기간 3개월(90일)을 기준으로 책정합니다.
          - 야나두 장학금을 받은 경우, 최종 환불 금액에서 장학금 지급 금액이 추가 차감됩니다.`,
          '② 수강시작 후 7일 이내, 5강 미만 수강 시에는 100% 환불 가능합니다.',
          '③ 수강시작 후 7일 초과, 5강 이상 수강 시에는 잔여일에 대한 환불이 가능합니다.',
          `④ 반품 및 환불 시 사은품을 포함한 모든 구성 상품은 개봉/사용하지 않은 상태로 반환해야 하며, 반품 배송비(상품 및 사은품별 별도 책정됨)는 소비자 부담입니다.
          아이패드 반품배송비 : 7,000원
          트레이닝북, 리얼스피킹 5권 세트 반품배송비 : 6,000원`,
          `⑤ 환불 시, “야나두 환불규정”에 따라 환불금액에서 사은품 비용이 별도 청구됩니다.
          1) 아이패드 : 제품 수령 후 7일 이후 또는 개봉 시 환불 불가
           - 아이패드 10세대 환불차감액 529,000원
          2) 사은품(교재) : 구매 후 15일 이후 또는 제품 훼손시 환불 불가
          - 트레이닝북 환불차감액: 16,000원
          - 리얼스피킹 5권 세트 환불차감액 : 45,000원`,
          '⑥ 사은품 구성은 달라질 수 있으며, 사은품만 별도로 환불할 수 없습니다.',
          '⑦ 더 자세한 환불 정책 및 정산 방법 등은 [환불 규정]을 참고해 주시기 바랍니다.',
          '⑧ 환불과 관련된 자세한 문의사항은 고객센터 1600–0563번으로 문의 주시기 바랍니다'
        ]
      }
    ]
  }
}
