import React from "react";
import "../assets/styles/btnMore.scss";

export default function BtnMore({
  type,
  dataFunction,
  currentIdx,
  sizeNum,
  packageSeq,
  btnName,
}) {
  // type: 더보기 기능 - 페이징 or 리스트 show
  // dataFunction: 버튼 클릭시 실행되어야 하는 함수
  // currentIdx: 페이징 기능일시 현재 인덱스 값
  // 페이징 처리 함수 예제 - ItemApply.jsx
  // 리스트 show 처리 함수 예제 - Composition.jsx
  // sizeNum : 더보기 클릭 시 보여주고 싶은 리스트 개수
  // btnName : 버튼명, 없을 경우 "더보기"

  return (
    <div className="list-more-box">
      {type === "PAGINATION" ? (
        <button
          type="button"
          className="btn btn-more"
          onClick={(e) => dataFunction(currentIdx + 1, sizeNum, packageSeq)}
        >
          <span>{btnName || "더보기"}</span>
        </button>
      ) : (
        <button
          type="button"
          className="btn btn-more"
          onClick={(e) => dataFunction()}
        >
          <span>{btnName || "더보기"}</span>
        </button>
      )}
    </div>
  );
}
