import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const SkeletonMallList = () => {
  const deviceCheck = useMediaQuery({ maxWidth: 1024 }) ? true : false;
  const [isDeviceCheck, setDeviceCheck] = useState(false);
  useEffect(() => {
    setDeviceCheck(deviceCheck);
  }, [deviceCheck]);
  return (
    <>
      <Skeleton height={isDeviceCheck ? '20rem' : '28rem'} />
      <SkeletonList>
        <h1>
          <Skeleton />
        </h1>
        <ul>
          <li>
            <Skeleton height={isDeviceCheck ? '20rem' : '28rem'} />
          </li>
          <li>
            <Skeleton height={isDeviceCheck ? '20rem' : '28rem'} />
          </li>
          <li>
            <Skeleton height={isDeviceCheck ? '20rem' : '28rem'} />
          </li>
          <li>
            <Skeleton height={isDeviceCheck ? '20rem' : '28rem'} />
          </li>
          <li>
            <Skeleton height={isDeviceCheck ? '20rem' : '28rem'} />
          </li>
          <li>
            <Skeleton height={isDeviceCheck ? '20rem' : '28rem'} />
          </li>
          <li>
            <Skeleton height={isDeviceCheck ? '20rem' : '28rem'} />
          </li>
          <li>
            <Skeleton height={isDeviceCheck ? '20rem' : '28rem'} />
          </li>
        </ul>
      </SkeletonList>
    </>
  );
};

export default SkeletonMallList;

const SkeletonList = styled.div`
  display: block;
  width: 100%;
  max-width: 120rem;
  margin: 3rem auto;
  padding: 0 3rem;
  box-sizing: border-box;
  h1 {
    width: 40rem;
    margin-bottom: 2rem;
    font-size: 3rem;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    li {
      width: calc(25% - 1rem);
      padding: 1rem 0;
      box-sizing: border-box;
    }
  }
`;
