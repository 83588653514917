import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router'

import useGetClass from 'hooks/useGetClass'
import AuthService from 'services/authService'

import api from 'common/api'
import * as config from 'common/config'

export default function useStoreDetail() {
  const { packageSeq } = useParams()
  const [isSticky, setIsSticky] = useState(false)
  const [isEntrancedUser, setIsEntrancedUser] = useState(false) // 입학 여부
  const [isEntrancedCheck, setIsEntrancedCheck] = useState(false) // 입학 체크여부

  const detailWrapRef = useRef(null)
  const userInfo = AuthService.getUserInfo()

  const { isLoading, classData, getClass } = useGetClass()

  const getUserInfo = () => {
    if (AuthService.getUserInfo()) {
      api
        .get(config.AUTH_API_HOST + '/auth/v2/user')
        .then((response) => {
          setIsEntrancedUser(response.data.data?.entrance?.entranceStatus === 'ENTRANCE')
          setIsEntrancedCheck(true)
        })
        .catch((e) => {
          console.log(e)
        })
    }
  }

  const onScrollToTop = () => {
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: 'smooth'
    })
  }

  const onChangeIsSticky = (is) => {
    setIsSticky(is)
  }

  useEffect(() => {
    if (detailWrapRef.current !== null && isLoading && classData) {
      let pArr = detailWrapRef.current.children
      Object.entries(pArr).forEach(([key, value]) => {
        if (value && value.innerHTML === '&nbsp;') {
          value.classList.add('empty_block')
        }
      })
    }
  }, [isLoading, detailWrapRef, classData])

  useEffect(() => {
    if (packageSeq) {
      getClass(packageSeq)
    }
  }, [packageSeq])

  useEffect(() => {
    getUserInfo()
  }, [])

  return {
    isEntrancedUser,
    isEntrancedCheck,
    userInfo,
    classData,
    isLoading,
    isSticky,
    onScrollToTop,
    onChangeIsSticky
  }
}
