import React from 'react'

import styled, { ThemeProvider } from 'styled-components'

import theme from 'common/theme'

import ico_mall_error from '../../assets/images/ico_mall_error.png'

const MallError = ({ comment, fontColor = '#444' }) => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <ErrorUI fontColor={fontColor}>
          <p>
            <strong>ERROR</strong>
            {comment}
          </p>
        </ErrorUI>
      </ThemeProvider>
    </>
  )
}

export default MallError

const ErrorUI = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 76rem;
  p {
    padding-bottom: 3rem;
    font-weight: 700;
    font-size: 2rem;
    color: ${(props) => props.fontColor};
    text-align: center;
    line-height: 1.6em;
    strong {
      display: block;
      width: 9.5rem;
      height: 9.5rem;
      margin: 0 auto 0.6rem;
      background: url(${ico_mall_error}) no-repeat 50% 0 / 100% auto;
      text-indent: -9999rem;
    }
    @media ${({ theme }) => theme.device.mobile} {
      font-size: 3.2rem;
      strong {
        width: 13rem;
        height: 13rem;
        margin: 0 auto 2rem;
      }
    }
  }
`
