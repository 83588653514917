import { useEffect } from 'react'

export default function MobileHeader({ title, onClick }) {
  useEffect(() => {
    document.querySelector('header').classList.add('header-overflow')
    return () => {
      document.querySelector('header').classList.remove('header-overflow')
    }
  }, [])
  return (
    <div className="header-prev header-line">
      <button type="button" onClick={onClick}>
        뒤로가기
      </button>
      <strong>{title}</strong>
    </div>
  )
}
