import api from 'common/api'
import { API_HOST } from 'common/config'

export default function useConsultation() {
  /**
   * 비로그인 유저 - 내부 상담 form post
   * @param formData
   * @param callback : 상담완료 후 실행할 callback
   */
  const onPostConsultation = (formData, callback) => {
    api
      .post(`${API_HOST}/v2/inquiry/consultation/tel`, JSON.stringify(formData))
      .then((res) => {
        const message = res.data.meta.message
        if (message === 'SUCCESS') {
          callback(res)
        } else {
          alert('통신장애가 발생되었습니다.')
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
  /**
   * 비로그인 유저 - 내부 상담 form post
   * @param formData
   * @param callback : 상담완료 후 실행할 callback
   */
  const onPostUserConsultation = (formData, callback) => {
    api
      .post(`${API_HOST}/v2/inquiry/consultation/tel/user`, JSON.stringify(formData))
      .then((res) => {
        const message = res.data.meta.message
        if (message === 'SUCCESS') {
          callback(res)
        } else {
          alert('통신장애가 발생되었습니다.')
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return { onPostConsultation, onPostUserConsultation }
}
