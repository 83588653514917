import EditInfo from 'pages/member/EditInfo'
import Expiration from 'pages/member/Expiration'
import JoinOverseasPage from 'pages/member/JoinOverseasPage'
import JoinPage from 'pages/member/JoinPage'
import MemberAuth from 'pages/member/MemberAuth'
import MemberDrop from 'pages/member/MemberDrop'
import NonMember from 'pages/member/NonMember'
import { Member } from 'router/OutletStore'

import PrivateRoute from 'common/PrivateRoute'

const MemberRoutes = [
  {
    path: 'member/*',
    element: <Member />,
    children: [
      { path: 'join/:type', element: <JoinPage /> },
      { path: 'join/overseas', element: <JoinOverseasPage /> },
      {
        path: 'drop',
        element: (
          <PrivateRoute>
            <MemberDrop />
          </PrivateRoute>
        )
      },
      {
        path: 'auth',
        element: (
          <PrivateRoute>
            <MemberAuth />
          </PrivateRoute>
        )
      },
      { path: 'expiration', element: <Expiration /> },
      {
        path: 'nonmember',
        element: (
          <PrivateRoute>
            <NonMember />
          </PrivateRoute>
        )
      },
      {
        path: 'editinfo',
        element: (
          <PrivateRoute>
            <EditInfo />
          </PrivateRoute>
        )
      }
    ]
  }
]

export default MemberRoutes
