import React, { useRef, useEffect } from 'react'

import styled from 'styled-components'

import icoCheck_36x21 from '../../assets/images/ico_check_36x21.png'
import ico_search_100x100 from '../../assets/images/ico_search_100x100.png'
import { scrollMove } from '../../common/common'
import YndButton from '../common/YndButton'

export default function BoardSearch({
  searchTypeList,
  setSearchType,
  setSearchWord,
  setPageIndex,
  searchWord
}) {
  const refInput = useRef()
  const refSelect = useRef()
  const searchPush = () => {
    setSearchType(refSelect.current.value)
    setSearchWord(encodeURIComponent(refInput.current.value))
    setPageIndex(1)
    scrollMove(0, 0)
  }

  useEffect(() => {
    refInput.current.value = decodeURIComponent(searchWord)
  }, [searchWord])
  return (
    <>
      <SearchSection>
        {searchTypeList && searchTypeList.length > 0 ? (
          <select ref={refSelect}>
            {searchTypeList.map((item, idx) => {
              return (
                <option key={idx} value={item.searchType}>
                  {item.description}
                </option>
              )
            })}
          </select>
        ) : null}
        <input type="text" ref={refInput} placeholder="검색어를 입력해주세요." />
        <YndButton name="검색" size="md" btnTheme="dark" onClick={() => searchPush()} />
      </SearchSection>
    </>
  )
}

const SearchSection = styled.section`
  display: flex;
  justify-content: center;
  padding: 0;
  select {
    display: inline-block;
    width: 13.8rem;
    margin-right: 1rem;
    padding: 0.5rem 1.5rem;
    font-weight: 400;
    font-size: 1.4rem;
    color: #000;
    border: 0.1rem solid #dfdfea;
    background: #fff url(${icoCheck_36x21}) calc(100% - 1.3rem) 50% / 1.2rem auto no-repeat;
  }
  input {
    width: 28.6rem;
    height: 3rem;
    padding: 0 1.6rem;
    font-size: 1.4rem;
    background-color: #fff;
    border: 0.1rem solid #dfdfea;
    box-sizing: border-box;
    ::placeholder {
      display: block;
      position: absolute;
      color: #b7b7c8;
    }
  }
  button {
    margin-left: 1rem;
  }
  @media ${({ theme }) => theme.device.mobile} {
    justify-content: flex-start;
    align-items: center;
    margin: 4.2rem;
    padding: 1rem;
    background-color: #f7f7fc;
    box-sizing: border-box;
    select {
      width: 18rem;
      padding: 0.5rem 2.7rem;
      font-size: 2.9rem;
      background-color: transparent;
      background-size: 2rem auto;
      border: 0;
    }
    input {
      flex: 1 1 auto;
      height: auto;
      padding: 2.6rem 2rem;
      font-size: 2.9rem;
      background-color: transparent;
      border: 0;
      ::placeholder {
        position: relative;
        padding-top: 0;
      }
    }
    button {
      width: 8rem;
      height: 8rem;
      text-indent: -9999px;
      background: url(${ico_search_100x100}) no-repeat 50% 50% / 6rem auto;
    }
  }
`
