import React from 'react';
import { ThemeProvider } from 'styled-components';
import styled, { css } from 'styled-components';
import theme from 'common/theme';

export default function LikedButton({
  onToggleLike,
  liked, // false: 빈하트, liked: 눌린하트, active: 모션하트
}) {
  return (
    <ThemeProvider theme={theme}>
      <HeartButton
        onClick={() => {
          onToggleLike();
        }}
        likeType={liked}
      >
        <span>좋아요</span>
      </HeartButton>
    </ThemeProvider>
  );
}

const HeartButton = styled.button`
  flex: 0 auto;
  position: relative;
  font-weight: 700;
  font-size: 1.6rem;
  color: #8d8da0;
  background-repeat: no-repeat;
  background-position: 0 45%;
  background-size: 6rem auto;
  cursor: pointer;

  &::before {
    content: '';
    display: inline-block;
    width: 6rem;
    height: 6rem;
    margin-top: -0.2rem;
    background-image: url(https://english.yanadoocdn.com/upload/yanadoo/new/webview/scholarship/img_scholarship_like_active_v2.png);
    background-position: 0 -6rem;
    background-size: 100% auto;
    vertical-align: middle;
  }

  span {
    display: inline-block;
    margin: 0 0 0 -1rem;
    vertical-align: middle;
  }

  ${(props) => {
    if (props.likeType === 'active') {
      return css`
        &::before {
          animation: linkActive 1s linear forwards;
          animation-timing-function: steps(1);
        }
      `;
    } else if (props.likeType === 'liked') {
      return css`
        &::before {
          background-position: 50% 100%;
          animation: none;
        }
      `;
    }
  }}

  @keyframes linkActive {
    0% {
      background-position: 0 -6rem;
    }
    4% {
      background-position: 0 -6rem;
    }
    8% {
      background-position: 0 -12rem;
    }
    12% {
      background-position: 0 -18rem;
    }
    16% {
      background-position: 0 -24rem;
    }
    20% {
      background-position: 0 -30rem;
    }
    24% {
      background-position: 0 -36rem;
    }
    28% {
      background-position: 0 -42rem;
    }
    32% {
      background-position: 0 -48rem;
    }
    36% {
      background-position: 0 -54rem;
    }
    40% {
      background-position: 0 -60rem;
    }
    44% {
      background-position: 0 -66rem;
    }
    48% {
      background-position: 0 -72rem;
    }
    52% {
      background-position: 0 -78rem;
    }
    56% {
      background-position: 0 -84rem;
    }
    60% {
      background-position: 0 -90rem;
    }
    64% {
      background-position: 0 -96rem;
    }
    68% {
      background-position: 0 -102rem;
    }
    72% {
      background-position: 0 -108rem;
    }
    76% {
      background-position: 0 -114rem;
    }
    80% {
      background-position: 0 -120rem;
    }
    84% {
      background-position: 0 -126rem;
    }
    100% {
      background-position: 0 -126rem;
    }
  }

  @media ${({ theme }) => theme.device.mobile} {
    font-size: 2.2rem;

    span {
      margin: 0 0 0 -2rem;
    }

    &::before {
      width: 8rem;
      height: 8rem;
      margin-top: 0;
      background-position: 0 0;
    }

    @keyframes linkActive {
      0% {
        background-position: 0 0;
      }
      4% {
        background-position: 0 -8rem;
      }
      8% {
        background-position: 0 -16rem;
      }
      12% {
        background-position: 0 -24rem;
      }
      16% {
        background-position: 0 -32rem;
      }
      20% {
        background-position: 0 -40rem;
      }
      24% {
        background-position: 0 -48rem;
      }
      28% {
        background-position: 0 -56rem;
      }
      32% {
        background-position: 0 -64rem;
      }
      36% {
        background-position: 0 -72rem;
      }
      40% {
        background-position: 0 -80rem;
      }
      44% {
        background-position: 0 -88rem;
      }
      48% {
        background-position: 0 -96rem;
      }
      52% {
        background-position: 0 -104rem;
      }
      56% {
        background-position: 0 -112rem;
      }
      60% {
        background-position: 0 -120rem;
      }
      64% {
        background-position: 0 -128rem;
      }
      68% {
        background-position: 0 -136rem;
      }
      72% {
        background-position: 0 -144rem;
      }
      76% {
        background-position: 0 -152rem;
      }
      80% {
        background-position: 0 -160rem;
      }
      84% {
        background-position: 0 -168rem;
      }
      100% {
        background-position: 0 -168rem;
      }
    }
  }
`;
