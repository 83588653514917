import { YANADOO_CDN_HOST } from 'common/config'

export const MACBOOK_PACKAGE_HOST_CDN = `${YANADOO_CDN_HOST}/promotion/macbookPackage/`

export const metaInfoValues = {
  title: '국민 영어회화 야나두의 하루 10분 영어',
  description: '야나두가 준비한 특별한 혜택! MacBook Air 패키지',
  site_name: 'MacBook Air 패키지로 기초부터 실전까지 영어 실력 업그레이드하세요!',
  imgsrc: `${YANADOO_CDN_HOST}/banner/img_macbook_og_v2.png`
}

export const keyvisualBannerValues = {
  backgroundColor: '#2371ff',
  maxWidth: 1024,
  pc: `${YANADOO_CDN_HOST}/promotion/etc/img_cool-summer_pc.png`,
  mo: `${YANADOO_CDN_HOST}/promotion/etc/img_cool-summer_m.png`
}

export const reviewSlideValues = {
  title: '10분 영어로 말문이 트인',
  description: '회원님들의 성원에 힘입어 준비했어요',
  background: '#6511ff'
}

export const benefitOneListValues = [
  {
    id: 1,
    cordingSrc: `${MACBOOK_PACKAGE_HOST_CDN}2023/july/img_pc_macbook_one_before_1.png`,
    alt: '파이썬 이미지',
    description: `세상 어디에도 없는\n그림으로 배우는 쉬운 파이썬`,
    afterSrc: `${MACBOOK_PACKAGE_HOST_CDN}2023/july/img_pc_macbook_one_1_v2.png`,
    afterSrcM: `${MACBOOK_PACKAGE_HOST_CDN}2023/july/img_m_macbook_one_1.png`
  },
  {
    id: 2,
    cordingSrc: `${MACBOOK_PACKAGE_HOST_CDN}2023/july/img_pc_macbook_one_before_2.png`,
    alt: '강의 이미지',
    description: `맥 유저 모여라!\n주목 받는 프레젠테이션`,
    afterSrc: `${MACBOOK_PACKAGE_HOST_CDN}2023/july/img_pc_macbook_one_2_v2.png`,
    afterSrcM: `${MACBOOK_PACKAGE_HOST_CDN}2023/july/img_m_macbook_one_2.png`
  },
  {
    id: 3,
    cordingSrc: `${MACBOOK_PACKAGE_HOST_CDN}2023/july/img_pc_macbook_one_before_3.png`,
    alt: `코딩 이미지`,
    description: ` 그림으로 배우는 하루 10분\n코딩 입문 클래스`,
    afterSrc: `${MACBOOK_PACKAGE_HOST_CDN}2023/july/img_pc_macbook_one_3_v2.png`,
    afterSrcM: `${MACBOOK_PACKAGE_HOST_CDN}2023/july/img_m_macbook_one_3.png`
  }
]
export const benefitTwoPreviewListValues = [
  {
    title: `날렵하고\n가뿐하게`,
    description: `가벼운 무게와 1cm 남짓에 불과한 두께로 극강의 휴대성을 자랑하는 M3 칩 탑재 MacBook Air는 Apple 사상 최초로 내구성이 뛰어난 재활용 알루미늄 외장을 갖추고 있습니다.`
  },
  {
    title: `강력하게\nM3답게`,
    description: `MacBook Air가 M3칩과 만나\n한층 강력해졌습니다. M3칩 탑재로 M1칩 모델 대비 최대 1.6배 더 빠른 속도와 탁월한 AI 성능을 활용해 macOS의 여러 스마트한 기능들을 구동합니다.`
  },
  {
    title: `놀랍도록 빠른 속도와 탁월한 반응성`,
    description: `Dynamic Caching과 하드웨어 가속형 레이 트레이싱까지 지원하여 성능을 대폭 끌어올려주고 한층 실감 나는 그래픽을 구현합니다.`
  }
]
export const formValues = {
  category: 'macbookPackage',
  type: 'macbook',
  title: `고민은 배송만 늦출뿐!\n늦기 전에 상담 신청하세요`,
  description: '재고 수량 소진 시 혜택이 종료될 수 있습니다'
}

export const dbModalValues = {
  form: {
    pCategory: 'macbookPackage',
    eventName: 'macbookPackage2',
    formName: 'macbookPackageForm',
    btnText: 'MacBook 신청하기',
    btnBg: `#6511ff`,
    btnColor: '#fff',
    banner: {
      src: `${MACBOOK_PACKAGE_HOST_CDN}2024/bnr_pc_popup_macbook.png`,
      width: 550,
      height: 430
    }
  },
  button: {
    buttonColor: '#fff',
    buttonBackground: '#6511ff'
  }
}
