import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useMediaQuery } from 'react-responsive';
import Password from './Password';
import ModalPortal from 'ModalPortal';
import AlertModal from './modal/AlertModal';
import DateBirth from './DateBirth';
import api from 'common/api';
import * as config from 'common/config';
import '../assets/styles/modal.scss';
import '../assets/styles/modalJoin.scss';

const joinPathList = [
  {
    id: 'NEWS',
    title: '인터넷뉴스기사',
  },
  {
    id: 'INSTAGRAM',
    title: '인스타그램',
  },
  {
    id: 'YOUTUBE',
    title: '유튜브',
  },
  {
    id: 'BANNER',
    title: '인터넷 배너',
  },
  {
    id: 'IPTV',
    title: 'IPTV',
  },
  {
    id: 'BLOG_CAFE',
    title: '블로그, 카페 등',
  },
  {
    id: 'RECOMMEND',
    title: '주변지인 추천',
  },
  {
    id: 'FACEBOOK',
    title: '페이스북',
  },
];

const interestArticlesList = [
  {
    id: 'ENGLISH',
    title: '영어',
  },
  {
    id: 'YAFIT',
    title: '야핏',
  },
  {
    id: 'SELF_DEVELOPMENT',
    title: '자기개발',
  },
  {
    id: 'INVESTMENT_TECHNIQUES',
    title: '재테크',
  },
  {
    id: 'DIET',
    title: '다이어트',
  },
  {
    id: 'HOME_TRAINING',
    title: '홈트',
  },
];

export default function OverseasJoin({ handleUserInfo, overseasToken, tokenData }) {
  const idRef = useRef();
  const nameRef = useRef();

  const [validStatus, setValidStatus] = useState({
    id: null,
    name: false,
    birthYear: false,
    birthMonth: false,
    birthDay: false,
    interestArticles: false,
  });

  const [validText, setValidText] = useState({
    id: '',
    interestArticles: '',
  });

  const [inputValue, setInputValue] = useState({
    id: '',
    password: '',
    countryCode: '82',
    name: '',
    gender: '',
    birthYear: '',
    birthMonth: '',
    birthDay: '',
    email: '',
    joinPath: '',
    consultingTime: '',
    recommendId: '',
  });

  const [isChecked, setIsChecked] = useState({
    consultingAgree: false,
  });

  const [isRequiredChecked, setIsRequiredChecked] = useState({
    allAgree: false,
    termsAgree: false,
    privacyAgree: false,
    emailAgree: true,
    smsAgree: true,
  });

  const [interestValue, setInterestValue] = useState([]);

  const deviceCheck = useMediaQuery({ maxWidth: 1024 }) ? true : false;
  const [modalopenAlert, setModalopenAlert] = useState(false);
  const [currentCheck, setCurrentCheck] = useState(null);
  const [isComplete, setIsComplete] = useState(false);
  const [pwdComplete, setPwdComplete] = useState(false);

  const handleOpenModalAlert = useCallback((e) => {
    document.body.classList.add('modal-open');
    setModalopenAlert(true);
  }, []);

  const handleCloseModalAlert = useCallback((status) => {
    document.body.classList.remove('modal-open');
    setModalopenAlert(false);
  }, []);

  const handleConfirmClose = (status) => {
    setModalopenAlert(!status);
    // setIsChecked({ ...isChecked, [currentCheck]: !isChecked[currentCheck] });
    setIsRequiredChecked({
      ...isRequiredChecked,
      emailAgree: true,
      smsAgree: true,
    });
  };

  // 동의 체크박스
  const handleCheckedItem = (e) => {
    let { id } = e.target;

    // setCurrentCheck(id);
    setIsChecked({ ...isChecked, [id]: !isChecked[id] });
  };

  const handleRequiredAllCheck = () => {
    setIsRequiredChecked({
      ...isRequiredChecked,
      allAgree: !isRequiredChecked.allAgree,
      termsAgree: !isRequiredChecked.allAgree,
      privacyAgree: !isRequiredChecked.allAgree,
      emailAgree: !isRequiredChecked.allAgree,
      smsAgree: !isRequiredChecked.allAgree,
    });
  };

  const handleRequiredCheck = (e) => {
    const { id } = e.target;

    setCurrentCheck(id);
    setIsRequiredChecked({
      ...isRequiredChecked,
      [id]: !isRequiredChecked[id],
    });
  };

  // input 상태값 관리 함수
  const handleInputValue = (e) => {
    let { name, value } = e.target;

    if (value) {
      setInputValue({ ...inputValue, [name]: value });
      setValidStatus({ ...validStatus, [name]: true });
    } else {
      setInputValue({ ...inputValue, [name]: '' });
      setValidStatus({ ...validStatus, [name]: false });
    }
  };

  /**
   * 관심 서비스 클릭 시 발생하는 함수
   */
  const handleInterestValue = (e) => {
    let { value, checked } = e.target;

    if (checked) {
      setInterestValue([...interestValue, value]);
    } else {
      setInterestValue(interestValue.filter((el) => el !== value));
    }
  };

  // 아이디 상태값 관리
  const handleIdValue = (e) => {
    let { value } = e.target;

    if (value) {
      setInputValue({ ...inputValue, id: value });
    } else {
      setInputValue({ ...inputValue, id: '' });
      setValidStatus({ ...validStatus, id: null });
      setValidText({ ...validText, id: '' });
    }
  };

  // 비밀번호 완료 상태 관리
  const handlePwdComplete = (status, newPwd) => {
    setPwdComplete(status);
    setInputValue({ ...inputValue, password: newPwd });
  };

  // 아이디 유효성 체크 및 중복 체크
  const getIdVerify = () => {
    api
      .get(config.AUTH_API_HOST + '/auth/v2/sign-up/verify-id?userId=' + inputValue.id)
      .then((response) => {
        if (response.data.meta.code !== 200) {
          if (response.data.meta.code === 4041) {
            idRef.current.focus();
            setValidText({ ...validText, id: response.data.meta.message });
            setValidStatus({ ...validStatus, id: false });

            return false;
          }

          if (response.data.meta.code === 4042) {
            idRef.current.focus();
            setValidText({ ...validText, id: response.data.meta.message });
            setValidStatus({ ...validStatus, id: false });

            return false;
          }

          alert(response.data.meta.message);
          idRef.current.focus();

          return false;
        }

        setValidText({ ...validText, id: '사용가능한 아이디입니다.' });
        setValidStatus({ ...validStatus, id: true });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleNameCheck = (e) => {
    let nameRegExp = /^[ㄱ-ㅎ가-힣a-zA-Z|]*$/;

    if (!nameRegExp.test(e.target.value)) {
      nameRef.current.focus();

      return false;
    }

    if (!e.target.value) {
      setInputValue({ ...inputValue, name: '' });
      setValidStatus({ ...validStatus, name: false });

      return false;
    }

    setInputValue({ ...inputValue, name: e.target.value });
    setValidStatus({ ...validStatus, name: true });

    return true;
  };

  // 생년월일 체크
  const handleBirthCheck = (e) => {
    let { name, value } = e.target;

    setInputValue({ ...inputValue, [name]: value });

    if (value === 'default') {
      setValidStatus({ ...validStatus, [name]: false });
      return false;
    }

    setValidStatus({ ...validStatus, [name]: true });
  };

  // 저장 버튼
  const postEditInfo = () => {
    const data = {
      userId: inputValue.id,
      password: inputValue.password,
      userName: inputValue.name,
      userSex: inputValue.gender,
      birthDate: inputValue.birthYear + inputValue.birthMonth + inputValue.birthDay,
      email: inputValue.email,
      emailYn: isRequiredChecked.emailAgree ? 1 : 0,
      countryCode: inputValue.countryCode,
      joinPath: inputValue.joinPath,
      outboundTime: inputValue.consultingTime,
      outboundYn: isChecked.consultingAgree ? 1 : 0,
      recommendId: inputValue.recommendId,
      smsYn: isRequiredChecked.smsAgree ? 1 : 0,
      privacyAgree: isRequiredChecked.privacyAgree ? 1 : 0,
      termsAgree: isRequiredChecked.termsAgree ? 1 : 0,
      isMobile: deviceCheck ? 'Y' : 'N', //데스크탑이냐 모바일이냐
      deviceType: 0, // 디바이스 종류
      snsToken: '',
      snsType: 0,
      overseasSignUpKey: overseasToken,
      interestArticles: interestValue,
    };

    api
      .post(config.AUTH_API_HOST + '/auth/v2/sign-up/overseas-user', data)
      .then((response) => {
        if (response.data.meta.code !== 200) {
          alert(response.data.meta.message);

          return false;
        }

        handleUserInfo(response.data.data.profileAccessToken, inputValue.id);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (isRequiredChecked.termsAgree && isRequiredChecked.privacyAgree) {
      setIsRequiredChecked({ ...isRequiredChecked, allAgree: true });
    } else {
      setIsRequiredChecked({ ...isRequiredChecked, allAgree: false });
    }
  }, [isRequiredChecked.termsAgree, isRequiredChecked.privacyAgree]);

  useEffect(() => {
    if (interestValue.length < 1) {
      setValidStatus({ ...validStatus, interestArticles: false });
      setValidText({ ...validText, interestArticles: '관심 서비스를 선택해주세요.' });
    } else {
      setValidStatus({ ...validStatus, interestArticles: true });
      setValidText({ ...validText, interestArticles: '' });
    }
  }, [interestValue]);

  useEffect(() => {
    if (
      validStatus.id &&
      validStatus.birthYear &&
      validStatus.birthMonth &&
      validStatus.birthDay &&
      isRequiredChecked.termsAgree &&
      isRequiredChecked.privacyAgree &&
      pwdComplete &&
      validStatus.interestArticles
    ) {
      setIsComplete(true);
    } else {
      setIsComplete(false);
    }
  }, [validStatus, pwdComplete, isRequiredChecked]);

  useEffect(() => {
    if (!isRequiredChecked.emailAgree || !isRequiredChecked.smsAgree) {
      handleOpenModalAlert();
    }
  }, [isRequiredChecked.emailAgree, isRequiredChecked.smsAgree]);

  useEffect(() => {
    if (tokenData) {
      setInputValue({ ...inputValue, email: tokenData.email });
    }
  }, [tokenData]);

  return (
    <>
      <div className="modal-content">
        <div className="inner">
          <div className="title">
            <p>
              <strong>회원가입</strong>
              지금 회원가입을 하시면 아주 특별한 혜택을 누릴 수 있습니다.
            </p>
          </div>
          <p className="required-display">
            <span>필수 입력 항목</span>
          </p>
          <form name="joinFrm" action="#" className="frm">
            <div className="form-box">
              <dl>
                <dt className="mark">아이디</dt>
                <dd>
                  <input
                    type="text"
                    maxLength="12"
                    placeholder="아이디를 입력해주세요."
                    className={validText.id && (validStatus.id ? 'success' : 'warning')}
                    name="id"
                    ref={idRef}
                    value={inputValue.id}
                    onChange={handleIdValue}
                    onBlur={getIdVerify}
                  />
                  {validText.id && (
                    <span
                      className={
                        'valid-text' + (validStatus.id ? ' active success' : ' active warning')
                      }
                    >
                      {validText.id}
                    </span>
                  )}
                </dd>
              </dl>
              <Password mark handlePwdComplete={handlePwdComplete} />
              <dl>
                <dt className="mark">필수 선택</dt>
                <dd className="join-select-box">
                  <div className="inner">
                    <div className="select-title">
                      <p>관심 서비스 (중복 선택 가능)</p>
                    </div>
                    <ul className="join-path-box">
                      {interestArticlesList.map((item) => (
                        <li key={item.id}>
                          <label htmlFor={item.id}>
                            <input
                              type="checkbox"
                              id={item.id}
                              name="interestArticles"
                              value={item.id}
                              onChange={handleInterestValue}
                              checked={interestValue.includes(item.id) ? true : false}
                            />
                            <span className="chk"></span>
                            {item.title}
                          </label>
                        </li>
                      ))}
                    </ul>
                  </div>
                  {!validStatus.interestArticles && (
                    <span className={'valid-text active warning'}>
                      {validText.interestArticles}
                    </span>
                  )}
                </dd>
              </dl>
              <dl className="line">
                <dt className="mark">이름</dt>
                <dd>
                  <input
                    type="text"
                    placeholder="이름을 입력해주세요."
                    name="name"
                    ref={nameRef}
                    value={inputValue.name}
                    onChange={handleNameCheck}
                  />
                </dd>
              </dl>
              <dl>
                <dt className="mark">성별</dt>
                <dd className="gender-box">
                  <label htmlFor="genderWoman" className={inputValue.gender == 2 ? 'active' : null}>
                    <input
                      type="radio"
                      id="genderWoman"
                      name="gender"
                      value="2"
                      onChange={handleInputValue}
                      checked={inputValue.gender === 2 ? true : false}
                    />
                    여자
                  </label>
                  <label htmlFor="genderMan" className={inputValue.gender == 1 ? 'active' : null}>
                    <input
                      type="radio"
                      id="genderMan"
                      name="gender"
                      value="1"
                      onChange={handleInputValue}
                      checked={inputValue.gender === 1 ? true : false}
                    />
                    남자
                  </label>
                </dd>
              </dl>
              <DateBirth
                yearValue={inputValue.birthYear}
                monthValue={inputValue.birthMonth}
                dayValue={inputValue.birthDay}
                mark
                handleBirthCheck={handleBirthCheck}
              />
              <dl>
                <dt className="mark">이메일 주소</dt>
                <dd className="email-box">
                  <div className="certified-code-form disabled">
                    <input type="text" name="email" value={inputValue.email} readOnly />
                  </div>
                </dd>
              </dl>
              {/* <dl className="agree-flex-form">
                <dt>
                  야나두에서 제공하는 컨텐츠 안내 및 이벤트, 각종 소식에 대한
                  알림을 받으시겠습니까?
                </dt>
                <dd>
                  <label
                    htmlFor="SMSAgree"
                    className="checkbox-flex-form lg-chk"
                  >
                    <input
                      type="checkbox"
                      id="SMSAgree"
                      checked={isChecked.SMSAgree ? true : false}
                      onChange={handleCheckedItem}
                    />
                    <span className="chk"></span>
                    <p>SMS 동의</p>
                  </label>
                  <label
                    htmlFor="emailAgree"
                    className="checkbox-flex-form lg-chk"
                  >
                    <input
                      type="checkbox"
                      id="emailAgree"
                      checked={isChecked.emailAgree ? true : false}
                      onChange={handleCheckedItem}
                    />
                    <span className="chk"></span>
                    <p>이메일 동의</p>
                  </label>
                </dd>
              </dl> */}
              {/* <dl className="line">
                <dt>선택항목</dt>
                <dd className="join-select-box">
                  <div className="inner">
                    <div className="select-title">
                      <p>야나두를 어떻게 알고 가입하셨나요?</p>
                    </div>
                    <ul className="join-path-box">
                      {joinPathList.map((item) => (
                        <li key={item.id}>
                          <label htmlFor={'check' + item.id}>
                            <input
                              type="radio"
                              id={'check' + item.id}
                              name="joinPath"
                              value={item.id}
                              onChange={handleInputValue}
                              checked={inputValue.joinPath === item.id ? true : false}
                            />
                            <span className="chk"></span>
                            {item.title}
                          </label>
                        </li>
                      ))}
                    </ul>
                  </div>
                </dd>
              </dl> */}
              <dl>
                <dt>전화상담</dt>
                <dd className="join-select-box">
                  <div className="inner">
                    <div className="select-title">
                      <p>
                        <span>상담 가능 시간</span>
                        <span>평일 : 10:00 ~ 18:00</span>
                      </p>
                    </div>
                    <select
                      id="consultingTime"
                      className="sct"
                      name="consultingTime"
                      onChange={handleInputValue}
                    >
                      <option>아무때나</option>
                      <option>10:00 ~ 11:00</option>
                      <option>11:00 ~ 12:00</option>
                      <option>12:00 ~ 13:00</option>
                      <option>13:00 ~ 14:00</option>
                      <option>14:00 ~ 15:00</option>
                      <option>15:00 ~ 16:00</option>
                      <option>16:00 ~ 17:00</option>
                      <option>17:00 ~ 18:00</option>
                    </select>
                  </div>
                  <label htmlFor="consultingAgree" className="checkbox-flex-form lg-chk">
                    <input
                      type="checkbox"
                      id="consultingAgree"
                      checked={isChecked.consultingAgree ? true : false}
                      onChange={handleCheckedItem}
                    />
                    <span className="chk"></span>
                    <p>상담원과의 전화 상담 후 상품 구매를 원하십니까?</p>
                  </label>
                </dd>
              </dl>
              <dl>
                <dt>추천 아이디</dt>
                <dd>
                  <input
                    type="text"
                    placeholder="추천 아이디 입력"
                    name="recommendId"
                    onChange={handleInputValue}
                  />
                </dd>
              </dl>

              <dl>
                <dt>약관동의</dt>
                <dd className="join-select-box agree">
                  <div className="inner">
                    <div className="select-title">
                      <label htmlFor="allAgree" className="checkbox-flex-form">
                        <input
                          type="checkbox"
                          id="allAgree"
                          checked={isRequiredChecked.allAgree ? true : false}
                          onChange={handleRequiredAllCheck}
                        />
                        <span className="chk"></span>
                        <p>만 14세 이상이며, 아래 약관에 모두 동의합니다.</p>
                      </label>
                    </div>
                    <ul>
                      <li>
                        <label htmlFor="termsAgree" className="checkbox-flex-form">
                          <input
                            type="checkbox"
                            id="termsAgree"
                            checked={isRequiredChecked.termsAgree ? true : false}
                            onChange={handleRequiredCheck}
                          />
                          <span className="chk"></span>
                          <p>(필수) 이용약관 동의</p>
                          <a href="/service/yanadoo/service" target="_blank">
                            내용보기
                          </a>
                        </label>
                      </li>
                      <li>
                        <label htmlFor="privacyAgree" className="checkbox-flex-form">
                          <input
                            type="checkbox"
                            id="privacyAgree"
                            checked={isRequiredChecked.privacyAgree ? true : false}
                            onChange={handleRequiredCheck}
                          />
                          <span className="chk"></span>
                          <p>(필수) 개인정보 수집 및 이용 동의</p>
                          <a href="/service/policydetailjoin" target="_blank">
                            내용보기
                          </a>
                        </label>
                      </li>
                      <li>
                        <label htmlFor="emailAgree" className="checkbox-flex-form">
                          <input
                            type="checkbox"
                            id="emailAgree"
                            checked={isRequiredChecked.emailAgree ? true : false}
                            onChange={handleRequiredCheck}
                          />
                          <span className="chk"></span>
                          <p>(선택) e-mail 광고 및 마케팅 이용 동의</p>
                        </label>
                      </li>
                      <li>
                        <label htmlFor="smsAgree" className="checkbox-flex-form">
                          <input
                            type="checkbox"
                            id="smsAgree"
                            checked={isRequiredChecked.smsAgree ? true : false}
                            onChange={handleRequiredCheck}
                          />
                          <span className="chk"></span>
                          <p>(선택) SMS 광고 및 마케팅 이용 동의</p>
                        </label>
                      </li>
                    </ul>
                  </div>
                </dd>
              </dl>
            </div>
            <div className="btn-flex-form">
              <button
                type="button"
                onClick={postEditInfo}
                className={'btn btn-edit-submit' + (isComplete ? ' active' : ' disabled')}
                disabled={isComplete ? false : true}
              >
                가입완료
              </button>
            </div>
          </form>
        </div>
      </div>
      {modalopenAlert ? (
        <ModalPortal>
          <AlertModal
            // btnClose
            btnGroup
            btnGroupTxtClose={'받지 않기'}
            btnGroupTxtConfirm={'알림 받기'}
            msg={'알림을 받지 않으면 \n 다양한 무료 제공 혜택과 이벤트를 \n 놓칠 수 있습니다.'}
            handleCloseModal={handleCloseModalAlert}
            handleConfirmClose={handleConfirmClose}
          />
        </ModalPortal>
      ) : null}
    </>
  );
}
