import React, { useState, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ModalPortal from 'ModalPortal';
import ModalJoin from 'components/modal/ModalJoin';
import ModalNonMember from 'components/modal/ModalNonMember';
import '../../assets/styles/member.scss';

export default function NonMember() {
  const { state } = useLocation();
  const userId = state && state.userId;
  const userToken = state && state.token;

  const [modalopenJoin, setModalopenJoin] = useState(false);
  const [modalopenMember, setModalopenMember] = useState(false);

  const handleCloseModalJoin = useCallback((e) => {
    document.body.classList.remove('modal-open');
    setModalopenJoin(false);
  }, []);

  const handleOpenModalMember = useCallback((e) => {
    document.body.classList.add('modal-open');
    setModalopenMember(true);
  }, []);

  const handleCloseModalMember = useCallback((e) => {
    setModalopenMember(false);
    setModalopenJoin(true);
  }, []);

  useEffect(() => {
    handleOpenModalMember();

    return () => {
      document.body.classList.remove('modal-open');
    };
  }, []);

  return (
    <>
      <div className="no-content nonmember"></div>
      {modalopenJoin ? (
        <ModalPortal>
          <ModalJoin
            title={'회원가입'}
            btnClose
            type={'PAYMENT'} // DEFAULT: 기존 회원가입 , SOCIAL: 소셜 회원가입, PAYMENT: 수기결제
            userToken={userToken}
            handleCloseModal={handleCloseModalJoin}
          />
        </ModalPortal>
      ) : null}
      {modalopenMember ? (
        <ModalPortal>
          <ModalNonMember
            title={'서비스 이용 안내'}
            btnClose
            userId={userId}
            handleChangeJoinModal={handleCloseModalMember}
          />
        </ModalPortal>
      ) : null}
    </>
  );
}
