import React from 'react'
import { useNavigate } from 'react-router'

import { v4 as uuid } from 'uuid'

import useLevelTestAdvancedResultStore from 'store/levelTestAdvanced/useLevelTestAdvancedResultStore'

import Button from './common/Button'
import useSetCategoryGrade from '../hooks/useSetCategoryGrade'
import useSetCategoryStats from '../hooks/useSetCategoryStats'
import styles from '../LevelTestAdvancedResult.module.scss'

export default function Grading() {
  const navigate = useNavigate()
  const { test, correctCount } = useLevelTestAdvancedResultStore()
  const { categoryStats } = useSetCategoryStats(test)
  const { grade } = useSetCategoryGrade(categoryStats)

  return (
    <section className={styles.grading}>
      <hgroup className={styles.hgroup}>
        <h6>20문제 중</h6>
        <h3>{correctCount}문제를 맞췄어요</h3>
      </hgroup>

      <ul>
        {grade.map((item, idx) => (
          <li key={uuid()}>
            <p>Part {idx + 1}</p>
            <h5>{item.category}</h5>
            <h3>
              {item.correct}
              <span>/{item.amount}</span>
            </h3>

            <figure>
              <img src={item.src} alt={item.badge} />
            </figure>
          </li>
        ))}
      </ul>

      <Button
        text="정답 상세보기"
        onClick={() => {
          navigate('/levelTestAdvanced/answer')
        }}
      />
    </section>
  )
}
