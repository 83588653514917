import React, { useState, useRef, useEffect } from 'react';
import api from 'common/api';
import * as config from 'common/config';
import '../../assets/styles/myClass.scss';

export default function ModalLearningInquiry({
  activeClassData,
  activeCourseData,
  learningData,
  btnClose,
  handleCloseModal,
}) {
  const inquiryRef = useRef();
  let fileMaxSize = 2 * 1024 * 1024;

  const [selectFileName, setSelectFileName] = useState(
    learningData ? learningData.questionFilePath : ''
  );
  const [selectFileData, setSelectFileData] = useState('');
  const [uploadImg, setUploadImg] = useState(null);
  const [textareaLength, setTextareaLength] = useState(
    learningData ? learningData.contents.length : 0
  );
  const [textareaValue, setTextareaValue] = useState(
    learningData ? learningData.contents : ''
  );

  const handleInputFile = (e) => {
    setSelectFileName(e.target.value);
    setSelectFileData(e.target.files[0]);
  };

  const handleInquiryValue = (e) => {
    setTextareaValue(e.target.value);
    setTextareaLength(e.target.value.length);
  };

  // 이미지 업로드
  const postImage = (e) => {
    const formData = new FormData();

    formData.append('file', selectFileData);
    formData.append('resizeType', 'DEFAULT');

    api
      .post('/v2/file/image-upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        if (response.data.meta.code !== 200) {
          if (response.data.meta.message) {
            alert(response.data.meta.message);
          } else {
            alert(config.MESSAGE['common-error']);
          }
          return;
        }

        setUploadImg(response.data.data[0]);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // 내가 작성한 문의가 있다면 데이터 업데이트
  const getMyInquiry = () => {
    api
      .get('/v2/lecture-question/' + activeCourseData.questionSeq)
      .then((response) => {
        if (response.data.meta.code === 200) {
          setTextareaValue(response.data.data.contents);
          setTextareaLength(response.data.data.contents.length);
          setSelectFileName(response.data.data.questionFilePath);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // 문의 등록
  const postMyInquiry = () => {
    const data = {
      classSeq: activeClassData.classSeq,
      contents: textareaValue,
      lectureSeq: activeCourseData.lectureSeq,
      questionFilePath: uploadImg ? uploadImg.filePath : '',
    };

    api
      .post('/v2/lecture-question', data)
      .then((response) => {
        if (response.data.meta.code !== 200) {
          alert(response.data.meta.message);

          return false;
        }

        alert('문의가 저장되었습니다.');
        window.location.reload();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // 문의 수정
  const editMyInquiry = () => {
    const data = {
      lectureSeq: activeCourseData.lectureSeq,
      contents: textareaValue,
      questionFilePath: uploadImg ? uploadImg.filePath : selectFileName,
      questionSeq: activeCourseData.questionSeq,
    };

    api
      .put('/v2/lecture-question', data)
      .then((response) => {
        if (response.data.meta.code !== 200) {
          alert(response.data.meta.message);

          return false;
        }

        alert('문의가 수정되었습니다.');
        window.location.reload();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleRegistLearningInquiry = () => {
    if (textareaLength < 11) {
      alert('최소 10자 이상 입력해주세요.');
      inquiryRef.current.focus();

      return false;
    }

    if (uploadImg && uploadImg.fileSize > fileMaxSize) {
      alert('파일은 2M 이하만 등록 가능 합니다.');
      setSelectFileName('');
      return false;
    }

    // 문의를 작성한 적이 있다면 수정 API, 없다면 등록 API
    if (activeCourseData && activeCourseData.questionSeq) {
      editMyInquiry();
    } else {
      postMyInquiry();
    }
  };

  useEffect(() => {
    if (selectFileData) {
      postImage();
    }
  }, [selectFileData]);

  useEffect(() => {
    if (activeCourseData.questionSeq) {
      getMyInquiry();
    }
  }, []);

  return (
    <>
      <div className="modal-wrap">
        <div className="dimmed" onClick={handleCloseModal}></div>
        <div className="modal-inner">
          {btnClose && (
            <button
              type="button"
              className="btn-modal-close"
              onClick={handleCloseModal}
            >
              <span className="blind">닫기</span>
            </button>
          )}
          <div className="modal-header">
            <strong>학습 문의</strong>
          </div>
          <div className="modal-content">
            <div className="modal-form-col learning-inquiry">
              <form name="learningInquiryFrm" action="#">
                <div className="title-area">
                  <p className="ellipsis line-clamp-2">
                    {activeCourseData && activeClassData
                      ? activeClassData.className
                      : learningData
                      ? learningData.classTitle
                      : null}
                  </p>
                  <p className="ellipsis">
                    {activeCourseData && activeClassData
                      ? activeCourseData.title
                      : learningData
                      ? learningData.lectureTitle
                      : null}
                  </p>
                </div>
                <dl className="inquiry-area">
                  <dt>문의내용</dt>
                  <dd>
                    <textarea
                      placeholder="※ 현재 문의량이 많아 답변이 지연되고 있습니다. 순차적으로 답변 드리고 있으며, 빠른 답변이 될 수 있도록 하겠습니다. 불편을 드려 죄송합니다."
                      maxLength="1000"
                      ref={inquiryRef}
                      value={textareaValue}
                      onChange={handleInquiryValue}
                    ></textarea>
                    <p>
                      <span>{textareaLength}</span>/1000
                    </p>
                  </dd>
                </dl>
                <dl>
                  <dt>첨부파일</dt>
                  <dd className="flex-form">
                    <span className="txt ellipsis active">
                      {selectFileName}
                    </span>
                    <input
                      type="file"
                      accept="image/*"
                      id="fileUpload"
                      onChange={handleInputFile}
                    />
                    <label className="btn active" htmlFor="fileUpload">
                      파일찾기
                    </label>
                  </dd>
                </dl>
                <div className="notice">
                  <em>
                    파일은 2M 이하, jpg, png, gif 형식만 등록 가능 합니다.
                  </em>
                  <strong>문의 작성 안내</strong>
                  <ul>
                    <li>
                      약관 및 법률에 위배(비속어, 허위사실 등), 해석이
                      불가능하거나 문의 외 다른 목적의 글을 작성한 경우 관리자에
                      의해 사전 동의 없이 미게시 될 수 있습니다.
                    </li>
                    <li>
                      작성하신 내용은 홍보, 이벤트 등 다양한 방법으로 활용될 수
                      있습니다.
                    </li>
                  </ul>
                  <div className="btn-flex-form">
                    <button
                      type="button"
                      className="btn"
                      onClick={handleCloseModal}
                    >
                      <span>취소</span>
                    </button>
                    <button
                      type="button"
                      className="btn active"
                      onClick={handleRegistLearningInquiry}
                    >
                      <span>확인</span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
