import classNames from 'classnames'
import { Swiper, SwiperSlide } from 'swiper/react'

import styles from '../StoreDetail.module.scss'

import 'swiper/swiper.scss'
import 'swiper/components/pagination/pagination.scss'

export default function TopImageSection({ packageImages, hashTags }) {
  const isSingleImage = packageImages.length === 1
  return (
    <div className={styles.topImageSection}>
      {packageImages && (
        <Swiper
          centeredSlides
          autoplay={{ delay: 30000 }}
          spaceBetween={12}
          allowTouchMove={isSingleImage ? false : true}
          className={(styles.swiper, classNames(isSingleImage && styles.sliderOptionNone))}
          navigation
          pagination={{
            type: 'fraction',
            renderFraction: (currentClass, totalClass) => {
              return '<div class="swiper-pagination-inner"><span class="' + currentClass + '"></span>' + '/' + '<span class="' + totalClass + '"></span></div>'
            }
          }}
        >
          {packageImages?.map((imgUrl, idx) => (
            <SwiperSlide key={idx} className={styles.imageSwiperSlide}>
              <img src={imgUrl} alt={`class ${idx}`} />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
      {hashTags && (
        <ul className={styles.hashList}>
          {hashTags?.map((el, idx) => (
            <li key={idx}>#{el.hashTagName}</li>
          ))}
        </ul>
      )}
    </div>
  )
}
