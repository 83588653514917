import { useState } from 'react';

export default function Counter() {
    const [counter, setCounter] = useState(1);

    const onIncrease = () => {
        setCounter (counter => counter + 1);
    }

    const onDecrease = () =>{
        (counter > 1) &&
        setCounter (counter => counter - 1);
    }

    return (
        <>
        <div className="material-counter">
            <button type="button" onClick={onDecrease}>&#8722;</button>
            <span>{counter}</span>
            <button type="button" onClick={onIncrease}>&#43;</button>
        </div>
        </>
    )
}