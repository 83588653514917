import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import api from 'common/api'
import * as config from 'common/config'
import MobileHeader from 'components/common/MobileHeader'

import PackageList from './components/PackageList'
import TabMain from './TabMain'
import Lnb from '../../components/Lnb'

export default function ClassHistory() {
  const [toggleMenu, setToggleMenu] = useState(1)
  const [myClassData, setMyClassData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [userName, setUserName] = useState(null)
  const navigate = useNavigate()

  const runningClassData =
    myClassData &&
    myClassData.filter(
      (list) =>
        list.learningAttendingStatus === 'RUNNING' || list.learningAttendingStatus === 'READY'
    )
  const endClassData =
    myClassData && myClassData.filter((list) => list.learningAttendingStatus === 'END')

  const handleToggleMenu = (id) => {
    setToggleMenu(id)
  }

  const getMyclasssList = () => {
    api
      .get('/v2/myClass/web/packages')
      .then((response) => {
        setMyClassData(response.data.data.packages)
        setUserName(response.data.data.userName)
      })
      .catch((e) => {
        alert(config.MESSAGE['common-error'])
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    getMyclasssList()
  }, [])

  return (
    <div className="myclass-col">
      <div className="inner-col">
        <div className="container mypage-sub">
        <MobileHeader title="MY 클래스" onClick={() => navigate('/mypage/1')}/>
          <Lnb />
          <section className="content">
            <TabMain activeTab={'MYHISTORY'} />
            <section className="myclass-content-box">
              <div className="class-box history active">
                <div className="toggle-btn-area">
                  {userName && (
                    <p className="user-name-area">
                      <span>{userName}</span>님의 클래스
                    </p>
                  )}
                  <div className={'toggle-menu' + (toggleMenu === 2 ? ' active' : '')}>
                    <button
                      className={'toggle-item' + (toggleMenu === 1 ? ' active' : '')}
                      onClick={() => handleToggleMenu(1)}
                    >
                      <span>수강중</span>
                    </button>
                    <button
                      className={'toggle-item' + (toggleMenu === 2 ? ' active' : '')}
                      onClick={() => handleToggleMenu(2)}
                    >
                      <span>수강완료</span>
                    </button>
                  </div>
                </div>
                <div className={'tab-content toggle-tab' + (toggleMenu === 1 ? ' active' : '')}>
                  <PackageList isLoading={isLoading} myClassData={runningClassData} />
                </div>
                <div className={'tab-content toggle-tab' + (toggleMenu === 2 ? ' active' : '')}>
                  <PackageList isLoading={isLoading} myClassData={endClassData} dataType={'END'} />
                </div>
              </div>
            </section>
          </section>
        </div>
      </div>
    </div>
  )
}
