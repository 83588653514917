// 사용자의 레벨을 계산
export const getUserLevel = (score) => {
  if (score > 90) {
    return 6
  } else if (score > 80) {
    return 5
  } else if (score > 70) {
    return 4
  } else if (score > 40) {
    return 3
  } else if (score > 20) {
    return 2
  } else {
    return 1
  }
}

// 현재 년/월/일 리턴 (ex. 24.01.16)
export const getDateString = () => {
  const nowDate = new Date()
  const year = nowDate.getFullYear().toString().slice(-2)
  const month = String(nowDate.getMonth() + 1).padStart(2, '0')
  const date = String(nowDate.getDate()).padStart(2, '0')

  return `${year}.${month}.${date}`
}

// 소요 시간을 분/초 문자열로 변환
export const formatTime = (seconds) => {
  if (typeof seconds !== 'number' || isNaN(seconds)) {
    return ''
  }

  const minutes = Math.floor(seconds / 60)
  const remainingSeconds = seconds % 60

  const formattedMinutes = String(minutes).padStart(2, '0')
  const formattedSeconds = String(remainingSeconds).padStart(2, '0')

  return `${formattedMinutes}분 ${formattedSeconds}초`
}

// 소요 시간 백분율 구하기
export const calcTimePercent = (userTime, limitTime) => {
  return (userTime / (limitTime * 60)) * 100
}

// 정답 수 백분율 구하기
export const calcCorrectPercent = (correctCount, questionCount) => {
  return (correctCount / questionCount) * 100
}

// 사용자에 맞는 추천상품 계산
export const getUserRecommendedProduct = (score) => {
  if (score > 90) {
    return '리얼스피킹'
  } else if (score > 70) {
    return '스르르 끝장팩'
  } else if (score > 40) {
    return '니포우포 패키지'
  } else if (score > 20) {
    return '10분 패키지'
  } else {
    return '원예나 앞자리 학습법'
  }
}
