import styled from 'styled-components'

export default function CategoryFilter({ list, handleCategory, categoryTitle }) {
  return (
    <>
      <CategoryBox>
        <ul className="category-list">
          {list.map((item, idx) => (
            <li key={idx}>
              <button className={categoryTitle === item.categoryName ? 'active' : ''} onClick={() => handleCategory(item.categoryCodeList, item.categoryName, idx)}>
                {item.categoryName}
              </button>
            </li>
          ))}
        </ul>
      </CategoryBox>
    </>
  )
}
const CategoryBox = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 5rem;
  line-height: 1.4;
  .category-list {
    margin: 0 auto;
  }
  .category-list li {
    display: inline-block;
    vertical-align: middle;
  }
  .category-list li + li {
    margin-left: 3.6rem;
  }
  .category-list button {
    display: block;
    font-size: 1.6rem;
    font-weight: 500;
    color: #717188;
  }

  .category-list button.active {
    font-weight: 700;
    padding: 1.2rem 3rem;
    color: #000;
    background-color: #ffb900;
    border-color: #ffb900;
    border-radius: 3.4rem;
  }
  @media ${({ theme }) => theme.device.mobile} {
    margin-bottom: 6rem;
    .category-list {
      margin: 0 -4.2rem;
      padding-left: 4.2rem;
      white-space: nowrap;
      overflow: auto;
    }
    .category-list li {
      margin-right: 4.8rem;
    }
    .category-list li + li {
      margin-left: 0;
    }

    .category-list button {
      font-size: 2.8rem;
    }
    .category-list button.active {
      padding: 1.5rem 3.2rem;
    }
  }
`
