import React, { useState, useEffect } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import api from 'common/api'
import { Link } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'

import styled, { css, ThemeProvider } from 'styled-components'
import theme from 'common/theme'
import { SkeletonTheme } from 'react-loading-skeleton'

import ImgView from 'components/ImgView'
import BoardBanner from 'components/board/BoardBanner'
import BoardListReviewItem from 'components/board/BoardListReviewItem'
import CommentReview from './CommentReview'
import SkeletonReview from './SkeletonReview'
import AdminAnswer from './AdminAnswer'

import IcoComment from 'assets/images/ico_comment_114x114.png'
import IcoHeart from 'assets/images/ico_heart_216x216g.png'
import IcoHeartRed from 'assets/images/ico_heart_216x216r.png'
import ImgBannerSrr from '../../assets/images/ico_board_banner_srr_356x356.png'
import BgBannerSrr from '../../assets/images/bg_board_banner_srr.png'

export default function DetailBoard({ type }) {
  const boardDetailInfo = {
    REVIEW: {
      title: `학습지 후기`,
      contents: `하루 한 장으로 영어가 스르르~\n스르르 학습지의 영어 소통 공간입니다.`,
      bgFullbox: `url(${BgBannerSrr}) repeat-y 50% 50% / 100% auto`,
      bgInnerBox: `url(${ImgBannerSrr}) no-repeat 100% 50% / 17.8rem auto`,
      apiContentUrl: '/v2/community-tab/srr-learning-review/detail?learningReviewSeq=',
      apiCommentUrl: '/v2/community-tab/srr-learning-review/comment/list?learningReviewSeq=',
      apiPrevNextUrl: '/v2/community-tab/community/move-and-post?communityPostSeq='
    }
  }[type]

  const location = useLocation()
  const param = location.search
  const { currentSeq } = useParams()
  const deviceCheck = useMediaQuery({ maxWidth: 1024 }) ? true : false
  const [communityInfo, setCommunityInfo] = useState(null)
  const [commentData, setCommentData] = useState(null)

  const [commentRegistTime, setCommentRegistTime] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  // 커뮤니티 상세 정보
  const getCommunityInfo = (seq) => {
    api
      .get(`${boardDetailInfo.apiContentUrl}${seq}`)
      .then((response) => {
        setCommunityInfo(response.data.data)
        setIsLoading(true)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  // 댓글 조회 API
  const getCommentData = (seq) => {
    api
      .get(`${boardDetailInfo.apiCommentUrl}${seq}&pageIndex=1&pageSize=100`)
      .then((response) => {
        if (response.data.meta.code === 200) {
          setCommentData(response.data.data?.comments)
          setCommentRegistTime(response.data.meta.dateTime.replace('-', '/').replace('-', '/'))
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  useEffect(() => {
    getCommunityInfo(currentSeq)
    getCommentData(currentSeq)
    setIsLoading(false)
  }, [currentSeq])

  return (
    <ThemeProvider theme={theme}>
      <BoardBanner title={boardDetailInfo.title} contents={boardDetailInfo.contents} bgFullbox={boardDetailInfo.bgFullbox} bgInnerBox={boardDetailInfo.bgInnerBox} />

      <DetailBoardBox>
        <theme.CardInnerBox>
          {!isLoading ? (
            <SkeletonTheme baseColor="#f7f7fc" highlightColor="#eeeeee">
              <SkeletonReview deviceCheck={deviceCheck} />
            </SkeletonTheme>
          ) : (
            <BoardListReviewBox>
              {communityInfo && (
                <>
                  <BoardListReviewItem deviceCheck={deviceCheck} title={communityInfo.classes.name} contents={communityInfo.contents} registDate={communityInfo.registDate} backgroundImage={communityInfo.registUser.profileImageUrl} userName={communityInfo.registUser.nickName} satisfaction={communityInfo.satisfaction} />
                  <ImgView data={communityInfo} isLoading={isLoading} />
                </>
              )}
              {communityInfo && communityInfo?.answer && <AdminAnswer data={communityInfo.answer} deviceCheck={deviceCheck} backgroundColor={'#F7F7FC'} type={type} />}
              <CommentReview deviceCheck={deviceCheck} getCommentData={getCommentData} data={commentData} commentRegistTime={commentRegistTime} isLoading={isLoading} communityInfo={communityInfo} type={type} currentSeq={currentSeq} />
            </BoardListReviewBox>
          )}
          <StyledLink to={`/community/srr/${type.toLowerCase()}?${param}`}>목록보기</StyledLink>
        </theme.CardInnerBox>
      </DetailBoardBox>
    </ThemeProvider>
  )
}

const StyledLink = styled(Link)`
  display: block;
  width: 27rem;
  margin: 7rem auto 0;
  padding: 1.9rem 0;
  font-size: 2rem;
  color: #000;
  border: 1px solid #cfcfda;
  border-radius: 5px;
  text-align: center;

  @media ${({ theme }) => theme.device.mobile} {
    width: 32.4rem;
    margin: 5rem auto 0;
    padding: 2.8rem 0;
    font-size: 2.7rem;
  }
`

const DetailBoardBox = styled.div`
  padding: 8rem 0 12rem;
  background-color: #f7f7fc;

  .pagination {
    padding: 4rem 0;
  }

  .react-loading-skeleton {
    --base-color: #f4f4f4;
    --highlight-color: #f8f8f8;
  }

  @media ${({ theme }) => theme.device.mobile} {
    padding: 10rem 4.2rem;

    h3 {
      display: none;
    }

    .pagination {
      padding: 8rem 0;
    }
  }
`

const BoardListReviewBox = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 49.9rem;
  margin-bottom: 1.5rem;
  background-color: #fff;
  border-radius: 2rem;
  box-shadow: 0 0 2rem rgb(0 0 0 / 10%);
  overflow: hidden;

  & + div {
    border-top: none;
  }

  @media ${({ theme }) => theme.device.mobile} {
    box-shadow: 0.1rem 0.1rem 2.4rem rgb(0 0 0 / 10%);
  }
`

const ProfileBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  strong {
    margin-left: 1.3rem;
    font-weight: 400;
    font-size: 1.6rem;
  }

  @media ${({ theme }) => theme.device.mobile} {
    margin-top: 3.4rem;
    margin-left: auto;

    strong {
      margin-left: 1.7rem;
      font-size: 2.4rem;
    }
  }
`

const InfoBox = styled.div`
  span {
    font-weight: 400;
    font-size: 1.2rem;
    color: #a0a0b6;

    & + span {
      margin-left: 1rem;
    }
  }

  @media ${({ theme }) => theme.device.mobile} {
    margin-right: auto;

    span {
      font-size: 2.3rem;

      & + span {
        margin-left: 1.4rem;
      }
    }
  }
`

const ContentBox = styled.div`
  padding: 3rem 2.5rem;

  p {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.5;
  }

  @media ${({ theme }) => theme.device.mobile} {
    padding: 5rem 4.2rem;

    p {
      font-size: 2.6rem;
    }
  }
`

const CommentListBox = styled.div`
  margin-top: 1rem;
  border-bottom: 1px solid #e7e7f0;
  background-color: #f7f7fc;

  @media ${({ theme }) => theme.device.mobile} {
    padding: 0 4.2rem;
  }
`

const CountBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 4rem;

  span {
    position: relative;
    font-weight: 700;
    font-size: 1.4rem;
    color: #8d8da0;

    & + span {
      margin-left: 2.6rem;
    }

    &::before {
      content: '';
      display: inline-block;
      width: 2.4rem;
      height: 2.4rem;
      margin-top: -0.2rem;
      margin-right: 0.6rem;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: 100% auto;
      vertical-align: middle;
    }
  }

  @media ${({ theme }) => theme.device.mobile} {
    margin-top: 5rem;

    span {
      font-size: 2rem;

      & + span {
        margin-left: 3.2rem;
      }

      &::before {
        width: 3.8rem;
        height: 3.8rem;
      }
    }
  }
`

const LikedCountItem = styled.span`
  &::before {
    background-image: url(${IcoHeart});
  }

  ${(props) =>
    props.type === 'ACTIVE' &&
    css`
      &::before {
        background-image: url(${IcoHeartRed});
      }
    `}
`

const CommentCountItem = styled.span`
  &::before {
    background-image: url(${IcoComment});
  }
`
