import React, { useEffect } from 'react'

import s from 'pages/chatBot/ChatBot.module.scss'

import { CHAT_COMPONENTS_CONFIG } from 'components/chat/config'

export default function AiTalkTitle({ handleEndedModal, handleSpeaker, mode, mute }) {
  const { svg } = CHAT_COMPONENTS_CONFIG

  useEffect(() => {
    window.pressedBackButton = function () {
      pressedBackButton()
    }

    return () => {
      delete window.pressedBackButton
    }
  }, [])

  const pressedBackButton = () => {
    handleEndedModal()
  }
  return (
    <section className={s.chatHeader}>
      <hgroup className={s.inner}>
        <button className={s.btnClose} onClick={handleEndedModal}>
          {svg.back}
        </button>
        <h3>AI 원어민톡</h3>
        <div className={s.buttons}>
          {mode === 'audio' && (
            <button className={s.btnSpeaker} onClick={handleSpeaker}>
              {mute ? svg.mute : svg.speaker}
            </button>
          )}
        </div>
      </hgroup>
    </section>
  )
}
