import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router'

export default function FaqSearchForm({ styleName, isPlatform }) {
  const location = useLocation()
  const navigate = useNavigate()
  const queryParams = new URLSearchParams(location.search)
  const key = queryParams.get('key')
  const [keyword, setKeyword] = useState(key || '')
  const { currentSeq } = useParams()
  const setCurrentSeq = currentSeq
    ? currentSeq
    : location.pathname === '/service/center' || location.pathname === '/webview/service/center'
    ? 1
    : 2

  const onSubmitSearch = (e) => {
    e.preventDefault()
    handleSearchKeyword()
  }

  const handleSearchKeyword = () => {
    if (keyword.length < 1) return
    if (isPlatform) {
      navigate(`/webview/service/faq/search/${setCurrentSeq}?key=${keyword}`)
    } else {
      navigate(`/service/faq/search/${setCurrentSeq}?key=${keyword}`)
    }
  }

  useEffect(() => {
    setKeyword(key || '')
    return () => setKeyword('')
  }, [key])

  return (
    <form className={styleName} onSubmit={onSubmitSearch}>
      <input
        type="text"
        placeholder="키워드로 검색해보세요!"
        value={keyword}
        onChange={(e) => setKeyword(e.target.value)}
      />
      {isPlatform && <button onClick={handleSearchKeyword}>button</button>}
    </form>
  )
}
