import React from 'react'
import Lottie from 'react-lottie'

import cx from 'classnames'

import s from './ChatFooterAudio.module.scss'
import LottieData from './CircleLoaded.json'
import { CHAT_COMPONENTS_CONFIG } from './config'

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: LottieData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
}

const getAfeterfix = (name) => {
  let afterfix = ''
  switch (name) {
    case 'Sam':
      afterfix = '이'
      break
    case 'Rachael':
      afterfix = '이'
      break
    case 'Tom':
      afterfix = '이'
      break
    default:
      afterfix = '가'
      break
  }

  return afterfix
}

export default function RecorderTooltip({ isListening, isLoading, name = '', msg }) {
  return (
    <>
      <div className={cx(s.tooltip, { [s.hide]: isListening || isLoading })}>
        {CHAT_COMPONENTS_CONFIG.svg.tooltip.recorder}
      </div>
      {isListening && (
        <div className={s.listeningTooltip}>
          <div className={s.loading}>
            <Lottie options={defaultOptions} />
          </div>
          <span className={s.text}>{msg ? msg : '듣고 있어요.'}</span>
        </div>
      )}
      {isLoading && (
        <div className={s.listeningTooltip}>
          <span className={s.text}>
            {name && name !== '' ? `${name}${getAfeterfix(name)}` : 'AI가'} 답변을 작성하고 있어요.
          </span>
        </div>
      )}
    </>
  )
}
