import { Link, useLocation } from 'react-router-dom'

import s from '../aiTalk.module.css'
import { recommendListValues } from '../config'
import usePlatform from 'hooks/usePlatform'
import { openExternalURL } from 'webview/bridge'
import { YANADOO_HOST } from 'common/config'

export default function RecommendLecture() {
  const location = useLocation()
  const { isPlatform } = usePlatform(location.pathname)

  const handleNavigate = (url) => {
    openExternalURL(`${YANADOO_HOST}${url}`)
  }

  return (
    <ul className={s.recommendLecture}>
      {recommendListValues.map((item, index) => {
        return (
          <li key={index}>
            {isPlatform ? (
              <div onClick={() => handleNavigate(item.link)}>
                <div className={s.thumb}>
                  <i className={`${s.badge} ${item.badge === 'NEW' ? s.new : s.best}`}>{item.badge}</i>
                  <img src={item.img} alt={item.title} />
                </div>
                <div>
                  <h5>{item.title}</h5>
                  <p>{item.detail}</p>
                </div>
              </div>
            ) : (
              <Link to={item.link} target="_blank">
                <div className={s.thumb}>
                  <i className={`${s.badge} ${item.badge === 'NEW' ? s.new : s.best}`}>{item.badge}</i>
                  <img src={item.img} alt={item.title} />
                </div>
                <div>
                  <h5>{item.title}</h5>
                  <p>{item.detail}</p>
                </div>
              </Link>
            )}
          </li>
        )
      })}
    </ul>
  )
}
