import React, { useState, useEffect, useCallback } from 'react'
import { useMediaQuery } from 'react-responsive'

import styled from 'styled-components'
import { ThemeProvider } from 'styled-components'

import theme from 'common/theme'

import ModalReviewDetail from '../../../components/modal/ModalReviewDetail'
import ModalPortal from '../../../ModalPortal'

export default function ReviewThumbs({ item, comment }) {
  const deviceCheck = useMediaQuery({ maxWidth: 1024 }) ? true : false
  const [modalopen, setModalopen] = useState(false)

  const [images, setImages] = useState(null)

  const handleOpenModal = useCallback((images) => {
    document.body.classList.add('modal-open')
    setImages(images)
    setModalopen(!modalopen)
  }, [])

  const handleCloseModal = useCallback(() => {
    document.body.classList.remove('modal-open')
    setModalopen(false)
  }, [])

  return (
    <>
      <ThemeProvider theme={theme}>
        {item.attachments?.length > 0 ? (
          <ViewBtn
            type="button"
            onClick={() => {
              handleOpenModal(item.attachments)
            }}
          >
            {item.attachments.map((files, idx) => {
              return (
                <ItemThumbSpan
                  key={idx}
                  style={{
                    background: `url(${files.thumbnailPath ? files.thumbnailPath : files.url}) no-repeat center / cover`
                  }}
                />
              )
            })}
          </ViewBtn>
        ) : (
          <ViewBtn
            type="button"
            onClick={() => {
              handleOpenModal(item)
            }}
            comment={comment}
          >
            <ItemThumbSpan style={{ background: `url(${item.url}) no-repeat center / cover` }} />
          </ViewBtn>
        )}
        {modalopen ? (
          <ModalPortal>
            <ModalReviewDetail deviceCheck={deviceCheck} handleCloseModal={handleCloseModal} images={images} />
          </ModalPortal>
        ) : null}
      </ThemeProvider>
    </>
  )
}

const ViewBtn = styled.button`
  display: flex;
  width: 100%;
  padding: ${(props) => (props.comment ? '1.5rem 0 0 0' : '2.3rem 3.5rem 3.5rem')};
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media ${({ theme }) => theme.device.mobile} {
    padding: ${(props) => (props.comment ? '3rem 0 0 0' : '4rem')};
  }
`

const ItemThumbSpan = styled.span`
  width: 7rem;
  height: 7rem;

  & + span {
    margin-left: 0.5rem;
  }

  @media ${({ theme }) => theme.device.mobile} {
    flex: 0 0 auto;
    width: 12.6rem;
    height: 12.6rem;

    & + span {
      margin-left: 0.9rem;
    }
  }
`
