import React from 'react'
import { useLocation, useNavigate, useParams } from 'react-router'

import s from '../Service.module.scss'

function NavDirection() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
      <path
        d="M4 10L8 6L4 2"
        stroke="#8D8DA0"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default function SearchNavigate() {
  const { currentSeq } = useParams()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const key = queryParams.get('key')
  const navigate = useNavigate()
  return (
    <div className={s.searchNav}>
      <button className={s.btnCenter} onClick={() => navigate('/service/center')}>
        야나두 고객센터
      </button>
      {(currentSeq === '2' || location.pathname === '/service/faq') && (
        <>
          <NavDirection />
          <button className={s.btnFaq} onClick={() => navigate('/service/faq')}>
            자주 묻는 질문
          </button>
        </>
      )}
      {key && (
        <>
          <NavDirection />
          <button className={s.btnResult}>검색 결과</button>
        </>
      )}
    </div>
  )
}
