import AppDownload from 'pages/appDownload'
import AppDownloadVoca from 'pages/appDownloadVoca'
import Custom from 'pages/custom'
import CustomPreview from 'pages/customPreview'
import GnbPreview from 'pages/mainPreview'
import Redirect from 'pages/redirect'
import Temp from 'pages/temp'

const EtcRoutes = [
  { path: 'main-preview', element: <GnbPreview /> },
  { path: 'common/appDownload', element: <AppDownload /> },
  { path: 'common/appDownloadVoca', element: <AppDownloadVoca /> },
  { path: 'custom/:pageUrl', element: <Custom /> },
  { path: 'custom-preview/:pageUrl', element: <CustomPreview /> },

  { path: 'view/temp', element: <Temp /> },
  { path: '*', element: <Redirect /> }
]

export default EtcRoutes
