import { YANADOO_AI_HOST } from 'common/config'

const IMG_HOST = 'https://english.yanadoocdn.com/upload/yanadoo/new/ainadoo/'
const AI_FAQ_CHAT = `${YANADOO_AI_HOST}/faq/chat-ai`
const AI_PROFILE_MAIN = `${IMG_HOST}ico_ainadoo.png`
const AI_PROFILE_THUMB = `${IMG_HOST}ico_profile.png`
const decodeHtml = (html) => {
  const parser = new DOMParser()
  const doc = parser.parseFromString(html, 'text/html')
  return doc.documentElement.textContent
}

export const AI_FAQ_CONFIG = { AI_FAQ_CHAT, AI_PROFILE_MAIN, AI_PROFILE_THUMB, decodeHtml }
