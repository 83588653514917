import React, { useState, useEffect } from 'react'

import ReviewThumbs from 'pages/community/components/ReviewThumbs'
import styled, { css, ThemeProvider } from 'styled-components'
import { convertHtmlCode } from 'utils/convertHtmlCode'

import api from 'common/api'
import theme from 'common/theme'

import AudioPlayer from './AudioPlayer'
import FileDownLoad from './FileDownLoad'
import ThumbNail from './ThumbNail'
import IcoReply from '../../assets/images/ico_reply_90x90.png'
import { timeCheck } from '../../common/common'

export default function Comment({ deviceCheck, data, commentRegistTime, isLoading }) {
  const [replyData, setReplyData] = useState(null)

  // 대댓글 조회 API
  const getReplyData = (seq) => {
    api
      .get(`/v2/community-tab/community/comment/child-list?pageIndex=1&pageSize=100&parentSeq=${seq}`)
      .then((response) => {
        if (response.data.meta.code === 200) {
          setReplyData(response.data.data.childComments)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  useEffect(() => {
    if (isLoading && data.existChildComment) {
      getReplyData(data.commentSeq)
    }
  }, [isLoading])

  return (
    <ThemeProvider theme={theme}>
      <CommentBox>
        <ProfileBox>
          <ThumbNail backgroundImage={data.registUser.profileImageUrl} size={deviceCheck ? '8.2rem' : '5.4rem'} />
          <p>
            <strong>{data.registUser.nickName}</strong>
            <span>
              좋아요 {data.likeCount} &middot; {timeCheck(commentRegistTime, data.registDate.replace('-', '/').replace('-', '/'))}
            </span>
          </p>
        </ProfileBox>
        <DescBox>
          <p>{convertHtmlCode(data.contents)}</p>
          {data.attachment?.type === 'ALL' ? <FileDownLoad data={data.attachment} /> : undefined}
          {data.attachment?.type === 'AUDIO' ? <AudioPlayer data={data.attachment} /> : undefined}
          {data.attachment?.type === 'IMAGE' ? <ReviewThumbs item={data.attachment} comment /> : undefined}
        </DescBox>
      </CommentBox>
      {data.existChildComment &&
        replyData &&
        replyData
          .filter((reReply) => reReply.status !== 'DELETE_OF_WRITER')
          .map((reReply, idx) => {
            return (
              <CommentBox type={'REPLY'} key={idx}>
                <ProfileBox>
                  <ThumbNail backgroundImage={reReply.registUser.profileImageUrl} size={deviceCheck ? '7.4rem' : '4.8rem'} />
                  <p>
                    <strong>{reReply.registUser.nickName}</strong>
                    <span>
                      좋아요 {reReply.likeCount} &middot; {timeCheck(commentRegistTime, reReply.registDate.replace('-', '/').replace('-', '/'))}
                    </span>
                  </p>
                </ProfileBox>
                <DescBox>
                  <p>{reReply.contents}</p>
                  {reReply.attachment?.type === 'ALL' ? <FileDownLoad data={reReply.attachment} /> : undefined}
                  {reReply.attachment?.type === 'AUDIO' ? <AudioPlayer data={reReply.attachment} /> : undefined}
                  {reReply.attachment?.type === 'IMAGE' ? <ReviewThumbs item={reReply.attachment} comment /> : undefined}
                </DescBox>
              </CommentBox>
            )
          })}
    </ThemeProvider>
  )
}

const CommentBox = styled.div`
  position: relative;
  padding: 4rem 2.5rem;

  & + & {
    border-top: 1px solid #e7e7f0;
  }

  ${(props) =>
    props.type === 'REPLY' &&
    css`
      padding-top: 0 !important;
      padding-left: 5.9rem;
      border-top: none !important;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 2.5rem;
        width: 3rem;
        height: 3rem;
        background: url(${IcoReply}) 50% 50% no-repeat;
        background-size: 100% auto;
      }
    `}

  @media ${({ theme }) => theme.device.mobile} {
    padding: 5rem 0;

    ${(props) =>
      props.type === 'REPLY' &&
      css`
        padding-left: 5rem;

        &::before {
          left: 0;
          width: 3.6rem;
          height: 3.6rem;
        }
      `}
  }
`
const ProfileBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  p {
    margin-left: 1.5rem;

    strong {
      display: block;
      font-weight: 700;
      font-size: 1.7rem;
    }

    span {
      display: block;
      margin-top: 0.3rem;
      font-weight: 400;
      font-size: 1.3rem;
      color: #a0a0b6;
    }
  }

  @media ${({ theme }) => theme.device.mobile} {
    p {
      margin-left: 2.2rem;

      strong {
        font-size: 2.6rem;
      }

      span {
        font-size: 2rem;
      }
    }
  }
`
const DescBox = styled.div`
  padding: 1.5rem 0 0 0;

  p {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.5;
  }

  @media ${({ theme }) => theme.device.mobile} {
    padding: 2.5rem 0 0 0;

    p {
      font-size: 2.6rem;
    }
  }
`
