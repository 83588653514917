import s from './CircleSwiper.module.css'
import { aiImageValues } from '../config'

export default function CharacterCircleList({ idx }) {
  return (
    <>
      <ul className={`${s.circleList} ${s.leftList}`}>
        <li>
          <img src={aiImageValues[4 + idx]} alt={3 + idx} />
        </li>
        <li>
          <img src={aiImageValues[5 + idx]} alt={4 + idx} />
        </li>
        <li>
          <img src={aiImageValues[6 + idx]} alt={5 + idx} />
        </li>
        <li>
          <img src={aiImageValues[7 + idx]} alt={5 + idx} />
        </li>
      </ul>
      <ul className={`${s.circleList} ${s.rightList}`}>
        <li>
          <img src={aiImageValues[1 + idx]} alt={1 + idx} />
        </li>
        <li>
          <img src={aiImageValues[2 + idx]} alt={2 + idx} />
        </li>
        <li>
          <img src={aiImageValues[3 + idx]} alt={2 + idx} />
        </li>
        <li>
          <img src={aiImageValues[8 + idx]} alt={6 + idx} />
        </li>
      </ul>
    </>
  )
}
