import React from 'react';
import theme from '../../common/theme';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

// [props 정보]
// best : 베스트 게시물 여부 (boolean)
// subText : {강의이름} || <span>YYYY.MM.DD</span>
// contents : 게시물 내용 text (string)
// subInfo : <em>답변완료</em> YYYY.MM.DD || <span>조회수 {number}</span><span>좋아요 {number}</span><span>댓글 {number}</span>
// userName : {닉네임}
export default function BoardListItem({
  best,
  link,
  subTextTitle,
  subTextDate,
  contents,
  subInfo,
  userName,
}) {
  return (
    <>
      <BoardItemBox>
        <Link to={link} className={best ? `best` : ``}>
          <p>
            <strong>
              {subTextTitle}
              {subTextDate}
            </strong>
            {contents}
          </p>
          <div className="item-info">
            <strong>{subInfo}</strong>
            <span>{userName}</span>
          </div>
        </Link>
      </BoardItemBox>
    </>
  );
}

const BoardItemBox = styled.li`
  border-bottom: 0.1rem solid #e7e7f0;
  a {
    display: block;
    position: relative;
    padding: 2.5rem;
    cursor: pointer;
    &.best:before {
      content: 'Best 질문';
      position: absolute;
      top: 2.4rem;
      right: 2.4rem;
      font-size: 1.4rem;
      color: #269cff;
    }
    p {
      ${theme.StyleEllipsis}
      position:relative;
      margin-bottom: 1.6rem;
      font-weight: 700;
      font-size: 1.6rem;
      color: #000;
      letter-spacing: -0.05rem;

      strong {
        ${theme.StyleEllipsis}
        max-width: 32rem;
        margin-bottom: 1.4rem;
        font-weight: 700;
        font-size: 1.4rem;
        color: #a0a0b6;
        span {
          font-weight: 400;
        }
      }
      em {
        position: absolute;
        right: 0;
        font-weight: 400;
        color: #a0a0b6;
      }
    }
  }
  .item-info {
    display: flex;
    justify-content: flex-start;
    strong {
      flex: 1 auto;
      font-weight: 400;
      font-size: 1.4rem;
      color: #a0a0b6;
      em {
        font-weight: 700;
        color: #17c624;
      }
      span {
        margin-right: 1.2rem;
      }
    }
    & > span {
      ${theme.StyleEllipsis}
      max-width: 25rem;
      font-weight: 400;
      font-size: 1.4rem;
      color: #a0a0b6;
      text-align: right;
    }
  }

  @media ${({ theme }) => theme.device.mobile} {
    a {
      padding: 4.2rem;
      &.best:before {
        top: 4.2rem;
        right: 4.2rem;
        font-size: 2.5rem;
      }
      p {
        strong {
          font-size: 2.5rem;
          margin-bottom: 1.5rem;
        }
        margin-bottom: 2.4rem;
        font-size: 2.9rem;
        line-height: 1.4;
        ${theme.StyleEllipsisTwo}
      }
    }
    .item-info {
      strong {
        font-size: 2.5rem;
        em {
          margin-right: 2rem;
        }
      }
      & > span {
        max-width: 30rem;
        font-size: 2.3rem;
        line-height: 1.4;
      }
    }
  }
`;
