import Modal from './Modal'
import RecommendLecture from './RecommendLecture'
import s from '../aiTalk.module.css'

export default function ModalRecommend({ close, char }) {
  return (
    <Modal onClose={close}>
      <div className={s.modalRecommend}>
        <div className={s.recommendHeader}>
          <button onClick={close}>닫기</button>
        </div>
        <div className={s.scrollBox}>
          <div className={s.recommendContainer}>
            <hgroup>
              {char ? (
                <>
                  <img src={char.images.circle} alt={char.info.fullname} />
                  <h4>
                    {char.info.firstname}
                    {char.afterfix} 자유로운 대화를 원한다면?
                  </h4>
                  <p>
                    AI 원어민톡 자유 이용권이 포함된
                    <br />
                    야나두의 대표 상품을 확인해 보세요.
                  </p>
                </>
              ) : (
                <>
                  <h4>
                    Emily와의 대화가
                    <br />
                    왠지 부족하게 느껴진다면?
                  </h4>
                  <p>야나두와 함께 영어공부 시작해요! 😉</p>
                </>
              )}
            </hgroup>
            <div className={s.recommendNoti}>
              <i></i>
              <span>야나두 영어의 유료 강의를 수강하면 모든 AI 원어민과 대화할 수 있어요</span>
            </div>
            <RecommendLecture />
          </div>
        </div>
      </div>
    </Modal>
  )
}
