import React, { useEffect } from 'react'
import { Outlet } from 'react-router'

import useRemoveInlineStyles from 'hooks/useRemoveInlineStyles'
import { hideTitleBar } from 'webview/bridge'

export default function WebviewCustomer() {
  useRemoveInlineStyles()

  useEffect(() => {
    window['wingBannerDisplay']()
    hideTitleBar()
  }, [])

  return <Outlet />
}
