import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import ModalShare from '../../components/modal/ModalShare';
import ShareItem from '../../components/ShareItem';
import ModalPortal from '../../ModalPortal';

export default function DetailTitle({ eventData, winner, banner }) {
  const status = eventData.eventStatus
    ? eventData.eventStatus.toLowerCase()
    : 'winner';

  const [shareModalOpen, setShareModalOpen] = useState(false);

  const handleOpenShareModal = useCallback((e) => {
    document.body.classList.add('modal-open');
    setShareModalOpen(true);
  }, []);

  const handleCloseShareModal = useCallback((e) => {
    document.body.classList.remove('modal-open');
    setShareModalOpen(false);
  }, []);

  function formatDate(date) {
    if (date) {
      let getDate = date.split(' ');
      return getDate[0];
    } else {
      return null;
    }
  }

  return (
    <>
      <section className={'detail-title-col ' + status}>
        <div className="inner-col">
          <div className="list-detail-title">
            <div className="title-share-area">
              <p className="ellipsis line-clamp-2">{eventData.eventName}</p>
              {!winner && <ShareItem data={eventData} banner={banner} />}
            </div>
            <div className={'detail-info-area ' + status}>
              {!winner && (
                <>
                  {eventData.eventStatus === 'PROGRESS' && (
                    <p className="dday">
                      <em>{eventData.remainingDays}</em>
                    </p>
                  )}
                  <p className="period">
                    <span>{formatDate(eventData.startDate)}</span>~
                    <span>{formatDate(eventData.endDate)}</span>
                  </p>
                  {eventData.winnerAnnouncementUseYn === 'Y' && (
                    <p className="announcement-date">
                      {formatDate(eventData.winnerAnnouncementDate)}
                    </p>
                  )}
                  <button
                    type="button"
                    className="btn btn-share"
                    onClick={handleOpenShareModal}
                  >
                    <i className="blind">공유하기</i>
                  </button>
                </>
              )}
              {winner && (
                <>
                  <p className="period">
                    <span>{formatDate(eventData.startDate)}</span>~
                    <span>{formatDate(eventData.endDate)}</span>
                  </p>
                  <Link
                    to={{
                      pathname: `/event/detail/${eventData.eventSeq}`,
                    }}
                    className="btn-go-event"
                  >
                    <span>해당 이벤트 보기</span>
                  </Link>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
      {shareModalOpen ? (
        <ModalPortal>
          <ModalShare
            title={'이벤트 글을'}
            btnClose
            banner={banner}
            data={eventData}
            handleCloseModal={handleCloseShareModal}
          />
        </ModalPortal>
      ) : null}
    </>
  );
}
