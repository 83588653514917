export const AI_CDN_HOST = 'https://english.yanadoocdn.com/upload/yanadoo/new/aitalk/'
export const aiCharacterKeys = [
  'EMILY_ROSE',
  'SAM_ANDERSON',
  'TONY_SCOTT',
  'RACHAEL_SMITH',
  'LILY_THOMPSON',
  'LAURA_PARKER',
  'TOM_KIM',
  'JENNY_JUNG'
]
export const aiCharacterValuse = [
  {
    key: 'EMILY_ROSE',
    images: {
      rectangle: `${AI_CDN_HOST}person_emily.png`,
      circle: `${AI_CDN_HOST}circle_emily.png`
    },
    info: {
      firstname: 'Emily',
      fullname: 'Emily Rose',
      age: '27',
      mbti: 'ENFP',
      job: 'UX Designer',
      location: 'San Francisco',
      hashtag: ['In⭐gram', '연애❤️', '러닝🏃‍️'],
      hi: '안녕🖐 나는 Emily야.',
      intro:
        '나는 평소 러닝하는 걸 좋아해. 지금은 UX 디자이너로 일하고 있어. 아직 근무한지 얼마 안 되어서 적응이 조금 힘들지만, 회사에서 받은 스트레스는 러닝 크루들과 만나 뛰어 놀며 풀고 있어. 게다가 최근에는 크루에서 만난 Aiden과 썸타고 있어서 설레는 하루하루를 보내는 중이야😀 너는 요즘 어떻게 지내고 있는지 궁금해!',
      comment: 'Hey, there! Can we catch up for a bit?😘'
    },
    tips: {
      content:
        'Emily는 요즘 러닝 크루에서 만난 사람과 썸 타고 있어요. 누구와 만나고 있는지 물어봐 주세요.',
      opening: 'Tell me about the guy you’ve recently met.'
    },
    afterfix: '와'
  },
  {
    key: 'SAM_ANDERSON',
    images: {
      rectangle: `${AI_CDN_HOST}person_sam.png`,
      circle: `${AI_CDN_HOST}circle_sam.png`
    },
    info: {
      firstname: 'Sam',
      fullname: 'Sam Anderson',
      age: '29',
      mbti: 'INTP',
      job: 'Fitness Trainer',
      location: 'Los Angeles',
      hashtag: ['익스트림 스포츠🧗‍♂️', '여행✈️', '헬스 트레이닝💪‍️'],
      hi: '안녕 반가워, 나는 Sam이야.',
      intro:
        '현재 LA에서 헬스 트레이너로 일하고 있지💪 트레이너들은 헬스장에만 박혀있을 거라는 오해는 하지 말아 줘. 나는 스카이다이빙, 암벽 등반, 패러 글라이딩 등 다양한 익스트림 스포츠를 좋아해👍 관광객이 적은 곳으로 여행가는 건 또 다른 내 취미야! 얼마 전부터 새로운 곳으로 여행 가려고 계획 세우고 있는데 같이 여행에 대한 대화를 나눠보는 건 어때?',
      comment: 'Hey! Up for a little conversation?'
    },
    tips: {
      content:
        'Sam은 관광객이 적은 곳으로 여행 다니는 것을 좋아해요. 가장 좋았던 여행지가 어딘지 물어보세요.',
      opening: 'What’s your all-time favorite destination?'
    },
    afterfix: '과'
  },
  {
    key: 'TONY_SCOTT',
    images: {
      rectangle: `${AI_CDN_HOST}person_tony.png`,
      circle: `${AI_CDN_HOST}circle_tony.png`
    },
    info: {
      firstname: 'Tony',
      fullname: 'Tony Scott',
      age: '32',
      mbti: 'ESFJ',
      job: 'Software Engineer',
      location: 'Liverpool',
      hashtag: ['게임🎮', '스포츠⚽', 'IT💻‍️'],
      hi: '안녕! 나는 Tony라고 해.',
      intro:
        '나는 영국에서 소프트웨어 엔지니어로 일하고 있어. 개발자로서 삶은 쉽지 않은 것 같아. 평일에는 밤낮없이 일하고 있어😥 물론 주말에는 축구를 보며 스트레스를 날려버리고 있지! 영국하면 축구 아니겠어? 주말 경기가 없다면 친구들과 함께 게임하면서 시간을 보내곤 해🎮 나는 프리미어리그를 주로 보는데, 너도 축구 좋아하면 같이 얘기 나눠볼래?⚽',
      comment: 'Yo! Up for some chitchat?'
    },
    tips: {
      content:
        'Tony는 영국 프리미어리그 경기를 관람하는 것을 좋아해요. 직접 경기장을 찾은 적 있는지 물어보세요.',
      opening: 'Have you watched the soccer game in person?'
    },
    afterfix: '와'
  },
  {
    key: 'RACHAEL_SMITH',
    images: {
      rectangle: `${AI_CDN_HOST}person_rachael.png`,
      circle: `${AI_CDN_HOST}circle_rachael.png`
    },
    info: {
      firstname: 'Rachael',
      fullname: 'Rachael Smith',
      age: '35',
      mbti: 'ISTP',
      job: 'Primary School Teacher',
      location: 'London',
      hashtag: ['캠핑🌲️', '드라마📺', '금융💰‍️'],
      hi: '안녕. 나는 Rachael이야.',
      intro:
        '런던에서 초등학교 선생님으로 아이들을 가르치고 있어. 평소에 에너지 넘치는 아이들을 상대하다 보니, 여가 시간에는 혼자 시간을 보내는 게 좋더라구😔 휴가 때는 남자친구와 함께 캠핑을 가기도 하지만, 주로 혼자 집에서 드라마나 영화를 볼 때가 가장 행복한 것 같아. 꼭 특별한 취미가 있어야만 행복한 건 아니잖아?🤷‍♀️ 너는 어때? 너와 서로의 인생 드라마나 인생 영화를 공유해 볼래?🎬',
      comment: 'Hello there, everything alright with you?'
    },
    tips: {
      content:
        'Rachael은 퇴근 후 혼자 집에서 영화나 드라마 보는 것을 좋아해요. Rachael의 인생 영화가 무엇인지 물어보세요.',
      opening: 'What’s your all-time favorite movie?'
    },
    afterfix: '과'
  },
  {
    key: 'LILY_THOMPSON',
    images: {
      rectangle: `${AI_CDN_HOST}person_lily.png`,
      circle: `${AI_CDN_HOST}circle_lily.png`
    },
    info: {
      firstname: 'Lily',
      fullname: 'Lily Thompson',
      age: '37',
      mbti: 'ISFP',
      job: 'Vacation Rental Host',
      location: 'Oxford',
      hashtag: ['인테리어🏚', '요리🍱', '독서📚‍️', '육아👶‍️'],
      hi: '안녕. 내 이름은 Lily야.',
      intro:
        '영국에서 게스트 하우스를 운영하고 있어👩‍🍳 아이를 홈스쿨링 시키며 매일 손님을 맞이하는 게 쉬운 일은 아니지만, 아이가 여러 사람을 만나며 다양한 문화를 간접 체험할 수 있어서 좋은 것 같아. 매일 저녁 손님들에게 식사를 제공하고 있는데, 파리에서 유학하며 여러 맛집을 다녔던 게 지금 요리하는 데에 큰 도움이 되는 것 같아. 나와 함께 음식이나 요리를 주제로 대화해보지 않을래?😀',
      comment: 'Hi, lovely! How about we catch up?'
    },
    tips: {
      content:
        'Lily는 자신이 운영하는 게스트 하우스의 손님들에게 직접 만든 음식을 제공하곤 해요. 최근 새롭게 시도한 요리가 있는지 물어보세요.',
      opening: 'Any recent food adventures?'
    },
    afterfix: '와'
  },
  {
    key: 'LAURA_PARKER',
    images: {
      rectangle: `${AI_CDN_HOST}person_laura.png`,
      circle: `${AI_CDN_HOST}circle_laura.png`
    },
    info: {
      firstname: 'Laura',
      fullname: 'Laura Parker',
      age: '39',
      mbti: 'ENTJ',
      job: 'Global Corporation Marketer',
      location: 'New York',
      hashtag: ['골프🏑', '미술🎨', '패션👗‍️'],
      hi: '안녕. 나는 Laura라고 해.',
      intro:
        '뉴욕의 한 글로벌 기업에서 마케터로 일하고 있어. 주변에서 워커홀릭이라는 얘기를 많이 하는데 어느정도 나도 인정하는 부분이야. 연애와 결혼보다는 내 커리어를 쌓는데 더 관심이 많거든! 누구보다 트렌드에 민감해야 하는 마케터이다 보니, 패션에도 관심이 많아. SNS에는 참 옷 잘입는 사람들이 많더라? 물론 나도 그런 편이고!😉 너는 어때? 나와 함께 쇼핑, 패션을 주제로 대화 나눠보지 않을래?🛍',
      comment: 'Hey, friend! Care to chat?'
    },
    tips: {
      content:
        'Laura는 SNS에서 패션에 대한 영감을 많이 얻는다고 해요. Laura가 생각하는 패션 아이콘은 누구인지 물어보세요.',
      opening: 'Do you have fashion icons or influencers you admire?'
    },
    afterfix: '와'
  },
  {
    key: 'TOM_KIM',
    images: {
      rectangle: `${AI_CDN_HOST}person_tom.png`,
      circle: `${AI_CDN_HOST}circle_tom.png`
    },
    info: {
      firstname: 'Tom',
      fullname: 'Tom Kim',
      age: '32',
      mbti: 'ESTJ',
      job: 'Postgraduate student',
      location: 'Boston',
      hashtag: ['독서 📖', '악기 연주 🎸', '여행 🌍'],
      hi: '안녕. 나는 Tom이라고 해.',
      intro:
        '부모님은 모두 한국 분이시고, 난 태어났을 때부터 쭉 Boston에 살고 있어. 지금은 대학원에서 경제학을 전공 중이야. 내가 좋아하는 건 독서, 그리고 기타 연주야🎸 물론 활동적인 것도 좋아해서 틈틈이 미국 곳곳을 여행 다니기도 해. 나와 서로의 관심사에 대해 얘기 나누면서, 내가 내는 영어 문법 퀴즈를 풀어보지 않을래?',
      comment: 'Ready for some English grammar quizzes, buddy?'
    },
    tips: {
      content: 'Tom은 장르 불문하고 책 읽는 것을 좋아해요. 추천 도서가 있는지 물어봐 주세요.',
      opening: "What's the best book you've ever read?"
    },
    afterfix: '과'
  },
  {
    key: 'JENNY_JUNG',
    images: {
      rectangle: `${AI_CDN_HOST}person_jenny.png`,
      circle: `${AI_CDN_HOST}circle_jenny.png`
    },
    info: {
      firstname: 'Jenny',
      fullname: 'Jenny Jung',
      age: '22',
      mbti: 'ENFJ',
      job: 'College student',
      location: 'Berkeley',
      hashtag: ['SNS 📱', 'K POP 🎤', '외국어 학습 ✍️'],
      hi: '반가워 나는 Jenny야.',
      intro:
        'UC Berkeley에서 영문학을 전공하고 있어. 부모님께서 한국인임을 잊지 말라고 하셔서 집에서는 한국어로 대화하고 있어. 그 영향인지 한국 문화가 익숙하고, K-POP을 정말 사랑해😘 언어 교류를 하면서 한국 문화를 사랑하는 친구들을 많이 사귀었다구😉 그리고 난 SNS로 숏폼 영상 만드는 것도 좋아해! 내가 주로 쓰는 신조어와 슬랭에 대해 얘기해줄까?',
      comment: 'Are you familiar with any new slang?'
    },
    tips: {
      content: 'Jenny는 SNS로 많은 팔로워들과 교류하고 있어요. 요즘 핫한 신조어를 물어보세요.',
      opening: 'What slang is trending recently?'
    },
    afterfix: '와'
  }
]
export const spacialAiValues = [
  {
    img: `${AI_CDN_HOST}slider_image_jenny.png`,
    subTitle: '실제 원어민이 쓰는 영어가 궁금하신가요? ',
    title: `Gen Z 세대가 사용하는 \n신조어를 알려드릴게요.✌🏻`,
    detail:
      'SNS에서 자주 사용되는 트렌디한 표현들을 알려드릴게요.\n배운 표현을 대화하며 바로 사용해봐요.'
  },
  {
    img: `${AI_CDN_HOST}slider_image_lily.png`,
    subTitle: '무엇이든 물어보세요. 다 알려드릴게요.',
    title: `영어 문장이 떠오르지 않는다면\n저에게 물어보세요.💛`,
    detail: `하고 싶은 말을 영어로 말할 수 있게 도와줄게요.\n딱, 한마디만 말해보세요. 다음 대화는 제가 책임질게요.`
  },
  {
    img: `${AI_CDN_HOST}slider_image_tom.png`,
    subTitle: '문법이 틀리진 않았을까 걱정되시나요?',
    title: `문법 퀴즈를 풀며 가볍게 대화해봐요.\n틀린 문장은 바로 피드백 해드릴게요.✍🏻`,
    detail: `즉각적인 문법 교정은 제가 책임질게요.
필요하다면 틀린 이유까지 친절히 설명해 드려요.`
  },
  {
    img: `${AI_CDN_HOST}slider_image_rachel.png`,
    subTitle: '틀려도 괜찮아요. 가벼운 마음으로 대화해요.',
    title: `문장이 틀려도, 한국어를 혼용해도\n자연스러운 대화가 가능해요!😊`,
    detail: `저와 대화할 때는 표현이 틀릴까 걱정하지 않아도 돼요.\n친구와 이야기하듯 가벼운 마음으로 말해보세요.`,
    info: `* 한/영 혼용은 채팅 모드에 한정되며, 음성 모드는 영어로만 대화할 수 있습니다.`
  },
  {
    img: `${AI_CDN_HOST}slider_image_sam.png`,
    subTitle: '우린 관심사도 통하는 찐친 같은 사이니까!',
    title: `여행 ✈️ 음식 🍔 스포츠 등 🏀 \n관심 주제로 대화해 보세요!`,
    detail: `우리는 공통 관심사를 가진 사이니까 대화가 더욱\n풍성해지고, 수다 떨듯이 편하게 말할 수 있을 거예요.`
  }
]
export const androidValues = [
  {
    pc: `${AI_CDN_HOST}aos_01.png`,
    mo: `${AI_CDN_HOST}m_aos_01.png`,
    text: '먼저, 설정 → 일반 → 기본 키보드 및 추가 키보드 설정 → 삼성 키보드 → 음성 입력 → 삼성 음성 입력 또는 Google 음성 입력을 선택해 주세요.'
  },
  {
    pc: `${AI_CDN_HOST}aos_02.png`,
    mo: `${AI_CDN_HOST}m_aos_02.png`,
    text: '설정 → 일반 → 삼성 키보드 설정 → 키보드 툴바를 ON으로 설정해 주세요.'
  },
  {
    pc: `${AI_CDN_HOST}aos_03.png`,
    mo: `${AI_CDN_HOST}m_aos_03.png`,
    text: '영문 키보드 상단 키보드 툴바의 음성 입력 아이콘을 선택해 주세요.'
  },
  {
    pc: `${AI_CDN_HOST}aos_04.png`,
    mo: `${AI_CDN_HOST}m_aos_04.png`,
    text: '음성 아이콘을 활성화한 후 음성 채팅을 시작해 주세요.'
  }
]
export const iosValues = [
  {
    pc: `${AI_CDN_HOST}ios_01.png`,
    mo: `${AI_CDN_HOST}m_ios_01.png`,
    text: '먼저, 설정 > 일반 > 키보드 > 받아쓰기 활성화가 “ON”이 되었는지 확인해 주세요.'
  },
  {
    pc: `${AI_CDN_HOST}ios_02.png`,
    mo: `${AI_CDN_HOST}m_ios_02.png`,
    text: '디바이스 키보드 내 언어 설정 아이콘을 선택해 주세요.'
  },
  {
    pc: `${AI_CDN_HOST}ios_03.png`,
    mo: `${AI_CDN_HOST}m_ios_03.png`,
    text: '키보드 언어를 “English”로 변경해 주세요.'
  },
  {
    pc: `${AI_CDN_HOST}ios_04.png`,
    mo: `${AI_CDN_HOST}m_ios_04.png`,
    text: '받아쓰기(마이크) 아이콘을 선택해 주세요.'
  },
  {
    pc: `${AI_CDN_HOST}ios_05.png`,
    mo: `${AI_CDN_HOST}m_ios_05.png`,
    text: '받아쓰기 언어가 영문(EN)으로 설정됐는지 확인해 주세요.'
  },
  {
    pc: `${AI_CDN_HOST}ios_06.png`,
    mo: `${AI_CDN_HOST}m_ios_06.png`,
    text: '받아쓰기 활성화 후 음성 채팅을 시작해 주세요.'
  }
]
export const stepListValues = [
  {
    pc: `${AI_CDN_HOST}box1.png`,
    mo: `${AI_CDN_HOST}m_step_01.png`,
    text: '영어 자신감 UP! 어느새 영어가 툭!'
  },
  {
    pc: `${AI_CDN_HOST}box2.png`,
    mo: `${AI_CDN_HOST}m_step_02.png`,
    text: '영어 자신감 UP! 어느새 영어가 툭!'
  },
  {
    pc: `${AI_CDN_HOST}box3.png`,
    mo: `${AI_CDN_HOST}m_step_03.png`,
    text: '영어 자신감 UP! 어느새 영어가 툭!'
  },
  {
    pc: `${AI_CDN_HOST}box4.png`,
    mo: `${AI_CDN_HOST}m_step_04.png`,
    text: '영어 자신감 UP! 어느새 영어가 툭!'
  }
]
export const btnValue = {
  pc: `${AI_CDN_HOST}btn_conversation.png`,
  mo: `${AI_CDN_HOST}m_btn_conversation.png`,
  text: '야나두 AI 친구와 대화하기'
}
export const sectionTitleValue = {
  pc: `${AI_CDN_HOST}img_pc_section01_title_v2.png`,
  mo: `${AI_CDN_HOST}img_m_section01_title_v2.png`,
  text: 'AI 원어민톡'
}
export const imgGradientValue = `${AI_CDN_HOST}text_gradation.gif`
export const aiConversationValues = {
  EMILY_ROSE: {
    key: 'EMILY_ROSE',
    images: {
      rectangle: `${AI_CDN_HOST}person_emily.png`,
      circle: `${AI_CDN_HOST}circle_emily.png`
    },
    info: {
      firstname: 'Emily',
      fullname: 'Emily Rose',
      age: '27',
      mbti: 'ENFP',
      job: 'UX Designer',
      location: 'San Francisco',
      hashtag: ['In⭐gram', '연애❤️', '러닝🏃‍️']
    },
    afterfix: '와'
  },
  SAM_ANDERSON: {
    key: 'SAM_ANDERSON',
    images: {
      rectangle: `${AI_CDN_HOST}person_sam.png`,
      circle: `${AI_CDN_HOST}circle_sam.png`
    },
    info: {
      firstname: 'Sam',
      fullname: 'Sam Anderson',
      age: '29',
      mbti: 'INTP',
      job: 'Fitness Trainer',
      location: 'Los Angeles',
      hashtag: ['익스트림 스포츠🧗‍♂️', '여행✈', '헬스 트레이닝💪‍️']
    },
    afterfix: '과'
  },
  TONY_SCOTT: {
    key: 'TONY_SCOTT',
    images: {
      rectangle: `${AI_CDN_HOST}person_tony.png`,
      circle: `${AI_CDN_HOST}circle_tony.png`
    },
    info: {
      firstname: 'Tony',
      fullname: 'Tony Scott',
      age: '32',
      mbti: 'ESFJ',
      job: 'Software Engineer',
      location: 'Liverpool',
      hashtag: ['게임🎮', '스포츠⚽', 'IT💻‍️']
    },
    afterfix: '와'
  },
  RACHAEL_SMITH: {
    key: 'RACHAEL_SMITH',
    images: {
      rectangle: `${AI_CDN_HOST}person_rachael.png`,
      circle: `${AI_CDN_HOST}circle_rachael.png`
    },
    info: {
      firstname: 'Rachael',
      fullname: 'Rachael Smith',
      age: '35',
      mbti: 'ISTP',
      job: 'Primary School Teacher',
      location: 'London',
      hashtag: ['캠핑🌲️', '드라마📺', '금융💰‍️']
    },
    afterfix: '과'
  },
  LILY_THOMPSON: {
    key: 'LILY_THOMPSON',
    images: {
      rectangle: `${AI_CDN_HOST}person_lily.png`,
      circle: `${AI_CDN_HOST}circle_lily.png`
    },
    info: {
      firstname: 'Lily',
      fullname: 'Lily Thompson',
      age: '37',
      mbti: 'ISFP',
      job: 'Vacation Rental Host',
      location: 'Oxford',
      hashtag: ['인테리어🏚', '요리🍱', '독서📚‍️', '육아👶‍️']
    },
    afterfix: '와'
  },
  LAURA_PARKER: {
    key: 'LAURA_PARKER',
    images: {
      rectangle: `${AI_CDN_HOST}person_laura.png`,
      circle: `${AI_CDN_HOST}circle_laura.png`
    },
    info: {
      firstname: 'Laura',
      fullname: 'Laura Parker',
      age: '39',
      mbti: 'ENTJ',
      job: 'Global Corporation Marketer',
      location: 'New York',
      hashtag: ['골프🏑', '미술🎨', '패션👗‍️']
    },
    afterfix: '와'
  },
  TOM_KIM: {
    key: 'TOM_KIM',
    images: {
      rectangle: `${AI_CDN_HOST}person_tom.png`,
      circle: `${AI_CDN_HOST}circle_tom.png`
    },
    info: {
      firstname: 'Tom',
      fullname: 'Tom Kim',
      age: '32',
      mbti: 'ESTJ',
      job: 'Postgraduate student',
      location: 'Boston',
      hashtag: ['독서 📖', '악기 연주 🎸', '여행 🌍']
    },
    afterfix: '과'
  },
  JENNY_JUNG: {
    key: 'JENNY_JUNG',
    images: {
      rectangle: `${AI_CDN_HOST}person_jenny.png`,
      circle: `${AI_CDN_HOST}circle_jenny.png`
    },
    info: {
      firstname: 'Jenny',
      fullname: 'Jenny Jung',
      age: '22',
      mbti: 'ENFJ',
      job: 'College student',
      location: 'Berkeley',
      hashtag: ['SNS 📱', 'K POP 🎤', '외국어 학습 ✍️']
    },
    afterfix: '와'
  }
}
export const aiImageValues = [
  `${AI_CDN_HOST}circle_emily.png`,
  `${AI_CDN_HOST}circle_sam.png`,
  `${AI_CDN_HOST}circle_tony.png`,
  `${AI_CDN_HOST}circle_rachael.png`,
  `${AI_CDN_HOST}circle_lily.png`,
  `${AI_CDN_HOST}circle_laura.png`,
  `${AI_CDN_HOST}circle_tom.png`,
  `${AI_CDN_HOST}circle_jenny.png`,
  `${AI_CDN_HOST}circle_emily.png`,
  `${AI_CDN_HOST}circle_sam.png`,
  `${AI_CDN_HOST}circle_tony.png`,
  `${AI_CDN_HOST}circle_rachael.png`,
  `${AI_CDN_HOST}circle_lily.png`,
  `${AI_CDN_HOST}circle_laura.png`,
  `${AI_CDN_HOST}circle_tom.png`,
  `${AI_CDN_HOST}circle_jenny.png`
]
export const recommendListValues = [
  {
    img: `${AI_CDN_HOST}img_recommend_thmb_01.png`,
    badge: 'NEW',
    title: '10분 패키지',
    detail: '170만이 선택한 영어 왕초보 탈출 솔루션',
    link: '/promotion-landing/bestAwards'
  },
  {
    img: `${AI_CDN_HOST}img_recommend_thmb_02.png`,
    badge: 'BEST',
    title: '스르르 학습지',
    detail: '왕초보가 원어민 영어를 할 수 있게 도와주는 학습지 완결판',
    link: '/promotion-landing/SRRfullpackage'
  },
  {
    img: `${AI_CDN_HOST}img_recommend_thmb_03.png`,
    badge: 'NEW',
    title: '오드리 버디톡',
    detail: '꼭 필요한 기초 문법과 표현으로 원어민과 찐친되기',
    link: '/promotion-landing/buddytalk'
  },
  {
    img: `${AI_CDN_HOST}img_recommend_thmb_04.png`,
    badge: 'BEST',
    title: '원예나 앞자리 영어',
    detail: '한 번 배우면 평생 써먹는 만능 영어 이론',
    link: '/promotion-landing/frontEnglish'
  }
]
export const stepValues = [
  {
    badge: 'STEP 1',
    title: '영어 자신감 UP!\n어느새 영어가 툭!',
    detail:
      '모르는 단어는 한국어로 말해도 OK!\n영어 문장 말하기에 집중해서 이야기하다 보면 어느새 영어로 대화하는 ‘나’를 발견할 거예요.',
    sub: '🥰 I love the main character’s 자신감.',
    icon: `${AI_CDN_HOST}step_ico_01.png`
  },
  {
    badge: 'STEP 2',
    title: '요즘 쓰는 진짜 영어를\n말할 수 있어요.',
    detail:
      'AI 친구의 답변에 귀 기울여 보세요!\n실제 원어민이 자주 사용하는 문장과 어휘를 배우고 말할 수 있어요.',
    sub: '⚽ 축덕 = soccer fanatic 👗 꾸안꾸 = effortless vibe',
    icon: `${AI_CDN_HOST}step_ico_02.png`
  },
  {
    badge: 'STEP 3',
    title: '리액션까지\n자연스러운 티키타카!',
    detail: '찐 원어민과 대화하듯,\n상황에 맞는 자연스러운 리액션을 배우고 따라 할 수 있어요.',
    sub: '👌 Absolutely!, Sure thing! 등등',
    icon: `${AI_CDN_HOST}step_ico_03.png`
  },
  {
    badge: 'STEP 4',
    title: '대화는 재밌게,\n피드백은 빠르게!',
    detail:
      '피드백이 확실한 AI원어민 친구를 원한다면? \nTom, Jenny와 대화해 보세요! \n빠른 피드백으로 나만의 튜터가 되어줄 거예요.',
    sub: '📝 Tom과 Jenny는 대화 중 즉각적인 피드백을 제공해요.',
    icon: `${AI_CDN_HOST}step_ico_04_v2.png`
  }
]
export const loadingValue = `${AI_CDN_HOST}loading_dot.json`
export const metaValues = {
  title: '나만의 AI 원어민 친구! AI 원어민톡',
  description: '언제 어디서나 편하게 찐친과 톡하듯이!',
  og: 'https://english.yanadoocdn.com/upload/yanadoo/new/aitalk/img_og_aiTalk_v2.png'
}
