import React from 'react'

import ModalPortal from 'ModalPortal'

import s from './ModalAiTravel.module.scss'

export default function ModalFeedNot({ onClick }) {
  return (
    <ModalPortal>
      <div className={s.modalAiTravel}>
        <div className={s.modalContent}>
          <h3>
            AI와의 대화 진행 후<br />
            피드백을 받으실 수 있습니다.
          </h3>
          <div className={s.btnBox}>
            <button className={s.btnConfirm} onClick={onClick}>
              확인
            </button>
          </div>
        </div>
      </div>
    </ModalPortal>
  )
}
