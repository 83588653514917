import ModalPortal from 'ModalPortal'

import s from '../aiTalk.module.css'

export default function ModalToast({ type }) {
  return (
    <ModalPortal>
      <div className={s.toastMsg}>
        {type === 'TRANSLATE' ? (
          <>
            번역 기능은 추후 업데이트 예정입니다.
            <br />
            성장 중인 AI 야나두에 많은 관심 부탁드립니다.
          </>
        ) : (
          <>공백을 포함하여 200자 미만으로 입력해 주세요.</>
        )}
      </div>
    </ModalPortal>
  )
}
