import { YAFIT_CYCLE_LINK } from 'common/config'

export const textRollingListValues = [
  {
    seq: 1,
    title: '수백만 회원이 직접 검증한 콘텐츠',
    desc: '아냐두는 15년 간의 회원 데이터를 기반으로 콘텐츠를 설계하고 서비스합니다. 겉만 비슷하게 흉내내는 콘텐츠와는 다릅니다.  가격이 비슷하다고 모두 동일한 콘텐츠가 아닙니다. 수많은 회원의 데이터를 기반으로 지속적인 개발을 수행한 콘텐츠만이 그 효과를 발휘 합니다.'
  },
  {
    seq: 2,
    title: '집요한 연구소의 끊임 없는 노력',
    desc: '야나두의 모든 서비스는 ‘하루 10분 집요한 연구소’에서 4만여 시간을 들여 연구한 결과를 바탕으로 구성되어 있습니다. 처음부터 끝까지 집요하게 파고드는 연구의 성과로 수강생들에게 주어진 시간을 가장 효율적으로 활용하는 방법을 제시합니다.'
  },
  {
    seq: 3,
    title: '과학적 근거에 의한 발전',
    desc: '10분의 집중력, 66일 습관 형성 등 모든 활동은 과학적인 근거를 기반으로 제공됩니다. 야나두가 제시하는 커리큘럼과 여러 과정은 과학적인 근거를 기반으로 합니다. 근거 없이 고객을 현혹하는  감성적인 말로 서비스를 제공하지 않습니다.'
  },
  {
    seq: 4,
    title: '야나두의 프로 정신',
    desc: '야나두는 갓 태어난 아마추어가 아닙니다. 같은 시간을 소비하더라도 최적의 효과를 나타낼 수 있도록 프로의 마인드로 경험과 지식을 기반으로 서비스합니다. 항상 고객과 야나두가 프로라는 정신으로 진정성 있는 제품을 개발하기 위해 노력합니다.'
  }
]

export const vodListValues = [
  {
    category: '야나두 1년 차',
    imagePath1:
      'https://english.yanadoocdn.com/upload/yanadoo/pc/sub/beforeAfter/2021/img_media_thumb_yjh_interview.png',
    imagePath2:
      'https://english.yanadoocdn.com/upload/yanadoo/pc/sub/beforeAfter/2021/img_media_thumb_yjh_interview.png',
    linkType: 'LINK',
    linkPath: 'https://www.youtube.com/embed/iSVkC786OAk',
    title: '미스코리아 유지형',
    subTitle: '유지형의 1년 Before & After',
    sortOrder: 1
  },
  {
    category: '50대 주부',
    imagePath1:
      'https://english.yanadoocdn.com/upload/yanadoo/pc/sub/beforeAfter/2021/img_media_thumb_mhg.jpg',
    imagePath2:
      'https://english.yanadoocdn.com/upload/yanadoo/pc/sub/beforeAfter/2021/img_media_thumb_mhg.jpg',
    linkType: 'LINK',
    linkPath: 'https://www.youtube.com/embed/5jZcaBqLsLg',
    title: '수강생 민혜경',
    subTitle: '지금도 늦지 않은 영어 도전기',
    sortOrder: 2
  },
  {
    category: '외과의사',
    imagePath1:
      'https://english.yanadoocdn.com/upload/yanadoo/pc/sub/beforeAfter/2021/img_media_thumb_kgj.png',
    imagePath2:
      'https://english.yanadoocdn.com/upload/yanadoo/pc/sub/beforeAfter/2021/img_media_thumb_kgj.png',
    linkType: 'LINK',
    linkPath: 'https://www.youtube.com/embed/WCf0bdxYYHA',
    title: '수강생 김광조',
    subTitle: '30년만에 영어에 재도전 하는 의사',
    sortOrder: 3
  },
  {
    category: '모델',
    imagePath1:
      'https://english.yanadoocdn.com/upload/yanadoo/pc/sub/beforeAfter/2021/img_media_thumb_ly.png',
    imagePath2:
      'https://english.yanadoocdn.com/upload/yanadoo/pc/sub/beforeAfter/2021/img_media_thumb_ly.png',
    linkType: 'LINK',
    linkPath: 'https://www.youtube.com/embed/qJJaRZffsZU',
    title: '수강생 이영',
    subTitle: '13만 팔로워 모델의 특별한 공부법',
    sortOrder: 4
  }
]

export const serviceBannerListValues = [
  {
    id: 1,
    bedge: 'NEW',
    bedgeBgColor: '#ff5647',
    bedgeTextColor: '#fff',
    bannerText: '야나두 피트니스, 지금 선택하면 월 1만원대로 완벽한 몸매까지!',
    bgColor: '#FFB900',
    textColor: '#000',
    link: YAFIT_CYCLE_LINK
  },
  {
    id: 2,
    bedge: 'NEW',
    bedgeBgColor: '#ff5647',
    bedgeTextColor: '#fff',
    bannerText: '야나두가 "평생 책임집니다" (feat.아이패드는 거들 뿐)',
    bgColor: '#FFB900',
    textColor: '#000',
    link: '/promotion-landing/tabPackage10th'
  },
  {
    id: 3,
    bedge: 'NEW',
    bedgeBgColor: '#ff5647',
    bedgeTextColor: '#fff',
    bannerText: '3만원 할인쿠폰! 카카오채널 친구추가하면 바로 증정!',
    bgColor: '#FFB900',
    textColor: '#000',
    link: 'https://pf.kakao.com/_xeWxjMu'
  }
]
