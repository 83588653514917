import { useEffect, useState } from 'react'

import api from 'common/api'

export default function useGetEventPopup(pathname) {
  const [popup, setPopup] = useState(null)

  function getEventPopup(popupPathname) {
    if (!popupPathname) return
    api
      .get(`/v2/popup/current?pathname=${popupPathname}`)
      .then((response) => {
        const res = response.data.data
        setPopup(res)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  useEffect(() => {
    getEventPopup(pathname)
    return () => setPopup(null)
  }, [pathname])

  return popup
}
