import React, { useEffect, useState } from 'react';
import '../assets/styles/btnMore.scss';

let addItemCount = null;

// 디폴트 개수 세팅 (디폴트 개수, 전체 리스트 개수)
export const setDefaultItemCount = (count, dataLength) => {
  addItemCount = count;

  if (dataLength < count) {
    addItemCount = dataLength;
  } else {
    addItemCount = count;
  }
};

// 보여지는 개수가 바뀔때마다 리스트를 추가하여 업데이트
export const getMoreDataList = (showItems, list) => {
  let datas = [];

  for (let i = 0; i < showItems; i++) {
    let data = list[i];
    datas.push(data);
  }
  
  return datas;
};

export const BtnMoreAllList = ({ reviewData, isLoading, handleAddReviewList }) => {
  
  let { totalCount, list } = reviewData;
  const [showItems, setShowItems] = useState(0);
  const [moreBtn, setMoreBtn] = useState(true);

  // 현재 보여지고 있는 개수 세팅, 디폴트 개수 세팅, 더보기 버튼 유무
  const addShowItems = () => {
    let currentShowItem = showItems + addItemCount;

    setShowItems(currentShowItem);

    if (totalCount - currentShowItem <= addItemCount) {
      addItemCount = totalCount - currentShowItem;
    }

    setMoreBtn(!(currentShowItem === totalCount));
  };

  useEffect(() => {
    if (!isLoading) {
      addShowItems();
    } else {
      setShowItems(0);
    }
  }, [isLoading]);

  useEffect(() => {
    handleAddReviewList(getMoreDataList(showItems, list));
  }, [showItems]);

  return (
    moreBtn && (
      <div className="list-more-box">
        <button type="button" className="btn btn-more" onClick={addShowItems}>
          <span>더보기</span>
        </button>
      </div>
    )
  );
};
