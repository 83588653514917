import React from 'react'
import { Link } from 'react-router-dom'

import styled from 'styled-components'

import bgGroup_m from 'assets/images/bg_m_group_v2.png'
import bgGroup from 'assets/images/bg_pc_group_v2.png'

export default function BottomMenuSection() {
  return (
    <GroupSection>
      <InnerCol>
        <h3>
          No.1 성장 그룹
          <br />
          야나두와 함께
        </h3>
        <MenuList>
          <MenuListItem>
            <Link to="/EnglishHome">10분 영어배우기 &gt;</Link>
          </MenuListItem>
          <MenuListItem>
            <Link to="https://yafit.yanadoo.co.kr/yanadoo/promotion/yafitAllInOne.html">
              가볍게 다이어트 &gt;
            </Link>
          </MenuListItem>
          <MenuListItem>
            <Link to="/store/list">야나두 클래스 &gt;</Link>
          </MenuListItem>
        </MenuList>
      </InnerCol>
    </GroupSection>
  )
}
const InnerCol = styled.div`
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 3rem;
  @media ${({ theme }) => theme.device.mobile} {
    padding: ${(props) => props.padding || `0 4.2rem`};
  }
`
const GroupSection = styled.section`
  padding: 10rem 0;
  background: #b1a99f url(${bgGroup}) no-repeat center / cover;

  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  h3 {
    font-weight: 700;
    font-size: 2.8rem;
    color: #fff;
    line-height: 3.6rem;
    letter-spacing: -0.5px;
    text-align: left;
  }

  @media ${({ theme }) => theme.device.mobile} {
    background: #b1a99f url(${bgGroup_m}) no-repeat top center / auto 100%;

    & > div {
      align-items: flex-start;
    }
    h3 {
      font-size: 3.6rem;
      line-height: 4.4rem;
    }
  }
`
const MenuList = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${({ theme }) => theme.device.mobile} {
    flex-direction: column;
    align-items: stretch;
  }
`
const MenuListItem = styled.li`
  flex: 1 auto;
  margin: 0 0 0 3rem;
  background: #fff;
  border-radius: 5rem;

  &:first-child {
    margin-left: 0;
  }

  a {
    display: block;
    padding: 2rem 5rem;
    font-weight: 400;
    font-size: 2rem;
    color: #000;
    line-height: 2.6rem;
    box-sizing: border-box;
  }

  @media ${({ theme }) => theme.device.mobile} {
    margin: 0 0 2.4rem;

    a {
      font-size: 2.4rem;
    }
  }
`
