/**
 * HTML 인코딩된 엔터티를 해당하는 문자로 변환
 *
 * @param {string} str - 변환할 HTML 인코딩된 문자열
 * @returns {string} - 디코딩된 문자열
 */
export function convertHtmlCode(str) {
  str = str.replaceAll('&lt;', '<')
  str = str.replaceAll('&gt;', '>')
  str = str.replaceAll('&#x2F;', '/')
  str = str.replaceAll('&#40;', '(')
  str = str.replaceAll('&#41;', ')')
  str = str.replaceAll('&#58;', ':')
  str = str.replaceAll('&#46;', '.')
  str = str.replaceAll('&quot;', '"')
  str = str.replaceAll('&#x27;', "'")

  return str
}
