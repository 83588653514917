const routerValues = {
  h2HidePaths: [
    '/promotion-landing/applePackageUI',
    '/promotion-landing/macbookPackage_performance',
    '/promotion-landing/laptopPackage_performance',
    '/promotion-landing/SRRfullpackage_performance'
  ]
}

export default routerValues
