import { useState } from 'react'

import ResponsiveImage from 'components/ResponsiveImage'

import s from './TrainingStep.module.css'
import { HOST_CDN } from '../../config'
import BtnPulse from '../BtnPulse'

export default function TrainingStep06() {
  const [target, setTarget] = useState({ one: false, two: false })

  const handleToggle = (key) => {
    setTarget({ ...target, [key]: !target[key] })
  }

  return (
    <div className={`${s.step} ${s.step06}`}>
      <span className={s.btn01}>
        <BtnPulse active event={() => handleToggle('one')} />
      </span>
      <span className={s.tooltip01}>
        {target.one && (
          <ResponsiveImage
            pc={`${HOST_CDN}step_06_hover_01.png`}
            mo={`${HOST_CDN}step_06_hover_01_m.png`}
            alt={'원하는 주제로 골라 대화해요!'}
            maxWidth={1024}
          />
        )}
      </span>
    </div>
  )
}
