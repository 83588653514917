export default function ArrowSvg({ isBlack = false }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 31" fill="none">
      <path
        d="M22.033 17.113H0V12.9564H22.033L12.0336 2.96515L15 0.046875L30 15.0347L15 30.0225L12.0336 27.1043L22.033 17.113Z"
        fill={isBlack ? '#000' : '#fff'}
      />
    </svg>
  )
}
