import React from 'react'

import s from './Chat.module.scss'
import { AINADOO_CONFIG } from '../config'

export default function InitMessage({ pageType }) {
  return (
    <li className={s.feedItem}>
      <div className={s.aiMessage}>
        <div className={s.msgThumb}>
          <img
            src={`${AINADOO_CONFIG.IMG_HOST}${
              pageType === 'srr' ? 'img_ainadoo_profile_srr' : 'img_ainadoo_profile'
            }.png`}
            alt="AI 상담원"
          />
        </div>
        <dl>
          <dt className={s.msgName}>{pageType === 'srr' ? 'AI 스르르' : 'AI 나두'}</dt>
          <dd className={s.message}>
            {pageType === 'srr' ? (
              <>
                안녕하세요!
                <br />
                저는 학습 도우미 AI 스르르 입니다.
                <br />
                궁금한 점이 있으신가요?
              </>
            ) : (
              <>
                안녕하세요. AI 나두입니다!
                <br />
                학습 중 이해되지 않는 내용을 물어보세요. 🚀
              </>
            )}
          </dd>
        </dl>
      </div>
    </li>
  )
}
