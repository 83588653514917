import React, { useEffect } from 'react'

import useFloatingElementStore from 'store/useFloatingElementStore'

const FloatingBannerHeight = ({ children }) => {
  const { setFloatingElementHeight } = useFloatingElementStore()

  useEffect(() => {
    const element = document.getElementById('fixedBanner')
    if (element) {
      const updateHeight = () => {
        // setTimeout을 사용하여 요소가 완전히 렌더링된 후에 높이를 측정
        setTimeout(() => {
          const height = element.getBoundingClientRect().height

          if (!isNaN(height)) {
            setFloatingElementHeight(height)
          }
        }, 0)
      }

      updateHeight() // 초기 높이 설정
      window.addEventListener('resize', updateHeight)

      return () => window.removeEventListener('resize', updateHeight)
    } else {
      console.error(`Element with id 'fixedBanner' not found.`)
    }
  }, [setFloatingElementHeight])

  return <>{children}</>
}

export default FloatingBannerHeight
