import MetaInfo from 'components/common/MetaInfo'

import BannerSwiper from './component/BannerSwiper'
import BigDataSection from './component/BigDataSection'
import BrandSection from './component/BrandSection'
import ChangeSection from './component/ChangeSection'
import ContactSection from './component/ContactSection'
import LectureSection from './component/LectureSection'
import LevelTestSection from './component/LevelTestSection'
import MotivationSection from './component/MotivationSection'
import ResultSection from './component/ResultSection'
import ReviewSection from './component/ReviewSection'
import TeacherSection from './component/TeacherSection'
import YutubeSection from './component/YutubeSection'
import s from './EnglishHome.module.css'
import useEnglishHome from './hooks/useEnglishHome'

export default function EnglishHome() {
  const { bannerList } = useEnglishHome()
  return (
    <div className={s.englishHome}>
      <MetaInfo
        props={{
          title: '야, 너두 영어 할 수 있어!',
          description: '170만 수강생이 증명하는 효과를 직접 확인해보세요',
          site_name: '하루 10분으로 2024년 영어 왕초보 탈출',
          imgsrc: 'https://english.yanadoocdn.com/upload/yanadoo/new/english/og_english_home.png'
        }}
      />
      <article className={s.wrap}>
        <BannerSwiper bannerList={bannerList} />
        <BrandSection />
        <YutubeSection />
        <LectureSection />
        <MotivationSection />
        <BigDataSection />
        <ChangeSection />
        <ReviewSection />
        <ResultSection />
        <TeacherSection />
        <LevelTestSection />
        <ContactSection />
      </article>
    </div>
  )
}
