import React from 'react'

import s from './Chat.module.scss'
import ChatFeedList from './ChatFeedList'
import ChatTopFaq from './ChatTopFaq'

export default function ChatFeedContainer({ isAiResponse, addToFeed, isPlatform }) {
  return (
    <div className={s.chatFeed}>
      <ChatTopFaq isPlatform={isPlatform} />
      <ChatFeedList isAiResponse={isAiResponse} addToFeed={addToFeed} />
    </div>
  )
}
