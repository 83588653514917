import React from 'react'
import { useNavigate } from 'react-router'

import s from './WebviewCustomer.module.scss'
import { WEBVIEW_SERVICE } from '../config'

export default function NotFindResult() {
  const navigate = useNavigate()
  return (
    <>
      <section className={s.notFind}>
        {WEBVIEW_SERVICE.svg_alert}
        <div className={s.notFindText}>
          <h5>찾으시는 질문이 없나요?</h5>
          <p>AI 상담원에게 언제든지 문의해보세요!</p>
        </div>
        <button
          className={s.helpAiButton}
          onClick={() => navigate('/webview/service/faq/chat?service=YANADOO')}
        >
          <span>AI 상담하기</span>
        </button>
      </section>
      <section className={s.notFindTips}>
        <dl>
          <dt>검색 TIP</dt>
          <dd>단어의 철자가 정확한지 확인해 주세요.</dd>
          <dd>검색어의 단어 수를 줄이거나 다른 검색어로 검색해 보세요.</dd>
          <dd>일반적인 단어로 다시 검색해 보세요.</dd>
        </dl>
      </section>
    </>
  )
}
