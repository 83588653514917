import React, { useState, useEffect, useRef, useCallback } from 'react'

import ModalPortal from 'ModalPortal'

import api from 'common/api'
import * as config from 'common/config'
import CertificationType from 'components/CertificationType'

import ModalFindPwd from './ModalFindPwd'
import Toast from './Toast'
import DateBirth from '../DateBirth'

import 'assets/styles/modal.scss'
import 'assets/styles/modalJoin.scss'
import 'assets/styles/modalFind.scss'

export default function ModalFindId({ title, btnClose, handleCloseModalFindId, handleCloseModal }) {
  const nameRef = useRef()

  const [validStatus, setValidStatus] = useState({
    name: false,
    phone: false,
    email: false,
    birthYear: false,
    birthMonth: false,
    birthDay: false
  })

  const [inputValue, setInputValue] = useState({
    name: '',
    phone: '',
    countryCode: '82',
    email: '',
    birthYear: '',
    birthMonth: '',
    birthDay: '',
    findType: 'CELLPHONE'
  })

  const [isStep, setIsStep] = useState(1)
  const [openToast, setOpenToast] = useState(false)
  const [modalopenPwd, setModalopenPwd] = useState(false)
  const [certifiedComplete, setCertifiedComplete] = useState(false)
  const [isComplete, setIsComplete] = useState(null)
  const [idResult, setIdResult] = useState(null)

  const handleToastStatus = useCallback((status) => {
    setOpenToast(status)
  }, [])

  const defaultCertUrl = config.AUTH_API_HOST + '/auth/v2/sign-in/find-id/check-cert-number?birthday=' + inputValue.birthYear + inputValue.birthMonth + inputValue.birthDay + '&userName=' + inputValue.name

  const defaultTypeUrl = '?birthday=' + inputValue.birthYear + inputValue.birthMonth + inputValue.birthDay + '&userName=' + inputValue.name

  const handleOpenModalFindPwd = useCallback((e) => {
    document.body.classList.add('modal-open')
    setModalopenPwd(true)
  }, [])

  const handleNameCheck = (e) => {
    let nameRegExp = /^[ㄱ-ㅎ가-힣a-zA-Z|]*$/

    if (!nameRegExp.test(e.target.value)) {
      nameRef.current.focus()

      return false
    }

    if (!e.target.value) {
      setInputValue({ ...inputValue, name: '' })
      setValidStatus({ ...validStatus, name: false })

      return false
    }

    setInputValue({ ...inputValue, name: e.target.value })
    setValidStatus({ ...validStatus, name: true })

    return true
  }

  const handleCertificationValue = (checkType, typeValue, countryCodeValue) => {
    const currentType = checkType === 'CELLPHONE' ? 'phone' : 'EMAIL' ? 'email' : null

    if (checkType) {
      if (countryCodeValue) {
        setInputValue({
          ...inputValue,
          [currentType]: typeValue,
          countryCode: countryCodeValue,
          findType: checkType
        })
      } else {
        setInputValue({
          ...inputValue,
          [currentType]: typeValue,
          findType: checkType
        })
      }
      setValidStatus({ ...validStatus, [currentType]: true })

      setCertifiedComplete(true)
    } else {
      setCertifiedComplete(false)
    }
  }

  const handleBirthCheck = (e) => {
    let { name, value } = e.target

    setInputValue({ ...inputValue, [name]: value })

    if (value === 'default') {
      setValidStatus({ ...validStatus, [name]: false })
      return false
    }

    setValidStatus({ ...validStatus, [name]: true })
  }

  const handleFindId = () => {
    api
      .post(config.AUTH_API_HOST + '/auth/v2/sign-in/find-id' + defaultTypeUrl + (inputValue.findType === 'CELLPHONE' ? '&certType=CELLPHONE&cellphone=' + inputValue.phone + '&countryCode=' + inputValue.countryCode : '&certType=EMAIL&email=' + inputValue.email))
      .then((response) => {
        if (response.data.meta.code !== 200) {
          alert(response.data.meta.message)

          return false
        }

        setIdResult(response.data.data.length > 0 ? response.data.data : '')
      })
      .catch((e) => {
        console.log(e)
        return false
      })
  }

  useEffect(() => {
    if (validStatus.name && validStatus.birthYear && validStatus.birthMonth && validStatus.birthDay && (validStatus.phone || validStatus.email) && certifiedComplete) {
      setIsComplete(true)
    } else {
      setIsComplete(false)
    }
  }, [validStatus, certifiedComplete])

  useEffect(() => {
    if (idResult && idResult.length > 0) {
      setIsStep(2)
    }
  }, [handleFindId])

  return (
    <>
      <div className="modal-wrap modal-form find">
        <div className="dimmed" onClick={handleCloseModal}></div>
        <div className="modal-inner">
          {btnClose && (
            <button type="button" className="btn-modal-close" onClick={handleCloseModal}>
              <i className="blind">닫기</i>
            </button>
          )}
          <div className="modal-header">
            <strong>{title}</strong>
          </div>
          {isStep === 1 ? (
            <div className="modal-content">
              <div className="inner">
                <form name="findIdFrm" action="#" className="frm">
                  <div className="form-box">
                    <dl>
                      <dt>이름</dt>
                      <dd>
                        <input type="text" placeholder="이름을 입력해주세요." name="name" ref={nameRef} value={inputValue.name} onChange={handleNameCheck} />
                      </dd>
                    </dl>
                    <DateBirth yearValue={inputValue.birthYear} monthValue={inputValue.birthMonth} dayValue={inputValue.birthDay} handleBirthCheck={handleBirthCheck} />
                    <CertificationType defaultTypeUrl={'/auth/v2/sign-in/find-id/get-cert-number' + defaultTypeUrl} defaultCertUrl={defaultCertUrl} handleToastStatus={handleToastStatus} handleCertificationValue={handleCertificationValue} />
                  </div>
                  <div className="btn-flex-form">
                    <button type="button" onClick={handleFindId} className={'btn btn-edit-submit' + (isComplete ? ' active' : ' disabled')} disabled={isComplete ? false : true}>
                      ID 찾기
                    </button>
                  </div>
                </form>
              </div>
            </div>
          ) : isStep === 2 ? (
            <div className="modal-content type-fixed-button">
              <div className="modal-center-box">
                <div className="modal-center-inner">
                  <div className={'complete-box' + (idResult && idResult.length > 1 ? ' several' : '')}>
                    <dl>
                      <dt>{idResult && idResult.length > 1 ? idResult.length + '개 계정을 찾았습니다.' : '회원님의 아이디 찾기가 완료되었습니다.'}</dt>
                      <dd>
                        {idResult && idResult.length > 1 ? (
                          <ul>
                            {idResult &&
                              idResult.map((list) => (
                                <li key={list.id}>
                                  <p>
                                    <strong>{list.userId}</strong>
                                    <span>{list.joinDate} 가입</span>
                                  </p>
                                </li>
                              ))}
                          </ul>
                        ) : (
                          <>
                            <p>
                              <strong>{idResult && idResult[0].userId}</strong>
                              <span>{idResult && idResult[0].joinDate} 가입</span>
                            </p>
                          </>
                        )}
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
              <div className="btn-flex-form btn-group-bottom">
                <button type="button" className="btn active" onClick={handleCloseModalFindId ? handleCloseModalFindId : handleCloseModal}>
                  <span>로그인</span>
                </button>
                <button type="button" onClick={handleOpenModalFindPwd}>
                  <span>비밀번호 찾기</span>
                </button>
              </div>
            </div>
          ) : null}
          <Toast msg={'인증이 완료되었습니다.'} openToast={openToast} handleToastStatus={handleToastStatus} />
        </div>
      </div>
      {modalopenPwd ? (
        <ModalPortal>
          <ModalFindPwd title={'비밀번호 찾기'} btnClose handleCloseModal={handleCloseModal} />
        </ModalPortal>
      ) : null}
    </>
  )
}
