import Modal from './Modal'
import s from '../aiTalk.module.css'

export default function ModalConfirm({ title, aiInfo, close, confirm }) {
  const { tips, images, info } = aiInfo
  return (
    <Modal onClose={close}>
      <div className={s.modalTips}>
        <hgroup>
          <h5>{title}</h5>
          <button onClick={close}>닫기</button>
        </hgroup>
        <div className={s.content}>
          <div className={s.tipThumb}>
            <img src={images.circle} alt={info.fullname} />
          </div>
          <p>{tips.content}</p>
          <span>{tips.opening}</span>
        </div>
        <div className={s.btnFooter}>
          <button className={s.confirm} onClick={confirm}>
            대화 시작하기
          </button>
        </div>
      </div>
    </Modal>
  )
}
