import { useState } from 'react'

import classNames from 'classnames'

import ModalShare from 'components/modal/ModalShare'

import styles from '../StoreDetail.module.scss'

export default function DetailInfo({ infoRef, detailWrapRef, classData }) {
  const [prodDetail, setProdDetail] = useState(false)
  const [isShowShareModal, setIsShowShareModal] = useState(false)

  const hasPdfFile = classData.pdfFilePath?.length > 0
  const hasAddintionalBooks = classData.additionTypeYn === 'Y'

  const hasCurriculum =
    classData.packageTypeCode === 'CLASS' ||
    classData.packageTypeCode === 'COMBINATION' ||
    classData.packageTypeCode === 'COURSE'

  const handleOpenShareModal = () => {
    setIsShowShareModal(true)
  }

  const handleCloseShareModal = () => {
    setIsShowShareModal(false)
  }

  return (
    <>
      <div className={classNames(styles.detailInfo, 'product-col')}>
        <div className={styles.title} ref={infoRef}>
          <h1>상품소개</h1>
          <button type="button" className={styles.btnShare} onClick={handleOpenShareModal}>
            공유
          </button>
        </div>
        {/* 강의 정보 */}
        {hasCurriculum && (
          <div className={styles.curriculum}>
            <li>
              <i className={styles.count}></i>
              <span>강의 수</span>
              <b>{classData.contentInfo}</b>
            </li>
            <li>
              <i className={styles.period}></i>
              <span>수강 기간</span>
              <b>{classData.learningMonth}개월</b>
            </li>
            <li>
              <i className={styles.level}></i>
              <span>난이도</span>
              <b>
                {classData.studyLevelName === ''
                  ? classData.classLevelInfo
                  : classData.studyLevelName}
              </b>
            </li>
            {(hasPdfFile || hasAddintionalBooks) && (
              <li>
                <i className={styles.benefit}></i>
                <span>제공 혜택</span>
                <b>
                  {hasPdfFile && '강의자료'}
                  {hasPdfFile && hasAddintionalBooks && '&'}
                  {hasAddintionalBooks && '교재'}
                </b>
              </li>
            )}
          </div>
        )}
        {/* 가격 */}
        {classData.packageTypeCode === 'APP' && (
          <div className={styles.curriculum}>
            <p>
              <i className={styles.period}></i>
              <span>수강 기간</span>
              {classData.periodInfo}
            </p>
          </div>
        )}

        <div
          ref={detailWrapRef}
          className={classNames(styles.storeDetail, prodDetail === true && styles.active)}
          dangerouslySetInnerHTML={{
            __html: classData.detail
          }}
        ></div>
        <button
          className="btn-detail-more"
          type="button"
          onClick={() => setProdDetail(true)}
          style={{ display: 'none' }}
        >
          <span>상품정보 더보기</span>
        </button>
      </div>
      {/* 공유 modal */}
      {isShowShareModal && (
        <ModalShare
          title={'페이지를'}
          snsShareTitle={classData.title}
          btnClose
          data={classData}
          banner={classData.packageImages[0]}
          handleCloseModal={handleCloseShareModal}
        />
      )}
    </>
  )
}
