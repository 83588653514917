import styles from './ModalCounselDuplicated.module.scss'
import ModalWrap from '../ModalWrap'

export default function ModalCounselDuplicated({ onClose, buttonColor, buttonBackground }) {
  return (
    <ModalWrap onClose={onClose}>
      <div className={styles.counselDuplicatedModal}>
        <div onClick={onClose}></div>
        <div className={styles.popYndContent}>
          <p>
            <strong>이미 상담 신청하셨어요!</strong>해당 번호로는 더 이상 접수 불가합니다.
            <br />
            기존에 등록해주신 번호로 전화 연락드리겠습니다.
          </p>
          <span>
            * 추가적으로 다른 상품에 대한 문의가 있을 경우,
            <br /> 상담원과의 통화 시 말씀해 주세요.
          </span>
          <button
            type="button"
            style={{ background: buttonBackground, color: buttonColor }}
            onClick={onClose}
            className={styles.submitButton}
          >
            확인
          </button>
        </div>
      </div>
    </ModalWrap>
  )
}
