import styled, { ThemeProvider } from 'styled-components'

import theme from 'common/theme'

export default function Loading() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <LoadingBox>
          <theme.Blind>로딩중</theme.Blind>
          <div className="line1"></div>
          <div className="line2"></div>
          <div className="line3"></div>
        </LoadingBox>
      </ThemeProvider>
    </>
  )
}

const LoadingBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  div {
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background-color: rgb(255, 185, 0);

    & + div {
      margin-left: 0.5rem;
    }

    &.line1 {
      animation: loadingAnimation 0.6s 0.1s linear infinite;
    }
    &.line2 {
      animation: loadingAnimation 0.6s 0.2s linear infinite;
    }
    &.line3 {
      animation: loadingAnimation 0.6s 0.3s linear infinite;
    }
  }

  @keyframes loadingAnimation {
    0% {
      transform: translate(0, 0);
    }
    50% {
      transform: translate(0, 1.5rem);
    }
    100% {
      transform: translate(0, 0);
    }
  }

  @media ${({ theme }) => theme.device.mobile} {
    div {
      width: 2rem;
      height: 2rem;

      & + div {
        margin-left: 1rem;
      }
    }
  }
`
