import { useState } from 'react'

import ResponsiveImage from 'components/ResponsiveImage'

import s from './TrainingStep.module.css'
import { HOST_CDN } from '../../config'
import BtnPulse from '../BtnPulse'

export default function TrainingStep01() {
  const [target, setTarget] = useState({ one: false, two: false })

  const handleToggle = (key) => {
    setTarget({ ...target, [key]: !target[key] })
  }

  return (
    <div className={`${s.step} ${s.step01}`}>
      <span className={s.btn01}>
        <BtnPulse active event={() => handleToggle('one')} />
      </span>
      <span className={s.tooltip01}>
        {target.one && (
          <ResponsiveImage
            pc={`${HOST_CDN}step_01_hover_01.png`}
            mo={`${HOST_CDN}step_01_hover_01_m.png`}
            alt={'내 클래스 정보를 한 눈에 파악해요'}
            maxWidth={1024}
          />
        )}
      </span>
      <span className={s.btn02}>
        <BtnPulse event={() => handleToggle('two')} />
      </span>
      <span className={s.tooltip02}>
        {target.two && (
          <ResponsiveImage
            pc={`${HOST_CDN}step_01_hover_02.png`}
            mo={`${HOST_CDN}step_01_hover_02_m.png`}
            alt={'터치 한 번으로 바로 수강해요'}
            maxWidth={1024}
          />
        )}
      </span>
    </div>
  )
}
