import s from '../aiTalk.module.css'

export default function SpecialItem({ data }) {
  const { img, subTitle, title, detail, info } = data
  return (
    <div className={s.specialItem}>
      <div className={s.subTitle}>{subTitle}</div>
      <img className={s.aiThumb} src={img} alt={title} />
      <dl>
        <dt>{title}</dt>
        <dd>
          {detail} {info && <span className={s.infoText}>{info}</span>}
        </dd>
      </dl>
    </div>
  )
}
