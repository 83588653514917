import React from 'react'
import ReactMarkdown from 'react-markdown'

import s from './Chat.module.scss'
import ChatFeedList from './ChatFeedList'
import ChatTopInfo from './ChatTopInfo'

export default function ChatFeedContainer({ metaInfo, isAiResponse, userSeq, feedback }) {
  return (
    <div className={s.chatFeed}>
      <ChatTopInfo metaInfo={metaInfo} />
      <ChatFeedList
        metaInfo={metaInfo}
        isAiResponse={isAiResponse}
        userSeq={userSeq}
        feedback={feedback}
      />
      {feedback && (
        <article className={s.feedbackWrap}>
          <section className={s.feedback}>
            <h6>
              <i className={s.ico} />
              대화 내용에 대한 피드백
            </h6>
            <div className={s.feedbackContent}>
              <ReactMarkdown>{feedback}</ReactMarkdown>
            </div>
          </section>
        </article>
      )}
    </div>
  )
}
