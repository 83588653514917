import React from 'react'

import MessageAi from './MessageAi'
import MessageUser from './MessageUser'
import RelatedFaq from './RelatedFaq'
import { AI_FAQ_CONFIG } from '../config'

export default function ChatFeedItem({ type, message, subContents, addToFeed }) {
  const { decodeHtml } = AI_FAQ_CONFIG

  const handleSubContent = (q, a) => {
    const decodeData = decodeHtml(q)
    addToFeed({ type: 'USER', message: decodeData, responseOff: true })
    setTimeout(() => {
      addToFeed({ type: 'AI', message: a })
    }, 500)
  }
  if (type === 'AI') {
    return (
      <>
        <MessageAi message={message} subContents={subContents} />
        {subContents.length > 0 && (
          <RelatedFaq subContents={subContents} handleSubContent={handleSubContent} />
        )}
      </>
    )
  } else {
    return <MessageUser message={message} />
  }
}
