import { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useParams } from 'react-router-dom'

import ItemStore from 'pages/custom/components/ItemStore.jsx'
import styled, { ThemeProvider } from 'styled-components'
import { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import api from 'common/api'
import * as config from 'common/config'

import 'swiper/swiper.scss'
import 'assets/styles/Home.scss'
import 'assets/styles/index.scss'
import theme from 'common/theme'

import MetaInfo from '../../components/common/MetaInfo.jsx'
import MallError from '../mall/Error.jsx'

export default function CustomPreview() {
  const deviceCheck = useMediaQuery({ maxWidth: 1024 }) ? true : false
  const { pageUrl } = useParams()
  const [isError, setError] = useState(false)
  const [isCustomData, setCustomData] = useState(null)

  const customGet = async (code) => {
    try {
      await api
        .get(`/v2/custom-page/` + code)
        .then((response) => {
          if (response.data.data.useYn === 'N') {
            setError(true)
          } else {
            const data = response.data.data
            setCustomData(data)
          }
        })
        .catch((e) => {
          alert(config.MESSAGE['common-error'])
          setError(true)
        })
    } catch (e) {
      console.log(e)
      setError(true)
    }
  }

  useEffect(() => {
    customGet(pageUrl.toLowerCase())
  }, [])

  return (
    <ThemeProvider theme={theme}>
      {isError && isError ? (
        <MallError comment={`연결할 수 없는 페이지 입니다`} />
      ) : (
        <>
          <MetaInfo
            props={{
              title: '야나두 전체 강의',
              description: '영어의 기초부터 능숙한 대화까지, 전체 강의에서 선택해보세요!',
              site_name: '야나두 영어로 시작하면 더 이상 어렵지 않아요',
              imgsrc: 'https://english.yanadoocdn.com/upload/yanadoo/new/og/img_og_custom.jpg'
            }}
          />
          {isCustomData ? (
            <>
              <BannerBox>
                {isCustomData.banners && isCustomData.banners.length > 1 ? (
                  <Swiper slidesPerView={1} pagination={true} modules={[Pagination]}>
                    {isCustomData.banners.map((bannerSlide, bannerIndex) => {
                      return (
                        <SwiperSlide key={bannerIndex}>
                          <a
                            href={bannerSlide.bannerUrl ? `${bannerSlide.bannerUrl}` : null}
                            target={bannerSlide.bannerUrl ? `${bannerSlide.linkTarget}` : null}
                            style={{ backgroundColor: `${bannerSlide.backgroundColor}` }}
                          >
                            {deviceCheck ? (
                              <img src={bannerSlide.mobileImagePath} alt={bannerSlide.bannerName} />
                            ) : (
                              <img src={bannerSlide.pcImagePath} alt={bannerSlide.bannerName} />
                            )}
                          </a>
                        </SwiperSlide>
                      )
                    })}
                  </Swiper>
                ) : (
                  <>
                    {isCustomData.banners &&
                      isCustomData.banners.map((bannerSlide, bannerIndex) => {
                        return (
                          <a
                            href={bannerSlide.bannerUrl ? `${bannerSlide.bannerUrl}` : null}
                            target={bannerSlide.bannerUrl ? `${bannerSlide.linkTarget}` : null}
                            key={bannerIndex}
                          >
                            {deviceCheck ? (
                              <img src={bannerSlide.mobileImagePath} alt={bannerSlide.bannerName} />
                            ) : (
                              <img src={bannerSlide.pcImagePath} alt={bannerSlide.bannerName} />
                            )}
                          </a>
                        )
                      })}
                  </>
                )}
              </BannerBox>
              <section>
                {isCustomData.products &&
                  isCustomData.products.map((item, itemIndex) => {
                    return (
                      <ProductBox key={itemIndex}>
                        <InnerCol>
                          <ItemStore
                            title={item.categoryName}
                            subTitle={item.categoryText}
                            storeData={item.list}
                          />
                        </InnerCol>
                      </ProductBox>
                    )
                  })}
              </section>
            </>
          ) : null}
        </>
      )}
      {/* {console.log('isError', isError)} */}
    </ThemeProvider>
  )
}

const BannerBox = styled.div`
  .swiper-container {
    .swiper-pagination {
      display: block !important;
    }

    .swiper-pagination-bullet {
      width: 0.8rem;
      height: 0.8rem;
      bottom: 0;
      background-color: #fff;
      opacity: 0.6;
    }

    .swiper-pagination-bullet-active {
      width: 3.2rem;
      border-radius: 1rem;
      opacity: 1;
    }
  }

  a {
    display: block;
  }

  img {
    display: block;
    width: 192rem;
    margin: 0 auto;
  }
  @media ${({ theme }) => theme.device.mobile} {
    .swiper-container {
      .swiper-pagination-bullet {
        width: 1.2rem;
        height: 1.2rem;
      }

      .swiper-pagination-bullet-active {
        width: 4.8rem;
        border-radius: 1rem;
      }
    }

    img {
      width: 75rem;
    }
  }
`
const ProductBox = styled.div`
  padding: 8rem 0;

  &:first-child {
    padding-top: 8rem;
  }
  &:nth-child(even) {
    background: #f7f7fc;
  }

  @media ${({ theme }) => theme.device.mobile} {
    padding: 6rem 4.2rem;
  }
`

const InnerCol = styled.div`
  display: grid;
  grid-template-columns: 34rem 1fr;
  grid-template-rows: repeat(auto-fit, minmax(3rem, auto));
  grid-gap: 8rem;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 3rem;
  background: unset;

  @media ${({ theme }) => theme.device.mobile} {
    display: unset;
    padding: 0;
  }
`
