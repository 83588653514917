import React, { useEffect } from 'react'

import styled from 'styled-components'
import { ThemeProvider } from 'styled-components'
import theme from 'common/theme'
import ImgView from 'components/ImgView'
import CommentView from 'components/CommentView'

import BgStarGray from '../assets/images/bg_star_120x24g.png'
import BgStarOrange from '../assets/images/bg_star_120x24_orange.png'
import IcoReply from '../assets/images/ico_reply_90x90.png'

export default function DetailReviewForm({ getReviewData, reviewData, isLoading }) {
  function formatDate(date) {
    if (date) {
      let getDate = date.split(' ')
      return getDate[0]
    } else {
      return null
    }
  }

  useEffect(() => {
    // 페이지 벗어날때 모달 클래스 삭제
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])
  return (
    <>
      <ThemeProvider theme={theme}>
        {reviewData && (
          <DetailView>
            <DetailTxtBox>
              <FlexBox>
                <div className="left-box">
                  <ThumbSpan bgImage={reviewData.profileUrl ? reviewData.profileUrl : reviewData.profileImageUrl}>
                    <i>썸네일</i>
                  </ThumbSpan>
                  <div>
                    <strong>{reviewData.nickName ? reviewData.nickName : reviewData.username}</strong>
                    <p>{reviewData.registDate}</p>
                  </div>
                </div>
                <div className="right-box">
                  <StarPoint per={reviewData.satisfaction && reviewData.satisfaction * 20}>
                    <span>star point</span>
                  </StarPoint>
                  {reviewData.title ? (
                    <p>
                      <strong>{reviewData.title}</strong>
                    </p>
                  ) : (
                    <p>
                      <strong>장학금 후기</strong>
                    </p>
                  )}
                </div>
              </FlexBox>
              <Contents>
                <p>{reviewData.contents}</p>
              </Contents>
            </DetailTxtBox>
            <ImgView data={reviewData} isLoading={isLoading} />
            {reviewData.answerYn === 'Y' && reviewData.answer ? (
              <DetailAnswer>
                <div className="detail-answer">
                  <div className="thumb-area">
                    <span className="thumb">
                      <i className="blind">썸네일</i>
                      {reviewData.answer.answerThumbnailPath && <img src={reviewData.answer.answerThumbnailPath} alt="" />}
                    </span>
                    <div className="desc">
                      <strong>{reviewData.answer.answerUserName}</strong>
                      <p>{formatDate(reviewData.answer.answerDate)}</p>
                    </div>
                  </div>{' '}
                  <pre
                    className="answer-txt"
                    dangerouslySetInnerHTML={{
                      __html: reviewData.answer.answerContent
                    }}
                  />
                  {reviewData.image && <img src={reviewData.image} alt="" />}
                </div>
              </DetailAnswer>
            ) : null}
            <CommentView getReviewData={getReviewData} data={reviewData} isLoading={isLoading} />
          </DetailView>
        )}
      </ThemeProvider>
    </>
  )
}

const StarPoint = styled.div`
  position: relative;
  flex: 0 0 auto;
  width: 10rem;
  height: 2rem;

  &:before {
    content: '';
    display: inline-block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: url(${BgStarGray}) 0 0 no-repeat;
    background-size: auto 100%;
    z-index: 2;
  }

  span {
    display: inline-block;
    position: absolute;
    z-index: 5;
    width: ${(props) => props.per + '%' || '0%'};
    height: 100%;
    background: url(${BgStarOrange}) 0 0 no-repeat;
    background-size: auto 100%;
    max-width: 100%;
    text-indent: -9999rem;
    overflow: hidden;
  }

  @media ${({ theme }) => theme.device.mobile} {
    width: 12rem;
    height: 2.4rem;
  }
`

const FlexBox = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  .left-box {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    div {
      flex: 1 1 auto;
      margin-left: 1.5rem;
      overflow: hidden;

      strong {
        font-size: 1.7rem;
      }

      p {
        margin-top: 0.4rem;
        font-weight: 400;
        font-size: 1.3rem;
        color: #a0a0b6;
      }
    }

    + div {
      p,
      div {
        vertical-align: middle;
      }

      p {
        strong {
          font-weight: 400;
          font-size: 1.4rem;
          color: #8d8da0;
        }
      }
    }
  }

  .right-box {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 0.5rem;
    overflow: hidden;

    p {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      margin: 0.8rem 0 0;
      font-size: 1.6rem;
      white-space: normal;
      text-overflow: ellipsis;
      word-break: break-all;
      overflow: hidden;
    }

    &.active {
      height: auto;
      max-height: 100%;

      p {
        -webkit-line-clamp: initial;
      }
    }
  }

  @media ${({ theme }) => theme.device.mobile} {
    align-items: flex-start;

    div:first-child {
      div {
        margin-left: 2.2rem;

        strong {
          font-size: 2.6rem;
        }

        p {
          margin-top: 0.4rem;
          font-size: 2.2rem;
        }
      }

      + div {
        margin: 0 !important;

        p {
          position: absolute;
          top: 14.7rem;
          left: 4rem;

          strong {
            font-size: 2.2rem;
          }
        }

        div {
          margin-top: 1.2rem;
        }
      }
    }
  }
`

const ThumbSpan = styled.span`
  flex: 0 0 auto;
  width: 5.4rem;
  height: 5.4rem;
  background-color: #cfcfda;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-sizing: border-box;

  background-image: ${(props) => props.bgImage && `url(${props.bgImage})`};

  i {
    position: absolute;
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
  }

  @media ${({ theme }) => theme.device.mobile} {
    width: 8.2rem;
    height: 8.2rem;
  }
`

const DetailView = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 80.6rem;
  min-height: 49.9rem;
  margin: 0 auto 2.4rem;
  background-color: #fff;
  border-radius: 2rem;
  box-shadow: 0 0 2rem rgba(0, 0, 0, 0.1);

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
  }
`

const DetailTxtBox = styled.div`
  padding: 3.5rem 3.5rem 3rem;
`

const DetailAnswer = styled.div`
  .detail-answer {
    padding: 3.5rem;
    font-size: 1rem;
    line-height: 1.2;
    background-color: #f7f7fc;

    .thumb-area {
      display: flex;
      align-items: center;
      position: relative;
      padding-left: 3.4rem;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 3rem;
        height: 3rem;
        background: url(${IcoReply}) 50% 50% no-repeat;
        background-size: 100% auto;
      }

      img {
        margin: 0;
        border: 1px solid #e6e6e6;
        border-radius: 50%;
        box-sizing: border-box;
      }

      .thumb {
        width: 5.4rem;
        height: 5.4rem;
        margin-right: 1.5rem;
        background-color: #fff;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: cover;
        border-radius: 50%;
        overflow: hidden;
      }

      .desc {
        strong {
          display: block;
          font-weight: 700;
          font-size: 1.7rem;
        }

        p {
          margin-top: 0.6rem;
          font-weight: 400;
          font-size: 1.3rem;
          color: #a0a0b6;
        }
      }
    }

    .answer-txt {
      margin-top: 1.9rem;
      white-space: pre-wrap;
      font-size: 1.6rem;
    }
  }

  @media ${({ theme }) => theme.device.mobile} {
    .detail-answer {
      padding: 4rem;
      background-color: #f7f7fc;

      .thumb-area {
        padding-left: 5.6rem;

        &::before {
          top: 0;
          left: 0;
          width: 5.5rem;
          height: 5.5rem;
        }

        .thumb {
          width: 8.2rem;
          height: 8.2rem;
          margin-right: 2.2rem;
        }

        .desc {
          strong {
            font-size: 2.6rem;
          }

          p {
            font-size: 2rem;
          }
        }
      }

      .answer-txt {
        margin-top: 2.8rem;
        padding-left: 5.6rem;
        font-size: 2.6rem;
        line-height: 1.5;
      }
    }
  }
`

const Contents = styled.div`
  margin: 1.5rem 0 0;

  p {
    font-size: 1.6rem;
    line-height: 1.5;
    white-space: pre-line;
    word-break: break-all;
  }

  @media ${({ theme }) => theme.device.mobile} {
    margin: 7.5rem 0.5rem 0;

    p {
      font-size: 2.6rem;
      line-height: 1.6;
    }
  }
`
