import React from 'react'

const SendSvgIcon = ({ pathClassName }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 52 52" fill="none">
      <path
        className={pathClassName}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.9996 2.36328C39.0536 2.36328 49.636 12.9456 49.636 25.9996C49.636 39.0536 39.0536 49.636 25.9996 49.636C12.9456 49.636 2.36328 39.0536 2.36328 25.9996C2.36328 12.9456 12.9456 2.36328 25.9996 2.36328Z"
        fill="#DFDFEA"
      />
      <path
        d="M17.334 24.4245L26.0007 15.7578L34.6673 24.4245"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path d="M26 16.5449L26 37.0298" stroke="white" strokeWidth="3" strokeLinecap="round" />
    </svg>
  )
}

export default SendSvgIcon
