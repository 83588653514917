import { useState } from 'react'

import s from './Chat.module.css'

export default function ErrorMessage({ msg }) {
  const [translateText, setTranslateText] = useState(null)
  const [transLoading, setTransLoading] = useState(false)
  const [translated, setTranslated] = useState(false)

  return <div className={`${s.message} ${s.errorMessage}`}>{msg}</div>
}
