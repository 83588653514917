import { getUserLevel } from 'pages/levelTestAdvanced/useLevelTestAdvanced'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

const initialState = {
  isResultStoreSet: false,
  name: undefined,
  level: undefined,
  goal: undefined,
  questionCount: undefined,
  score: undefined,
  avgScore: undefined,
  time: undefined,
  avgTime: undefined,
  correctCount: undefined,
  avgCorrectCount: undefined,
  location: undefined,
  lineChartData: undefined,
  test: undefined
}

const useLevelTestAdvancedResultStore = create(
  devtools(
    (set) => ({
      ...initialState,
      setData: (data) =>
        set(
          (state) => ({
            ...state,
            name: data.userName,
            level: `lv${getUserLevel(data.score)}`,
            score: data.score,
            avgScore: data.avgScore,
            time: data.leadTime,
            avgTime: data.avgLeadTime,
            correctCount: data.correctAnswerCount,
            avgCorrectCount: data.avgCorrectAnswerCount,
            location: data.myLocation,
            lineChartData: data.charts
          }),
          false,
          'LevelTestAdvancedResult/setData'
        ),
      setStoreData: (data) =>
        set(
          (state) => ({
            ...state,
            isResultStoreSet: true,
            goal: data.selfCheck.goal,
            questionCount: data.test.length,
            test: data.test
          }),
          false,
          'LevelTestAdvancedResult/setStoreData'
        )
    }),
    { name: 'LevelTestAdvancedResultStore', enabled: process.env.NODE_ENV === 'development' }
  )
)

export default useLevelTestAdvancedResultStore
