import React, { useEffect, useRef, useState } from 'react'

import useAudioState from 'store/useAudioState'

import s from './ChatFooterAudio.module.scss'
import RecorderButton from './RecorderButton'
import RecorderTooltip from './RecorderTooltip'

// 브라우저에서 SpeechRecognition 객체 가져오기
const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition
const isSpeechRecognitionSupported = !!SpeechRecognition // 지원 여부 확인

export default function SpeechToTextAos({ addToFeed, isLoading, character = {} }) {
  const [text, setText] = useState('')
  const [isListening, setIsListening] = useState(false)
  const recognitionRef = useRef(null) // recognition 객체를 useRef로 관리
  const isRestartingRef = useRef(false) // 재시작 플래그 관리
  const { setIsAudioPlaying } = useAudioState()

  useEffect(() => {
    // navigator.permissions가 존재하는지 확인
    if (navigator.permissions) {
      navigator.permissions
        .query({ name: 'microphone' })
        .then((result) => {
          if (result.state !== 'granted') {
            console.error('마이크 권한이 필요합니다.')
          }
        })
        .catch((error) => {
          console.error('권한 조회 중 에러 발생:', error)
        })
    } else {
      console.warn('navigator.permissions가 지원되지 않는 환경입니다.')
    }

    if (isSpeechRecognitionSupported) {
      // 음성 인식을 지원하는 경우 recognition 초기화
      recognitionRef.current = new SpeechRecognition()
      recognitionRef.current.lang = 'en-US'
      recognitionRef.current.continuous = true // 연속 모드 설정
      recognitionRef.current.interimResults = true // 중간 결과도 사용

      console.log('[start] 음성 인식이 시작되었습니다.')

      recognitionRef.current.onresult = (event) => {
        let interimTranscript = ''
        let finalTranscript = ''

        for (let i = event.resultIndex; i < event.results.length; i++) {
          const transcript = event.results[i][0].transcript
          if (event.results[i].isFinal) {
            finalTranscript += transcript + ' '
          } else {
            interimTranscript += transcript
          }
        }

        setText(finalTranscript + interimTranscript) // 실시간으로 텍스트 업데이트
      }

      recognitionRef.current.onerror = (event) => {
        console.error('음성 인식 에러:', event.error)
        setIsListening(false)
        setIsAudioPlaying(true)
        addToFeed({
          type: 'Error',
          msg: '죄송합니다. 이해하지 못했어요. 다시 한번 말씀해 주시겠어요?',
          mode: false
        })

        if (event.error === 'aborted' || event.error === 'network') {
          console.warn('음성 인식이 중단되었습니다. 재시작합니다.')
          if (!isRestartingRef.current && !isListening) {
            recognitionRef.current.start() // "aborted" 또는 "network" 오류 발생 시 음성 인식 재시작
          }
        }
      }

      recognitionRef.current.onend = () => {
        console.log('[end] 음성 인식이 종료되었습니다.')

        if (isRestartingRef.current) {
          try {
            recognitionRef.current.start()
            isRestartingRef.current = false
            console.log('[re] 음성 인식이 재시작되었습니다.')
          } catch (error) {
            console.error('[re][error] 음성 인식 재시작 오류:', error)
          }
        }
      }
    }

    // 클린업 함수 추가
    return () => {
      if (recognitionRef.current) {
        recognitionRef.current.onend = null
        recognitionRef.current.onerror = null
        recognitionRef.current.stop() // 마이크 중지
      }
    }
  }, [])

  // listen start
  const handleListenStart = () => {
    setIsAudioPlaying(false)
    setText('')

    if (isSpeechRecognitionSupported && recognitionRef.current) {
      try {
        recognitionRef.current.start()
        setIsListening(true)
        console.log('[start][onClick] 음성 인식 시작 버튼 클릭.')
      } catch (error) {
        console.error('[error] 음성 인식 시작 오류:', error)
      }
    }
  }

  // listen stop
  const handleListenStop = () => {
    setIsAudioPlaying(true)
    setText('')
    if (isSpeechRecognitionSupported && recognitionRef.current && isListening) {
      recognitionRef.current.stop()
      setIsListening(false)
      console.log('[end][onClick] 음성 인식 종료 버튼 클릭.')
    }
  }

  // send to api
  const handleSendMessage = () => {
    if (text.length < 1 || text.trim() === '' || !text) {
      addToFeed({
        type: 'Error',
        msg: '죄송합니다. 이해하지 못했어요. 다시 한번 말씀해 주시겠어요?',
        mode: false
      })
      handleListenStop()
      return
    }

    addToFeed({ type: 'User', msg: text })
    handleListenStop()
  }

  const handleRestart = () => {
    setText('')
    isRestartingRef.current = true // 재시작 플래그 설정
    if (isSpeechRecognitionSupported && recognitionRef.current && isListening) {
      console.log('[re] 음성 인식 중지.')
      recognitionRef.current.stop()
    }
  }

  return (
    <section className={s.footerMicGroup}>
      <RecorderTooltip
        isListening={isListening}
        isLoading={isLoading}
        name={character?.info?.firstname}
        msg={text}
      />
      <RecorderButton
        isListening={isListening}
        isLoading={isLoading}
        handleRecorder={{
          send: handleSendMessage,
          start: handleListenStart,
          stop: handleListenStop,
          re: handleRestart
        }}
      />
    </section>
  )
}
