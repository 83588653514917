import React from 'react'
import { useNavigate } from 'react-router'

import s from '../Service.module.scss'

export default function SectionSurvey() {
  const navigate = useNavigate()

  // 설문조사 링크 추가 예정
  const handleBtnClick = () => {
    navigate('https://forms.gle/A9R9otLYSYW2sFCd7')
  }
  return (
    <section className={s.csSurvey}>
      <div className={s.csInner}>
        <div className={s.surveyCont}>
          <span>서비스 조사</span>
          <h4>
            더 나은 서비스를 위해
            <br />
            서비스 개선 의견을 작성해주세요.
          </h4>
          <a
            className={s.btnSurvey}
            href="https://forms.gle/A9R9otLYSYW2sFCd7"
            target="_blank"
            rel="noreferrer"
          >
            참여하기
          </a>
        </div>
      </div>
    </section>
  )
}
