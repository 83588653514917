import { useMediaQuery } from 'react-responsive';
import s from './BtnHover.module.css';
export default function BtnPulse({ active, event }) {
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const options = {
    onClick: isMobile
      ? () => {
          event();
        }
      : null,
    onMouseEnter: !isMobile
      ? () => {
          event();
        }
      : null,
    onMouseLeave: !isMobile
      ? () => {
          event();
        }
      : null,
  };

  return (
    <button className={`${s.btn} ${active ? s.pulse : null}`} {...options}>
      <svg xmlns="http://www.w3.org/2000/svg" width="14px" height="14px">
        <path
          fillRule="evenodd"
          fill="rgb(255, 255, 255)"
          d="M13.552,7.968 L7.920,7.968 L7.920,13.608 L5.667,13.608 L5.667,7.968 L0.034,7.968 L0.034,5.712 L5.667,5.712 L5.667,0.072 L7.920,0.072 L7.920,5.712 L13.552,5.712 L13.552,7.968 Z"
        />
      </svg>
    </button>
  );
}
