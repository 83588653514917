import React, { useState, useEffect } from 'react'

export default function DdayCounter({ targetDate }) {
  const parseDate = (dateString) => {
    const year = dateString.substring(0, 4)
    const month = dateString.substring(4, 6) - 1
    const day = dateString.substring(6, 8)
    return new Date(year, month, day)
  }

  const calculateDaysLeft = (date) => {
    const target = parseDate(date)
    const today = new Date()
    const difference = target - today
    return Math.ceil(difference / (1000 * 60 * 60 * 24))
  }

  const [daysLeft, setDaysLeft] = useState(calculateDaysLeft(targetDate))

  useEffect(() => {
    const timer = setInterval(() => {
      setDaysLeft(calculateDaysLeft(targetDate))
    }, 1000 * 60 * 60 * 24)

    return () => clearInterval(timer)
  }, [targetDate])

  const formatDaysLeft = (days) => {
    const daysString = days < 10 ? `0${days}` : `${days}`
    return daysString.split('')
  }

  if (daysLeft < 0) {
    return null
  }

  return (
    <div className="DdayBox">
      <span>D</span>
      <span className="dash">-</span>
      {formatDaysLeft(daysLeft).map((char, index) => (
        <span key={index}>{char}</span>
      ))}
    </div>
  )
}
