import { useEffect, useRef } from 'react'

import s from 'pages/english/EnglishHome.module.css'

import { StaggerMotion } from 'components/promotion/utile/utile'
import ResponsiveImage from 'components/ResponsiveImage'

import { brandCardValues } from 'pages/english/config'

export default function BrandSection() {
  const motionRef = useRef(null)
  const staggerInstance = new StaggerMotion(motionRef, 500, s.active)

  useEffect(() => {
    const observer = new IntersectionObserver(
      (els) => {
        els.forEach((el) => {
          if (el.isIntersecting) {
            setTimeout(() => {
              staggerInstance.staggerOn(motionRef)
            }, 300)
          } else {
            staggerInstance.staggerOff(motionRef)
          }
        })
      },
      {
        threshold: 1
      }
    )

    observer.observe(motionRef.current)

    return () => {
      observer.disconnect()
      staggerInstance.staggerOff(motionRef)
    }
  }, [staggerInstance])

  return (
    <section className={s.brand}>
      <div className={s.inner}>
        <div className={s.titleGroup}>
          <h3 className={s.title}>
            <span>4년 연속 브랜드 대상 1위</span>
            진짜 영어가 되니까
          </h3>
          <p className={s.description}>
            170만 명의 왕초보 패턴 분석으로
            <br />더 완벽해진 영어의 공식
            <span>*브랜드 대상 수상 (19년 - 22년)</span>
          </p>
        </div>
        <ul className={s.cardList} ref={motionRef}>
          {brandCardValues.map(({ pc, mo, alt }, index) => {
            return (
              <li key={index}>
                <ResponsiveImage pc={pc} mo={mo} alt={alt} maxWidth={1024} />
              </li>
            )
          })}
        </ul>
      </div>
    </section>
  )
}
