import { useState, useEffect } from 'react'

const PLATFORM_PATH = ['/iframe', '/webview']

export default function usePlatform(path) {
  const [isPlatform, setIsPlatform] = useState(false)

  useEffect(() => {
    if (path) {
      const isMatchingPath = PLATFORM_PATH.some((item) => path.includes(item))
      if (isMatchingPath) {
        setIsPlatform(true)
      } else {
        setIsPlatform(false)
      }
    }
  }, [path])

  return { isPlatform }
}
