import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useState, useEffect, useCallback } from 'react';
import AuthService from 'services/authService';

export default function PrivateRoute({ children }) {
  const location = useLocation();
  const [loginStatus, setLoginStatus] = useState(AuthService.isLoggedIn());

  const updateLoginStatus = useCallback(async () => {
    const loggedIn = await AuthService.isLoggedIn();
    setLoginStatus(loggedIn);
  }, []);

  useEffect(() => {
    AuthService.addObserver(updateLoginStatus);
    return () => {
      AuthService.removeObserver(updateLoginStatus);
    };
  }, [updateLoginStatus]); // 의존성 배열에 updateLoginStatus를 포함

  return loginStatus ? (
    children
  ) : (
    <Navigate
      to="/login"
      state={{
        from: location.pathname,
        authCheck: true,
        query: location.search,
      }}
    />
  );
}
