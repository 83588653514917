import React from 'react'

export default function FaqAnswer({ answer }) {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: answer
      }}
    ></div>
  )
}
