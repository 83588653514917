import React, { useState, useEffect } from 'react'

import styled from 'styled-components'

import api from 'common/api'

import SectionTitle from './SectionTitle'
import { setDefaultItemCount, BtnMoreAllList } from '../../../components/BtnMoreAllList'
import IcoArrow from 'assets/images/ico_arrow_36x21b.png'
import IcoStar from 'assets/images/ico_star_100x100.png'

export default function HomeReviewSection() {
  const [isLoading, setIsLoading] = useState(true)
  const [reviewInfo, setReviewInfo] = useState([])
  const [addReviewList, setAddReviewList] = useState([])
  const [categoryData, setCategoryData] = useState({
    category: 'ALL'
  })
  const [reviewData, setReviewData] = useState({
    list: [],
    totalCount: 0
  })

  const handleAddReviewList = (data) => {
    setAddReviewList(data)
  }

  const addComma = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  const getReviewInfo = () => {
    api
      .get(`v2/review/real-time/info`)
      .then((response) => {
        setReviewInfo(response.data.data)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const getReviewList = (category) => {
    api
      .get(`v2/review/real-time/list?categoryCode=${category}`)
      .then((response) => {
        let responseData = response.data.data

        setReviewData({
          ...reviewData,
          list: responseData,
          totalCount: responseData.length
        })
        setDefaultItemCount(3, responseData.length)
        setIsLoading(false)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const handleChangeCategory = (e) => {
    const targetValue = e.target.value
    setIsLoading(true)
    setCategoryData({ ...categoryData, category: targetValue })
    getReviewList(targetValue)
  }

  const ReviewTemp = ({ reviewInfo, addReviewList }) => {
    return (
      <ReviewCol>
        <h4>
          <span>다양한 후기를 확인해 보세요</span>
          <select
            name="categorySct"
            id="categorySct"
            value={categoryData.category}
            onChange={handleChangeCategory}
          >
            {reviewInfo &&
              reviewInfo.map((item) => (
                <option value={item.categoryCode} key={item.categoryCode}>
                  {item.categoryName}
                </option>
              ))}
          </select>
        </h4>
        <ul>
          {addReviewList?.length > 0 &&
            addReviewList
              .filter((list) => list?.satisfaction === 5)
              .map((item, idx) => (
                <li key={idx}>
                  <ReviewItems item={item} />
                </li>
              ))}
        </ul>
      </ReviewCol>
    )
  }

  const ReviewItems = ({ item }) => {
    const [reviewDetail, setReviewDetail] = useState(false)

    return (
      <>
        <div>
          <p>
            <strong>{item.title}</strong>
          </p>
          <StarPoint per={item.satisfaction && item.satisfaction * 20}>
            <span>star point</span>
          </StarPoint>
        </div>
        <div className={reviewDetail ? 'active' : ''}>
          <p>{item.content}</p>
        </div>
        <div>
          <em>{item.userName}</em>
          <button type="button" onClick={() => setReviewDetail(!reviewDetail)}>
            {item.content.length >= 100 && <span>{reviewDetail ? '접기' : '더보기'}</span>}
          </button>
        </div>
      </>
    )
  }

  const SatisfactionTemp = ({ level, per }) => {
    return (
      <SatisfactionGraph level={level} per={per}>
        <StarPoint per={level && level * 20}>
          <span>star level</span>
        </StarPoint>
        <div>
          <span>star point</span>
        </div>
      </SatisfactionGraph>
    )
  }

  useEffect(() => {
    reviewInfo && getReviewInfo()
    reviewData && getReviewList(categoryData.category)
  }, [])

  return (
    <HomeReivewCol>
      <InnerCol>
        <SectionTitle
          title={'수강생들의 진심을 담은\n실제 후기 입니다.'}
          desc={`${String(reviewInfo.totalCount).substring(
            0,
            2
          )}만 명의 실제 수강생들의 후기가\n '할 수 있다'는 자신감을 쌓아 드립니다.`}
        />
        <RradeCol>
          <dl>
            <dt>수강생 실제 후기</dt>
            <dd>
              <strong>{reviewInfo.totalCount && addComma(parseInt(reviewInfo.totalCount))}</strong>
            </dd>
          </dl>
          <dl>
            <dt>만족도</dt>
            <dd>
              <strong>
                {reviewInfo.satisfactionAverage && parseFloat(reviewInfo.satisfactionAverage)}
              </strong>
            </dd>
          </dl>
          <dl>
            <dt>만족도 비율</dt>
            <dd>
              <SatisfactionTemp level={5} per={reviewInfo.fiveSatisfactionPercent} />
              <SatisfactionTemp level={4} per={reviewInfo.fourSatisfactionPercent} />
              <SatisfactionTemp level={3} per={reviewInfo.threeSatisfactionPercent} />
              <SatisfactionTemp level={2} per={reviewInfo.twoSatisfactionPercent} />
              <SatisfactionTemp level={1} per={reviewInfo.oneSatisfactionPercent} />
            </dd>
          </dl>
        </RradeCol>
        <ReviewTemp
          reviewInfo={reviewInfo.realTimeReviewCategoryItems}
          addReviewList={addReviewList}
        />
        <BtnMoreAllList
          reviewData={reviewData}
          isLoading={isLoading}
          handleAddReviewList={handleAddReviewList}
        />
      </InnerCol>
    </HomeReivewCol>
  )
}

const HomeReivewCol = styled.section`
  padding: 18rem 0;
  background-color: #f7f7fc;

  .btn.btn-more {
    background-color: #e7e7f0;
  }

  @media ${({ theme }) => theme.device.mobile} {
    padding: 14rem 0;
  }
`

const InnerCol = styled.section`
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 3rem;
`

const RradeCol = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 6.4rem;
  padding: 6.4rem 10.2rem;
  background-color: #fff;
  border: 1px solid #cfcfda;

  dl {
    flex: 1 1 0;
    width: 30%;
    text-align: center;
  }

  dt {
    color: #717188;
    font-weight: 700;
    font-size: 2rem;
    text-align: center;
  }

  dd {
    height: 9.6rem;
    margin-top: 2rem;

    strong {
      display: inline-block;
      padding-top: 2rem;
      font-weight: 700;
      font-size: 4.8rem;

      &:after {
        content: '개';
        padding: 0 0 1rem 0.4rem;
        font-size: 2.4rem;
        vertical-align: middle;
      }
    }
  }

  dl:nth-child(2) {
    dd strong {
      &:before {
        content: '';
        display: inline-block;
        width: 3.5rem;
        height: 3.4rem;
        background: #ffb900 url(${IcoStar}) 50% 50%/100% no-repeat;
      }

      &:after {
        content: '/5';
        color: #b7b7c8;
      }
    }
  }

  dl:last-child {
    flex-shrink: 0;
    width: 40%;
    min-width: 36.7rem;
  }

  @media ${({ theme }) => theme.device.mobile} {
    margin-top: 5.6rem;
    padding: 4.8rem 4.6rem;

    dl {
      dt {
        font-size: 2.4rem;
      }
      dd {
        strong {
          padding-top: 0;
          font-size: 4rem;
        }
      }
    }

    dl:first-child {
      flex-basis: 100%;
      margin-bottom: 2rem;

      dd {
        strong {
          font-size: 4.8rem;
        }
      }
    }

    dl:nth-child(2) {
      flex: 0 0 13.2rem;
      flex-basis: 13.2rem;
      width: 13.2rem;
      margin-right: 5.8rem;

      dd {
        strong {
          padding-top: 2.5rem;
        }
      }
    }

    dl:last-child {
      flex: 0 0 36.5rem;
      width: 36.5rem;
      min-width: 36.5rem;
    }
  }
`

const SatisfactionGraph = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  text-align: left;

  div:first-child {
    margin-right: 1rem;
  }

  div:last-child {
    position: relative;
    width: 25.7rem;
    height: 1.2rem;
    background-color: #ececf6;
    border-radius: 1rem;
    overflow: hidden;

    span {
      display: inline-block;
      position: absolute;
      width: ${(props) => props.per + '%' || '0%'};
      height: inherit;
      background-color: #ffb900;
      border-radius: 1rem;
      text-indent: -9999rem;
    }
  }
`

const ReviewCol = styled.div`
  h4 {
    display: flex;
    margin: 8.5rem 0 4.5rem;
    font-size: 2.4rem;
    line-height: 1.3;
    justify-content: space-between;
    align-items: center;
  }

  select {
    display: inline-block;
    width: 14.8rem;
    padding: 0.7rem 0 0.9rem 1.2rem;
    color: #717188;
    background: #fff url(${IcoArrow}) 90% 50%/1rem no-repeat;
    font-size: 1.8rem;
    border: 1px solid #cfcfda;
    box-sizing: border-box;
  }

  ul {
    width: 100%;
  }

  li {
    padding: 4rem 7.2rem;
    margin-bottom: 2.4rem;
    background-color: #fff;

    div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    div + div:nth-child(2) {
      display: block;
      margin: 1.6rem 0;
      max-height: 4.8rem;
      overflow: hidden;

      p {
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        white-space: normal;
        text-overflow: ellipsis;
        word-break: break-all;
      }

      &.active {
        height: auto;
        max-height: 100%;

        p {
          -webkit-line-clamp: initial;
        }
      }
    }

    p {
      font-size: 1.8rem;
      word-break: keep-all;
      line-height: 1.4;

      strong {
        font-size: 2rem;
      }
    }

    em {
      color: #717188;
      font-size: 1.6rem;

      &:before {
        content: 'By.';
      }
    }

    button {
      color: #717188;
      font-weight: 700;
      font-size: 1.6rem;
      border-bottom: 1px solid #717188;
    }
  }

  @media ${({ theme }) => theme.device.mobile} {
    h4 {
      font-size: 3.2rem;
    }
    li {
      padding: 4rem;

      div + div:nth-child(2) {
        margin: 2rem 0;
        max-height: 9rem;

        p {
          -webkit-line-clamp: 3;
        }
      }

      p {
        color: #717188;
        font-size: 2.4rem;
        line-height: 1.3;

        strong {
          color: #000;
          font-size: 2.8rem;
        }
      }

      em {
        font-size: 2.4rem;
      }

      button {
        font-size: 2.4rem;
        border-bottom: 0;
      }
    }
    select {
      width: 16rem;
      height: 7rem;
      font-size: 2.4rem;
    }
  }
`

const StarPoint = styled.div`
  position: relative;
  width: 10rem;
  height: 2rem;
  background: #e7e7f0;

  &:before {
    content: '';
    display: inline-block;
    position: absolute;
    width: 10rem;
    height: 2rem;
    background: url(${IcoStar}) 50% 50%/20% repeat-x;
    z-index: 2;
  }

  span {
    display: inline-block;
    position: absolute;
    width: ${(props) => props.per + '%' || '0%'};
    height: inherit;
    background-color: #ffb900;
    max-width: 100%;
    text-indent: -9999rem;
  }
`
