import { useEffect, useState } from 'react'

import api from 'common/api'
import useHeaderStore from 'store/useHeaderStore'

export default function useGetGnbList(gnbList = JSON.parse(sessionStorage.getItem('gnbList'))) {
  const [list, setList] = useState(gnbList)
  const [gnbItems, setGnbItems] = useState(
    gnbList === null ? [] : gnbList.filter((item) => item.menuName !== '이벤트')
  )
  const [gnbEventItems, setGnbEventItems] = useState(
    gnbList === null ? [] : gnbList.filter((item) => item.menuName === '이벤트')
  )
  const [refresh, setRefresh] = useState(false)

  const { onChangeGnbItems, onChangeGnbEventItems } = useHeaderStore()

  function getGnbList() {
    api
      .get(`/v2/gnb-menu/list`)
      .then((response) => {
        const res = response.data.data
        setList(res)
        handleGnbList(res)

        // sessionStorage set
        sessionStorage.setItem('gnbList', JSON.stringify(res))
      })
      .catch((e) => {
        console.log(e)
      })
  }

  function handleGnbList(list) {
    const gnbItems = list.filter((item) => item.menuName !== '이벤트')
    const gnbEventItems = list.filter((item) => item.menuName === '이벤트')
    setGnbItems(gnbItems)
    onChangeGnbItems(gnbItems)
    setGnbEventItems(gnbEventItems)
    onChangeGnbEventItems(gnbEventItems[0].childGnbMenus)
  }

  useEffect(() => {
    if (list === null || refresh) {
      getGnbList()
    } else {
      handleGnbList(list)
    }
    return () => setRefresh(false)
  }, [list, refresh])

  return { gnbItems, gnbEventItems, setRefresh }
}
