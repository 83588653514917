import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { useSearchParams } from 'react-router-dom'

import cx from 'classnames'
import TitleBar from 'webview/ai/components/TitleBar'
import { callIOS, callNative, userAgent } from 'webview/bridge'

import s from './component/Chat.module.scss'
import ChatContainer from './component/ChatContainer'
import ChatFooter from './component/ChatFooter'
import MissionList from './component/MissionList'
import ModalBack from './component/ModalBack'
import useAiMessageFeed from './hooks/useAiMessageFeed'

export default function AiTravelChat() {
  const location = useLocation()
  const state = location?.state
  const [searchParams] = useSearchParams()
  const classSeq = Number(searchParams.get('classSeq'))
  const lectureSeq = Number(searchParams.get('lectureSeq'))
  const userSeq = Number(searchParams.get('userSeq'))
  const {
    isAiResponse,
    isChecked,
    resetFeedList,
    addToFeed,
    initPromptMessage,
    postPromptMessage
  } = useAiMessageFeed({
    chatWindow: state?.metaInfo.chatWindow,
    classSeq,
    lectureSeq,
    missions: state?.metaInfo.missions,
    userSeq
  })
  const [modalBack, setModalBack] = useState(false)

  useEffect(() => {
    callNative(
      function () {
        callIOS({ action: 'hideTitleBar' })
      },
      function () {
        window.Android.hideTitleBar()
      }
    )

    window['wingBannerDisplay']()
  }, [])

  useEffect(() => {
    const metaTag = document.querySelector('meta[name="viewport"]')
    let originalContent = ''

    if (metaTag && userAgent() === 'ios') {
      originalContent = metaTag.content
      metaTag.content = `${originalContent},viewport-fit=cover`
    }

    return () => {
      if (metaTag && userAgent() === 'ios') {
        metaTag.content = originalContent
      }
    }
  }, [])

  useEffect(() => {
    if (classSeq && lectureSeq && userSeq) {
      initPromptMessage()
    }

    return () => resetFeedList()
  }, [initPromptMessage, resetFeedList, classSeq, lectureSeq, userSeq])

  if (!state) return null

  return (
    <>
      <div className={cx(s.chatLayout, s.aiTravelLayout)}>
        <TitleBar title={'AI 여행영어'} type="close" onBtnClick={() => setModalBack(!modalBack)} />
        <MissionList missions={state?.metaInfo.missions} isChecked={isChecked} />
        <ChatContainer
          metaInfo={state?.metaInfo}
          isAiResponse={isAiResponse}
          lectureSeq={lectureSeq}
          userSeq={userSeq}
        />
        <ChatFooter
          addToFeed={addToFeed}
          postPromptMessage={postPromptMessage}
          isAiResponse={isAiResponse}
        />
      </div>
      {modalBack && <ModalBack onClick={() => setModalBack(!modalBack)} />}
    </>
  )
}
