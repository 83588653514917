import { useEffect, useState } from 'react'

import useGetUser from 'hooks/useGetUser'

import api from 'common/api'
import { YANADOO_AI_HOST } from 'common/config'

import s from '../aiTalk.module.css'
import { useSearchParams } from 'react-router-dom'

const isEmpty = (val) => {
  if (val === undefined || val === null) return true

  if (typeof val === 'string' || Array.isArray(val)) {
    return val.length === 0
  }

  if (typeof val === 'object') {
    return Object.keys(val).length === 0
  }

  return false
}

export default function AiTravelTranslateBtn({
  type,
  message,
  translateText,
  setTranslated,
  setTransLoading,
  setTranslateText,
  userSeq
}) {
  const [able, setAble] = useState(false)

  const postMessage = async (userSeq, msg) => {
    if (translateText === null) {
      setTransLoading(true)

      const dataBody = {
        userSeq,
        message: msg.trim()
      }
      await api
        .post(`${YANADOO_AI_HOST}/translate`, dataBody)
        .then((response) => {
          setTranslateText(response.data)
          setTransLoading(false)
        })
        .catch((e) => {
          alert('연결이 불안정 합니다. 다시한번 번역 버튼을 눌러주세요')
        })
    }

    setAble(!able)
  }

  useEffect(() => {
    setTranslated(able)
  }, [able])

  return (
    <div
      className={`${s.transBtn} ${type === 'AI' ? s.transAi : s.transUser} ${
        able ? s.unable : s.able
      }`}
    >
      <button onClick={() => postMessage(userSeq, message)}>번역</button>
    </div>
  )
}
