import styled from 'styled-components';
import theme from 'common/theme';

export default function YndButton({ size, name, btnTheme, style, onClick }) {
  return (
    <>
      <CustomButton fontSize={size} ThemeColor={btnTheme} style={style} onClick={onClick}>
        {name}
      </CustomButton>
    </>
  );
}

const CustomButton = styled.button`
  padding: 0.6rem 1.7rem 0.5rem 1.7rem;
  ${theme.StyleFontSize}
  ${theme.StyleBackgroundColor}
  border-radius: 0.3rem;

  @media ${({ theme }) => theme.device.mobile} {
  }
`;
