import { useEffect, useState } from 'react'

import api from 'common/api'

export default function useGetNoticeList({ currentSeq, size }) {
  const [noticeList, setNoticeList] = useState([])
  const fetchNoticeList = async () => {
    try {
      const response = await api.get(`/v2/notice/list?index=${currentSeq}&size=${size}`)
      const data = response.data.data.content
      setNoticeList(data)
    } catch (e) {
      console.error(`fetch faq list error : `, e)
    }
  }

  useEffect(() => {
    fetchNoticeList()
    return () => setNoticeList([])
  }, [currentSeq, size])

  return { noticeList }
}
