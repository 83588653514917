import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Autoplay } from 'swiper';
import api from 'common/api';
//style
import '../assets/styles/banner.scss';
import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';

SwiperCore.use([Pagination, Autoplay]);

const Banner = ({
  userName,
  bannerTitle,
  margin, // margin: 있다면 옵션 추가
  loop, // loop: 있다면 옵션 추가
  autoPlay, // autoPlay: 있다면 옵션 추가
  pagination, // pagination: 있다면 옵션 추가
  slideView, // slideView: 보여져야 하는 슬라이드 수 값
  type, // type: 배너 타입 (big, normal)
  data, // data: 슬라이드 데이터
}) => {
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);
  const deviceCheck = useMediaQuery({ maxWidth: 1024 }) ? true : false;
  const swiperMargin = margin ? margin : null;
  const swiperLoop = loop ? true : false;
  const swiperPagination = pagination ? true : false;

  const [linkTypeData, setLinkTypeData] = useState(null);

  // 링크형 이벤트 조회수
  const getLinkTypeData = (eventSeq) => {
    api
      .get('/v2/event/' + eventSeq)
      .then((response) => {
        setLinkTypeData(response.data.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <>
      <div className={`banner-col ${type}`}>
        {bannerTitle && (
          <strong className="banner-tit">
            {userName}
            {bannerTitle}
          </strong>
        )}
        <div className="banner-slide">
          {data && data.length > 1 ? (
            <Swiper
              className="swiper-container"
              spaceBetween={swiperMargin}
              slidesPerView={slideView}
              loop={swiperLoop}
              pagination={{ clickable: true }}
              autoplay={
                autoPlay
                  ? {
                      delay: 3000,
                      disableOnInteraction: false,
                    }
                  : ''
              }
              navigation={{
                prevEl: navigationPrevRef.current,
                nextEl: navigationNextRef.current,
              }}
              onSwiper={(swiper) => {
                setTimeout(() => {
                  swiper.params.navigation.prevEl = navigationPrevRef.current;
                  swiper.params.navigation.nextEl = navigationNextRef.current;

                  swiper.navigation.destroy();
                  swiper.navigation.init();
                  swiper.navigation.update();
                });
              }}
            >
              {data &&
                data.map((bannerSlide, index) => {
                  return (
                    <SwiperSlide key={index} className="swiper-slide">
                      {bannerSlide.eventType ? (
                        <>
                          {bannerSlide.eventType === 'LINK' ? (
                            <a
                              onClick={(e) => getLinkTypeData(bannerSlide.eventSeq)}
                              href={bannerSlide.bannerLink}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img src={bannerSlide.packageBannerImageFilePath} alt={bannerSlide.eventName} />
                            </a>
                          ) : (
                            <Link to={`/event/detail/${bannerSlide.eventSeq}`} className="slide-item">
                              <img src={bannerSlide.packageBannerImageFilePath} alt={bannerSlide.eventName} />
                            </Link>
                          )}
                        </>
                      ) : (
                        <Link
                          to={bannerSlide.link}
                          className="slide-item"
                          style={{ backgroundColor: `${bannerSlide.bgColor}` }}
                        >
                          {deviceCheck ? (
                            <img src={bannerSlide.imagePath2} alt={bannerSlide.imageInfo} />
                          ) : (
                            <img src={bannerSlide.imagePath1} alt={bannerSlide.imageInfo} />
                          )}
                        </Link>
                      )}
                    </SwiperSlide>
                  );
                })}
            </Swiper>
          ) : (
            <>
              {data &&
                data.map((bannerSlide, index) => {
                  return bannerSlide.eventType ? (
                    bannerSlide.eventType === 'LINK' ? (
                      <a
                        key={index}
                        onClick={(e) => getLinkTypeData(bannerSlide.eventSeq)}
                        href={bannerSlide.bannerLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={bannerSlide.packageBannerImageFilePath} alt={bannerSlide.eventName} />
                      </a>
                    ) : (
                      <Link key={index} to={`/event/detail/${bannerSlide.eventSeq}`} className="slide-item">
                        <img src={bannerSlide.packageBannerImageFilePath} alt={bannerSlide.eventName} />
                      </Link>
                    )
                  ) : (
                    <Link
                      key={index}
                      to={bannerSlide.link}
                      className="slide-item"
                      style={{ backgroundColor: `${bannerSlide.bgColor}` }}
                    >
                      {deviceCheck ? (
                        <img src={bannerSlide.imagePath2} alt={bannerSlide.imageInfo} />
                      ) : (
                        <img src={bannerSlide.imagePath1} alt={bannerSlide.imageInfo} />
                      )}
                    </Link>
                  );
                })}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Banner;
