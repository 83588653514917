import { useMemo } from 'react'

import useHeaderHeightStore from 'store/useHeaderHeightStore'

// 스크롤 내릴때 gnb hide/show에 따른 높이값을 반환하는 함수
export default function useHideHeader() {
  const headerHeight = useHeaderHeightStore((state) => state.headerHeight)
  const isHideHeader = useHeaderHeightStore((state) => state.isHideHeader)

  const top = useMemo(() => {
    return isHideHeader ? 0 : headerHeight
  }, [headerHeight, isHideHeader])

  return { isHideHeader, headerHeight, top }
}
