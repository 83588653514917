import React from 'react';

import ThumbNail from './ThumbNail';

import styled, { ThemeProvider } from 'styled-components';
import theme from 'common/theme';

import IcoReply from '../../assets/images/ico_reply_90x90.png';

export default function AdminAnswer({ data, backgroundColor, deviceCheck, type }) {
  function formatDate(date) {
    if (date) {
      let getDate = date.split(' ');
      return getDate[0];
    } else {
      return null;
    }
  }

  return (
    <ThemeProvider theme={theme}>
      {data && (
        <AdminAnswerBox type={type} style={{ backgroundColor: backgroundColor }}>
          <div>
            <ThumbNail
              backgroundColor={'#fff'}
              backgroundImage={`${data.answerUser.profileImageUrl}`}
              size={deviceCheck ? '8.2rem' : '5.4rem'}
            />
            <p>
              <strong>{data.answerUser.nickName}</strong>
              <span>{formatDate(data.registDate)}</span>
            </p>
          </div>
          <pre
            dangerouslySetInnerHTML={{
              __html: data.contents,
            }}
          />
          {data?.attachment?.url && (
            <img src={data.attachment.url} alt="학습관리자에서 등록한 이미지" />
          )}
        </AdminAnswerBox>
      )}
    </ThemeProvider>
  );
}

const AdminAnswerBox = styled.div`
  padding: ${(props) => (props.type ? '3.5rem' : '3rem 2.5rem')};

  > div {
    display: flex;
    position: relative;
    align-items: center;
    padding-left: ${(props) => (props.type ? '3.4rem' : '0')};

    &::before {
      content: '';
      display: ${(props) => (props.type ? 'block' : 'none')};
      position: absolute;
      top: 0;
      left: 0;
      width: 3rem;
      height: 3rem;
      background: url(${IcoReply}) 50% 50% no-repeat;
      background-size: 100% auto;
    }

    p {
      margin-left: 1.5rem;

      strong {
        display: block;
        font-weight: 700;
        font-size: 1.7rem;
      }

      span {
        display: block;
        margin-top: 0.6rem;
        font-weight: 400;
        font-size: 1.3rem;
        color: #a0a0b6;
      }
    }
  }

  pre {
    margin-top: 1.9rem;
    white-space: pre-wrap;
    font-weight: 400;
    font-size: 1.6rem;

    & + img {
      margin-top: 2rem;
    }
  }

  @media ${({ theme }) => theme.device.mobile} {
    padding: ${(props) => (props.type ? '4rem' : '7rem 4.2rem')};

    > div {
      padding-left: ${(props) => (props.type ? '5.6rem' : '0')};

      &::before {
        width: 5.5rem;
        height: 5.5rem;
      }

      p {
        margin-left: 2.2rem;

        strong {
          font-size: 2.6rem;
        }

        span {
          margin-top: 0.8rem;
          font-size: 2rem;
        }
      }
    }

    pre {
      margin-top: 5rem;
      font-size: 2.6rem;

      & + img {
        margin-top: 5rem;
      }
    }
  }
`;
