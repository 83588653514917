import { Link } from 'react-router-dom'

import s from 'pages/english/EnglishHome.module.css'
import { Swiper, SwiperSlide } from 'swiper/react'

import ResponsiveImage from 'components/ResponsiveImage'

import 'swiper/swiper.scss'

export default function BannerSwiper({ bannerList }) {
  return (
    <section className={s.slideSection}>
      {bannerList.length > 0 ? (
        <Swiper
          loop
          pagination={{
            clickable: true,
            el: '.eh-pagination',
            bulletActiveClass: s.bulletActive,
            bulletClass: s.bullet
          }}
        >
          {bannerList?.map((item, index) => {
            return (
              <SwiperSlide key={index}>
                <Link
                  to={item.link}
                  className={s.swiperItem}
                  style={{
                    backgroundColor: item.bgColor
                  }}
                  target={item.linkType === 'NEW' ? '_blank' : '_self'}
                >
                  <ResponsiveImage
                    className={s.bannerImage}
                    mo={item.bannerFileMobile}
                    pc={item.bannerFilePc}
                    maxWidth={1024}
                  />
                </Link>
              </SwiperSlide>
            )
          })}
        </Swiper>
      ) : (
        <div className={s.swiperItem}></div>
      )}
      <div className={`eh-pagination ${s.pagination}`}></div>
    </section>
  )
}
