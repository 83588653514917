import cx from 'classnames'

import { CHAT_COMPONENTS_CONFIG } from 'components/chat/config'

import AiFeedIntro from './AiFeedIntro'
import s from './AiInformation.module.scss'

export default function AiInformation({ data, isSupported }) {
  const { images, info, key } = data
  return (
    <div className={cx(s.aiInfo, { [s.webviewTopInfo]: !isSupported })}>
      <div className={s.colDescription}>
        <dl className={s.description}>
          <dt className={s.name}>{info.fullname}</dt>
          <dd className={s.profile}>
            <span>
              {info.age} / {info.mbti} / {info.job}
            </span>
            {CHAT_COMPONENTS_CONFIG.svg.dot}
            <span>{info.location}</span>
          </dd>
          <dd className={s.hashBlock}>
            {info.hashtag.map((item, index) => {
              return <span key={index}>{item}</span>
            })}
          </dd>
        </dl>
        <figure className={s.figure}>
          <img src={images.circle} alt={info.fullname} />
        </figure>
      </div>
      <div className={s.colTips}>
        <p>
          <AiFeedIntro ai={key} />
        </p>
      </div>
    </div>
  )
}
