import { YANADOO_CDN_HOST } from 'common/config'

export const IPADAIR_HOST_CDN = `${YANADOO_CDN_HOST}/promotion/tabpackagefive/2023/`
export const IPADAIR_HOST_CDN_UPDATE = `${YANADOO_CDN_HOST}/promotion/tabpackageAir/2024/`

const tabPackageAirValues = {
  HOST_CDN: `${YANADOO_CDN_HOST}/promotion/tabpackagefive/2023/`,
  HOST_CDN_UPDATE: `${YANADOO_CDN_HOST}/promotion/tabpackageAir/2024/`,
  metaInfo: {
    title: '평생수강 + iPad Air6',
    description:
      '걱정 없이 평생 수강하고 iPad Air6 받아요! 영어회화 기초부터 원어민과의 대화까지 올 케어 공부에 최적화된 iPad Air6도 챙기세요.',
    site_name: '하루 10분 야나두',
    imgsrc: `${YANADOO_CDN_HOST}/banner/img_tabpackagesix_og.png`
  },
  keyvisualBanner: {
    backgroundColor: '#2371ff',
    maxWidth: 1024,
    pc: `${YANADOO_CDN_HOST}/promotion/etc/img_ready-summer_pc.png`,
    mo: `${YANADOO_CDN_HOST}/promotion/etc/img_ready-summer_m.png`
  },
  ipadColorValue: [
    {
      pc: `${IPADAIR_HOST_CDN_UPDATE}tabpackagesix_ipad_space.png`,
      mo: `${IPADAIR_HOST_CDN_UPDATE}m_tabpackagesix_ipad_space.png`,
      alt: '아이패드 스페이스 그레이 컬러',
      color: '스페이스 그레이',
      colorEn: 'gray'
    },
    {
      pc: `${IPADAIR_HOST_CDN_UPDATE}tabpackagesix_ipad_blue.png`,
      mo: `${IPADAIR_HOST_CDN_UPDATE}m_tabpackagesix_ipad_blue.png`,
      alt: '아이패드 블루 컬러',
      color: '블루',
      colorEn: 'blue'
    },
    {
      pc: `${IPADAIR_HOST_CDN_UPDATE}tabpackagesix_ipad_purple.png`,
      mo: `${IPADAIR_HOST_CDN_UPDATE}m_tabpackagesix_ipad_purple.png`,
      alt: '아이패드 퍼플 컬러',
      color: '퍼플',
      colorEn: 'purple'
    },
    {
      pc: `${IPADAIR_HOST_CDN_UPDATE}tabpackagesix_ipad_star.png`,
      mo: `${IPADAIR_HOST_CDN_UPDATE}m_tabpackagesix_ipad_star.png`,
      alt: '아이패드 스타라이트 컬러',
      color: '스타라이트',
      colorEn: 'star'
    }
  ],
  infoBoxList: [
    { text: 'M2칩 탑재로 엄청난 속도와 막강 퍼포먼스 구현' },
    { text: '40% 더 빠른 Neural Engine으로 강력한 AI' },
    { text: '반사 방지 코팅과 True Tone 기술로 눈이 편안한 화면' },
    { text: 'P3의 넓은 색영역으로 이미지를 더욱 생생하게' },
    { text: '넉넉한 배터리로 최대 10시간까지 강의 시청 가능' },
    { text: '스테이지 매니저 기능으로 새로운 방식의 멀티태스킹까지' },
    { text: '가로 방향 스테레오 스피커로 2배 더 깊은 저음' }
  ],
  experienceList: [
    {
      pc: `${IPADAIR_HOST_CDN}mar/img_pc_tabpackagefive_exp_1.png`,
      mo: `${IPADAIR_HOST_CDN}mar/img_m_tabpackagefive_exp_1.png`,
      alt: '아이패드로 공부하는 사진',
      text: (
        <>
          <strong>언제 어디서나 iPad로 공부</strong>하니 <br />
          실력이 점점 늘어요!
        </>
      )
    },
    {
      pc: `${IPADAIR_HOST_CDN}mar/img_pc_tabpackagefive_exp_2.png`,
      mo: `${IPADAIR_HOST_CDN}mar/img_m_tabpackagefive_exp_2.png`,
      alt: '아이패드로 공부하는 사진',
      text: (
        <>
          <strong>iPad로 강의를 들으며 멀티로 필기</strong>
          <br />할 수 있어 더 체계적이에요!
        </>
      )
    },
    {
      pc: `${IPADAIR_HOST_CDN}mar/img_pc_tabpackagefive_exp_3.png`,
      mo: `${IPADAIR_HOST_CDN}mar/img_m_tabpackagefive_exp_3.png`,
      alt: '아이패드로 공부하는 사진',
      text: (
        <>
          <strong>위클리, 먼슬리 플랜</strong>을 세우며 <br />
          공부할 수 있어서 더욱 알차요!
        </>
      )
    }
  ],
  specialBasicList: [
    {
      pc: `${IPADAIR_HOST_CDN_UPDATE}tabpackagesix_ipad6.png`,
      mo: `${IPADAIR_HOST_CDN_UPDATE}m_tabpackagesix_ipad6.png`,
      alt: '아이패드 6세대 이미지',
      text: (
        <>
          <span>기본혜택</span>
          언제 어디서나 강의 재생! <br />
          실행력 강화되는 iPad Air6
        </>
      )
    },
    {
      pc: `${IPADAIR_HOST_CDN}mar/img_pc_tabpackagefive_special_2.png`,
      mo: `${IPADAIR_HOST_CDN}mar/img_m_tabpackagefive_special_2.png`,
      alt: '아이패드 이미지',
      text: (
        <>
          <span>기본혜택</span>
          기초-비즈니스까지 1,830강
          <br />
          무제한 평생수강
          <em>(단, 클래스 강의 제외)</em>
        </>
      )
    }
  ],
  specialAdditionList: [
    {
      pc: `${IPADAIR_HOST_CDN}mar/img_pc_tabpackagefive_special_3.png`,
      alt: '공부 습관 잡아주는 장학금',
      textPc: '공부 습관 잡아주는 장학금 증정',
      textMo: `공부 습관 잡아주는\n장학금 증정`
    },
    {
      pc: `${YANADOO_CDN_HOST}/promotion/tabpackagefive/2024/img_pc_tabpackagefive_special_4.png`,
      alt: '공부 습관 잡아주는 장학금',
      textPc: 'AI 원어민톡 제공',
      textMo: `나만의 AI 원어민 친구 \nAI 원어민톡`
    },
    {
      pc: `${IPADAIR_HOST_CDN}mar/img_pc_tabpackagefive_special_5.png`,
      alt: '공부 습관 잡아주는 장학금',
      textPc: '필수 단어 정복! 틈새단어 앱 이용권',
      textMo: `필수 단어 정복! \n틈새단어 앱 이용권`
    },
    {
      pc: `${IPADAIR_HOST_CDN}may/img_pc_tabpackagefive_special_6.png`,
      alt: '공부 습관 잡아주는 장학금',
      textPc: '알차게 복습! 강의별 PDF 학습자료',
      textMo: `알차게 복습! \n강의별 PDF 학습자료`
    },
    {
      pc: `${IPADAIR_HOST_CDN}may/img_pc_tabpackagefive_special_7.png`,
      alt: '공부 습관 잡아주는 장학금',
      textPc: '입으로 훈련하는 기초영어회화 NEW 트레이닝 북',
      textMo: `기초영어회화 훈련\nNEW 트레이닝 북`
    }
  ],
  threePointList: [
    {
      strongTxt: '마법 패턴',
      description: `익숙한 문장 하나로 수 천개의 \n문장을 만드는 마법! 더 이상 문장을\n어렵게 암기하지 마세요`
    },
    {
      strongTxt: '9단계 입체 커리큘럼',
      description: `영어로 의사소통하기 위한 듣고 말하는 \n모든 영역을 다각도로 한번에 체득할\n수 있습니다`
    },
    {
      strongTxt: '증명된 10분 학습효과',
      description: `우리 뇌의 집중력은 단 10분! \n10분으로 완성하는 1시간의 학습효과`
    }
  ],
  threeStepList: [
    {
      pc: `${IPADAIR_HOST_CDN}mar/img_pc_tabpackagefive_step_1.png`,
      mo: `${IPADAIR_HOST_CDN}mar/img_m_tabpackagefive_step_1.png`,
      alt: 'step1 문장패턴 익히기, step2 단어바꿔 말해보기, step3 확장해서 말해보기',
      title: (
        <>
          원어민이 영어를 배우는 원리
          <strong>마법 패턴</strong>
        </>
      ),
      description: '익숙한 문장 하나로 다양한 표현을 쉽게 배워요'
    },
    {
      pc: `${IPADAIR_HOST_CDN}mar/img_pc_tabpackagefive_step_2.png`,
      mo: `${IPADAIR_HOST_CDN}mar/img_m_tabpackagefive_step_2.png`,
      alt: 'step1 문장패턴 익히기, step2 단어바꿔 말해보기, step3 확장해서 말해보기',
      title: (
        <>
          말하기에 필요한 모든 것<strong>9단계 입체 커리큘럼</strong>
        </>
      ),
      description: '전문 강사들의 11년 연구 결실을 압축했어요'
    },
    {
      pc: `${IPADAIR_HOST_CDN}mar/img_pc_tabpackagefive_step_3.png`,
      mo: `${IPADAIR_HOST_CDN}mar/img_m_tabpackagefive_step_3.png`,
      alt: 'step1 문장패턴 익히기, step2 단어바꿔 말해보기, step3 확장해서 말해보기',
      title: (
        <>
          짧게 집중해서 10분
          <strong>증명된 학습효과</strong>
        </>
      ),
      description: '원리/ 표현 연습/ 복습까지 10분 강의 하나면 충분해요'
    }
  ],
  reviewSlide: {
    title: '야 나두 했어!',
    description: '10분 영어 이런게 좋아요',
    background: `linear-gradient(135deg, #6151ff, #813fff)`
  },
  teacherList: [
    {
      pc: `${IPADAIR_HOST_CDN}mar/img_pc_tabpackagefive_star_1.png`,
      mo: `${IPADAIR_HOST_CDN}mar/img_m_tabpackagefive_star_1.png`,
      name: '원예나',
      text: '야나두 대표 강사'
    },
    {
      pc: `${IPADAIR_HOST_CDN}mar/img_pc_tabpackagefive_star_2.png`,
      mo: `${IPADAIR_HOST_CDN}mar/img_m_tabpackagefive_star_2.png`,
      name: '오드리',
      text: '230만 유튜버 영어 선생님'
    },
    {
      pc: `${IPADAIR_HOST_CDN}mar/img_pc_tabpackagefive_star_3.png`,
      mo: `${IPADAIR_HOST_CDN}mar/img_m_tabpackagefive_star_3.png`,
      name: '에일린',
      text: '연예인 전담 강사'
    },
    {
      pc: `${IPADAIR_HOST_CDN}mar/img_pc_tabpackagefive_star_4.png`,
      mo: `${IPADAIR_HOST_CDN}mar/img_m_tabpackagefive_star_4.png`,
      name: '설비',
      text: '외국어 특강 전문 강사'
    }
  ],
  tenMinutesValue: [
    {
      img: `${YANADOO_CDN_HOST}/promotion/curriculum/img_ten01.jpg`,
      alt: '뇌파 메핑 분석결과',
      guideTxt: '출처 : KBS 수요기획 <하루 10분의 기적>중, 프로그램 내 자체 검사 결과',
      title: '뇌를 제대로 활용하는 10분',
      description: (
        <>
          뇌는 한 번에 할당할 수 있는 용량이 제한적이기 때문에 뇌의 활성도를 높이고, 집중력이 높은
          시간 동안 빠르게 주입 시키는 것이 필요합니다.
          <br />
          즉, 긴 시간 많은 양을 학습하는 것보다 10분 동안 꾸준히 하는 것이 더욱효과적입니다.
        </>
      )
    },
    {
      img: `${YANADOO_CDN_HOST}/promotion/curriculum/img_ten02.jpg`,
      alt: '반복의 중요성 분석자료',
      guideTxt: '출처 : KBS 수요기획 <하루 10분의 기적>중, 프로그램 내 자체 검사 결과',
      title: '반복의 중요성 분석자료',
      description: (
        <>
          뇌에는 정보 저장 창고 역할을 하는 '해마'가 있습니다.
          <br />
          하지만, 정보를 보관하는 기간이 한 달을 넘지 않기 때문에 지속적인 자극을 주어야 합니다.
          <br />
          실험 연구 결과반복 학습 시 기억 효과가 약 2배 이상 높게 나타났습니다.
        </>
      )
    },
    {
      img: `${YANADOO_CDN_HOST}/promotion/curriculum/img_ten03.jpg`,
      alt: '반복을 가능케 하는 10분',
      guideTxt: '출처 : 습관과 뇌 활용의 상관관계 by.케이온',
      title: '강력한 습관을 만드는 10분',
      description: (
        <>
          영어학습이 습관이 아닌 그룹은 뇌가 일을 늘 새롭게 해야 하기에 스트레스를 많이 받습니다.
          반면 영어학습이 습관인 그룹은 뇌가 조금만 일해도 그 일을 잘 해낼 수 있습니다. <br />
          즉, 영어학습이 습관이 되면 아주 적은 노력만으로 영어를 잘하게 됩니다.
        </>
      )
    },
    {
      img: `${YANADOO_CDN_HOST}/promotion/curriculum/img_ten04.png`,
      alt: '뇌구조연구 > 적정학습량 > 장기기억저장 > 효과극대화',
      title: '결과의 차이를 만드는 10분',
      description: (
        <>
          우리의 뇌 구조를 파악하여 적정 학습 시간을 설계, 소화할 수 있는 적정 양의 학습 내용을
          주입, 반복을 통해 배운 내용을 장기 기억으로 저장, 습관을 형성하여 큰 노력 없이도 충분히
          영어 실력을 향상시킬 수 있게 되는 10분 영어입니다.
        </>
      )
    }
  ],
  dbModalForm: {
    title: ['가장 합리적으로 공부하는 방법', '지금 상담 받으세요'],
    pCategory: 'tabPackageAir5',
    eventName: '탭패키지',
    formName: 'tabPackageAir5Form',
    btnText: '상담 예약하기',
    content: 'iPad',
    btnBg: `linear-gradient(90deg, #6555ff, #8543ff)`,
    btnColor: '#fff',
    banner: {
      src: `${IPADAIR_HOST_CDN_UPDATE}tabpackagesix_db_banner.png`,
      width: 550,
      height: 230
    }
  },
  dbModalButton: {
    buttonColor: '#fff',
    buttonBackground: 'linear-gradient(90deg, #6555ff, #8543ff)'
  },
  formValues: {
    category: 'tabPackageAir5',
    type: 'tabPackageAir',
    title: '지금 문의하시면 추가 혜택을 드려요',
    description: `더 많은 혜택을 알아보세요`,
    content: 'iPad'
  }
}
export default tabPackageAirValues
