/**
 * 주어진 ID를 가진 HTML 요소로 스크롤 하는 함수
 *
 * @param {string} id - 스크롤 대상 요소의 ID
 * @param {('smooth' | 'instant')} [behavior='smooth'] - 스크롤이 즉각적인지 또는 부드럽게 움직이는지 여부를 결정 (기본값: smooth)
 * @returns {void}
 */
export const moveToSection = (id, behavior = 'smooth') => {
  const target = document.getElementById(id)

  if (!target) return

  window.scrollTo({
    top: target.offsetTop,
    behavior
  })
}
