import React from 'react'

import ModalPortal from 'ModalPortal'

import s from './ModalAiTravel.module.scss'

export default function ModalFeedback({ onClick, onConfirm }) {
  return (
    <ModalPortal>
      <div className={s.modalAiTravel}>
        <div className={s.modalContent}>
          <h3>
            대화를 종료하고
            <br />
            피드백을 받으시겠습니까?
          </h3>
          <div className={s.btnGroup}>
            <button onClick={onClick}>계속 대화하기</button>
            <button className={s.btnConfirm} onClick={onConfirm}>
              피드백 받기
            </button>
          </div>
        </div>
      </div>
    </ModalPortal>
  )
}
