import DetailEntrance from 'pages/product/DetailEntrance'
import SearchResult from 'pages/searchResult'
import StoreProduct from 'pages/store/detail'
import StoreCategory from 'pages/store/id'
import StoreList from 'pages/store/list'
import { ClassStore } from 'router/OutletStore'

const ClassRoutes = [
  {
    path: 'store/*',
    element: <ClassStore />,
    children: [
      { path: 'list', element: <StoreList /> },
      { path: ':categorySeq', element: <StoreCategory /> },
      { path: 'detail/:packageSeq', element: <StoreProduct /> },
      { path: 'entrance/:packageSeq', element: <DetailEntrance /> }
    ]
  },
  { path: 'search-result', element: <SearchResult /> }
]

export default ClassRoutes
