import Board from 'pages/community/Board'
import BoardReview from 'pages/community/BoardReview'
import DetailBoard from 'pages/community/DetailBoard'
import DetailBoardReview from 'pages/community/DetailBoardReview'
import { Community } from 'router/OutletStore'

const CommunityRoutes = [
  {
    path: 'community/*',
    element: <Community />,
    children: [
      { path: 'free/list', element: <Board type="FREE" /> },
      { path: 'free/detail/:currentSeq', element: <DetailBoard type="FREE" /> },
      { path: 'study/list', element: <Board type="STUDY" /> },
      { path: 'study/detail/:currentSeq', element: <DetailBoard type="STUDY" /> },
      { path: 'srr/list', element: <Board type="SRR" /> },
      { path: 'srr/review', element: <BoardReview type="REVIEW" /> },
      { path: 'srr/detail/:currentSeq', element: <DetailBoard type="SRR" /> },
      { path: 'review/detail/:currentSeq', element: <DetailBoardReview type="REVIEW" /> }
    ]
  }
]

export default CommunityRoutes
