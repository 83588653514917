import { useMediaQuery } from 'react-responsive'

import classNames from 'classnames'
import { postGAEvent } from 'utils/postGAEvent'

import DynamicLink from 'components/DynamicLink'
import { headerValues } from 'components/header/config'

import ChildGnbMenu from './ChildGnbMenu'
import styles from '../../styles/SubHeader.module.scss'

export default function GnbItem({
  item,
  index,
  currentPath,
  onActiveMobileGnb,
  isMoDepth3Chk,
  depth2Active,
  depth2Selected,
  companyItem,
  setDepth2Selected,
  setMoDepth3Chk,
  depthSelected
}) {
  const isMobile = useMediaQuery({ maxWidth: 1024 })

  const onClickGnbItem = () => {
    setDepth2Selected(index)
    postGAEvent(item.gaEventName, item.gaEventName)
  }
  const isSelected = depth2Selected === index || depthSelected === index
  const isActive = depth2Active === index && isMoDepth3Chk

  return (
    <li
      key={index}
      className={classNames(styles.gnbItem, styles[headerValues.gnbMenuSeq[companyItem[2]]])}
    >
      {isMobile && item.childGnbMenus.length > 0 ? (
        <button
          onClick={() => onActiveMobileGnb(index)}
          className={classNames(
            styles.gnbLink,
            isSelected && styles.selected,
            isActive && styles.active
          )}
        >
          {item.menuName}
        </button>
      ) : (
        <DynamicLink
          pathname={item.link}
          target={`_${item.linkTarget.toLowerCase()}`}
          className={classNames(
            styles.gnbLink,
            isSelected && styles.selected,
            isActive && styles.active
          )}
        >
          <span className={styles.gnbName} onClick={onClickGnbItem}>
            {item.menuName}
          </span>
        </DynamicLink>
      )}

      {/* 2depth가 있을 경우 */}
      {item.childGnbMenus?.length > 0 && (
        <ChildGnbMenu list={item.childGnbMenus} setMoDepth3Chk={setMoDepth3Chk} />
      )}
    </li>
  )
}
