import { v4 as uuid } from 'uuid'

import StepItem from './StepItem'
import s from '../aiTalk.module.css'
import { stepValues } from '../config'

export default function SectionStep() {
  const onClickConversation = () => {
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: 'smooth'
    })
  }

  return (
    <section className={s.step}>
      <div className={s.inner}>
        <hgroup className={s.titleGroup}>
          <h3>
            야나두 AI 친구를 만나면
            <br />확 달라질 거예요.
          </h3>
        </hgroup>
        <ul className={s.stepList}>
          {stepValues.map((item) => {
            return (
              <li key={uuid()}>
                <StepItem data={item} />
              </li>
            )
          })}
        </ul>
        <button onClick={onClickConversation} className={s.btnConversation}>
          야나두 AI 친구와 대화하기
        </button>
      </div>
    </section>
  )
}
