import ResponsiveImage from 'components/ResponsiveImage'

import { HOST_CDN } from '../config'
import s from '../EnglishHome.module.css'
export default function TeacherSection() {
  return (
    <section className={s.teacher}>
      <div className={s.teacherImg}>
        <ResponsiveImage pc={`${HOST_CDN}img_teacher.png`} mo={`${HOST_CDN}img_m_teacher.png`} alt={'야나두 대표강사'} maxWidth={1024} />
      </div>
    </section>
  )
}
