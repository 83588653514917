import classNames from 'classnames'
import { postGAEvent } from 'utils/postGAEvent'

import DynamicLink from 'components/DynamicLink'

import styles from '../../styles/SubHeader.module.scss'

export default function ChildGnbMenu({ list, setMoDepth3Chk }) {
  return (
    <ul className={styles.depthMenuList}>
      {list.map((sub) => (
        <li
          key={`${sub.depth}-${sub.gnbMenuSeq}`}
          className={classNames(
            styles[sub.iconType && sub.iconType !== 'NONE' && sub.iconType.toLowerCase()],
            styles.depthMenuItem
          )}
          onClick={() => {
            setMoDepth3Chk(false)
            postGAEvent(sub.gaEventName, sub.gaEventName)
          }}
        >
          <DynamicLink pathname={sub.link} target={`_${sub.linkTarget.toLowerCase()}`}>
            {sub.menuName}
          </DynamicLink>
        </li>
      ))}
    </ul>
  )
}
