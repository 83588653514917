import React, { useLayoutEffect, useMemo, useRef } from 'react'

import { CategoryScale, Chart, Filler, Legend, LineController, LineElement, LinearScale, PointElement, Title, Tooltip } from 'chart.js'
import classNames from 'classnames/bind'
import useLevelTestAdvancedResultStore from 'store/levelTestAdvanced/useLevelTestAdvancedResultStore'
import { v4 as uuid } from 'uuid'

import { ONLY_NUMBER_REGEXR } from 'common/regExr'

import styles from '../../LevelTestAdvancedResult.module.scss'

Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler, LineElement, LineController)

export default function LineChart() {
  const { lineChartData, level } = useLevelTestAdvancedResultStore()
  const canvasNode = useRef(null)
  const labels = useMemo(() => {
    return ['Lv.1', 'Lv.2', 'Lv.3', 'Lv.4', 'Lv.5', 'Lv.6']
  }, [])

  useLayoutEffect(() => {
    if (!level) return

    const ctx = canvasNode.current
    const data = lineChartData.map((data) => parseFloat(data))
    const chart = new Chart(ctx, {
      type: 'line',
      data: {
        labels,
        datasets: [
          {
            label: '',
            data,
            fill: false,
            borderColor: '#CFCFDA',
            pointStyle: (context) => {
              return context.dataIndex === getLevelIndex(level) ? 'cicle' : false
            },
            pointRadius: 5,
            pointBackgroundColor: '#fff',
            pointBorderColor: '#000',
            pointBorderWidth: 2,
            tension: 0.05
          }
        ]
      },
      options: {
        responsive: true,
        plugins: {
          title: {
            display: false
          },
          legend: {
            display: false
          },
          tooltip: {
            enabled: false
          }
        },
        scales: {
          x: {
            border: {
              display: false
            },
            grid: {
              display: false
            },
            ticks: {
              display: false
            }
          },
          y: {
            min: 0,
            max: 100,
            border: {
              display: false
            },
            grid: {
              color: '#ECECF6',
              lineWidth: 2
            },
            ticks: {
              display: false,
              stepSize: 20
            }
          }
        }
      }
    })

    return () => {
      chart.destroy()
    }
  }, [lineChartData, labels, level])

  const getLevelIndex = (level) => {
    return parseInt(level.replace(ONLY_NUMBER_REGEXR, '')) - 1
  }

  return (
    <>
      <div className={styles.lineChart}>
        <canvas height={150} ref={canvasNode}></canvas>
        {level && (
          <ul className={classNames(styles.labels, styles[level])}>
            {labels.map((label, idx) => {
              return (
                <li className={idx === getLevelIndex(level) ? styles.active : ''} key={uuid()}>
                  {label}
                </li>
              )
            })}
          </ul>
        )}
      </div>
    </>
  )
}
