import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

/**
 * @typedef {Object} GnbItemType
 * @property {string} backgroundColor
 * @property {number} depth
 * @property {string} description
 * @property {string} gaEventName
 * @property {number} gnbMenuSeq
 * @property {string} iconType
 * @property {string} link
 * @property {string} linkTarget
 * @property {string} menuName
 * @property {GnbItemType[]} childGnbMenus
 */
/**
 * @typedef {Object} GnbActiveDepthIndexType
 * @property {number} depth1
 * @property {number} depth2
 * @property {number} depth3
 */

/**
 * @typedef {Object} StoreType
 * @property {boolean} isMain : 메인 GNB 보여줄지 체크하기 위한 상태값.
 * @property {GnbItemType[]} gnbItems : gnb 메뉴
 * @property {GnbItemType[]} gnbEventItems : 이벤트 메뉴
 * @property {GnbActiveDepthIndexType} gnbActiveDepthIndex : 상세 GNB 활성화 된 index (3depth)
 * @property {number} currentSubIndex :상세 GNB를 호출 할때, 몇번째 GNB를 호출할지 체크하는 상태값.
 */

/**@type {StoreType} */
const initialState = {
  isMain: true,
  gnbItems: [],
  gnbEventItems: [],
  gnbActiveDepthIndex: null,
  currentSubIndex: null
}

const useHeaderStore = create(
  devtools(
    immer((set) => ({
      ...initialState,
      onChangeIsMain: (is) =>
        set(
          (state) => {
            state.isMain = is
          },
          false,
          'header/onChangeIsMain'
        ),
      onChangeCurrentSubIndex: (index) =>
        set(
          (state) => {
            state.currentSubIndex = index
          },
          false,
          'header/onChangeCurrentSubIndex'
        ),
      onChangeGnbItems: (items) =>
        set(
          (state) => {
            state.gnbItems = items
          },
          false,
          'header/onChangeGnbItems'
        ),
      onChangeGnbEventItems: (items) =>
        set(
          (state) => {
            state.gnbEventItems = items
          },
          false,
          'header/onChangeGnbEventItems'
        ),
      onChangeGnbActiveDepthIndex: (depth) =>
        set(
          (state) => {
            state.gnbActiveDepthIndex = depth
          },
          false,
          'header/onChangeGnbActiveDepthIndex'
        )
    }))
  )
)

export default useHeaderStore
