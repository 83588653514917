import React from 'react'
import { Link } from 'react-router-dom'

import styled from 'styled-components'

const TabMenu = styled.ul`
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;

  li {
    & + li {
      margin-left: 3rem;
    }

    a {
      display: block;
      padding: 0.2rem 0;
      font-size: 2rem;
      color: #a0a0b6;
    }

    &.active {
      a {
        font-weight: 700;
        color: #000;
        border-bottom: 0.3rem solid #ffb900;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    padding: 0 4.2rem;
    border-bottom: 0.1rem solid #dfdfea;
    background-color: #fff;

    li {
      & + li {
        margin-left: 5.5rem;
      }

      a {
        padding: 4rem 0;
        font-size: 2.8rem;
      }

      &.active {
        a {
          border-bottom: 0.4rem solid #ffb900;
        }
      }
    }
  }
`

export default function TabQna({ activeTab }) {
  return (
    <div>
      <TabMenu>
        <li className={activeTab === 'INQUIRY' ? 'active' : null}>
          <Link to="/mypage/qna/inquiry">1:1 문의</Link>
        </li>
        <li className={activeTab === 'PRODUCT' ? 'active' : null}>
          <Link to="/mypage/qna/product">상품 문의</Link>
        </li>
        <li className={activeTab === 'LEARNING' ? 'active' : null}>
          <Link to="/mypage/qna/learning">학습 문의</Link>
        </li>
        <li className={activeTab === 'REVIEW' ? 'active' : null}>
          <Link to="/mypage/qna/review">후기</Link>
        </li>
      </TabMenu>
    </div>
  )
}
