import { Link } from 'react-router-dom'

import styles from './NoContentButtons.module.scss'

export default function NoContentButtons() {
  return (
    <div className={styles.buttons}>
      <Link to="https://yanadoo.co.kr/custom/all_lecture" target="_blank">
        야나두 영어 알아보기
      </Link>
      <Link to="https://yanadoo.co.kr/store/list" target="_blank">
        야나두 클래스 알아보기
      </Link>
    </div>
  )
}
