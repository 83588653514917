export const LIMITED_TIME = 3600

export const partTitleValues = ['어휘/문법', '뉘앙스', '듣기/말하기']

// 각 파트의 Intro 설명
export const testPartIntroValues = [
  {
    id: 1,
    title: 'Part 1. 어휘/문법',
    description: (
      <>
        알맞은 단어 사용과 <br />
        문법에 대한 <br className="display-m" />
        이해력을 평가해요
      </>
    ),
    subDescription: `· 예문을 읽고 단어를 선택하거나, 문장을 완성해 보세요
      · 총 9개의 문제가 제공됩니다`,
    relativeClass: `관련 클래스
      #오드리의 오-트립 #써먹는 VOCA #스르르 학습지 원어민팩`
  },
  {
    id: 2,
    title: 'Part 2. 뉘앙스',
    description: (
      <>
        대화의 뉘앙스를
        <br />
        파악할 수 있는지
        <br className="display-m" /> 확인해요
      </>
    ),
    subDescription: `· 예문을 읽고 알맞은 표현을 선택해 보세요
    · 총 4개의 문제가 제공됩니다`,
    relativeClass: `관련 클래스
    #리얼대화영어 입문편 #비지니스 응급키트`
  },
  {
    id: 3,
    title: 'Part 3. 듣기/말하기',
    description: (
      <>
        주제와 상황에 맞게 <br />
        답변하는 능력을
        <br className="display-m" /> 확인해요
      </>
    ),
    subDescription: `· 영상을 보거나 음성을 듣고 질문에 대답해 보세요
      · 총 7개의 문제가 제공됩니다`,
    relativeClass: `관련 클래스
      #스르르 학습지3 #리얼대화영어 입문 #이디엄이 뭔대영`
  }
]

// 자가진단 문항
export const selfCheckSectionValues = {
  1: {
    key: 'reason',
    question: `영어 공부 시작을\n망설이고 있는 이유는?`,
    alertMessage: '영어 공부 시작을 망설이는 이유',
    answers: [
      {
        value: '금전적 비용이 부담스러워요',
        description: (
          <>
            내 텅장...
            <br />
            <b>금전적 비용이 부담스러워요 💸</b>
          </>
        )
      },
      {
        value: '중도 포기 할까 봐 두려워요',
        description: (
          <>
            배추 한 포기.. 두 포기..
            <br />
            <b>중도 포기 할까 봐 두려워요 🥶</b>
          </>
        )
      },
      {
        value: '공부할 시간이 부족해요',
        description: (
          <>
            바쁘다 바빠 현대사회
            <br />
            <b>공부할 시간이 부족해요 ⏰</b>
          </>
        )
      }
    ]
  },
  2: {
    key: 'method',
    question: `원하는 공부 방법이\n있으신가요?`,
    alertMessage: '원하는 영어 공부 방법',
    answers: [
      {
        value: '일상 시간을 활용해서 공부할래요 ',
        description: (
          <>
            짧고 굵게!
            <br />
            <b>일상 시간을 활용해서 공부할래요 ⏰</b>
          </>
        )
      },
      {
        value: '반복 학습을 좋아해요',
        description: (
          <>
            여러 번 학습해서 내 것으로
            <br />
            <b>반복 학습을 좋아해요 👩‍🎓</b>
          </>
        )
      },
      {
        value: '까먹지 않게 평생 공부하고 싶어요',
        description: (
          <>
            놓치지 않을 거에요
            <br />
            <b>까먹지 않게 평~생 공부하고 싶어요 ️🔁</b>
          </>
        )
      }
    ]
  },
  3: {
    key: 'goal',
    alertMessage: '영어 공부의 목표',
    question: `영어공부로 달성하고자\n하는 목표는 무엇인가요?`,
    answers: [
      {
        value: '자유롭게 해외 여행가기를',
        description: '자유롭게 해외 여행가기 ✈️'
      },
      {
        value: '기초 이론 및 문법 마스터를',
        description: '기초 이론 및 문법 마스터 👑'
      },
      {
        value: '원어민 급 발음 구사를',
        description: '원어민 급 발음 구사 🗣'
      },
      {
        value: '내 생각 고민 없이 표현하기를',
        description: '내 생각 고민 없이 표현하기 🧠'
      },
      {
        value: '간단한 의사 소통을',
        description: '간단한 의사 소통 👂👄'
      }
    ]
  }
}

// 분석중 페이지 - 후기 slider 내용
export const reviewListValues = [
  { id: 0, title: '영잘알 야나두!', writer: '이*름', description: `문법, 어휘, 듣기 등 문제 구성도 다양하고 \n내가 얼마나 영어를 잘 알고 있는지 정확히 평가를 받을 수 있어 좋았어요!ㅎㅎ ` },
  { id: 1, title: '선택과 집중', writer: '고*배', description: `심화 레벨테스트를 통해서 \n제가 부족한 부분과 강한 부분을 알게 되었어요! \n부족한 부분만 집중하면 되니 공부에 대한 부담도 줄었네요.` },
  { id: 2, title: '	야나두 1:1 퍼스널 진단?', writer: '김*진', description: `혹시 이건 야나두만의 1:1 퍼스널 진단인가요? \n파트마다 결과를 자세히 알려주고 지금 내 영어 실력은 상위 몇프로인지도 나와요ㅋㅋ 동기부여 제대로 됩니다` },
  { id: 3, title: '재도전 의지 뿜뿜!', writer: '이*미', description: `영어,, 어디서부터 시작할지 막막했는데, \n나에게 맞는 학습 계획을 알려줘서 믿고 따라가면 될 것 같아요! 전 오늘부터 당장 시작하려구요! 의지 뿜뿜` },
  { id: 4, title: '영어 실력 끌어올려!', writer: '안*현', description: `심화레벨테스트 해보고 스스로 영어 실력 객관화하는데 도움됐어요! 친구들에게도 심화레벨테스트 공유했습니당! \n다같이 영어 실력 끌어올려~~~` },
  { id: 5, title: '레벨 4까지 화이팅!', writer: '장*지', description: `영어 실력이 궁금했는데 전 레벨 2 나왔어요! \n야나두에서 알려준 학습법으로 한달 후 레벨 4 도전해봅니다! 다들 파이팅` }
]

// 문항 미선택시 alertMessage
export const LTA_ALERT_MESSAGE = {
  COMPLETE_SENTENCE_ANSWER_NONE: '4개 문항을 알맞은 문장 순서대로 모두 선택해 주세요.',
  DEFAULT_ANSWER_NONE: '정확한 결과를 위해 모든 문항에 답을 해주세요.'
}
