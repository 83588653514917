import React, { useState, useEffect, useCallback } from 'react';
import '../../assets/styles/modal.scss';
import '../../assets/styles/_modalMindsetIntro.scss';
import ModalPortal from '../../../src/ModalPortal';
import ModalMindsetInput from './ModalMindsetInput';
import AlertModal from './AlertModal';
import api from 'common/api';
import * as config from 'common/config';

import img_mindset_01 from '../../assets/images/img_mindset_01_960x1600.png';
import img_mindset_02 from '../../assets/images/img_mindset_02_960x1600.png';
import img_mindset_03 from '../../assets/images/img_mindset_03_960x1600.png';
import img_mindset_04 from '../../assets/images/img_mindset_04_960x1600.png';
import img_mindset_05 from '../../assets/images/img_mindset_05_960x1600.png';
import img_mindset_06 from '../../assets/images/img_mindset_06_960x1600.png';
import img_mindset_07 from '../../assets/images/img_mindset_07_960x1600.png';

export default function ModalMindsetIntro ({ title, btnClose, handleCloseModal }) {

	const [modalopen1, setModalopen1] = useState(false);
  const [modalopenAlert, setModalopenAlert] = useState(false);

	const handleOpenModalMindsetInput = useCallback((e) => {
		document.body.classList.add("modal-open");
		setModalopen1(true);
	}, []);

	const handleOpenModalAlert = useCallback((e) => {
    document.body.classList.add('modal-open');
    setModalopenAlert(true);
  }, []);

  const handleCloseAlertModal = useCallback((e) => {
    document.body.classList.remove('modal-open');
    setModalopenAlert(false);
  }, []);

	const introContentList = [
		{
			"colorCode": "#F8623F",
			"imageURL": img_mindset_01
		},
		{
			"colorCode": "#63D893",
			"imageURL": img_mindset_02
		},
		{
			"colorCode": "#FFC466",
			"imageURL": img_mindset_03
		},
		{
			"colorCode": "#2B75E8",
			"imageURL": img_mindset_04
		},
		{
			"colorCode": "#63D893",
			"imageURL": img_mindset_05
		},
		{
			"colorCode": "#925CF6",
			"imageURL": img_mindset_06
		},
		{
			"colorCode": "#FC5D6A",
			"imageURL": img_mindset_07
		}
	]

	let motionTimer ='';

	const introMotion = (sec) => {
		let introItem = document.querySelectorAll('.intro-item');
		let i = 0;
		introItem[i].style.display = 'block';
		document.querySelector('.mindset-intro').style.backgroundColor = introItem[i].style.color;
		i=1;

		motionTimer = setInterval(function() {
			if(i < introItem.length){
				introItem[i-1].style.display = 'none';
				introItem[i].style.display = 'block';
				document.querySelector('.mindset-intro').style.backgroundColor = introItem[i].style.color;
				i++;
			} else if(i == introItem.length){
				if(i > 0) introItem[i-1].style.opacity = 0;
				document.querySelector('.mindset-intro').classList.add('end-motion');
			} else {
				clearInterval(motionTimer);
			}
		}, sec);
	}

	// 초기 랜더링 시 현재 입학 상태 여부 체크(비정상 접근 시 예외처리)
  const getMindsetState = () => {
    api
      .get(config.AUTH_API_HOST + '/auth/v2/user')
      .then((response) => {
				let result = response.data.data.entrance;

				if(result.entranceStatus !== 'ENTRANCE'){ // 입학 상태가 아님
					alert('입학 상태가 아닙니다.');
					handleCloseModal();
				} else if(result.entranceStatus === 'ENTRANCE' && !result.isPledgePopTarget && !result.isWriteEntrancePledge){ // 입학 상태이면서, APP 팝업 발생 조건이 아니고, 서약서 미작성
					alert('서약서 작성 가능 상태가 아닙니다.');
					handleCloseModal();
				} else if(result.entranceStatus === 'ENTRANCE' && !result.isPledgePopTarget && result.isWriteEntrancePledge){ // 입학 상태이면서, APP 팝업 발생 조건이 아니고, 서약서 작성을 한경우
					setModalopen1(true);
				}
      })
      .catch((e) => {
        console.log(e);
      });
  };

	let delayCheck = false;

	const handleConfirmClose = () => {
		if(delayCheck){
			alert('잠시후에 다시 시도해주세요');
		} else {
			delayCheck = true;
			api
      .post(
        config.AUTH_API_HOST +
				'/auth/v2/user/pledge/pop-close'
      )
      .then((response) => {
        if (response.data.meta.code === 200) {
          handleCloseAlertModal();
					handleCloseModal();
					delayCheck = false;
        } else {
          alert(response.data.meta.message);
					delayCheck = false;
        }
      })
      .catch((e) => {
        alert(config.MESSAGE['common-error']);
				delayCheck = false;
      });
		}
  };
	
	useEffect(() => {
		introMotion(300);
		getMindsetState();
		return () => {
			clearInterval(motionTimer);
		}
	},[])

	return (
		<>
			<div className="modal-wrap">
				<div className="dimmed" onClick={handleOpenModalAlert}></div>
				<div className="modal-inner" style={{height:'100%'}}>
					{btnClose && (
							<button type="button" className="btn-modal-close" onClick={handleOpenModalAlert}>
									<i className="blind">닫기</i>
							</button>
					)}
					<div className="modal-header">
						<strong>{title}</strong>
					</div>
					<div className="modal-content" style={{height:'100%'}}>
						<div className="mindset-intro">
							<ul>
								{introContentList.map((item, idx) =>
									<li key={idx} style={{color: item.colorCode}} className="intro-item">
										<img src={item.imageURL} alt="" />
									</li>
								)}
							</ul>
							<div className="last-motion">
								<div className="last-inner">
									<p>지금 당신의 목표는<br/>무엇인가요?</p>
									<span className="last-item"></span>
									<button type="button" onClick={handleOpenModalMindsetInput}>서약서 작성하기</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{modalopen1 ? (
				<ModalPortal>
					<ModalMindsetInput
						btnClose
						title={'서약서 작성'}
						handleCloseModal={handleCloseModal}
					/>
				</ModalPortal>
			) : null}
			{modalopenAlert ? (
        <ModalPortal>
          <AlertModal
            // btnClose
            btnGroup
						btnGroupTxtClose={'취소'}
						btnGroupTxtConfirm={'다음에 하기'}
            msg={'다음에 서약하기\n\'마이페이지 > 서약서 작성하기\'에서 이어서 진행하실 수 있습니다.'}
            handleCloseModal={handleCloseAlertModal}
            handleConfirmClose={handleConfirmClose}
          />
        </ModalPortal>
      ) : null}
		</>
	);
};
