import { memo, useCallback, useState, useEffect } from 'react';
import api from 'common/api';
import ModalPortal from 'ModalPortal';
import ModalApplyCoupon from 'components/modal/ModalApplyCoupon';

import BgCoupon from '../../assets/images/bg_coupon_v2.png';
import BgUsedCoupon from '../../assets/images/bg_coupon_used.png';
import BgLineCoupon from '../../assets/images/bg_coupon_line.png';
import icoCheck from '../../assets/images/ico_check_38x30b.png';

import styled, { css, ThemeProvider } from 'styled-components';
import theme from 'common/theme';

const Coupon = memo(
  ({
    flag,
    type, // list : 마이페이지 쿠폰 목록 , down : 쿠폰 다운로드 팝업, input : 중복 쿠폰 선택 팝업
    couponData,
    selectedCoupon,
    handleSelectedCoupon,
    couponPriceLimit,
    getCouponDownloadData,
    priceInfoSeq,
    isUrlButton,
  }) => {
    const [modalopen, setModalopen] = useState(false);
    const [couponPackage, setCouponPackage] = useState(null);
    const [couponName, setCouponName] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [couponUrl, setCouponUrl] = useState('');
    const addComma = (num) => {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };
    const onChangeDash = (date) => {
      return date.replace(/-/g, '.');
    };

    const handleOpenModal = useCallback((e, couponPackage, name, url) => {
      e.stopPropagation();
      e.preventDefault();

      document.body.classList.add('modal-open');
      setModalopen(true);
      setCouponPackage(couponPackage);
      setCouponName(name);
      setCouponUrl(url);
    }, []);

    const handleCloseModal = useCallback((e) => {
      document.body.classList.remove('modal-open');
      setModalopen(false);
    }, []);

    // 개별 쿠폰 다운로드 API
    const downLoadCoupon = (couponCode) => {
      const data = {
        couponCode: couponCode,
      };
      api
        .post(`/v2/coupon/download?couponCode=${data.couponCode}`)
        .then((response) => {
          if (response.data.meta.code !== 200) {
            alert(response.data.meta.message);
            setIsLoading(!isLoading);
          } else {
            alert('쿠폰 다운로드가 완료되었습니다!');
            window.location.reload();
          }
        })
        .catch((e) => {
          console.log(e);
        });
    };

    useEffect(() => {
      if (isLoading) {
        priceInfoSeq ? getCouponDownloadData(priceInfoSeq) : getCouponDownloadData();
        setIsLoading(!isLoading);
      }
    }, [isLoading]);

    return (
      <>
        <ThemeProvider theme={theme}>
          <li>
            {type === 'input' ? (
              <CouponLabel
                htmlFor={couponData.couponSeq}
                status={
                  //!overlapDisabled && 중복 사용 가능 쿠폰 && 선택된 쿠폰 배열 리스트에 중복 사용 가능 쿠폰이 있을경우
                  (!flag.overlapDisabled &&
                    couponData.overlapUseYn !== 'Y' &&
                    selectedCoupon.some((coupon) => coupon.overlapUseYn === 'Y')) ||
                  flag.overlapDisabled ||
                  // shareDisabled && 중복 사용 가능 쿠폰 && 수익 배분 쿠폰
                  (flag.shareDisabled &&
                    couponData.overlapUseYn === 'Y' &&
                    couponData.couponKindCode === 'SHARE')
                }
              >
                <input
                  type="checkbox"
                  name={couponData.overlapUseYn}
                  id={couponData.couponSeq}
                  value={couponData.couponKindCode}
                  onChange={handleSelectedCoupon}
                  checked={selectedCoupon.some((coupon) =>
                    coupon.number === String(couponData.couponSeq) ? true : false
                  )}
                  disabled={
                    // 중복 사용 가능 && 쿠폰 할인 금액 > 최대 할인 금액
                    couponData.discountPrice > couponPriceLimit && couponData.overlapUseYn === 'Y'
                  }
                />
                <CouponItem status={type === 'input' ? 'down' : ''}>
                  <CouponBox>
                    <div>
                      <strong
                        className={
                          selectedCoupon.some(
                            (coupon) => coupon.number === String(couponData.couponSeq)
                          )
                            ? 'check'
                            : ''
                        }
                      >
                        {couponData.discountType === 'PERCENT'
                          ? couponData.discountPercent + `% 할인`
                          : addComma(Number(couponData.discountPrice)) + '원 할인'}
                      </strong>
                      <p>{couponData.couponName}</p>
                      {couponData.minimumPriceYn === 'Y' && (
                        <p>
                          {addComma(Number(couponData.minimumPrice))}원 이상 구매 시
                          {couponData.maximumDiscountPriceYn === 'Y' &&
                            '/ 최대 ' + couponData.maximumDiscountPrice + '원 할인'}
                        </p>
                      )}
                      {couponData.couponPublishEndStatus === 'EXPIRED' ||
                      couponData.couponPublishEndStatus === 'USED' ? (
                        <span
                          className={
                            couponData.couponPublishEndStatus === 'USED' ? 'used' : 'expired'
                          }
                        >
                          {couponData.couponPublishEndStatus === 'EXPIRED'
                            ? '기간 만료'
                            : couponData.couponPublishEndStatus === 'USED'
                            ? '사용 완료'
                            : ''}
                        </span>
                      ) : (
                        <>
                          {couponData.restrictedPackageYn === 'N' ? (
                            <span>전체 상품 적용</span>
                          ) : (
                            <span
                              onClick={
                                couponData.restrictedPackageYn === 'Y'
                                  ? (e) => {
                                      handleOpenModal(
                                        e,
                                        couponData.couponToPackageInfoDTOList,
                                        couponData.couponName
                                      );
                                    }
                                  : undefined
                              }
                              style={{ cursor: 'pointer' }}
                            >
                              적용 상품
                            </span>
                          )}
                        </>
                      )}
                    </div>
                    <div>
                      <strong className={couponData.overlapUseYn !== 'Y' ? 'disabled' : ''}>
                        {couponData.overlapUseYn === 'Y'
                          ? '중복 사용 가능'
                          : couponData.overlapUseYn === 'S'
                          ? '단독 사용'
                          : '중복 사용 불가'}
                      </strong>
                      <span>
                        {couponData.expiredDate &&
                          onChangeDash(couponData.expiredDate.substring(0, 10)) + ' 까지'}
                      </span>
                    </div>
                  </CouponBox>
                </CouponItem>
              </CouponLabel>
            ) : (
              <CouponItem
                status={
                  type === 'down' || type === 'input'
                    ? 'down'
                    : couponData.couponPublishEndStatus === 'EXPIRED' ||
                      couponData.couponPublishEndStatus === 'USED'
                    ? 'disabled'
                    : ''
                }
                onClick={
                  type === 'list' &&
                  couponData.restrictedPackageYn === 'Y' &&
                  !couponData.couponPublishEndStatus
                    ? (e) => {
                        handleOpenModal(
                          e,
                          couponData.couponToPackageInfoDTOList,
                          couponData.couponName,
                          couponData.couponUrl
                        );
                      }
                    : undefined
                }
              >
                <CouponBox
                  status={
                    type === 'list' && couponData.restrictedPackageYn === 'N' ? ' disabled' : ''
                  }
                  style={{ cursor: type === 'down' && 'default' }}
                >
                  <div>
                    <strong>
                      {couponData.discountType === 'PERCENT'
                        ? couponData.discountPercent + `% 할인`
                        : addComma(Number(couponData.discountPrice)) + '원 할인'}
                    </strong>
                    <p>{couponData.couponName}</p>
                    {couponData.minimumPriceYn === 'Y' && (
                      <p>
                        {addComma(Number(couponData.minimumPrice))}원 이상 구매 시
                        {couponData.maximumDiscountPriceYn === 'Y' &&
                          '/ 최대 ' + couponData.maximumDiscountPrice + '원 할인'}
                      </p>
                    )}
                    {type === 'down' ? (
                      <>
                        {couponData.restrictedPackageYn === 'N' ? (
                          <span>전체 상품 적용</span>
                        ) : (
                          <span
                            onClick={
                              couponData.restrictedPackageYn === 'Y'
                                ? (e) => {
                                    handleOpenModal(
                                      e,
                                      couponData.couponToPackageInfoDTOList,
                                      couponData.couponName,
                                      couponData.couponUrl
                                    );
                                  }
                                : undefined
                            }
                            style={{ cursor: 'pointer' }}
                          >
                            적용 상품
                          </span>
                        )}
                      </>
                    ) : (
                      <>
                        {couponData.couponPublishEndStatus === 'EXPIRED' ||
                        couponData.couponPublishEndStatus === 'USED' ? (
                          <span
                            className={
                              couponData.couponPublishEndStatus === 'USED' ? 'used' : 'expired'
                            }
                          >
                            {couponData.couponPublishEndStatus === 'EXPIRED'
                              ? '기간 만료'
                              : couponData.couponPublishEndStatus === 'USED'
                              ? '사용 완료'
                              : ''}
                          </span>
                        ) : (
                          <span>
                            {couponData.restrictedPackageYn === 'N'
                              ? '전체 상품 적용'
                              : '적용 상품'}
                          </span>
                        )}
                      </>
                    )}
                  </div>
                  <div>
                    <strong className={couponData.overlapUseYn !== 'Y' ? 'disabled' : ''}>
                      {couponData.overlapUseYn === 'Y'
                        ? '중복 사용 가능'
                        : couponData.overlapUseYn === 'S'
                        ? '단독 사용'
                        : '중복 사용 불가'}
                    </strong>
                    {type === 'list' ? (
                      <span>
                        {couponData.expiredDate &&
                          onChangeDash(couponData.expiredDate.substring(0, 10)) + ' 까지'}
                      </span>
                    ) : (
                      <>
                        <span>
                          {couponData.expiredDay &&
                            '다운로드 후 ' + couponData.expiredDay + '일이내 사용'}
                          {couponData.expiredDate &&
                            onChangeDash(couponData.expiredDate.substring(0, 10)) + ' 까지'}
                        </span>
                        {couponData.getCouponYn && couponData.getCouponYn ? (
                          <CouponDownButton className="disabled">발급완료</CouponDownButton>
                        ) : (
                          <CouponDownButton
                            onClick={() => {
                              downLoadCoupon(couponData.couponCode);
                            }}
                          >
                            쿠폰받기
                          </CouponDownButton>
                        )}
                      </>
                    )}
                  </div>
                </CouponBox>
              </CouponItem>
            )}
          </li>

          {modalopen ? (
            <ModalPortal>
              <ModalApplyCoupon
                btnClose
                title={'쿠폰 적용 상품'}
                couponName={couponName}
                couponPackage={couponPackage}
                handleCloseModal={handleCloseModal}
                couponUrl={couponUrl}
                isUrlButton={isUrlButton}
              />
            </ModalPortal>
          ) : null}
        </ThemeProvider>
      </>
    );
  }
);

export default Coupon;

const CouponLabel = styled.label`
  display: block;
  position: relative;

  ${(props) =>
    props.status === true &&
    css`
      > div {
        opacity: 0.6;
        cursor: default;
      }
    `}

  input {
    &:checked {
      + div {
        opacity: 1 !important;

        &::before,
        &::after {
          background-image: url(${BgLineCoupon});
        }

        > div {
          &::before {
            background-image: url(${BgLineCoupon});
          }
        }
      }
    }

    &:disabled {
      + div {
        opacity: 0.6;
      }
    }
  }
`;

const CouponItem = styled.div`
  display: block;
  position: relative;
  width: 100%;
  height: 19.5rem;
  text-align: left;
  cursor: pointer;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 2;
    width: 2.6rem;
    background-image: url(${BgCoupon});
    background-repeat: no-repeat;
    background-size: auto 100%;
    box-sizing: border-box;
  }

  &::before {
    left: 0;
    background-position: 0 0;
  }

  &::after {
    right: 0;
    background-position: 100% 0;
  }

  > div {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 2.6rem;
      left: 2.6rem;
      z-index: 1;
      background-image: url(${BgCoupon});
      background-repeat: no-repeat;
      background-size: auto 100%;
      background-position: -2.6rem 0;
    }
  }

  ${(props) =>
    props.status === 'disabled' &&
    css`
      box-shadow: none;

      &::before,
      &::after {
        background-image: url(${BgUsedCoupon});
      }

      > div {
        &::before {
          background-image: url(${BgUsedCoupon});
        }

        div:first-child {
          strong,
          p {
            color: #a0a0b6;
            + p {
              color: #a0a0b6;
            }
          }

          span {
            opacity: 0.5;
          }
        }

        div:last-child {
          strong {
            color: #a0a0b6;
            background: #dfdfea;
          }
        }
      }
    `}

  ${(props) =>
    props.status === 'down' &&
    css`
      height: 21rem;

      > div {
        padding-bottom: 1.4rem;
        cursor: default;

        div:first-child {
          strong {
            margin-bottom: 1.8rem;
          }

          p {
            font-size: 1.4rem;
          }

          span {
            font-size: 1.6rem;
          }
        }
      }
    `}

  @media ${({ theme }) => theme.device.mobile} {
    height: 33rem;

    ${(props) =>
      props.status === 'down' &&
      css`
        > div {
          padding-bottom: 2.2rem;

          div:first-child {
            strong {
              margin-bottom: 2rem;
            }

            p {
              font-size: 2.4rem;
            }

            span {
              font-size: 2.4rem;
            }
          }
        }
      `}
  }
`;

const CouponBox = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 2.6rem 3.2rem 2.2rem;
  box-sizing: border-box;

  > div {
    position: relative;
    z-index: 1;
  }

  ${(props) =>
    props.status === 'disabled' &&
    css`
      cursor: default;
    `}

  div:first-child {
    flex: 0 0 auto;
    overflow: hidden;

    strong {
      display: inline-block;
      position: relative;
      margin-bottom: 1.4rem;
      font-weight: 700;
      font-size: 2.4rem;
      line-height: 1.2;

      &.check {
        &::after {
          content: '';
          position: absolute;
          top: 0.2rem;
          right: -3.3rem;
          width: 2rem;
          height: 2rem;
          background: url(${icoCheck}) 50% 50% no-repeat;
          background-size: 100% auto;
        }
      }
    }

    p {
      font-weight: 700;
      font-size: 1.2rem;
      color: #cfcfda;
      line-height: 1.5;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      white-space: normal;

      & + p {
        margin-top: 0.2rem;
        font-weight: 400;
        color: #28284b;
        white-space: nowrap;
        -webkit-line-clamp: 1;
        line-height: 1.2;
      }
    }

    span {
      position: absolute;
      top: -2rem;
      right: -2rem;
      padding: 2rem;
      font-weight: 700;
      font-size: 1.4rem;
      color: #28284b;
      text-decoration: underline;

      &.used,
      &.expired {
        font-weight: 700;
        font-size: 1.8rem;
        text-decoration: none;
      }

      &.used {
        color: #269cff;
      }

      &.expired {
        color: #ff5647;
      }
    }
  }

  div:last-child {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    strong {
      display: inline-block;
      padding: 0.5rem 0.8rem;
      font-weight: 700;
      font-size: 1.2rem;
      color: #269cff;
      background: #f7f7fc;
      border-radius: 0.4rem;
      vertical-align: middle;

      &.disabled {
        color: #717188;
      }
    }

    span {
      display: inline-block;
      margin-left: 1rem;
      font-weight: 400;
      font-size: 1.2rem;
      color: #a0a0b6;
      vertical-align: middle;
    }
  }

  @media ${({ theme }) => theme.device.mobile} {
    padding: 4rem 5.2rem 3.4rem 5.2rem;

    div:first-child {
      strong {
        margin-bottom: 2rem;
        font-size: 4rem;

        &.check {
          &::after {
            top: 0.2rem;
            right: -5.3rem;
            width: 3.4rem;
            height: 3.4rem;
          }
        }
      }

      p {
        font-size: 2.4rem;
      }

      span {
        top: -4rem;
        right: -4rem;
        padding: 4rem;
        font-size: 2.4rem;

        &.used,
        &.expired {
          font-size: 3.2rem;
        }
      }
    }

    div:last-child {
      strong {
        padding: 0.8rem 1.2rem;
        font-size: 2rem;
        border-radius: 0.6rem;
      }

      span {
        margin-left: 1.4rem;
        font-size: 2rem;
      }
    }
  }
`;

const CouponDownButton = styled.button`
  margin-left: auto;
  padding: 0.9rem 1.7rem 0.7rem;
  font-weight: 700;
  font-size: 1.4rem;
  color: #fff;
  background-color: #269cff;
  border-radius: 10rem;

  &.disabled {
    cursor: auto;
    background-color: #cfcfda;
  }

  @media ${({ theme }) => theme.device.mobile} {
    padding: 0.9rem 2.6rem 1.1rem;
    font-size: 2.4rem;
    border-radius: 15rem;
  }
`;
