import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import api from 'common/api'
import * as config from 'common/config'
import Loading from 'components/common/Loading'
import ModalCertificate from 'components/modal/ModalCertificate'
import ModalReviewWrite from 'components/modal/ModalReviewWrite'
import ModalWrap from 'components/promotion/modal/ModalWrap'
import { formatDate } from 'components/promotion/utile/utile'
import ResponsiveImage from 'components/ResponsiveImage'

import ClassList from './ClassList'
import NoContentButtons from './NoContentButtons'
import s from './packageList.module.css'
import icoImg from '../../../assets/images/ico_no_class.png'
import NoContent from '../../../components/NoContent'

export default function PackageList({ myClassData, isLoading }) {
  const HOST_CDN = 'https://english.yanadoocdn.com/upload/yanadoo/new/common'
  const [modalControl, setModalControl] = useState({
    certificate: false,
    reviewWrite: false
  })
  const [seq, setSeq] = useState(null)
  const [packageType, setPackageType] = useState(null)
  const [openStates, setOpenStates] = useState({})

  const handleOpen = (index) => () => {
    setOpenStates((prevStates) => ({
      ...prevStates,
      [index]: !prevStates[index]
    }))
  }

  const handleModalOpen = (key, seq, packageType) => {
    setModalControl((prev) => ({ ...prev, [key]: true }))
    setSeq(seq)
    setPackageType(packageType)
  }
  const handleModalClose = () => {
    setModalControl({ certificate: false, reviewWrite: false })
  }
  // 내가 작성한 수강 후기가 있다면 데이터 업데이트
  const getMyreview = (
    setTextareaValue,
    setTextareaLength,
    setLearningReviewSeq,
    handleGetPoint,
    handleUpdateImage
  ) => {
    api
      .get('/v2/review/learning-review/' + seq)
      .then((response) => {
        if (response.data.meta.code === 200) {
          setTextareaValue(response.data.data.contents)
          setTextareaLength(response.data.data.contents.length)
          setLearningReviewSeq(response.data.data.learningReviewSeq)
          handleGetPoint(response.data.data.satisfaction)
          handleUpdateImage(response.data.data.attachments)
        }
      })
      .catch((e) => {
        alert(config.MESSAGE['common-error'])
      })
  }

  // 수강 후기 등록
  const postMyreview = (classData, textareaValue, satisfaction, registerImage) => {
    const data = {
      classSeq: classData.classSeq,
      contents: textareaValue,
      satisfaction: satisfaction,
      studyGroupUserSeq: seq,
      attachments: registerImage
    }

    api
      .post('/v2/review/learning-review', data)
      .then((response) => {
        if (response.data.meta.code !== 200) {
          alert(response.data.meta.message)

          return false
        }

        alert('수강 후기 등록이 완료되었습니다.')
        window.location.reload()
      })
      .catch((e) => {
        alert(config.MESSAGE['common-error'])
      })
  }

  // 수강 후기 수정
  const editMyReview = (learningReviewSeq, textareaValue, satisfaction, registerImage) => {
    const data = {
      learningReviewSeq: learningReviewSeq,
      contents: textareaValue,
      satisfaction: satisfaction,
      attachments: registerImage
    }

    api
      .put('/v2/review', data)
      .then((response) => {
        if (response.data.meta.code !== 200) {
          alert(response.data.meta.message)

          return false
        }

        alert('수강 후기 수정이 완료되었습니다.')
        window.location.reload()
      })
      .catch((e) => {
        alert(config.MESSAGE['common-error'])
      })
  }

  return (
    <div className={s.container}>
      {isLoading ? (
        <Loading />
      ) : myClassData.length > 0 ? (
        <div className={s.historyListBox}>
          {myClassData.map((myclass, idx) => {
            return (
              <div className={s.historyList} key={idx}>
                <div className={s.packageListContainer}>
                  <div className={s.infoContainer}>
                    <div className={s.classInfo}>
                      {myclass.packageTypeCode !== 'CLASS' ? (
                        <p className={`${s.badge} ${s.packageBadge}`}>패키지</p>
                      ) : (
                        <p className={`${s.badge} ${s.oneBadge}`}>단과</p>
                      )}
                      <p className={s.teacher}>{myclass.teacherName}</p>
                      {myclass.subscriptionType === 'U_PLUS' ||
                      myclass.learningAttendingStatus === 'READY' ? null : (
                        <p className={s.date}>
                          {formatDate(myclass.beginDate)}~{formatDate(myclass.closeDate)}
                        </p>
                      )}
                    </div>
                    <div className={s.titleBox}>
                      <div
                        className={s.title}
                        onClick={myclass.packageTypeCode !== 'CLASS' ? handleOpen(idx) : null}
                      >
                        <p
                          className={
                            myclass.packageTypeCode !== 'CLASS' ? s.packageTitle : s.classTitle
                          }
                        >
                          {myclass.packageName}
                        </p>
                      </div>
                      {myclass.packageTypeCode !== 'CLASS' ? (
                        <div className={s.toggleBox}>
                          <button
                            className={!openStates[idx] ? `${s.toggleUp}` : `${s.toggleDwn}`}
                            onClick={handleOpen(idx)}
                          >
                            <ResponsiveImage
                              pc={`${HOST_CDN}/ico_downBtn.png`}
                              mo={`${HOST_CDN}/ico_downBtn.png`}
                              alt="버튼"
                              maxWidth={1024}
                            />
                          </button>
                        </div>
                      ) : null}
                    </div>
                    <div className={`${s.classInfo2} ${s.disP}`}>
                      {myclass.subscriptionType !== 'U_PLUS' ? (
                        <p className={s.dday}>D-{myclass.remainingDays}</p>
                      ) : null}
                      {myclass.packageTypeCode !== 'SRR' && (
                        <>
                          <p className={s.attend}>{myclass.attendanceDays}</p>
                          <p className={s.progress}>{myclass.packageMediaProgressRate}%</p>
                        </>
                      )}
                    </div>
                  </div>
                  {myclass.packageTypeCode !== 'SRR' && (
                    <div className={s.btnBox}>
                      <button
                        className={`${
                          openStates[idx] && myclass.packageTypeCode !== 'CLASS' ? s.active : null
                        } ${myclass.learningAttendingStatus === 'READY' ? s.disabled : null} `}
                        onClick={() => {
                          handleModalOpen(
                            'certificate',
                            myclass.classes[0].studyGroupUserSeq,
                            myclass.packageTypeCode
                          )
                        }}
                      >
                        수강증
                      </button>
                      {myclass.versionCode === 'YND_CLS' && myclass.packageTypeCode === 'CLASS' && (
                        <button
                          className={`${
                            myclass.learningAttendingStatus === 'READY' ? s.disabled : null
                          } `}
                          onClick={() => {
                            handleModalOpen(
                              'reviewWrite',
                              myclass.classes[0].studyGroupUserSeq,
                              myclass.packageTypeCode
                            )
                          }}
                        >
                          후기작성
                        </button>
                      )}
                    </div>
                  )}
                </div>
                <div className={s.classListBox}>
                  {myclass.packageTypeCode !== 'CLASS' && openStates[idx] ? (
                    <>
                      {myclass.classes.map((myClassData, idx) => {
                        return (
                          <ClassList
                            key={idx}
                            myClassData={myClassData}
                            packageTypeCode={myclass.packageTypeCode}
                          />
                        )
                      })}
                    </>
                  ) : null}
                </div>
              </div>
            )
          })}
          {Object.entries(modalControl).map(([key, flag]) => {
            if (flag) {
              return (
                <ModalWrap key={key} onClose={handleModalClose}>
                  {key === 'certificate' ? (
                    <ModalCertificate
                      btnClose
                      studySeq={seq}
                      packageType={packageType}
                      handleCloseModalCertificate={handleModalClose}
                    />
                  ) : (
                    <ModalReviewWrite
                      title={'수강 후기 작성'}
                      btnClose
                      studySeq={seq}
                      packageType={packageType}
                      handleCloseModalReviewWrite={handleModalClose}
                      getMyreview={getMyreview}
                      postMyreview={postMyreview}
                      editMyReview={editMyReview}
                    />
                  )}
                </ModalWrap>
              )
            }
          })}
        </div>
      ) : (
        <div className="class-empty history">
          <NoContent icoImg={icoImg} titleMsg={'수강 내역이 없습니다.'}>
            <NoContentButtons />
          </NoContent>
        </div>
      )}
    </div>
  )
}
