import { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import ModalPortal from 'ModalPortal'
import AuthService from 'services/authService'

import api from 'common/api'
import { YAFIT_CYCLE_LINK } from 'common/config'
import ModalCourseInquiry from 'components/modal/ModalCourseInquiry'
import ModalPartnershipInquiry from 'components/modal/ModalPartnershipInquiry'
import useFloatingElementStore from 'store/useFloatingElementStore'
import useModalLogin from 'store/useModalLogin'

import IcoAndroid from 'assets/images/ico_android_download_70x70.png'
import IcoBand from 'assets/images/ico_band_70x70.png'
import IcoBlog from 'assets/images/ico_blog_70x70.png'
import IcoFacebook from 'assets/images/ico_facebook_70x70.png'
import IcoInsta from 'assets/images/ico_insta_70x70.png'
import IcoIos from 'assets/images/ico_ios_download_70x70.png'
import IcoNaverPost from 'assets/images/ico_naver_post_70x70.png'
import Logo from 'assets/images/img_footer_logo_444x106.png'
import ModalMyInquiry from './modal/ModalMyInquiry'

const Footer = () => {
  const location = useLocation()
  const paramChk = location.search.split('?')
  const param = paramChk.length > 1 ? paramChk[1].split('&')[paramChk[1].split('&').length - 1] : ''
  const param2 = paramChk.length > 1 ? paramChk[1].split('&')[0] : '' // couponDownload
  const couponCode = param
    ? paramChk[1].split('&')[paramChk[1].split('&').length - 1].split('=')[1]
    : ''

  const [modalopenMyInquiry, setModalopenMyInquiry] = useState(false)
  const [modalopenCourseInquiry, setModalopenCourseInquiry] = useState(false)
  const [modalopenPartnershipInquiry, setModalopenPartnershipInquiry] = useState(false)
  const [corpInfo, setCorpInfo] = useState(false)
  const { setIsModalLogin } = useModalLogin()

  // 개별 쿠폰 다운로드 API
  const downLoadCoupon = (couponCode) => {
    const data = {
      couponCode: couponCode
    }
    api
      .post(`/v2/coupon-publish?couponCode=${data.couponCode}`)
      .then((response) => {
        if (response.data.meta.code !== 200) {
          alert(response.data.meta.message)
          window.location.href = YAFIT_CYCLE_LINK
        } else {
          alert('쿠폰 다운로드가 완료되었습니다!')
          window.location.href = YAFIT_CYCLE_LINK
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const handleChangeSelect = (e) => {
    window.open(e.target.value, '_blank')
  }

  // 단체 수강 문의
  const handleOpenModalCourseInquiry = useCallback((e) => {
    document.body.classList.add('modal-open')
    setModalopenCourseInquiry(true)
  }, [])

  const handleCloseModalCourseInquiry = useCallback((e) => {
    document.body.classList.remove('modal-open')
    setModalopenCourseInquiry(false)
    let paramWord = 'courseInquiry'
    if (window.location.href.indexOf(paramWord) !== -1) {
      window.location.href = window.location.href
        .replace('?' + paramWord, '')
        .replace('&' + paramWord, '')
    }
  }, [])

  // 제휴 문의
  const handleOpenModalPartnershipInquiry = useCallback((e) => {
    document.body.classList.add('modal-open')
    setModalopenPartnershipInquiry(true)
  }, [])

  const handleCloseModalPartnershipInquiry = useCallback((e) => {
    document.body.classList.remove('modal-open')
    setModalopenPartnershipInquiry(false)
    let paramWord = 'partnershipInquiry'
    if (window.location.href.indexOf(paramWord) !== -1) {
      window.location.href = window.location.href
        .replace('?' + paramWord, '')
        .replace('&' + paramWord, '')
    }
  }, [])

  // 1:1 문의
  const handleOpenModalMyInquiry = useCallback((e) => {
    if (!AuthService.getUserInfo()) {
      handleOpenModalLogin()
    } else {
      document.body.classList.add('modal-open')
      setModalopenMyInquiry(true)
    }
  }, [])

  const handleCloseModalMyInquiry = useCallback((e) => {
    document.body.classList.remove('modal-open')
    setModalopenMyInquiry(false)
    let paramWord = 'myInquiry'
    if (window.location.href.indexOf(paramWord) !== -1) {
      window.location.href = window.location.href
        .replace('?' + paramWord, '')
        .replace('&' + paramWord, '')
    }
  }, [])

  const handleOpenModalLogin = (e) => {
    document.body.classList.add('modal-open')
    setIsModalLogin(true)
  }

  const { floatingElementHeight } = useFloatingElementStore()
  const footerMarginTop = isNaN(floatingElementHeight) ? 0 : floatingElementHeight

  useEffect(() => {
    if (param === 'courseInquiry') {
      handleOpenModalCourseInquiry()
    } else if (param === 'partnershipInquiry') {
      handleOpenModalPartnershipInquiry()
    } else if (param === 'myInquiry') {
      handleOpenModalMyInquiry()
    }
    // 야핏 쿠폰
    if (param2 === 'couponDownload') {
      downLoadCoupon(couponCode)
    }
  }, [])

  return (
    <>
      <footer className={`${corpInfo ? 'isOpen' : ''}`} style={{ marginBottom: footerMarginTop }}>
        <div className="inner-col">
          <h2 className="footer-logo">
            <a href="/">
              <img src={Logo} alt="야나두 푸터 로고" />
            </a>
          </h2>

          <div className="footer-nav-menu">
            <dl>
              <dt>고객센터</dt>
              <dd>
                <a href="/service/center">
                  <span>자주 묻는 질문</span>
                </a>
              </dd>
              <dd>
                <a href="/service/notice/1">
                  <span>공지사항</span>
                </a>
              </dd>
            </dl>
            <dl>
              <dt>카카오톡 상담하기</dt>
              <dd>
                <a href="https://pf.kakao.com/_xeWxjMu" target="_blank" rel="noreferrer">
                  야나두 카카오톡 상담하기
                </a>
              </dd>
              <dd>
                <a href="https://pf.kakao.com/_xmCkrG" target="_blank" rel="noreferrer">
                  야핏 카카오톡 상담하기
                </a>
              </dd>
            </dl>
            <dl>
              <dt>제휴 및 단체수강 문의</dt>
              <dd>
                <a href="/promotion-landing/b2bLanding">
                  <span>B2B 및 단체수강 소개</span>
                </a>
              </dd>
              <dd>
                <button type="button" onClick={handleOpenModalPartnershipInquiry}>
                  <span>제휴문의</span>
                </button>
              </dd>
              <dd>
                <button type="button" onClick={handleOpenModalCourseInquiry}>
                  <span>단체 수강 문의</span>
                </button>
              </dd>
            </dl>

            <div className="footer-utility">
              <div className="utility-group">
                <a href="/promotion/company">회사소개</a>
                <a href="/promotion/yanadooInfo">야나두 서비스</a>
                <a href="https://yanadoo.career.greetinghr.com/" target="_blank" rel="noreferrer">
                  야나두 채용
                </a>
              </div>
              <div className="utility-group">
                <a href="/service/official/1">공고사항</a>
                <a href="/service/yanadoo/service">이용약관</a>
                <a href="/service/refunddetail">환불규정안내</a>
                <a href="/service/yanadoo/privacy_policy?privacyOnly=y">
                  <span>개인정보처리방침</span>
                </a>
              </div>
            </div>
          </div>

          <div className="footer-link-area">
            <select name="footerSelect" id="footerSelect" onChange={handleChangeSelect}>
              <option value="#;" defaultValue="default">
                Related Sites
              </option>
              <option value="https://www.yanadookids.com">야나두 키즈</option>
              <option value="https://www.yanadookidsmall.com">무브셀렉트</option>
            </select>
            <div className="footer-link-area__flex">
              <div className="link-lists">
                <a
                  href="#;"
                  onClick={() => window.open('https://itunes.apple.com/kr/app/id1539582420')}
                >
                  <img src={IcoIos} alt="아이폰 앱 다운로드" />
                </a>
                <a
                  href="#;"
                  onClick={() =>
                    window.open('https://play.google.com/store/apps/details?id=kr.co.yanadoo.app2')
                  }
                >
                  <img src={IcoAndroid} alt="안드로이드 앱 다운로드" />
                </a>
              </div>
              <div className="link-lists">
                <a
                  href="https://www.instagram.com/yanadoo_official/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={IcoInsta} alt="인스타그램" />
                </a>
                <a
                  href="https://www.facebook.com/yanadoo1/?fref=ts"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={IcoFacebook} alt="페이스북" />
                </a>
                <a href="https://band.us/band/56561305" target="_blank" rel="noreferrer">
                  <img src={IcoBand} alt="네이버밴드" />
                </a>
                <a href="https://blog.naver.com/yanadooblog" target="_blank" rel="noreferrer">
                  <img src={IcoBlog} alt="네이버블로그" />
                </a>
                <a
                  href="https://post.naver.com/my.nhn?memberNo=25566629"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={IcoNaverPost} alt="네이버포스트" />
                </a>
              </div>
            </div>
          </div>

          <address>
            <p>
              주식회사 야나두 &#40;야나두 원격평생교육원&#41;
              <button
                className="btn-corp-toggle"
                type="button"
                onClick={() => setCorpInfo(!corpInfo)}
              >
                자세히보기
              </button>
            </p>

            {corpInfo && (
              <>
                <div className="footer-corp-info">
                  <p>
                    사업자등록번호: 129-86-23477 &middot; 통신판매업: 신고번호
                    제2018-서울강남-02139호
                  </p>
                  <p>대표: 김정수, 김민철 &middot; 개인정보보호 책임자: 이정훈</p>
                  <p>주소: 서울시 강남구 봉은사로 619 JBK Tower 4,5층</p>
                  <p>원격 평생교육 시설신고: 서울특별시 강남 서초교육지원청 &#40;제 원516호&#41;</p>
                </div>
                <p className="footer-notice">
                  (주)야나두는 통신판매중개자로서 야나두 사이트의 야나두와 야핏 이용권을 제외하면
                  거래 당사자가 아니며,
                  <br />
                  입점판매자가 등록한 상품정보 및 거래에 대해 (주)야나두는 일체 책임을 지지
                  않습니다.
                </p>
                <div className="footer-call">
                  <p>
                    야나두 고객센터:{' '}
                    <a href="tel:16000563">
                      1600-0563 <strong>(유료)</strong>
                    </a>
                  </p>
                  <p>평일 09:00~18:00 (12:00~13:00 점심시간)</p>
                </div>
              </>
            )}
          </address>
        </div>
      </footer>

      {modalopenMyInquiry ? (
        <ModalPortal>
          <ModalMyInquiry btnClose handleCloseModal={handleCloseModalMyInquiry} />
        </ModalPortal>
      ) : null}
      {modalopenCourseInquiry ? (
        <ModalPortal>
          <ModalCourseInquiry btnClose handleCloseModal={handleCloseModalCourseInquiry} />
        </ModalPortal>
      ) : null}
      {modalopenPartnershipInquiry ? (
        <ModalPortal>
          <ModalPartnershipInquiry btnClose handleCloseModal={handleCloseModalPartnershipInquiry} />
        </ModalPortal>
      ) : null}
    </>
  )
}

export default Footer
