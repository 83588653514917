import React, { useState, useRef, useEffect } from 'react';

import styled, { ThemeProvider } from 'styled-components';
import theme from 'common/theme';

import ReactPlayer from 'react-player/lazy';
import Duration from './audio/Duration';

import IcoPlay from 'assets/images/ico_play_216x216g.png';
import IcoPause from 'assets/images/ico_pause_216x216g.png';

export default function AudioPlayer({ data, isLoading }) {
  const player = useRef();
  const [playing, setPlaying] = useState(false); // 재생 상태값

  const [audioState, setAudioState] = useState({
    url: '',
    playing: false,
    volume: 0.8,
    muted: false,
    played: 0,
    loaded: 0,
    duration: 0,
    loop: false,
  });

  // 오디오 재생 구간 조절하는 함수
  const handleSeekChange = (e) => {
    setAudioState({ ...audioState, played: parseFloat(e.target.value) });
  };

  const handleSeekMouseDown = (e) => {
    setAudioState({ ...audioState, seeking: true });
  };

  const handleSeekMouseUp = (e) => {
    setAudioState({ ...audioState, seeking: false });
    player.current.seekTo(parseFloat(e.target.value));
  };

  // 오디오 재생,일시정지 함수
  const handlePlayPause = () => {
    setPlaying(!playing);
  };

  // 오디오 총 재생 시간 구하는 함수
  const handleDuration = (duration) => {
    setAudioState({ ...audioState, duration });
  };

  // 오디오 프로그레스바 표시 함수
  const handleProgress = (state) => {
    const { loaded, loadedSeconds, played, playedSeconds } = state;

    if (!audioState.seeking) {
      setAudioState({ ...audioState, loaded, loadedSeconds, played, playedSeconds });
    }
  };

  useEffect(() => {
    if (data) {
      setAudioState({ ...audioState, url: data.url });
    }
  }, [isLoading]);

  return (
    <ThemeProvider theme={theme}>
      {data && (
        <AudioPlayerBox>
          <ReactPlayer
            ref={player}
            url={audioState.url} // 플레이어 url
            width="0" // 플레이어 크기 (가로)
            height="0" // 플레이어 크기 (세로)
            playing={playing} // 자동 재생 on
            muted={false} // 자동 재생 on
            controls={false} // 플레이어 컨트롤 노출 여부
            light={false} // 플레이어 모드
            pip={false} // pip 모드 설정 여부
            onSeek={(e) => console.log('onSeek', e)}
            onProgress={handleProgress}
            onDuration={handleDuration}
            playsinline={true} // 모바일에서 비디오를 전체 화면으로 노출 여부
          />
          <AudioButton type={playing ? 'PAUSE' : 'PLAY'} onClick={handlePlayPause}>
            <theme.Blind>{playing ? '재생' : '일시정지'}</theme.Blind>
          </AudioButton>
          <ProgressSlider>
            <progress max={1} value={audioState.played} />
            <input
              type="range"
              min={0}
              max={0.999999}
              step="any"
              value={audioState.played}
              onMouseDown={handleSeekMouseDown}
              onChange={handleSeekChange}
              onMouseUp={handleSeekMouseUp}
              readOnly
            />
          </ProgressSlider>
          <Duration seconds={audioState.duration * (1 - audioState.played)} />
        </AudioPlayerBox>
      )}
    </ThemeProvider>
  );
}

const AudioPlayerBox = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 38.2rem;
  height: 6rem;
  margin-top: 1.5rem;
  padding: 1.8rem 2.4rem 1.8rem 0.8rem;
  border: 1px solid #dfdfea;
  border-radius: 79rem;
  box-sizing: border-box;
  background-color: #fff;

  time {
    font-weight: 400;
    font-size: 1.6rem;
  }

  @media ${({ theme }) => theme.device.mobile} {
    width: 100%;
    height: 8.4rem;
    margin-top: 3rem;
    padding: 2.1rem 4.2rem 2.1rem 1.4rem;
    border-radius: 120rem;

    time {
      flex: 0 0 auto;
      font-size: 2.2rem;
    }
  }
`;

const AudioButton = styled.button`
  width: 5.2rem;
  height: 5.2rem;
  background-image: ${(props) => (props.type === 'PAUSE' ? `url(${IcoPause})` : `url(${IcoPlay})`)};
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 1.6rem auto;

  @media ${({ theme }) => theme.device.mobile} {
    flex: 0 0 auto;
    width: 7rem;
    height: 7rem;
    background-size: 2.1rem auto;
  }
`;

const ProgressSlider = styled.div`
  position: relative;
  width: 23.2rem;
  height: 0.9rem;
  border-radius: 148rem;
  margin: 0 1.3rem 0 0.5rem;
  overflow: hidden;

  progress {
    width: 100%;
    height: 100%;
    appearance: none;
    -webkit-appearance: none;
    vertical-align: 0;

    &::-webkit-progress-bar {
      background-color: #ececf6;
    }

    &::-webkit-progress-value {
      background-color: #FFB900;
    }
  }

  input {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: transparent;
    border-radius: inherit;
    cursor: pointer;

    &[type='range']:focus {
      outline: none;
    }

    &[type='range']::-webkit-slider-thumb {
      -webkit-appearance: none;
      background: #FFB900;
      cursor: pointer;
      height: 0.9rem;
      width: 0.5rem;
    }
  }

  @media ${({ theme }) => theme.device.mobile} {
    flex: 1 1 auto;
    height: 1.4rem;
    border-radius: 210rem;

    progress {
      vertical-align: top;
    }
  }
`;
