import React from 'react'
import { useNavigate } from 'react-router'

import s from './Notice.module.scss'
import NoticeItem from './NoticeItem'
import useGetNoticeList from '../hooks/useGetNoticeList'

export default function NoticeList() {
  const navigate = useNavigate()
  const currentSeq = 1
  const size = 4
  const { noticeList } = useGetNoticeList({ currentSeq, size })

  const handleMoveNotice = (seq) => {
    navigate(`/service/notice/detail/${seq}`)
  }

  return (
    <ul className={s.csNoticeList}>
      {noticeList.slice(0, 4).map((item, index) => (
        <li
          className={s.csNoticeItem}
          key={index}
          onClick={() => handleMoveNotice(item.serviceNoticeSeq)}
        >
          <NoticeItem notice={item} />
        </li>
      ))}
    </ul>
  )
}
