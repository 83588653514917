import s from './ModalCheckPackage.module.css'

export default function ModalCheckPackage({ onClose }) {
  const handleMore = () => {
    onClose()
    window.open('https://www.yanadoo.co.kr/custom/all_lecture', '_blank')
  }
  return (
    <div className={s.modal}>
      <div className={s.content}>
        <h3>
          인기가 많아
          <br /> 모두 소진되었어요
        </h3>
        <p>다음에 돌아올게요</p>
        <button className={s.btnMore} onClick={handleMore}>
          다른 상품 보러가기
        </button>
        <button className={s.btnClose} onClick={onClose}>
          close
        </button>
      </div>
    </div>
  )
}
