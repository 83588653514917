import { useState } from 'react'

import useLevelTestAdvancedExamStore from 'store/levelTestAdvanced/useLevelTestAdvancedExamStore'
import useLevelTestAdvancedStore from 'store/levelTestAdvanced/useLevelTestAdvancedStore'

import styles from './SelfCheckSection.module.css'
import { selfCheckSectionValues } from '../../config'
import Navigation from '../Navigation'
import QItem from '../QuestionSection/QuestionItem'

export default function SelfCheckSection() {
  const [index, setIndex] = useState(1)
  const contents = selfCheckSectionValues[index]

  const { onChangeCurrentSection } = useLevelTestAdvancedExamStore()
  const { selfCheck, onChangeSelfCheck } = useLevelTestAdvancedStore((state) => ({ selfCheck: state.selfCheck, onChangeSelfCheck: state.onChangeSelfCheck }))

  const handleClickPrev = () => {
    setIndex((prev) => prev - 1)
  }

  const handleClickNext = () => {
    if (selfCheck[contents?.key].length) {
      if (index < 3) {
        setIndex((prev) => prev + 1)
      } else {
        onChangeCurrentSection('analyzing')
      }
    } else {
      alert(`정확한 맞춤 진단을 위해 ${contents.alertMessage}를 선택해 주세요.`)
    }
  }

  const handleClickAnswer = (answer) => {
    if (selfCheck[contents?.key] === answer) {
      onChangeSelfCheck(contents.key, '')
    } else {
      onChangeSelfCheck(contents.key, answer)
    }
  }

  return (
    <div className={styles.container}>
      <p className={styles.pagination}>{index}/3</p>
      <p className={styles.description}>맞춤 진단을 위해 선택해 주세요!</p>
      <p className={styles.title}>{contents?.question}</p>
      <div className={styles.buttonGroup}>
        {contents?.answers?.map((answer) => (
          <QItem.Button key={answer.value} isSelected={selfCheck[contents.key] === answer.value} onClick={() => handleClickAnswer(answer.value)}>
            {answer.description}
          </QItem.Button>
        ))}
      </div>
      <Navigation prevDisabled={index === 1} onClickPrev={handleClickPrev} onClickNext={handleClickNext} />
    </div>
  )
}
