import { useState } from 'react'

import api from 'common/api'
import useFeedbackStore from 'store/useFeedbackStore'

import { AI_TRAVEL_CONFIG } from '../config'

export default function useGetTravelFeedback() {
  const [feedback, setFeedback] = useState(null)
  const { setIsFeedback } = useFeedbackStore()

  const fetchFeedback = async ({ chatWindow, lectureSeq, userSeq }) => {
    const payload = {
      chatWindow,
      lectureSeq,
      userSeq
    }
    await api
      .post(`${AI_TRAVEL_CONFIG.TRAVEL_FEEDBACK_HOST}`, payload)
      .then((response) => {
        const data = response.data
        setFeedback(data)
        setIsFeedback(!!data)
      })
      .catch((e) => {
        console.log('🚀  error:', e)
      })
  }

  return { fetchFeedback, feedback }
}
