import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import ThumbNail from 'pages/community/ThumbNail'

import BgStarGray from '../../assets/images/bg_star_120x24g.png'
import BgStarOrange from '../../assets/images/bg_star_120x24_orange.png'

// [props 정보]
// type : IMAGE (이미지 후기 모달)
// link : 이동 될 url
// backgroundImage : 썸네일 이미지 url(string)
// userName : {닉네임}
// registDate : 등록 날짜
// title : 게시물 타이틀
// contents : 게시물 내용 text (string)
export default function BoardListReviewItem({ type, link, userName, registDate, title, contents, backgroundImage, satisfaction, deviceCheck }) {
  return (
    <>
      <BoardItemBox className={type === 'IMAGE' ? 'image' : ''}>
        {link && <Link to={link}></Link>}
        <FlexBox>
          <div className="left-box">
            <ThumbNail backgroundColor={'#fff'} backgroundImage={`${backgroundImage}`} size={deviceCheck ? '8.2rem' : '5.4rem'} />
            <div>
              <strong>{userName}</strong>
              <p>{registDate}</p>
            </div>
          </div>
          <div className="right-box">
            <StarPoint per={satisfaction * 20}>
              <span>star point</span>
            </StarPoint>
            <p>{title}</p>
          </div>
        </FlexBox>
        {contents && (
          <div>
            <p>{contents}</p>
          </div>
        )}
      </BoardItemBox>
    </>
  )
}

const BoardItemBox = styled.div`
  position: relative;
  padding: 3.5rem 3.5rem 3.2rem 3.5rem;

  /* 이미지 후기 스타일 */
  &.image {
    padding: 0;

    > div {
      flex-direction: column;
      align-items: flex-start;

      .right-box {
        margin-top: 1.3rem;
        align-items: flex-start;
      }
    }
  }

  & + button {
    padding-top: 0;
  }

  a {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  @media ${({ theme }) => theme.device.mobile} {
    padding: 4rem;

    /* 이미지 후기 스타일 */
    &.image {
      > div {
        flex-direction: row;

        .right-box {
          margin-top: 1.3rem;
          align-items: flex-start;

          p {
            top: auto;
            left: 0;
            bottom: -5.5rem;
          }
        }
      }
    }
  }
`

const FlexBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & + div {
    margin-top: 1.5rem;

    p {
      font-weight: 400;
      font-size: 1.6rem;
    }
  }

  .left-box {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    div {
      flex: 1 1 auto;
      margin-right: 1.5rem;
      overflow: hidden;

      strong {
        font-weight: 700;
        font-size: 1.6rem;
      }

      p {
        margin-top: 0.8rem;
        font-weight: 400;
        font-size: 1.2rem;
        color: #a0a0b6;
        line-height: 1;
      }
    }
  }

  .right-box {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    overflow: hidden;

    p {
      margin-top: 1rem;
      font-weight: 400;
      font-size: 1.2rem;
      color: #717188;
    }
  }

  @media ${({ theme }) => theme.device.mobile} {
    align-items: flex-start;

    & + div {
      margin-top: 6.5rem;

      p {
        font-size: 2.6rem;
      }
    }

    .left-box {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      div {
        margin-right: 2.2rem;

        strong {
          font-size: 2.6rem;
        }

        p {
          margin-top: 0.4rem;
          font-size: 2rem;
        }
      }
    }

    .right-box {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      overflow: hidden;

      p {
        position: absolute;
        top: 14.7rem;
        left: 4rem;
        margin-top: 0;
        font-weight: 500;
        font-size: 2rem;
        color: #8d8da0;
      }
    }
  }
`

const StarPoint = styled.div`
  position: relative;
  flex: 0 0 auto;
  width: 10rem;
  height: 2rem;

  &:before {
    content: '';
    display: inline-block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: url(${BgStarGray}) 0 0 no-repeat;
    background-size: auto 100%;
    z-index: 2;
  }

  span {
    display: inline-block;
    position: absolute;
    z-index: 5;
    width: ${(props) => props.per + '%' || '0%'};
    height: 100%;
    background: url(${BgStarOrange}) 0 0 no-repeat;
    background-size: auto 100%;
    max-width: 100%;
    text-indent: -9999rem;
    overflow: hidden;
  }

  @media ${({ theme }) => theme.device.mobile} {
    width: 12rem;
    height: 2.4rem;
  }
`
