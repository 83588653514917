import React, { useState, useEffect, useRef } from 'react';
import api from 'common/api';
import { timeCheck } from '../../common/common';

import ThumbNail from './ThumbNail';

import styled, { css, ThemeProvider } from 'styled-components';
import theme from 'common/theme';

export default function CommentReviewItem({
  handleOpenReply, // 답글 달기 함수
  handleOpenModal, // 팝업 여는 함수
  backgroundImage,
  nickName,
  contents,
  likeCount,
  registDate,
  existChildComment,
  getCommentData, // 댓글 조회 API
  deviceCheck,
  commentRegistTime,
  isLoading,
  data,
  userInfo,
  replyFlag,
  currentSeq,
}) {
  const [replyData, setReplyData] = useState(null);
  // 대댓글 조회 API
  const getReplyData = (seq) => {
    api
      .get(
        `/v2/community-tab/srr-learning-review/comment/child-comment?pageIndex=1&pageSize=100&parentSeq=${seq}`
      )
      .then((response) => {
        if (response.data.meta.code === 200) {
          setReplyData(response.data.data.childComments);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const onToggleLike = (seq, relationType) => {
    if (isLoading) {
      api
        .post(`/v2/reaction/like?relationSeq=${seq}&relationType=${relationType}`)
        .then((response) => {
          if (response.data.meta.code === 200) {
            getCommentData(currentSeq);
            getReplyData(data.commentSeq);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  useEffect(() => {
    if (isLoading && existChildComment) {
      getReplyData(data.commentSeq);
      getCommentData(currentSeq);
    }
  }, [isLoading, existChildComment]);

  useEffect(() => {
    if (replyFlag && existChildComment) {
      getReplyData(data.commentSeq);
    }
  }, [replyFlag, data]);

  return (
    <ThemeProvider theme={theme}>
      <CommentItem>
        <ThumbNail backgroundImage={backgroundImage} size={deviceCheck ? '6.8rem' : '4.6rem'} />
        <DescBox>
          <ContentsBox>
            <strong>{nickName}</strong>
            {contents}
          </ContentsBox>
          <IconBox>
            <div>
              {data.status === 'NORMAL' && (
                <>
                  <button
                    type="button"
                    onClick={() => {
                      onToggleLike(data.commentSeq, 'COMMENT');
                    }}
                  >
                    {data.isLiked ? <strong>좋아요</strong> : <span>좋아요</span>}
                    {data.likeCount === 0 ? (
                      ''
                    ) : data.isLiked ? (
                      <strong>{likeCount}</strong>
                    ) : (
                      likeCount
                    )}
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      handleOpenReply(data.commentSeq, data.registUser.nickName);
                    }}
                  >
                    <span>답글달기</span>
                  </button>
                </>
              )}

              <span>{registDate}</span>
            </div>
            {userInfo && data.registUser.userSeq === userInfo.userSeq && data.status === 'NORMAL' && (
              <button
                type="button"
                onClick={() => {
                  handleOpenModal(data.commentSeq, data.contents);
                }}
              >
                <span>
                  <theme.Blind>팝업열기</theme.Blind>
                </span>
              </button>
            )}
          </IconBox>
        </DescBox>
      </CommentItem>
      {existChildComment &&
        replyData &&
        replyData
          .filter((reReply) => reReply.status !== 'DELETE_OF_WRITER')
          .map((reReply, idx) => {
            return (
              <CommentItem type={'REPLY'} key={idx}>
                <ThumbNail
                  backgroundImage={reReply.registUser.profileImageUrl}
                  size={deviceCheck ? '5.2rem' : '3.6rem'}
                />
                <DescBox>
                  <ContentsBox>
                    <strong>{reReply.registUser.nickName}</strong>
                    {reReply.contents}
                  </ContentsBox>
                  <IconBox>
                    <div>
                      {reReply.status === 'NORMAL' && (
                        <button
                          type="button"
                          onClick={() => {
                            onToggleLike(reReply.commentSeq, 'COMMENT');
                          }}
                        >
                          {reReply.isLiked ? <strong>좋아요</strong> : <span>좋아요</span>}
                          {reReply.likeCount === 0 ? (
                            ''
                          ) : reReply.isLiked ? (
                            <strong>{reReply.likeCount}</strong>
                          ) : (
                            likeCount
                          )}
                        </button>
                      )}
                      <span>
                        {timeCheck(
                          commentRegistTime,
                          reReply.registDate.replace('-', '/').replace('-', '/')
                        )}
                      </span>
                    </div>
                    {userInfo &&
                      reReply.registUser.userSeq === userInfo.userSeq &&
                      reReply.status === 'NORMAL' && (
                        <button
                          type="button"
                          onClick={() => {
                            handleOpenModal(reReply.commentSeq, reReply.contents);
                          }}
                        >
                          <span>
                            <theme.Blind>팝업열기</theme.Blind>
                          </span>
                        </button>
                      )}
                  </IconBox>
                </DescBox>
              </CommentItem>
            );
          })}
    </ThemeProvider>
  );
}

const CommentItem = styled.div`
  display: flex;

  & + div {
    margin-top: 1.8rem;
  }

  ${(props) =>
    props.type === 'REPLY' &&
    css`
      padding-left: 5.8rem;
    `}

  @media ${({ theme }) => theme.device.mobile} {
    & + div {
      margin-top: 2.6rem;
    }

    ${(props) =>
      props.type === 'REPLY' &&
      css`
        padding-left: 8rem;
      `}
  }
`;
const ContentsBox = styled.div`
  width: 100%;
  padding: 1.8rem;
  background-color: #efeff6;
  font-size: 1.6rem;
  border-radius: 1rem;
  line-height: 1.5;
  box-sizing: border-box;

  strong {
    display: inline-block;
    margin-right: 1rem;
    font-weight: 700;
  }

  @media ${({ theme }) => theme.device.mobile} {
    padding: 2.6rem;
    font-size: 2.6rem;
  }
`;

const DescBox = styled.div`
  flex-grow: 1;
  margin-left: 1.2rem;

  @media ${({ theme }) => theme.device.mobile} {
    margin-left: 1.4rem;
  }
`;

const IconBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0.7rem 0 0.7rem;
  color: #8d8da0;
  font-size: 1.4rem;

  div {
    button {
      position: relative;

      &::after {
        content: '·';
        display: inline-block;
        margin: auto 0.3rem;
      }
    }

    & + button {
      padding: 0 1.6rem;

      span {
        display: inline-block;
        position: relative;
        width: 0.3rem;
        height: 0.3rem;
        border-radius: 50%;
        background-color: #a0a0b6;

        &::before,
        &::after {
          content: '';
          position: absolute;
          top: 0;
          width: 0.3rem;
          height: 0.3rem;
          border-radius: 50%;
          background-color: #a0a0b6;
        }

        &::before {
          left: -0.6rem;
        }

        &::after {
          right: -0.6rem;
        }
      }
    }
  }

  @media ${({ theme }) => theme.device.mobile} {
    font-size: 2.2rem;
  }
`;
