import React from 'react';
import theme from 'common/theme';
import styled, { ThemeProvider } from 'styled-components';

const UserInfo = ({ userName, cellPhone }) => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <UserInfoBox>
          <div className="inner-col">
            <h1>주문자 정보</h1>
            <InfoBox>
              <em>구매내역을 알림톡(SMS)으로 안내해 드립니다.</em>
            </InfoBox>
            <UserBox>
              <dl>
                <dt>이름</dt>
                <dd>
                  <input type="text" name="defaultName" value={userName} readOnly />
                </dd>
              </dl>
              <dl>
                <dt>휴대폰 번호</dt>
                <dd>
                  <input type="text" name="defaultCellPhone" value={cellPhone} readOnly />
                </dd>
              </dl>
            </UserBox>
          </div>
        </UserInfoBox>
      </ThemeProvider>
    </>
  );
};
export default UserInfo;

const UserBox = styled.div`
  display: flex;
  height: 7rem;
  border-top: 0.3rem solid #000;
  border-bottom: 0.1rem solid #b7b7c8;
  dl {
    display: flex;
    align-items: center;
    width: 50%;
    dt {
      width: 19rem;
      padding: 0 2.5rem;
      font-size: 1.6rem;
      text-align: left;
    }
    dd {
      flex: 1 auto;
      justify-content: flex-start;
      font-size: 1.6rem;
      text-align: left;
      input {
        font-size: 1.6rem;
      }
    }
  }
  @media ${({ theme }) => theme.device.mobile} {
    display: block;
    padding-top: 2.4rem;
    border-top: 0;
    border-bottom: 0;
    dl {
      display: block;
      dt {
        display: none;
      }
      dd {
        display: block;
        padding-bottom: 0.5rem;
        input {
          font-size: 2.6rem;
          color: #717188;
        }
      }
    }
  }
`;

const UserInfoBox = styled.section`
  padding-top: 8rem;
  h1 {
    font-weight: 700;
    font-size: 3rem;
    color: #000;
    text-align: left;
  }
  @media ${({ theme }) => theme.device.mobile} {
    padding: 4.5rem 4.2rem;
    h1 {
      padding-bottom: 2rem;
      font-weight: 700;
      font-size: 3rem;
      color: #000;
    }
    table {
      border-top: 0 !important;
      th {
        display: none;
      }
      td {
        width: 100%;
        font-size: 2.6rem;
      }
    }
  }
`;

const InfoBox = styled.p`
  text-align: right;
  padding: 0.4rem 0 1rem;
  font-size: 1.4rem;
  color: #28284b;
  em {
    display: inline-block;
    &:before {
      content: '※ ';
    }
  }
  @media ${({ theme }) => theme.device.mobile} {
    display: none;
  }
`;
