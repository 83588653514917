import React from 'react'
import Lottie from 'react-lottie'

import cx from 'classnames'

import s from './ChatFooterAudio.module.scss'
import { CHAT_COMPONENTS_CONFIG } from './config'
import ResponseLoading from './ResponseLoading.json'

const defaultOptions2 = {
  loop: true,
  autoplay: true,
  animationData: ResponseLoading,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
}

export default function RecorderButton({ isListening, isLoading, handleRecorder }) {
  return (
    <>
      {isListening ? (
        <div className={s.controlButtons}>
          <button className={s.buttonCancel} onClick={handleRecorder.stop}>
            취소
          </button>
          <button className={s.buttonMic} onClick={handleRecorder.send} disabled={isLoading}>
            <div>{CHAT_COMPONENTS_CONFIG.svg.send}</div>
          </button>
          <button className={s.buttonRestart} onClick={handleRecorder.re}>
            <span>재시작</span>
          </button>
        </div>
      ) : isLoading ? (
        <div className={cx(s.buttonMic, s.buttonLoading)}>
          <div className={s.loading}>
            <Lottie options={defaultOptions2} />
          </div>
        </div>
      ) : (
        <button className={s.buttonMic} onClick={handleRecorder.start} disabled={isLoading}>
          <div>{CHAT_COMPONENTS_CONFIG.svg.mic}</div>
        </button>
      )}
    </>
  )
}
